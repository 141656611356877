import customerService from '../../services/customerService';
import { cloudFunctionsPost } from '../../modules/cloud-function-wrapper.js';

const state = {
  paymentMethods: [],
  extendedPaymentMethods: []
};

const getters = {
  getPaymentMethods(state) {
    return state.paymentMethods;
  },
  getExtendedPaymentMethods(state) {
    return state.extendedPaymentMethods;
  }
};

const mutations = {
  setPaymentMethods(state, payload) {
    state.paymentMethods = payload;
  },
  setExtendedPaymentMethods(state, payload) {
    state.extendedPaymentMethods = payload;
  }
};

const actions = {
  //CREATE
  addPaymentMethod({ commit }, { data }) {
    return cloudFunctionsPost('/api/payments/addcard', data);
  },
  addBankAccount({ commit }, payload) {
    return cloudFunctionsPost('/api/payments/addbankaccount', payload);
  },
  //READ
  async getPaymentMethodsData({ commit }, payload) {
    let extendedPaymentMethodsList = await customerService.getPaymentMethodsByCustomerId(payload.customerId);
    if (!payload.showAllCards) {
      extendedPaymentMethodsList = extendedPaymentMethodsList.filter(method => method.data.is_visible !== false);
    }
    const paymentsMethodsList = extendedPaymentMethodsList.map(method => ({
      id: method.id,
      details: `${method.data.last_four || ''} • ${method.data.card_type || ''} • ${method.data.exp_month || ''}/${method.data.exp_year || ''}`,
      type: method.data.stripe_card ? 'card' : method.data.stripe_bank_account ? 'bank_account' : '',
      disabled: !!(method.data.stripe_bank_account && method.data.status !== 'verified')
    }));
    commit('setPaymentMethods', paymentsMethodsList);
    commit('setExtendedPaymentMethods', extendedPaymentMethodsList);
  },
  //UPDATE
  updatePaymentMethod({ dispatch }, payload) {
    return customerService.updatePaymentMethod(payload.customerId, payload.paymentMethodId, payload.data);
  },
  setDefaultPaymentMethod({ dispatch }, payload) {
    return cloudFunctionsPost('/api/customers/setpaymentmethodtodefault', payload);
  },
  verifyBankAccount({ dispatch }, payload) {
    return cloudFunctionsPost('/api/paymentmethods/verifybankaccount', payload);
  },
  //DELETE
  deletePaymentMethod({ dispatch }, payload) {
    return cloudFunctionsPost('/api/payments/deletesource', payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
