<template>
  <v-container fluid transition="fade-transition">
    <confirmation-dialog
      :isConfirmationDialogOpened="isDuplicationDialogOpened"
      :action="confirmationDialogAction"
      @closeConfirmationDialog="closeDuplicationDialog"
      @actionConfirmed="duplicateConfirmed"
    >
    </confirmation-dialog>

    <confirm-delete-dialog :isDialogOpened="isDeleteDialogOpened" :itemNames="deleteDialogText" @confirmDelete="deleteConfirmed" @closeDialog="closeDeleteDialog">
    </confirm-delete-dialog>

    <confirm-import-dialog
      :isDialogOpened="isImportDialogOpened"
      :selectedItemsCount="selectedProductsCount"
      :duplicatedItemsCount="duplicatedProductsCount"
      @closeDialog="closeImportDialog"
      @excludeItems="excludeItems"
      @overwriteItems="overwriteItems"
    ></confirm-import-dialog>

    <confirm-dialog
      :value="isInactiveDialogOpened"
      title="Set Product(s) Inactive"
      closeText="Cancel"
      openText="Set Inactive"
      @onClose="cancelInactiveDialog"
      @onConfirm="confirmSetInactive"
    >
      <div>
        <p>Some products cannot be deleted because they are linked to orders or templates. Would you like to set these products as inactive instead?</p>
        <div v-if="usedProducts.length">
          <span>Product(s) linked to orders:</span>
          <ul>
            <li v-for="product in usedProducts.slice(0, 9)" :key="product.product_firebase_id">
              {{ product.name }}
            </li>
            <li v-if="usedProducts.length > 9">... {{ usedProducts.length - 9 }} others</li>
          </ul>
        </div>

        <div v-if="linkedTemplatesProducts.length">
          <br /><span> Product(s) linked to templates:</span>
          <ul>
            <li v-for="product in linkedTemplatesProducts.slice(0, 9)" :key="product.product_firebase_id">
              {{ product.name }}
            </li>
            <li v-if="linkedTemplatesProducts.length > 9">... {{ linkedTemplatesProducts.length - 9 }} others</li>
          </ul>
        </div>
      </div>
    </confirm-dialog>

    <v-toolbar flat class="site-table--header site-table--lists">
      <v-row class="mt-n3 mb-0">
        <v-toolbar-title class="headline grey--text text--darken-3 ml-n3 pt-1">{{
          isImportMode
            ? 'Select products to import'
            : isProductSiteDefault
            ? `Products: Default`
            : `Products:
          ${siteName} (${siteID})`
        }}</v-toolbar-title>
        <v-spacer />
        <v-col v-if="isFilterDispayed" sm="2" class="text-center">
          <v-select v-model="filter" class="mt-n3" label="Filter by site" :items="filterOptions" item-text="name" item-value="id" return-object multiple outlined dense clearable>
            <template v-slot:prepend-item>
              <v-list-item ripple @mousedown.prevent @click="toggle">
                <v-list-item-action>
                  <v-icon :color="filter.length ? 'primary darken-4' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ item, index }">
              <div v-if="index === 0">
                <span>{{ item.name }}</span>
              </div>
              <span v-if="index === 1" class="grey--text text--darken-3 text-caption"> (+{{ filter.length - 1 }} others) </span>
            </template>
          </v-select>
        </v-col>
        <v-col sm="2" class="text-center">
          <v-text-field v-model="search" label="Search" class="mt-n3" append-icon="mdi-magnify" outlined dense hide-details></v-text-field>
        </v-col>
        <v-btn v-if="!isImportMode" color="primary" class="white--text ml-2 mr-n1" min-width="200" @click="exportProducts"
          ><v-icon left>mdi-file-export</v-icon>Export products</v-btn
        >
        <v-btn v-if="!isImportMode" color="blue" class="white--text ml-2 mr-n1" min-width="170" @click="addNewProduct"
          ><v-icon left>mdi-plus-circle</v-icon>{{ isProductSiteDefault ? 'Add New Product' : 'Add products' }}</v-btn
        >
        <v-btn v-else color="blue" class="white--text" min-width="200" @click="importProduct"><v-icon left>mdi-plus-circle</v-icon>Add Selected Products</v-btn>
        <v-btn v-if="isDeleteEnabled" :disabled="isDeleteButtonDisabled" color="red" class="white--text ml-2 mr-n1 d-flex" @click="massDeleteProducts"
          ><v-icon left>mdi-trash-can</v-icon>Delete Products</v-btn
        >
      </v-row>
    </v-toolbar>
    <div class="site-table mt-xl-n5 ml-n1 mr-0 mt-sm-0">
      <stamp-data-table
        ref="dataTable"
        :headers="headers"
        :data="filteredProducts"
        :actions="!isImportMode ? actions : []"
        :is-multi-select-enabled="isDeleteEnabled"
        :search="search"
        :is-loading="isProductsTableLoading"
        :is-items-per-page-disabled="true"
        :items-per-page="50"
        item-key="product_firebase_id"
        @viewProduct="viewProduct"
        @editProduct="editProduct"
        @duplicateProduct="duplicateProduct"
        @deleteProduct="deleteProduct"
        @onItemSelect="onProductSelect"
      >
        <template #[`item.size_name`]="{ item }">{{ item.size_x }}<span>x</span>{{ item.size_y }}</template>
        <template #[`item.graphic`]="{ item }">
          <v-img max-width="35" :src="item.image"></v-img>
        </template>
        <template #[`item.product_firebase_id`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon color="amber darken-3" v-on="on" @click="copyItemToClipboard(item.product_firebase_id)">mdi-firebase</v-icon>
            </template>
            <span>Copy FirebaseID ( {{ item.product_firebase_id }} ) to clipboard</span>
          </v-tooltip>
        </template>
        <template #[`item.salesforce_product_id`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon color="blue" v-on="on" @click="copyItemToClipboard(item.salesforce_product_id)">mdi-salesforce</v-icon>
            </template>
            <span>Copy Salesforce ID ( {{ item.salesforce_product_id }} ) to clipboard</span>
          </v-tooltip>
        </template>
        <template #[`item.salesforce_pricebook_entry_id`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon color="green darken-2" v-on="on" @click="copyItemToClipboard(item.salesforce_pricebook_entry_id)">mdi-book-open-page-variant</v-icon>
            </template>
            <span>Copy Salesforce Pricebook Entry ID ( {{ item.salesforce_pricebook_entry_id }} ) to clipboard</span>
          </v-tooltip>
        </template>
        <template #[`item.fourover_product_id`]="{ item }">
          <div v-if="item.fourover_product_id">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon color="gray darken-2" v-on="on" @click="copyItemToClipboard(item.fourover_product_id)">mdi-printer</v-icon>
              </template>
              <span>Copy Fourover Product ID ( {{ item.fourover_product_id }} ) to clipboard</span>
            </v-tooltip>
          </div>
        </template>
        <template #[`item.is_default`]="{ item }">
          <v-chip small :color="getProductInformationChipColor(item)" class="mt-auto mb-auto text-white">
            {{ item.is_default ? 'Default' : 'Custom' }}
          </v-chip>
        </template>
        <template #[`item.is_active`]="{ item }">
          <div class="d-flex align-center">
            <v-badge large :color="getProductStatusChipColor(item)" class="shrink-on-small mt-2"></v-badge>
            <div class="ml-5">{{ item.is_active ? 'Active' : 'Inactive' }}</div>
          </div>
        </template>
        <template #[`item.sites`]="{ item }">
          <v-tooltip v-if="availablesSiteNames(item.sites).length" bottom>
            <template #activator="{ on }">
              <v-btn depressed small v-on="on">{{ availablesSiteNames(item.sites).length }}</v-btn>
            </template>
            <span>{{ availablesSiteNames(item.sites).toString() }}</span>
          </v-tooltip>
          <span v-else>{{ availablesSiteNames(item.sites).length }}</span>
        </template>
      </stamp-data-table>
    </div>
    <v-row class="mt-10 mb-0 mr-3 justify-end"
      ><v-btn v-if="isImportMode" color="blue" class="white--text" min-width="200" @click="importProduct"><v-icon left>mdi-plus-circle</v-icon>Add Selected Products</v-btn></v-row
    >
  </v-container>
</template>

<script>
import ConfirmDeleteDialog from '../dialog/ConfirmDeleteDialog';
import ConfirmImportDialog from '../dialog/ConfirmImportDialog';
import ConfirmationDialog from '../order-management/orders/orderDialogs/ConfirmationDialog';
import ConfirmDialog from '../dialog/ConfirmationDialog.vue';
import { mapGetters } from 'vuex';
import DataTable from '@/components/common/DataTable.vue';
import productService from '../../services/productService';

export default {
  components: {
    'confirm-delete-dialog': ConfirmDeleteDialog,
    'confirm-import-dialog': ConfirmImportDialog,
    'confirmation-dialog': ConfirmationDialog,
    'stamp-data-table': DataTable,
    'confirm-dialog': ConfirmDialog
  },
  props: {
    isImportMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      isDeleteDialogOpened: false,
      isImportDialogOpened: false,
      isMassDeleteClicked: false,
      selectedProduct: {},
      productsToDelete: [],
      isDuplicationDialogOpened: false,
      isInactiveDialogOpened: false,
      isSingleItemDelete: false,
      inactiveDialogText: '',
      confirmationDialogAction: '',
      selectedProducts: [],
      selectedProductsCount: 0,
      duplicatedProductsCount: 0,
      productObject: {},
      duplicatedProducts: {},
      filter: [],
      usedProducts: [],
      linkedTemplatesProducts: []
    };
  },
  computed: {
    ...mapGetters('products', {
      products: 'getProducts',
      isProductsTableLoading: 'getProductsLoadingState'
    }),
    ...mapGetters('users', {
      userAccessLevel: 'userAccessLevel'
    }),
    ...mapGetters('site', {
      sites: 'getSites'
    }),
    massDeleteItems() {
      return this.$store.getters['products/getSelectedProducts'].map(product => product.name);
    },
    isDeleteButtonDisabled() {
      return !this.isDeleteEnabled || !this.$store.getters['products/getSelectedProducts'].length;
    },
    isFilterDispayed() {
      return this.isProductSiteDefault || this.isImportMode;
    },
    filterOptions() {
      return this.sites.map(({ firebase_id, site_name }) => ({ id: firebase_id, name: site_name }));
    },
    filteredProducts() {
      if (!this.filter || !this.filter.length) {
        return this.products;
      }
      return this.products.filter(product => Array.isArray(product.sites) && this.filter.every(filterItem => product.sites.some(site => filterItem.id === site.id)));
    },
    allFiltersSelected() {
      return this.filter.length === this.filterOptions.length;
    },
    someFiltersSelected() {
      return this.filter.length && !this.allFiltersSelected;
    },
    icon() {
      if (this.allFiltersSelected) return 'mdi-close-box';
      if (this.someFiltersSelected) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    isUserSuperAdmin() {
      return this.userAccessLevel && parseInt(this.userAccessLevel.value) === 100;
    },
    siteID() {
      return this.$route.params.siteId;
    },
    isProductSiteDefault() {
      return this.siteID === 'default';
    },
    isDeleteEnabled() {
      return this.isUserSuperAdmin && !this.isProductSiteDefault;
    },
    siteName() {
      if (!this.isProductSiteDefault) {
        const site = this.sites.find(site => site.firebase_id === this.siteID);
        return site ? site?.site_name : '';
      }
      return '';
    },
    deleteDialogText() {
      return this.isMassDeleteClicked ? this.massDeleteItems : this.selectedProduct.name;
    },
    actions() {
      let actions = [
        { icon: 'mdi-eye', iconColor: 'blue darken-1', title: 'View Product', eventName: 'viewProduct' },
        { icon: 'mdi-pencil', iconColor: 'green darken-2', title: 'Edit Product', eventName: 'editProduct' }
      ];

      if (this.isDeleteEnabled) {
        actions.push({
          icon: 'mdi-delete-outline',
          iconColor: 'red darken-1',
          title: 'Delete Product',
          eventName: 'deleteProduct'
        });
      }

      if (this.isProductSiteDefault) {
        actions.push({ icon: 'mdi-plus-box-multiple-outline', iconColor: 'grey darken-1', title: 'Duplicate Product', eventName: 'duplicateProduct' });
      }
      return actions;
    },
    headers() {
      let headers = [
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Tax Code', value: 'tax_code', sortable: true },
        { text: 'Netsuite ID', value: 'netsuite_id', sortable: true, width: '110px' },
        { text: 'Size', value: 'size_name', sortable: true },
        { text: 'Graphic', value: 'graphic', sortable: false },
        { text: 'Salesforce ID', value: 'salesforce_product_id', sortable: true, width: '80px' },
        { text: 'Salesforce Pricebook ID', value: 'salesforce_pricebook_entry_id', sortable: true, width: '110px' },
        { text: '4over ID', value: 'fourover_product_id', sortable: true, width: '110px' },
        { text: 'Category', value: 'category.name', sortable: true },
        { text: 'Type', value: 'type.name', sortable: true },
        { text: 'Sites', value: 'sites', align: 'center', sortable: true },
        { text: 'Firebase ID', value: 'product_firebase_id', align: 'center', sortable: false },
        { text: 'Last date modified', value: 'modified_at', sortable: true }
      ];

      if (this.siteID !== 'default' && !this.isImportMode) {
        headers.unshift({ text: 'Status', value: 'is_active', sortable: true, width: '150px' });
        headers.unshift({ text: 'Product Information', value: 'is_default', sortable: true, width: '140px' });
      }

      return headers;
    }
  },
  async created() {
    if (this.isProductSiteDefault || this.isImportMode) {
      this.$store.dispatch('products/getDefaultProducts');
    } else {
      await this.$store.dispatch('products/getProductsBySiteID', this.siteID);
    }
    await this.$store.dispatch('loadSitesFromDb');
  },
  methods: {
    availablesSiteNames(item) {
      return item ? item.map(site => site.name) : 0;
    },
    toggle() {
      this.$nextTick(() => {
        this.filter = this.allFiltersSelected ? [] : this.filterOptions.slice();
      });
    },
    addNewProduct() {
      if (this.isProductSiteDefault) {
        this.$router.replace('/products/new/default');
      } else {
        this.$router.replace(`/products/import/${this.siteID}`);
      }
    },
    async importProduct() {
      const selectedProducts = this.$store.getters['products/getSelectedProducts'];

      if (!selectedProducts.length) {
        this.setSnackbar('error', `Please select at least one product!`);
        return;
      }

      try {
        this.productObject = this.buildProductObject(this.productFirebaseId, this.siteID, selectedProducts);

        this.duplicatedProducts = await this.$store.dispatch('products/getDuplicatedProducts', this.productObject);

        if (this.duplicatedProducts.length) {
          this.selectedProductsCount = selectedProducts.length;
          this.duplicatedProductsCount = this.duplicatedProducts.length;
          this.isImportDialogOpened = true;
        } else {
          await this.$store.dispatch('products/importProducts', this.productObject);
          this.$store.commit('products/setSelectedProducts', []);
          this.setSnackbar('success', `Selected products have been imported successfully!`);
          this.$router.replace(`/products/sites/${this.siteID}`);
        }
      } catch (error) {
        console.log('Error importing products: ', error);
        this.setSnackbar('error', error);
      }
    },
    async excludeItems() {
      this.isImportDialogOpened = false;

      const duplicatedProductsIDs = this.duplicatedProducts.map(obj => obj.product_firebase_id);
      const filteredProducts = this.productObject.data.filter(obj => !duplicatedProductsIDs.includes(obj.product_firebase_id));

      this.productObject = this.buildProductObject(this.productFirebaseId, this.siteID, filteredProducts);

      await this.$store.dispatch('products/importProducts', this.productObject);
      this.$store.commit('products/setSelectedProducts', []);
      this.setSnackbar('success', `Selected products have been imported successfully!`);
      this.$router.replace(`/products/sites/${this.siteID}`);
    },
    async overwriteItems() {
      this.isImportDialogOpened = false;

      await this.$store.dispatch('products/importProducts', this.productObject);
      this.$store.commit('products/setSelectedProducts', []);
      this.setSnackbar('success', `Selected products have been imported successfully!`);
      this.$router.replace(`/products/sites/${this.siteID}`);
    },
    buildProductObject(productId, siteId, data) {
      const productObject = {
        id: productId || '',
        data,
        siteID: siteId
      };

      productObject.data.forEach(productData => {
        if (!productData.material_weight) {
          productData.material_weight = '0.0062';
        }
      });

      return productObject;
    },
    editProduct(product) {
      this.$router.replace(`/products/sites/${this.siteID}/edit/${product.product_firebase_id}`);
    },
    viewProduct(product) {
      this.$router.replace(`/products/sites/${this.siteID}/view/${product.product_firebase_id}`);
    },
    duplicateProduct(product) {
      this.confirmationDialogAction = `duplicate product '${product.name}'`;
      this.selectedProduct.data = product;
      this.isDuplicationDialogOpened = true;
    },
    async duplicateConfirmed() {
      console.log('Duplicating product: ', this.selectedProduct);

      try {
        this.$store.dispatch('showLoader', { message: `Duplicating product ${this.selectedProduct.data.name} in progress...` });
        this.isDuplicationDialogOpened = false;

        this.selectedProduct.data.name = await this.generateDuplicatedProductName(this.selectedProduct.data.name);

        this.selectedProduct.data.salesforce_product_id = '';
        this.selectedProduct.data.salesforce_pricebook_entry_id = '';
        this.selectedProduct.data.fourover_product_id = '';
        this.selectedProduct.data.stripe_product_id = '';
        this.selectedProduct.data.product_usage = 0;
        this.selectedProduct.data.stripe_product_id = '';
        this.selectedProduct.data.stripe_id = '';
        this.selectedProduct.data.external_sku = '';

        const { product_firebase_id, ...productData } = this.selectedProduct.data;

        const productDuplicateData = { data: productData, siteID: this.siteID };
        const result = await this.$store.dispatch('products/addProduct', productDuplicateData);

        try {
          const productPricing = await this.$store.dispatch('product_pricing/getDefaultProductPricingById', { product_firebase_id: this.selectedProduct.data.product_firebase_id });

          await this.$store.dispatch('product_pricing/addNewProductPricing', { id: result, data: productPricing });
        } catch (error) {
          console.log('Error: ', error);
        }

        this.setSnackbar('success', `Duplicate product created with id [${result}]`);
      } catch (error) {
        this.showAlert('error', 'An error occurred while duplicating product.');
        console.log('[duplicateConfirmed] ERROR:', error);
      } finally {
        this.closeDuplicationDialog();
        this.$store.dispatch('hideLoader');
      }
    },
    async generateDuplicatedProductName(originalName) {
      let number = 1;
      let baseName = originalName;

      while (this.checkIfNameAlreadyExists(baseName)) {
        baseName = `${originalName} (${number})`;
        number++;
      }

      return baseName;
    },
    checkIfNameAlreadyExists(productName) {
      return this.products.some(product => product.name === productName);
    },

    async deleteProduct(product) {
      this.selectedProduct = product;
      if (product?.product_usage) {
        this.inactiveDialogText = `The product "${product.name}" was used in ${product.product_usage} order(s), can not delete it. Would you like to set it as inactive instead?`;
        this.isInactiveDialogOpened = true;
        this.isSingleItemDelete = true;
        return;
      }
      const templatesLinkedWithProduct = await this.$store.dispatch('products/countLinkedTemplatesWithProduct', { productId: product.product_firebase_id, siteId: this.siteID });
      if (templatesLinkedWithProduct) {
        this.inactiveDialogText = `The product "${product.name}" is linked to ${templatesLinkedWithProduct} template(s), can not delete it. Would you like to set it as inactive instead?`;
        this.isInactiveDialogOpened = true;
        this.isSingleItemDelete = true;
        return;
      }
      this.isDeleteDialogOpened = true;
    },

    async massDeleteProducts() {
      this.$store.dispatch('showLoader', { message: `Preparing product(s) for deletion...` });

      const selectedProducts = this.$store.getters['products/getSelectedProducts'];

      if (!selectedProducts.length) {
        this.setSnackbar('error', `Please select at least one product!`);
        return;
      }

      this.isMassDeleteClicked = true;

      try {
        for (const product of selectedProducts) {
          if (product?.product_usage) {
            this.usedProducts.push(product);
            continue;
          }

          try {
            const templatesLinkedWithProduct = await this.$store.dispatch('products/countLinkedTemplatesWithProduct', {
              productId: product.product_firebase_id,
              siteId: this.siteID
            });
            if (templatesLinkedWithProduct) {
              this.linkedTemplatesProducts.push(product);
            }
          } catch (error) {
            this.showAlert('error', `Failed to check linked templates for product "${product.name}": ${error.message}`);
            return;
          }
        }

        if (this.usedProducts.length || this.linkedTemplatesProducts.length) {
          this.isInactiveDialogOpened = true;
          return;
        }

        this.productsToDelete = selectedProducts.map(product => ({
          productFirebaseId: product.product_firebase_id,
          name: product.name,
          siteID: this.siteID
        }));

        this.isDeleteDialogOpened = true;
      } catch (error) {
        this.showAlert('error', `An error occurred during the mass delete process: ${error.message}`);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },

    async confirmSetInactive() {
      try {
        this.$store.dispatch('showLoader', { message: `Setting product(s) inactive in progress...` });

        if (this.isSingleItemDelete) {
          const payload = {
            id: this.selectedProduct.product_firebase_id,
            data: { ...this.selectedProduct, is_active: false },
            siteID: this.siteID,
            isOnlyProductStatusModified: true
          };

          await this.$store.dispatch('products/editProduct', payload);
        } else {
          const selectedProducts = this.$store.getters['products/getSelectedProducts'];

          const usedOrLinkedProducts = selectedProducts.filter(product => this.usedProducts.includes(product) || this.linkedTemplatesProducts.includes(product));

          const unusedProducts = selectedProducts
            .filter(product => !this.usedProducts.includes(product) && !this.linkedTemplatesProducts.includes(product))
            .map(product => ({
              productFirebaseId: product.product_firebase_id,
              siteID: this.siteID
            }));

          await this.$store.dispatch('products/massDeleteProducts', unusedProducts);

          const updatePromises = usedOrLinkedProducts.map(product => {
            const payload = {
              id: product.product_firebase_id,
              data: { ...product, is_active: false },
              siteID: this.siteID,
              isOnlyProductStatusModified: true
            };
            return this.$store.dispatch('products/editProduct', payload);
          });

          await Promise.all(updatePromises);
        }

        this.setSnackbar('success', `Selected product(s) have been set inactive successfully! Unused are deleted!`);
        await this.$store.dispatch('products/getProductsBySiteID', this.siteID);
      } catch (error) {
        this.showAlert('error', `Failed to set product(s) inactive: ${error.message}`);
      } finally {
        this.closeInactiveDialog();
        this.$store.dispatch('hideLoader');
        this.isSingleItemDelete = false;
        this.usedProducts = [];
        this.linkedTemplatesProducts = [];
      }
    },

    async deleteConfirmed() {
      try {
        this.$store.dispatch('showLoader', { message: `Deleting product(s) in progress...` });
        if (this.isMassDeleteClicked) {
          await this.handleMassDelete();
        } else {
          await this.handleSingleDelete();
        }
      } catch (error) {
        this.showAlert('error', error);
        console.log('[deleteConfirmed] ERROR:', error);
      } finally {
        this.closeDeleteDialog();
        this.$store.dispatch('hideLoader');
      }
    },

    async handleMassDelete() {
      try {
        await this.$store.dispatch('products/massDeleteProducts', this.productsToDelete);
        this.setSnackbar('success', `Products [ ${this.massDeleteItems.join(', ')} ] were deleted successfully!`);

        await this.$store.dispatch('products/getProductsBySiteID', this.siteID);

        this.productsToDelete.forEach(product => this.$store.commit('products/removeProduct', product.product_firebase_id));
      } catch (error) {
        this.showAlert('error', `Failed to delete products: ${error.message}`);
      }
    },

    async handleSingleDelete() {
      await this.$store.dispatch('products/deleteProduct', { product_firebase_id: this.selectedProduct.product_firebase_id, siteID: this.siteID });
      this.setSnackbar('success', `Product [ ${this.selectedProduct.product_firebase_id} ] has been deleted successfully!`);
      this.$store.commit('products/removeProduct', this.selectedProduct.product_firebase_id);
    },

    closeDeleteDialog() {
      this.isDeleteDialogOpened = false;
      this.$store.commit('products/setSelectedProducts', []);
      this.$refs.dataTable.resetSelectedItems();
      this.selectedProduct = {};
      this.isMassDeleteClicked = false;
    },
    closeImportDialog() {
      this.isImportDialogOpened = false;
    },
    closeDuplicationDialog() {
      this.isDuplicationDialogOpened = false;
      this.confirmationDialogAction = '';
      this.selectedProduct = {};
    },
    cancelInactiveDialog() {
      this.usedProducts = [];
      this.linkedTemplatesProducts = [];
      this.isMassDeleteClicked = false;
      this.closeInactiveDialog();
    },
    closeInactiveDialog() {
      this.isInactiveDialogOpened = false;
      this.$store.commit('products/setSelectedProducts', []);
      this.$refs.dataTable.resetSelectedItems();
      this.selectedProduct = {};
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    },
    onProductSelect(selectedProducts) {
      this.$store.commit('products/setSelectedProducts', selectedProducts);
    },
    async syncProducts() {
      if (
        !confirm(
          'Are you sure you want to sync all products to SQL and Netsuite? This operation is only for developers and if done incorrectly it can cause serious financial troubles! '
        )
      ) {
        return;
      } else {
        if (!confirm('Are you really sure ?')) return;
        this.$store.dispatch('showLoader', { message: 'Sync in progress, do not leave this page' });
        try {
          const productsToSync = this.products.map(product => {
            const id = product.product_firebase_id;
            delete product.product_firebase_id;
            return { id, data: product };
          });
          for (let i = 0; i < productsToSync.length; i++) {
            productsToSync[i].data.netsuite_id = '';
            productService.update(productsToSync[i].id, productsToSync[i].data);
            await this.$store.dispatch('products/editProduct', productsToSync[i]);
            console.log(`Synced ${i + 1} out of ${productsToSync.length} [${productsToSync[i].id}] ... `);
          }
          this.setSnackbar('success', 'Sync done!');
        } catch (error) {
          console.error('Sync failed : ', error);
          this.showAlert('error', 'Sync failed, check console for more details');
        } finally {
          this.$store.dispatch('hideLoader');
        }
      }
    },
    getProductInformationChipColor(item) {
      return item.is_default ? 'blue darken-2' : 'grey darken-1';
    },
    getProductStatusChipColor(item) {
      return item.is_active ? 'success' : 'grey';
    },
    async exportProducts() {
      await this.$store.dispatch('products/exportProducts', { siteId: this.siteID });
    }
  }
};
</script>

<style scoped>
.text-white {
  color: white !important;
}
</style>
