import ProductModel from '../modules/product.js'
import ShippingModel from '../modules/shipping.js'

const quoteStore = {
	state: {
		defaultQuote: null,
		productModel: null,
		shippingModel: null,
		categories: null,
		siteForQuoteTool: null
	},
	getters: {
		getDefaultQuote (state) {
			return state.defaultQuote
		},
		getCategories (state) {
			return state.categories
		},
		getSiteForQuoteTool (state) {
			return state.siteForQuoteTool
		}
	},
	mutations: {
		setDefaultQuote (state, quote) {
			state.defaultQuote = quote
		},
		setCategories (state, categories) {
			state.categories = categories
		},
		setSiteForQuoteTool (state, site) {
			state.siteForQuoteTool = site
		}
	},
	actions: {
		initDefaultQuote ({commit, getters}, payload) {
			return commit('setDefaultQuote', {
        selectedCategory: '',
        selectedSize: '',
        products: '',
        selectedProductById: {},
        qty: '',
        quantityInt: '',
        productData: '',
        turnaroundOption: { label: '', value: 0 },
        stockOption: { label: '', value: 0 },
        coatingOption: { label: '', value: 0 },
        binderyOption: [],
        designOption: '',
        mailingService: { label: '', value: 0 },
        mailingServiceTabs: false,
        postage: { label: '', value: 0 },
        zipCode: '',
        shippingWeight: 0,
        shippingRates: '',
        shippingOption: [],
        discount: '',
        discountPrice: 0,
        total: 0
      })
		},
		getProductModelInstace (context) {
			if (context.state.productModel === null) {
				context.state.productModel = new ProductModel(context)
			}
			return context.state.productModel
		},
		getShippingModelInstace (context) {
			if (context.state.shippingModel === null) {
				context.state.shippingModel = new ShippingModel(context)
			}
			return context.state.shippingModel
		},
		loadCategories ({commit, dispatch}) {
			return dispatch('getProductModelInstace').then(model => {
				return model.loadCategories().then(result => {
					return commit('setCategories', result)
				})
			})
		},
		loadShippingRates ({commit, getters, dispatch}, quote) {
			const config = {
				product_id: quote.selectedSize.product_id,
				quantity: quote.quantityInt,
				order_type: 'template',
				shipping_weight: 0,
				location: {
					wlos_location_zipcode: quote.zipCode
				}
			}

			if (quote.productData.shipping_services.per_piece_weight !== undefined) {
				console.log('Shipping weight in lbs.: ' + quote.productData.shipping_services.per_piece_weight.value * quote.quantityInt)
				config.shipping_weight = quote.productData.shipping_services.per_piece_weight.value * quote.quantityInt
			}

			return dispatch('getShippingModelInstace').then(model => {
				return model.loadShippingRates(config)
			})
		},
		getProductsByCategory ({commit, dispatch}, id) {
			return dispatch('getProductModelInstace').then(model => {
				return model.getProductsByCategory(id)
			})
		},
		getProduct ({dispatch}, productId) {
			return dispatch('getProductModelInstace').then(model => {
				return model.getProduct(productId)
			})
		}
	}
}

export { quoteStore as default }
