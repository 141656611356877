<template>
  <v-toolbar flat class="site-table--header mb-n2">
    <v-toolbar-title class="body-2 mr-2">Status</v-toolbar-title>
    <v-chip-group v-model="selectedStatus" multiple active-class="primary--text">
      <template v-for="(statusName, index) in statusNames">
        <v-chip :key="index" filter outlined>
          <span>{{ capitalizeText(statusName) }}</span>
        </v-chip>
      </template>
    </v-chip-group>

    <v-btn fab x-small icon outlined color="grey lighten-2" :disabled="!selectedStatus" @click="clearSelectedStatus()">
      <v-icon color="grey">mdi-close</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { capitalize } from '@/modules/helpers/format';

export default {
  data() {
    return {
      statusNames: ['quote', 'incomplete', 'active', 'complete'],
      selectedStatus: ''
    };
  },
  watch: {
    selectedStatus(val) {
      const orderStatus = this.selectedStatus.map(index => this.statusNames[index]);
      this.$emit('onOrderStatusSelected', orderStatus);
    }
  },
  methods: {
    clearSelectedStatus() {
      this.selectedStatus = '';
      this.$emit('onStatusCleared');
    },
    capitalizeText(text) {
      return capitalize(text);
    }
  }
};
</script>
