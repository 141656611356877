import { dbFS } from '@/services/firebaseInit';
import productTypeService from '../../services/productTypeService.js';

const state = {
  productTypes: [],
  isProductTypesLoading: false,
  productTypeModel: [
    {
      label: 'Product Type Name',
      model: 'name',
      value: '',
      type: 'text',
      input_type: 'textfield',
      is_required: true,
      rule: [value => !!value || 'Product Type Name is required!']
    }
  ],
  selectedModel: []
};

const getters = {
  getProductTypes(state) {
    return state.productTypes;
  },
  getProductTypesLoadingState(state) {
    return state.isProductTypesLoading;
  },
  getSelectedModel(state) {
    return state.selectedModel;
  },
  getProductTypeModel(state) {
    return state.productTypeModel;
  }
};

const mutations = {
  clearProductTypes(state) {
    state.productTypes = [];
  },
  clearModel(state) {
    state.productTypeModel.map(model => (model.value = ''));
    state.selectedModel = [];
  },
  setProductTypes(state, payload) {
    state.productTypes = payload;
  },
  setSelectedModel(state, payload) {
    state.selectedModel = payload;
  },
  setProductTypesLoadingState(state, payload) {
    state.isProductTypesLoading = payload;
  }
};

const actions = {
  initSelectedModel({ commit, getters }, payload) {
    let productTypeData = getters.getProductTypeModel;

    if (payload) {
      productTypeData.forEach((modelProperty, index) => {
        if (payload[modelProperty.model]) {
          productTypeData[index].value = payload[modelProperty.model];
        }
      });
    }
    commit('setSelectedModel', productTypeData);
  },
  getAllProductTypes({ commit }, payload) {
    commit('setProductTypesLoadingState', true);
    return new Promise(resolve => {
      dbFS.collection('product_types').onSnapshot(productSnapshot => {
        commit('clearProductTypes');
        const productTypeData = productSnapshot.docs.map(product => {
          return { product_type_firebase_id: product.id, ...product.data() };
        });
        commit('setProductTypes', productTypeData);
        commit('setProductTypesLoadingState', false);
        resolve(productTypeData);
      });
    });
  },
  async getProductTypeById(_context, payload) {
    return productTypeService.getById(payload);
  },
  addNewProductType(_context, payload) {
    return productTypeService.add(payload);
  },
  editProductType(_context, payload) {
    return productTypeService.update(payload.id, payload.data);
  },
  deleteProductType(_context, payload) {
    return productTypeService.delete(payload.product_type_firebase_id);
  },
  async saveProductType({ commit, dispatch }, payload) {
    const functionName = payload.id ? 'editProductType' : 'addNewProductType';
    const result = await dispatch(functionName, payload);
    return { message: `Product Type saved with id: ${result}` };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
