import { dbFS, storage, storageRef } from '../../services/firebaseInit.js';
import siteSettingsService from '../../services/siteSettingsService';
import orderSettingsService from '../../services/orderSettingsService';
import algoliasearch from 'algoliasearch';

const state = {
  sitesRef: null,
  sites: [],
  selectedSite: null,
  selectedOrder: null,
  siteKey: null,
  siteInfo: null,
  orderProcessDetails: null,
  duplicateSite: null,
  searchedText: '',
  sitesAcceptedRoutes: '',
  searchClient: algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_API_KEY),
  algoliaAdminClient: algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_ADMIN_KEY),
  testSiteIds: [],
  templateCounts: {}
};

const getters = {
  getSitesRef(state) {
    return state.sitesRef;
  },
  getSites(state) {
    return state.sites;
  },
  getSelectedSite(state) {
    return state.selectedSite;
  },
  getSelectedOrder(state) {
    return state.selectedOrder;
  },
  getSiteKey(state) {
    return state.siteKey;
  },
  getSiteInfo(state) {
    return state.siteInfo;
  },
  getOrderProcessDetails(state) {
    return state.orderProcessDetails;
  },
  getDuplicateSite(state) {
    return state.duplicateSite;
  },
  getSearchedSite(state) {
    return state.searchedText;
  },
  getSitesAcceptedRoutes(state) {
    return state.sitesAcceptedRoutes;
  },
  getSearchClient(state) {
    return state.searchClient;
  },
  getAlgoliaAdminClient(state) {
    return state.algoliaAdminClient;
  },
  getTestSiteIds(state) {
    return state.testSiteIds;
  },
  getTemplateCounts(state) {
    return state.templateCounts;
  }
};

const mutations = {
  setSitesRef(state, ref) {
    state.sitesRef = ref;
  },
  setSites(state, list) {
    state.sites = list;
  },
  setSelectedSite(state, site) {
    state.selectedSite = site;
  },
  setSelectedOrder(state, order) {
    state.selectedOrder = order;
  },
  setSiteKey(state, payload) {
    state.siteKey = payload;
  },
  setSiteInfo(state, payload) {
    state.siteInfo = payload;
  },
  setOrderProcessDetails(state, payload) {
    state.orderProcessDetails = payload;
  },
  setDuplicateSite(state, payload) {
    state.duplicateSite = payload;
  },
  setSearchedSite(state, payload) {
    state.searchedText = payload;
  },
  setSitesAcceptedRoutes(state, payload) {
    state.sitesAcceptedRoutes = payload;
  },
  setTestSites(state, payload) {
    state.testSiteIds = payload;
  },
  setTemplateCounts(state, payload) {
    state.templateCounts = payload;
  }
};

const actions = {
  async createNewSite({ commit, dispatch }, { siteItem, duplicateSite }) {
    console.log('[ Create new site payload ]:', siteItem);
    console.log('[ Duplicated site origin ]:', duplicateSite);

    try {
      const siteKey = await siteSettingsService.add({ data: siteItem });
      console.log('[ Create new site has succeeded with firebase ID ]:', siteKey);
      commit('setSiteKey', siteKey);

      const siteUpdatePromises = [];
      siteUpdatePromises.push(
        siteSettingsService
          .update(siteKey, { firebase_id: siteKey })
          .then(() => Promise.resolve(siteKey))
          .catch(error => {
            console.log('Updating firebase site ID failed:', error);
            return Promise.reject(error);
          })
      );

      siteUpdatePromises.push(
        orderSettingsService.getById('default').then(orderSettings => {
          return orderSettingsService.set(siteKey, orderSettings, { merge: false }).then(() => {
            console.log(`Order settings for siteId: [ ${siteKey} ] successfully updated`);
            return Promise.resolve(orderSettings);
          });
        })
      );

      return Promise.all(siteUpdatePromises)
        .then(results => {
          console.log('Insert new site results:', results);
          dispatch('loadSitesFromDb', null, { root: true });
          return Promise.resolve(siteKey);
        })
        .catch(error => {
          console.log('Inserting new site failed:', error);
          return Promise.reject(error);
        });
    } catch (error) {
      console.log('Create new site has failed: ', error);
    }
  },
  async deleteImgFileByPath({ dispatch }, filePath) {
    try {
      const imgFile = await dispatch('getImageNameFromUrl', { path: filePath });
      if (imgFile) {
        let storageImgfRef = storage.ref('sites/' + imgFile.fileName);
        await storageImgfRef.delete();
        return;
      }
    } catch (error) {
      console.log(`Deleting the ${filePath} image file has failed: `, error);
      throw error;
    }
  },
  async deleteSite({ getters, dispatch }, payload) {
    const firebaseID = payload.deletedSite.firebase_id;
    const clientLogoImgPath = payload.deletedSite.client_logo;
    const stripeLogoImgPath = payload.deletedSite.stripe_logo;
    const headerLargeImgPath = payload.deletedSite.header_image_large;
    const headerSmallImgPath = payload.deletedSite.header_image_small;
    const algoliaClient = getters.getAlgoliaAdminClient;
    const envs = ['orders', 'maps', 'mailing_list', 'designs'];
    const promises = [];

    try {
      dispatch('loadSitesFromDb', null, { root: true });
      await siteSettingsService.delete(firebaseID);
      await orderSettingsService.delete(firebaseID);
      console.log('Delete site and orderSettings has succeeded: ');
      // delete Algolia indexes
      envs.forEach((element, index) => {
        const siteIndex = 'site_' + firebaseID + '_' + element;
        console.log('Site Index:', siteIndex, ' will be deleted!');
        promises.push(algoliaClient.deleteIndex(siteIndex));
      });

      await Promise.all(promises);
      console.log('All indexes deleted!');

      // TODO: DRY all 4 if conditions, script will stop at first return false
      if (clientLogoImgPath) {
        await dispatch('deleteImgFileByPath', clientLogoImgPath);
        console.log('clientLogoImg DELETED');
      }
      if (stripeLogoImgPath) {
        await dispatch('deleteImgFileByPath', stripeLogoImgPath);
        console.log('stripeLogoImg DELETED');
      }
      if (headerLargeImgPath) {
        await dispatch('deleteImgFileByPath', headerLargeImgPath);
        console.log('headerLargeImg DELETED');
      }
      if (headerSmallImgPath) {
        await dispatch('deleteImgFileByPath', headerSmallImgPath);
        console.log('headerSmallImgPath DELETED');
      }
    } catch (error) {
      console.log('Delete site has failed: ', error);
    }
  },
  getImageNameFromUrl({ commit }, payload) {
    const url = payload.path;
    const fields = url.split('%');
    if (fields.length < 2) {
      return null;
    }
    const firstSplit = fields[1];
    const secondSplit = firstSplit.split('?');
    const secondResult = secondSplit[0];
    const fileName = secondResult.replace('2F', '');
    if (fileName) {
      return { fileName: fileName };
    }
    return false;
  },
  async editSite({ commit, dispatch }, payload) {
    const siteObj = payload.siteItem;
    const siteKey = payload.siteKey;

    try {
      await siteSettingsService.set(siteKey, siteObj);
      console.log('Edit site has succeeded!');
      dispatch('loadSitesFromDb', null, { root: true });
      return siteObj.env_type;
    } catch (error) {
      console.log('Edit site has failed: ', error);
    }
  },
  async listSelectedSite({ commit, dispatch }, payload) {
    try {
      const siteKey = payload.siteKey;
      const siteData = await siteSettingsService.getById(siteKey);
      if (siteData) {
        return siteData;
      }
    } catch (error) {
      console.log('List selected site has failed: ', error);
    }
  },
  async addImageToStorage({ commit }, payload) {
    const file = payload.file;
    const fieldName = payload.fieldName;
    const time = payload.time;

    const filename = file.name;
    const filenameToInsert = `${filename.toLowerCase()}_${time}`;

    try {
      let storageImgfRef = storage.ref(`sites/${filenameToInsert}`);
      const snapshot = await storageImgfRef.put(file);
      const downloadUrl = await storageRef.child(`sites/${snapshot?.metadata?.name}`).getDownloadURL();
      return {
        downloadURL: downloadUrl,
        fieldName: fieldName,
        filename: filenameToInsert
      };
    } catch (error) {
      throw `Adding image to storage has failed: ${error}`;
    }
  },
  initTemplateCounts({ commit }) {
    try {
      return new Promise(resolve => {
        const sitesRef = dbFS.collection('sites');
        let processedSites = 0;
        const templateCounts = {};

        sitesRef.onSnapshot(snapshot => {
          snapshot.forEach(siteDoc => {
            const siteId = siteDoc.id;
            const templatesRef = siteDoc.ref.collection('templates');

            templatesRef.onSnapshot(templatesSnapshot => {
              templateCounts[siteId] = templatesSnapshot.size;
              processedSites++;

              if (processedSites === snapshot.size) {
                commit('setTemplateCounts', templateCounts);
                resolve(templateCounts);
              }
            });
          });
        });
      });
    } catch (error) {
      console.error('Error fetching template numbers:', error);
      throw error;
    }
  },
  getSiteDetailsInfo({ commit }, payload) {
    const orderSettingsKey = payload.key;
    return siteSettingsService
      .getById(orderSettingsKey)
      .then(data => {
        commit('setSiteInfo', {
          site_id: data.site_id,
          site_name: data.site_name
        });
        return {
          site_id: data.site_id,
          site_name: data.site_name
        };
      })
      .catch(error => {
        console.log('Error fetching site details:', error);
      });
  },
  async orderProcessUploadImage({ commit }, payload) {
    const file = payload.file;
    const firebaseId = payload.firebaseId;
    const filename = file.name;

    try {
      const siteSettings = await siteSettingsService.getById(firebaseId);
      const siteName = siteSettings.site_name.replace(/ /g, '_');
      const filenameToInsert = `${siteName}-${filename.toLowerCase()}`;
      const dirPath = `order-processes/${payload.orderProcessName}/`;
      const storageImgfRef = storage.ref(dirPath + filenameToInsert);
      const snapshot = await storageImgfRef.put(file);
      const downloadURL = await storageRef.child(dirPath + snapshot?.metadata?.name).getDownloadURL();
      return {
        downloadURL: downloadURL,
        filename: filenameToInsert
      };
    } catch (error) {
      console.error(`orderProcessUploadImage has failed: ${error}`);
      throw { error };
    }
  },
  orderProcessDetails({ commit }, payload) {
    return orderSettingsService
      .getById(payload.selectedSite)
      .then(({ orderProcesses }) => {
        if (orderProcesses[payload.selectedOrderName].orderProcessDetails) {
          commit('setOrderProcessDetails', orderProcesses[payload.selectedOrderName].orderProcessDetails);
        } else {
          const obj = {
            title: '',
            subtitle: '',
            buttonText: '',
            imageUrl: '',
            icon: '',
            imageName: ''
          };
          commit('setOrderProcessDetails', obj);
        }
      })
      .catch(error => {
        console.log('orderProcessDetails has failed', error);
      });
  },
  async deleteImage({ commit, getters }, payload) {
    const imageName = payload.filename;
    const orderProcessName = payload.orderProcessName;
    const firebaseId = payload.firebaseId;
    const path = `order-processes/${orderProcessName}/${imageName}`;

    try {
      const storageImgfRef = storage.ref(path);
      await storageImgfRef.delete();
      console.log('Order Process Image deleted from storage ');
      await orderSettingsService.update(firebaseId, {
        [`${firebaseId}.orderProcesses.${orderProcessName}.orderProcessDetails.imageUrl`]: '',
        [`${firebaseId}.orderProcesses.${orderProcessName}.orderProcessDetails.imageName`]: ''
      });
      console.log('Image Url Updated successfully!');
      return { deleted: true };
    } catch (error) {
      console.error('Error on delete order process image: ', error);
      throw { error };
    }
  },
  listObbopSites({ commit, dispatch }, payload) {
    let obbopList = [];
    return siteSettingsService.getAll({ isArray: true }).then(siteSettings => {
      siteSettings.forEach(siteSetting => {
        if (siteSetting.env_type === 'obopp') {
          obbopList.push(siteSetting);
        }
      });
      return obbopList;
    });
  },
  getOrderProcessOrderText({ commit }, payload) {
    try {
      return orderSettingsService.getById(payload.selectedSite).then(({ orderProcesses }) => orderProcesses?.[payload.selectedOrderName]?.orderText ?? []);
    } catch (error) {
      console.log('getOrderProcessOrderText has failed', error);
    }
  },
  updateOrderProcessOrderText({ commit }, payload) {
    console.log('[updateOrderProcessOrderText] payload', payload);
    return orderSettingsService.update(payload.selectedSite, {
      [`orderProcesses.${payload.selectedOrderName}.orderText.approvalDescription`]: payload.approvalDescription,
      [`orderProcesses.${payload.selectedOrderName}.orderText.approvalTitle`]: payload.approvalTitle,
      [`orderProcesses.${payload.selectedOrderName}.orderText.orderNameDescription`]: payload.orderNameDescription
    });
  },
  async getTestSites({ commit }, payload) {
    return siteSettingsService
      .getByProperty('site_type', 'test')
      .then(testSites => {
        let testSiteIds = [];
        if (Object.keys(testSites).length) {
          testSiteIds = Object.keys(testSites);
          commit('setTestSites', testSiteIds);
          return testSiteIds;
        }
        return testSiteIds;
      })
      .catch(error => {
        console.log('Getting test sites failed: ', error);
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
