<template>
  <div>
    <ConfirmationDialog
      :value="isDeleteDialogOpen"
      title="Confirmation required"
      text="Are you sure you want to delete the selected site?"
      @onClose="closeDialog"
      @onConfirm="confirmDelete"
    />
    <v-banner single-line @click:icon="openSiteTools">
      <v-icon v-if="isUserSuperAdmin" slot="icon" size="36" class="showPointerOnHover"> mdi-tools</v-icon>
      Manage Sites

      <template v-slot:actions>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense clearable class="mr-2"></v-text-field>
        <v-btn dark class="blue white--text" @click="onAddNewSite"><v-icon small>mdi-plus</v-icon> New Site</v-btn>
      </template>
    </v-banner>

    <div v-if="sites" class="site-table">
      <stamp-data-table
        :headers="headers"
        :data="sites"
        :actions="actions"
        :search="search"
        :is-items-per-page-disabled="true"
        sort-by="created_at"
        :sort-desc="true"
        item-key="firebase_id"
        @onManageSite="onManageSite"
        @onEditSite="onEditSite"
        @onManageProducts="onManageProducts"
        @onDuplicateSite="onDuplicateSite"
        @onDeleteSite="onDeleteSite"
      >
        <template #[`item.created_at`]="{ item }">{{ formatDate(item.created_at) }}</template>
        <template #[`item.firebase_id`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span class="cyan--text text--darken-3 showPointerOnHover" v-on="on" @click="copyItemToClipboard(item.firebase_id)">{{ item.firebase_id }}</span>
            </template>
            <span>Copy FirebaseID ( {{ item.firebase_id }} ) to clipboard</span>
          </v-tooltip>
        </template>
      </stamp-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from '@/components/common/DataTable.vue';
import ConfirmationDialog from '../dialog/ConfirmationDialog';

export default {
  name: 'manageSites',
  components: { 'stamp-data-table': DataTable, ConfirmationDialog },
  data() {
    return {
      sortBy: 'created_at',
      descending: true,
      itemsPerPage: 15,
      headers: [
        { text: 'Site ID', value: 'site_id' },
        { text: 'Site Type', value: 'site_type' },
        { text: 'Site Name', value: 'site_name' },
        { text: 'Site URL', value: 'site_url' },
        { text: 'Site DB ID', value: 'firebase_id', width: 220 },
        { text: 'Osprey API Endpoint', value: 'crm_api_endpoint' },
        { text: 'Creation Date', value: 'created_at', sortable: false }
      ],
      actions: [
        { icon: 'mdi-order-bool-descending', iconColor: 'blue darken-1', title: 'Manage Order Processes', eventName: 'onManageSite' },
        { icon: 'mdi-cog', iconColor: 'blue-grey darken-1', title: 'Edit Site Settings', eventName: 'onEditSite' },
        { icon: 'mdi-plus-box-multiple', iconColor: 'green darken-1', title: 'Manage Products', eventName: 'onManageProducts' },
        { icon: 'mdi-content-duplicate', iconColor: 'grey darken-1', title: 'Duplicate Site', eventName: 'onDuplicateSite' },
        { icon: 'mdi-delete-outline', iconColor: 'red darken-1', title: 'Delete Site', eventName: 'onDeleteSite' }
      ],
      search: '',
      isDeleteDialogOpen: false,
      deletedSite: null
    };
  },
  computed: {
    orderSettings() {
      return this.$store.getters.getOrderSettings;
    },
    ...mapGetters('site', {
      sites: 'getSites',
      searchText: 'getSearchedSite',
      siteRoutes: 'getSitesAcceptedRoutes'
    }),
    ...mapGetters('users', {
      userAccessLevel: 'userAccessLevel'
    }),
    isUserSuperAdmin() {
      return this.userAccessLevel && parseInt(this.userAccessLevel.value) === 100;
    }
  },
  watch: {
    search(value) {
      this.$store.commit('site/setSearchedSite', value || '');
    }
  },
  async created() {
    await this.$store.dispatch('loadSitesFromDb');
    this.search = this.searchText?.length && (this.siteRoutes === 'siteProcess' || this.siteRoutes === 'orderProcesses') ? this.searchText : '';
    this.$store.commit('site/setSitesAcceptedRoutes', this.$options.name);
  },
  methods: {
    onManageSite({ firebase_id }) {
      console.log('Selected Site: ', firebase_id);
      this.$store.commit('site/setSelectedSite', firebase_id);
      this.$router.push({ path: `/orders-process/${firebase_id}` });
      this.drawer = false;
    },
    onAddNewSite() {
      this.$router.push({ path: `/sites/add` });
    },
    onEditSite({ firebase_id }) {
      this.$router.push({ path: `/sites/edit/${firebase_id}` });
    },
    onManageProducts(site) {
      this.$router.push({ path: `/products/sites/${site.firebase_id}` });
    },
    onDeleteSite(site) {
      this.deletedSite = site;
      this.isDeleteDialogOpen = true;
    },
    onDuplicateSite(site) {
      this.$store.commit('site/setDuplicateSite', site);
      this.$router.push({ path: `/sites/add` });
    },
    closeDialog() {
      this.isDeleteDialogOpen = false;
    },
    async confirmDelete() {
      this.isDeleteDialogOpen = false;
      this.$store.dispatch('showLoader', { message: 'Deleting site in progress...' });
      try {
        await this.$store.dispatch('site/deleteSite', { deletedSite: this.deletedSite });
        await this.$store.dispatch('loadSitesFromDb');
        this.$store.dispatch('showAlert', { type: 'success', message: 'Site deleted successfully!' });
      } catch (error) {
        console.error('[delete site error] : ', error);
        this.$store.dispatch('showAlert', { type: 'error', message: 'Something went wrong while deleting the site.' });
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    formatDate(dateTotransform) {
      const date = new Date(dateTotransform);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    openSiteTools() {
      this.$router.push({ path: `/sites/sites-tools` });
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    }
  }
};
</script>

<style scoped>
.showPointerOnHover {
  cursor: pointer;
}
</style>
