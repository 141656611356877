import siteSettingsService from '../../services/siteSettingsService';
import templateService from '../../services/templateService';
import { dbFS, storageRef } from '@/services/firebaseInit';
import templateCategoryService from '../../services/templateCategoryService';
import templateTypeService from '../../services/templateTypeService';
import productService from '../../services/productService';
import stampSettingsService from '../../services/stampSettingsService';
import httpClient from '@/modules/httpModule';

const state = {
  templates: [],
  isTemplatesLoading: false,
  currentTemplate: {},
  selectedTemplates: [],
  templateCounts: {},
  siteId: '',
  csvImports: '',
  importTemplateObject: {},
  templateCategories: {},
  templateTypes: {},
  designLivePaths: []
};

const getters = {
  getTemplates(state) {
    return state.templates;
  },
  getTemplatesLoadingState(state) {
    return state.isTemplatesLoading;
  },
  getCurrentTemplate(state) {
    return state.currentTemplate;
  },
  getSelectedTemplates(state) {
    return state.selectedTemplates;
  },
  getTemplateCounts(state) {
    return state.templateCounts;
  },
  getSiteId(state) {
    return state.siteId;
  },
  geCsvImports(state) {
    return state.csvImports;
  },
  getTemplateCategories(state) {
    return state.templateCategories;
  },
  getTemplateTypes(state) {
    return state.templateTypes;
  },
  getDesignLivePaths() {
    return state.designLivePaths;
  }
};

const mutations = {
  setTemplates(state, payload) {
    state.templates = payload;
  },
  setTemplatesLoadingState(state, payload) {
    state.isTemplatesLoading = payload;
  },
  setCurrentTemplate(state, payload) {
    state.currentTemplate = payload;
  },
  setSelectedTemplates(state, payload) {
    state.selectedTemplates = payload;
  },
  setTemplateCounts(state, payload) {
    state.templateCounts = payload;
  },
  setSiteId(state, payload) {
    state.siteId = payload;
  },
  setCsvImports(state, payload) {
    state.csvImports = payload;
  },
  setTemplateCategories(state, payload) {
    state.templateCategories = payload;
  },
  setTemplateTypes(state, payload) {
    state.templateTypes = payload;
  },
  setDesignLivePaths(state, payload) {
    state.designLivePaths = payload;
  },
  addTemplateType(state, { id, name }) {
    state.templateTypes[id] = { name };
  },
  addTemplateCategory(state, { id, name }) {
    state.templateCategories[id] = { name };
  },
  addToDesignList(state, element) {
    state.designLivePaths.push(element);
  }
};

const actions = {
  initTemplates({ commit, getters }, payload) {
    commit('setTemplatesLoadingState', true);
    let collectionRoute = '';
    if (payload?.isImport) {
      collectionRoute = 'templates';
    } else {
      collectionRoute = getters.getSiteId === 'default' ? `templates` : `sites/${payload.siteId}/templates`;
    }
    try {
      return new Promise(resolve => {
        dbFS.collection(collectionRoute).onSnapshot(templateSnapshot => {
          commit('setTemplates', []);
          const templateData = templateSnapshot.docs.map(template => {
            return { template_firebase_id: template.id, ...template.data() };
          });
          commit('setTemplates', templateData);
          commit('setTemplatesLoadingState', false);
          resolve(templateData);
        });
      });
    } catch (error) {
      console.error('Error fetching templates:', error);
      throw error;
    }
  },

  initTemplateCounts({ commit }) {
    try {
      return new Promise(resolve => {
        dbFS.collection('templates').onSnapshot(templateSnapshot => {
          const templateCounts = {};
          templateSnapshot.forEach(template => {
            const siteId = template.data().site_id;
            if (templateCounts[siteId]) {
              templateCounts[siteId]++;
            } else {
              templateCounts[siteId] = 1;
            }
          });
          commit('setTemplateCounts', templateCounts);
          resolve(templateCounts);
        });
      });
    } catch (error) {
      console.error('Error fetching template numbers:', error);
      throw error;
    }
  },

  async importTemplates({ dispatch }, payload) {
    try {
      const templatePromises = payload.data.map(async templateData => {
        templateData.is_default = true;
        return await siteSettingsService.set(`${payload.siteId}/templates/${templateData.template_firebase_id}`, templateData);
      });

      const savedTemplates = await Promise.all(templatePromises);
      console.log('[importTemplates] templates saved successfully: ', savedTemplates);
    } catch (error) {
      console.error('[importTemplates] error saving templates in firestore: ', error);
    }
  },

  async getTemplateUploadExample() {
    try {
      const { template_upload_example } = await stampSettingsService.getById('templates');
      return template_upload_example;
    } catch (error) {
      console.error('[getTemplateUploadExample] error getting exaple csv: ', error);
    }
  },

  readFileSync(_context, file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsText(file);
    });
  },

  async uploadTemplatesFromCsv({ dispatch }, payload) {
    if (!(payload.file instanceof Blob)) {
      console.error('Invalid file type. Expected a Blob.');
      return;
    }

    try {
      const csvData = await dispatch('readFileSync', payload.file);
      const objectsArray = await dispatch('parseCsvToObjectArray', csvData);

      if (!objectsArray || objectsArray.length === 0) {
        console.error('No data found in CSV file.');
        return;
      }

      const templateObjectsArray = await dispatch('parseToTemplateObject', objectsArray);

      if (!templateObjectsArray || templateObjectsArray.length === 0) {
        console.error('Failed to parse CSV data into template objects.');
        return;
      }

      console.log('Templates being imported: ', templateObjectsArray);
      for (const templateObject of templateObjectsArray) {
        await dispatch('addTemplate', { data: templateObject });
      }
    } catch (error) {
      throw new Error(`Could not import the templates to Firebase. ${error}`);
    }
  },

  async uploadTemplatesFromUrl({ dispatch }, payload) {
    try {
      const response = await fetch(payload.url);
      if (!response.ok) {
        throw new Error('Failed to fetch CSV file');
      }

      const csvData = await response.text();
      const objectsArray = await dispatch('parseCsvToObjectArray', csvData);

      if (!objectsArray || objectsArray.length === 0) {
        console.error('No data found in CSV file.');
        return;
      }

      const templateObjectsArray = await dispatch('parseToTemplateObject', objectsArray);

      if (!templateObjectsArray || templateObjectsArray.length === 0) {
        console.error('Failed to parse CSV data into template objects.');
        return;
      }

      console.log('Templates being imported: ', templateObjectsArray);
      for (const templateObject of templateObjectsArray) {
        await dispatch('addTemplate', { data: templateObject });
      }
    } catch (error) {
      throw new Error(`Could not import the templates to Firebase. ${error}`);
    }
  },

  parseCsvToObjectArray({ commit }, csvData) {
    return new Promise(resolve => {
      const lines = csvData.split('\n');
      const headers = lines[0].trim().split(',');
      const objectsArray = lines.slice(1).map(line => {
        let values = [];
        let insideQuotes = false;
        let currentValue = '';

        for (let i = 0; i < line.length; i++) {
          const char = line[i];
          if (char === '"') {
            insideQuotes = !insideQuotes;
          } else if (char === ',' && !insideQuotes) {
            values.push(currentValue.trim());
            currentValue = '';
          } else {
            currentValue = `${currentValue}${char}`;
          }
        }

        values.push(currentValue.trim());
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = values[index];
        });
        return obj;
      });

      resolve(objectsArray);
    });
  },

  async parseToTemplateObject({ dispatch, commit, getters }, templateArray) {
    try {
      commit('setTemplateCategories', await templateCategoryService.getAll());
      commit('setTemplateTypes', await templateTypeService.getAll());
      const siteId = getters.getSiteId;

      const templateObjectsArray = [];
      for (const csvTemplateObj of templateArray) {
        if (
          !csvTemplateObj['Linked Product'] ||
          !csvTemplateObj['OneBrand Template ID'] ||
          !csvTemplateObj['Design Live Slug Front'] ||
          !csvTemplateObj['Design Live Slug Back'] ||
          !csvTemplateObj['Type'] ||
          !csvTemplateObj['Image Link 1 (BACK)'] ||
          !csvTemplateObj['Image Link 1 (FRONT)'] ||
          !csvTemplateObj['Order Type']
        ) {
          throw new Error('Required fields are missing.');
        }

        const linkedProduct =
          siteId === 'default'
            ? productService.getById(csvTemplateObj['Linked Product'])
            : await dispatch('products/getCustomProductByID', { siteID: siteId, productID: csvTemplateObj['Linked Product'] }, { root: true });

        if (!linkedProduct) {
          throw new Error(`One of the liked products isn't present on the site.`);
        }

        const templateType = await dispatch('getTemplateType', csvTemplateObj['Type']);

        let templateTypeId = '';

        if (!templateType) {
          templateTypeId = await dispatch('template_types/addNewTemplateType', { id: '', data: { name: csvTemplateObj['Type'] } }, { root: true });
          await commit('addTemplateType', { id: templateTypeId, name: csvTemplateObj['Type'] });
          console.log(`Template type added: ${csvTemplateObj['Type']}`);
        }

        const templateCategoryNamesArray = csvTemplateObj['Category'].split(',').map(category => category.trim());

        const templateCategoryArray = [];
        if (templateCategoryNamesArray[0] !== '') {
          for (const templateCategoryName of templateCategoryNamesArray) {
            const templateCategory = await dispatch('getTemplateCategory', templateCategoryName);

            let templateCategoryId = '';
            if (!templateCategory) {
              templateCategoryId = await dispatch('template_categories/addNewTemplateCategory', { id: '', data: { name: templateCategoryName, description: '' } }, { root: true });
              commit('addTemplateCategory', { id: templateCategoryId, name: templateCategoryName });
              templateCategoryArray.push({ id: templateCategoryId, name: templateCategoryName });
              console.log(`Template type added: ${csvTemplateObj['Category']}`);
            } else {
              templateCategoryArray.push(templateCategory);
            }
          }
        }

        const orderType = csvTemplateObj['Order Type'];
        const templateName = csvTemplateObj['OneBrand Template ID'];

        templateObjectsArray.push({
          category: templateCategoryArray,
          dl_template_back: csvTemplateObj['Design Live Slug Back'],
          dl_template_front: csvTemplateObj['Design Live Slug Front'],
          edited_at: await dispatch('products/getCurrentDate', null, { root: true }),
          is_availability: orderType === 'availability',
          is_custom: orderType === 'custom',
          is_customQuote: orderType === 'customQuote',
          is_eddm: orderType === 'eddm',
          is_eddmPrintOnly: orderType === 'eddmPrintOnly',
          is_inserts: orderType === 'inserts',
          is_map: orderType === 'map',
          is_newMovers: orderType === 'newMovers',
          is_printprepship: orderType === 'printprepship',
          is_saturation: orderType === 'saturation',
          is_targeted: orderType === 'targeted',
          is_targetedLetter: orderType === 'targetedLetter',
          is_template: orderType === 'template',
          linked_product: csvTemplateObj['Linked Product'],
          name: templateName,
          onebrand_id: parseInt(templateName.charAt(0) === '(' ? templateName.substr(1, 4) : templateName.substr(0, 4)),
          preview_back_url: csvTemplateObj['Image Link 1 (BACK)'],
          preview_front_url: csvTemplateObj['Image Link 1 (FRONT)'],
          template_download_url: csvTemplateObj['Template URL'],
          type: templateType || { id: templateTypeId, name: csvTemplateObj['Type'] }
        });
      }

      return templateObjectsArray;
    } catch (error) {
      throw `${error.response?.data?.message || error.message || ''}`;
    }
  },

  async getTemplateCategory({ getters }, categoryName) {
    const categories = getters.getTemplateCategories;
    const resultId = Object.keys(categories).find(id => categories[id].name === categoryName);
    return resultId ? { id: resultId, name: categoryName } : null;
  },

  async getTemplateType({ getters }, typeName) {
    const templates = getters.getTemplateTypes;
    const resultId = Object.keys(templates).find(id => templates[id].name === typeName);
    return resultId ? { id: resultId, name: typeName } : null;
  },

  async getDuplicatedTemplates({ dispatch, getters }, payload) {
    try {
      const selectedTemplates = payload.data;

      const existingTemplates = await siteSettingsService.getTemplatesBySiteID(payload.siteId);

      const productMap = new Map();

      selectedTemplates.forEach(template => {
        const templateId = template.template_firebase_id;
        productMap.set(templateId, template);
      });

      const duplicatedTemplates = existingTemplates.filter(template => {
        const templateId = template.template_firebase_id;
        return productMap.has(templateId);
      });

      return duplicatedTemplates;
    } catch (error) {
      console.log('Error getting duplicated templates: ', error);
    }
  },

  getTemplateByID({ getters }, payload) {
    const siteId = getters.getSiteId;
    return siteId === 'default' ? templateService.getById(payload) : siteSettingsService.getById(`${siteId}/templates/${payload}`);
  },

  async uploadTemplateImage(_context, payload) {
    const fileName = `${payload.name.replaceAll(' ', '_')}_${Date.now()}`;
    const fileRef = storageRef.child(`templates/test/${fileName}`);
    try {
      const snapshot = await fileRef.put(payload.file);
      return snapshot.ref.getDownloadURL();
    } catch (error) {
      console.error('[uploadTemplateImage error]: ', error);
      throw new Error(`Could not save the image to Firebase storage. ${error.response?.data?.message || error.message || ''}`);
    }
  },

  async uploadDesignLiveImage({ getters, commit }, payload) {
    if (!payload.file || !payload.field_name) {
      throw new Error('Payload must contain file, and field name.');
    }
    try {
      const timestamp = Date.now();
      const filename = `${timestamp}-${payload.field_name === 'front_path' ? 'front' : 'back'}-${payload.file.name}`;
      const newPathSegment = payload.newFolderName ? `/${payload.newFolderName}` : '';
      const selectedPathSegment = payload.selectedPath ? `/${payload.selectedPath}` : '';
      const path = `${selectedPathSegment}${newPathSegment}`;

      const formData = new FormData();
      formData.append(payload.field_name, payload.file, filename);

      const response = await httpClient.post({
        url: `${process.env.VUE_APP_DESIGN_LIVE_BASE_URL}/bbb${path}`,
        headers: { 'X-CustomersCanvasAPIKey': process.env.VUE_APP_DESIGN_LIVE_API_KEY },
        data: formData
      });

      const newPath = response.data.substring(response.data.indexOf('/') + 1);
      console.log('Upload successful:', newPath);
      commit('addToDesignList', newPath);
      return newPath;
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  },

  async getSubfolderContent({ getters, commit }) {
    try {
      const response = await httpClient.get({
        url: `${process.env.VUE_APP_DESIGN_LIVE_BASE_URL}`,
        params: {
          subfolder: 'bbb'
        },
        headers: { 'X-CustomersCanvasAPIKey': process.env.VUE_APP_DESIGN_LIVE_API_KEY }
      });
      commit('setDesignLivePaths', response.data);
      return response.data;
    } catch (error) {
      console.error('Error retrieving subfolder contents:', error);
      throw error;
    }
  },

  addTemplate({ getters }, payload) {
    try {
      const siteId = getters.getSiteId;
      return siteId === 'default' ? templateService.add(payload) : siteSettingsService.addTemplateToSite(siteId, payload.data);
    } catch (error) {
      console.error('[addTemplate error]: ', error);
    }
  },

  async editTemplate({ getters, dispatch }, payload) {
    try {
      const siteId = getters.getSiteId;
      if (siteId === 'default') {
        await templateService.update(payload.id, payload.data);
        await dispatch('updateAllDefaultTemplateInformationByID', { template_firebase_id: payload.id, ...payload.data });
      } else {
        payload.data.is_default = false;
        await siteSettingsService.update(`${siteId}/templates/${payload.id}`, payload.data);
      }
    } catch (error) {
      console.error('[editTemplate error]: ', error);
    }
    return payload.id;
  },

  deleteTemplate({ getters }, payload) {
    try {
      const siteId = getters.getSiteId;
      return siteId === 'default' ? templateService.delete(payload.key) : siteSettingsService.delete(`${siteId}/templates/${payload.key}`);
    } catch (error) {
      console.error('[editTemplate error]: ', error);
    }
  },

  async updateAllDefaultTemplateInformationByID(_context, payload) {
    console.log('[updateAllDefaultTemplateInformationByID]: ', payload);
    try {
      const templateId = payload?.template_firebase_id;
      const sitesRef = dbFS.collection('sites');
      let processedSites = 0;

      const querySnapshot = await sitesRef.get();

      querySnapshot.forEach(async siteDoc => {
        try {
          const siteId = siteDoc.id;
          const templatesRef = siteDoc.ref.collection('templates');
          console.log('Processing site:', siteId);

          const templatesSnapshot = await templatesRef.where('template_firebase_id', '==', templateId).where('is_default', '==', true).get();

          templatesSnapshot.forEach(templateDoc => {
            console.log('Updating template information in site:', siteId);
            templateDoc.ref.update(payload);
          });

          processedSites++;
          if (processedSites === querySnapshot.size) {
            console.log('All sites processed for template information.');
          }
        } catch (error) {
          console.error('Error processing site:', siteDoc.id, error);
        }
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
