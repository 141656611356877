<template>
  <v-container v-if="isImportOptionsVisible" fluid>
    <map-select v-if="isSelectMapDialogOpened" :selectMapDialog="isSelectMapDialogOpened" @selectedMapId="setSelectedMapId" @closeDialog="closeMapSelectDialog()"> </map-select>

    <v-row v-if="isUploadInputVisible" class="justify-space-around" transition="fade-transition">
      <v-col cols="12" md="5">
        <v-row class="justify-center">
          <span class="headline">Upload a Mailing List</span>
        </v-row>
        <v-row class="justify-center mt-5">
          <v-col cols="12" xl="7">
            <file-pond
              name="mailing_list"
              label-idle="Drop file here or click to upload"
              accepted-file-types=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              allow-multiple="false"
              allowFileTypeValidation="false"
              allowImageTransform="false"
              :server="{ process }"
            />
          </v-col>
        </v-row>
      </v-col>
      <template v-if="!isTargetedOrder">
        <v-divider vertical></v-divider>
        <v-col cols="12" md="5">
          <v-row class="justify-center">
            <span class="headline">Select an Existing Map</span>
          </v-row>
          <v-row class="justify-center mt-7">
            <v-btn color="info" @click="openSelectMapDialog()">Search Map</v-btn>
          </v-row>
        </v-col>
      </template>
    </v-row>

    <v-row v-if="isMailingListTableVisible" class="justify-center" no-gutters>
      <v-col cols="12">
        <v-row class="justify-space-around mx-5 mb-5 mt-n2">
          <v-divider />
          <span class="headline mx-5 mt-n4">Preview of your uploaded list</span>
          <v-divider />
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-alert v-if="uploadedListContent.length" color="#DBE3D3" class="text-center">
              <b class="mr-1">{{ addresses }} </b> addresses found.
              <span v-if="uploadedListContent.length !== addresses">
                This is a preview of the first <b>{{ uploadedListContent.length }}</b> rows from the uploaded file
              </span>
            </v-alert>
          </v-col>
        </v-row>
        <div class="site-table">
          <stamp-data-table
            :headers="uploadedListHeaders"
            :data="uploadedListContent"
            item-key="address"
            custom-class="elevation-2 font-weight-regular body-2 preview-table-width"
          />
        </div>
        <v-row class="justify-space-around mt-2">
          <v-col cols="12" sm="5" class="text-end">
            <v-btn color="primary" @click="uploadAnotherList()"><v-icon class="mr-2">mdi-chevron-left</v-icon>Upload another list</v-btn>
          </v-col>
          <v-col cols="12" sm="5">
            <v-btn color="primary" @click="processUploadedList()">Process My List<v-icon class="ml-3">mdi-chevron-right</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <template v-if="!isSelectedMapEmpty">
      <v-row class="justify-center mb-4">
        <span class="headline font-weight-regular">Selected Map</span>
      </v-row>
      <v-row class="justify-center mb-5" transition="fade-transition">
        <v-col cols="12" md="5">
          <v-row class="justify-end">
            <span class="title font-weight-regular">{{ selectedMapData.orderType }} Map</span>
          </v-row>
          <v-row class="justify-end">
            <span class="title font-weight-regular primary--text">Total count: {{ selectedMapData.totalCount }}</span>
          </v-row>
        </v-col>
        <v-divider vertical class="mx-10"></v-divider>
        <v-col cols="12" md="5">
          <v-row>
            <span class="title font-weight-regular blue-grey--text text--darken-2">{{ selectedMapData.name }}</span>
          </v-row>
          <v-row>
            <span class="subtitle-1 secondary--text text--darken-3 font-weight-regular">
              <span class="grey--text text--darken-2">Customer Email: </span>{{ selectedMapData.customer.email }}</span
            >
          </v-row>
          <v-row class="subtitle-1 secondary--text text--darken-3 font-weight-regular">
            <span class="subtitle-1">Created: {{ this.$moment(selectedMapData.creationDateTimestamp * 1000).format('MMMM DD, YYYY') }}</span>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="justify-center mt-8 mb-n5">
        <v-btn color="primary" class="ml-n12" @click="selectAnotherList()"><v-icon class="ml-n2 mr-1">mdi-chevron-left</v-icon>Select Another</v-btn>
        <v-btn color="primary" class="ml-6" @click="finishMapSelect()">Continue<v-icon class="ml-1 mr-n2">mdi-chevron-right</v-icon></v-btn>
      </v-row>
    </template>

    <template v-if="isListUploaded">
      <div class="mt-n12">
        <v-alert v-if="isNameFieldErrorVisible" type="error" color="orange darken-3" dismissible>
          <span>The <b>[ Business Name ]</b> or the <b>[ First Name ]</b> is required!</span>
        </v-alert>
        <v-row class="justify-center my-2">
          <span class="title font-weight-regular">Adjust your list columns to required headers</span>
        </v-row>
      </div>
      <v-row class="justify-space-around mt-2 mb-3">
        <v-col cols="12" md="8" lg="5" xl="5">
          <v-row v-for="(header, headerIndex) in requiredHeaders" :key="headerIndex" class="justify-space-around mb-n10">
            <v-col cols="12" sm="6" xl="5">
              <v-select v-model="adjustedHeaders[headerIndex]" :items="fileHeaders" dense outlined clearable class="subtitle-1" @click:clear="clearHeader(headerIndex)"></v-select>
            </v-col>
            <v-col cols="1">
              <v-icon class="mt-2">mdi-chevron-right</v-icon>
            </v-col>
            <v-col cols="8" sm="3" md="4" lg="4" xl="3" class="pt-4 pl-5">
              <span class="subtitle-1 font-weight-regular grey--text text--darken-3">{{ formatAddress(header) }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div v-if="isCustomFieldMapperVisible">
        <v-row class="justify-center my-2">
          <span class="title font-weight-regular">Adjust your list columns to custom headers</span>
        </v-row>
      </div>
      <v-row v-if="isCustomFieldMapperVisible" class="justify-space-around mt-2 mb-3">
        <v-col cols="12" md="8" lg="5" xl="5">
          <v-row v-for="(header, headerIndex) in customHeaders" :key="headerIndex" class="justify-space-around mb-n10">
            <v-col cols="12" sm="6" xl="5">
              <v-select
                v-model="adjustedCustomHeaders[headerIndex]"
                :items="fileHeaders"
                dense
                outlined
                clearable
                class="subtitle-1"
                @click:clear="clearHeader(headerIndex)"
              ></v-select>
            </v-col>
            <v-col cols="1">
              <v-icon class="mt-2">mdi-chevron-right</v-icon>
            </v-col>
            <v-col cols="8" sm="3" md="4" lg="4" xl="3" class="pt-4 pl-5">
              <span class="subtitle-1 font-weight-regular grey--text text--darken-3">{{ formatAddress(header) }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <v-row v-if="isFinalStepAllowed" class="justify-center mt-6 mb-n5">
      <v-btn color="primary" class="mr-2" @click="closeHeaderMapSelection()"><v-icon class="mr-2">mdi-chevron-left</v-icon>Back</v-btn>
      <v-btn color="primary" :loading="isFileUploadLoading" :disabled="isContinueButtonDisabled" class="ml-2" @click="finishImport()">
        Continue<v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import vueFilePond from 'vue-filepond'; // { setOptions }
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import MapSelect from './DmmMapSelector.vue';
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);
import DataTable from '@/components/common/DataTable.vue';
import { mapGetters } from 'vuex';
import mapsService from '../../../services/mapsService';

export default {
  components: {
    FilePond,
    'map-select': MapSelect,
    'stamp-data-table': DataTable
  },
  props: ['isImportOptionsVisible', 'orderId', 'mailDropId', 'orderType', 'initialList', 'isCustomFieldsEnabled'],
  data() {
    return {
      queryParams: {},
      addresses: 0,
      csvLink: '',
      uploadedFile: null,
      isMailingListTableVisible: false,
      fileHeaders: [],
      adjustedHeaders: [],
      adjustedCustomHeaders: [],
      basicHeaders: ['business_name', 'first_name', 'last_name', 'address', 'address2', 'city', 'state', 'zip'],
      requiredHeaders: ['business_name', 'first_name', 'last_name', 'address_line1', 'address_line2', 'address_city', 'address_state', 'address_zip'],
      uploadedListHeaders: [],
      uploadedListContent: [],
      isSelectMapDialogOpened: false,
      selectedMapData: {},
      isCountsLoading: false,
      isFileUploadLoading: false,
      isFinalStepAllowed: false
    };
  },
  computed: {
    ...mapGetters('custom_fields', {
      customFields: 'getDmmCustomFields'
    }),
    customHeaders() {
      return this.customFields.length ? this.customFields.map(({ merge_tag }) => merge_tag) : [];
    },
    isCustomFieldMapperVisible() {
      return this.isCustomFieldsEnabled && this.customFields.length;
    },
    isUploadInputVisible() {
      return !this.isMailingListTableVisible && !this.uploadedListContent.length && this.isSelectedMapEmpty;
    },
    isTargetedOrder() {
      return this.orderType === 'targeted';
    },
    isSelectedMapEmpty() {
      return !Object.keys(this.selectedMapData).length;
    },
    isListUploaded() {
      return !this.csvLink && !this.isMailingListTableVisible && this.uploadedListContent.length;
    },
    isNameFieldErrorVisible() {
      const selectedHeaders = {};
      for (let index in this.requiredHeaders) {
        Object.assign(selectedHeaders, {
          [this.requiredHeaders[index]]: this.getHeaderIndexes(this.adjustedHeaders)[index]
        });
      }
      const businessName = selectedHeaders?.business_name?.toString();
      const firstName = selectedHeaders?.first_name?.toString();

      return !businessName && !firstName;
    },
    isContinueButtonDisabled() {
      return !Object.values(this.adjustedHeaders).filter(header => header !== '').length || this.isNameFieldErrorVisible;
    }
  },
  async mounted() {
    this.queryParams = this.$route.query;
    if (!this.initialList?.url) {
      return;
    }
    const blob = await fetch(this.initialList.url).then(res => res.blob());
    blob.name = this.initialList.name;
    this.process('', blob);
  },
  methods: {
    uploadAnotherList() {
      this.uploadedFile = {};
      this.uploadedListHeaders = [];
      this.uploadedListContent = [];
      this.isMailingListTableVisible = false;
      this.addresses = 0;
    },
    async setSelectedMapId(mapObj) {
      try {
        console.log('[ Selected Map ]:', mapObj);
        this.selectedMapData = mapObj;
        const response = await mapsService.getMapCsvLink(mapObj.firebase_map_id);
        if (response.status === 'success') {
          console.log('getMapCsvLink:', response);
          this.csvLink = response.csvLink;
        }
      } catch (error) {
        console.error('setSelectedMapId Error: ', error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    openSelectMapDialog() {
      this.isSelectMapDialogOpened = true;
    },
    closeMapSelectDialog() {
      this.isSelectMapDialogOpened = false;
    },
    selectAnotherList() {
      this.selectedMapData = {};
      this.isSelectMapDialogOpened = true;
    },
    finishMapSelect() {
      this.$emit('selectedCsvLink', this.csvLink);
      this.$emit('fileUploadFinished');
    },
    async finishImport() {
      const headerMaps = {};
      const headerIndexes = this.getHeaderIndexes(this.adjustedHeaders);

      const customHeaderMaps = {};
      const customHeaderIndexes = this.getHeaderIndexes(this.adjustedCustomHeaders);

      for (let index in this.requiredHeaders) {
        Object.assign(headerMaps, { [this.requiredHeaders[index]]: headerIndexes[index] });
      }

      for (let index in this.customHeaders) {
        Object.assign(customHeaderMaps, { [this.customHeaders[index]]: customHeaderIndexes[index] });
      }
      if (!(headerMaps.first_name || headerMaps.first_name === 0 || headerMaps.business_name || headerMaps.business_name === 0)) {
        this.$emit('notification', { type: 'error', message: 'First Name or Business Name must be specified!' });
        return;
      }
      try {
        this.isFileUploadLoading = true;
        const mailingListFile = {
          file: this.uploadedFile,
          fieldName: 'mailing_list',
          campaignName: `${this.orderId ?? 'customlist'}_${this.$isProductionEnv ? 'live' : 'test'}`,
          subFolderName: `${this.orderId ?? 'artwork'}${this.mailDropId ? `_${this.mailDropId}` : ''}_${this.$moment().format('DD_MM_YYYY')}`
        };
        const data = await this.$store.dispatch('customer_messaging/addImage', mailingListFile);
        const uploadedMailingListData = {
          url: data.downloadURL,
          headers: {
            fields: headerMaps,
            custom_fields: customHeaderMaps
          },
          totalCount: this.addresses
        };
        this.$emit('downloadURL', uploadedMailingListData);
        this.$emit('fileUploadFinished');
      } catch (error) {
        console.error('Uploading file to storage failed:', error);
        this.$emit('notification', { type: 'error', message: error });
      } finally {
        this.isFileUploadLoading = false;
      }
    },
    formatAddress(addressTitle) {
      const splitted = addressTitle.split('_');
      let formatted = '';
      splitted.forEach((chunk, index) => {
        if (chunk !== this.queryParams.crm_customer_id) {
          formatted += `${index > 0 ? ' ' : ''}${chunk.charAt(0).toUpperCase()}${chunk.slice(1)}`;
        }
      });
      return formatted;
    },
    getHeaderIndexes(headers) {
      return headers.map(header => {
        const headerIndex = this.fileHeaders.indexOf(header);
        return headerIndex > -1 ? headerIndex : '';
      });
    },
    async process(fieldName, file, metadata, load, error, progress, abort) {
      try {
        console.log('[ File ]:', file);
        const result = await this.$store.dispatch('tools/getJsonDataFromCsvFile', file);
        if (load) {
          load();
        }
        if (result?.content?.length && Object.keys(result.content[0]).filter(key => key === '').length) {
          this.$emit('notification', { type: 'error', message: 'Blank column headers found! Please fill in all column headers and upload it again!' });
          return;
        }
        this.uploadedFile = result.excelFile || file;
        this.fileHeaders = result.headers;
        result.headers.forEach(header => {
          this.uploadedListHeaders.push({
            text: header,
            align: 'start',
            sortable: false,
            value: header
          });
        });
        this.uploadedListContent = result.content;
        console.log('[ CSV File Parse Result ] ::', result);
        this.addresses = result.fileLength;
        this.isMailingListTableVisible = true;
        this.$emit('notification', { isVisible: false });
      } catch (error) {
        console.error('process Error:', error);
        this.$emit('notification', { type: 'error', message: 'Something went wrong in CSV file processing. Check console for more details.' });
      }
    },
    setRecommendedHeaders(headers) {
      const verifiedHeaders = [];
      headers.forEach(header => {
        const headerToCheck = header.toLowerCase().replace(' ', '');
        this.basicHeaders.forEach((required, index) => {
          if (required.toLowerCase() === headerToCheck || required === headerToCheck.replace('_', '')) {
            verifiedHeaders[index] = headerToCheck;
            this.adjustedHeaders[index] = header;
          } else if (headerToCheck.includes(required.toLowerCase()) || required.includes(headerToCheck)) {
            if (verifiedHeaders.includes(headerToCheck)) {
              this.adjustedHeaders[index] = '';
            } else if (!verifiedHeaders[index]) {
              this.adjustedHeaders[index] = header;
            }
            verifiedHeaders[index] = headerToCheck;
          }
        });
      });
      this.isFinalStepAllowed = true;
    },
    clearHeader(headerIndex) {
      // v-select's clear event sets the value to undefined but we need empty string
      this.$nextTick(() => (this.adjustedHeaders[headerIndex] = ''));
    },
    processUploadedList() {
      this.setRecommendedHeaders(this.fileHeaders);
      this.isMailingListTableVisible = false;
    },
    closeHeaderMapSelection() {
      this.isMailingListTableVisible = true;
      this.isFinalStepAllowed = false;
      this.adjustedHeaders = [];
    }
  }
};
</script>

<style scoped>
.preview-table-width {
  width: 100%;
}
</style>
