<template>
  <v-container fluid>
    <v-dialog v-model="isViewJsonDialogOpened" max-width="620">
      <v-card>
        <v-card-title class="justify-end">
          <v-btn icon @click="closeJsonViewDialog()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <vue-json-pretty :data="logJsonData" :show-double-quotes="true" :show-length="true" :show-line="true"></vue-json-pretty>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card v-if="isPanelOpened" class="elevation-0 mt-n10" :loading="isLogsLoading">
      <template slot="progress">
        <v-progress-linear color="cyan darken-2" indeterminate></v-progress-linear>
      </template>
      <v-card-title>
        <v-row class="mt-n7 mb-n3">
          <v-spacer></v-spacer>
          <v-col cols="6" md="4">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          </v-col>
          <v-btn text small class="mt-8" color="cyan darken-3" @click="getOrderLogs()"><v-icon class="mr-2">mdi-refresh</v-icon>Refresh</v-btn>
        </v-row>
      </v-card-title>
      <v-card-text class="mb-n3 mt-n5">
        <div class="site-table">
          <stamp-data-table
            :headers="logHeaders"
            :data="logs"
            sort-by="timestamp"
            :sort-desc="true"
            :search="search"
            :itemsPerPage="-1"
            item-key="index"
            custom-class="elevation-0 mt-n3"
          >
            <template #[`item.icon`]>
              <v-icon medium color="cyan darken-1" class="mr-n3">mdi-history</v-icon>
            </template>
            <template #[`item.timestamp`]="{ item }">
              <span>{{ formatDate(item.timestamp.seconds) }} </span>
            </template>
            <template #[`item.action`]="{ item }">
              <span>{{ getActionMessage(item.action) }}</span>
              <v-btn
                v-if="actionJsonData(item.action)[1] || item.data"
                outlined
                small
                color="blue-grey darken-2"
                class="ml-2 caption font-weight-regular"
                @click="showJsonResult(item)"
              >
                View JSON Result
              </v-btn>
            </template>
          </stamp-data-table>
          <p class="subtitle-2 font-weight-regular grey--text text--darken-1 mt-n10 ml-4"><strong>Order Log ID: </strong>{{ orderLogId }}</p>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: {
    VueJsonPretty,
    'stamp-data-table': DataTable
  },
  props: ['isPanelOpened', 'firebaseOrderId'],
  data() {
    return {
      isLogsLoading: false,
      logHeaders: [
        { text: '', value: 'icon', align: 'right', sortable: false, width: '10' },
        { text: 'Timestamp', value: 'timestamp', align: 'center', width: '240' },
        { text: 'Action', value: 'action', align: 'left' }
      ],
      search: '',
      orderLogId: '',
      logs: [],
      isViewJsonDialogOpened: false,
      logJsonData: {}
    };
  },
  watch: {
    isPanelOpened: {
      handler: function (value) {
        if (value) this.getOrderLogs();
      },
      immediate: true
    }
  },
  methods: {
    async getOrderLogs() {
      if (this.firebaseOrderId) {
        try {
          this.isLogsLoading = true;
          this.logs = [];
          const response = await this.$store.dispatch('logs/listOrderLog', { documentId: this.firebaseOrderId });
          this.logs = response.actions.map((action, index) => ({ index, ...action }));
          this.orderLogId = response.orderLogId;
        } catch (error) {
          console.error('getOrderLogs error:', error);
          this.$store.dispatch('showAlert', { type: 'error', message: 'Could not load order logs. Check console for more details.' });
        } finally {
          this.isLogsLoading = false;
        }
      }
    },
    getActionMessage(action) {
      return this.actionJsonData(action).length < 2 ? action : this.actionJsonData(action)[0];
    },
    actionJsonData(action) {
      // We don't have a JSON object saved in the log object, so I have to get and parse it this way
      const jsonContent = action.includes('JSON:') ? action.split('JSON:') : action.includes('DATA:') ? action.split('DATA:') : '';
      return jsonContent.length > 1 ? jsonContent : [];
    },
    showJsonResult(item) {
      this.logJsonData = this.actionJsonData(item.action)[1] ? JSON.parse(this.actionJsonData(item.action)[1]) : item.data;
      this.isViewJsonDialogOpened = true;
    },
    closeJsonViewDialog() {
      this.isViewJsonDialogOpened = false;
    },
    formatDate(dateTotransform) {
      var date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    }
  }
};
</script>
