<template>
  <v-container>
    <v-col cols="12" class="pl-0">
      <v-btn icon class="mb-2" @click.native="back()">
        <v-icon color="primary">mdi-arrow-left</v-icon>
      </v-btn>
      <span class="headline">Process Eddm JSON to Excel file</span>
    </v-col>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <v-text-field v-model="downloadUrl" label="JSON Url" append-icon="mdi-magnify" single-line hide-details></v-text-field>
        <v-text-field v-model="orderID" label="OrderID" single-line hide-details></v-text-field>
        <v-row>
          <v-col sm="6" class="mt-5">
            <p class="title font-weight-regular">Bundle value:</p>
            <v-radio-group v-model="radios" :mandatory="false">
              <v-radio label="200" value="200"></v-radio>
              <v-radio label="100" value="100"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col sm="6" class="mt-5">
            <p class="title font-weight-regular">File Type Downloaded:</p>
            <v-radio-group v-model="fileType" :mandatory="false">
              <v-radio label="Excel file (.xlsx)" value="excel"></v-radio>
              <v-radio label="CSV file (.csv)" value="csv"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <div class="mt-4">
          <v-btn color="grey lighten-2" :disabled="!downloadUrl" class="v-btn theme--light mb-3" @click="downloadExcel()">
            <v-icon left>mdi-download</v-icon>
            <span class="mr-1">Download {{ fileType.toUpperCase() }}</span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      emptyDownloadUrlAlert: false,
      orderID: null,
      downloadUrl: '',
      radios: '200',
      fileType: 'excel'
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    downloadExcel() {
      this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
      const isUrlFromAmazon = this.downloadUrl.includes('amazon');
      this.$store
        .dispatch('maps/generateExcelFileEddm', {
          downloadUrl: this.downloadUrl,
          isUrlFromAmazon,
          orderID: this.orderID,
          bundleValue: parseInt(this.radios),
          fileType: this.fileType
        })
        .catch(error => this.$store.dispatch('showAlert', { type: 'error', message: error }))
        .finally(() => this.$store.dispatch('hideLoader'));
    }
  }
};
</script>
