<template>
  <v-card elevation="0">
    <v-card-text class="mt-4">
      <v-row>
        <v-col cols="12" md="6">
          <ul>
            <li v-for="(data, index) in customerIdentifiers" :key="index">
              <v-row>
                <v-col cols="6">
                  <p class="font-weight-medium grey--text text--darken-1 my-0">{{ data.attribute_name }}</p>
                </v-col>
                <v-col cols="6">
                  <p class="black--text my-0">{{ data.value }}</p>
                </v-col>
              </v-row>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="6">
          <ul>
            <li v-for="(data, index) in customerInfo" :key="index">
              <v-row>
                <v-col cols="6">
                  <p class="font-weight-medium grey--text text--darken-1 my-0">{{ data.attribute_name }}</p>
                </v-col>
                <v-col cols="6">
                  <p class="black--text my-0">{{ data.value }}</p>
                </v-col>
              </v-row>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ['customer'],
  computed: {
    customerIdentifiers() {
      const identifierKeys = ['wp_user_id', 'brand_id', 'firebase_site_id', 'site_id', 'stripe_customer', 'customer_unique_id'];
      return identifierKeys.map(id => ({ attribute_name: this.capitalize(id), value: this.customer[id] }));
    },
    customerInfo() {
      const attributes = [
        {
          attribute_name: 'Phone Number',
          value: this.customer?.phone || ''
        }
      ];
      if (this.customer?.address) {
        Object.entries(this.customer.address).forEach(address => {
          attributes.push({
            attribute_name: this.capitalize(address[0]),
            value: address[1]
          });
        });
      }
      return attributes;
    }
  },
  methods: {
    capitalize(text) {
      return (
        text
          ?.toLowerCase()
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ') || ''
      );
    }
  }
};
</script>
