<template>
  <v-container fluid>
    <template v-if="!isFileUploaderVisible">
      <v-row class="justify-space-around mt-n5" transition="fade-transition">
        <artwork-tool artwork-side="front" :is-loaded="isFrontArtworkLoaded" :artwork-url="artworkImages.front_artwork_thumbnail" @onReplace="replaceArtwork($event)" />
        <artwork-tool artwork-side="back" :is-loaded="isBackArtworkLoaded" :artwork-url="artworkImages.back_artwork_thumbnail" @onReplace="replaceArtwork($event)" />
      </v-row>
    </template>

    <v-dialog v-if="isPreflightToolVisible" v-model="isPreflightToolVisible" persistent max-width="1500">
      <v-card>
        <v-card-title class="justify-end">
          <v-btn icon @click="closePreflightModal()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <preflight-tool :order="orderObject" @onUploadFile="onUploadFile"></preflight-tool>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PreflightTool from '../tools/PreflightTool';
import ArtworkTool from '../tools/ArtworkTool.vue';

export default {
  components: {
    'artwork-tool': ArtworkTool,
    'preflight-tool': PreflightTool
  },
  props: ['firebaseOrderId', 'orderObject'],
  data() {
    return {
      artworkImages: {
        front_artwork_thumbnail: '',
        front_artwork_url: '',
        back_artwork_thumbnail: '',
        back_artwork_url: ''
      },
      isFileUploaderVisible: false,
      isUpdateArtworkLoading: false,
      isFrontArtworkLoaded: false,
      isBackArtworkLoaded: false,
      isPreflightToolVisible: false,
      isArtworkBack: false
    };
  },
  computed: {
    showFrontArtworkPlaceholder() {
      return this.isFrontArtworkLoaded && !this.artworkImages.front_artwork_thumbnail;
    },
    showBackArtworkPlaceholder() {
      return this.isBackArtworkLoaded && !this.artworkImages.back_artwork_thumbnail;
    }
  },
  mounted() {
    this.loadExistingArtworkData();
  },
  methods: {
    loadExistingArtworkData() {
      const delivery = this.orderObject.orderData.delivery;
      const fouroverFrontArtwork = delivery.fourover_front_artwork;
      this.artworkImages.front_artwork_thumbnail = fouroverFrontArtwork?.preview_uri || '';
      this.artworkImages.front_artwork_url = fouroverFrontArtwork?.pdf_uri || '';
      const customerFileFront = fouroverFrontArtwork?.pdf_uuid || '';
      this.$store.commit('fourover/setArtworkDataField', { field: 'fr', value: customerFileFront });
      this.isFrontArtworkLoaded = true;

      const fouroverBackArtwork = delivery.fourover_back_artwork;
      this.artworkImages.back_artwork_thumbnail = fouroverBackArtwork?.preview_uri || '';
      this.artworkImages.back_artwork_url = fouroverBackArtwork?.pdf_uri || '';
      const customerFileBack = fouroverBackArtwork?.pdf_uuid || '';
      this.$store.commit('fourover/setArtworkDataField', { field: 'bk', value: customerFileBack });
      this.isBackArtworkLoaded = true;
    },
    async saveArtworks(config) {
      try {
        this.isUpdateArtworkLoading = true;
        this.$store.dispatch('showLoader', { message: `Updating artworks in progress...` });
        this.isFrontArtworkLoaded = false;
        this.isBackArtworkLoaded = false;
        const artworkPayload = {
          data: {
            path: [config.urlFront ?? config.urlBack],
            preflight: true
          },
          meta: {
            firebase_order_id: this.firebaseOrderId,
            side: config.urlFront ? 'fr' : 'bk'
          }
        };
        const artworkResponse = await this.$store.dispatch('fourover/createArtwork', artworkPayload);
        if (config.urlFront) {
          this.artworkImages.front_artwork_thumbnail = artworkResponse.fourover_front_artwork.preview_uri || '';
          this.artworkImages.front_artwork_url = artworkResponse.fourover_front_artwork.pdf_uri || '';
        }
        if (config.urlBack) {
          this.artworkImages.back_artwork_thumbnail = artworkResponse.fourover_back_artwork.preview_uri || '';
          this.artworkImages.back_artwork_url = artworkResponse.fourover_back_artwork.pdf_uri || '';
        }
        this.isBackArtworkLoaded = true;
        this.isFrontArtworkLoaded = true;
      } catch (error) {
        console.error('[saveArtworks ERROR ]', error.response?.data || error);
        this.$store.dispatch('showAlert', { type: 'error', message: error.response?.data?.message || error?.message || 'Could not create artwork data.' });
      } finally {
        this.isUpdateArtworkLoading = false;
        this.isPreflightToolVisible = false;
        this.$store.dispatch('hideLoader');
      }
    },
    replaceArtwork(selectedSide) {
      this.isArtworkBack = selectedSide === 'back';
      this.isPreflightToolVisible = true;
    },
    onUploadFile(result) {
      console.log('ArtworkPreflightUpload: onUploadFile : ', result);
      this.saveArtworks({
        [this.isArtworkBack ? 'urlBack' : 'urlFront']: result?.[0]?.url ?? ''
      });
    },
    closePreflightModal() {
      this.isPreflightToolVisible = false;
    }
  }
};
</script>
