<template>
  <v-container fluid transition="fade-transition">
    <confirmation-dialog
      :isConfirmationDialogOpened="isConfirmationDialogOpened"
      :action="confirmationDialogAction"
      @closeConfirmationDialog="closeConfirmationDialog"
      @actionConfirmed="duplicateConfirmed"
    >
    </confirmation-dialog>

    <confirm-delete-dialog :isDialogOpened="isDeleteDialogOpened" :itemNames="selectedProductType.name" @confirmDelete="deleteConfirmed" @closeDialog="closeDeleteDialog">
    </confirm-delete-dialog>

    <v-toolbar flat class="site-table--header site-table--lists">
      <v-row class="mt-n3 mb-0">
        <v-toolbar-title class="headline grey--text text--darken-3 ml-n3 pt-1">Product Types</v-toolbar-title>
        <v-spacer />
        <v-col cols="12" sm="5" md="4" lg="4" xl="3" class="text-center">
          <v-text-field v-model="search" label="Search" class="mt-n3" append-icon="mdi-magnify" outlined dense hide-details></v-text-field>
        </v-col>
        <v-btn color="blue" class="white--text ml-2 mr-n1" min-width="200" @click="addNewProductTypeDialog()"><v-icon left>mdi-plus-circle</v-icon>Add New Product Type</v-btn>
      </v-row>
    </v-toolbar>
    <div class="site-table mt-n5 ml-n1 mr-0">
      <stamp-data-table
        :headers="headers"
        :data="product_types"
        :actions="actions"
        :search="search"
        :is-loading="isProductTypesTableLoading"
        :is-items-per-page-disabled="true"
        item-key="product_type_firebase_id"
        @viewProductType="viewProductType"
        @editProductType="editProductType"
        @duplicateProductType="duplicateProductType"
        @deleteProductType="deleteProductType"
      >
        <template #[`item.graphic`]="{ item }">
          <v-img max-width="35" :src="item.image"></v-img>
        </template>
        <template #[`item.product_type_firebase_id`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon color="amber darken-3" v-on="on" @click="copyItemToClipboard(item.product_type_firebase_id)">mdi-firebase</v-icon>
            </template>
            <span>Copy FirebaseID ( {{ item.product_type_firebase_id }} ) to clipboard</span>
          </v-tooltip>
        </template>
      </stamp-data-table>
    </div>
  </v-container>
</template>

<script>
import ConfirmDeleteDialog from '../../dialog/ConfirmDeleteDialog';
import ConfirmationDialog from '../../order-management/orders/orderDialogs/ConfirmationDialog';
import { mapGetters } from 'vuex';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: {
    'confirm-delete-dialog': ConfirmDeleteDialog,
    'confirmation-dialog': ConfirmationDialog,
    'stamp-data-table': DataTable
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Firebase ID', value: 'product_type_firebase_id', align: 'center', sortable: false }
      ],
      actions: [
        { icon: 'mdi-eye', iconColor: 'blue darken-1', title: 'View Product Type', eventName: 'viewProductType' },
        { icon: 'mdi-pencil', iconColor: 'green darken-2', title: 'Edit Product Type', eventName: 'editProductType' },
        { icon: 'mdi-plus-box-multiple-outline', iconColor: 'grey darken-1', title: 'Duplicate Product Type', eventName: 'duplicateProductType' }
      ],
      isDeleteDialogOpened: false,
      selectedProductType: {},
      isConfirmationDialogOpened: false,
      confirmationDialogAction: ''
    };
  },
  computed: {
    ...mapGetters('product_types', {
      product_types: 'getProductTypes',
      isProductTypesTableLoading: 'getProductTypesLoadingState'
    }),
    ...mapGetters('users', {
      userAccessLevel: 'userAccessLevel'
    }),
    isUserSuperAdmin() {
      return this.userAccessLevel && parseInt(this.userAccessLevel.value) === 100;
    }
  },
  created() {
    this.$store.dispatch('product_types/getAllProductTypes');
    if (this.isUserSuperAdmin) {
      this.actions.push({ icon: 'mdi-delete-outline', iconColor: 'red darken-1', title: 'Delete Product Type', eventName: 'deleteProductType' });
    }
  },
  methods: {
    addNewProductTypeDialog() {
      this.$router.replace('/product-types/new');
    },
    editProductType(product_type) {
      this.$router.replace(`/product-types/edit/${product_type.product_type_firebase_id}`);
    },
    viewProductType(product_type) {
      this.$router.replace(`/product-types/view/${product_type.product_type_firebase_id}`);
    },
    duplicateProductType(product_type) {
      this.confirmationDialogAction = `duplicate product type '${product_type.name}'`;
      this.selectedProductType.data = product_type;
      this.isConfirmationDialogOpened = true;
    },
    async duplicateConfirmed() {
      this.$store.dispatch('showLoader', { message: `Duplicating product type ${this.selectedProductType.data.name} in progress...` });
      try {
        this.selectedProductType.data.name += ' Duplicated';
        const { product_type_firebase_id, ...typeData } = this.selectedProductType.data;
        const productTypeDuplicate = { data: typeData };
        const result = await this.$store.dispatch('product_types/addNewProductType', productTypeDuplicate);
        this.setSnackbar('success', `Product Type saved with id: ${result}. `);
      } catch (error) {
        this.showAlert('error', error.message || error);
      } finally {
        this.selectedProductType = {};
        this.closeConfirmationDialog();
        this.$store.dispatch('hideLoader');
      }
    },
    deleteProductType(product_type) {
      this.selectedProductType = product_type;
      this.isDeleteDialogOpened = true;
    },
    async deleteConfirmed() {
      this.$store.dispatch('showLoader', { message: `Deleting product type in progress...` });
      try {
        await this.$store.dispatch('product_types/deleteProductType', { product_type_firebase_id: this.selectedProductType.product_type_firebase_id });
        this.setSnackbar('success', `Product Type [ ${this.selectedProductType.product_type_firebase_id} ] has been deleted successfully!`);
      } catch (error) {
        console.error('[deleteProductType error]', error);
        this.showAlert('error', error.message || error || 'Could not delete product type!');
      } finally {
        this.closeDeleteDialog();
        this.selectedProductType = {};

        this.$store.dispatch('hideLoader');
      }
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpened = false;
    },
    closeConfirmationDialog() {
      this.isConfirmationDialogOpened = false;
      this.confirmationDialogAction = '';
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    }
  }
};
</script>
