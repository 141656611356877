<template>
  <v-container>
    <v-row class="justify-center py-2 mx-0">
      <v-col cols="12">
        <v-card>
          <v-row class="px-5 py-2">
            <v-btn icon class="px-3 pb-1" @click.native="back()">
              <v-icon color="red">mdi-arrow-left</v-icon>
            </v-btn>
            <span class="headline">Selected Site</span>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row class="px-3 grey--text text--darken-2">
            <v-col cols="12" md="6" class="mb-n2">
              <p class="font-weight-medium">
                Firebase Site ID:
                <span class="font-weight-light">{{ selectedSite }}</span>
              </p>
              <p v-if="siteName" class="font-weight-medium">
                Site Name:
                <span class="font-weight-light">{{ siteName }}</span>
              </p>
              <p v-if="siteId" class="font-weight-medium">
                Site ID:
                <span class="font-weight-light">{{ siteId }}</span>
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="justify-center py-2 mx-0">
      <v-col cols="12">
        <v-card>
          <v-row>
            <v-col cols="6">
              <v-list subheader>
                <v-subheader class="title mb-6">Toggle Order Processes</v-subheader>
                <v-list-item v-for="(orderProcess, i) in orderProcesses" :key="i" class="my-n11">
                  <v-list-item-content class="pl-4">
                    <v-switch v-model="availableProcesses" color="primary" :label="capitalize(i)" :value="i"></v-switch>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6">
              <v-list subheader>
                <v-subheader class="title mb-4">Displayed Sequence of the Order Processes</v-subheader>
                <draggable v-model="availableSequence" draggable=".item" class="center-content cursor-grab">
                  <v-list-item v-for="(orderProcess, i) in availableSequence" :key="i" class="item my-n1">
                    <v-list-item-avatar class="my-0">
                      <v-icon>mdi-format-line-spacing</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ getSequenceLabel(orderProcess, i) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </draggable>
              </v-list>
            </v-col>
          </v-row>
          <v-card-actions class="justify-space-around mt-2">
            <v-btn color="primary" @click.native="saveOrderProcesses">Save Selections</v-btn>
            <v-btn color="primary" @click="saveOrderSequence">Save Sequence</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <template v-if="!isStepViewVisible">
        <v-subheader class="title mb-2">Order Process Settings</v-subheader>
        <v-expansion-panels popout>
          <v-expansion-panel v-for="(orderProcess, i) in orderProcesses" :key="i">
            <v-expansion-panel-header @click="selectOrderProcess(i)">{{ capitalize(i) }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card>
                <OrderProcess :orderProcess="orderProcess" />
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <div v-else class="full-width-container">
        <router-view></router-view>
      </div>
    </v-row>

    <template v-if="isResetAllVisible">
      <v-btn color="red" dark class="my-8" @click="openResetDialog"> Reset All Settings </v-btn>
      <ConfirmationDialog
        v-model="isResetDialogVisible"
        title="Reset Confirmation"
        text="Are you sure you want to reset all settings?"
        @onClose="closeResetDialog"
        @onConfirm="resetOrderSettings"
      />
    </template>
  </v-container>
</template>
<script>
import ConfirmationDialog from '../dialog/ConfirmationDialog.vue';
import OrderProcess from './OrderProcess.vue';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

export default {
  name: 'orderProcesses',
  components: {
    ConfirmationDialog,
    draggable,
    OrderProcess
  },
  data() {
    return {
      firebaseSiteId: this.$route.params.siteId,
      availableProcesses: [],
      availableSequence: [],
      isResetDialogVisible: false
    };
  },
  computed: {
    ...mapGetters('site', {
      selectedSite: 'getSelectedSite',
      siteInfo: 'getSiteInfo'
    }),
    ...mapGetters({
      orderSettings: 'getOrderSettings'
    }),
    siteName() {
      return this.siteInfo?.site_name || '';
    },
    siteId() {
      return this.siteInfo?.site_id || '';
    },
    orderProcesses() {
      return this.orderSettings?.[this.selectedSite]?.orderProcesses || [];
    },
    orderSequence() {
      return this.orderSettings?.[this.selectedSite]?.orderSequence || [];
    },
    isStepViewVisible() {
      return this.$route.params.step !== undefined;
    },
    isResetAllVisible() {
      return this.$route.name === 'OrderProcesses';
    }
  },
  watch: {
    orderProcesses: {
      handler() {
        this.setAvailableProcesses();
      },
      deep: true,
      immediate: true
    },
    availableProcesses: {
      handler() {
        this.$store.dispatch('site/getSiteDetailsInfo', { key: this.firebaseSiteId });
        this.updateSequenceList();
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    if (!this.orderProcesses.length) {
      await this.$store.dispatch('loadOrderSettingById', this.firebaseSiteId);
      this.$store.dispatch('site/getSiteDetailsInfo', { key: this.firebaseSiteId });
      this.$store.commit('site/setSelectedSite', this.firebaseSiteId);
    }
    this.$store.commit('site/setSitesAcceptedRoutes', this.$options.name);
  },
  methods: {
    back() {
      this.$router.back();
    },
    capitalize(text) {
      return (
        text
          ?.split(/(?=[A-Z])|_/)
          ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ') || ''
      );
    },
    setAvailableProcesses() {
      this.availableProcesses = Object.keys(this.orderProcesses).filter(key => this.orderProcesses[key].available);
    },
    async saveOrderProcesses() {
      this.$store.dispatch('showLoader', { message: 'Saving Order Processes in progress...' });
      try {
        const promises = [];
        for (let i in this.orderProcesses) {
          promises.push(
            this.$store.dispatch('setOrdersAvailability', {
              site: this.selectedSite,
              order: i,
              available: this.availableProcesses.includes(i)
            })
          );
        }
        await Promise.all(promises);
        this.$store.dispatch('showAlert', { type: 'success', message: 'Order Processes updated successfully!' });
      } catch (error) {
        console.error('[saveOrderVisibility error]:', error);
        this.$store.dispatch('showAlert', { type: 'error', message: 'Something went wrong while updating Order Processes' });
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },

    getSequenceLabel(label, key) {
      return `${key + 1}. ${this.capitalize(label)}`;
    },
    updateSequenceList() {
      if (!this.availableSequence.length) {
        this.availableSequence = this.orderSequence.slice(0);
      }
      this.availableSequence = this.availableSequence.filter(sequence => this.availableProcesses.includes(sequence));

      this.availableProcesses.forEach(process => {
        if (!this.availableSequence.includes(process)) {
          this.availableSequence.push(process);
        }
      });
    },
    async saveOrderSequence() {
      this.$store.dispatch('showLoader', { message: 'Saving Order Sequence in progress...' });
      try {
        await this.$store.dispatch('setOrderSequence', { site: this.selectedSite, sequence: this.availableSequence });
        this.$store.dispatch('showAlert', { type: 'success', message: 'Order Sequence updated successfully!' });
      } catch (error) {
        console.error('[saveOrderSequence error]:', error);
        this.$store.dispatch('showAlert', { type: 'error', message: 'Something went wrong while saving sequence' });
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    selectOrderProcess(order) {
      this.$store.dispatch('site/orderProcessDetails', {
        selectedSite: this.selectedSite,
        selectedOrderName: order
      });
      this.$store.commit('site/setSelectedOrder', order);
    },
    openResetDialog() {
      this.isResetDialogVisible = true;
    },
    closeResetDialog() {
      this.isResetDialogVisible = false;
    },
    async resetOrderSettings() {
      this.closeResetDialog();
      this.$store.dispatch('showLoader', { message: 'Reseting all settings in progress...' });
      try {
        await this.$store.dispatch('resetAllOrderSettings', this.selectedSite);
        await this.$store.dispatch('loadOrderSettingById', this.firebaseSiteId);
        this.$store.dispatch('showAlert', { type: 'success', message: 'All settings have been reset to their default state.' });
      } catch (error) {
        console.error('[resetOrderSettings error]:', error);
        this.$store.dispatch('showAlert', { type: 'error', message: 'Something went wrong while reseting settings' });
      } finally {
        this.$store.dispatch('hideLoader');
      }
    }
  }
};
</script>
<style scoped>
.full-width-container {
  width: 100%;
}
.cursor-grab {
  cursor: grab;
}
</style>
