<template>
  <v-container fluid>
    <v-row>
      <v-btn icon large @click="navigateBack()"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <span class="ml-3 pt-1 title font-weight-regular">{{ title }}</span>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <FormGenerator :modelData="fouroverStatus" :isEditEnabled="isEditEnabled" :isLoading="isLoading" @saveModel="saveFouroverStatus" />
  </v-container>
</template>

<script>
import FormGenerator from '@/components/common/dynamicForm/FormGenerator';

export default {
  components: {
    FormGenerator
  },
  data() {
    return {
      fouroverStatusDbId: '',
      fouroverStatus: [],
      isEditEnabled: true,
      isLoading: false
    };
  },
  computed: {
    title() {
      return this.fouroverStatusDbId ? `${this.isEditEnabled ? 'Edit' : 'View'}: ${this.fouroverStatusDbId}` : 'Add New 4Over Status';
    }
  },
  async mounted() {
    this.isEditEnabled = this.$route.name !== 'ViewFouroverStatus';
    this.fouroverStatusDbId = Object.values(this.$route.params)[0];
    this.fouroverStatus = this.$store.getters['fourover_statuses/getSelectedModel'];

    if (!this.fouroverStatus.length && this.fouroverStatusDbId) {
      const fouroverStatus = await this.$store.dispatch('fourover_statuses/getFouroverStatusById', this.fouroverStatusDbId);
      await this.$store.dispatch('fourover_statuses/initSelectedModel', fouroverStatus);
      this.fouroverStatus = this.$store.getters['fourover_statuses/getSelectedModel'];
    }
  },
  methods: {
    async saveFouroverStatus(fourover_status) {
      this.isLoading = true;
      const fouroverStatusObject = {
        id: this.fouroverStatusDbId || '',
        data: fourover_status
      };
      try {
        const result = await this.$store.dispatch('fourover_statuses/saveFouroverStatus', fouroverStatusObject);
        this.setSnackbar('success', `4over status saved with id: ${result}`);
        this.navigateBack();
      } catch (error) {
        this.showAlert('error', error.message || error);
      } finally {
        this.isLoading = false;
      }
    },
    navigateBack() {
      this.fouroverStatus = [];
      this.$store.commit('fourover_statuses/clearModel');
      this.$router.replace(`/${this.$route.path.split('/')[1]}`);
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    }
  }
};
</script>
