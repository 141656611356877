import { cloudFunctionsPost } from '../../modules/cloud-function-wrapper.js';
import smsTemplateService from '../../services/smsTemplateService.js';

const state = {
  smsTemplates: []
};

const mutations = {
  setSMSTemplates(state, payload) {
    state.smsTemplates = payload;
  }
};

const getters = {
  getSMSTemplates(state) {
    return state.smsTemplates;
  }
};

const actions = {
  async fetchSMSTemplates({ commit }, payload) {
    const templates = await smsTemplateService.getAll({ isIdReturned: true, isArray: true });
    if (payload && payload.saveInStore) {
      commit('setSMSTemplates', templates);
    }
  },
  async isSMSTemplateExistsByCode({ commit }, payload) {
    const templates = await smsTemplateService.getByProperty('code', payload.code.toUpperCase(), { isIdReturned: true, isArray: true });
    return templates.some(template => template._id !== payload._id && template.code.toUpperCase() === payload.code.toUpperCase());
  },
  insertTemplate({ commit }, payload) {
    const template = { id: '', data: { name: payload.name, code: payload.code ? payload.code.toUpperCase() : '', content: payload.content, active: payload.active } };
    return smsTemplateService.add(template);
  },
  updateTemplate({ commit }, payload) {
    const template = { id: payload._id, data: { name: payload.name, code: payload.code ? payload.code.toUpperCase() : '', content: payload.content, active: payload.active } };
    return smsTemplateService.update(template.id, template.data);
  },
  deleteTemplate({ commit, dispatch, getters }, payload) {
    return smsTemplateService.delete(payload._id);
  },
  executeSMSRequest({ commit }, { endpoint, data }) {
    return cloudFunctionsPost(`/sms/${endpoint}`, data);
  },
  previewSMS({ dispatch }, payload) {
    return dispatch('executeSMSRequest', {
      endpoint: 'preview',
      data: {
        order_id: payload.order_id,
        template: {
          message: payload.message
        }
      }
    });
  },
  sendSMS({ dispatch }, payload) {
    return dispatch('executeSMSRequest', {
      endpoint: 'send',
      data: {
        to: payload.to,
        content: payload.content,
        order_id: payload.order_id
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
