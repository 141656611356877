<template>
  <v-container>
    <div v-if="sendAllTransactionToOspreyLoading" class="ml-2 mb-4">
      <v-progress-circular indeterminate color="cyan darken-1" size="20"></v-progress-circular>
      <span class="ml-2">Sending transaction to Osprey...</span>
    </div>
    <v-btn
      v-else-if="failedSentTransactions.length > 0 && !sendAllTransactionToOspreyLoading"
      small
      color="blue-grey lighten-2"
      class="ml-2 mb-4 white--text"
      @click="sendAllTransactionToOsprey()"
      >Resend all {{ failedSentTransactions.length || '' }} transaction to Osprey</v-btn
    >
    <v-row class="mx-3">
      <div class="site-table">
        <stamp-data-table
          :headers="transactionFields"
          :data="transactions"
          sort-by="timestamp"
          :sort-desc="true"
          item-key="payment_db_id"
          :loading="loading"
          no-data-text="No transactions have been found for this order"
          @expanded="setExpandedRows"
        >
          <template #[`item.agent`]="{ item }">
            <p class="body-2">{{ getAgentName(item) }}</p>
            <p class="agentEmail">{{ getAgentEmail(item) }}</p>
          </template>
          <template #[`item.amount`]="{ item }">
            <span class="font-weight-bold primary--text">{{ formatPrice(item.amount / 100) }}</span>
            <p v-if="item.refunds" class="font-weight-bold blue-grey--text mb-n1 ml-n1">-{{ formatPrice(calculateRefundTotal(item.refunds) / 100) }}</p>
          </template>
          <template #[`item.payment_type`]="{ item }">
            <payment-chip :transaction="item"></payment-chip>
            <v-tooltip v-if="item.paymentType === 'adjustment' && item.osprey_note" bottom>
              <template #activator="{ on }">
                <v-btn icon x-small v-on="on"><v-icon color="blue-grey lighten-2">mdi-comment</v-icon></v-btn>
              </template>
              <span>{{ item.osprey_note }}</span>
            </v-tooltip>
          </template>
          <template #[`item.payment_method`]="{ item }">
            <template v-if="item.paymentType === 'stripe' || item.paymentType === 'stripe_pending'">
              <span
                v-if="paymentMethods.length > 0 && paymentMethods.findIndex(paymentMethod => paymentMethod.id === item.payment_method_db_id) === -1"
                class="font-italic paymentHistoryTable"
              >
                Payment method has been removed
              </span>
              <span v-else-if="paymentMethods.length > 0" class="paymentHistoryTable">
                <v-icon v-if="item.card_stripe_id" x-small>mdi-credit-card</v-icon>
                <v-icon v-else-if="item.bank_account_stripe_id" x-small>mdi-bank</v-icon>
                {{ paymentMethods[paymentMethods.findIndex(paymentMethod => paymentMethod.id === item.payment_method_db_id)].details.slice(0, -8) }}
              </span>
            </template>
          </template>
          <template #[`item.timestamp`]="{ item }">
            <span class="paymentHistoryTable">{{ formatDate(item.timestamp.seconds) }}</span>
          </template>
          <template #[`item.success`]="{ item }">
            <v-chip v-if="item.success" small color="green" text-color="white">Success</v-chip>
            <v-tooltip v-else-if="item.chargeResponse || item.chargeResponse.error_msg" bottom>
              <template #activator="{ on }">
                <v-chip small color="red" text-color="white" v-on="on">Error</v-chip>
              </template>
              <span>{{ item.chargeResponse || item.chargeResponse.error_msg }}</span>
            </v-tooltip>
            <v-chip v-else small color="red" text-color="white">Error</v-chip>
          </template>
          <template #[`item.sent_to_opsrey_error`]="{ item }">
            <template v-if="!item.sent_to_opsrey_error && item.paymentType !== 'stripe_pending'">
              <v-chip small color="green" text-color="white">Sent to Osprey</v-chip>
            </template>
            <template v-else-if="item.paymentType !== 'stripe_pending'">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-chip small :color="item.sent_to_opsrey_error == 'Not sent to Osprey' ? 'orange' : 'red'" text-color="white" v-on="on">Can't send to Osprey</v-chip>
                </template>
                <span>{{ item.sent_to_opsrey_error }}</span>
              </v-tooltip>
              <v-progress-circular v-if="sendAllTransactionToOspreyLoading" indeterminate color="cyan darken-1" size="20" class="ml-3"></v-progress-circular>
              <v-btn v-else-if="!sendAllTransactionToOspreyLoading" x-small color="blue-grey lighten-2" dark class="ml-1" @click="sendTransactionsToOsprey([item.payment_db_id])"
                >Resend</v-btn
              >
            </template>
          </template>
          <template v-if="refundIsEnabled" #[`item.actions`]="{ item }">
            <p v-if="balanceUpdateInProgress === true"><v-progress-circular indeterminate color="cyan darken-1" size="20" class="mt-3"></v-progress-circular></p>
            <template v-else>
              <v-btn
                v-if="item.success === true && item.paymentType === 'stripe' && ((item.refunds && calculateRefundSum(item.refunds) !== item.amount) || !item.refunds)"
                color="blue-grey lighten-2"
                dark
                small
                @click="refundAmount(item)"
                >Refund</v-btn
              >
            </template>
          </template>
          <template #[`item.expand`]="{ item }">
            <v-btn v-if="isTransactionFound(item)" x-small icon @click="getRefundsForTransaction(item)"><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-btn v-if="expanded.includes(item)" x-small icon @click="removeRefunds(item)"><v-icon>mdi-chevron-up</v-icon></v-btn>
          </template>
          <template #expanded-item="{ item, headers }">
            <td
              v-if="expanded.find(transaction => transaction.payment_db_id === item.payment_db_id) !== undefined && transactionRefunds[item.payment_db_id] === undefined"
              :colspan="headers.length"
            >
              <v-progress-linear color="cyan darken-2" indeterminate></v-progress-linear>
            </td>
            <td v-else-if="transactionRefunds[item.payment_db_id]" :colspan="headers.length">
              <v-row v-for="(refund, i) in transactionRefunds[item.payment_db_id].slice().reverse()" :key="'transaction_' + i">
                <v-col cols="8" sm="3" md="3" lg="3" xl="3">
                  <p class="body-2 mb-n1 mt-n2">{{ refund.agent_name }}</p>
                  <p class="agentEmail mb-n2">{{ refund.agent_email }}</p>
                </v-col>
                <v-col :key="'refundAmount' + i" cols="3" sm="2" md="2" lg="1" xl="2" class="text-start">
                  <span class="font-weight-bold blue-grey--text amountPosition"> -{{ formatPrice(refund.amount / 100) }}</span>
                </v-col>
                <v-col :key="'refundReason' + i" cols="12" sm="2" md="2" lg="2" xl="2" class="text-start">
                  <span class="paymentHistoryTable mr-3 reasonPosition">{{ refund.refundResponse.reason }}</span>
                  <v-tooltip v-if="refund.refundResponse.note" bottom>
                    <template #activator="{ on }">
                      <v-btn icon x-small v-on="on"><v-icon color="blue-grey lighten-2">mdi-comment</v-icon></v-btn>
                    </template>
                    <span>{{ refund.refundResponse.note }}</span>
                  </v-tooltip>
                </v-col>
                <v-col :key="'refundTimestamp' + i" cols="12" sm="3" md="3" lg="2" xl="2" class="text-start ml-0">
                  <span class="paymentHistoryTable">{{ formatDate(refund.paymentTime.seconds) }}</span>
                </v-col>
                <v-col :key="'status' + i" cols="12" sm="3" md="3" lg="2" xl="2" class="text-start ml-0">
                  <template v-if="refund.success">
                    <v-chip v-if="refund.paymentType === 'refund_pending'" small color="grey" text-color="white">Pending</v-chip>
                    <v-chip v-else-if="refund.paymentType === 'refund_canceled'" small color="grey" text-color="white">Canceled</v-chip>
                    <v-chip v-else-if="refund.paymentType === 'refund'" small color="green" text-color="white">Success</v-chip>
                  </template>
                  <v-tooltip v-else-if="refund.refundResponse && refund.refundResponse.error_msg" bottom>
                    <template #activator="{ on }">
                      <v-btn icon x-small v-on="on"><v-icon color="red">mdi-comment</v-icon></v-btn>
                    </template>
                    <span>{{ refund.refundResponse.error_msg }}</span>
                  </v-tooltip>
                </v-col>
                <v-col :key="'resend' + i" cols="12" sm="3" md="3" lg="2" xl="2" class="text-start ml-0">
                  <template v-if="!refund.sent_to_opsrey_error">
                    <v-chip small color="green" text-color="white">Sent to Osprey</v-chip>
                  </template>
                  <template v-else>
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-chip small :color="refund.sent_to_opsrey_error == 'Not sent to Osprey' ? 'orange' : 'red'" text-color="white" v-on="on">Can't send to Osprey</v-chip>
                      </template>
                      <span>{{ refund.sent_to_opsrey_error }}</span>
                    </v-tooltip>
                    <v-progress-circular
                      v-if="sendAllTransactionToOspreyLoading || sendingTransactionsInprogress.includes(refund.refund_db_id)"
                      indeterminate
                      color="cyan darken-1"
                      size="20"
                      class="ml-3"
                    ></v-progress-circular>
                    <v-btn v-else x-small color="blue-grey lighten-2" dark class="ml-1" @click="sendTransactionsToOsprey([refund.refund_db_id])">Resend</v-btn>
                  </template>
                </v-col>
              </v-row>
              <v-divider v-if="transactionRefunds[item.payment_db_id].length > 1 && i !== transactionRefunds[item.payment_db_id].length - 1" :key="'line_' + i"></v-divider>
            </td>
          </template>
        </stamp-data-table>
      </div>
    </v-row>
  </v-container>
</template>

<script>
const numeral = require('numeral');
import { mapGetters } from 'vuex';
import DataTable from '@/components/common/DataTable.vue';
import PaymentChip from './PaymentChip.vue';

export default {
  components: { 'stamp-data-table': DataTable, 'payment-chip': PaymentChip },
  props: ['refundIsEnabled', 'customerFirebaseId'],
  data() {
    return {
      transactionFields: [
        { text: 'Agent', align: 'start', value: 'agent', sortable: false },
        { text: 'Amount', align: 'center', value: 'amount' },
        { text: 'Type', align: 'left', value: 'payment_type', sortable: false },
        { text: 'Method', align: 'left', value: 'payment_method', sortable: false },
        { text: 'Timestamp', align: 'center', value: 'timestamp' },
        { text: 'Payment Status', align: 'left', value: 'success', sortable: false },
        { text: 'Osprey status', align: 'left', value: 'sent_to_opsrey_error', sortable: false },
        { text: 'Refund', align: 'center', value: 'actions', sortable: false, class: `${this.refundIsEnabled ? '' : 'd-none'}` },
        { text: '', align: 'end', value: 'expand', sortable: false }
      ],
      expanded: [],
      transactions: [],
      transactionRefunds: {},
      sendAllTransactionToOspreyLoading: false,
      sendingTransactionsInprogress: [],
      resendAttemptCount: 0,
      resendAttemptLimit: 1,
      failedSentTransactions: []
    };
  },
  computed: {
    ...mapGetters('stripe', {
      paymentsHistory: 'getPaymentHistory',
      refunds: 'getRefunds',
      loading: 'getPaymentHistoryIsLoading',
      balanceUpdateInProgress: 'getBalanceUpdateInProgress'
    }),
    ...mapGetters('payment_methods', {
      paymentMethods: 'getPaymentMethods'
    }),
    ...mapGetters('customer_messaging', {
      order: 'getTriggeredOrder'
    })
  },
  watch: {
    paymentsHistory: {
      handler(oldTransactions, newTransactions) {
        this.getTransactions(newTransactions);
      },
      deep: true
    },
    refunds: {
      handler(oldRefunds, newRefunds) {
        this.getRefunds();
      },
      deep: true
    },
    transactions() {
      this.getFailedSentTransactionsAndRefunds();
    }
  },
  mounted() {
    this.getTransactions();
  },
  methods: {
    getTransactions(newTransactions) {
      this.transactions = [];
      let transactions = Array.from(this.paymentsHistory) || [];
      let transactionList = transactions.filter(transaction => transaction.paymentType.toUpperCase() !== 'REFUND');

      this.transactions = transactionList;
      this.getRefunds();
      if (this.transactions.length > 0) {
        this.getAllTransactionRefund();
      }
    },
    sendAllTransactionToOsprey() {
      this.sendAllTransactionToOspreyLoading = true;
      this.sendTransactionsToOsprey(this.failedSentTransactions);
    },
    getAgentName(transaction) {
      if (transaction.agent_name) {
        return transaction.agent_name;
      } else if (
        transaction.paymentType.toUpperCase() === 'WIRE' ||
        transaction.paymentType.toUpperCase() === 'CHECK' ||
        transaction.paymentType.toUpperCase() === 'ACH' ||
        transaction.paymentType.toUpperCase() === 'ADJUSTMENT'
      ) {
        return 'OSPREY';
      } else if (this.order.first_name && this.order.last_name) {
        return `${this.order.first_name} ${this.order.last_name}`;
      }
      return '-';
    },
    getAgentEmail(transaction) {
      if (transaction.agent_email) {
        return transaction.agent_email;
      } else if (
        transaction.paymentType.toUpperCase() === 'WIRE' ||
        transaction.paymentType.toUpperCase() === 'CHECK' ||
        transaction.paymentType.toUpperCase() === 'ACH' ||
        transaction.paymentType.toUpperCase() === 'ADJUSTMENT'
      ) {
        return 'OSPREY';
      } else if (this.order && this.order.order_email) {
        return this.order.order_email;
      }
      return '-';
    },
    formatPrice(value) {
      return numeral(value.toFixed(2)).format('$0,0.00');
    },
    formatDate(dateTotransform) {
      var date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss (UTC Z)');
    },
    calculateRefundTotal(refunds) {
      let total = 0;
      refunds.forEach(refund => {
        total += refund.amount || 0;
      });
      return parseFloat(total.toFixed(2));
    },
    sendTransactionsToOsprey(transactionIds) {
      if (transactionIds?.length) {
        this.sendingTransactionsInprogress = transactionIds;
        this.$store
          .dispatch('stripe/sendTransactionsToOsprey', {
            payment_ids: transactionIds
          })
          .then(response => {
            if (response?.data?.Success) {
              this.$store.dispatch('setSnackbar', { type: 'success', message: `Transaction${transactionIds.length > 1 ? 's' : ''} sent successfully to Osprey` });
            }
            return Promise.resolve();
          })
          .catch(error => {
            console.log('[ sendTransactionsToOsprey ] ERROR:', error);
            this.$store.dispatch('setSnackbar', {
              type: 'error',
              message: error?.response?.data ?? `Unexpected error occurred while sending transaction to Osprey! Error: ${error}`
            });
            return Promise.reject(error);
          })
          .finally(() => {
            this.sendAllTransactionToOspreyLoading = false;
            this.sendingTransactionsInprogress = [];
            if (this.order) {
              setTimeout(() => {
                this.$store.dispatch('stripe/getPaymentHistory', { paymentIds: this.order.payments });
              }, 1000);
            }
          });
      } else {
        this.sendAllTransactionToOspreyLoading = false;
      }
    },
    isTransactionFound(transaction) {
      return transaction.refunds && !this.expanded.find(expanded => expanded.payment_db_id === transaction.payment_db_id);
    },
    getRefundsForTransaction(transaction) {
      let foundTransaction = this.expanded.find(expanded => expanded.payment_db_id === transaction.payment_db_id);
      if (!foundTransaction) {
        this.expanded.push(transaction);
        this.$store.dispatch('stripe/loadRefunds', { transactions: [transaction] });
      }
    },
    getAllTransactionRefund() {
      let promises = [];
      if (this.transactions) {
        this.$store.dispatch('stripe/loadRefunds', { transactions: this.transactions });
      }
    },
    getFailedSentTransactionsAndRefunds() {
      this.failedSentTransactions = [];
      let transactionIds = Object.keys(this.refunds);
      this.transactions.forEach(payment => {
        if (payment.sent_to_opsrey_error) {
          this.failedSentTransactions.push(payment.payment_db_id);
        }
      });
      if (this.resendAttemptCount < this.resendAttemptLimit) {
        if (this.transactions.length > 0) {
          this.resendAttemptCount++;
          setTimeout(() => {
            this.sendAllTransactionToOsprey();
          }, 1000);
        }
      }
    },
    removeRefunds(transaction) {
      for (let index = 0; index < this.expanded.length; index++) {
        if (this.expanded[index] === transaction) {
          this.expanded.splice(index, 1);
          break;
        }
      }
    },
    getRefunds() {
      let transactionIds = [];
      this.transactions.forEach(transaction => {
        transactionIds.push(transaction.payment_db_id);
      });
      for (let index = 0; index < transactionIds.length; index++) {
        this.$set(this.transactionRefunds, transactionIds[index], this.refunds[transactionIds[index]]);
      }
      this.getFailedSentTransactionsAndRefunds();
    },
    calculateRefundSum(refunds) {
      let sum = 0;
      refunds.forEach(refund => {
        sum += parseFloat(refund.amount);
      });
      return parseFloat(sum.toFixed(2));
    },
    refundAmount(item) {
      this.$emit('refundAmount', item);
    },
    getRefundText(transaction) {
      let text = '';
      let total = 0;
      if (transaction.card_stripe_id) {
        if (transaction.refunds && transaction.refunds.length) {
          transaction.refunds.forEach(refund => {
            total += refund.amount || 0;
          });
          text = transaction.amount.toFixed(2) === total.toFixed(2) ? 'Full refund' : 'Partial refund';
        }
      } else if (transaction.bank_account_stripe_id) {
        let pendingRefund = transaction.refunds.find(item => {
          return item.status === 'pending';
        });
        if (pendingRefund) {
          text = 'Refund pending';
        } else {
          transaction.refunds.forEach(refund => {
            total += refund.amount || 0;
          });
          text = transaction.amount.toFixed(2) === total.toFixed(2) ? 'Full refund' : 'Partial refund';
        }
      }
      return text;
    },
    setExpandedRows(expanded) {
      this.expanded = expanded;
    }
  }
};
</script>

<style>
.agentEmail {
  color: #757575;
  letter-spacing: normal !important;
  font-size: 0.75rem !important;
  font-weight: 400;
}

.paymentHistoryTable {
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: normal;
  font-size: 0.75rem !important;
  font-weight: 400;
}

.v-data-table table tbody td.text-start p {
  margin-bottom: 0px;
}

.v-data-table tbody tr.v-data-table__expanded__content {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fafafa;
}
</style>
