<template>
  <v-hover>
    <v-card slot-scope="{ hover }" outlined rounded elevation="1" :class="hover ? 'selected-card-background' : ''">
      <v-card-title class="justify-space-between mb-n6">
        <span class="body-2 grey--text text--darken-2 ml-n1">{{ notification.title || '' }}</span>
        <span class="caption grey--text text--darken-3">{{ formatDate(notification.date) }}</span>
      </v-card-title>
      <v-card-text class="pb-4">
        <v-row class="d-flex align-center">
          <v-col cols="1" class="d-flex align-center justify-center" elevation="none">
            <v-btn v-if="hover" class="pt-1" icon color="transparent" :disabled="isCloseNotificationClicked" @click="closeNotificationCard(notification)">
              <v-icon color="grey darken-2">mdi-close</v-icon>
            </v-btn>
            <v-icon v-else :color="notificationColor" class="">{{ notificationIcon }}</v-icon>
          </v-col>
          <v-col cols="11" class="body-2 wrap-word grey--text text--darken-3">
            <span>{{ content.message }}</span>
            <a v-if="isNotificationDetailsAvailable" class="subtitle-2 ml-1 blue-grey--text text--darken-1" @click="showNotificationDetails()"
              >Details
              <v-icon small>mdi-chevron-down</v-icon>
            </a>
            <v-row v-else class="pl-1 mt-1" no-gutters>
              <span>{{ formatDetails(content.details) }}.</span>
              <router-link v-if="content.link" :to="content.link" class="cyan--text text--darken-3 ml-n1 mt-1"> <v-icon small>mdi-chevron-right</v-icon>View order </router-link>
              <v-spacer />
              <a class="subtitle-2 mt-1 blue-grey--text text--darken-1" @click="hideNotificationDetails()">Hide
                <v-icon small>mdi-chevron-up</v-icon>
              </a>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: ['notification', 'type'],
  data() {
    return {
      isCloseNotificationClicked: false,
      isNotificationDetailsHidden: true
    };
  },
  computed: {
    content() {
      return this.notification?.content ?? {};
    },
    notificationIcon() {
      return this?.type === 'success' ? 'mdi-bell-check-outline' : this?.type === 'error' ? 'mdi-alert-circle' : 'mdi-bell-outline';
    },
    notificationColor() {
      return this?.type === 'success' ? 'green darken-2' : this?.type === 'error' ? 'red darken-2' : 'grey darken-2';
    },
    isNotificationDetailsAvailable() {
      return this.isNotificationDetailsHidden && (this.content.link || this.content.details);
    }
  },
  methods: {
    formatDate(dateTotransform) {
      return this.$moment(dateTotransform).format('DD/MM/YY [at] HH:mm:ss');
    },
    closeNotificationCard(notification) {
      this.isCloseNotificationClicked = true;
      this.$emit('hideNotification', notification.firestoreID);
      this.isCloseNotificationClicked = false;
    },
    showNotificationDetails() {
      this.isNotificationDetailsHidden = false;
    },
    hideNotificationDetails() {
      this.isNotificationDetailsHidden = true;
    },
    formatDetails(text) {
      return /\r|\n/.exec(text) ? text : text.replaceAll('-', '\n-')
    }
  }
};
</script>

<style scoped>
.wrap-word {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-line;
}
.action-row {
  width: 440px !important;
}
.selected-card-background {
  background-color: rgba(248, 248, 248, 0.1);
}
</style>
