<template>
  <v-dialog v-if="isDialogOpen" v-model="isDialogOpen" transition="fade-transition" persistent max-width="600">
    <v-row class="justify-center ml-0">
      <v-avatar color="warning" size="55" class="dialog-icon mt-n4">
        <v-icon large color="white">mdi-alert</v-icon>
      </v-avatar>
    </v-row>
    <v-card>
      <v-system-bar color="warning" height="5"></v-system-bar>
      <v-card-title class="mt-10 justify-center">
        <span class="title font-weight-regular">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-row class="justify-center mt-2">
          <span class="subtitle-1 grey--text text--darken-2 text-center">{{ text }}</span>
        </v-row>
      </v-card-text>
      <v-divider class="mt-3"></v-divider>
      <v-card-actions class="justify-space-between">
        <v-btn color="grey darken-1" class="subtitle-1 font-weight-medium text-capitalize px-5" outlined small @click="closeDialog()">{{ closeText }}</v-btn>
        <v-btn color="warning" class="subtitle-1 ml-3 font-weight-medium text-capitalize px-5" small @click="confirmAction()">{{ confirmText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isDialogOpened: Boolean,
    title: {
      type: String,
      default: 'Warning'
    },
    text: String,
    closeText: {
      type: String,
      default: 'No'
    },
    confirmText: {
      type: String,
      default: 'Yes'
    }
  },
  computed: {
    isDialogOpen() {
      return this.isDialogOpened;
    }
  },
  methods: {
    confirmAction() {
      this.$emit('onConfirm');
    },

    closeDialog() {
      this.$emit('onClose');
    }
  }
};
</script>

<style scoped>
.dialog-icon {
  position: absolute;
  z-index: 1000;
}
</style>
