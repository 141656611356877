import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class UserLogsService extends FirestoreModel {
  constructor() {
    super(collections.stamp_logs);
  }
}

export default new UserLogsService();
