import customDesignsService from "../../services/customDesignsService"

const artworkStore = {
	state: {
		designList: null,
		selectedDesign: null
	},
	getters: {
		getDesignList (state) {
			return state.designList
		},
		getSelectedDesign (state) {
			return state.selectedOrder
		}
	},
	mutations: {
		setDesignList (state, list) {
			state.designList = list
		},
		setSelectedDesign (state, design) {
			state.selectedDesign = design
		}
	},
	actions: {
		loadCustomDesigns ({commit, getters}, payload) {
			return customDesignsService.getAll().then((data) => {
				if (data === null) {
					console.log('Load custom designs has failed: ')
					return
				}
				console.log('Load custom designs has succeeded: ', data)
				commit('setDesignList', data)
			})
		}
	}
}

export { artworkStore as default }
