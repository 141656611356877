const Exception = require('../../models/exceptions').Exception;

const errorCodesMap = {
  unauthorized: 'Unauthorized',
  unknown: 'Unknown',
  notFound: 'NotFound',
  invalidParameter: 'InvalidParameter',
  externalError: 'ExternalError',
  ospreyResponse: 'OspreyResponse',
  dmmFail: 'DMMFail',
  default: 'Unknown',
  alreadyExists: 'ResourceAlreadyExists',
  operationError: 'OperationUnsuccessful',
  notAllowed: 'MethodNotAllowed'
};

const errorStatusMap = {
  unknown: 400,
  unauthorized: 401,
  notFound: 404,
  invalidParameter: 422,
  externalError: 502,
  alreadyExists: 409,
  notAllowed: 405
};

const methods = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

const unknown = new Exception({
  status: errorStatusMap.unknown,
  code: errorCodesMap.unknown,
  message: 'An unknown error occurred. Please try again later.'
});
const unauthorized = new Exception({
  status: errorStatusMap.unauthorized,
  code: errorCodesMap.unauthorized,
  message: 'You are not authorized to access this resource.'
});
const notFound = resource =>
  new Exception({
    status: errorStatusMap.notFound,
    code: errorCodesMap.notFound,
    message: `The requested ${resource} was not found.`
  });
const operationError = ({ message = null, operation, error }) => {
  if (!operation && !message) {
    return new Exception({
      status: errorStatusMap.unknown,
      code: errorCodesMap.operationError,
      message: 'Operation failed'
    });
  }
  const exceptionPayload = {
    status: errorStatusMap.unknown,
    code: errorCodesMap.operationError,
    message: message || `${operation} could not be completed because of an unknown error`
  };
  if (error) {
    if (error.message) {
      exceptionPayload.message = `${operation} cloud not be completed because ${error.message}.`;
    }
    exceptionPayload.stack = error?.stack || error?.message || error;
  }
  return new Exception(exceptionPayload);
};
const invalidParameter = param =>
  new Exception({
    status: errorStatusMap.invalidParameter,
    code: errorCodesMap.invalidParameter,
    message: `Missing or invalid parameter(s): ${param}.`
  });
const externalError = (code, message) => new Exception({ status: errorStatusMap.externalError, code, message });
const alreadyExists = resource =>
  new Exception({
    status: errorStatusMap.alreadyExists,
    code: errorCodesMap.alreadyExists,
    message: `The ${resource} you are trying to access, already exists.`
  });
const onlyAllowedMethod = method =>
  new Exception({
    status: errorStatusMap.notAllowed,
    code: errorCodesMap.notAllowed,
    message: `Only ${method} is allowed for this endpoint.`
  });

/**
 *  Check if params exist in object
 *
 *  Checks for missing params first, if there are no missing params, checks types, if no mismatched type, returns null.
 * @param {any} body request body to check
 * @param {any} params array of objects: {property: nameOfPropertyToCheckFor, type: typeOfPropertyToCheckFor}
 *
 * or array of string with property names
 * @returns an exception if one of params is not found or does not match type, returns null if all params are ok;
 *
 * @example const error = paramCheck(requestBody, [{ type: 'boolean' }]);
 * if(error) {
 *      throw error
 * }
 * */
function paramCheck(body, params, checkWithType = false) {
  const missingParams = missingParamCheck(body, params);
  if (checkWithType && !missingParams && params.length && Object.hasOwn(params[0], 'type')) {
    const wrongTypes = typeCheck(body, params);
    return wrongTypes;
  }
  return missingParams || null;
}

function getPropertyOrItem(item) {
  //in case if passed object is array
  return Object.hasOwn(item, 'property') ? item.property : item;
}

function missingParamCheck(body, params) {
  const missingParams = params.filter(param => !Object.hasOwn(body, getPropertyOrItem(param))).map(param => getPropertyOrItem(param));
  if (missingParams.length) {
    return invalidParameter(missingParams.join(', '));
  }
  return null;
}
function typeCheck(body, params) {
  const badTypes = params
    .filter(item => Object.hasOwn(item, 'type') && typeof body[item.property] !== item.type)
    .map(({ property, type }) => `Param: '${property}', should be type of: '${type}'`);
  if (badTypes.length) {
    return invalidParameter(badTypes.join(', '));
  }
  return null;
}

function makeException(error) {
  return error instanceof Exception ? error : new Exception(error);
}

function makeUserFriendlyException(error) {
  return error instanceof Exception ? error : unknown;
}

function logError(error = unknown, log) {
  const errorToSend = makeException(error);
  if (typeof log === 'string') {
    console.log(`${log} Unexpected error: `, errorToSend);
    return errorToSend;
  }
  if (!log) {
    console.log(`Unexpected error: `, errorToSend);
    return errorToSend;
  }

  log('Unexpected error', errorToSend);
  return errorToSend;
}

function throwNetworkError(res, error, log) {
  const errorToSend = logError(error, log);
  return res.status(errorToSend.status || 500).json(errorToSend);
}

module.exports = {
  unknown,
  unauthorized,
  notFound,
  invalidParameter,
  externalError,
  errorCodesMap,
  paramCheck,
  errorStatusMap,
  alreadyExists,
  operationError,
  makeException,
  makeUserFriendlyException,
  logError,
  methods,
  onlyAllowedMethod,
  throwNetworkError
};
