<template>
  <div>
    <v-alert v-model="isAlertVisible" :type="alertType" dismissible class="mb-n1 mt-2">{{ alertText }}</v-alert>
    <v-layout row wrap class="my-3 ml-2">
      <v-flex xs12>
        <h4 class="my-2">Reschedule Drops</h4>
        <p class="ml-2">Select the date to reschedule drops</p>
      </v-flex>
      <v-layout>
        <v-flex xs3>
          <v-layout class="mx-2">
            <v-menu max-width="290">
              <template v-slot:activator="{ on }">
                <v-text-field :value="currentExecutionDate" label="Start Date" readonly v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="currentExecutionDate"></v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>

        <v-flex xs3>
          <v-menu max-width="290">
            <template v-slot:activator="{ on }">
              <v-text-field :value="toUpdateExecutionDate" label="Reschedule Date" readonly v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="toUpdateExecutionDate"></v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs3>
          <v-btn :disabled="isButtonDisabled" class="mx-2" @click="openDialog">
            <span>Reschedule Drops</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-layout>
    <confirmation-dialog
      :isDialogOpened="isDialogOpened"
      title="Warning"
      text="Are you sure you want to reschedule drops?"
      closeText="No"
      confirmText="Yes"
      @onConfirm="rescheduleDrops"
      @onClose="closeDialog"
    ></confirmation-dialog>
  </div>
</template>

<script>
import Dialog from '../../dialog/ConfirmationWarningDialog.vue';

export default {
  components: {
    'confirmation-dialog': Dialog
  },
  data() {
    return {
      alertText: 'Drops are not available for the selected date',
      currentExecutionDate: '',
      toUpdateExecutionDate: '',
      scheduleDrops: null,
      isDialogOpened: false,
      isAlertVisible: false,
      alertType: 'error'
    };
  },
  computed: {
    isButtonDisabled() {
      return !this.currentExecutionDate || !this.toUpdateExecutionDate;
    }
  },
  methods: {
    async rescheduleDrops() {
      this.closeDialog();
      await this.makeRescheduleDrops();
    },

    async getScheduleDropsByDate() {
      const drops = await this.$store.dispatch('scheduled_drops/getScheduleDropsByDate', {
        date: this.currentExecutionDate
      });
      this.scheduleDrops = drops;
    },
    async checkIfDropsAreAvailable() {
      try {
        await this.getScheduleDropsByDate();
      if (!this.scheduleDrops) {
        this.showErrorAlert();
        throw new Error('Drops are not available for the selected date');
      }
      } catch (error) {
        console.log('Error while checking if drops are available', error);
        throw error;
      }
    },
    async makeRescheduleDrops() {
      try {
        await this.checkIfDropsAreAvailable();
        await this.$store.dispatch('scheduled_drops/updateOrAddScheduledDrop', {
          date: this.toUpdateExecutionDate,
          drops: this.scheduleDrops
        });
        this.showSuccessAlert();
      } catch (error) {
        console.log('Error while rescheduling drops', error);
        this.alertText = `Error while rescheduling drops: ${error.message}`;
        this.alertType = 'error';
        this.isAlertVisible = true;
      }
    },
    showErrorAlert() {
      this.isAlertVisible = true;
      this.alertType = 'error';
    },
    showSuccessAlert() {
      this.alertText = 'Drops are rescheduled successfully';
      this.isAlertVisible = true;
      this.alertType = 'success';
    },
    closeDialog() {
      this.isDialogOpened = false;
    },
    openDialog() {
      this.isDialogOpened = true;
    }
  }
};
</script>
