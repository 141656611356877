import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class LevelPermissionService extends FirestoreModel {
  constructor() {
    super(collections.access_levels);
  }
}

export default new LevelPermissionService();
