<template>
  <div class="site-table">
    <v-dialog v-model="isOpen" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <h1 v-if="template._id" class="headline ml-3"><v-icon large color="cyan darken-2" class="mb-1 mr-2">mdi-message-draw</v-icon>Edit SMS Template</h1>
          <h1 v-else class="headline ml-3"><v-icon large color="cyan darken-2" class="mb-1 mr-2">mdi-message-plus-outline</v-icon>Add New SMS Template</h1>
          <v-spacer></v-spacer>
          <v-btn icon large color="red darken-1" class="mt-n1" @click="isOpen = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-alert v-if="errorMessage" type="error">{{ errorMessage }}</v-alert>
          <v-row>
            <v-col cols="12">
              <v-text-field id="templateLabel" v-model="template.name" label="Name *" hint="Enter template Name" required type="text"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field id="templateCode" v-model="template.code" label="Code *" hint="Enter template Code" required type="text"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-switch v-model="template.active" color="cyan darken-2" label="Active"></v-switch>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="template.content" counter label="Message *" :rules="contentRules"></v-textarea>
            </v-col>
          </v-row>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="isOpen = false">Close</v-btn>
          <v-btn color="blue" text :disabled="isTemplateSavingDisabled" @click="saveTemplate">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <template>
          <v-toolbar flat class="site-table--header site-table--lists">
            <v-col md="4" class="pl-0">
              <v-toolbar-title class="headline"> SMS Templates </v-toolbar-title>
            </v-col>
            <v-col md="4">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-col>
            <v-col md="4" xl="2" class="pr-0">
              <div class="flexing-to-end">
                <v-btn color="blue white--text" @click="openDialog(null)">+ New SMS Template</v-btn>
              </div>
            </v-col>
          </v-toolbar>
        </template>
        <v-card>
          <stamp-data-table v-if="templates" :headers="headers" :data="templates" :search="search" item-key="id" sort-by="name" :sort-desc="false">
            <template #[`item.active`]="{ item }">
              <v-icon :color="item.active ? 'green' : 'red'" :title="item.active ? 'Active' : 'Inactive'">
                {{ item.active ? 'mdi-check' : 'mdi-diameter-variant' }}
              </v-icon>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn icon @click="openDialog(item)">
                <v-icon color="teal">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="deleteItem(item)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </template>
          </stamp-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirm-dialog-global
      title="Delete"
      message="Are you sure you want to delete this SMS template?"
      :isOpen="confirmDialogIsOpen"
      :data="deleteTemplateID"
      @confirm="confirmDelete"
    >
    </confirm-dialog-global>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ConfirmDialogGlobal from '@/components/ui/dialogs/ConfirmDialog';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: {
    'confirm-dialog-global': ConfirmDialogGlobal,
    'stamp-data-table': DataTable
  },
  data() {
    return {
      isOpen: false,
      search: '',
      errorMessage: '',
      contentLengthLimit: 1000,
      confirmDialogIsOpen: false,
      deleteTemplateID: '',
      template: {
        _id: '',
        name: '',
        code: '',
        content: '',
        active: true
      },
      contentRules: [v => v.length <= this.contentLengthLimit || `Max ${this.contentLengthLimit} characters`],
      headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Code', align: 'left', value: 'code' },
        { text: 'Active', value: 'active' },
        { text: 'Actions', value: 'actions', sortable: false, width: 115 }
      ]
    };
  },
  computed: {
    ...mapGetters('sms_templates', {
      templates: 'getSMSTemplates'
    }),
    isTemplateSavingDisabled() {
      return !this.template.name || !this.template.code || !this.template.content || this.template.content.length > this.contentLengthLimit;
    }
  },
  created() {
    this.getTemplates();
  },
  methods: {
    getTemplates() {
      this.$store.dispatch('showLoader', { message: 'Loading templates...' });
      this.$store
        .dispatch('sms_templates/fetchSMSTemplates', { saveInStore: true })
        .then(() => {
          this.$store.dispatch('hideLoader');
        })
        .catch(error => {
          this.$store.dispatch('hideLoader');
          this.$store.dispatch('setSnackbar', { type: 'error', message: error });
        });
    },
    saveTemplate() {
      this.errorMessage = '';
      this.$store
        .dispatch('sms_templates/isSMSTemplateExistsByCode', { _id: this.template._id, code: this.template.code })
        .then(exists => {
          if (exists) {
            this.errorMessage = 'Code already exists!';
          } else {
            this.$store.dispatch('showLoader', { message: 'Saving template...' });
            this.$store
              .dispatch(`sms_templates/${this.template._id ? 'updateTemplate' : 'insertTemplate'}`, this.template)
              .then(() => {
                this.$store.dispatch('hideLoader');
                this.getTemplates();
                this.isOpen = false;
              })
              .catch(error => {
                this.$store.dispatch('hideLoader');
                this.errorMessage = error;
              });
          }
        })
        .catch(error => {
          this.errorMessage = error;
        });
    },
    openDialog(item) {
      this.errorMessage = '';
      console.log(item);
      if (item) {
        this.template._id = item._id;
        this.template.name = item.name;
        this.template.code = item.code;
        this.template.content = item.content;
        this.template.active = item.active;
      } else {
        this.template._id = '';
        this.template.name = '';
        this.template.code = '';
        this.template.content = '';
        this.template.active = true;
      }
      this.isOpen = true;
    },
    deleteItem(item) {
      this.deleteTemplateID = item._id;
      this.confirmDialogIsOpen = true;
    },
    confirmDelete(response) {
      if (response.confirmed) {
        this.$store.dispatch('showLoader', { message: 'Deleting template...' });
        this.$store
          .dispatch('sms_templates/deleteTemplate', { _id: response.data })
          .then(() => {
            this.$store.dispatch('hideLoader');
            this.$store.dispatch('setSnackbar', { type: 'success', message: 'Temlpate deleted successfully' });
            this.getTemplates();
          })
          .catch(error => {
            this.$store.dispatch('hideLoader');
            this.$store.dispatch('setSnackbar', { type: 'error', message: error });
          });
      }
      this.confirmDialogIsOpen = false;
    }
  }
};
</script>
<style scoped>
.site-table {
  padding-top: 0 !important;
}
</style>
