<template>
  <div class="site-table">
    <v-dialog :key="isDialogVisible" v-model="isDialogVisible" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="userLevelName" label="User Level Name *" required :rules="userLevelNameRules"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="userLevelDescription" label="User Level Description *" required :rules="userLevelDescriptionRules"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="userLevelValue" label="User Level Value *" required :disabled="isUserLevelDisabled" :rules="userLevelValueRules"></v-text-field>
                <div v-if="isUserLevelDisabled">
                  <p class="red--text">* Super Admin level cannot be changed!</p>
                </div>
              </v-col>
              <v-col>
                <div class="flexing-to-end">
                  <v-btn color="blue" text class="mr-2" @click="close">Close</v-btn>
                  <v-btn :disabled="!isFormValid" color="success" @click="saveLevel">Save</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <small>*indicates required field</small>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row>
      <div class="site-table">
        <v-toolbar flat class="site-table--header">
          <v-row class="justify-space-between mt-2 mb-3">
            <v-col cols="12" sm="3" md="6">
              <v-toolbar-title class="headline">Levels</v-toolbar-title>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="5" md="4" xl="2">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="2" xl="1">
              <v-row class="justify-end">
                <v-btn dark class="ml-0 mt-2 blue darken-1 white--text mr-3" @click="addNewLevel"><v-icon class="mr-1">mdi-plus</v-icon>New Level</v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-toolbar>

        <stamp-data-table
          :headers="headers"
          :data="levelList"
          item-key="_id"
          :search="search"
          :is-items-per-page-disabled="true"
          :actions="actions"
          :sort-by="'name'"
          :sort-desc="false"
          @editItem="editItem"
          @deleteItem="deleteItem"
        >
        </stamp-data-table>
      </div>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DataTable from '../common/DataTable.vue';
import accessLevelService from '../../services/accessLevelService.js';
export default {
  name: 'levels',
  components: { 'stamp-data-table': DataTable },
  data() {
    return {
      isDialogVisible: false,
      search: '',
      headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Description', value: 'description', align: 'left' },
        { text: ' Value', value: 'value', align: 'center' }
      ],
      actions: [
        { icon: 'mdi-pencil', iconColor: 'teal', title: 'Edit Level', eventName: 'editItem' },
        { icon: 'mdi-delete', iconColor: 'red', title: 'Delete Level', eventName: 'deleteItem' }
      ],
      isEditMode: false,
      editKey: null,
      isFormValid: true,
      userLevelName: '',
      userLevelNameRules: [v => !!v || 'Level name is required'],
      userLevelDescription: '',
      userLevelDescriptionRules: [v => !!v || 'User level description is required'],
      userLevelValue: '',
      userLevelValueRules: [v => !!v || 'Enter a valid value'],
      isUserLevelDisabled: false,
      levelList: []
    };
  },
  computed: {
    formTitle() {
      return this.isEditMode ? 'Edit Level' : 'New Level';
    },
    ...mapGetters('permissions', {
      levelParameters: 'levelsParam',
      levelsValueExist: 'levelsValExist',
      groupExist: 'groupExistInLevels'
    }),
    ...mapGetters('users', {
      userStore: 'user',
      userAccessLevel: 'userAccessLevel'
    })
  },
  created() {
    accessLevelService.attachRootListener((levels) => {
      this.levelList = levels;
    },
    { isIdReturned: true, isArray: true });
  },
  methods: {
    addNewLevel() {
      this.isDialogVisible = true;
    },
    async saveLevel() {
      if (this.levelsValueExist) {
        this.$store.dispatch('showAlert', { type: 'error', message: 'Value already exists!' });
      }
      const levelConfig = {
        levelNameToInsert: this.userLevelName,
        levelDescriptionsToInsert: this.userLevelDescription,
        levelValueToInsert: this.userLevelValue,
        levelKey: this.editKey
      };

      this.$store.dispatch(`permissions/${!this.isEditMode ? 'save' : 'update'}Level`, levelConfig)

      if (this.isEditMode) {
        // when access level is edited, update in routes
				await this.$store.dispatch('permissions/updateLevelsInRoutes', { id: this.editKey, value: this.userLevelValue })
        // update value of acces level in groups() when value is edited
        await this.$store.dispatch('permissions/updateAccessLevelsInGroups', {
          levelKey: this.editKey,
          levelValueToInsert: this.userLevelValue,
          levelNameToInsert: this.userLevelName
        });
      }
      this.close();
    },
    editItem(item) {
      if (Number(item.value) === 100 && Number(this.userAccessLevel.value) !== 100) {
        this.$store.dispatch('showAlert', { type: 'error', message: 'Sorry , you do not have the right to edit this level!' });
        this.isDialogVisible = false;
        this.isUserLevelDisabled = true;
      } else {
        this.isEditMode = true;
        this.editKey = item._id;
        this.userLevelName = item.name;
        this.userLevelValue = item.value;
        this.userLevelDescription = item.description;
        this.isDialogVisible = true;
      }
    },
    close() {
      this.isDialogVisible = false;
      this.userLevelName = '';
      this.userLevelValue = '';
      this.userLevelDescription = '';
      this.isEditMode = false;
    },
    async deleteItem(item) {
      if (Number(item.value) === 100) {
        this.$store.dispatch('showAlert', { type: 'error', message: 'Sorry , Super Admin level cannot be deleted!' });
      } else {
        const isDeleteConfirmed = confirm('Are you sure you want to delete this item?');
        if (isDeleteConfirmed) {
          // when access level is deleted, delete from groups
					await this.$store.dispatch('permissions/deleteAccessLevelFromGroups', { levelKey: item._id })
					// when access level is deleted, delete from groups
					await this.$store.dispatch('permissions/deleteAccessLevelFromUsers', { levelKey: item._id })
          this.$store.dispatch('permissions/deleteLevel', { key: item._id });
        }
      }
    }
  }
};
</script>
<style scoped></style>
