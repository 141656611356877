import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class ScheduledDropsService extends FirestoreModel {
  constructor() {
    super(collections.scheduled_drops);
  }

  async setDropsByDate(date, drops) {
    try {
      if (!date || !drops) {
        throw new Error('Missing date or drop data');
      }
      const siteProductsRef = this.collectionRef.doc(date);
      await siteProductsRef.set(drops);
    } catch (error) {
      console.log('[setDropsByDate]: ', error);
      throw new Error(error);
    }
  }

  async updateDropsByDate(date, drops) {
    try {
      if (!date || !drops) {
        throw new Error('Missing date or drop data');
      }
      const siteProductsRef = this.collectionRef.doc(date);
      await siteProductsRef.update(drops);
    } catch (error) {
      console.log('[updateDropsByDate]: ', error);
      throw new Error(error);
    }
  }
}

export default new ScheduledDropsService();
