<template>
  <div>
    <v-row no-gutters class="mb-1" align="center" justify="start">
      <v-col cols="1" class="body-2 items-center justify-start">Order Status</v-col>
      <v-col cols="auto">
        <v-row no-gutters align="center" justify="start">
          <v-chip-group v-model="selectedOrderStatus" active-class="primary--text" @change="setSelectedOrderStatus(selectedOrderStatus)">
            <v-chip v-for="({ label, value }, index) in orderStatusName" :key="index" filter outlined :value="value">
              <span>{{ capitalize(label) }}</span>
            </v-chip>
          </v-chip-group>
          <v-btn fab x-small icon outlined color="grey lighten-2" :disabled="!selectedOrderStatus" @click="setSelectedOrderStatus('')">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-1" align="center" justify="start">
      <v-col cols="1" class="body-2">Drop Status</v-col>
      <v-col cols="auto">
        <v-row no-gutters align="center" justify="start">
          <v-chip-group v-model="selectedDropStatus" active-class="primary--text" @change="setSelectedDropStatus(selectedDropStatus)">
            <v-chip v-for="({ label, value }, index) in dropStatusNames" :key="index" filter outlined :value="value">
              <span>{{ capitalize(label) }}</span>
            </v-chip>
          </v-chip-group>

          <v-btn fab x-small icon outlined color="grey lighten-2" :disabled="!selectedDropStatus" @click="setSelectedDropStatus('')">
            <v-icon color="grey">mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({ orderStatus: '', dropStatus: '' })
    }
  },
  emits: ['input'],
  data() {
    return {
      orderStatusName: [
        { label: 'Unpaid', value: 'unpaid' },
        { label: 'Past Due', value: 'past_due	' },
        { label: 'Active', value: 'active' },
        { label: 'Paused', value: 'paused' },
        { label: 'Canceled', value: 'canceled completed' },
        { label: 'Cancellation Pending', value: 'cancellation_pending' },
        { label: 'Cancellation Failed', value: 'cancellation_failed' }
      ],
      dropStatusNames: [
        { label: 'In Progress', value: 'in_progress' },
        { label: 'Scheduled', value: 'scheduled' },
        { label: 'Error', value: 'error' }
      ],
      selectedOrderStatus: this.$props.value?.orderStatus || '',
      selectedDropStatus: this.$props.value?.dropStatus || ''
    };
  },
  methods: {
    setSelectedOrderStatus(orderStatus) {
      this.selectedOrderStatus = orderStatus;
      this.$emit('input', { orderStatus, dropStatus: this.selectedDropStatus });
    },
    setSelectedDropStatus(dropStatus) {
      this.selectedDropStatus = dropStatus;
      this.$emit('input', { orderStatus: this.selectedOrderStatus, dropStatus: dropStatus });
    },
    capitalize(text) {
      return (
        text
          ?.toLowerCase()
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ') || ''
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
