<template>
  <v-container fluid>
    <v-row>
      <v-btn icon large @click="navigateBack()"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <span class="ml-3 pt-1 title font-weight-regular">{{ title }}</span>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <insert-edit-route :modelData="route" :isEditEnabled="isEditEnabled" @saveModel="saveRoute"></insert-edit-route>
  </v-container>
</template>

<script>
import InsertEditRoute from '../../common/dynamicForm/FormGenerator';

export default {
  components: {
    'insert-edit-route': InsertEditRoute
  },
  data() {
    return {
      routeDbId: '',
      route: [],
      isEditEnabled: true,
      isLoading: false
    };
  },
  computed: {
    title() {
      return this.routeDbId ? `${this.isEditEnabled ? 'Edit' : 'View'}: ${this.routeDbId}` : 'Add New Route';
    }
  },
  async mounted() {
    this.isEditEnabled = this.$route.name !== 'ViewRoute';
    this.routeDbId = Object.values(this.$route.params)[0];
    this.route = this.$store.getters['routes/getSelectedModel'];

    if (!this.route.length && this.routeDbId) {
      const route = await this.$store.dispatch('routes/getRouteById', this.routeDbId);
      await this.$store.dispatch('routes/initSelectedModel', route);
      this.route = this.$store.getters['routes/getSelectedModel'];
    }
  },
  methods: {
    async saveRoute(route) {
      this.isLoading = true;
      const routeObject = {
        id: this.routeDbId || '',
        data: route
      };
      try {
        const result = await this.$store.dispatch('routes/saveRoute', routeObject);
        this.showAlert('success', result.message);
        this.navigateBack();
      } catch (error) {
        this.showAlert('error', error.message || error);
      } finally {
        this.isLoading = false;
      }
    },
    navigateBack() {
      this.route = [];
      this.$store.commit('routes/clearModel');
      this.$router.replace(`/${this.$route.path.split('/')[1]}`);
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    }
  }
};
</script>
