<template>
  <v-row class="mt-n3 mb-5">
    <v-toolbar flat class="site-table--header mb-n2">
      <v-toolbar-title class="body-2 mr-2">Environment Type</v-toolbar-title>
      <v-chip-group v-model="selectedEnv" active-class="primary--text">
        <template v-for="(envName, index) in enviroments">
          <v-chip :key="index" filter outlined>
            <span>{{ capitalize(envName) }}</span>
          </v-chip>
        </template>
      </v-chip-group>

      <v-btn fab x-small icon outlined color="grey lighten-2" :disabled="selectedEnv === ''" @click="clearSelectedEnv()">
        <v-icon color="grey">mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
  </v-row>
</template>

<script>
export default {
  props: ['envFromQueryParam', 'testSitesList', 'siteDbIdAttrName', 'originSiteDbIdAttrName', 'isFiltersCleared'],
  data() {
    return {
      enviroments: ['live', 'test'],
      selectedEnv: ''
    };
  },
  watch: {
    selectedEnv() {
      this.setupFilters();
    },
    envFromQueryParam() {
      this.initEnvByQueryParam();
    },
    isFiltersCleared() {
      if (this.isFiltersCleared) this.clearSelectedEnv();
    }
  },
  mounted() {
    this.initEnvByQueryParam();
  },
  methods: {
    initEnvByQueryParam() {
      this.selectedEnv = this.envFromQueryParam ? this.enviroments.findIndex(item => item === this.envFromQueryParam) : '';
    },
    clearSelectedEnv() {
      this.selectedEnv = '';
    },
    setupFilters() {
      if (this.selectedEnv !== 0 && !this.selectedEnv) {
        this.emitEnvType({ selectedEnv: '', envFilters: '' });
        return;
      }

      const isTestEnvSelected = this.enviroments[this.selectedEnv] === 'test';
      const operator = isTestEnvSelected ? 'OR' : 'AND NOT';
      let filter = '';

      this.testSitesList.map(
        item =>
          (filter = filter
            ? `${filter} ${operator} ${this.siteDbIdAttrName}:${item} ${operator} ${this.originSiteDbIdAttrName}:${item}`
            : `${isTestEnvSelected ? '' : 'NOT'} ${this.siteDbIdAttrName}:${item} ${operator} ${this.originSiteDbIdAttrName}:${item}`)
      );
      this.emitEnvType({ selectedEnv: this.enviroments[this.selectedEnv], envFilters: filter });
    },
    emitEnvType(payload) {
      this.$emit('onEnvTypeChanged', payload);
    },
    capitalize(text) {
      if (!text || text.length <= 2) {
        return '';
      } else if (text.length >= 2) {
        return text.charAt(0).toUpperCase() + text.slice(1);
      }
    }
  }
};
</script>
