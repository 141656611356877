<template>
  <v-container fluid>
    <v-row>
      <v-btn icon large @click="navigateBack()"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <span class="ml-3 pt-1 title font-weight-regular">{{ title }}</span>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <insert-edit-product-type :modelData="productType" :isEditEnabled="isEditEnabled" :isLoading="isLoading" @saveModel="saveProductType"></insert-edit-product-type>
  </v-container>
</template>

<script>
import InsertEditProductType from '../../common/dynamicForm/FormGenerator';
import { mapGetters } from 'vuex';

export default {
  components: {
    'insert-edit-product-type': InsertEditProductType
  },
  data() {
    return {
      productTypeDbId: '',
      isEditEnabled: true,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('product_types', {
      productType: 'getSelectedModel'
    }),
    title() {
      return this.productTypeDbId ? `${this.isEditEnabled ? 'Edit' : 'View'}: ${this.productTypeDbId}` : 'Add New Product Type';
    }
  },
  async mounted() {
    this.isEditEnabled = this.$route.name !== 'ViewProductType';
    this.productTypeDbId = Object.values(this.$route.params)[0];
    if (this.productTypeDbId) {
      this.getProductTypeById();
    } else {
      this.$store.commit('product_types/clearModel');
      await this.$store.dispatch('product_types/initSelectedModel', []);
    }
  },
  methods: {
    async getProductTypeById() {
      this.$store.dispatch('showLoader');
      try {
        const productTypeModel = await this.$store.dispatch('product_types/getProductTypeById', this.productTypeDbId);
        await this.$store.dispatch('product_types/initSelectedModel', productTypeModel);
      } catch (error) {
        console.error('[getProductTypeById error ] ', error);
        this.showAlert('error', error.message || error || 'Could not load Product');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async saveProductType(product_type) {
      this.isLoading = true;
      const productTypeObject = {
        id: this.productTypeDbId || '',
        data: product_type
      };
      try {
        const result = await this.$store.dispatch('product_types/saveProductType', productTypeObject);
        this.setSnackbar('success', result.message);
        this.navigateBack();
      } catch (error) {
        this.showAlert('error', error.message || error);
      } finally {
        this.isLoading = false;
      }
    },
    navigateBack() {
      this.$store.commit('product_types/clearModel');
      this.$router.replace(`/${this.$route.path.split('/')[1]}`);
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    }
  }
};
</script>
