<template>
  <v-dialog :value="isDialogOpen" persistent max-width="600">
    <v-card>
      <v-card-title> {{ fieldName }} - Batch Editing</v-card-title>
      <v-card-text class="py-2">{{ text }}</v-card-text>
      <v-radio-group v-model="selectedOption" row mandatory class="px-6">
        <v-radio label="Included" value="included"></v-radio>
        <v-radio label="Price" value="price"></v-radio>
      </v-radio-group>
      <v-col cols="8" class="px-6 my-n4">
        <v-text-field v-if="isPriceFieldVisible" v-model="price" label="Price" type="number" min="0" step="0.1" prepend-inner-icon="mdi-currency-usd" outlined dense></v-text-field>
      </v-col>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" text @click="closeDialog">Cancel</v-btn>
        <v-btn color="primary" text @click="saveValue">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: 'By applying the selected option, all values will be updated in the selected column'
    }
  },
  data() {
    return {
      isDialogOpen: false,
      selectedOption: 'included',
      price: 0
    };
  },
  computed: {
    isPriceFieldVisible() {
      return this.selectedOption === 'price';
    }
  },
  watch: {
    value: {
      handler(value) {
        this.isDialogOpen = value;
      },
      immediate: true
    }
  },
  methods: {
    saveValue() {
      this.$emit('batchEdit', this.selectedOption === 'included' ? 0 : this.price);
      this.closeDialog();
    },
    closeDialog() {
      this.isDialogOpen = false;
      this.$emit('input', false);
    }
  }
};
</script>
