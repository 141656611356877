<template>
  <v-container fluid transition="fade-transition">
    <v-toolbar flat class="site-table--header site-table--lists">
      <v-row class="mt-n3 mb-0">
        <v-toolbar-title class="headline grey--text text--darken-3 ml-n3 pt-1">Product Services</v-toolbar-title>
        <v-spacer />
        <v-col cols="12" sm="5" md="4" lg="4" xl="3" class="text-center">
          <v-text-field v-model="search" label="Search" class="mt-n3" append-icon="mdi-magnify" outlined dense hide-details></v-text-field>
        </v-col>
        <v-btn color="blue" class="white--text ml-2 mr-n1" min-width="200" @click="addNewService"><v-icon left>mdi-plus-circle</v-icon>Add New Service</v-btn>

        <v-btn v-if="isUserSuperAdmin" color="red" class="white--text ml-2 mr-n1" min-width="200" @click="syncServices"
          ><v-icon left>mdi-sync-alert</v-icon>Sync All Services</v-btn
        >
      </v-row>
    </v-toolbar>
    <v-row class="mt-n4 mb-4">
      <v-toolbar flat class="site-table--header">
        <v-toolbar-title class="subtitle-1 mr-2">Status: </v-toolbar-title>
        <v-chip-group v-model="selectedStatus" active-class="primary--text" mandatory>
          <v-chip v-for="(filterName, index) in statusFilterItems" :key="index" filter outlined>
            <span>{{ filterName }}</span>
          </v-chip>
        </v-chip-group>
      </v-toolbar>
    </v-row>
    <div class="site-table mt-n5 ml-n1 mr-0">
      <DataTable :headers="headers" :data="productServices" :search="search" :is-loading="isProductServicesTableLoading" item-key="firebase_service_id">
        <template #[`item.options`]="{ item }">
          <v-tooltip v-if="getOptionNames(item.options).length" bottom>
            <template #activator="{ on }">
              <v-btn depressed small v-on="on">{{ getOptionNames(item.options).length }}</v-btn>
            </template>
            <span>{{ getOptionNames(item.options).toString() }}</span>
          </v-tooltip>
          <span v-else>{{ getOptionNames(item.options).length }}</span>
        </template>
        <template #[`item.firebase_service_id`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon color="amber darken-3" v-on="on" @click="copyItemToClipboard(item.firebase_service_id)">mdi-firebase</v-icon>
            </template>
            <span>Copy FirebaseID ( {{ item.firebase_service_id }} ) to clipboard</span>
          </v-tooltip>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn icon @click="editProductService(item)">
            <v-icon color="teal">mdi-pencil</v-icon>
          </v-btn>
        </template>
      </DataTable>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import DataTable from '@/components/common/DataTable.vue';
import productServiceServices from '../../../services/productServiceService';

export default {
  components: { DataTable },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Service ID (SQL)', value: 'service_id', sortable: true },
        { text: 'Netsuite ID', value: 'netsuite_id', sortable: true },
        { text: 'Salesforce ID', value: 'salesforce_id', sortable: true },
        { text: 'Salesforce Price Book Entry ID', value: 'salesforce_price_book_entry_id', sortable: true },
        { text: 'Stripe ID', value: 'stripe_id', sortable: true },
        { text: 'Tax Code', value: 'tax_code', sortable: true },
        { text: 'Service Options', value: 'options', align: 'center', sortable: false },
        { text: 'Firebase ID', value: 'firebase_service_id', align: 'center', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
      ],
      statusFilterItems: ['Inactive', 'Active'], //[1, 0]
      selectedStatus: 1
    };
  },
  computed: {
    ...mapGetters('product_services', {
      allProductServices: 'getProductServices',
      isProductServicesTableLoading: 'getProductServicesLoadingState'
    }),
    ...mapGetters('users', {
      userAccessLevel: 'userAccessLevel'
    }),
    isUserSuperAdmin() {
      return this.userAccessLevel && parseInt(this.userAccessLevel.value) === 100;
    },
    productServices() {
      return this.allProductServices.filter(({ is_active }) => is_active === !!this.selectedStatus);
    }
  },
  created() {
    this.$store.dispatch('product_services/fetchProductServices');
  },
  methods: {
    addNewService() {
      this.$router.replace('/product-services/new');
    },
    getOptionNames(serviceOption) {
      return serviceOption
        ? Object.values(serviceOption)
            .filter(({ is_active }) => is_active)
            .map(({ name }) => name)
        : [];
    },
    editProductService(productService) {
      this.$router.replace(`/product-services/edit/${productService.firebase_service_id}`);
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    },
    async syncServices() {
      if (
        !confirm(
          'Are you sure you want to sync all products to SQL and Netsuite? This operation is only for developers and if done incorrectly it can cause serious financial troubles! '
        )
      ) {
        return;
      } else {
        if (!confirm('Are you really sure ?')) return;
        this.$store.dispatch('showLoader', { message: 'Sync in progress, do not leave this page' });
        try {
          const servicesToSync = this.allProductServices.map(service => {
            const id = service.firebase_service_id;
            delete service.firebase_service_id;
            return { id, data: service };
          });
          for (let i = 0; i < servicesToSync.length; i++) {
            servicesToSync[i].data.netsuite_id = '';
            servicesToSync[i].data.service_id = '';
            productServiceServices.update(servicesToSync[i].id, servicesToSync[i].data);
            await this.$store.dispatch('product_services/editProductService', servicesToSync[i]);
            console.log(`Synced ${i + 1} out of ${servicesToSync.length} [${servicesToSync[i].id}] ... `);
          }
          this.setSnackbar('success', 'Sync done!');
        } catch (error) {
          console.error('Sync failed : ', error);
          this.showAlert('error', 'Sync failed, check console for more details');
        } finally {
          this.$store.dispatch('hideLoader');
        }
      }
    }
  }
};
</script>
