<template>
  <div>
    <v-toolbar flat class="site-table--header site-table--lists mb-3">
      <v-row class="mt-n3 mb-0">
        <v-toolbar-title class="headline grey--text text--darken-3 ml-3 mt-3 pt-1">Site selector for Products</v-toolbar-title>
      </v-row>
    </v-toolbar>

    <div class="default-products-edit-section mb-3">
      <v-container>
        <p>Edit default products</p>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-toolbar flat>
                <v-toolbar-title>Default Products</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="editDefaultProducts"> Edit Default Products </v-btn>
              </v-toolbar>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-divider class="mb-4"></v-divider>

    <div v-if="sites" class="site-table">
      <v-row class="flex justify-space-between align-center mb-2 ml-1">
        <p>Select a site to manage products</p>
        <v-col cols="12" sm="5" md="4" lg="4" xl="3" class="text-center">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense clearable></v-text-field>
        </v-col>
      </v-row>

      <stamp-data-table
        id="sites-table"
        :headers="headers"
        :data="sitesWithProductCounts"
        :is-border-visible="true"
        :search="search"
        :items-per-page="10"
        :is-items-per-page-disabled="true"
        sort-by="created_at"
        :sort-desc="true"
        item-key="firebase_id"
      >
        <template #[`item.site_name`]="{ item }">
          <a :href="`${formatLink(item.site_url)}`" target="_blank" rel="noopener noreferrer" class="blue--text">{{ item.site_name }}</a>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn text color="blue" @click="siteSpecificProducts(item)">MANAGE PRODUCTS</v-btn>
        </template>
      </stamp-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: { 'stamp-data-table': DataTable },
  data() {
    return {
      sortBy: 'created_at',
      descending: true,
      headers: [
        { text: 'Site Name', value: 'site_name' },
        { text: 'Site URL', value: 'site_url' },
        { text: 'Site DB ID', value: 'firebase_id', width: 220 },
        { text: 'Number of Products', value: 'product_counts', width: 100 },
        { text: 'Actions', value: 'actions', align: 'center' }
      ],
      search: '',
      isDeleteDialogOpen: false,
      deletedSite: null,
      siteTemplateCounts: {}
    };
  },
  computed: {
    orderSettings() {
      return this.$store.getters.getOrderSettings;
    },
    ...mapGetters('site', {
      sites: 'getSites',
      searchText: 'getSearchedSite',
      siteRoutes: 'getSitesAcceptedRoutes'
    }),
    productCounts() {
      return this.$store.getters['products/getProductCounts'];
    },
    sitesWithProductCounts() {
      return this.sites.map(obj => ({
        ...obj,
        product_counts: this.productCounts[obj.firebase_id] || 0
      }));
    }
  },
  watch: {
    search(value) {
      this.$store.commit('site/setSearchedSite', value || '');
    }
  },
  async created() {
    await this.$store.dispatch('loadSitesFromDb');
    this.search = this.searchText?.length && (this.siteRoutes === 'siteProcess' || this.siteRoutes === 'orderProcesses') ? this.searchText : '';
    this.$store.commit('site/setSitesAcceptedRoutes', this.$options.name);
    await this.$store.dispatch('products/initProductCounts');
  },
  methods: {
    formatLink(link) {
      return `https://${link?.split('//')[1]?.split('/')[0]}`;
    },
    siteSpecificProducts(item) {
      this.$router.push({ name: 'SiteSpecificProducts', params: { siteId: item.firebase_id } });
    },
    editDefaultProducts() {
      this.$router.push({ name: 'SiteSpecificProducts', params: { siteId: 'default' } });
    }
  }
};
</script>
