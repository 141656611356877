import templateCategoryService from '../../services/templateCategoryService';
import { dbFS } from '@/services/firebaseInit';

const state = {
  templateCategories: [],
  isTemplateCategoriesLoading: false,
  templateCategoryModel: [
    {
      label: 'Template Category Name',
      model: 'name',
      value: '',
      type: 'text',
      input_type: 'textfield',
      is_required: true,
      rule: [value => !!value || 'Template Category Name is required!']
    },
    {
      label: 'Description',
      model: 'description',
      value: '',
      type: 'text',
      input_type: 'textfield',
      is_required: false
    }
  ],
  selectedModel: []
};

const getters = {
  getTemplateCategories(state) {
    return state.templateCategories;
  },
  getTemplateCategoriesLoadingState(state) {
    return state.isTemplateCategoriesLoading;
  },
  getSelectedModel(state) {
    return state.selectedModel;
  },
  getTemplateCategoryModel(state) {
    return state.templateCategoryModel;
  }
};

const mutations = {
  clearTemplateCategories(state) {
    state.templateCategories = [];
  },
  setTemplateCategories(state, payload) {
    state.templateCategories = payload;
  },
  setSelectedModel(state, payload) {
    state.selectedModel = payload;
  },
  setTemplateCategoriesLoadingState(state, payload) {
    state.isTemplateCategoriesLoading = payload;
  },
  setTemplateCategoryModel(state, payload) {
    state.templateCategoryModel = payload;
  }
};

const actions = {
  async isNameDuplicated({ dispatch, getters }, payload) {
    await dispatch('getAllTemplateCategories');
    const templateCategories = getters.getTemplateCategories;
    return templateCategories.some(template => template.name === payload.data.name && template.template_category_firebase_id !== payload.id);
  },
  initSelectedModel({ commit, getters }, payload) {
    let templateCategoryData = getters.getTemplateCategoryModel;

    if (payload) {
      templateCategoryData.forEach((modelProperty, index) => {
        if (payload[modelProperty.model]) {
          templateCategoryData[index].value = payload[modelProperty.model];
        }
      });
    }
    commit('setSelectedModel', templateCategoryData);
  },
  getAllTemplateCategories({ commit }, payload) {
    commit('setTemplateCategoriesLoadingState', true);
    return new Promise(resolve => {
      dbFS.collection('template_categories').onSnapshot(templateSnapshot => {
        commit('clearTemplateCategories');
        const templateCategoryData = templateSnapshot.docs.map(template => {
          return { template_category_firebase_id: template.id, ...template.data() };
        });
        commit('setTemplateCategories', templateCategoryData);
        commit('setTemplateCategoriesLoadingState', false);
        resolve(templateCategoryData);
      });
    });
  },
  async getTemplateCategoryById(_context, payload) {
    try {
      return templateCategoryService.getById(payload);
    } catch (error) {
      console.error('[getTemplateCategoryById error]: ', error);
    }
  },
  addNewTemplateCategory(_context, payload) {
    try {
      return templateCategoryService.add(payload);
    } catch (error) {
      console.error('[addNewTemplateCategory error]: ', error);
    }
  },
  editTemplateCategory(_context, payload) {
    try {
      return templateCategoryService.update(payload.id, payload.data);
    } catch (error) {
      console.error('[editTemplateCategory error]: ', error);
    }
  },
  deleteTemplateCategory(_context, payload) {
    try {
      return templateCategoryService.delete(payload.template_category_firebase_id);
    } catch (error) {
      console.error('[deleteTemplateCategory error]: ', error);
    }
  },
  async saveTemplateCategory({ dispatch }, payload) {
    const functionName = payload.id ? 'editTemplateCategory' : 'addNewTemplateCategory';
    const result = await dispatch(functionName, payload);
    return { message: `Template Category saved with id: ${result}` };
  },
  clearModel({ commit, getters }) {
    commit(
      'setTemplateCategoryModel',
      getters.getTemplateCategoryModel.map(model => ({ ...model, value: '' }))
    );
    commit('setSelectedModel', []);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
