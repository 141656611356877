<template>
  <div class="site-table">
    <ConfirmationDialog
      :value="isConfirmDialogOpen"
      title="Confirmation required"
      :text="`Are you sure you want to delete the following level: ${templateToDelete.label} ?`"
      @onClose="closeDeleteDialog"
      @onConfirm="confirmDelete"
    />
    <v-dialog v-model="isTemplateDialogOpen" max-width="600px">
      <v-card>
        <v-card-title>
          <span v-if="selectedEmailTemplateId" class="headline">Edit email template</span>
          <span v-else class="headline">New email template</span>
        </v-card-title>
        <v-card-text>
          <v-alert :value="isTemplateInDb" color="error">Template code already exist!</v-alert>
          <v-row>
            <v-col cols="12">
              <v-text-field id="templateLabel" v-model="selectedEmailTemplate.label" label="Label *" hint="Enter template label" required type="text"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field id="templateCode" v-model="selectedEmailTemplate.code" label="Code *" hint="Enter template Code" required type="text"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field id="templateSubject" v-model="selectedEmailTemplate.subject" label="Subject *" hint="Enter Subject" required type="text"></v-text-field>
            </v-col>
            <v-col cols="12">
              <PrismEditor v-model="selectedEmailTemplate.message" language="html" line-numbers auto-style-line-numbers style="background-color: white" />
            </v-col>
            <v-col cols="12">
              <v-switch v-model="selectedEmailTemplate.active" color="cyan darken-2" :label="activatorSwitchText"></v-switch>
            </v-col>
          </v-row>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="closeTemplateDialog">Close</v-btn>
          <v-btn color="blue" text :disabled="isTemplateSavingDisabled" @click="saveTemplate">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <template>
          <v-toolbar flat class="site-table--header site-table--lists">
            <v-col class="pl-0">
              <v-toolbar-title class="headline"> Email Templates </v-toolbar-title>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-col>
            <v-col md="3" xl="2" class="pr-0">
              <div class="flexing-to-end">
                <v-btn color="blue white--text" @click="showNewTemplateDialog()">+ New Email Template</v-btn>
              </div>
            </v-col>
          </v-toolbar>
        </template>
        <v-card>
          <stamp-data-table
            :headers="headers"
            :data="templates"
            :actions="actions"
            :search="search"
            sort-by="label"
            :sort-desc="false"
            item-key="_id"
            @editItem="editItem"
            @deleteItem="deleteItem"
          >
            <template #[`item.active`]="{ item }">{{ activeStateText(item.active) }}</template>
          </stamp-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PrismEditor from 'vue-prism-editor';
import DataTable from '@/components/common/DataTable.vue';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import { mapGetters } from 'vuex';

export default {
  components: {
    PrismEditor,
    'stamp-data-table': DataTable,
    ConfirmationDialog
  },
  data() {
    return {
      isTemplateDialogOpen: false,
      search: '',
      selectedEmailTemplateId: null,
      selectedEmailTemplate: {
        label: '',
        code: '',
        subject: '',
        message: '',
        active: false
      },
      headers: [
        { text: 'Label', align: 'left', value: 'label' },
        { text: 'Code', align: 'left', value: 'code' },
        { text: 'Subject', value: 'subject', align: 'left' },
        { text: 'Status', value: 'active', align: 'left' }
      ],
      actions: [
        { icon: 'mdi-pencil', iconColor: 'teal', title: 'Edit Template', eventName: 'editItem' },
        { icon: 'mdi-delete', iconColor: 'red', title: 'Delete Template', eventName: 'deleteItem' }
      ],
      templateToDelete: {
        id: '',
        label: ''
      },
      isTemplateInDb: false,
      isConfirmDialogOpen: false
    };
  },
  computed: {
    ...mapGetters('email_templates', {
      templates: 'getEmailTemplates'
    }),
    activatorSwitchText() {
      return `Template Status: ${this.selectedEmailTemplate.active ? 'Active' : 'Inactive'}`;
    },
    isTemplateSavingDisabled() {
      return !this.selectedEmailTemplate.label || !this.selectedEmailTemplate.code || !this.selectedEmailTemplate.subject || !this.selectedEmailTemplate.message;
    }
  },
  created() {
    this.getTemplates();
  },
  methods: {
    activeStateText(isActive) {
      return isActive ? 'Active' : 'Inactive';
    },
    async getTemplates() {
      await this.$store.dispatch('email_templates/fetchEmailTemplates');
    },
    async saveTemplate() {
      try {
        const isEmailTemplateFound = await this.isCodeAlreadyExist(this.selectedEmailTemplateId, this.selectedEmailTemplate.code);
        if (isEmailTemplateFound) {
          throw new Error('Template already exists');
        }
        if (this.selectedEmailTemplateId) {
          await this.$store.dispatch('email_templates/editEmailTemplate', this.selectedEmailTemplate);
        } else {
          await this.$store.dispatch('email_templates/addNewEmailTemplate', this.selectedEmailTemplate);
        }
        this.closeTemplateDialog();
        this.getTemplates();
      } catch (error) {
        console.error('saveTemplateError:', error);
        this.isTemplateInDb = true;
      }
    },
    editItem(item) {
      this.isTemplateInDb = false;
      this.selectedEmailTemplate = item;
      this.selectedEmailTemplateId = item._id;
      this.isTemplateDialogOpen = true;
    },
    async isCodeAlreadyExist(id, code) {
      return await this.$store.dispatch('email_templates/isEmailTemplateCodeExist', { id, code });
    },
    closeDeleteDialog() {
      this.isConfirmDialogOpen = false;
    },
    deleteItem(item) {
      this.templateToDelete.id = item._id;
      this.templateToDelete.label = item.label;
      this.isConfirmDialogOpen = true;
    },
    async confirmDelete() {
      this.closeDeleteDialog();
      await this.$store.dispatch('email_templates/deleteEmailTemplate', this.templateToDelete);
      this.getTemplates();
    },
    closeTemplateDialog() {
      this.isTemplateDialogOpen = false;
    },
    showNewTemplateDialog() {
      this.isTemplateDialogOpen = true;
      this.selectedEmailTemplate.label = '';
      this.selectedEmailTemplate.code = '';
      this.selectedEmailTemplate.subject = '';
      this.selectedEmailTemplate.message = '';
      this.selectedEmailTemplate.active = false;
      this.selectedEmailTemplateId = null;
      this.isTemplateInDb = false;
    }
  }
};
</script>
<style scoped>
.site-table {
  padding-top: 0 !important;
}
</style>
