import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';
const errorHandler = require('../modules/helpers/errorHandler');

const BANK_ACCOUNT_KEY = 'stripe_bank_account';
const CARD_KEY = 'stripe_card';
class CustomerService extends FirestoreModel {
  constructor() {
    super(collections.customers);
    this._paymentMethodsCollectionName = 'payment_methods';
  }

  async getPaymentMethodsByCustomerId(customerId) {
    try {
      if (!customerId) {
        throw errorHandler.invalidParameter('customerId');
      }
      const data = [];
      const snapshot = await this.collectionRef.doc(customerId).collection(this._paymentMethodsCollectionName).get();
      snapshot.forEach(doc => {
        data.push({ id: doc.id, data: doc.data() });
      });
      return data;
    } catch (error) {
      console.log('getPaymentMethodsByCustomerId has failed: ', error);
    }
  }

  async getPaymentMethodById(paymentMethodId, customerId) {
    if (!paymentMethodId) {
      throw errorHandler.invalidParameter('paymentMethodId');
    }
    if (!customerId) {
      throw errorHandler.invalidParameter('customerId');
    }

    try {
      const doc = await this.collectionRef.doc(customerId).collection(this._paymentMethodsCollectionName).doc(paymentMethodId).get();

      if (!doc.exists) {
        throw errorHandler.notFound(`paymentMethod ${paymentMethodId}`);
      }
      return doc.data();
    } catch (error) {
      console.log('getPaymentMethodById has failed: ', error);
    }
  }

  async getPaymentMethodType(paymentMethodId, customerId) {
    const paymentMethod = await this.getPaymentMethodById(paymentMethodId, customerId);
    if (Object.hasOwn(paymentMethod, BANK_ACCOUNT_KEY)) {
      return BANK_ACCOUNT_KEY;
    }
    if (Object.hasOwn(paymentMethod, CARD_KEY)) {
      return CARD_KEY;
    }
    throw errorHandler.operationError({ message: 'Payment method has no stripe payment method object attached' });
  }

  async updatePaymentMethod(customerId, paymentMethodId, data) {
    if (!paymentMethodId) {
      throw errorHandler.invalidParameter('paymentMethodId');
    }
    if (!customerId) {
      throw errorHandler.invalidParameter('customerId');
    }
    const docRef = this.collectionRef.doc(customerId).collection(this._paymentMethodsCollectionName).doc(paymentMethodId);
    await docRef.update(data);
    return { customerId: customerId, paymentMethodId: docRef.id };
  }

  async getCustomerFirebaseId(uniqueId) {
    try {
      const localUniqueId = uniqueId;
      const querySnapshot = await this.collectionRef.where('customer_unique_id', '==', localUniqueId).get();

      if (!querySnapshot.empty) {
        return {
          firebaseId: querySnapshot.docs[0].id,
          customerData: querySnapshot.docs[0].data()
        };
      }
      return { firebaseId: null };
    } catch (err) {
      console.error('getCustomerFirebaseId has failed: ', err);
      return { error: err };
    }
  }
}

export default new CustomerService();
