import axios from 'axios';

class RestClient {
  constructor(endpoint, context) {
    this.context = context;

    this.HTTP = axios.create({
      headers: {
        'Content-Type': 'application/json'
      }
    });

    console.log('endpoint: ', this.context);
    if (endpoint === 'salesforce') {
      this.BASE_URL = this.context.getters['site_setting/getBasicApiUrl'];
    } else {
      this.HTTP.defaults.headers.common['Authorization'] = 'Basic d2ViZ3VydXM6Tk5WdXFlNjc=';
      this.BASE_URL = this.context.getters.getSiteForQuoteTool;
    }

    // this.BASE_URL = 'https://quote.onebrand.net/wp-json/amp/v2/'
    this.endpoint = endpoint;
  }

  apiAuth(siteData) {
    const _self = this;
    // "/amp" is wrong in split, it fails on //amp.onebrand.net site - Edge case
    const url = `https:${siteData.site_url.split('/wp-json/amp')[0]}/wp-json/jwt-auth/v1/token`;
    const config = {
      username: siteData.site_api_username || '',
      password: siteData.site_api_password || ''
    };
    if (config.username === '' || config.password === '') {
      console.warn('---- API AUTH: Wrong username or password!! ----');
    }
    const http = axios.create({
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });

    const toUrlEncoded = obj =>
      Object.keys(obj)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
        .join('&');
    let form_data = toUrlEncoded(config);

    return new Promise(function (resolve, reject) {
      return http
        .post(url, form_data)
        .then(function (response) {
          _self.HTTP.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
          return resolve(`Bearer ${response.data.token}`);
        })
        .catch(function (error) {
          console.log(`apiAuth: POST ERROR: ${url}:`, error);
          return reject();
        });
    });
  }

  get(config, id) {
    const _self = this;
    let url = 'https:' + this.BASE_URL + this.endpoint;

    if (id !== undefined) {
      url += '/' + id;
    }

    console.log('GET: ', url);

    return new Promise((resolve, reject) => {
      _self.HTTP.get(url, { params: config.params })
        .then(response => {
          console.log('GET RESPONSE: ', response);
          config.success(response, resolve);
        })
        .catch(error => {
          console.log('GET ERROR:', error);
          config.error(error, reject);
        });
    });
  }

  post(config, id) {
    const _self = this;
    let url = 'https:' + this.BASE_URL + this.endpoint;

    if (id !== undefined) {
      url += '/' + id;
    }

    console.log('POST: ', url);

    return new Promise((resolve, reject) => {
      _self.HTTP.post(url, config.params)
        .then(response => {
          console.log('POST RESPONSE: ', response);
          config.success(response, resolve);
        })
        .catch(error => {
          console.log('POST ERROR:', error);
          config.error(error, reject);
        });
    });
  }
}

export { RestClient as default };
