import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class ProductCategoryService extends FirestoreModel {
  constructor() {
    super(collections.product_categories);
  }
}

export default new ProductCategoryService();
