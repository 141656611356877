<template>
  <v-container fluid>
    <subscription-edit-warning
      :isVisible="campaignEditNotification.isVisible"
      :firebaseOrderId="orderId"
      :isOrderUpdatedSuccessfully="isOrderUpdatedSuccessfully"
      :customSuccessMessage="customSuccessMessage"
      class="mt-n8"
      @subscriptionStatus="showSubscriptionStatusChange"
    >
    </subscription-edit-warning>

    <v-alert v-if="!isContentEditable" type="warning"
      >Editing content is disabled because one of the reasons: the subscription is canceled, editing is disabled by the Site settings or the order was created on AMP and was sent
      to cAMP as a quote.
    </v-alert>

    <confirmation-dialog
      :isConfirmationDialogOpened="isConfirmationDialogOpened"
      additionalMessage="By saving your map, it will change the quantity and price of your order."
      @closeConfirmationDialog="closeConfirmationDialog"
      @actionConfirmed="updateConfirmed"
    >
    </confirmation-dialog>
    <v-row v-if="mapStateHash" :class="getMapStyle">
      <map-tool :mapState="mapStateHash" :markupPrice="markupPrice" @saveMap="updateMap"></map-tool>
    </v-row>
  </v-container>
</template>

<script>
import MapTool from '../tools/MapTool';
import SubscriptionEditWarning from '../orders/orderDialogs/PauseSubscriptionNotification';
import ConfirmationDialog from '../orders/orderDialogs/ConfirmationDialog';
import { mapGetters } from 'vuex';

export default {
  components: {
    'map-tool': MapTool,
    'subscription-edit-warning': SubscriptionEditWarning,
    'confirmation-dialog': ConfirmationDialog
  },
  props: ['isPanelOpened', 'mapId', 'firebaseOrderId', 'orderData', 'isContentEditable'],
  data() {
    return {
      mapStateHash: '',
      mapImgName: '',
      isConfirmationDialogOpened: false,
      newMapData: null,
      customSuccessMessage: '',
      markupPrice: 0,
      isMapUpdateLoading: false
    };
  },
  computed: {
    isSubscriptionActive() {
      return (
        this.orderData.automated &&
        this.orderData.automated.campaign_status === 'active' &&
        this.orderData.automated.subscription_status.toUpperCase() === 'ACTIVE' &&
        this.orderData.automated.subscription_id
      );
    },
    ...mapGetters('om_orders', {
      campaignEditNotification: 'getCampaignEditNotification'
    }),
    isOrderUpdatedSuccessfully() {
      return this.campaignEditNotification.isOrderUpdated && this.campaignEditNotification.updatedData === 'audience';
    },
    isEditCampaignDisabled() {
      return !this.isContentEditable || this.isSubscriptionActive || this.isMapUpdateLoading;
    },
    getMapStyle() {
      return `justify-center mx-2 ${this.isEditCampaignDisabled ? 'disableContent' : ''}`;
    },
    orderId() {
      return this.orderData?.firebase_order_id ?? this.firebaseOrderId;
    }
  },
  watch: {
    isPanelOpened: {
      handler(value) {
        this.$store.commit('om_orders/setCampaignEditNotification', { isOrderUpdated: false });
        if (value) {
          this.getMarkupPrice();
          this.getMapStateHash();
        }
      },
      immediate: true
    },
    isSubscriptionActive(value) {
      this.$store.commit('om_orders/setCampaignEditNotification', { isVisible: value });
    }
  },
  methods: {
    async getMarkupPrice() {
      this.$store.dispatch('showLoader', { message: 'Getting markup price in progress... ' });
      this.markupPrice = await this.$store
        .dispatch('maps/getMarkupPrice', {
          site_db_id: this.orderData.origin?.site_db_id || this.orderData.site_db_id
        })
        .catch(error => {
          this.displayFeedback('error', `Getting markup price has failed: ${error}`);
        })
        .finally(() => this.$store.dispatch('hideLoader'));
    },
    getMapStateHash() {
      this.$store.dispatch('showLoader', { message: 'Getting map state in progress... ' });
      this.mapStateHash = '';
      this.mapImgName = '';
      this.$store
        .dispatch('maps/getMapDataById', { mapId: this.mapId })
        .then(map => {
          this.mapStateHash = map.stateHash ? map.stateHash : '';
          this.mapImgName = map.mapImgName ? map.mapImgName : '';
        })
        .catch(error => {
          this.displayFeedback('error', `Getting map state has failed: ${error}`);
        })
        .finally(() => this.$store.dispatch('hideLoader'));
    },
    updateMap(newMapData) {
      this.newMapData = newMapData;
      this.isConfirmationDialogOpened = true;
    },
    closeConfirmationDialog() {
      this.isConfirmationDialogOpened = false;
    },
    updateConfirmed() {
      this.closeConfirmationDialog();
      this.isMapUpdateLoading = true;
      this.$store.dispatch('showLoader', { message: 'Updating map and calculating new prices in progress... ' });
      this.$store
        .dispatch('edit_audience/recalculateAudience', {
          mapId: this.mapId,
          mapImgName: this.mapImgName,
          mapData: this.newMapData,
          orderData: this.orderData
        })
        .then(result => {
          if (result && result.status === 'success') {
            this.customSuccessMessage = 'Order has been updated successfully. Please note that Monthly budget has been set to default.';
            this.$store.commit('om_orders/setCampaignEditNotification', { isOrderUpdated: true, updatedData: 'audience', isVisible: this.isSubscriptionActive });
          } else {
            this.displayFeedback('error', 'Updating map and prices failed. No result found or result status is not success!');
          }
        })
        .catch(error => this.displayFeedback('error', error))
        .finally(() => {
          this.isMapUpdateLoading = false;
          this.$store.dispatch('hideLoader');
        });
    },
    displayFeedback(type, message) {
      this.$emit('updateFeedback', { type, message });
    },
    showSubscriptionStatusChange(data) {
      this.$store.commit('om_orders/setCampaignEditNotification', { isVisible: this.isSubscriptionActive, isOrderUpdated: false });
      if (data && data.status === 'error') {
        this.displayFeedback('error', data.message);
      }
    }
  }
};
</script>

<style scoped>
.disableContent {
  pointer-events: none;
  opacity: 0.4;
}
</style>
