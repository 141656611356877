<template>
  <div>
    <v-row class="px-8 py-4">
      <template v-for="(service, index) in services">
        <v-chip
          v-if="service.is_active"
          :key="index"
          filter
          outlined
          :input-value="isServiceSelected(service)"
          active-class="primary--text"
          class="mr-2 mb-2"
          :disabled="!isEditable"
          @click="toggleService(service)"
        >
          <span>{{ service.name }}</span>
        </v-chip>
      </template>
      <v-chip outlined :disabled="!isEditable" @click="clearSelectedServices"><v-icon color="grey darken-3" size="16">mdi-close</v-icon></v-chip>
    </v-row>
    <v-row class="px-8 py-4">
      <v-expansion-panels v-model="panels" multiple accordion class="elevation-2">
        <v-expansion-panel v-for="(service, i) in selectedServices" :key="i">
          <v-expansion-panel-header class="px-6"> {{ service.name }}</v-expansion-panel-header>
          <v-expansion-panel-content class="px-4">
            <v-row class="d-flex align-center">
              <v-switch v-model="service.is_full_price" :true-value="true" :false-value="false" :disabled="!isEditable">
                <template #label
                  ><v-tooltip right>
                    <template #activator="{ on, attrs }">
                      <span>Flat Amounts <v-icon v-if="isEditable" v-bind="attrs" small class="ml-1 cursor-help" v-on="on">mdi-information-slab-circle-outline</v-icon></span>
                    </template>
                    <span v-if="service.is_full_price">By disabling this option the prices will be calculated based on quantity</span>
                    <span v-else>By enabling this option the prices will be calculated as a flat amount instead of a per unit value</span>
                  </v-tooltip>
                </template>
              </v-switch>
              <v-tooltip right>
                <template #activator="{ on }">
                  <span><v-icon v-if="!isEditable" class="ml-1 cursor-help" small v-on="on">mdi-information-slab-circle-outline</v-icon></span>
                </template>
                <span v-if="service.is_full_price">The service option prices will be calculated as a flat amount instead of a per unit value</span>
                <span v-else>The service option prices will be calculated based on quantity</span>
              </v-tooltip>
            </v-row>
            <v-row>
              <template v-for="(option, optionKey) in getOptionsForSelectedService(service)">
                <div v-if="option.is_active" :key="`${option.name}-${optionKey}`" class="mr-6 mb-4">
                  <label class="custom v-label theme--light d-flex align-end" :class="{ 'cursor-not-allowed': !isEditable }"
                    ><input v-model="service.headers" type="checkbox" :value="getOptionValue(option, optionKey, service.name)" class="mr-2 mt-1" :disabled="!isEditable" />{{
                      option.name
                    }}</label
                  >
                </div>
              </template>
            </v-row>

            <v-row class="pt-2">
              <v-data-table :headers="service.headers" :items="service.tiers" hide-default-footer disable-sort disable-pagination class="elevation-2 table-bordered">
                <template v-for="(serviceHeader, index) in service.headers" #[`header.${serviceHeader.value}`]="{ header }">
                  <v-checkbox v-if="isOptionPriceField(header.value)" :key="index" v-model="header.batch" dense :label="header.text" :disabled="!isEditable" class="mb-n2">
                    <template #label>
                      <span class="text-subtitle-2">{{ header.text }} </span>
                      <BatchEditDialog v-model="header.batch" :field-name="header.text" @batchEdit="updateColumn($event, header.value, service)" />
                    </template>
                  </v-checkbox>
                  <span v-if="!isOptionPriceField(header.value)" :key="index" class="text-subtitle-2">{{ header.text }} </span>
                </template>
                <template v-for="(header, index) in service.headers" #[`item.${header.value}`]="{ item }">
                  <v-edit-dialog
                    v-if="isOptionPriceField(header.value) && isEditable"
                    :key="index"
                    :return-value.sync="item[header.value]"
                    large
                    save-text="Apply"
                    @open="setDefaultOption(item, header.value)"
                  >
                    <span><v-icon class="ml-n2" dense>mdi-currency-usd</v-icon>{{ formatValue(item[header.value]) }}</span>
                    <template #input>
                      <v-radio-group v-model="item.selectedPriceOption[header.value]">
                        <v-radio label="Included" value="included" @click="setPriceOption(item, header.value, 0)"></v-radio>
                        <v-radio label="Not Available" value="unavailable" @click="setPriceOption(item, header.value, -1)"></v-radio>
                        <v-radio label="Price" value="price" selected></v-radio>
                      </v-radio-group>
                      <v-text-field
                        v-if="item.selectedPriceOption[header.value] === 'price'"
                        v-model="item[header.value]"
                        type="number"
                        class="mb-n4"
                        step="0.1"
                        prepend-inner-icon="mdi-currency-usd"
                        outlined
                        dense
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                  <span v-if="!isOptionPriceField(header.value) || !isEditable" :key="index" class="text-body-2 grey--text text--lighten-1">
                    <span v-if="isOptionPriceField(header.value)"
                      ><v-icon class="ml-n2" color="grey lighten-1" dense>mdi-currency-usd</v-icon>{{ formatValue(item[header.value]) }}</span
                    >
                    <span v-else>{{ item[header.value] }}</span>
                  </span>
                </template>
              </v-data-table>
            </v-row>
            <v-row class="pt-3">
              <span v-if="isEditable" class="text-caption grey--text text--darken-1">*Note: By Checking the checkbox on the top of a column you enable batch editing mode. </span>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>
<script>
import BatchEditDialog from './BatchEditDialog.vue';

export default {
  components: {
    BatchEditDialog
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    services: {
      type: Array,
      required: true
    },
    tiers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedServices: [],
      panels: []
    };
  },
  computed: {
    isEditable() {
      return this.$route?.name !== 'ViewProduct';
    }
  },
  watch: {
    tiers: {
      handler(values) {
        // This watcher is responsible for updating service tiers if the product pricing tiers are updated
        this.selectedServices.forEach(service => {
          const tierAdded = values.filter(({ up_to: up_to1 }) => !service.tiers.some(({ up_to: up_to2 }) => up_to1 == up_to2));
          const tierRemoved = service.tiers.filter(({ up_to: up_to1 }) => !values.some(({ up_to: up_to2 }) => up_to1 == up_to2));

          if (tierRemoved.length) {
            tierRemoved.forEach(removedTier => {
              const tierIndex = service.tiers.findIndex(tier => tier.up_to === removedTier.up_to);
              if (tierIndex !== -1) {
                if (tierAdded.length) {
                  const newTier = tierAdded.shift();
                  service.tiers[tierIndex] = { ...service.tiers[tierIndex], from: newTier.from, up_to: newTier.up_to };
                } else {
                  service.tiers.splice(tierIndex, 1);
                }
              }
            });
          }

          if (tierAdded.length && !tierRemoved.length) {
            tierAdded.forEach(newTier => {
              service.tiers.push({ from: newTier.from, up_to: newTier.up_to, selectedPriceOption: {} });
            });
          }

          if (tierAdded.length || tierRemoved.length) {
            service.tiers.sort((a, b) => a.up_to - b.up_to);
            for (let i = 0; i < service.tiers.length; i++) {
              const previousLimit = Number(service.tiers[i - 1]?.up_to || 0);
              const currentLimit = Number(service.tiers[i].up_to);
              const step = currentLimit > previousLimit ? currentLimit - previousLimit - 1 : previousLimit - 1;

              this.$set(service.tiers[i], 'from', previousLimit + 1);
              this.$set(service.tiers[i], 'up_to', previousLimit + 1 + step);
            }
          }
        });
      },
      deep: true
    },
    selectedServices: {
      handler(value) {
        this.$emit('input', value);
      },
      deep: true
    },
    value: {
      handler(values) {
        values.forEach(service => {
          if (!this.isServiceSelected(service)) {
            this.toggleService(service);
          }
        });
      }
    }
  },
  methods: {
    getServiceIndex(service) {
      return this.selectedServices.findIndex(({ firebase_service_id }) => firebase_service_id === service.firebase_service_id);
    },
    getOptionValue(option, optionKey, serviceName) {
      let value = { text: option.name, value: optionKey, batch: false };
      if (serviceName === 'Stocks') {
        value.thickness = option.thickness;
      }
      return value;
    },
    isServiceSelected(service) {
      const serviceIndex = this.getServiceIndex(service);
      return serviceIndex !== -1;
    },
    getOptionsForSelectedService(service) {
      return this.services.find(({ firebase_service_id }) => firebase_service_id === service.firebase_service_id)?.options || [];
    },
    toggleService(service) {
      const serviceIndex = this.getServiceIndex(service);
      if (serviceIndex === -1) {
        if (service.name === 'Stocks' && service?.headers) {
          const stockServiceOptions = this.services.find(serviceItem => serviceItem.firebase_service_id === service.firebase_service_id)?.options;

          service.headers = service.headers.map(header => {
            if (header.value !== 'from' && header.value !== 'up_to') {
              const thickness = Object.values(stockServiceOptions).find(option => option.name === header.text)?.thickness || '0';

              return {
                ...header,
                thickness: thickness
              };
            }
            return header;
          });
        }

        this.selectedServices.push({
          headers: service.headers?.length
            ? service.headers
            : [
                { text: 'First Unit', value: 'from', batch: false },
                { text: 'Last Unit', value: 'up_to', batch: false }
              ],
          tiers: this.tiers.map(tier => ({ from: tier.from, up_to: tier.up_to, selectedPriceOption: {} })),
          is_full_price: false,
          ...service
        });
      } else {
        this.selectedServices.splice(serviceIndex, 1);
      }
      this.panels.push(this.selectedServices.length - 1);
    },
    clearSelectedServices() {
      this.selectedServices = [];
    },
    isOptionPriceField(fieldName) {
      return !['from', 'up_to'].includes(fieldName);
    },
    updateColumn(value, column, service) {
      const serviceIndex = this.getServiceIndex(service);
      if (serviceIndex === -1) {
        return;
      }
      this.selectedServices[serviceIndex].tiers.forEach(tier => {
        this.$set(tier, column, value);
      });
    },
    setDefaultOption(item, fieldName) {
      this.$set(item, 'selectedPriceOption', {});
      this.$set(item.selectedPriceOption, fieldName, 'price');
    },
    setPriceOption(item, fieldName, value) {
      this.$set(item, fieldName, value);
    },
    formatValue(value) {
      //value !== '' is necessary so it doesn't show empty values as included
      if (Number(value) === 0 && value !== '') {
        return 'Included';
      }
      if (Number(value) === -1) {
        return 'Not Available';
      }
      return value;
    }
  }
};
</script>
<style lang="scss">
/** custom check mark since v-checkbox has a a known bug which will be fixed in v3 only */
label.custom > input[type='checkbox'] {
  accent-color: var(--v-primary-base);
  width: 18px;
  height: 18px;
}

label.custom:hover,
label.custom > input[type='checkbox']:hover {
  cursor: pointer;
}

.cursor-not-allowed,
label.custom > input[type='checkbox']:disabled {
  cursor: not-allowed !important;
}

.cursor-help {
  cursor: help;
}
</style>
