import { dbFS } from '@/services/firebaseInit';

const state = {
  notifications: [],
  notificationsCount: 0,
  notificationsCollectionCount: 0,
  lastVisibleNotification: {}
};

const mutations = {
  addNotification(state, payload) {
    if (!state.notifications.filter(notification => notification.firestoreID === payload.firestoreID).length) {
      state.notifications.push(payload);
    }
  },
  setNotificationsCount(state, payload) {
    state.notificationsCount = parseInt(payload);
  },
  removeNotificationFromList(state, payload) {
    state.notifications.forEach((notification, index) => {
      if (notification.firestoreID === payload) {
        state.notifications.splice(index, 1);
      }
    });
  },
  clearNotifications(state) {
    state.notifications = [];
  },
  setNotificationsMaxCount(state, payload) {
    state.notificationsCollectionCount = parseInt(payload);
  },
  setLastVisibleNotification(state, payload) {
    state.lastVisibleNotification = payload;
  }
};

const getters = {
  getNotifications(state) {
    return state.notifications;
  },
  getNotificationsCount(state) {
    return state.notificationsCount;
  },
  getNotificationsMaxCount(state) {
    return state.notificationsCollectionCount;
  },
  getLastVisibleNotification(state) {
    return state.lastVisibleNotification;
  }
};

const actions = {
  addNotification({ commit }, payload) {
    return dbFS.collection('notifications').add({
      content: {
        message: payload.message || '',
        link: payload.link || ''
      },
      is_read: false,
      user: payload.user || '',
      date: payload.date || Date.now()
    });
  },
  setNotificationData({ commit }, payload) {
    const notifications = [];

    const lastVisible = payload.querySnapshot[payload.querySnapshot.length - 1];
    commit('setLastVisibleNotification', lastVisible);

    payload.querySnapshot.forEach(doc => {
      const notificationData = doc.data();
      notifications.push({
        id: doc.id,
        date: notificationData.date,
        message: notificationData.content.message,
        is_read: notificationData.is_read,
        user: notificationData.user
      });
    });
    return notifications;
  },
  fetchNotifications({ commit, getters, dispatch }, { page }) {
    dbFS
      .collection('notifications')
      .get()
      .then(querySnapshot => {
        commit('setNotificationsMaxCount', querySnapshot.size);
      });

    const notificationsQuery = dbFS.collection('notifications').orderBy('date', 'desc').limit(15);
    if (page > 1) {
      return notificationsQuery
        .startAfter(getters.getLastVisibleNotification)
        .get()
        .then(querySnapshot => dispatch('setNotificationData', { querySnapshot: querySnapshot.docs }));
    } else {
      return notificationsQuery.get().then(querySnapshot => dispatch('setNotificationData', { querySnapshot: querySnapshot.docs }));
    }
  },
  changeNotificationReadableState({ commit }, payload) {
    return dbFS
      .collection('notifications')
      .doc(payload.firestoreID)
      .update({
        is_read: payload.is_read
      })
      .then(() => {
        return { status: `Notification ${payload.firestoreID} successfully updated!` };
      })
      .catch(error => {
        console.error('Error in updating notification: ', error);
        return { error };
      });
  },
  async markAsRead({ commit, getters, dispatch }, payload) {
    if (payload.firestoreID) {
      return dispatch('changeNotificationReadableState', {
        firestoreID: payload.firestoreID,
        is_read: true
      })
        .then(() => {
          let notificationCount = getters.getNotifications.length;
          commit('setNotificationsCount', notificationCount ? notificationCount - 1 : 0);
          commit('removeNotificationFromList', payload.firestoreID);

          return Promise.resolve({ status: `Notification ${payload.firestoreID} successfully updated!` });
        })
        .catch(error => {
          console.error('Error: ', error);
          return Promise.reject(error);
        });
    } else {
      const unReadedNotifications = getters.getNotifications;
      const markAsReadPromises = [];
      if (unReadedNotifications.length) {
        unReadedNotifications.forEach(notification => {
          markAsReadPromises.push(
            dispatch('changeNotificationReadableState', {
              firestoreID: notification.firestoreID,
              is_read: true
            })
          );
        });
        return Promise.all(markAsReadPromises)
          .then(results => {
            console.log('Mark as read promise all results:', results);
            commit('clearNotifications');
            commit('setNotificationsCount', 0);
            return Promise.resolve();
          })
          .catch(error => {
            console.error('Error: ', error);
            return Promise.reject(error);
          });
      }
    }
  },
  triggerNotificationChanges({ commit }) {
    commit('clearNotifications');
    return dbFS
      .collection('notifications')
      .where('is_read', '==', false)
      .limit(7)
      .onSnapshot(snapshot => {
        if (snapshot.docs && snapshot.docs.length) {
          commit('setNotificationsCount', snapshot.docs.length);
          snapshot.docs.forEach(doc => {
            const docData = doc.data();
            commit('addNotification', { ...docData, firestoreID: doc.id });
          });
        }
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
