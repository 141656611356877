import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class ProductService extends FirestoreModel {
  constructor() {
    super(collections.products);
  }
}

export default new ProductService();
