<template>
  <v-row justify="center">
    <v-dialog v-model="isDialogOpen" persistent :max-width="maxWidth || 450">
      <v-card>
        <v-card-title>
          <h1 class="headline">{{ title || 'Confirm' }}</h1>
          <v-spacer></v-spacer>
          <v-btn icon large color="red darken-1" class="mt-n1" @click="confirm(false)"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="confirm(true)">{{ confirmButtonText || 'Yes' }}</v-btn>
          <v-btn color="green darken-1" text @click="confirm(false)">{{ denyButtonText || 'No' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['message', 'title', 'confirmButtonText', 'denyButtonText', 'isOpen', 'data', 'maxWidth'],
  computed: {
    isDialogOpen() {
      return this.isOpen;
    }
  },
  methods: {
    confirm(confirmed) {
      this.$emit('confirm', { confirmed: confirmed, data: this.data });
    }
  }
};
</script>
