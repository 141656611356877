<template>
  <div>
    <v-row>
      <v-col>
        <v-btn icon class="mb-2" @click.native="back()">
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>
        <span class="headline">Details for {{ firebaseID }}</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-4">
      <v-col cols="12" md="7">
        <div>
          <p class="headline">Summary</p>
        </div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Field Name</th>
                <th class="text-left">Field Details</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in summary" :key="item.name">
                <td>{{ item.key }}</td>
                <td>{{ item.value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="7">
        <v-card>
          <v-card-title>
            <div>
              <h4 class="headline">Details</h4>
            </div>
          </v-card-title>
          <v-card-text class="custom-card-text">
            <v-tabs v-model="tab" align-with-title>
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab v-for="item in header" :key="item" class="custom-tab">
                {{ item }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in header" :key="item">
                <v-card flat class="mt-4">
                  <div v-if="item === 'Configuration'">
                    <ul id="example-1" class="ml-0">
                      <li v-for="(item, key) in configData" :key="key" class="custom-ul ml-0">
                        <div v-if="typeof item === 'object'" class="mb-2">
                          {{ key }}
                          <ul class="pt-0">
                            <li v-for="(item1, key1) in item" :key="key1">
                              <div v-if="typeof item1 === 'object'">
                                {{ key1 }}
                                <ul>
                                  <li v-for="(item2, key2) in item1" :key="key2">{{ key2 }}: {{ item2 }}</li>
                                </ul>
                              </div>
                              <div v-else>{{ key1 }}: {{ item1 }}</div>
                            </li>
                          </ul>
                        </div>
                        <div v-else class="mb-2">{{ key }} : {{ item }}</div>
                        <v-divider class="pt-2"></v-divider>
                      </li>
                    </ul>
                  </div>
                  <div v-else-if="item === 'Date'">
                    <div class="ml-4">
                      {{ formatDate(customizedDesignTime) }}
                    </div>
                  </div>
                  <div v-else-if="item === 'Proof images'">
                    <v-row>
                      <v-col md="6">
                        <p>Front</p>
                        <div v-if="proofImages.front.length != 0" class="custom-height-PI">
                          <img class="image-custom-height-PI" :src="proofImages.front" alt="Avatar image" />
                        </div>
                        <div v-else>
                          <img class="image-custom-height-PII" src="@/assets/no-image-available.png" height="100" alt="Avatar image" />
                        </div>
                      </v-col>
                      <v-col md="6">
                        <p class="ml-1">Back</p>
                        <div v-if="proofImages.back.length != 0" class="custom-height-PI ml-1">
                          <img class="image-custom-height-PI" :src="proofImages.back" alt="Avatar image" />
                        </div>
                        <div v-else>
                          <img class="image-custom-height-PII" src="@/assets/no-image-available.png" height="100" alt="No image" />
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else-if="item === 'Rendered Data'">
                    <ul id="example-2">
                      <li v-for="(item, key) in renderedData" :key="key">
                        <div v-if="typeof item === 'object'" class="mb-2">
                          {{ key }}
                          <ul>
                            <li v-for="(item1, key1) in item" :key="key1">
                              <div v-if="typeof item1 === 'object'">
                                {{ key1 }}
                                <ul>
                                  <li v-for="(item2, key2) in item1" :key="key2">{{ key2 }}: {{ item2 }}</li>
                                </ul>
                              </div>
                              <div v-else>
                                <div v-if="key1 === 0">
                                  <div v-if="key === 'hiResOutputUrls'">
                                    <a v-bind:href="item1">{{ item1 }}</a>
                                  </div>
                                  <div v-else>
                                    {{ item1 }}
                                  </div>
                                </div>
                                <div v-else>{{ key1 }}: {{ item1 }}</div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div v-else class="mb-2">
                          <div v-if="key === 'returnToEditUrl'">
                            {{ key }} :
                            <a v-bind:href="item">{{ item }}</a>
                          </div>
                          <div v-else>{{ key }} : {{ item }}</div>
                        </div>
                        <v-divider class="pt-2"></v-divider>
                      </li>
                    </ul>
                  </div>
                  <div v-else-if="item === 'Product'">
                    <ul id="example-3">
                      <li v-for="(item, key) in savedProduct" :key="key" class="mb-2">
                        <div v-if="key === 'returnToEditUrl'">
                          {{ key }} :
                          <a v-bind:href="item">{{ item }}</a>
                        </div>
                        <div v-else>{{ key }} : {{ item }}</div>
                      </li>
                    </ul>
                  </div>
                  <div v-else-if="item === 'Surfaces'">
                    <ul id="example-4">
                      <li v-for="(item, key) in surfaces" :key="key" class="mb-2">
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                  <div v-else>
                    <p>{{ text2 }}</p>
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import customDesignsService from '../../../services/customDesignsService';

export default {
  data() {
    return {
      designID: this.$route.params.designID,
      summary: [],
      tab: null,
      text2: 'No data available',
      configData: [],
      header: [],
      customizedDesignTime: null,
      proofImages: {},
      renderedData: {},
      savedProduct: {},
      surfaces: {},
      firebaseID: null
    };
  },
  async created() {
    try {
      const response = await customDesignsService.listSelectedDesign(this.designID);
      this.header = response.header;
      this.summary = response.summary;
      // firebase id
      this.firebaseID = response.firestoreId;
      // config
      this.configData = response.config;
      // customizedDesignTime
      this.customizedDesignTime = response.customizedDesignTime[0].value.seconds;
      // proofImages
      if (Object.keys(response.proofImages).length) {
        this.proofImages.front = response.proofImages.front.length ? response.proofImages.front[0] : '';
        this.proofImages.back = response.proofImages.back.length ? response.proofImages.back[0] : '';
      } else {
        this.proofImages.back = '';
        this.proofImages.front = '';
      }
      // renderedData
      this.renderedData = response.renderedData;
      // savedProduct
      this.savedProduct = response.savedProduct;
      // surfaces
      this.surfaces = response.surfaces;
    } catch (err) {
      console.log('DesignDetails/created function has failed: ', err);
    }
  },
  methods: {
    formatDate(dateTotransform) {
      var monthsArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'Deccember'];
      // Convert timestamp to milliseconds
      var date = new Date(dateTotransform * 1000);
      // Year
      var year = date.getFullYear();
      // Month
      var month = monthsArr[date.getMonth()];
      // Day
      var day = date.getDate();
      // Hours
      var hours = date.getHours();
      // Minutes
      var minutes = '0' + date.getMinutes();
      // Seconds
      var seconds = '0' + date.getSeconds();
      // Display date time in MM-dd-yyyy h:m:s format
      var convdataTime = month + ' ' + day + ', ' + year + ' at ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
      return convdataTime;
    },
    back() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.custom-tab {
  text-transform: initial;
}

.custom-card-text {
  font-size: 14px !important;
}

#example-1,
#example-2,
#example-3,
#example-4 {
  list-style-type: none;
}

.image-custom-height-PI {
  width: 100%;
}
</style>
