<template>
  <v-container fluid>
    <ldp-summary-dialog
      v-if="isSummaryDialogOpened"
      :isSummaryDialogOpened="isSummaryDialogOpened"
      :isLdpOrderLoading="isLdpOrderLoading"
      :dialogTitle="dialogTitle"
      :isClientDataEmpty="isClientDataEmpty"
      @closeDialog="closeSummaryDialog"
    >
    </ldp-summary-dialog>

    <confirmation-dialog
      :isConfirmationDialogOpened="isConfirmationDialogOpened"
      action="delete this LDP data (media plan, LDP result) from order"
      @closeConfirmationDialog="closeConfirmationDialog"
      @actionConfirmed="deleteLdpDataConfirmed"
    >
    </confirmation-dialog>

    <v-card v-if="isPanelOpened" class="elevation-0 mt-n10">
      <template #progress>
        <v-progress-linear color="cyan darken-2" indeterminate></v-progress-linear>
      </template>
      <v-alert v-if="isLdpAlertVisible" :type="ldpAlertType" dismissible :outlined="isInfoText" class="mb-n1 mt-2"> {{ ldpAlertMessage }}</v-alert>
      <v-alert v-if="!isOspreyOrderIdAvailable" type="warning" color="orange darken-2" prominent dense outlined class="mt-4"
        ><span class="subtitle-1">Media plan cannot be created for zero Osprey Order Id value!</span></v-alert
      >
      <v-alert v-if="isDeliveryWeeksEmpty" type="warning" color="orange darken-2" prominent dense outlined class="mt-4"
        ><span class="subtitle-1">No delivery weeks are available.</span></v-alert
      >

      <template v-else>
        <v-row no-gutters class="mt-5">
          <v-col cols="12">
            <v-card outlined>
              <v-tabs v-model="selectedMediaPlan" vertical color="cyan darken-2" class="pt-2">
                <p class="text-capitalize grey--text text--darken-2 body-1 pl-4 pt-3">Results</p>
                <template v-for="(delivery, index) in deliveryWeeks">
                  <v-tab v-if="index + 1" :key="`mediaPlanTab-${index}`" class="text-capitalize">
                    <v-icon left>mdi-send</v-icon>{{ index + 1 }} - Week: {{ formatDate(delivery.date) }}
                    <v-menu v-model="ldpDateMenusVisibility[index]" :close-on-content-click="false" max-width="290">
                      <template v-slot:activator="{ on }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: onTooltip }">
                            <v-btn icon v-on="on">
                              <v-icon :disabled="isMediaPlanCreated[index]" v-on="onTooltip" @click="selectMediaPlan(index)">mdi-calendar</v-icon>
                            </v-btn>
                          </template>
                          <span>Change drop date</span>
                        </v-tooltip>
                      </template>
                      <v-date-picker
                        v-model="formattedDeliveryWeeks[index]"
                        :allowed-dates="val => allowDates(index, val)"
                        :first-day-of-week="1"
                        :disabled="isMediaPlanCreated[index]"
                        @change="ldpDateMenusVisibility[index] = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-tooltip v-if="getDropStatusProps(delivery.date).isVisible" bottom>
                      <template #activator="{ on }">
                        <v-icon right :color="getDropStatusProps(delivery.date).color" v-on="on">{{ getDropStatusProps(delivery.date).icon }}</v-icon>
                      </template>
                      <span>{{ getDropStatusProps(delivery.date).message }}</span>
                    </v-tooltip>
                  </v-tab>
                  <v-tab-item v-if="index + 1" :key="index">
                    <v-card flat class="pl-3">
                      <v-tabs v-model="mediaPlanResultTab" color="cyan darken-2" grow>
                        <v-tab class="text-capitalize">Media Plan</v-tab>
                        <v-tab class="text-capitalize">Upload CSV</v-tab>
                        <v-tab class="text-capitalize">LDP Result</v-tab>
                        <v-tab class="text-capitalize">Webhook Response</v-tab>
                        <v-tooltip v-if="getLdpOrderResultJson(formatDate(delivery.date))" bottom>
                          <template #activator="{ on }">
                            <v-btn icon color="red" small class="mt-3 mx-3" v-on="on" @click="deleteLdpData(formatDate(delivery.date), delivery.mail_drop_id)"
                              ><v-icon left>mdi-delete</v-icon></v-btn
                            >
                          </template>
                          <span>Delete LDP order object from database</span>
                        </v-tooltip>
                      </v-tabs>

                      <v-tabs-items v-model="mediaPlanResultTab">
                        <v-tab-item class="pr-4 pb-4">
                          <v-card flat outlined width="100%" min-height="18vh" class="mt-3">
                            <div v-if="showMediaPlan(delivery.mail_drop_id)" class="pa-4">
                              <vue-json-pretty :data="getMediaPlanJsonData(delivery.mail_drop_id || '')"></vue-json-pretty>
                            </div>
                            <template v-if="isMediaPlanDataEmpty(delivery.mail_drop_id)">
                              <v-row no-gutters class="pl-4 mt-2">
                                <v-col cols="12" md="6">
                                  <v-row no-gutters class="mb-n3 mt-3">
                                    <p class="subtitle-1 grey--text text--darken-2">
                                      Mail Drop ID:<strong class="pl-2 cyan--text text--darken-3">{{ delivery.mail_drop_id || '-' }}</strong>
                                    </p>
                                  </v-row>
                                  <v-row no-gutters class="mt-2">
                                    <v-switch v-model="delivery.isExpress" label="Express" color="cyan darken-1"></v-switch>
                                  </v-row>
                                  <v-row no-gutters class="mt-2">
                                    <v-switch v-model="isClientNameEditEnabled" label="Edit Client Name and Client Promotion" color="cyan darken-1" class="mt-0"></v-switch>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-7">
                                  <v-row class="mx-1">
                                    <v-text-field v-model="clientData.clientName" label="Client Name" :disabled="!isClientNameEditEnabled" outlined dense></v-text-field>
                                  </v-row>
                                  <v-row class="mx-1 mt-0">
                                    <v-text-field
                                      v-model="clientData.clientNameAppearingOnAd"
                                      label="Client Name Appearing On Ad"
                                      :disabled="!isClientNameEditEnabled"
                                      :rules="[v => v.length <= 15 || 'Max 15 characters!']"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-row>
                                  <v-row class="mx-1 mt-n1">
                                    <v-text-field
                                      v-model="clientData.clientPromotion"
                                      label="Client Promotion"
                                      :disabled="!isClientNameEditEnabled"
                                      :rules="[v => v.length <= 30 || 'Max 30 characters!']"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-row>
                                </v-col>
                              </v-row>
                              <v-row no-gutters class="mt-2 pl-3">
                                <v-col cols="12" md="5">
                                  <v-tooltip :disabled="isMediaPlanCreated[index] || !isClientDataEmpty" bottom>
                                    <template #activator="{ on }">
                                      <div v-on="on">
                                        <v-btn
                                          v-if="!isMediaPlanCreated[index]"
                                          :disabled="isClientDataEmpty"
                                          color="cyan darken-1"
                                          class="white--text mb-3"
                                          block
                                          @click="callLdpSubmitMediaPlan(delivery, index)"
                                        >
                                          Create Media Plan
                                        </v-btn>
                                      </div>
                                    </template>
                                    <span>Please set first name and last name or company name</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </template>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item class="pr-4 pb-4">
                          <v-card flat outlined width="100%" min-height="18vh" class="mt-3 pa-4">
                            <v-row class="custom-row-height d-flex flex-column justify-center align-center">
                              <v-progress-circular v-if="isLdpLoaderVisible" class="padding-large" indeterminate color="cyan darken-1" size="30"></v-progress-circular>
                              <v-btn v-if="!isLdpCsvUploadedToOsprey && isMediaPlanCreated[index]" class="white--text" color="cyan darken-1" @click="uploadCsvToOsprey">
                                Start uploading LDP CSV file process
                              </v-btn>
                              <p v-else-if="isLdpCsvUploadedToOsprey">CSV was uploaded</p>
                            </v-row>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item class="pr-4 pb-4">
                          <v-card flat outlined width="100%" min-height="18vh" class="mt-3 pa-4">
                            <vue-json-pretty v-if="getLdpOrderResultJson(formatDate(delivery.date))" :data="getLdpOrderResultJson(formatDate(delivery.date))"></vue-json-pretty>
                            <v-row
                              v-else-if="isSubmitLdpOrderButtonVisible && getMediaPlanJsonData(delivery.mail_drop_id)"
                              class="custom-row-height d-flex flex-column justify-center align-center"
                            >
                              <v-tooltip :disabled="!isSubmitVdpOrderButtonDisabled(delivery)" bottom>
                                <template #activator="{ on }">
                                  <div v-on="on">
                                    <v-btn class="white--text" color="cyan darken-1" :disabled="isSubmitVdpOrderButtonDisabled(delivery)" @click="callLdpSubmitOrder(delivery)">
                                      Submit VDP Order
                                    </v-btn>
                                  </div>
                                </template>
                                <span>Please set first name and last name or company name</span>
                              </v-tooltip>
                            </v-row>
                          </v-card>
                        </v-tab-item>

                        <v-tab-item class="ml-2">
                          <v-card flat outlined width="100%" min-height="18vh" class="mt-3 pa-4">
                            <vue-json-pretty v-if="getWebhookResponseJsonData(formatDate(delivery.date))" :data="getWebhookResponseJsonData(formatDate(delivery.date))">
                            </vue-json-pretty>
                            <v-row v-if="isResubmitButtonVisible(formatDate(delivery.date))" no-gutters class="mt-4 justify-center">
                              <v-col cols="12" md="5">
                                <v-btn class="cyan--text text--darken-4 text-capitalize" block @click="callLdpSubmitOrder(delivery)">
                                  <v-icon class="mr-2">mdi-repeat</v-icon>Resend VDP Order
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-tab-item>
                </template>
                <v-tooltip :disabled="!isClientDataEmpty" bottom>
                  <template #activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        v-if="isSubmitAllMediaPlansAvailable"
                        :disabled="isClientDataEmpty"
                        text
                        class="text-capitalize mb-2"
                        color="grey darken-2"
                        @click="submitAllMediaPlans()"
                        ><v-icon class="pr-3">{{ submitAllMediaPlansIcon }}</v-icon> Submit All Weeks</v-btn
                      >
                    </div>
                  </template>
                  <span>Please set first name and last name or company name</span>
                </v-tooltip>
              </v-tabs>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import LdpSummaryDialog from '../dialogs/LdpSummaryDialog';
import ConfirmationDialog from '../orders/orderDialogs/ConfirmationDialog';

export default {
  components: {
    VueJsonPretty,
    'ldp-summary-dialog': LdpSummaryDialog,
    'confirmation-dialog': ConfirmationDialog
  },
  props: ['isPanelOpened', 'firebaseOrderId', 'orderData'],
  data() {
    return {
      isLdpAlertVisible: false,
      ldpAlertType: 'info',
      ldpAlertMessage: '',
      isInfoText: false,
      isMapExpress: null,
      ldpResult: {},
      isMediaPlanCreated: [],
      mediaPlans: [],
      isLdpBookingSubmitted: false,
      ldpBookings: [],
      isSummaryDialogOpened: false,
      isLdpOrderLoading: false,
      dialogTitle: '',
      isConfirmationDialogOpened: false,
      isClientNameEditEnabled: false,
      clientData: {
        clientName: '',
        clientNameAppearingOnAd: '',
        clientPromotion: ''
      },
      deliveryWeeks: [],
      selectedMediaPlan: 'mediaPlanTab-0',
      mediaPlanResultTab: 0,
      isSubmitAllSelected: false,
      selectedLdpObjectToDelete: null,
      selectedDeliveryWeek: '',
      mailDropIdList: [],
      isLdpCsvUploadedToOsprey: this.orderData?.is_ldp_csv_uploaded_to_osprey || false,
      isLdpLoaderVisible: false,
      ldpDateMenusVisibility: [],
      formattedDeliveryWeeks: [],
      expressWeeks: [],
      dateFormats: ['YYYY-MM-DD', 'DD-MMM-YYYY', 'MMMM, DD YYYY HH:mm:ss'],
      currentDeliveryData: null
    };
  },
  computed: {
    isOspreyOrderIdAvailable() {
      return this.orderData && parseInt(this.orderData.crm_order_id) !== 0;
    },
    mapId() {
      return this.orderData.references.map_id?.split('/').pop() || '';
    },
    submitAllMediaPlansIcon() {
      return `mdi-numeric-${this.deliveryWeeks.length}-box-multiple-outline`;
    },
    isSubmitAllMediaPlansAvailable() {
      return (this.deliveryWeeks.length > 1 && this.deliveryWeeks.length !== this.ldpBookings.filter(booking => booking.ldpBookingOrderID).length) || !this.mediaPlans.length;
    },
    isSubmitLdpOrderButtonVisible() {
      return !!this.mediaPlans.length;
    },
    isDeliveryWeeksEmpty() {
      return !this.deliveryWeeks.length;
    },
    isClientDataEmpty() {
      return Boolean(Object.values(this.clientData).filter(value => value === '' || value.replace(/ /g, '') === '').length);
    }
  },
  watch: {
    isPanelOpened: {
      handler(value) {
        if (value) this.initLdpOrder();
      },
      immediate: true
    },
    orderData: {
      async handler(value) {
        try {
          await this.initLdpOrder();
          this.checkContractNumberValidationError(value);
          if (this.isMapExpress && this.mapId) {
            const map = await this.$store.dispatch('maps/getMapDataById', { mapId: this.mapId });
            this.expressWeeks = map?.availability?.filter(week => week?.discounted === 'express').map(week => week.week);
          }
        } catch (error) {
          console.log('handler function in ldpOrder has failed: ', error);
        }
      },
      immediate: true
    },
    'orderData.first_name': {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.getCompanyName();
        }
      }
    },
    'orderData.last_name': {
      handler(value, oldValue) {
        if (value !== oldValue) {
          this.getCompanyName();
        }
      }
    },
    formattedDeliveryWeeks: {
      handler(value) {
        value.forEach((dropDate, index) => {
          this.deliveryWeeks[index].date = this.$moment(dropDate, this.dateFormats).format('MMMM, DD YYYY HH:mm:ss');
        });
      },
      deep: true
    }
  },
  created() {
    this.getCompanyName();
  },
  methods: {
    async uploadCsvToOsprey() {
      this.isLdpLoaderVisible = true;
      try {
        await this.$store.dispatch('maps/uploadLdpCsvData', { mapId: this.mapId, ospreyOrderId: this.orderData?.crm_order_id, mailDropIdList: this.mailDropIdList });

        await this.$store.dispatch('om_orders/setUploadedLdpCsvStateInOrder', {
          id: this.orderData.firebase_order_id,
          isLdpCsvUploadedToOsprey: true
        });

        this.isLdpCsvUploadedToOsprey = true;
        this.setLdpAlert('success', 'LDP CSV has been uploaded successfully!', false);
      } catch (error) {
        this.isLdpCsvUploadedToOsprey = false;
        this.setLdpAlert('error', 'Uploading the LDP CSV file has failed. Please try again later.', false);
      } finally {
        this.isLdpLoaderVisible = false;
      }
    },
    async getCompanyName() {
      const { companyName } = await this.$store
        .dispatch('customer_messaging/listOrderData', { documentId: this.firebaseOrderId })
        .catch(error => this.setLdpAlert('error', error, false));
      const clientBusinessName = companyName || `${this.orderData.first_name} ${this.orderData.last_name}`;
      const formattedBusinessName = clientBusinessName.toUpperCase();
      this.clientData = {
        clientName: formattedBusinessName,
        clientNameAppearingOnAd: formattedBusinessName.substring(0, 15),
        clientPromotion: formattedBusinessName.substring(0, 30)
      };
      console.log('[ Default Client Data ]:', this.clientData);
    },
    async initLdpOrder() {
      if (this.orderData) {
        this.deliveryWeeks =
          (await this.$store.dispatch('om_orders/getOrderDataDoc', { orderId: this.firebaseOrderId, docName: 'delivery' }))?.delivery_dates.filter(drop =>
            [690, 692, 700].includes(Number(drop.drop_status))
          ) || [];

        this.formattedDeliveryWeeks = this.deliveryWeeks.map(({ date }) => this.formatDate(date, true));

        // Set isMapExpress
        this.isMapExpress = this.orderData.hasOwnProperty('is_map_express') ? this.orderData.is_map_express : true;
        this.mailDropIdList = this.deliveryWeeks.map(drop => drop.mail_drop_id);

        if (this.orderData.ldp_order) {
          this.isLdpOrderLoading = true;
          this.ldpResult = this.orderData.ldp_order;

          console.log('[ LDP RESULT ]:', this.ldpResult);

          this.mediaPlans = this.ldpResult.media_plans || [];
          this.isMediaPlanCreated = this.mailDropIdList.map(mailDropId => this.mediaPlans.some(plan => plan.mailDropId === mailDropId && plan?.status !== 'error'));

          this.ldpBookings = this.ldpResult.ldp_bookings || [];
          if (this.ldpBookings) this.isLdpBookingSubmitted = true;

          const mediaPlans = this.mediaPlans || null;

          this.deliveryWeeks.map(
            (item, index) => (item.isExpress = index === 0 ? mediaPlans[mediaPlans.findIndex(plan => plan.mailDropId === item.mail_drop_id)]?.is_map_express || false : false)
          );
        } else {
          // Set isExpress to the first week, set isExpress to false to the other weeks
          this.deliveryWeeks.map((item, index) => (item.isExpress = index === 0 ? this.isMapExpress : false));
        }
      }
    },
    getLengthOfFailedPlans(mediaPlans) {
      return mediaPlans.filter(mediaPlan => mediaPlan?.status === 'error').length;
    },
    async callLdpSubmitMediaPlan(deliveryData, index) {
      if (!this.mapId) {
        this.setLdpAlert('error', `No map ID found in order references!`, false);
      } else {
        this.selectedDeliveryWeek = deliveryData.date;
        this.dialogTitle = `Creating Media Plan for week ${this.$moment(this.selectedDeliveryWeek, this.dateFormats).format('MMM DD')}`;
        this.isSummaryDialogOpened = this.isLdpOrderLoading = true;
        this.currentDeliveryData = deliveryData;
        try {
          await this.$store.dispatch('ldp/submitMediaPlan', {
            mapId: this.mapId,
            ospreyOrderId: this.isOspreyOrderIdAvailable ? this.orderData.crm_order_id : 0,
            firebaseOrderId: this.firebaseOrderId,
            switchExpress: deliveryData.isExpress,
            week: this.selectedDeliveryWeek,
            mailDropId: deliveryData.mail_drop_id || ''
          });
          this.isMediaPlanCreated[index] = true;
          await this.$store.dispatch('om_orders/updateDeliveryDateInOsrpey', {
            mail_drop_id: deliveryData.mail_drop_id,
            data: {
              EstimatedMailDate: this.formattedDeliveryWeeks[index]
            }
          });
        } catch (error) {
          this.setLdpAlert('error', error.message, false);
          this.isSummaryDialogOpened = false;
          this.currentDeliveryData = null;
        } finally {
          this.isLdpOrderLoading = false;
        }
      }
    },
    async submitAllMediaPlans() {
      this.dialogTitle = `Submitting Order`;
      this.isSubmitAllSelected = this.isSummaryDialogOpened = this.isLdpOrderLoading = true;

      if (this.mediaPlans.filter(mediaplan => mediaplan.mediaPlanGroupId).length === this.deliveryWeeks.length) {
        return this.submitAllLdpOrders();
      } else {
        const deliveryWeeks = this.deliveryWeeks
          .map(({ date, mail_drop_id, isExpress }) => ({
            date,
            mailDropId: mail_drop_id,
            isExpress
          }))
          .filter(delivery => this.isMediaPlanDataEmpty(delivery.mailDropId));
        this.dialogTitle = `Creating Media Plans for weeks ${this.$moment(Object.values(deliveryWeeks[0]).toString()).format('MMM DD')} - ${this.$moment(
          Object.values(deliveryWeeks.slice(-1).pop()).toString()
        ).format('MMM DD')}`;

        try {
          const resultPlans = await this.$store.dispatch('ldp/submitAllMediaPlans', {
            mapId: this.mapId,
            ospreyOrderId: this.isOspreyOrderIdAvailable ? this.orderData.crm_order_id : 0,
            firebaseOrderId: this.firebaseOrderId,
            weeks: deliveryWeeks
          });

          console.log('[ldp/submitAllMediaPlans] Result: ', resultPlans);
          const mediaPlanGroupIds = resultPlans.filter(item => !!item.mediaPlanGroupId);
          if (mediaPlanGroupIds.length === deliveryWeeks.length) {
            this.isMediaPlanCreated = deliveryWeeks.map(() => true);
          }
        } catch (error) {
          this.setLdpAlert('error', error.message, false);
        } finally {
          this.isLdpOrderLoading = false;
        }
      }
    },
    async callLdpSubmitOrder(deliveryData) {
      if (this.isClientDataEmpty) {
        this.setLdpAlert('error', `Client name values cannot be empty!`, false);
      } else if (!this.mapId) {
        this.setLdpAlert('error', `No map ID found in order references!`, false);
      } else {
        this.dialogTitle = 'Submitting Order';
        this.isSummaryDialogOpened = true;
        this.isLdpOrderLoading = true;

        if (!this.isSubmitAllSelected) {
          const selectedMediaPlan = deliveryData ? this.getMediaPlanJsonData(deliveryData.mail_drop_id) : this.getMediaPlanByDate(this.selectedDeliveryWeek) || {};

          if (!selectedMediaPlan) {
            this.setLdpAlert('error', 'No MediaPlan found for this week!', false);
          }

          try {
            const result = await this.$store.dispatch('ldp/submitLdpOrder', {
              mapId: this.mapId,
              orderData: this.orderData,
              switchExpress: deliveryData?.isExpress,
              mediaPlan: selectedMediaPlan,
              firebaseOrderId: this.firebaseOrderId,
              client: this.clientData,
              week: deliveryData?.date || this.selectedDeliveryWeek || ''
            });

            console.log('Submit LDP order Result :: ', result);
            this.isLdpBookingSubmitted = true;
            return result;
          } catch (error) {
            this.setLdpAlert('error', error.message, false);
            this.isSummaryDialogOpened = false;
          } finally {
            this.isLdpOrderLoading = false;
            this.selectedDeliveryWeek = '';
          }
        } else {
          return this.submitAllLdpOrders();
        }
      }
    },
    submitAllLdpOrders() {
      const filteredDeliveryWeeks = this.deliveryWeeks.filter(delivery => !this.getLdpOrderResultJson(this.formatDate(delivery.date)));
      this.$store
        .dispatch('ldp/submitAllLdpOrders', {
          mapId: this.mapId,
          orderData: this.orderData,
          firebaseOrderId: this.firebaseOrderId,
          client: this.clientData,
          weeks: filteredDeliveryWeeks,
          mediaPlans: this.mediaPlans
        })
        .finally(() => {
          this.isSubmitAllSelected = false;
        });
    },
    deleteLdpData(selectedWeek, mailDropId) {
      this.selectedLdpObjectToDelete = {
        ...this.getLdpOrderResultJson(selectedWeek),
        mailDropId
      };
      this.isConfirmationDialogOpened = true;
    },
    async deleteLdpDataConfirmed() {
      this.closeConfirmationDialog();
      this.$store.dispatch('showLoader', { message: 'Deleting LDP data from order...' });
      try {
        await this.$store.dispatch('ldp/deleteLdpDataFromOrder', { firebase_order_id: this.firebaseOrderId, data: this.selectedLdpObjectToDelete });
        this.setLdpAlert('success', `LDP data deleted successfully!`, false);
      } catch (error) {
        this.setLdpAlert('error', error, false);
      } finally {
        this.selectedLdpObjectToDelete = null;
        this.$store.dispatch('hideLoader');
      }
    },
    closeConfirmationDialog() {
      this.isConfirmationDialogOpened = false;
    },
    closeSummaryDialog(data) {
      this.isSummaryDialogOpened = false;

      if (!data?.isSubmitMediaPlanClicked) return;

      if (this.currentDeliveryData) {
        this.callLdpSubmitOrder(this.currentDeliveryData);
        this.currentDeliveryData = null;
        return;
      }

      this.setLdpAlert('error', 'Delivery data is not found!', false);
      this.isSubmitAllSelected = false;
    },
    setLdpAlert(type, message, isInfo) {
      this.isInfoText = isInfo;
      this.ldpAlertType = type;
      this.ldpAlertMessage = message;
      this.isLdpAlertVisible = true;
      setTimeout(() => {
        this.isLdpAlertVisible = false;
      }, 15000);
    },
    checkContractNumberValidationError(order) {
      const valassisSubmitStatuses = order?.ldp_order?.submit_statuses ?? [];
      if (valassisSubmitStatuses.length) {
        if (valassisSubmitStatuses.filter(statusResponse => statusResponse?.message?.toLowerCase().includes('Contract/Agreement is not Valid'.toLowerCase())).length) {
          this.setLdpAlert('warning', 'Please provide a valid contract number! Claim the new one from Valassis then update it under site settings!');
        } else if (
          valassisSubmitStatuses.filter(statusResponse => statusResponse?.result?.toLowerCase() === 'success').length &&
          this.ldpBookings.length === valassisSubmitStatuses.length
        ) {
          this.setLdpAlert('success', 'LDP order has been submitted successfully!', false);
        }
      }
    },
    getMediaPlanJsonData(mailDropId) {
      const mediaPlanData = this.mediaPlans.filter(mediaplan => parseInt(mediaplan.mailDropId) === parseInt(mailDropId));
      return mediaPlanData.length ? mediaPlanData[0] : null;
    },
    getLdpOrderResultJson(advertisementDate) {
      const ldpBookingData = this.ldpBookings
        ? this.ldpBookings.filter(booking => booking.ldp_booking_data?.orderBody?.baseProductList[0].productType.advertisementDate === advertisementDate)
        : null;
      return ldpBookingData?.length ? ldpBookingData.reduce((prev, current) => (Number(prev.ldpBookingOrderID) > Number(current.ldpBookingOrderID) ? prev : current)) : null;
    },
    getWebhookResponseJsonData(advertisementDate) {
      const ldpOrderResult = this.getLdpOrderResultJson(advertisementDate);
      return this.ldpResult.submit_statuses && ldpOrderResult && ldpOrderResult.ldpBookingOrderID
        ? this.ldpResult.submit_statuses.filter(response => parseInt(response.fileId) === parseInt(ldpOrderResult.ldpBookingOrderID))[0]
        : null;
    },
    isResubmitButtonVisible(advertisementDate) {
      const webhookResponse = this.getWebhookResponseJsonData(advertisementDate);
      return webhookResponse?.result?.toLowerCase() !== 'success' || false;
    },
    getDropStatusProps(advertisementDate) {
      const webhookResponse = this.getWebhookResponseJsonData(this.formatDate(advertisementDate));
      return {
        isVisible: !!webhookResponse,
        icon: webhookResponse?.result === 'Error' ? 'mdi-alert-circle-outline' : 'mdi-check-circle-outline',
        color: webhookResponse?.result === 'Error' ? 'red' : 'green',
        message: webhookResponse?.message ?? ''
      };
    },
    isMediaPlanCreationFailed(mailDropId) {
      const mediaPlanData = this.getMediaPlanJsonData(mailDropId);
      return mediaPlanData?.status
        ? mediaPlanData.status === 'error'
        : mediaPlanData?.message
        ? ['failed', 'error'].some(word => mediaPlanData.message.toLowerCase().indexOf(word) !== -1)
        : false;
    },
    isMediaPlanDataEmpty(mailDropId) {
      return !this.getMediaPlanJsonData(mailDropId) || this.isMediaPlanCreationFailed(mailDropId);
    },
    getMediaPlanByDate(date) {
      return this.mediaPlans.find(mediaPlan => mediaPlan?.startDate?.seconds === this.$moment(date).unix());
    },
    formatDate(date, isVuetifyFormat = false) {
      const isObject = typeof date === 'object' && Object.values(date).length;
      const dateToConvert = isObject ? Object.values(date)[0] * 1000 : date;
      return this.$moment(dateToConvert, isObject ? 'X' : this.dateFormats).format(isVuetifyFormat ? 'YYYY-MM-DD' : 'DD-MMM-YYYY');
    },
    isSubmitVdpOrderButtonDisabled(delivery) {
      return this.isMediaPlanCreationFailed(delivery.mail_drop_id) || this.isClientDataEmpty;
    },
    allowDates(index, val) {
      const isExpressWeek = this.expressWeeks.includes(val);
      if (index === 0 && this.isMapExpress) {
        return isExpressWeek;
      }
      const isAfterFirstDropWeek = this.$moment(val).isAfter(this.formattedDeliveryWeeks[0]);
      const isSunday = this.$moment(val).day() === 0;
      const isAlreadySelected = this.formattedDeliveryWeeks.includes(val);
      return isAfterFirstDropWeek && isSunday && !isAlreadySelected && !isExpressWeek;
    },
    selectMediaPlan(index) {
      this.selectedMediaPlan = index;
    },
    showMediaPlan(mailDropId) {
      return this.getMediaPlanJsonData(mailDropId) && this.getMediaPlanJsonData(mailDropId).status !== 'error';
    }
  }
};
</script>

<style scoped>
.custom-row-height {
  height: 17vh;
}

.padding-large {
  margin-bottom: 2em;
}
</style>
