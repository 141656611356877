<template>
  <v-card class="elevation-2" height="120px">
    <v-card-text class="mb-n5 subtitle-1">
      <v-row class="mx-1 justify-space-between">
        <v-col cols="2" class="mt-3">
          <v-icon v-if="isIcon" large color="cyan darken-2">{{ icon }}</v-icon>
          <v-avatar v-else-if="isAvatar" color="cyan darken-2" class="ml-n2">
            <span class="white--text title">{{ icon }}</span>
          </v-avatar>
          <v-img v-else-if="isImage" src="@/assets/logos/ospreyLogo.png" max-width="30px"></v-img>
        </v-col>
        <v-col cols="9">
          <v-row class="mt-n1 mb-3 d-flex">
            <span class="font-weight-light grey--text text--darken-1">{{ title }}</span>

            <v-tooltip v-if="actionIcon" bottom class="ml-3">
              <template v-slot:activator="{ on }">
                <v-btn icon class="ml-3 mt-n1" @click="executeAction" v-on="on"
                  ><v-icon color="cyan darken-2">{{ actionIcon }}</v-icon></v-btn
                >
              </template>
              <span>{{ actionText }}</span>
            </v-tooltip>
          </v-row>
          <v-row class="font-weight-regular grey--text text--darken-3">
            <span>{{ getContent }}</span>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ['iconType', 'icon', 'title', 'content', 'action', 'actionIcon', 'actionText'],
  computed: {
    isIcon() {
      return this.iconType === 'icon';
    },
    isAvatar() {
      return this.iconType === 'avatar';
    },
    isImage() {
      return this.iconType === 'image';
    },
    getContent() {
      return this.content || '-';
    }
  },
  methods: {
    executeAction() {
      this[this.action]();
    },
    openInOsprey() {
      window.open(`${process.env.VUE_APP_OSPREY_ENDPOINT}/crm/customer/view.cfm?id=${this.content}`, '_blank');
    },
    setName() {
      this.$emit('customerEdit');
    }
  }
};
</script>