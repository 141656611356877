<template>
  <v-dialog v-if="isDialogOpen" v-model="isDialogOpen" transition="fade-transition" max-width="500px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
        <v-spacer />
        <v-btn color="grey darken-1" icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-alert v-if="isAlertVisible" :type="alertType" outlined dismissible>{{ alertMessage }}</v-alert>
      <v-card-text class="pt-3">
        <v-form ref="userForm" v-model="isUserFormValid">
          <v-container>
            <v-row>
              <v-text-field v-model="user.name" label="Name" :rules="nameRule" required outlined dense></v-text-field>
            </v-row>
            <v-row>
              <v-text-field v-model="user.email" label="Email" :rules="emailRules" required outlined dense></v-text-field>
            </v-row>
            <template v-if="!isEditMode">
              <v-row>
                <v-select v-model="selectedAuthType" :items="authTypes" label="Authentication Type" :rules="authTypeRule" required outlined dense></v-select>
              </v-row>
              <v-row v-if="isGenerateOneTimePassBtnVisible" class="mt-2 mb-5">
                <v-btn class="grey--text text--darken-2" outlined @click="generatePassword()">Generate Password</v-btn>
              </v-row>
            </template>
            <v-row v-if="user._id">
              <v-text-field v-model="user._id" label="User FirebaseID" type="text" readonly outlined dense>
                <v-tooltip slot="append-outer" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon color="blue-grey darken-1 pl-4" v-on="on" @click="copyUploadUrlToClipboard('firebaseId')">mdi-content-copy</v-icon>
                  </template>
                  <span>Copy FirebaseID to clipboard</span>
                </v-tooltip>
              </v-text-field>
            </v-row>
            <v-row v-if="isCopiedTextVisible">
              <p class="pl-1 mt-n5 mb-2 caption cyan--text text--darken-3">Item copied to clipboard!</p>
            </v-row>
            <v-row v-if="isOnetimePassGenerated" class="ml-n3 pt-2 mb-n3" no-gutters>
              <v-col cols="7">
                <v-text-field ref="generatedPass" v-model="generatedPass" label="Generated Password" readonly dense outlined filled>
                  <v-tooltip slot="append-outer" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon color="blue-grey darken-2" v-on="on" @click="copyUploadUrlToClipboard('generatedPassword')">mdi-content-copy</v-icon>
                    </template>
                    <span>Copy generated password to clipboard</span>
                  </v-tooltip>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-select
                v-model="selectedGroups"
                :items="availableGroups"
                label="User Groups"
                :rules="groupRule"
                multiple
                required
                item-value="key"
                item-text="name"
                outlined
                dense
              ></v-select>
            </v-row>
            <v-row>
              <v-text-field id="salesForceUserID" v-model="salesforceUserId" label="Salesforce User ID" outlined dense></v-text-field>
            </v-row>
          </v-container>
        </v-form>
        <v-row class="justify-center mt-0 mb-0">
          <v-btn color="cyan darken-2" class="px-5" dark small :disabled="!isUserFormValid" :loading="isUpdateInProgress" @click="saveUser()">Save</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import userGroupsService from '../../../services/userGroupsService.js';

export default {
  props: ['isDialogOpened', 'title', 'isEditMode', 'userData'],
  data() {
    return {
      isUserFormValid: false,
      user: {
        id: '',
        name: '',
        email: ''
      },
      nameRule: [v => !!v || 'Name is required'],
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],
      authTypeRule: [v => !!v || 'Authentication type is required'],
      groupRule: [v => !!v.length || 'Group is required'],
      emailPasswordOption: 'Email/Password',
      authTypes: ['Google', 'Email/Password'],
      selectedAuthType: '',
      isCopiedTextVisible: false,
      generatedPass: '',
      availableGroups: [],
      selectedGroups: [],
      salesforceUserId: '',
      isAlertVisible: false,
      alertType: 'info',
      alertMessage: '',
      isUpdateInProgress: false
    };
  },
  computed: {
    isGenerateOneTimePassBtnVisible() {
      return this.selectedAuthType === this.emailPasswordOption && !this.generatedPass;
    },
    isOnetimePassGenerated() {
      return this.generatedPass !== '';
    },
    isDialogOpen() {
      return this.isDialogOpened;
    }
  },
  watch: {
    userData: {
      handler: function (user) {
        if (this.isEditMode && Object.values(user).length) {
          console.log('Selected user data:', user);
          Object.assign(this.user, {
            name: user.name || `${user.first_name ?? ''} ${user.last_name ?? ''}`,
            email: user.email || '',
            id: user._id || ''
          });
          this.salesforceUserId = user.salesforce_user_id || '';
          this.generatedPass = user.one_time_pass ?? '';
          this.checkUserInGroup(user._id);
        }
      },
      immediate: true
    }
  },
  async created() {
    userGroupsService.attachRootListener(
      userGroups => {
        console.log(userGroups);
        userGroups.forEach(group => {
          this.availableGroups.push({
            key: group._id,
            name: group.name
          });
        });
      },
      { isIdReturned: true, isArray: true }
    );
  },
  methods: {
    saveUser() {
      this.isUpdateInProgress = true;
      const userConfig = {
        id: this.user.id ?? '',
        name: this.user.name,
        email: this.user.email,
        selectedGroups: this.selectedGroups,
        salesforceUserID: this.salesforceUserId
      };
      if (!this.isEditMode) {
        this.addNewUser(userConfig);
      } else {
        this.updateUser(userConfig);
      }
    },
    addNewUser(userConfig) {
      if (this.selectedAuthType === this.emailPasswordOption) Object.assign(userConfig, { one_time_pass: this.generatedPass });
      this.$store
        .dispatch('users/addNewUser', userConfig)
        .then(userId => {
          this.$emit('displayFeedback', { type: 'success', message: `User successfully added with ID: ${userId}` });
          this.closeDialog();
        })
        .catch(error => this.displayAlert('error', error))
        .finally(() => (this.isUpdateInProgress = false));
    },
    updateUser(userConfig) {
      const userUpdateConfig = {
        ...userConfig
      };
      const addUserToGroups = this.userData?.groups
        ? this.selectedGroups.filter(
            group =>
              !Object.values(this.userData.groups)
                .map(userGroup => userGroup.id)
                .includes(group)
          )
        : this.selectedGroups;
      const removeUserFromGroups = this.userData?.groups
        ? Object.values(this.userData.groups)
            .map(userGroup => userGroup.id)
            .filter(savedGroup => this.selectedGroups.indexOf(savedGroup) === -1)
        : [];

      const isUserGroupChanged = !!addUserToGroups.length || !!removeUserFromGroups.length;

      if (isUserGroupChanged) Object.assign(userUpdateConfig, { isUserGroupChanged, addUserToGroups, removeUserFromGroups });

      this.$store
        .dispatch('users/updateUser', userUpdateConfig)
        .then(() => {
          this.$emit('displayFeedback', { type: 'success', message: `User edited successfully` });
          this.closeDialog();
        })
        .catch(error => this.displayAlert('error', error))
        .finally(() => (this.isUpdateInProgress = false));
    },
    async checkUserInGroup(userKey) {
      this.selectedGroups = await this.$store.dispatch('users/selectedGroups', { userKey }).catch(error => {
        console.log('Getting user groups failed:', error);
        this.displayAlert('error', error);
        return Promise.reject(error);
      });
    },
    closeDialog() {
      this.$refs.userForm.reset();
      this.generatedPass = '';
      this.$emit('closeDialog');
    },
    copyUploadUrlToClipboard(item) {
      navigator.clipboard.writeText(item === 'generatedPassword' ? this.generatedPass : item === 'firebaseId' ? this.user.id : '');
      this.isCopiedTextVisible = true;
      setTimeout(() => {
        this.isCopiedTextVisible = false;
      }, 2000);
    },
    generatePassword() {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      this.generatedPass = result;
    },
    displayAlert(type, message) {
      this.alertType = type;
      this.alertMessage = message;
      this.isAlertVisible = true;
      setTimeout(() => {
        this.isAlertVisible = false;
      }, 8000);
    }
  }
};
</script>
