import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class OrderLogsService extends FirestoreModel {
  constructor() {
    super(collections.order_logs);
  }

  async getOrderLogsId(documentId) {
    try {
      const firebaseId = documentId;
      let orderLogFirebaseID = '';
      const querySnapshot = await this.collectionRef.where('firebase_order_id', '==', firebaseId).get();

      if (!querySnapshot.empty) {
        querySnapshot.forEach(doc => {
          orderLogFirebaseID = doc.id;
        });
        return {
          status: 'success',
          orderLogFirebaseID
        };
      }
      return {
        status: 'error',
        message: 'Firebase Order Log ID: ' + firebaseId + ' does not exist!'
      };
    } catch (err) {
      console.error('getOrderLogsId has failed: ', err);
    }
  }
}

export default new OrderLogsService();
