<template>
  <v-dialog v-model="mapsDialog" max-width="1200" persistent>
    <v-card transition="fade-transition" min-height="600">
      <v-card-title>
        <v-row no-gutters class="mt-1">
          <v-tooltip bottom open-delay="300">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="expandSearchField()"><v-icon>mdi-magnify</v-icon></v-btn>
            </template>
            <span>Search map</span>
          </v-tooltip>
          <v-col v-if="isSearchExpanded" cols="6" sm="4" class="mb-n7 mt-n1">
            <v-expand-x-transition>
              <v-text-field v-show="isSearchExpanded" v-model="searchValue" class="pl-2" outlined dense placeholder="Search" :hint="searchHintMessage"></v-text-field>
            </v-expand-x-transition>
          </v-col>
          <v-spacer />
          <span v-if="!isSearchExpanded" class="title font-weight-regular grey--text text--darken-2">Select a Map</span>
          <v-spacer />
          <v-btn icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>
        </v-row>
      </v-card-title>
      <v-row v-if="loading" class="pt-12 justify-center" no-gutters>
        <v-progress-circular color="primary" size="100" indeterminate></v-progress-circular>
      </v-row>
      <v-card-text v-else transition="fade-transition" class="pt-3">
        <template v-for="(map, index) in maps">
          <v-row :key="map.firebase_map_id" class="justify-space-around mb-3" no-gutters>
            <v-col cols="12" sm="2" class="d-flex flex-column">
              <v-row
                ><span class="subtitle-1 primary--text font-weight-regular pl-3 pt-2">Total Count: {{ map.totalCount }}</span></v-row
              >
              <v-row
                ><span class="subtitle-1 font-weight-regular pl-3">{{ map.orderType === 'eddm' ? map.orderType.toUpperCase() : map.orderType }} Map</span></v-row
              >
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="7" class="ml-4">
              <v-row
                ><span class="subtitle-1 primary--text">{{ map.name }}</span></v-row
              >
              <v-row>
                <span class="subtitle-1 secondary--text text--darken-3 font-weight-regular">
                  <span class="grey--text text--darken-2">Customer Email: </span>
                  {{ map.customer.email }}
                </span>
              </v-row>
              <v-row
                ><span class="subtitle-1 secondary--text text--darken-3 font-weight-regular">Created: {{ formatDate(map.creationDateTimestamp) }}</span></v-row
              >
            </v-col>
            <v-col cols="2" class="d-flex flex-column mr-3">
              <v-row class="justify-end align-center" no-gutters>
                <v-btn color="blue" class="white--text" small @click="selectMap(map)">Select</v-btn>
              </v-row>
              <v-row class="justify-end align-center pt-2" no-gutters>
                <v-btn text class="caption grey--text text--darken-3 font-weight-regular" outlined small @click="viewMapDetails(map.firebase_map_id)">Details</v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-divider :key="index" class="mb-4" />
        </template>
        <v-row v-if="maps.length" class="justify-center pt-1">
          <v-tooltip left open-delay="500">
            <template v-slot:activator="{ on }">
              <v-btn icon large v-on="on" @click="paginate('back')"><v-icon left>mdi-chevron-left</v-icon></v-btn>
            </template>
            <span>Previous Page</span>
          </v-tooltip>
          <span class="pt-2 subtitle-1">{{ pageCount }}</span>
          <v-tooltip right open-delay="500">
            <template v-slot:activator="{ on }">
              <v-btn icon large v-on="on" @click="paginate('next')"><v-icon right>mdi-chevron-right</v-icon></v-btn>
            </template>
            <span>Next Page</span>
          </v-tooltip>
        </v-row>
        <v-row v-else class="justify-center"><span class="subtitle-2">No results found</span></v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['selectMapDialog'],
  data() {
    return {
      mapsDialog: this.selectMapDialog,
      loading: true,
      maps: [],
      pageCount: 1,
      isSearchExpanded: false,
      searchValue: '',
      showMinLenghtSeachValue: false,
      searchTypeTimer: null
    };
  },
  computed: {
    searchHintMessage() {
      return this.showMinLenghtSeachValue ? 'Please enter min 3 characters to begin searching!' : '';
    }
  },
  watch: {
    selectMapDialog(value) {
      this.mapsDialog = value;
    },
    searchValue(value) {
      if (value.length === 0) {
        this.showMinLenghtSeachValue = false;
        this.initResult();
      } else if (value.length && value.length < 3) {
        this.showMinLenghtSeachValue = true;
      } else {
        this.showMinLenghtSeachValue = false;
        this.searchTypeTimer = setTimeout(async () => {
          this.pageCount = 1;
          this.maps = await this.getMapsFromAlgolia(value, 0);
        }, 1000);
      }
    }
  },
  mounted() {
    this.initResult();
  },
  methods: {
    getMapsFromAlgolia(searchValue, page) {
      this.loading = true;
      return this.$store
        .dispatch('algolia/getDataFromAlgolia', {
          indexName: `maps${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`,
          filter: 'orderType:newmovers',
          hitsPerPage: 5,
          searchValue: searchValue || '',
          page: page && page > 1 ? page - 1 : 0
        })
        .then(content => content?.hits ?? [])
        .catch(error => {
          console.log('[getMapsFromAlgolia ERROR]:', error, error.debugData);
          return Promise.reject(error);
        })
        .finally(() => (this.loading = false));
    },
    async initResult() {
      this.maps = await this.getMapsFromAlgolia('', this.pageCount);
    },
    async paginate(direction) {
      this.maps = [];
      this.pageCount = direction === 'next' ? this.pageCount + 1 : this.pageCount === 1 ? this.pageCount : this.pageCount - 1;
      this.maps = await this.getMapsFromAlgolia(this.searchValue?.length >= 3 ? this.searchValue : '', this.pageCount);
    },
    selectMap(map) {
      this.$emit('selectedMapId', map);
      this.closeDialog();
    },
    viewMapDetails(mapId) {
      let routeData = this.$router.resolve(`/maps/view/${mapId}`);
      window.open(routeData.href, '_blank');
    },
    formatDate(dateTotransform) {
      var date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY');
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    expandSearchField() {
      this.isSearchExpanded = !this.isSearchExpanded;
    }
  }
};
</script>

<style scoped></style>
