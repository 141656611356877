<template>
  <v-container fluid>
    <v-row>
      <v-btn icon large @click="navigateBack()"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <span class="ml-3 pt-1 title font-weight-regular">{{ title }}</span>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-form ref="form" v-model="isFormValid">
      <v-col cols="6">
        <v-text-field v-model="productService.name" :rules="fieldRule" label="Name" outlined dense required></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field v-model="productService.tax_code" :rules="fieldRule" label="Tax Code" outlined dense required></v-text-field>
      </v-col>
      <v-col cols="1" class="ml-2">
        <v-switch v-model="productService.is_active" :label="getSwitchLabel(productService.is_active)" class="mt-0" inset></v-switch>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="productService.service_id" label="Service ID" type="number" outlined dense disabled></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="productService.netsuite_id" label="Netsuite ID" type="number" outlined dense disabled></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="productService.salesforce_id" label="Salesforce ID" outlined dense></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="productService.salesforce_price_book_entry_id" label="Salesforce Price Book Entry ID" outlined dense></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="productService.stripe_id" label="Stripe ID" outlined dense></v-text-field>
      </v-col>
      <v-container v-if="serviceOptions.length" fluid class="mt-n8">
        <v-subheader>Service Options</v-subheader>
        <v-row v-for="(option, index) in serviceOptions" :key="index">
          <v-col cols="3">
            <v-text-field v-model="option.name" :rules="fieldRule" label="Option Name" outlined dense required></v-text-field>
          </v-col>
          <v-col v-if="productService.name === 'Stocks'" cols="2"
            ><v-text-field v-model="option.thickness" label="Thickness" type="number" min="0" step="0.001" outlined dense class=""></v-text-field
          ></v-col>
          <v-col cols="1">
            <v-switch v-model="option.is_active" :label="getSwitchLabel(option.is_active)" class="mt-0" inset></v-switch>
          </v-col>
        </v-row>
      </v-container>
      <v-btn class="mr-4" @click="addServiceOption()">Add New Service Option</v-btn>
      <v-btn color="warning" class="mr-4" @click="initData">Reset Form</v-btn>
      <v-btn :disabled="!isFormValid" color="primary" @click="saveProductService"> Save Product Service </v-btn>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      firebaseServiceID: '',
      productService: {},
      isFormValid: true,
      fieldRule: [v => !!v || 'Required']
    };
  },
  computed: {
    title() {
      return this.firebaseServiceID ? `Edit: ${this.firebaseServiceID}` : 'Add New Product Service';
    },
    serviceOptions() {
      return this.productService?.options ? Object.values(this.productService.options) : [];
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      this.firebaseServiceID = Object.values(this.$route.params)[0];
      this.productService = {
        service_id: '',
        is_active: true,
        name: '',
        netsuite_id: '',
        salesforce_id: '',
        salesforce_price_book_entry_id: '',
        stripe_id: '',
        tax_code: '',
        options: {}
      };
      if (this.firebaseServiceID) {
        await this.getProductServiceById();
      }
      if (!this.serviceOptions.length) {
        this.addServiceOption(this.productService.name);
      }
    },
    async getProductServiceById() {
      this.$store.dispatch('showLoader');
      try {
        this.productService = await this.$store.dispatch('product_services/getProductServiceByID', this.firebaseServiceID);
      } catch (error) {
        console.error('[getProductServiceById error ] ', error);
        this.showAlert('error', error.message || error || 'Could not load Product Service data.');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    addServiceOption(name = '') {
      const newOption = {
        name,
        is_active: true
      };
      const newIdentifier = Object.keys(this.productService.options).length;
      this.$set(this.productService.options, newIdentifier, newOption);
      this.$nextTick(() => {
        this.$refs.form.validate();
      });
    },
    getSwitchLabel(value) {
      return value ? 'Active' : 'Inactive';
    },
    async saveProductService() {
      this.$store.dispatch('showLoader');
      const functionName = this.firebaseServiceID ? 'editProductService' : 'addProductService';
      const productServiceObject = {
        id: this.firebaseServiceID || '',
        data: this.productService
      };

      try {
        const result = await this.$store.dispatch(`product_services/${functionName}`, productServiceObject);

        await this.$store.dispatch('product_services/updateServices', productServiceObject);

        this.setSnackbar('success', `Product Service saved with id: ${result}`);
        this.navigateBack();
      } catch (error) {
        console.error('[saveProductService error ] ', error);
        this.showAlert('error', error.message || error || 'Could not save Product Service data.');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    navigateBack() {
      this.$store.dispatch('hideAlert');
      this.$router.replace(`/${this.$route.path.split('/')[1]}`);
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    }
  }
};
</script>
