import { cloudFunctionsGet, cloudFunctionsPost, cloudFunctionsDelete } from '../../modules/cloud-function-wrapper.js';

const state = {
  isCustomFieldsLoading: false,
  dmmCustomFields: [],
  mergeTagMap: {}
};

const getters = {
  getCustomFieldsLoadingState(state) {
    return state.isCustomFieldsLoading;
  },
  getDmmCustomFields(state) {
    return state.dmmCustomFields;
  },
  getMergeTagMap(state) {
    return state.mergeTagMap;
  }
};

const mutations = {
  setCustomFieldsLoadingState(state, payload) {
    state.isCustomFieldsLoading = payload;
  },
  setDmmCustomFields(state, payload) {
    state.dmmCustomFields = payload;
  },
  setMergeTagMap(state, payload) {
    state.mergeTagMap = payload;
  }
};

const actions = {
  async fetchDmmCustomFields({ commit, dispatch }, payload) {
    try {
      commit('setDmmCustomFields', []);
      commit('setMergeTagMap', {});
      commit('setCustomFieldsLoadingState', true);
      const { data } = await cloudFunctionsGet(`/newmovercampaign/getCustomFields?search=${payload.customerId}&page[size]=100`);
      commit('setDmmCustomFields', data.data);
      const mergeTagMap = Object.assign({}, ...data.data.map(({ merge_tag }) => ({ [merge_tag.split('_').slice(1).join('_')]: merge_tag })));
      commit('setMergeTagMap', mergeTagMap);
    } catch (error) {
      dispatch('showAlert', { message: error.message || 'Could not load custom fields.', type: 'error' }, { root: true });
      console.error('[ fetchDmmCustomFields ERROR ]:', error);
    } finally {
      commit('setCustomFieldsLoadingState', false);
    }
  },
  createCustomField({ dispatch }, payload) {
    return cloudFunctionsPost('/newmovercampaign/createCustomField', payload);
  },
  deleteCustomField({ dispatch }, payload) {
    return cloudFunctionsDelete(`/newmovercampaign/deleteCustomField?cfld_id=${payload.cfld_id}`);
  },
  replaceCustomFields({ getters }, string) {
    const mergeTagMap = getters.getMergeTagMap;
    for (const [key, value] of Object.entries(mergeTagMap)) {
      string = string.replace(new RegExp(`{{${key}}}`, 'g'), `{{${value}}}`);
    }
    return string;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
