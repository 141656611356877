import RestClient from './rest-client.js'

class ProductModel extends RestClient {
	constructor (context) {
		super('products', context)
		this.context = context
	}

  loadCategories () {
    return this.get({
			success: (response, resolve) => {
        console.log('Load Categories has succeeded: ', response)
				resolve(response.data.product_categories)
			},
			error: (error, reject) => {
				console.log('Get categories from the server has failed', error)
				reject()
			}
		}, 'categories')
  }

	getProductsByCategory (id) {
		console.log('getProductsByCategory', id)
		return this.get({
			success: (response, resolve) => {
        console.log('GetProductsByCategory has succeeded: ', response)
				resolve(response.data.products)
			},
			error: (error, reject) => {
				console.log('GetProductsByCategory from the server has failed', error)
				reject()
			}
		}, `categories/${id}`)
	}

	getProduct (id) {
		return this.get({
			success: (response, resolve) => {
        console.log('GetProduct has succeeded: ', response)
				resolve(response.data)
			},
			error: (error, reject) => {
				console.log('GetProduct from the server has failed', error)
				reject()
			}
		}, id)
	}
}

export { ProductModel as default }
