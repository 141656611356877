<template>
  <v-row class="mb-4">
    <v-col v-for="(widget, index) in widgets" :key="index" cols="12" sm="6" md="5" lg="4" xl="3">
      <v-card class="elevation-2 infoCard" @click="navigateToSelected(widget)" @mouseover="addIconColor" @mouseleave="removeIconColor">
        <v-row class="align-center widgetContent mx-1 mt-0" no-gutters>
          <v-col cols="4" xl="3" class="text-center">
            <v-btn large fab outlined color="grey lighten-2">
              <v-progress-circular v-if="widgetLoading" size="30" color="grey" indeterminate></v-progress-circular>
              <v-icon v-else :color="iconColor">{{ widget.icon }}</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="!widgetLoading" cols="8" xl="9" class="ml-n3">
            <v-row class="text-center title font-weight-light black--text" align="center" justify="center">
              <span v-if="widget.type && widget.type === 'price'">{{ formatNumeral(widget.value) }}</span>
              <span v-else-if="widget.value && widget.value.length > 15" class="pt-1">{{ widget.value }}</span>
              <span v-else>{{ widget.value }}</span>
            </v-row>
            <v-row class="text-center" justify="center">
              <span class="subtitle-1 grey--text text--darken-2">{{ widget.title }}</span>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const numeral = require('numeral');

export default {
  props: ['selectedPeriod'],
  data() {
    return {
      widgets: [
        { icon: 'mdi-cart', title: 'New orders', value: '', id: 'orders' },
        { icon: 'mdi-clipboard-text', title: 'Most ordered type', value: '', id: 'orderTypes' },
        { icon: 'mdi-currency-usd', title: 'Sales', value: 0, type: 'price', id: 'sales' },
        { icon: 'mdi-tag', title: 'Most ordered product', value: '', id: 'mostOrdered' },
        { icon: 'mdi-account-multiple', title: 'New customers', value: '', id: 'customers' },
        { icon: 'mdi-map', title: 'Maps created', value: '', id: 'maps' },
        { icon: 'mdi-web', title: 'Best performing site', value: '', id: 'bestPerforming' },
        { icon: 'mdi-tray-full', title: 'Total print quantities', value: 0, id: 'printQty' }
      ],
      startDate: '',
      endDate: '',
      widgetLoading: false,
      iconColor: 'grey darken-2'
    };
  },
  watch: {
    selectedPeriod(value) {
      this.widgetLoading = true;
      this.startDate = '';
      this.endDate = '';
      switch (value) {
        case 'Today':
          this.startDate = this.endDate = this.getFormattedDate();
          break;
        case 'Yesterday':
          this.startDate = this.endDate = this.getFormattedDate(this.$moment().subtract(1, 'days'));
          break;
        case 'This Week':
          this.startDate = this.getFormattedDate(this.$moment().startOf('week'));
          this.endDate = this.getFormattedDate();
          break;
        case 'Last 7 Days':
          this.startDate = this.getFormattedDate(this.$moment().subtract(7, 'days'));
          this.endDate = this.getFormattedDate();
          break;
        case 'Last Week':
          this.startDate = this.getFormattedDate(this.$moment().subtract(1, 'week').startOf('week'));
          this.endDate = this.getFormattedDate(this.$moment().subtract(1, 'week').endOf('week'));
          break;
      }
      this.getFilterTimestamps(this.startDate, this.endDate);
    }
  },
  created() {
    this.widgetLoading = true;
    this.startDate = this.endDate = this.getFormattedDate();
    this.getFilterTimestamps(this.startDate, this.endDate);
  },
  methods: {
    getDataFromAlgolia(indexName, filter) {
      return this.$store.dispatch('algolia/getDataFromAlgolia', { indexName, filter, hitsPerPage: 100000 }).catch(error => {
        console.log('Getting data from algolia failed:', error, error.debugData);
        return error;
      });
    },
    processOrders(orders, totalNrOfOrders) {
      const sitesArray = [];
      const products = [];
      let sales = 0;
      const orderTypes = [];
      let totalPrintQuantities = 0;

      if (orders?.length > 0) {
        orders.forEach(order => {
          const siteIndex = sitesArray.findIndex(item => item.siteId === order.site_id);
          if (siteIndex === -1) {
            sitesArray.push({
              siteId: order.site_id,
              siteName: order.site_name,
              occurrence: 1
            });
          } else {
            sitesArray[siteIndex].occurrence += 1;
          }

          const productIndex = products.findIndex(item => item.productName === order.product_name);
          if (productIndex === -1) {
            products.push({
              productName: order.product_name,
              occurrence: 1
            });
          } else {
            products[productIndex].occurrence += 1;
          }

          const typeIndex = orderTypes.findIndex(item => item.typeName === order.order_type);
          if (typeIndex === -1) {
            orderTypes.push({
              typeName: order.order_type,
              occurrence: 1
            });
          } else {
            orderTypes[typeIndex].occurrence += 1;
          }

          if (order.order_total) {
            sales += order.order_total;
          }

          if (order.print_qty) {
            totalPrintQuantities += order.print_qty;
          }
        });

        const bestPerformingSite = this.getMostOccurencesOfArray(sitesArray, 'siteName');
        this.widgets.map(item => (item.id === 'bestPerforming' ? (item.value = bestPerformingSite) : ''));

        const mostOrdered = this.getMostOccurencesOfArray(products, 'productName');
        this.widgets.map(item => (item.id === 'mostOrdered' ? (item.value = mostOrdered) : ''));

        const mostOrderedType = this.getMostOccurencesOfArray(orderTypes, 'typeName');
        this.widgets.map(item => (item.id === 'orderTypes' ? (item.value = mostOrderedType) : ''));

        this.widgets.map(item => (item.id === 'printQty' ? (item.value = totalPrintQuantities) : 0));
        this.widgets.map(item => (item.id === 'sales' ? (item.value = sales) : 0));
        this.widgets.map(item => (item.id === 'orders' ? (item.value = totalNrOfOrders) : 0));
      }
      this.widgetLoading = false;
    },
    filterByDate(startDate, endDate) {
      const filter = `creationDateTimestamp:${endDate} TO ${startDate}`;

      this.getDataFromAlgolia(`orders${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`, `creation_date_timestamp:${endDate} TO ${startDate}`).then(content => {
        this.processOrders(content.hits, content.nbHits);
      });

      this.getDataFromAlgolia('customers', filter)
        .then(content => {
          this.widgets.map(item => (item.id === 'customers' ? (item.value = content.nbHits) : 0));
        })
        .catch(error => console.log('Error getting customers:', error));

      this.getDataFromAlgolia(`maps${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`, filter)
        .then(content => {
          this.widgets.map(item => (item.id === 'maps' ? (item.value = content.nbHits) : 0));
        })
        .catch(error => console.log('Error getting maps:', error));
    },
    getFilterTimestamps(startDate, endDate) {
      const startTimestamp = this.$moment(new Date(startDate)).startOf('day').unix();
      const endTimestamp = this.$moment(new Date(endDate)).endOf('day').unix();
      if (startTimestamp && endTimestamp && !isNaN(startTimestamp) && !isNaN(endTimestamp)) {
        this.filterByDate(startTimestamp, endTimestamp);
      }
    },
    navigateToSelected(widget) {
      if (widget?.id && ['orders', 'maps', 'customers'].includes(widget.id)) {
        this.$router.push({ path: `/${widget.id}?startDate=${this.startDate}&endDate=${this.endDate}` });
      }
    },
    getFormattedDate(date) {
      return this.$moment(date || {}).format('YYYY-MM-DD');
    },
    formatNumeral(amount) {
      return numeral(parseFloat(amount).toFixed(2)).format('$0,0.00');
    },
    getMostOccurencesOfArray(arrayOfData = [], propName) {
      return arrayOfData.length
        ? arrayOfData[
            arrayOfData.findIndex(
              item =>
                item.occurrence ===
                Math.max.apply(
                  Math,
                  arrayOfData.map(site => site.occurrence)
                )
            )
          ][propName]
        : '';
    },
    addIconColor() {
      this.iconColor = 'primary';
    },
    removeIconColor() {
      this.iconColor = 'grey darken-2';
    }
  }
};
</script>

<style scoped>
.infoCard:hover {
  transition: all 150ms ease-in-out;
  top: -5px;
  box-shadow: 1px 1px 10px 0px rgba(82, 82, 82, 0.5) !important;
}

@media only screen and (min-width: 1280px) and (max-height: 800px) {
  .infoCard {
    min-width: 330px !important;
    max-width: 330px !important;
  }
}

.widgetContent {
  height: 120px !important;
}
</style>
