import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class FouroverStatusService extends FirestoreModel {
  constructor() {
    super(collections.fourover_statuses);
  }
}

export default new FouroverStatusService();
