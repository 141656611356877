import { cloudFunctionsGet, cloudFunctionsPost } from '../../modules/cloud-function-wrapper.js';
import emailTemplateService from '../../services/emailTemplateService.js';

const state = {
  emailTemplates: [],
  activeEmailTemplates: []
};

const getters = {
  getEmailTemplates(state) {
    return state.emailTemplates;
  },
  getActiveEmailTemplates(state) {
    return state.activeEmailTemplates;
  }
};

const mutations = {
  setEmailTemplates(state, payload) {
    state.emailTemplates = payload;
  },
  setActiveEmailTemplates(state, payload) {
    state.activeEmailTemplates = payload;
  }
};

const actions = {
  async fetchEmailTemplates({ commit }) {
    const result = await emailTemplateService.getAll({ orderingProperty: 'label', isIdReturned: true, isArray: true });
    commit('setEmailTemplates', result);
  },
  async fetchActiveEmailTemplates({ commit, getters, dispatch }) {
    await dispatch('fetchEmailTemplates');
    const activeTemplates = getters.getEmailTemplates.filter(template => template.active);
    commit('setActiveEmailTemplates', activeTemplates);
  },
  async isEmailTemplateCodeExist(_context, payload) {
    const templates = await emailTemplateService.getByProperty('code', payload.code.toUpperCase(), { isIdReturned: true, isArray: true });
    return templates.some(template => template._id !== payload.id && template.code.toUpperCase() === payload.code.toUpperCase());
  },
  addNewEmailTemplate({ commit }, payload) {
    return emailTemplateService.add({ data: payload });
  },
  editEmailTemplate({ commit }, payload) {
    return emailTemplateService.update(payload._id, payload);
  },
  deleteEmailTemplate({ commit }, payload) {
    return emailTemplateService.delete(payload.id);
  },
  executeEmailRequest(_context, { endpoint, data, isPreview = false }) {
    return cloudFunctionsPost(isPreview ? `/api/${endpoint}` : `/${endpoint}`, data);
  },
  getEmailData(_context, { endpoint, params }) {
    if (params) return cloudFunctionsGet(`/api/${endpoint}`, { params });
    return cloudFunctionsGet(`/api/${endpoint}`);
  },
  previewEmail({ dispatch }, payload) {
    return dispatch('executeEmailRequest', {
      endpoint: 'emailtemplate/preview',
      isPreview: true,
      data: {
        order_id: payload.order_id,
        template: {
          message: payload.message,
          subject: payload.subject
        }
      }
    });
  },
  sendEmail({ dispatch }, payload) {
    return dispatch('executeEmailRequest', {
      endpoint: 'email/sendMail',
      data: {
        to: payload.to,
        email: {
          message: payload.message,
          subject: payload.subject
        },
        order_id: payload.order_id
      }
    });
  },
  getAvailableTags({ dispatch }) {
    return dispatch('getEmailData', { endpoint: 'emailtemplate/availabletags' });
  },
  getEmailAddressByOrderId({ dispatch }, { order_id }) {
    return cloudFunctionsGet(`/email/order/${order_id}`);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
