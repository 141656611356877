import RestClient from './rest-client';

class SiteSettingsService extends RestClient {
	constructor(context) {
		super('settings', context);
		this.context = context;
	}

	getSiteSettings() {
		const _self = this;

		return this.get({
			success: function(response, resolve) {
				console.log('Get site settings from API: ', response);
				resolve(response.data);
			},
			error: function(error, reject) {
				console.log('Get site settings from API has failed: ', error);
				reject();
			}
		});
	}
}

export { SiteSettingsService as default }
