<template>
  <div>
    <v-form ref="form" v-model="formIsValid" lazy-validation>
      <div class="site-table">
        <v-toolbar flat>
          <v-col class="pl-0">
            <v-toolbar-title class="headline"> Send SMS </v-toolbar-title>
          </v-col>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
          <v-alert v-if="sendingSuccessMessage" type="success" dismissible>
            {{ sendingSuccessMessage }}
          </v-alert>
          <v-alert v-if="sendingErrorMessage" type="error" dismissible>
            {{ sendingErrorMessage }}
          </v-alert>
          <v-card-text>
            <v-col md="4">
              <v-select
                v-model="selectedTemplate"
                :items="templates"
                label="Select SMS template"
                :rules="[value => !!value || 'Required.']"
                persistent-hint
                return-object
                item-value="id"
                item-text="name"
                single-line
              ></v-select>
            </v-col>
            <v-col md="4">
              <v-text-field v-model="orderID" label="Order ID" :rules="[value => !!value || 'Required.']" hint="Enter order ID" required type="number">
                <template v-slot:append-outer>
                  <v-btn :disabled="orderID === ''" color="success" class="mb-4" style="top: -6px" offset-y @click="getCustomerPhoneNumber()">Search phone number </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                id="to"
                v-model="to"
                label="Phone numbers"
                :rules="[value => !!value || 'Required.', value => /\+\d{11}$|(?:\+\d{11},\s*)+\+\d{11}$/.test(value) || 'The phone number(s) must be in a valid format.']"
                hint="Please enter one or more phone numbers in a valid format. ex. +19788384229, +14092305414, ..."
                required
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="content" counter label="Message" :rules="[v => v.length <= this.contentLengthLimit || `Max ${this.contentLengthLimit} characters`]"></v-textarea>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue" :disabled="orderID === '' || content === ''" text @click="preview">Preview</v-btn>
            <v-btn color="green" :disabled="!formIsValid" text @click="sendSMS">Send</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-form>
    <v-dialog v-model="showPreview" persistent max-width="700">
      <v-card>
        <v-card-title>
          <h1 class="headline">SMS Template Preview</h1>
          <v-spacer></v-spacer>
          <v-btn icon large color="red darken-1" class="mt-n1" @click="showPreview = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <br />
        <v-card-subtitle>The content of the SMS which will be received by the customer:</v-card-subtitle>
        <v-card-text>
          <v-textarea
            v-model="previewSMSContent"
            counter
            label="Message"
            :rules="[v => v.length <= this.contentLengthLimit || `Max ${this.contentLengthLimit} characters`]"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showPreview = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      selectedTemplate: null,
      formIsValid: true,
      orderID: '',
      content: '',
      contentLengthLimit: 1000,
      to: '',
      previewSMSContent: '',
      showPreview: false,
      sendingErrorMessage: '',
      sendingSuccessMessage: ''
    };
  },
  computed: {
    ...mapGetters('sms_templates', {
      templates: 'getSMSTemplates'
    })
  },
  watch: {
    templates(items) {
      this.selectTemplate();
    },
    selectedTemplate(template) {
      this.content = template.content;
    }
  },
  mounted() {
    this.orderID = this.$route.query.order_id || '';
    this.validateSend();
    this.getTemplates();
    this.getCustomerPhoneNumber();
  },
  methods: {
    getTemplates() {
      this.$store.dispatch('showLoader', { message: 'Loading templates...' });
      this.$store
        .dispatch('sms_templates/fetchSMSTemplates', { saveInStore: true })
        .then(() => {
          this.$store.dispatch('hideLoader');
        })
        .catch(error => {
          this.$store.dispatch('hideLoader');
          this.$store.dispatch('setSnackbar', { type: 'error', message: error });
        });
    },
    selectTemplate() {
      const templateCode = this.$route?.query?.template ? this.$route.query.template.toUpperCase() : '';
      if (templateCode) {
        this.selectedTemplate = this.templates.find(template => template.code === templateCode);
      }
    },
    getCustomerPhoneNumber() {
      if (this.orderID) {
        this.$store.dispatch('showLoader', { message: 'Loading phone number...' });
        this.$store
          .dispatch('getOrderByOspreyID', { ospreyOrderID: this.orderID })
          .then(order => {
            this.to = `+1${order.customer_phone}`;
          })
          .catch(error => {
            this.$store.dispatch('setSnackbar', { type: 'error', message: error });
            return Promise.reject(error);
          })
          .finally(() => this.$store.dispatch('hideLoader'));
      }
    },
    preview() {
      this.previewSMSContent = '';
      if (this.orderID !== '') {
        this.$store.dispatch('showLoader', { message: 'Loading preview...' });
        this.$store
          .dispatch('sms_templates/previewSMS', {
            order_id: this.orderID,
            message: this.content
          })
          .then(response => {
            if (response?.data?.message) {
              this.previewSMSContent = response.data.message;
            }
            this.showPreview = true;
            return Promise.resolve();
          })
          .catch(error => {
            console.log('[ previewSMS ] ERROR:', error);
            if (error?.response?.data) {
              this.$store.dispatch('setSnackbar', { type: 'error', message: error.response.data });
            }
            return Promise.reject(error);
          })
          .finally(() => this.$store.dispatch('hideLoader'));
      }
    },
    sendSMS() {
      this.sendingErrorMessage = '';
      this.sendingSuccessMessage = '';
      if (this.to && this.orderID && this.selectedTemplate && this.selectedTemplate.id) {
        this.$store.dispatch('showLoader', { message: 'Sending SMS...' });
        this.$store
          .dispatch('sms_templates/sendSMS', {
            to: this.to,
            content: this.content,
            order_id: this.orderID
          })
          .then(response => {
            if (response?.data?.message) {
              this.sendingSuccessMessage = response.data.message;
            }
            return Promise.resolve();
          })
          .catch(error => {
            console.log('[ SendSMS ] ERROR:', error);
            if (error?.response?.data) {
              this.sendingErrorMessage = error.response.data.failedMessage || error.response.data.message;
              if (error.response.data.successMessage) {
                this.sendingSuccessMessage = error.response.data.successMessage;
              }
            }
            return Promise.reject(error);
          })
          .finally(() => this.$store.dispatch('hideLoader'));
      }
    },
    validateSend() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    }
  }
};
</script>
