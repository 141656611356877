<template>
  <v-row>
    <v-col md="12">
      <span>{{ templateName }}</span>
      <v-img v-if="previewUrl" :src="previewUrl" contain class="overflow-hidden mb-4 image-box mt-1"></v-img>
      <v-row v-else class="d-flex justify-space-between px-3 mb-2 mt-1">
        <div class="justify-center d-flex align-center rounded-lg mb-4 image-box border-dashed">
          <span>Image</span>
        </div>
        <span class="red--text text--darken-4 caption text-right">* you must upload an image</span>
      </v-row>
      <span>Upload Image</span>
      <FileUpload ref="frontFileUploader" :disabled="isDisabled" @filesAdded="previewUploadedImage" @clear="clearUploadedImage" />
    </v-col>
  </v-row>
</template>

<script>
import FileUpload from '../common/FileUpload.vue';
export default {
  components: {
    FileUpload
  },
  props: {
    templateName: {
      type: String,
      default: 'Design'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    previewUrl: {
      type: String,
      default: ''
    }
  },
  methods: {
    previewUploadedImage(files) {
      this.$emit('previewUploadedImage', files);
    },
    clearUploadedImage() {
      this.$emit('clearUploadedImage');
    }
  }
};
</script>

<style scoped>
.image-box {
  width: 350px;
  height: 350px;
}
.border-dashed {
  border: 1px dashed;
}
</style>
