import httpClient from '@/modules/httpModule';
import moment from 'moment';

const state = {
  isAudienceUpdated: false,
  isAudienceUpdateLoading: false
};

const getters = {
  getEditAudienceUpdateStatus(state) {
    return state.isAudienceUpdated;
  },
  getAudienceLoadingState(state) {
    return state.isAudienceUpdateLoading;
  }
};

const mutations = {
  setEditAudienceUpdateStatus(state, payload) {
    state.isAudienceUpdated = payload.status === 'success';
  },
  setAudienceLoadingStatus(state, payload) {
    state.isAudienceUpdateLoading = payload;
  }
};

const actions = {
  convertValueToCent({ commit }, payload) {
    return parseFloat((parseFloat(payload) * 100).toFixed(1));
  },
  getAccessToken({ commit, dispatch }, payload) {
    return dispatch('site/listSelectedSite', { siteKey: payload.siteKey }, { root: true })
      .then(site => {
        const site_api_username = site.site_api_username ? site.site_api_username : '';
        const site_api_password = site.site_api_password ? site.site_api_password : '';
        const baseUrl = site.site_url;
        const authUrl = `https:${baseUrl.split('/wp-json/amp')[0]}/wp-json/jwt-auth/v1/token`;
        const authConfig = {
          username: site_api_username,
          password: site_api_password
        };
        if (!authConfig.username && !authConfig.password) {
          return { status: 'error', message: 'No username and password found for selected site!' };
        }
        const toUrlEncoded = obj =>
          Object.keys(obj)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
            .join('&');
        let formData = toUrlEncoded(authConfig);

        return httpClient
          .post({
            url: authUrl,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: formData
          })
          .then(response => {
            const token = response?.data?.token ?? '';
            if (!token) {
              return { status: 'error', message: 'Getting access token failed!' };
            }
            return { baseUrl, token };
          })
          .catch(error => {
            console.log('Requesting access token failed!', error);
            return { status: 'error', message: error };
          });
      })
      .catch(error => {
        console.log('Fetching site data from realtime db failed!', error);
        return { status: 'error', message: error };
      });
  },
  async getProductsById({ commit, dispatch }, payload) {
    let authConfig = await dispatch('getAccessToken', { siteKey: payload.siteKey });
    const requestHeaders = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authConfig.token}`
    };
    const requestBaseUrl = authConfig.baseUrl.substr(-1) === '/' ? authConfig.baseUrl : `${authConfig.baseUrl}/`;
    let requestUrl = `${requestBaseUrl}products/${payload.productId}`;
    return httpClient
      .get({
        url: requestUrl,
        headers: requestHeaders
      })
      .then(response => response.data)
      .catch(error => {
        console.log('Getting product details failed!', error);
        return { status: 'error', message: error };
      });
  },
  calculateNewMoversProductPricePerPiece({ commit, dispatch }, payload) {
    return new Promise(resolve => {
      let moversPostage = 0;
      let calculatedPricePerPiece = 0;
      moversPostage =
        payload.addons[payload.selectedQty.qty].mailing_services.first_class_postage !== undefined &&
        !isNaN(payload.addons[payload.selectedQty.qty].mailing_services.first_class_postage.value)
          ? payload.addons[payload.selectedQty.qty].mailing_services.first_class_postage.value
          : 0;
      calculatedPricePerPiece = dispatch(
        'convertValueToCent',
        parseFloat(payload.selectedQty.pricePerPiece) + payload.addons[payload.selectedQty.qty].mailing_services.targeted_mailing_service_fee.value + moversPostage + payload.tabs
      );
      resolve(calculatedPricePerPiece);
    });
  },
  calculateNewMoversProductMailingPrice({ commit }, payload) {
    return new Promise(resolve => {
      let basicMailingPrice = 0;
      let isTabsAvailable = false;
      let totalMailingPrice = 0;
      let isPostageAvailable = false;
      let moversPostage = 0;
      let mailing_service_rate = 0;
      let tabs_rate = 0;
      let tabs_price = 0;

      const isTargetedMailingFeeAvailable =
        payload.addons[payload.selectedQty.qty] &&
        payload.addons[payload.selectedQty.qty].mailing_services &&
        payload.addons[payload.selectedQty.qty].mailing_services.targeted_mailing_service_fee;
      // Mailing service fee - targeted_mailing_service_fee
      // Postage - first_class_postage
      isPostageAvailable =
        payload.addons[payload.selectedQty.qty] &&
        payload.addons[payload.selectedQty.qty].mailing_services &&
        payload.addons[payload.selectedQty.qty].mailing_services.first_class_postage;
      moversPostage =
        isPostageAvailable && !isNaN(payload.addons[payload.selectedQty.qty].mailing_services.first_class_postage.value)
          ? payload.addons[payload.selectedQty.qty].mailing_services.first_class_postage.value
          : 0;

      basicMailingPrice = isTargetedMailingFeeAvailable
        ? parseFloat(payload.selectedQty.pricePerPiece) + payload.addons[payload.selectedQty.qty].mailing_services.targeted_mailing_service_fee.value
        : parseFloat(payload.selectedQty.pricePerPiece);
      basicMailingPrice = isPostageAvailable ? basicMailingPrice + moversPostage : basicMailingPrice;
      isTabsAvailable = !isNaN(payload.addons[payload.selectedQty.qty].mailing_services.tabs);
      totalMailingPrice = isTabsAvailable
        ? (basicMailingPrice + payload.addons[payload.selectedQty.qty].mailing_services.tabs.value) * payload.currentQty
        : basicMailingPrice * payload.currentQty;
      // The warehouse drop should not have postage costs
      if (payload.warehouseQty > 0 && isPostageAvailable) {
        totalMailingPrice = totalMailingPrice - moversPostage * payload.warehouseQty;
      }
      if (isTargetedMailingFeeAvailable) {
        mailing_service_rate = payload.addons[payload.selectedQty.qty].mailing_services.targeted_mailing_service_fee.value;
      }
      if (isTabsAvailable) {
        tabs_rate = payload.addons[payload.selectedQty.qty].mailing_services.tabs.value;
        tabs_price = payload.addons[payload.selectedQty.qty].mailing_services.tabs.value * payload.currentQty;
      }
      resolve({
        isTabsAvailable,
        mailing_service_rate,
        moversPostage,
        tabs_price,
        tabs_rate,
        totalMailingPrice
      });
    });
  },
  async recalculateAudiencePrices({ commit, dispatch }, payload) {
    let calculations = {};
    const orderDeliveryData = await dispatch('om_orders/getOrderDataDoc', { orderId: payload.orderData.firebase_order_id, docName: 'delivery' }, { root: true });
    calculations.calculatedPrintQty = payload.mapData.totals.avg * orderDeliveryData.delivery_times;
    calculations.currentAddonQty = 0;

    const orderProductData = await dispatch('om_orders/getOrderDataDoc', { orderId: payload.orderData.firebase_order_id, docName: 'product' }, { root: true });
    let productsByCategory = await dispatch('getProductsById', {
      siteKey: payload.orderData.origin?.site_db_id || payload.orderData.site_db_id,
      productId: orderProductData.product_id
    });
    const addons = productsByCategory.product_addons;
    const addonsQtys = Object.keys(addons);

    if (addonsQtys) {
      for (let index = 0; index < addonsQtys.length; index++) {
        calculations.addonQty = addonsQtys[index];
        calculations.addonQtyNr = parseFloat(calculations.addonQty);
        if (index === 0 && calculations.calculatedPrintQty <= calculations.addonQtyNr) calculations.currentAddonQty = calculations.addonQty;
        if (calculations.addonQtyNr <= calculations.calculatedPrintQty && calculations.calculatedPrintQty <= addonsQtys[index + 1])
          calculations.currentAddonQty = calculations.addonQty;
        if (index === addonsQtys.length - 1) calculations.currentAddonQty = calculations.addonQty;
        if (calculations.currentAddonQty) break;
      }
    }

    calculations.currentAddon = addons[calculations.currentAddonQty];
    calculations.tabs = calculations.currentAddon.mailing_services && calculations.currentAddon.mailing_services.tabs ? calculations.currentAddon.mailing_services.tabs.value : 0;

    const markupPrice = await dispatch('maps/getMarkupPrice', { site_db_id: payload.orderData.site_db_id }, { root: true });

    calculations.markupPrice = markupPrice ? parseFloat(markupPrice) : 0;
    calculations.calculatedPricePerPiece = payload.orderData.is_custom_product
      ? orderProductData.price_per_piece
      : parseFloat(calculations.currentAddon.price_per_piece) + parseFloat(calculations.currentAddon.price_per_piece) * parseFloat(calculations.markupPrice);

    calculations.calculatedProductPrices = await dispatch('calculateNewMoversProductPricePerPiece', {
      addons,
      tabs: calculations.tabs,
      selectedQty: {
        pricePerPiece: calculations.calculatedPricePerPiece,
        calculatedPricePerPiece: calculations.currentAddon.price_per_piece,
        qty: parseFloat(calculations.currentAddonQty)
      }
    });
    calculations.calculatedMailingPrice = await dispatch('calculateNewMoversProductMailingPrice', {
      addons,
      currentQty: calculations.calculatedPrintQty,
      selectedQty: {
        pricePerPiece: calculations.calculatedPricePerPiece,
        calculatedPricePerPiece: calculations.calculatedProductPrices.calculatedPricePerPiece,
        qty: parseFloat(calculations.currentAddonQty)
      }
    });

    calculations.calculatedStockPrice = 0;
    calculations.calculatedCoatingPrice = 0;

    const orderDataPricing = await dispatch(
      'om_orders/getOrderDataDoc',
      {
        orderId: payload.orderData.firebase_order_id,
        docName: 'pricing'
      },
      { root: true }
    );

    if (orderDataPricing.stock) {
      calculations.calculatedStockPrice = orderProductData.stock.rate * calculations.calculatedPrintQty;
    }
    if (orderDataPricing.coating) {
      calculations.calculatedCoatingPrice = orderProductData.coating.rate * calculations.calculatedPrintQty;
    }
    calculations.artworkPrice = orderDataPricing.artwork;
    calculations.discountPrice = orderDataPricing.discount_total;
    calculations.extraQtyPrice = orderDataPricing.extra_qty_price;
    calculations.postagePrice = calculations.calculatedMailingPrice.moversPostage ? calculations.calculatedMailingPrice.moversPostage : 0;

    const isUserTaxable = await dispatch(
      'om_orders/getOrderDataDoc',
      {
        orderId: payload.orderData.firebase_order_id,
        docName: 'customer'
      },
      { root: true }
    ).then(customer => Promise.resolve(customer.taxable));
    calculations.taxPrice = isUserTaxable ? (calculations.calculatedMailingPrice.totalMailingPrice / 100) * 7 : 0;
    calculations.calculatedOrderTotal = parseFloat(
      (
        calculations.calculatedMailingPrice.totalMailingPrice +
        calculations.taxPrice +
        calculations.calculatedCoatingPrice +
        calculations.calculatedStockPrice +
        calculations.extraQtyPrice +
        calculations.postagePrice +
        calculations.artworkPrice -
        calculations.discountPrice
      ).toFixed(2)
    );
    calculations.calculatedDefaultMonthlyFee = payload.mapData.totals.max * calculations.calculatedPricePerPiece;

    console.log('recalculateAudiencePrices response: ', calculations);
    return calculations;
  },
 async recalculateAudience({ commit, dispatch }, payload) {
    commit('setEditAudienceUpdateStatus', { status: '' });
    commit('setAudienceLoadingStatus', true);
    const map = payload.mapData.mapState;
    const orderObject = payload.orderData;
    const updateConfig = {
      moverCounts: map.totals,
      totalCount: parseInt(map.totals.avg),
      stateHash: payload.mapData.stateHash ? payload.mapData.stateHash : ''
    };
    const carrier_routes = map.routes
      ? map.routes.map(route => (route.zipcrid ? route.zipcrid : route.ZIPCRID)).filter(zip => zip)
      : map.atzRoutes
      ? map.atzRoutes.map(route => (route.zipcrid ? route.zipcrid : route.ZIPCRID)).filter(zip => zip)
      : map.ids
      ? map.ids
      : [];
    updateConfig.carrier_routes = carrier_routes;
    updateConfig.csvLink = `https://emapdata.adc4gis.com/movers.csv?ids=${carrier_routes.join(',')}`;

    const userId = orderObject.customer_unique_id.split('_').slice(-2, -1)[0];
    const destinationUrl = `${orderObject.site_id}/${orderObject.crm_customer_id}-${userId}/map/${moment(new Date()).format('MM-DD-YYYY')}/${payload.mapId}`;
    const fileName = payload.mapImgName ? payload.mapImgName : `map_${Date.now()}.png`;

    const originalMonthlyBudget = await dispatch(
      'om_orders/initializeMonthlyBudget',
      {
        orderId: orderObject.firebase_order_id,
        mapId: payload.mapId
      },
      { root: true }
    );

    const originalMonthlyMaxPrice = Number(originalMonthlyBudget.pricing.monthly_fee * originalMonthlyBudget.delivery.delivery_times);
      
    return dispatch('recalculateAudiencePrices', { orderData: orderObject, mapData: map }).then(calculations => {
      const orderDataPricingChanges = {
        coating: calculations.calculatedCoatingPrice,
        monthly_fee: calculations.calculatedDefaultMonthlyFee,
        order_subtotal: calculations.calculatedOrderTotal,
        order_total: calculations.calculatedOrderTotal,
        postage: calculations.postagePrice,
        product_price: calculations.calculatedMailingPrice.totalMailingPrice,
        stock: calculations.calculatedStockPrice,
        tax: calculations.taxPrice,
        total_mailing_price: calculations.calculatedMailingPrice.totalMailingPrice,
        total_print_price: calculations.calculatedMailingPrice.totalMailingPrice,
        total_per_piece: calculations.calculatedOrderTotal / (calculations.calculatedPrintQty + orderObject.extra_qty)
      };
      const orderRootChanges = {
        mail_qty: calculations.calculatedPrintQty,
        monthly_qty: map.totals.max,
        order_balance: calculations.calculatedOrderTotal * -1,
        order_balance_test: calculations.calculatedOrderTotal * -1,
        order_total: calculations.calculatedOrderTotal,
        print_qty: calculations.calculatedPrintQty + orderObject.extra_qty
      };
      const orderDataProductChanges = {
        price: calculations.calculatedPrintQty * calculations.calculatedPricePerPiece,
        qty: calculations.calculatedPrintQty
      };
      const orderDataDeliveryChanges = {
        average: parseInt(map.totals.avg),
        average_price: orderDataProductChanges.price
      };

      let updatePromises = [];
      updatePromises.push(
        dispatch(
          'maps/uploadMapToStorage',
          {
            image: map.image,
            destinationUrl,
            fileName,
            mapData: {
              map_id: payload.mapId,
              map_data: {
                map_state: map,
                map_state_hash: payload.mapData.stateHash ? payload.mapData.stateHash : '',
                map_total: parseInt(map.totals.avg)
              },
              user_id: userId,
              crm_customer_id: orderObject.crm_customer_id
            }
          },
          { root: true }
        ).then(result => {
          console.log('[ Update map with new map data ]: ', result, updateConfig);
          dispatch('maps/updateMapData', { mapId: payload.mapId, data: { ...updateConfig, ...result } }, { root: true });
        })
      );
      updatePromises.push(
        dispatch(
          'om_orders/updateOrderRootAndOrderData',
          {
            orderId: orderObject.firebase_order_id,
            orderDataPricingChanges,
            orderRootChanges,
            orderDataProductChanges,
            orderDataDeliveryChanges
          },
          { root: true }
        )
      );
      
      return Promise.all(updatePromises)
        .then(() => {
          commit('setEditAudienceUpdateStatus', { status: 'success' });
          return dispatch(
            'stripe/notifyOspreyOfOrderDataChange',
            {
              firebase_order_id: orderObject.firebase_order_id,
              firebase_site_id: orderObject.site_db_id,
              osprey_order_id: orderObject.crm_order_id,
              osprey_customer_id: orderObject.crm_customer_id,
              map_id: payload.mapId,
              summary: {
                monthly_max_price: orderDataPricingChanges.monthly_fee,
                monthly_max_qty: orderRootChanges.monthly_qty,
                monthly_avg_price: orderDataDeliveryChanges.average_price,
                monthly_avg_qty: map.totals.avg,
                original_monthly_max_price: originalMonthlyMaxPrice,
                prev_monthly_avg_qty: orderObject?.print_qty
              }
            },
            { root: true }
          )
            .then(ospreyResponse => {
              if (ospreyResponse.data.Success) {
                commit('setAudienceLoadingStatus', false);
                return Promise.resolve({ status: 'success' });
              }
              return Promise.reject({ message: 'Unknown status' });
            })
            .catch(error => {
              console.log('Notify Osprey of order change failed:', error);
              return Promise.reject(error);
            });
        })
        .catch(error => {
          console.log('Update map and order Promise.all error:', error);
          return Promise.reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
