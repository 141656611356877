<template>
  <v-checkbox
    v-model="inputValue"
    :rules="rules"
    :label="label"
    :value="fieldValue"
    :readonly="readonly"
    :required="required"
    :false-value="false"
    :true-value="true"
    class="mt-n1 mb-1"
    @change="sendToParent"
  ></v-checkbox>
</template>

<script>
export default {
  props: ['label', 'fieldValue', 'rules', 'type', 'placeholder', 'required', 'readonly'],
  data() {
    return {
      inputValue: this.fieldValue
    };
  },
  methods: {
    sendToParent() {
      this.$emit('input', this.inputValue);
    }
  }
};
</script>
