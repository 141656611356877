<template>
  <div class="increase-height">
    <v-row v-if="userAccessLevel" dense flat class="mt-n10 ml-3">
      <p class="title primary--text">Welcome {{ userName }}</p>
    </v-row>
    <v-row v-if="userAccessLevel" dense flat class="mt-n4 ml-3 mb-n3">
      <p class="subtitle-1">Dashboard</p>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="3" lg="2">
        <v-select v-model="selectedPeriod" :items="periodItems" label="Filter" class="mt-n7 mr-2" outlined dense> </v-select>
      </v-col>
    </v-row>

    <v-divider class="mb-3"></v-divider>

    <v-container v-if="userAccessLevel">
      <v-row v-if="isAccessRestrictedMessageVisible" align="center" justify="center">
        <h4 class="display-3 red--text">No access level selected, app restricted!</h4>
      </v-row>
      <template v-else>
        <stamp-general-info-cards :selected-period="selectedPeriod" />
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GeneralOrderInfoCards from './widgets/GeneralOrderInfoCards.vue';

export default {
  components: {
    'stamp-general-info-cards': GeneralOrderInfoCards
  },
  data() {
    return {
      periodItems: ['Today', 'Yesterday', 'This Week', 'Last 7 Days', 'Last Week'],
      selectedPeriod: 'Today'
    };
  },
  computed: {
    ...mapGetters('users', {
      userAccessLevel: 'userAccessLevel',
      userName: 'userName'
    }),
    isAccessRestrictedMessageVisible() {
      return !this.userAccessLevel || Number(this.userAccessLevel?.value) < 0;
    }
  },
  created() {
    this.$store.dispatch('users/fetchUser');
  }
};
</script>

<style scoped>
.increase-height {
  padding-top: 50px;
  height: 600px !important;
}
</style>
