<template>
  <div>
    <confirm-delete-dialog
      :isDialogOpened="isDeleteConfirmationDialogOpened"
      :itemNames="selectedItemKeysToDelete"
      @closeDialog="closeDeleteConfirmationDialog"
      @confirmDelete="deleteConfirmed"
    ></confirm-delete-dialog>

    <v-dialog v-model="isEditDialogOpened" max-width="850px">
      <v-card>
        <v-card-title class="headline">{{ formTitle }}</v-card-title>
        <v-card-text>
          <v-row v-if="isUserInGroupsSelected">
            <v-col cols="10" sm="12">
              <v-card>
                <v-card-title>
                  Users
                  <v-spacer></v-spacer>
                  <v-text-field v-model="searchInUsers" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                </v-card-title>
                <stamp-data-table
                  :headers="usersHeaders"
                  :data="usersInSelectedGroup"
                  item-key="_id"
                  :search="searchInUsers"
                  :actions="[{ icon: 'mdi-delete', iconColor: 'red', title: 'Delete User From Group', eventName: 'removeUserFromGroup' }]"
                  @removeUserFromGroup="removeUserFromGroup"
                >
                </stamp-data-table>
              </v-card>
            </v-col>
          </v-row>
          <div v-else class="px-3">
            <v-row>
              <v-text-field v-model="groupName" label="Group name"></v-text-field>
            </v-row>
            <v-row>
              <v-autocomplete v-model="selectedLevels" :items="permissionLevels" label="Levels" multiple chips item-value="_id" item-text="name"></v-autocomplete>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
          <v-btn v-if="!isUserInGroupsSelected" color="blue darken-1" text :disabled="isSaveBtnDisabled" @click="saveGroup()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <div class="site-table">
        <v-toolbar flat class="site-table--header">
          <v-row class="justify-space-between mt-2 mb-3">
            <v-col cols="12" sm="3" md="6">
              <v-toolbar-title class="headline">Groups</v-toolbar-title>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="5" md="4" xl="3">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="2" xl="2">
              <v-row class="justify-end">
                <v-btn dark class="ml-0 mt-2 blue darken-1 white--text mr-3" @click="openEditDialog"><v-icon class="mr-1">mdi-plus</v-icon>New Group</v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-toolbar>

        <stamp-data-table
          :headers="headers"
          :data="groups"
          item-key="group_firebase_id"
          :search="search"
          :is-items-per-page-disabled="true"
          :actions="actions"
          sort-by="name"
          :sort-desc="false"
          @editItem="editItem"
          @viewUsersInGroup="viewUsersInGroup"
          @deleteItem="deleteItem"
        >
          <template #[`item.creation_date`]="{ item }">{{ formatDate(item.creation_date.seconds) }}</template>
          <template #[`item.access_levels`]="{ item }">
            <span v-for="(accessLevel, i) in item.access_levels" :key="i">
              {{ accessLevel.name }} ({{ accessLevel.value }})
              <span v-if="i < item.access_levels.length - 1">,</span>
            </span>
          </template>
        </stamp-data-table>
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from '../common/DataTable.vue';
import ConfirmDeleteDialog from '../dialog/ConfirmDeleteDialog.vue';

export default {
  name: 'groups',
  components: {
    'stamp-data-table': DataTable,
    'confirm-delete-dialog': ConfirmDeleteDialog
  },
  data() {
    return {
      groups: [],
      headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Access Level', value: 'access_levels', align: 'left' },
        { text: 'Group Firebase ID', value: '_id', align: 'left' },
        { text: 'Created', value: 'creation_date', align: 'left' }
      ],
      actions: [
        { icon: 'mdi-pencil', iconColor: 'teal', title: 'Edit Group', eventName: 'editItem' },
        { icon: 'mdi-account-multiple', iconColor: 'blue', title: 'View Users In Group', eventName: 'viewUsersInGroup' },
        { icon: 'mdi-delete', iconColor: 'red', title: 'Delete Group', eventName: 'deleteItem' }
      ],
      search: '',
      isEditDialogOpened: false,
      groupName: '',
      selectedLevels: [],
      editKey: '',
      isSaveBtnDisabled: true,
      isDeleteConfirmationDialogOpened: false,
      selectedItemKeysToDelete: [],
      isUserInGroupsSelected: false,
      usersInSelectedGroup: [],
      usersHeaders: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Email', align: 'left', value: 'email' },
        { text: 'FirebaseID', align: 'left', value: '_id' }
      ],
      searchInUsers: '',
      selectedGroupData: null
    };
  },
  computed: {
    formTitle() {
      return this.editKey ? 'Edit Group' : this.isUserInGroupsSelected ? 'Users in Group' : 'New Group';
    },
    ...mapGetters('permissions', {
      permissionLevels: 'levelsPermissions',
      levels: 'levels',
      levelParameters: 'levelsParam'
    }),
    ...mapGetters('users', {
      userStore: 'user',
      userAccessLevel: 'userAccessLevel'
    })
  },
  watch: {
    selectedLevels(value) {
      this.alert = this.isSaveBtnDisabled = !value.length;
    }
  },
  created() {
    this.getAllGroups();
  },
  methods: {
    async getAllGroups() {
      this.groups = await this.$store.dispatch('permissions/fetchAllGroups');
    },
    openEditDialog() {
      this.$store.dispatch('permissions/fetchLevelsPermissionsInApp');
      this.isEditDialogOpened = true;
    },
    saveGroup() {
      const isEditModeSelected = !!this.editKey;
      const accessLevels = [];
      this.selectedLevels.forEach(selectedLevel => {
        const { _id: id, name, value } = this.permissionLevels.find(level => level._id === selectedLevel);
        accessLevels.push({ id, name, value });
      });

      const groupConfig = {
        name: this.groupName,
        access_levels: accessLevels
      };

      if (isEditModeSelected) {
        groupConfig.group_id = this.editKey;
      }

      this.$store
        .dispatch(`permissions/${isEditModeSelected ? 'update' : 'save'}Group`, groupConfig)
        .then(async groupId => {
          this.$store.dispatch('showAlert', { type: 'success', message: `Group with ID:${groupId} ${isEditModeSelected ? 'edited' : 'added'} successfully!` });
          if (isEditModeSelected) {
            await this.$store.dispatch('permissions/updateAccessLevelsInUsers', groupConfig);
          }
          this.getAllGroups();
        })
        .finally(() => {
          this.isEditDialogOpened = false;
          this.selectedLevels = [];
        });
    },
    async editItem(item) {
      this.editKey = item._id;
      this.groupName = item.name;
      this.openEditDialog();
      this.selectedLevels = item.access_levels.map(item => item.id);
    },
    deleteItem(item) {
      this.selectedGroupData = item;
      this.selectedItemKeysToDelete = [item.name];
      this.isDeleteConfirmationDialogOpened = true;
    },
    closeDeleteConfirmationDialog() {
      this.isDeleteConfirmationDialogOpened = false;
      this.selectedGroupData = null;
    },
    deleteConfirmed() {
      this.$store
        .dispatch('permissions/deleteGroup', { group_id: this.selectedGroupData._id })
        .then(async () => {
          await this.$store.dispatch('permissions/deleteGroupIdFromUsers', { group_data: this.selectedGroupData });
          this.$store.dispatch('showAlert', { type: 'success', message: `Group deleted successfully!` });
        })
        .finally(() => {
          this.closeDeleteConfirmationDialog();
          this.getAllGroups();
        });
    },
    close() {
      this.isEditDialogOpened = false;
      this.groupName = '';
      this.selectedLevels = [];
      this.isUserInGroupsSelected = false;
      this.alert = true;
      this.selectedGroupData = null;
    },
    async viewUsersInGroup(group) {
      this.selectedGroupData = group;
      this.usersInSelectedGroup = await this.$store.dispatch('permissions/getUsersInGroup', { id: group._id });
      this.isUserInGroupsSelected = true;
      this.isEditDialogOpened = true;
    },
    removeUserFromGroup(userData) {
      this.$store.dispatch('permissions/deleteGroupFromUser', { group_data: this.selectedGroupData, user_data: userData });
    },
    formatDate(dateTotransform) {
      const date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    }
  }
};
</script>
