<template>
  <v-card elevation="4" outlined class="mt-4">
    <v-row class="mt-2 px-4"
      ><v-card-title tag="span" class="primary--text">Product Information</v-card-title>
      <v-chip v-if="!isSiteDefault" :color="productInfoColor" class="mt-auto mb-auto text-white"> {{ productInfoType }} </v-chip>
    </v-row>

    <v-form ref="form" v-model="isFormValid">
      <v-row class="px-4">
        <v-col cols="12" md="6">
          <v-text-field v-model="product.name" label="Product Name" :rules="required" :disabled="!isEditable" outlined dense></v-text-field>
          <v-row class="mb-n8">
            <v-col cols="6">
              <v-text-field v-model="product.internal_product_id" label="Product ID (SQL)" disabled outlined dense></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="product.netsuite_id" label="Netsuite ID" disabled outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-n8">
            <v-col cols="6">
              <v-text-field v-model="product.size_x" label="Size X" type="number" min="0" :rules="required" :disabled="!isEditable" outlined dense></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="product.size_y" label="Size Y" type="number" min="0" :rules="required" :disabled="!isEditable" outlined dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="product.category"
                label="Category"
                :items="productCategories.map(({ name, product_category_firebase_id }) => ({ id: product_category_firebase_id, name }))"
                item-text="name"
                item-value="id"
                return-object
                :rules="selectRequired"
                :disabled="!isEditable"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="product.type"
                label="Type"
                :items="productTypes.map(({ name, product_type_firebase_id }) => ({ id: product_type_firebase_id, name }))"
                item-text="name"
                item-value="id"
                return-object
                :rules="selectRequired"
                :disabled="!isEditable"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-text-field
            v-model="product.material_weight"
            label="Weight of Material (LBS)"
            type="number"
            step="0.0001"
            min="0"
            :rules="required"
            :disabled="!isEditable"
            outlined
            dense
          ></v-text-field>
          <v-text-field v-model="product.tax_code" label="Tax Code" :rules="required" :disabled="isTaxCodeDisabled" outlined dense></v-text-field>
          <v-text-field v-model="product.salesforce_product_id" label="Salesforce Product ID" :disabled="!isEditable" outlined dense></v-text-field>
          <v-text-field v-model="product.salesforce_pricebook_entry_id" label="Salesforce Price Book Entry ID" :disabled="!isEditable" outlined dense></v-text-field>
          <v-text-field v-model="product.fourover_product_id" label="4over Product ID" :disabled="!isEditable" outlined dense></v-text-field>
          <v-text-field v-model="product.external_sku" label="External SKU" :disabled="!isEditable" outlined dense></v-text-field>
          <v-text-field v-model="product.stripe_id" label="Stripe ID" :disabled="!isEditable" outlined dense></v-text-field>
          <div v-for="(integration, index) in product.integrations" :key="index">
            <v-text-field v-model="integration.value" :label="`${integration.name} [ ${integration.location} ]`" :disabled="!isEditable" outlined dense></v-text-field>
          </div>

          <v-select
            v-model="product.sites"
            label="Sites"
            :items="allSites"
            item-text="name"
            item-value="id"
            return-object
            :disabled="!isEditable"
            multiple
            outlined
            dense
          ></v-select>
        </v-col>

        <v-col cols="12" md="6">
          <v-img v-if="previewUrl" :src="previewUrl" aspect-ratio="1" contain class="overflow-hidden mb-4 image-box"></v-img>
          <v-row v-else class="d-flex justify-space-between px-3 mb-2">
            <div class="justify-center d-flex align-center rounded-lg mb-4 image-box border-dashed">
              <span>Image</span>
            </div>
            <span class="red--text text--darken-4 caption text-right">* you must upload an image or provide a URL to it</span>
          </v-row>

          <v-text-field
            v-model="product.image"
            label="Provide Product Image URL"
            :disabled="isImageFromDevice || !isEditable"
            clearable
            outlined
            dense
            @blur="previewImageFromUrl"
            @click:clear="clearPreviewFromUrl"
          ></v-text-field>

          <v-row align="center" justify="center">
            <v-col cols="5">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="auto">
              <span class="caption">Or</span>
            </v-col>
            <v-col cols="5">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <span class="caption">Upload Image</span>
          <FileUpload ref="fileUploader" :disabled="isFileUploadDisabled || !isEditable" @filesAdded="previewUploadedImage" @clear="clearUploadedImage" />

          <v-row>
            <v-col cols="6">
              <v-checkbox
                v-for="(box, i) in checkBoxes.slice(0, 7)"
                :key="i"
                v-model="product[box.key]"
                :label="box.label"
                :disabled="!isEditable"
                class="mb-n6"
                @change="validateCheckboxes"
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-for="(box, i) in checkBoxes.slice(7)"
                :key="i"
                v-model="product[box.key]"
                :label="box.label"
                :disabled="!isEditable"
                class="mb-n6"
                @change="validateCheckboxes"
              ></v-checkbox>
              <div v-if="!isAtLeastOneChecked" class="mt-4"><span class="red--text text--darken-4 caption">* at least one order process must be checked</span></div>
            </v-col>
          </v-row>
        </v-col>
        <v-switch v-if="!isSiteDefault" v-model="product.is_active" color="primary" :label="`Product status: ${product.is_active ? 'Active' : 'Inactive'}`" class="mt-0 ml-3">
        </v-switch>
      </v-row>
      <v-card-actions v-if="isEditable">
        <v-col class="text-right">
          <v-btn color="primary" :disabled="!isEveryFieldValid" @click="saveProduct">Save Changes</v-btn>
        </v-col>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import FileUpload from '../common/FileUpload.vue';
const deepEqual = require('deep-equal');

export default {
  components: {
    FileUpload
  },
  data() {
    return {
      product: {},
      checkBoxes: [],
      isAtLeastOneChecked: false,
      isFormValid: false,
      required: [v => !!v || 'This is a required field.'],
      selectRequired: [v => !!Object.keys(v || {}).length || 'This is a required field.'],
      previewUrl: '',
      uploadedImage: null,
      storageLink: '',
      initialProduct: {}
    };
  },

  computed: {
    ...mapGetters({
      productCategories: 'product_categories/getProductCategories',
      productTypes: 'product_types/getProductTypes',
      productIntegrations: 'products/getIntegrations'
    }),
    ...mapGetters('site', {
      sites: 'getSites'
    }),
    isImageFromDevice() {
      //must check like this , can't cast File to Boolean
      return this.uploadedImage !== null;
    },
    isFileUploadDisabled() {
      return !!this.product.image;
    },
    isEveryFieldValid() {
      const isEveryFieldValid = this.isFormValid && this.isAtLeastOneChecked && !!this.previewUrl;
      this.$emit('handleIsProductInformationSaveDisabled', !isEveryFieldValid);
      return isEveryFieldValid;
    },
    selectedSite() {
      return this.$route.params.siteId;
    },
    isSiteDefault() {
      return this.selectedSite === 'default';
    },
    productFirebaseId() {
      return this.$route.params.productId;
    },
    isEditable() {
      return this.$route.name !== 'ViewProduct';
    },
    isTaxCodeDisabled() {
      return !this.isEditable || !this.isSiteDefault;
    },
    isProductDefault() {
      return this.product?.is_default;
    },
    productInfoColor() {
      return this.isProductDefault ? 'blue darken-2' : 'grey darken-1';
    },
    productInfoType() {
      return this.isProductDefault ? 'Default' : 'Custom';
    },
    allSites() {
      return this.sites.map(({ firebase_id, site_name }) => ({ id: firebase_id, name: site_name }));
    }
  },
  watch: {
    product: {
      handler() {
        if (this.$route.name === 'EditProduct') {
          this.$emit('handleProductInformationChange', deepEqual(this.initialProduct, this.product));
        }
      },
      deep: true
    }
  },
  async mounted() {
    await this.initData();
    this.$nextTick(() => {
      this.$refs.form.validate();
    });
  },
  methods: {
    async initData() {
      if (!this.isSiteDefault) {
        await this.$store.dispatch('products/initIntegrations');
        console.log('List of integrations: ', this.productIntegrations);
      }
      this.product = {
        name: '',
        internal_product_id: '',
        netsuite_id: '',
        size_y: '',
        size_x: '',
        category: {},
        type: {},
        material_weight: '0.0062',
        tax_code: '',
        salesforce_product_id: '',
        salesforce_pricebook_entry_id: '',
        fourover_product_id: '',
        stripe_product_id: '',
        image: '',
        external_sku: '',
        integrations: []
      };
      try {
        await this.$store.dispatch('loadSitesFromDb');
        await Promise.all([this.addCheckboxes(), this.$store.dispatch('product_types/getAllProductTypes'), this.$store.dispatch('product_categories/getAllProductCategories')]);
        this.setInitialProduct();
        this.$emit('handleProductInformationChange', deepEqual(this.initialProduct, this.product));
        if (this.productFirebaseId) {
          await this.getProductByID();
          this.validateCheckboxes();
        }

        this.product.integrations = this.mapIntegrationsToProduct(this.product.integrations);
      } catch (error) {
        console.error('Error initializing data:', error);
      }
    },

    mapIntegrationsToProduct(existingIntegrations = []) {
      if (!this.productIntegrations?.length) {
        return existingIntegrations;
      }

      console.log('Existing integrations:', existingIntegrations);
      console.log('New integrations from endpoint:', this.productIntegrations);

      const updatedIntegrations = this.productIntegrations.map(newIntegration => {
        const existingIntegration = existingIntegrations.find(existing => existing.osprey_id === newIntegration.integration_id);

        if (existingIntegration) {
          return {
            ...existingIntegration,
            name: newIntegration.integration_name,
            location: newIntegration.Location.location_name,
            value: existingIntegration.value || ''
          };
        } else {
          return {
            name: newIntegration.integration_name,
            value: '',
            osprey_id: newIntegration.integration_id,
            location: newIntegration.Location.location_name
          };
        }
      });

      console.log('Updated integrations:', updatedIntegrations);
      return updatedIntegrations;
    },
    async addCheckboxes() {
      this.$store.dispatch('showLoader');
      try {
        this.checkBoxes = await this.$store.dispatch('products/getOrderProcesses');
        this.checkBoxes.forEach(process => (this.product[process.key] = false));
      } catch (error) {
        console.error('addCheckboxes error', error);
        this.showAlert('Could not initialize the product form.');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    validateCheckboxes() {
      this.isAtLeastOneChecked = Object.entries(this.product).filter(([key, value]) => this.checkBoxes.map(({ key }) => key).includes(key) && value).length;
    },
    async getProductByID() {
      if (!this.productFirebaseId) {
        return;
      }
      this.$store.dispatch('showLoader');
      try {
        if (this.selectedSite === 'default') {
          this.product = await this.$store.dispatch('products/getDefaultProductByID', this.productFirebaseId);
        } else {
          this.product = await this.$store.dispatch('products/getCustomProductByID', { productID: this.productFirebaseId, siteID: this.selectedSite });
        }

        this.setInitialProduct();
        this.previewUrl = this.product.image || '';
      } catch (error) {
        console.error('[getProductByID error ] ', error);
        this.showAlert('error', error.message || error || 'Could not load Product data.');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    previewUploadedImage(files) {
      const [image] = files;
      this.uploadedImage = image;
      this.previewUrl = URL.createObjectURL(image);
    },
    previewImageFromUrl() {
      this.previewUrl = this.product.image;
    },
    clearUploadedImage() {
      this.previewUrl = this.product.image = this.storageLink || '';
      this.uploadedImage = null;
    },
    clearPreviewFromUrl() {
      this.previewUrl = this.product.image = '';
    },
    setInitialProduct() {
      this.initialProduct = Object.assign({}, this.product);
    },
    async saveProduct() {
      const initialProductCopy = JSON.parse(JSON.stringify(this.initialProduct));
      initialProductCopy.is_active = true;

      const productCopy = JSON.parse(JSON.stringify(this.product));
      productCopy.is_active = true;

      const isOnlyProductStatusModified = deepEqual(initialProductCopy, productCopy);

      this.$store.dispatch('showLoader');
      const functionName = this.productFirebaseId ? 'editProduct' : 'addProduct';
      const productObject = {
        id: this.productFirebaseId || '',
        data: this.product,
        siteID: this.selectedSite
      };
      try {
        await this.$store.dispatch('products/validateProductData', productObject);
        productObject.data.image = this.storageLink = this.isImageFromDevice
          ? await this.$store.dispatch('products/uploadProductImage', { name: this.product.name, file: this.uploadedImage })
          : this.product.image;

        productObject.siteID = this.selectedSite;
        productObject.isOnlyProductStatusModified = isOnlyProductStatusModified;
        const result = await this.$store.dispatch(`products/${functionName}`, productObject);
        if (result) {
          const productFirebaseID = this.productFirebaseId || result;
          await this.updateTotalWeight(productFirebaseID);

          if (this.product?.integrations?.length) {
            try {
              await this.$store.dispatch('products/saveProductIntegrations', {
                productId: productFirebaseID,
                integrations: this.product.integrations,
                siteId: this.selectedSite,
                externalSku: this.product.external_sku
              });
            } catch (integrationError) {
              console.error('Failed to save product integrations:', integrationError);
              this.setSnackbar('warning', 'Product saved, but integrations failed to update.');
            }
          }

          this.setSnackbar('success', `Product saved with id: ${result}`);
          this.continueEditing(result);
        }

        this.setInitialProduct();
        this.$emit('handleProductInformationChange', deepEqual(this.initialProduct, this.product));
      } catch (error) {
        console.error('[saveProduct error ] ', error);
        this.showAlert('error', error.message || error || 'Could not save Product.');
      } finally {
        this.$refs.fileUploader.clearAll();
        this.$store.dispatch('hideLoader');
      }
    },
    async updateTotalWeight(productFirebaseID) {
      if (
        parseFloat(this.initialProduct.size_x) !== parseFloat(this.product.size_x) ||
        parseFloat(this.initialProduct.size_y) !== parseFloat(this.product.size_y) ||
        parseFloat(this.initialProduct.material_weight) !== parseFloat(this.product.material_weight)
      ) {
        await this.$store.dispatch('product_pricing/updateTotalWeight', { productFirebaseId: productFirebaseID, product: this.product, siteID: this.selectedSite });
        this.getProductByID();
      }
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    },
    continueEditing(product_firebase_id) {
      if (this.$route.name === 'AddProduct') {
        this.$router.replace(`/products/sites/${this.selectedSite}/edit/${product_firebase_id}`);
      }
    }
  }
};
</script>
<style scoped>
.image-box {
  width: 118px;
  height: 118px;
}
.border-dashed {
  border: 1px dashed;
}

.text-white {
  color: white !important;
}
</style>
