<template>
  <v-container fluid>
    <v-card v-if="isPanelOpened" class="elevation-0 mt-n10">
      <v-card-text class="mb-n3">
        <v-row>
          <v-col class="align-self-center" cols="2"><span class="font-weight-medium grey--text text--darken-1">Unbounce Page ID:</span></v-col>
          <v-col cols="7">
            <v-text-field id="unbounce_page_id" v-model="unbouncePageId" hint="Enter Unbounce Page ID"></v-text-field>
          </v-col>
          <v-col class="align-self-center text-center" cols="3">
            <v-btn @click="saveUnbouncePageId">Save</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isUnbounceAlertVisible">
          <v-col>
            <v-alert v-model="isUnbounceAlertVisible" :type="unbounceAlertType" dismissible>{{ unbounceAlertMsg }}</v-alert>
          </v-col>
        </v-row>
        <v-divider class="my-4"></v-divider>
        <v-row>
          <v-col class="align-self-center" cols="2"><span class="font-weight-medium grey--text text--darken-1">Convirza Group ID:</span></v-col>
          <v-col cols="7">
            <v-text-field id="convirza_group_id" v-model="convirzaGroupId" hint="Enter Convirza Group ID"></v-text-field>
          </v-col>
          <v-col class="align-self-center text-center" cols="3">
            <v-btn @click="saveConvirzaGroupId">Save</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="align-self-center" cols="2"><span class="font-weight-medium grey--text text--darken-1">Convirza Campaign Start Date:</span></v-col>
          <v-col cols="7">
            <v-menu v-model="isStartDateMenuVisible" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on }">
                <v-text-field :value="selectedStartDate" readonly v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="selectedStartDate" :max="maxAvailableDate" @change="setStartDateMenuVisibility(false)"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="align-self-center text-center" cols="3">
            <v-btn @click="saveConvirzaStartDate">Save</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isConvirzaAlertVisible">
          <v-col>
            <v-alert v-model="isConvirzaAlertVisible" :type="convirzaAlertType" dismissible>{{ convirzaAlertMsg }}</v-alert>
          </v-col>
        </v-row>
        <v-divider class="my-4"></v-divider>
        <v-row>
          <v-col class="align-self-center" cols="2"><span class="font-weight-medium grey--text text--darken-1">Callbox Phone Number:</span></v-col>
          <v-col cols="7">
            <v-text-field id="callbox_phone_number" v-model="callboxPhoneNumber" hint="Enter Callbox phone number without dashes"></v-text-field>
          </v-col>
          <v-col class="align-self-center text-center" cols="3">
            <v-btn @click="saveCallboxPhoneNumber">Save</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="align-self-center" cols="2"><span class="font-weight-medium grey--text text--darken-1">Callbox Site:</span></v-col>
          <v-col cols="7">
            <v-autocomplete v-model="callboxSiteId" :items="callboxSites" required item-value="-id" item-text="#content"></v-autocomplete>
          </v-col>
          <v-col class="align-self-center text-center" cols="3">
            <v-btn @click="saveCallboxSiteId">Save</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isCallboxAlertVisible">
          <v-col>
            <v-alert v-model="isCallboxAlertVisible" :type="callboxAlertType" dismissible>{{ callboxAlertMsg }}</v-alert>
          </v-col>
        </v-row>
        <v-divider class="my-4"></v-divider>
        <v-row>
          <v-col class="align-self-center" cols="2"><span class="font-weight-medium grey--text text--darken-1">Cuttly URL:</span></v-col>
          <v-col cols="7">
            <v-text-field id="cuttly_url" v-model="cuttlyURL" hint="Enter Cuttly URL"></v-text-field>
          </v-col>
          <v-col class="align-self-center text-center" cols="3">
            <v-btn @click="saveCuttlyURL">Save</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isCuttlyAlertVisible">
          <v-col>
            <v-alert v-model="isCuttlyAlertVisible" :type="cuttlyAlertType" dismissible>{{ cuttlyAlertMsg }}</v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ['isPanelOpened', 'orderData'],
  data() {
    return {
      unbouncePageId: '',
      convirzaGroupId: '',
      selectedStartDate: '',
      isStartDateMenuVisible: false,
      callboxPhoneNumber: '',
      callboxSiteId: '',
      callboxSites: [],
      cuttlyURL: '',
      //alert messages
      unbounceAlertMsg: '',
      unbounceAlertType: '',
      convirzaAlertMsg: '',
      convirzaAlertType: '',
      callboxAlertMsg: '',
      callboxAlertType: '',
      cuttlyAlertMsg: '',
      cuttlyAlertType: '',
      //alert visibility
      isUnbounceAlertVisible: false,
      isConvirzaAlertVisible: false,
      isCallboxAlertVisible: false,
      isCuttlyAlertVisible: false
    };
  },
  computed: {
    maxAvailableDate() {
      return this.$moment().format('YYYY-MM-DD');
    }
  },
  watch: {
    isPanelOpened(value) {
      if (!value) {
        this.isUnbounceAlertVisible = false;
        this.isConvirzaAlertVisible = false;
        this.isCallboxAlertVisible = false;
        this.isCuttlyAlertVisible = false;
      }
    }
  },
  async mounted() {
    if (this.orderData.unbounce_page_id) {
      this.unbouncePageId = this.orderData.unbounce_page_id;
    }
    if (this.orderData.convirza_group_id) {
      this.convirzaGroupId = this.orderData.convirza_group_id;
    }
    if (this.orderData.convirza_campaign_start_date) {
      this.selectedStartDate = this.orderData.convirza_campaign_start_date;
    }
    if (this.orderData.callbox?.phone_number) {
      this.callboxPhoneNumber = this.orderData.callbox.phone_number;
    }
    if (this.orderData.callbox?.site_id) {
      this.callboxSiteId = this.orderData.callbox.site_id;
    }
    if (this.orderData.cuttly_url) {
      this.cuttlyURL = this.orderData.cuttly_url;
    }
    await this.getCallboxSites();
  },
  methods: {
    async getCallboxSites() {
      try {
        const response = await this.$store.dispatch('tools/getCallboxSites');
        this.callboxSites = response.data;
      } catch (error) {
        console.log('Error while getting Callbox sites: ', error);
      }
    },
    async saveUnbouncePageId() {
      try {
        await this.$store.dispatch('om_orders/saveUnbouncePageId', {
          order_id: this.orderData.firebase_order_id,
          unbounce_page_id: this.unbouncePageId
        });
        this.unbounceAlertMsg = 'Unbounce Page ID successfully saved.';
        this.unbounceAlertType = 'success';
      } catch (error) {
        this.unbounceAlertMsg = `Saving Unbounce Page ID has failed: ${error}`;
        this.unbounceAlertType = 'error';
      } finally {
        this.isUnbounceAlertVisible = true;
      }
    },
    async saveConvirzaGroupId() {
      try {
        await this.$store.dispatch('om_orders/saveConvirzaGroupId', {
          order_id: this.orderData.firebase_order_id,
          convirza_group_id: this.convirzaGroupId
        });
        this.convirzaAlertMsg = 'Convirza Group ID successfully saved.';
        this.convirzaAlertType = 'success';
      } catch (error) {
        this.convirzaAlertMsg = `Saving Convirza Group ID has failed: ${error}`;
        this.convirzaAlertType = 'error';
      } finally {
        this.isConvirzaAlertVisible = true;
      }
    },
    async saveConvirzaStartDate() {
      try {
        await this.$store.dispatch('om_orders/saveConvirzaStartDate', {
          order_id: this.orderData.firebase_order_id,
          convirza_campaign_start_date: this.selectedStartDate
        });
        this.convirzaAlertMsg = 'Convirza Campaign Start Date successfully saved.';
        this.convirzaAlertType = 'success';
      } catch (error) {
        this.convirzaAlertMsg = `Saving Convirza Campaign Start Date has failed: ${error}`;
        this.convirzaAlertType = 'error';
      } finally {
        this.isConvirzaAlertVisible = true;
      }
    },
    async saveCallboxPhoneNumber() {
      try {
        await this.$store.dispatch('om_orders/saveCallboxPhoneNumber', {
          order_id: this.orderData.firebase_order_id,
          callbox_phone_number: this.callboxPhoneNumber
        });
        this.callboxAlertMsg = 'Callbox phone number successfully saved.';
        this.callboxAlertType = 'success';
      } catch (error) {
        this.callboxAlertMsg = `Saving Callbox phone number has failed: ${error}`;
        this.callboxAlertType = 'error';
      } finally {
        this.isCallboxAlertVisible = true;
      }
    },
    async saveCallboxSiteId() {
      try {
        await this.$store.dispatch('om_orders/saveCallboxSiteId', {
          order_id: this.orderData.firebase_order_id,
          callbox_site_id: this.callboxSiteId
        });
        this.callboxAlertMsg = 'Callbox site ID successfully saved.';
        this.callboxAlertType = 'success';
      } catch (error) {
        this.callboxAlertMsg = `Saving Callbox site ID has failed: ${error}`;
        this.callboxAlertType = 'error';
      } finally {
        this.isCallboxAlertVisible = true;
      }
    },
    async saveCuttlyURL() {
      try {
        await this.$store.dispatch('om_orders/saveCuttlyURL', {
          order_id: this.orderData.firebase_order_id,
          cuttly_url: this.cuttlyURL
        });
        this.cuttlyAlertMsg = 'Cuttly URL successfully saved.';
        this.cuttlyAlertType = 'success';
      } catch (error) {
        this.cuttlyAlertMsg = `Saving Cuttly URL has failed: ${error}`;
        this.cuttlyAlertType = 'error';
      } finally {
        this.isCuttlyAlertVisible = true;
      }
    },
    setStartDateMenuVisibility(value) {
      this.isStartDateMenuVisible = value;
    }
  }
};
</script>
