<template>
  <div>
    <v-form ref="dynamicForm" v-model="isFormValid">
      <v-row v-for="(item, index) in modelMapping" :key="index" no-gutters>
        <v-col v-if="isInputTypeExists(item)" cols="12" md="6" lg="12" xl="9">
          <component
            :is="fieldType(item.input_type)"
            :key="index"
            v-model="modelObject[item.model]"
            :label="item.label"
            :readonly="!isEditEnabled"
            :disabled="item.disabled"
            :name="`${item.label}-${item.input_type}`"
            :items="item.items"
            :multiple="item.is_multiple"
            :rules="item.rule"
            :fieldValue="item.value"
            :type="item.type"
            :directory="item.directory"
            :acceptedFileTypes="item.acceptedFileTypes"
            :required="isItemRequired(item)"
            outlined
            @input="sendToParent()"
          ></component>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import TextInput from './TextFieldInput';
import CheckBoxInput from './CheckBoxComponent';
import SwitchInput from './SwitchComponent';
import TextAreaInput from './TextAreaInput';
import SelectInput from './SelectComponent';
import FileInputComponent from './FileInputComponent.vue';

export default {
  components: {
    TextInput,
    CheckBoxInput,
    SwitchInput,
    TextAreaInput,
    SelectInput,
    FileInputComponent
  },
  props: ['modelMapping', 'isEditEnabled'],
  data() {
    return {
      modelObject: {},
      isFormValid: false
    };
  },
  watch: {
    isFormValid(value) {
      this.$emit('isValid', value);
    }
  },
  methods: {
    fieldType(type) {
      switch (type) {
        case 'textfield':
          return 'TextInput';
        case 'checkbox':
          return 'CheckBoxInput';
        case 'switch':
          return 'SwitchInput';
        case 'textarea':
          return 'TextAreaInput';
        case 'select':
          return 'SelectInput';
        case 'fileinput':
          return 'FileInputComponent';
        default:
          return type;
      }
    },
    isInputTypeExists(item) {
      return item?.input_type !== 'object';
    },
    isItemRequired(item) {
      return item.is_required ?? false;
    },
    sendToParent() {
      this.$emit('input', this.modelObject);
    }
  }
};
</script>
