<template>
  <v-container>
    <v-dialog v-model="dialogFinishUpdate" max-width="500px">
      <v-card>
        <v-card-title class="justify-center">
          <v-icon color="success">mdi-checkbox-marked-circle-outline</v-icon>
          <span color="success" class="ml-2">SUCCESS!</span></v-card-title
        >
        <v-card-text>
          <v-divider></v-divider>
          <div class="pt-5">
            CSV File successfully saved to Fireabase Storage in folder
            <a href="https://console.firebase.google.com/u/0/project/onebrandamp/storage/onebrandamp.appspot.com/files/~2Forder">order.</a>
          </div>
          <div class="pt-2">
            Filename: <b> {{ storageFileName }} </b>
          </div>
          <div class="pt-2">
            CSV Line Count: <b> {{ csvLineCount }} </b>
          </div>
          <v-row class="justify-center">
            <div class="mt-4">
              <a v-bind:href="csvLink">Download CSV <v-icon>mdi-download</v-icon></a>
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text class="mr-2" @click="dialogFinishUpdate = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="agreeGenerateCsvDialog" max-width="500px">
      <v-card>
        <v-card-title><v-icon large color="warning">mdi-alert</v-icon><span class="headline ml-2">Warning</span></v-card-title>
        <v-card-text class="subtitle-1">
          Are you sure want to download the requested information?
          <span v-if="!isPoliticalType">Please note that this operation incurs costs on our integration with Melissa Data.</span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            :class="`ma-1 ${isAgreeBtnFocused ? 'red--text' : ''}`"
            @mouseover="setAgreeBtnFocusState(true)"
            @mouseleave="setAgreeBtnFocusState(false)"
            @click="setAgreeGenerateCsvDialogState(false)"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            :class="`ma-1 ${isCancelBtnFocused ? 'blue--text' : ''}`"
            @mouseover="setCancelBtnFocusedState(true)"
            @mouseleave="setCancelBtnFocusedState(false)"
            @click="downloadAgreeded()"
            >I am sure</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isFileNameSelectDialogOpened" max-width="600px">
      <v-card>
        <v-card-title class="subtitle-1 grey--text text--darken-2">Filename for downloaded CSV</v-card-title>
        <v-card-text>
          <csv-filename-editor referenceType="list" :defaultFileName="defaultFileName" :firebaseId="listID" @fileName="setFileName"> </csv-filename-editor>
          <v-row class="justify-end mb-n2">
            <v-btn text color="grey darken-3" @click="onFileNameSelected()">Continue</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row>
      <v-btn icon class="mb-1 pb-1" @click.native="back()">
        <v-icon color="primary">mdi-arrow-left</v-icon>
      </v-btn>
      <span class="headline">Lists</span>
      <v-icon class="mt-n1 mx-2">mdi-chevron-right</v-icon>
      <span class="title">{{ listName }} </span>
      <v-spacer></v-spacer>
      <span class="subtitle-1 mr-3"><b>Type:</b> {{ type }} </span>
      <span class="subtitle-1 mr-3"><b>ID:</b> {{ listID }}</span>
    </v-row>
    <v-divider v-if="!feedbackAlert" class="my-3"></v-divider>
    <v-alert v-model="feedbackAlert" :type="alertType" dismissible outlined>{{ alertMessage }}</v-alert>
    <v-row>
      <v-col cols="12" md="9">
        <v-row class="mx-0 my-0">
          <p class="headline">Summary</p>
          <v-spacer></v-spacer>
          <template v-if="isListCriteriaAvailable">
            <v-btn :disabled="isListCriteriaDisabled" class="static subtitle-2 mr-3" color="blue" dark @click="initListFileName('criteria')">
              <v-icon class="mr-1" color="white">mdi-file-download-outline</v-icon>
              <span>List Criteria</span>
            </v-btn>

            <v-btn v-if="!isCsvFileExist" class="static subtitle-2" color="blue" dark @click="initListFileName('list')">
              <v-icon class="mr-2" color="white">mdi-table-plus</v-icon>Generate CSV
            </v-btn>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on }">
                <v-btn :href="csvLink" color="grey lighten-2" v-on="on"> <v-icon color="blue-grey darken-2" class="mr-2">mdi-cloud-download-outline</v-icon>Download CSV </v-btn>
              </template>
              <span>Download Generated CSV</span>
            </v-tooltip>
          </template>
        </v-row>
        <!-- TODO: Change data table to the general table under task: AMPDEV-3677 -->
        <v-data-table
          :headers="summaryHeaders"
          :items-per-page="itemsPerPage"
          :items="summary"
          :single-expand="true"
          :expanded.sync="expanded"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.key }}</span>
          </template>
          <template v-slot:[`item.details`]="{ item }">
            <span v-if="item.key === 'Site Name'"
              ><a v-bind:href="rootPath + 'orders-process/' + item.value">{{ siteName }}</a></span
            >
            <span v-else-if="item.key === 'Date'">{{ formatDate(item.value) }}</span>
            <span v-else-if="item.key === 'CSV file'"
              ><a v-bind:href="item.value">{{ item.value }}</a></span
            >
            <span v-else> {{ item.value }}</span>
          </template>

          <template v-slot:[`item.expand`]="{ item }">
            <template v-if="item.key === 'Logs' || item.key === 'Filters'">
              <v-btn v-if="item.canExpand" icon @click="toggleExpansion(item)">
                <v-icon v-if="isExpanded(item)">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td v-if="item.key === 'Logs' && isExpanded(item)" :colspan="headers.length" class="logsTable">
              <stamp-data-table
                class="customHeader"
                :headers="logHeaders"
                :data="actionLogs"
                sort-by="timestamp"
                sort-desc
                no-data-text="There are no logged events yet ..."
                item-key="logsTable"
              >
                <template #[`item.customer_email`]="{ item }">{{ item.customer_email }}</template>
                <template #[`item.action`]="{ item }">{{ item.action }}</template>
                <template #[`item.timestamp`]="{ item }">{{ formatDate(item.timestamp) }}</template>
                <template #[`item.status`]="{ item }">{{ item.status }}</template>
              </stamp-data-table>
            </td>
            <td v-else-if="item.key === 'Filters' && isExpanded(item)" :colspan="headers.length" class="logsTable">
              <v-card v-if="filters.geographical" class="pl-4 py-3">
                <div>
                  <strong>Geographical: </strong>
                  <span class="white--text warning rounded px-2">{{ filters.geographical }}</span>
                </div>

                <div v-if="filters.demographical?.length">
                  <strong>Demographical: </strong>
                  <span v-for="(filter, index) in filters.demographical" :key="index" class="white--text warning rounded px-2 mx-1"> {{ filter }}</span>
                </div>
              </v-card>
              <v-card v-else class="pl-4 py-3">
                <div class="grey--text lighten-1 text-center">
                  <strong>There are no fiters applied! </strong>
                </div>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="type === 'upload'">
      <v-col cols="12" md="9">
        <v-row class="justify-space-between mx-0">
          <span class="headline mt-2 mb-5">Processed Uploaded List Preview</span>
          <v-btn v-if="this.listObject.savedUploadedList && this.listObject.savedUploadedList.url" :href="this.listObject.savedUploadedList.url" color="grey lighten-2" tile>
            <v-icon color="blue-grey darken-2" class="mr-2">mdi-cloud-download-outline</v-icon>Download List
          </v-btn>
        </v-row>
        <v-data-table :headers="headers" :items="list" :items-per-page="15" class="elevation-1"> </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="storagePath.length !== 0">
      <v-col sm="9">
        <p class="title mt-3">Actions</p>
        <v-row>
          <v-btn color="green darken-1" text @click="checkStoragePath()">Check storage Path</v-btn>
          <v-btn v-if="isError" color="green darken-1" text @click="clearStoragePath()">Clear storage path</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import FileNameEditor from '../maps/mapDialogs/CsvFileNameEditor';
import DataTable from '@/components/common/DataTable.vue';
import { firestoreTimestamp } from '../../../services/firebaseInit.js';
import mailingListsService from '../../../services/mailingListsService';
export default {
  components: { 'csv-filename-editor': FileNameEditor, 'stamp-data-table': DataTable },
  data() {
    return {
      isLogListExpanded: false,
      isFilterListExpanded: false,
      listID: this.$route.params.listID,
      summary: [],
      type: null,
      listName: null,
      rootPath: '',
      headers: [],
      summaryHeaders: [
        { text: 'Name', align: 'left', value: 'name', width: '160' },
        { text: 'Details', align: 'left', value: 'details' },
        { text: '', align: 'end', value: 'expand', sortable: false }
      ],
      logHeaders: [
        { text: 'Customer', align: 'left', value: 'customer_email' },
        { text: 'Action', align: 'left', value: 'action' },
        { text: 'Count', align: 'left', value: 'count' },
        { text: 'Date', align: 'center', value: 'timestamp' },
        { text: 'Status', align: 'left', value: 'status' }
      ],
      expanded: [],
      itemsPerPage: 15,
      list: [],
      storagePath: '',
      isError: false,
      requestedOptions: null,
      isCsvFileExist: false,
      csvLink: '',
      politicalFilters: null,
      dialogFinishUpdate: false,
      storageFileName: '',
      csvLineCount: '',
      agreeGenerateCsvDialog: false,
      isCancelBtnFocused: false,
      isAgreeBtnFocused: false,
      isCriteriaRequested: false,
      listObject: {},
      filters: {
        geographical: [],
        demographical: []
      },
      logsLoading: false,
      requestedCount: 0,
      feedbackAlert: false,
      alertType: 'info',
      alertMessage: '',
      isFileNameSelectDialogOpened: false,
      csvFileName: '',
      defaultFileName: '',
      isDataAxleRequestFailed: false,
      isListCriteriaDisabled: true,
      filterNames: {
        voterRegistration: 'Voter Registration',
        ethnicityReligious: 'Ethnicity Religious',
        ethnicityLanguage: 'Ethnicity Language',
        ethnicityPrimaryLanguage: 'Ethnicity Primary Language',
        homeAges: 'Age of Home',
        householdIncome: 'Household Income',
        mailResponsiveInfo: 'Mail Responsive',
        nrOfPeopleList: 'Number of People',
        locationEmployeeCount: 'Location Employee Count',
        cardTypes: 'Card Types',
        ages: 'Ages',
        maritalStatus: 'Marital status',
        homeValue: 'Home Value',
        yearsOfResidence: 'Years of Residence',
        homeOwner: 'Home Owner Type',
        netWorth: 'Net worth',
        primaryNaicsCodeId: 'Primary NAICS code',
        congressDistrict: 'Congress District',
        primarySicCodeId: 'Primary SIC code',
        nrOfChildrenList: 'Number of Children',
        politicalParty: 'Political Party',
        locationSalesVolumeCount: 'Location Sales Volume',
        nrOfAdultsList: 'Number of Adults',
        homePurchasePrice: 'Home Purchase Price',
        homeYearBuilt: 'Building Year Of Home',
        squareFootage: 'Square Footage',
        gender: 'Gender',
        property: 'Property'
      }
    };
  },
  computed: {
    ...mapGetters('customer_messaging', {
      siteName: 'getSiteName'
    }),
    ...mapGetters('users', {
      userLevel: 'userAccesLevelValue',
      userAccessLevel: 'userAccessLevel',
      userStore: 'user'
    }),
    ...mapGetters('logs', {
      actionLogs: 'getMailingListLogs'
    }),
    isPoliticalType() {
      return this.type === 'political';
    },
    isListCriteriaAvailable() {
      return ['political', 'order'].includes(this.type);
    }
  },
  watch: {
    expanded() {
      if (this.expanded.length === 1) {
        this.logsLoading = true;
        this.$store.dispatch('logs/getListActionLogs', { listLogId: this.listObject.listLogId ? this.listObject.listLogId : '' }).then(() => {
          this.logsLoading = false;
        });
      }
    }
  },
  async mounted() {
    try {
      this.rootPath = window.location.origin + '/';
      this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });

      const response = await this.$store.dispatch('customer_messaging/listSelectedList', { listID: this.listID });

      console.log('ListObject:', response.data);
      this.listObject = response.data;
      this.requestedOptions = response.data.requestedOptions;
      if (response.data.type === 'political') {
        this.politicalFilters = response.data.adjustedFilters || '';
      }
      this.type = response.data.type;
      this.listName = response.data.listName;
      this.list = response.list;
      this.summary = response.summary;
      this.processDemographicalFilters(response.data.adjustedFilters);
      this.processGeographicalFilters(response.data.zipCodes, response.data.address);
      console.log('Filters:', this.filters);

      this.summary.push({ key: 'Business List', value: this.listObject.hasOwnProperty('isBusiness') && this.listObject.isBusiness ? 'Yes' : 'No' });
      this.summary.push({ key: 'Amount Limit', value: this.listObject.hasOwnProperty('targetedAmountLimit') ? this.listObject.targetedAmountLimit : '-' });
      this.summary.push({ key: 'Logs', canExpand: true });
      this.summary.push({ key: 'Filters', canExpand: true });
      this.itemsPerPage = this.summary.length ? this.summary.length : 15;
      if (this.listObject.processedUploadedList && this.listObject.processedUploadedList.list_preview_data) {
        this.listObject.processedUploadedList.list_preview_data.imported_header.forEach(name => {
          this.headers.push({
            text: typeof name === 'string' ? name.toLowerCase().charAt(0).toUpperCase() + name.toLowerCase().slice(1) : name,
            align: 'left',
            value: name
          });
        });
      }
      this.storagePath = response.storagePath;
      const csvSummary = response?.summary?.find(item => item.key === 'CSV file');
      if (csvSummary) {
        this.csvLink = csvSummary.value;
        this.isCsvFileExist = true;
      }
    } finally {
      this.$store.dispatch('hideLoader');
    }

    // fetch again if access level was changed after user is logged in
    if (!this.userLevel) {
      await this.$store.dispatch('users/fetchUserInApp');
    }
  },
  methods: {
    isExpanded(item) {
      return item.key === 'Logs' ? this.isLogListExpanded : this.isFilterListExpanded;
    },
    toggleExpansion(item) {
      this.expanded = [item];
      if (item.key === 'Logs') {
        this.isLogListExpanded = !this.isLogListExpanded;
        this.isFilterListExpanded = false;
        return;
      }
      this.isFilterListExpanded = !this.isFilterListExpanded;
      this.isLogListExpanded = false;
    },
    processDemographicalFilters(inputObject) {
      for (const key in inputObject) {
        if (inputObject[key]?.length) {
          const filterName = this.filterNames[key];
          const concatenatedLabels = inputObject[key][0]?.label ? inputObject[key].map(obj => obj.label).join(', ') : inputObject[key].join(', ');
          this.filters.demographical.push(`${filterName}: ${concatenatedLabels}`);
        }
      }
    },

    processGeographicalFilters(zipCodes, address) {
      if (address && Object.keys(address).length) {
        this.filters.geographical = `${address.street}, ${address.city}, ${address.state}, ${address.zip}`;
        return;
      }
      this.filters.geographical = zipCodes;
    },
    async checkStoragePath() {
      try {
        const response = await this.$store.dispatch('customer_messaging/checkStoragePathMailingLists', { storagePath: this.storagePath, firebaseID: this.listID });
        if (!response) {
          this.setAlertProps('warning', 'There is no storage path!');
        } else if (response.error) {
          this.isError = true;
          this.setAlertProps('error', response.error);
        } else if (response.url) {
          let isCSV = false;
          this.summary.forEach((value, index) => {
            if (value.key === 'CSV file') {
              this.summary[index].value = response.url;
              isCSV = true;
            }
          });
          if (!isCSV) {
            this.summary.push({
              key: 'CSV file',
              value: response.url
            });
          }
          this.setAlertProps('info', 'Storage Path exist! URL to CSV file created!');
        }
      } catch (error) {
        console.log('checkStoragePath has failed: ', error);
      }
    },
    async downloadAgreeded() {
      this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
      this.logsLoading = true;
      this.setAgreeGenerateCsvDialogState(false);

      if (this.isCriteriaRequested) {
        if (this.type === 'order') {
          await this.listExport();
        } else if (this.type === 'political') {
          await this.getPoliticalListCriteria();
        }
      } else {
        if (this.type === 'order') {
          await this.getCsv();
        } else if (this.type === 'political') {
          await this.getCsvPolitical();
        }
      }
      console.log('[ COUNT ]:', this.requestedCount);

      let logData = {
        firebaseID: this.listID,
        siteId: this.listObject.siteDbId,
        listLogId: this.listObject.listLogId ? this.listObject.listLogId : '',
        action: {
          timestamp: firestoreTimestamp.fromDate(new Date()),
          customer_email: this.userStore.email,
          action: this.isCriteriaRequested ? 'list_criteria_requested' : 'generate_csv_requested',
          count: this.requestedCount > 0 ? this.requestedCount : 0,
          status: this.isDataAxleRequestFailed ? 'error' : 'success',
          type: this.type
        }
      };
      if (this.type === 'order') {
        logData.action.is_business = this.listObject.hasOwnProperty('isBusiness') && this.listObject.isBusiness;
      }

      try {
        const response = await this.$store.dispatch('logs/countMailingListRequests', logData);

        if (!this.listObject.listLogId && response.listLogId) {
          this.listObject.listLogId = response.listLogId;
        }
        console.log('[ Logs updated ]:', response);
        return Promise.resolve();
      } catch (error) {
        console.log('Error occured in updating logs:', error);
        this.setAlertProps('error', error);
        return Promise.reject();
      } finally {
        this.logsLoading = false;
        this.isCriteriaRequested = false;
        this.requestedCount = 0;
        this.$store.dispatch('hideLoader');
        console.log('All done!');
      }
    },
    async clearStoragePath() {
      try {
        await mailingListsService.clearStoragePath(this.listID);
        this.isError = false;
        this.setAlertProps('info', 'Storage Path, download URL and progress status succefully cleared!');
        this.summary.forEach((value, index) => {
          if (value.key === 'Storage Path') {
            this.summary[index].value = '';
          }
          if (value.key === 'CSV file') {
            this.summary[index].value = '';
          }
        });
      } catch (err) {
        console.log('clearStoragePath has failed: ', err);
      }
    },
    async clearAll() {
      try {
        await mailingListsService.clearStoragePath(this.listID);
        this.summary.forEach((value, index) => {
          if (value.key === 'Storage Path') {
            this.summary[index].value = '';
          }
          if (value.key === 'CSV file') {
            this.summary[index].value = '';
          }
        });
      } catch (err) {
        console.log('clearAll has failed: ', err);
      }
    },
    formatDate(dateTotransform) {
      var date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    back() {
      this.$router.back();
    },
    async listExport() {
      try {
        const response = await this.$store.dispatch('lists/getListCriteria', {
          requestedOptions: this.listObject?.requestObject,
          csvFileName: `${this.csvFileName}_${Number(new Date())}`,
          firebaseID: this.listID,
          isBusiness: this.listObject.hasOwnProperty('isBusiness') && this.listObject.isBusiness,
          limit: this.listObject.targetedAmountLimit
        });
        this.requestedCount = response.requestedCount;
        return Promise.resolve({ status: 'success' });
      } catch (error) {
        console.log('[ Getting order list criteria failed ]:', error);
        this.setAlertProps('error', error);
        this.isDataAxleRequestFailed = true;
        return Promise.reject({ status: 'error', message: error });
      }
    },
    async getPoliticalListCriteria() {
      return this.$store
        .dispatch('lists/getPoliticalListCriteria', {
          requestedOptions: this.requestedOptions,
          fileName: `${this.csvFileName}_${Number(new Date())}`,
          firebaseID: this.listID,
          listCount: this.listObject.listCount
        })
        .then(() => {
          this.requestedCount = this.listObject.listCount;
          return { status: 'success' };
        })
        .catch(error => {
          console.log('[ Getting political list criteria failed ]:', error);
          this.setAlertProps('error', error);
          return { status: 'error', message: error };
        });
    },
    async getCsv() {
      try {
        const response = await this.$store.dispatch('lists/generateListCsv', {
          requestedOptions: this.listObject?.requestObject,
          filename: `${this.csvFileName}_${Number(new Date())}`,
          firebaseId: this.listID,
          isBusiness: this.listObject.hasOwnProperty('isBusiness') && this.listObject.isBusiness,
          targetedAmountLimit: this.listObject.targetedAmountLimit ? this.listObject.targetedAmountLimit : ''
        });
        this.storageFileName = response.storageFileName;
        this.csvLineCount = response.csvLineCount;
        this.requestedCount = this.csvLineCount;
        this.isCsvFileExist = true;
        this.csvLink = response.csvLink;
        this.summary.push({ key: 'CSV file', value: this.csvLink });
        this.dialogFinishUpdate = true;
        return { status: 'success' };
      } catch (error) {
        this.isDataAxleRequestFailed = true;
        console.log('Error during getting list:', error);
        this.setAlertProps('error', error);
        return { status: 'error', message: error };
      }
    },
    async getCsvPolitical() {
      try {
        const response = await this.$store.dispatch('lists/generatePoliticalListCsv', {
          requestedOptions: this.listObject.requestedOptions,
          filename: `${this.csvFileName}_${Number(new Date())}`,
          firebaseId: this.listID,
          politicalFilters: this.politicalFilters,
          targetedAmountLimit: this.listObject.targetedAmountLimit ? this.listObject.targetedAmountLimit : ''
        });

        this.storageFileName = response.storageFileName;
        this.csvLineCount = this.listObject.listCount;
        this.requestedCount = this.csvLineCount;
        this.isCsvFileExist = true;
        this.csvLink = response.csvLink;
        this.summary.push({ key: 'CSV file', value: this.csvLink });
        this.dialogFinishUpdate = true;
        return { status: 'success' };
      } catch (error) {
        this.isDataAxleRequestFailed = true;
        console.log('Error during getting political list:', error);
        this.setAlertProps('error', error);
        return { status: 'error', message: error };
      }
    },
    initListFileName(selector) {
      this.defaultFileName = this.concatAndFormat(this.listName);
      this.isFileNameSelectDialogOpened = true;
      this.isCriteriaRequested = selector === 'criteria';
    },
    onFileNameSelected() {
      this.isFileNameSelectDialogOpened = false;
      this.setAgreeGenerateCsvDialogState(true);
    },
    setFileName(name) {
      this.csvFileName = name.slice(-1) === '_' ? name.slice(0, -1) : name;
    },
    concatAndFormat(text) {
      if (!text) return '';
      let modifiedText = text.toString();
      modifiedText = modifiedText.toLowerCase();
      modifiedText = modifiedText.replace(/["']/g, '');
      modifiedText = modifiedText.replace(/:/g, '_');
      modifiedText = modifiedText.replace(/[/]/g, '_');
      modifiedText = modifiedText.replace(/\s+/g, '_');
      return modifiedText;
    },
    setAlertProps(type, message) {
      this.alertType = type;
      this.alertMessage = message;
      this.feedbackAlert = true;
    },
    setAgreeBtnFocusState(value) {
      this.isAgreeBtnFocused = value;
    },
    setAgreeGenerateCsvDialogState(value) {
      this.agreeGenerateCsvDialog = value;
    },
    setCancelBtnFocusedState(value) {
      this.isCancelBtnFocused = value;
    }
  }
};
</script>
<style>
.custom-tab {
  text-transform: initial;
}
.custom-card-text {
  font-size: 14px !important;
}
.v-data-table.elevation-1.theme--light .logsTable {
  padding: 0;
}
.v-data-table.customHeader.theme--light table thead.v-data-table-header th {
  background-color: #f5f5f5;
}

#example-1,
#example-2,
#example-3,
#example-4 {
  list-style-type: none;
}
</style>
