<template>
  <div>
    <v-dialog v-model="isDialogRootFolder" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">New Root Folder</span>
        </v-card-title>
        <v-card-text>
          <v-alert v-model="isExtensionAlertRootFolder" type="error" dismissible> File extension must be .psd! </v-alert>
          <v-alert v-model="isUpdateAlertNewRootFolder" type="success" dismissible> Folder created succefully! File upload succefully! </v-alert>
          <v-alert v-model="isUpdateAlertErrorNewRootFolder" type="error" dismissible> Error on upload image! </v-alert>
          <v-alert v-model="isEmptyRootFolderAlert" type="error" dismissible> Folder name input field cannont be empty! Please add folder name. </v-alert>
          <v-row>
            <v-col class="pt-0">
              <small class="red--text">*Folder cannot be created empty, file must be uploaded to new folder!</small>
              <v-text-field id="newRootFolderName" v-model="newRootFolderName" label="Root folder name" hint="Enter folder name" required type="text"></v-text-field>
              <div class="ml-0 mt-2">
                <p class="body-2">Upload file</p>
                <file-pond
                  ref="pond"
                  name="new_root_psd_image"
                  label-idle="Drop files here or Browse"
                  allow-multiple="true"
                  allowFileTypeValidation="false"
                  :server="{ process, revert, restore, load, fetch }"
                  @removefile="handleRemoveFile"
                  @processfile="processFile"
                  v-on:addfile="handleFilePondAddFile"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeCreateRootFolder">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert v-if="deleteFlag" v-model="deleteAlert" type="error" dismissible>
      {{ deleteMessage }}
    </v-alert>
    <v-alert v-else v-model="deleteAlert" type="success" dismissible>
      {{ deleteMessage }}
    </v-alert>
    <v-alert v-model="deletePathAlert" type="error" dismissible> Path not found! </v-alert>
    <div class="site-table--header">
      <v-card-title class="headline">
        <span> Design Folder </span>
        <v-btn text icon color="green" @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-card-title>
    </div>
    <!-- /.site-table--header -->
    <v-card>
      <v-row>
        <v-col>
          <ConfirmationDialog :value="isDeleteDialogOpen" title="Confirmation required" :text="deleteImageText" @onClose="closeDialog" @onConfirm="confirmDelete" />
        </v-col>
      </v-row>
      <v-layout justify-space-between pa-3>
        <v-flex xs4 sm4 class="custom-layout">
          <v-row>
            <v-col cols="12" class="">
              <v-btn v-if="!loadingDialog" text @click="openRootDialog"> <v-icon left>mdi-folder-plus</v-icon> New Root Folder </v-btn>
            </v-col>
          </v-row>
          <v-treeview v-model="tree" :active.sync="active" :open="open" :items="items" activatable item-key="name" transition class="grey lighten-4 custom-node darkmode-bg">
            <template slot="prepend" slot-scope="{ item, open }">
              <v-icon v-if="!item.file" @click="folder(item)">
                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
              </v-icon>
              <v-icon v-else>
                {{ files[item.file] }}
              </v-icon>
            </template>
            <template slot="label" slot-scope="{ item }">
              <a @click="folder(item)">{{ item.name }}</a>
            </template>
          </v-treeview>
        </v-flex>
        <v-col md="8" class="pt-0">
          <v-row v-if="isDirOpen" class="custom-row">
            <v-col md="6" class="text-md-left">
              <span class="grey--text text--darken-3 subheading"> /designs/{{ selectedPath }}</span>
            </v-col>
            <v-col md="6" class="text-md-right">
              <v-dialog v-model="dialogFolder" max-width="400px">
                <template v-slot:activator="{ on }">
                  <v-btn text small color="primary" v-on="on"> <v-icon left>mdi-folder-plus</v-icon> New Folder </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">New folder</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container grid-list-md>
                      <v-alert v-model="updateAlertNewFolder" type="success" dismissible> File upload succefully! </v-alert>
                      <v-alert v-model="updateAlertErrorNewFolder" type="error" dismissible> Error on upload image! </v-alert>
                      <v-alert v-model="emptyFolderAlert" type="error" dismissible> Folder name input field cannont be empty! Please add folder name. </v-alert>
                      <v-layout wrap>
                        <small class="red--text">*Folder cannot be created empty, file must be uploaded to new folder!</small>
                        <v-flex xs12 sm12>
                          <v-text-field id="newFolderName" v-model="newFolderName" label="Folder name" hint="Enter folder name" required type="text"> </v-text-field>
                          <div class="ml-0 mt-2">
                            <p class="body-2">Upload file</p>
                            <file-pond
                              ref="pond"
                              name="new_psd_image1"
                              label-idle="Drop files here or Browse"
                              allow-multiple="true"
                              accepted-file-types=""
                              allowFileTypeValidation="false"
                              :server="{ process, revert, restore, load, fetch }"
                              @removefile="handleRemoveFile"
                              @processfile="processFile"
                              v-on:addfile="handleFilePondAddFile"
                            />
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeNewFolder">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-divider v-if="isDirOpen" class="mb-5"></v-divider>
          <v-row>
            <v-col md="5" class="files--column">
              <h4 class="grey--text text--darken-3 title">Files</h4>
              <v-card v-if="noFiles" color="grey lighten-5" class="grey--text text--darken-3 mx-4 mt-3">
                <v-card-text>
                  <h4 class="grey--text text--darken-3 subheading text-md-center px-2">No Files in selected folder</h4>
                </v-card-text>
              </v-card>
              <v-list subheader class="pt-3" dense>
                <v-list-item v-for="child in childrenList" :key="child.key" class="">
                  <v-list-item-content v-bind:class="{ selected: selectedChild === child }" class="text-start py-1" @click="imageDetails(child)">
                    <v-list-item-title class="py-0 subtitle-1 font-weight-regular">{{ child }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon v-bind:class="{ selected: selectedChild === child }" class="mx-0 py-2">
                    <v-icon color="red" @click="deleteImage(child)">mdi-delete</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="7" class="text-md-center">
              <div v-if="isDirOpen" class="pb-4">
                <v-card color="grey lighten-5" class="grey--text text--darken-3">
                  <v-card-title primary-title class="pb-0">
                    <div class="title">File Path</div>
                  </v-card-title>
                  <div class="px-2 pb-2">
                    <v-text-field id="routeName" v-model="selectedFilePath" hint="Selected File Path" type="text"></v-text-field>
                  </div>
                </v-card>
              </div>
              <div v-if="isDirOpen" class="add-new-file">
                <v-card color="grey lighten-5" class="grey--text text--darken-3">
                  <v-card-title primary-title class="pb-0">
                    <div class="title grey--text text--darken-3">Add new file</div>
                  </v-card-title>
                  <div class="pb-1">
                    <v-alert v-model="extensionAlert" type="error" dismissible> File extension must be .psd! </v-alert>
                    <v-alert v-model="updateAlert" type="success" dismissible> File upload succefully! </v-alert>
                    <v-alert v-model="updateAlertError" type="error" dismissible> Error on upload image! </v-alert>
                  </div>
                  <div class="custom-width pl-3 pb-2">
                    <file-pond
                      ref="pond"
                      name="psd_image"
                      label-idle="Drop files here or Browse"
                      allow-multiple="true"
                      accepted-file-types=""
                      allowFileTypeValidation="false"
                      allowImageTransform="false"
                      :server="{ process, revert, restore, load, fetch }"
                      @removefile="handleRemoveFile"
                      @processfile="processFile"
                      v-on:addfile="handleFilePondAddFile"
                    />
                  </div>
                </v-card>
              </div>
              <div v-if="isDirOpen" class="mt-4 pb-2">
                <v-card color="grey lighten-5" class="grey--text text--darken-3 pb-2">
                  <v-card-title primary-title class="pb-2">
                    <div class="title grey--text text--darken-3">Templates</div>
                  </v-card-title>
                  <div v-if="progressIsLoading" class="px-3">
                    <v-progress-linear :indeterminate="true"></v-progress-linear>
                  </div>
                  <div v-if="showTemplateContent" class="text-sm-left">
                    <p v-if="!templateFound" class="pl-3">File is not found in any template</p>
                    <p v-else class="pl-2">
                      File found in template name <b>{{ templateName }}</b> as <b>{{ templatePath }} path</b>
                    </p>
                    <div v-if="!templateFound" class="pl-2">
                      <p class="pl-2">Add file as:</p>
                      <v-radio-group v-model="radios" :mandatory="false">
                        <v-radio label="Front path" value="front"></v-radio>
                        <v-radio label="Back path" value="back"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <div v-if="showTemplateContent" class="pb-3">
                    <v-btn v-if="!templateFound" rounded small outlined color="primary" class="py-1" @click="addToNewTemplate">
                      <span>Add to New template</span>
                    </v-btn>
                    <v-btn v-else rounded small outlined color="primary" @click="goToEditTemplate">Edit template</v-btn>
                  </div>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

// import * as pathSort from 'path-sort'
/*
Documentation:
- mdi incons: https://materialdesignicons.com/cdn/2.0.46/

 */
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import UploadButton from 'vuetify-upload-button';
// Import Vue FilePond
import vueFilePond from 'vue-filepond'; // { setOptions }

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
  name: 'fileExplorer',
  components: {
    FilePond,
    ConfirmationDialog
  },
  data() {
    return {
      deleteImageText: 'image',
      folderName: '',
      deleteMessage: '',
      deleteAlert: false,
      deleteFlag: false,
      deletePathAlert: false,
      extensionCheck: false,
      extensionAlert: false,
      isExtensionAlertRootFolder: false,
      timestamp: '',
      updateAlert: false,
      updateAlertError: false,
      loadingDialog: false,
      selectedFilePath: '',
      onlyChildInFolder: false,
      noFiles: false,
      dialogFolder: false,
      newFolderName: '',
      emptyFolderAlert: false,
      updateAlertNewFolder: false,
      updateAlertErrorNewFolder: false,
      isDialogRootFolder: false,
      newRootFolderName: '',
      isUpdateAlertNewRootFolder: false,
      isUpdateAlertErrorNewRootFolder: false,
      isEmptyRootFolderAlert: false,
      isCreateRootDirMarker: false,
      templateName: null,
      templatePath: null,
      templateFound: false,
      templateId: null,
      progressIsLoading: false,
      showTemplateContent: false,
      filePathToAddToTemplate: '',
      radios: 'front',
      open: [],
      files: {
        html: 'mdi-language-html5',
        js: 'mdi-nodejs',
        json: 'mdi-json',
        md: 'mdi-markdown',
        pdf: 'mdi-file-pdf',
        png: 'mdi-file-image',
        txt: 'mdi-file-document-outline',
        xls: 'mdi-file-excel',
        psd: 'mdi-file-image'
      },
      paths: null,
      tree: [],
      active: [],
      users: [],
      childrenInDirectory: [],
      childrenList: [],
      isDeleteDialogOpen: false,
      isDirOpen: false,
      selectedPath: '',
      imageName: '',
      pathToDelete: '',
      selectedChild: '',
      theList: [],
      apiKey: process.env.VUE_APP_DESIGN_LIVE_API_KEY
    };
  },
  computed: {
    items() {
      return this.users;
    },
    ...mapGetters('design_live', {
      listOfDesignLivePath: 'designsList',
      childrenInDirectoryVuex: 'childrenInDirectory'
    })
  },
  watch: {
    listOfDesignLivePath(value) {
      this.theList = value;
      this.getDesignList();
    }
  },
  async created() {
    this.$store.dispatch('showLoader', { message: 'Files and folders tree loading ...' });
    this.loadingDialog = true;
    await this.$store.dispatch('design_live/initRealtimeListenersForPaths');
    await this.$store.dispatch('design_live/loadDesignLiveListToFirebase');
    await this.$store.dispatch('design_live/listDesigns').then(response => {
      this.theList = response.data;
      this.getDesignList();
    });
  },
  methods: {
    refresh() {
      console.log('refresh');
      this.loadingDialog = true;
      this.$store.dispatch('showLoader', { message: 'Files and folders tree loading ...' });
      this.$store.dispatch('design_live/loadDesignLiveListToFirebase').then(response => {
        console.log('--response:', response);
        if (response.data === true) {
          this.$store.dispatch('design_live/listDesigns').then(response => {
            this.theList = response.data;
            this.getDesignList();
          });
        } else {
          console.log('Error on write design live data on DB');
        }
      });
    },
    closeNewFolder() {
      this.dialogFolder = false;
      this.newFolderName = '';
      this.emptyFolderAlert = false;
      this.updateAlertErrorNewFolder = false;
      this.updateAlertNewFolder = false;
      this.$refs.pond.removeFile();
    },
    closeDialog() {
      this.isDeleteDialogOpen = false;
    },
    closeCreateRootFolder() {
      this.isCreateRootDirMarker = false;
      this.isDialogRootFolder = false;
      this.isExtensionAlertRootFolder = false;
      this.isUpdateAlertNewRootFolder = false;
      this.isUpdateAlertErrorNewRootFolder = false;
      this.isEmptyRootFolderAlert = false;
      this.newRootFolderName = '';
      this.$refs.pond.removeFile();
    },
    getDesignList() {
      this.loadingDialog = false;
      const data = this.theList;
      this.loadingDialog = false;
      this.$store.dispatch('hideLoader');
      let rootArray = data;
      let firstDirArray = [];
      let rootFiles = [];
      let rootDirAndFiles = [];
      let firstDirList = [];
      let secondDirlist = [];
      let thirdDirlist = [];
      data.forEach((value, index) => {
        const test = value.split('/');

        if (test.length > 1) {
          firstDirList.push(test[0]);
        }
        if (test.length > 3) {
          const pathElement = `${test[0]}/${test[1]}`;
          const path3Elements = `${test[0]}/${test[1]}/${test[2]}`;
          secondDirlist.push(pathElement);
          thirdDirlist.push(path3Elements);
        }
        if (test.length === 2) {
          firstDirArray.push(test[0]);
          const element = `${test[0]}/${test[1]}`;
          rootDirAndFiles.push({ element });
        } else if (test.length > 2) {
          let path = '';
          // console.log(test.length)
          let dirLength = test.length - 1;
          test.forEach((value, index) => {
            if (index < dirLength) {
              path = path + '/' + value;
            }
          });
          path = path.substring(1);
          firstDirArray.push(path);
        } else {
          rootFiles.push({
            name: test[0],
            file: 'psd'
          });
        }
      });

      // first Directory list
      let uniqueFirstDir = [...new Set(firstDirList)];

      // second Directory list
      let uniqueSecondDir = [...new Set(secondDirlist)];

      // Third Directory List
      let uniqueThirdDir = [...new Set(thirdDirlist)];

      let unique = [...new Set(firstDirArray)];

      // concatenate Dir's with
      const concatUnique1 = unique.concat(uniqueFirstDir);
      const concatUnique2 = concatUnique1.concat(uniqueSecondDir);
      const concatUnique3 = concatUnique2.concat(uniqueThirdDir);

      // unique list
      let uniqueConcatList = [...new Set(concatUnique3)];

      let uniqueComplete = [];
      uniqueConcatList.forEach((value, index) => {
        const path = value + '/';
        uniqueComplete.push(path);
      });

      let rootDir = [];
      let parentArray = [];
      unique.forEach((value1, index1) => {
        var test = value1.split('/');
        if (test.length === 1) {
          rootDir.push(value1);
        } else if (test.length > 1) {
          parentArray.push(value1);
        }
      });

      let parentKeys = [];
      parentArray.forEach((value1, index1) => {
        var test = value1.split('/');
        parentKeys.push(test[0]);
      });

      // create array for folder - children pair
      let parentDir = [];
      let childrenFiles = [];

      parentArray.forEach((value, index) => {
        data.forEach((value1, index1) => {
          var test = value1.split('/');
          if (test.length === 3) {
            const path = `${test[0]}/${test[1]}`;
            if (value === path) {
              childrenFiles.push(test[2]);
            }
          }
          if (test.length === 4) {
            const path1 = `${test[0]}/${test[1]}/${test[2]}`;
            if (value === path1) {
              childrenFiles.push(test[3]);
            }
          }
          if (test.length === 5) {
            const path2 = `${test[0]}/${test[1]}/${test[2]}/${test[3]}`;
            if (value === path2) {
              childrenFiles.push(test[4]);
            }
          }
        });
        parentDir.push({
          name: value,
          list: childrenFiles
        });
        childrenFiles = [];
      });

      let uniqueParentKeys = [...new Set(parentKeys)];
      let createArrayKeyValuePair = [];
      let createArrayKeyValuePair1 = [];

      uniqueParentKeys.forEach((value1, index1) => {
        parentArray.forEach((value2, index2) => {
          var test = value2.split('/');
          if (value1 === test[0]) {
            createArrayKeyValuePair.push(test[1]);
          }
        });
        createArrayKeyValuePair1.push({
          name: value1,
          list: createArrayKeyValuePair
        });
        createArrayKeyValuePair = [];
      });

      // same logic as above for root
      let createArrayKeyValuePairRoot = [];
      let createArrayKeyValuePairRoot1 = [];

      rootDir.forEach((value1, index1) => {
        rootDirAndFiles.forEach((value2, index2) => {
          var test = value2.element.split('/');
          if (value1 === test[0]) {
            createArrayKeyValuePairRoot.push(test[1]);
          }
        });
        createArrayKeyValuePairRoot1.push({
          name: value1,
          list: createArrayKeyValuePairRoot
        });
        createArrayKeyValuePairRoot = [];
      });

      let filesInFolder = [];
      let filesInFolder2 = [];

      // for first level of folder  with folder(s) and files
      // create key value pair array (only for files):
      // key - folder name
      // value -  array of files (of folder)
      createArrayKeyValuePair1.forEach((value1, index1) => {
        createArrayKeyValuePairRoot1.forEach((value2, index2) => {
          // check if folder has files not only folders
          if (value1.name === value2.name) {
            const fileChildren = value2.list;

            fileChildren.forEach((value5, index5) => {
              filesInFolder.push(value5);
            });
            filesInFolder2.push({
              name: value2.name,
              list: filesInFolder
            });
            filesInFolder = [];
            createArrayKeyValuePairRoot1.splice(index2, 1);
          }
        });
      });

      let filesInRoot = [];
      let filesInRoot2 = [];
      createArrayKeyValuePairRoot1.forEach((value2, index2) => {
        const children = value2.list;
        children.forEach((value4, index4) => {
          filesInRoot.push(value4);
        });
        filesInRoot2.push({
          name: value2.name,
          list: filesInRoot
        });
        filesInRoot = [];
      });

      let concat1 = parentDir.concat(filesInRoot2);
      let concat2 = filesInFolder2.concat(concat1);
      this.childrenInDirectory = concat2;
      let s1 = rootArray.concat(uniqueComplete);
      let s2 = s1.sort();

      this.paths = s2;
      // build tree
      var tree = this.buildTree();
      let files = [];
      let folders = [];
      tree.forEach((value, index) => {
        if (value.parent === undefined) {
          files.push(value);
        } else {
          folders.push(value);
        }
      });

      // initial root files loaded
      let rootChildren = [];
      files.forEach((value, index) => {
        rootChildren.push(value.name);
      });
      // put files at last
      const finalTree = folders.concat(files);
      this.users = finalTree;
      /* -- END Function -- */
    },
    compareValues(key, order = 'asc') {
      return function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }

        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order === 'desc' ? comparison * -1 : comparison;
      };
    },
    openDialog(item) {
      console.log('open dialog', item);
    },
    openRootDialog() {
      this.isDialogRootFolder = true;
      this.isCreateRootDirMarker = true;
    },
    // Find sub paths
    findSubPaths(path) {
      // slashes need to be escaped when part of a regexp
      var rePath = path.replace('/', '\\/');
      var re = new RegExp('^' + rePath + '[^\\/]*\\/?$');
      return this.paths.filter(function (i) {
        return i !== path && re.test(i);
      });
    },
    // Extract a filename from a path
    getFilename(path) {
      return path
        .split('/')
        .filter(function (value) {
          return value && value.length;
        })
        .reverse()[0];
    },
    buildTree(path) {
      path = path || '';
      var nodeList = [];
      this.findSubPaths(path).forEach(subPath => {
        var nodeName = this.getFilename(subPath);
        if (/\/$/.test(subPath)) {
          var node = {};
          node['name'] = nodeName;
          node['parent'] = path;
          node['children'] = this.buildTree(subPath);
          nodeList.push(node);
        } else {
          var obj = { name: nodeName, file: 'psd' };
          nodeList.push(obj);
        }
      });
      return nodeList;
    },
    folder(item) {
      // console.log(item)
      this.showTemplateContent = false;
      this.selectedFilePath = '';
      this.onlyChildInFolder = false;
      const childrenArray = item.children;
      // check if children folder contains file ,
      // count number of file
      // if not contain files & number of files is 0 => folder is empty of files
      let isFolderEmpty = false;
      let i = 0;
      childrenArray.forEach((value, index) => {
        if (value.file === undefined) {
          isFolderEmpty = true;
        }
        if (value.file === 'psd') {
          i++;
        }
      });
      if (isFolderEmpty && i === 0) {
        this.childrenList = [];
        this.noFiles = true;
      }

      if (item.parent) {
        this.isDirOpen = true;
        const path = item.parent + item.name;
        this.selectedPath = path;
        this.childrenInDirectory.forEach((value, index) => {
          if (value.name === path) {
            this.noFiles = false;
            this.childrenList = value.list;
            // check if is only one file in folder, if the only file is deleted also de folder is deleted
            this.onlyChildInFolder = value.list.length === 1;
          }
        });
      } else {
        this.isDirOpen = true;
        const path = item.name;
        this.selectedPath = path;
        this.childrenInDirectory.forEach((value, index) => {
          if (value.name === path) {
            this.noFiles = false;
            this.childrenList = value.list;
            // check if is only one file in folder, if the only file is deleted also de folder is deleted
            this.onlyChildInFolder = value.list.length === 1;
          }
        });
      }
    },
    imageDetails(child) {
      this.selectedChild = child;
      this.selectedFilePath = `${this.selectedPath}/${child}`;
      const filePath = `${this.selectedPath}/${child}`;
      this.filePathToAddToTemplate = filePath;
      this.showTemplateContent = true;
      this.progressIsLoading = true;
      this.$store.dispatch('design_live/checkFileInTemplate', { filePath: filePath }).then(response => {
        this.progressIsLoading = false;
        if (response) {
          this.templateFound = true;
          this.templatePath = response.path;
          this.templateName = response.content.name;
          this.templateId = response.id;
        } else {
          this.templateFound = false;
        }
      });
    },
    addToNewTemplate() {
      const selectedPath = this.selectedFilePath;
      const radioPath = this.radios;
      this.$store.dispatch('design_live/setPath', { url: selectedPath, path: radioPath });
      this.$router.push({ path: 'dl-templates/new' });
    },
    goToEditTemplate() {
      const templateId = this.templateId;
      this.$router.push({ path: `/dl-templates/edit/${templateId}` });
    },
    deleteImage(item) {
      this.imageName = item;
      this.isDeleteDialogOpen = true;
      this.pathToDelete = `${this.selectedPath}/${item}`;
    },
    confirmDelete() {
      const path = this.pathToDelete;
      this.$store.dispatch('design_live/deletePath', { path: path, apiKey: this.apiKey }).then(result => {
        this.isDeleteDialogOpen = false;

        if (result.statusText) {
          this.deleteMessage = 'Image deleted successfully!';
          this.deleteAlert = true;
          this.deleteFlag = false;
          this.childrenList.forEach((value, index) => {
            if (value === this.imageName) {
              this.childrenList.splice(index, 1);
            }
          });
          // reload the file explorer
          this.$store.dispatch('design_live/loadDesignLiveListToFirebase');
          this.getDesignList();
        } else {
          this.deleteFlag = true;
          this.deleteAlert = true;
          this.deleteMessage = `Error on delete: ${result}`;
        }
      });
    },
    handleFilePondAddFile(err, file) {
      const fileExtension = file.fileExtension;
      if (fileExtension === 'psd') {
        // file type allowed
        this.extensionCheck = false;
      } else {
        // file type NO allowed
        this.extensionCheck = true;
        if (!this.isCreateRootDirMarker) {
          this.extensionAlert = true;
        } else {
          this.isExtensionAlertRootFolder = true;
        }
      }
    },
    handleRemoveFile(file) {
      console.log(`Remove: file has terminated`, file);
    },
    processFile(e, file) {
      console.log(`ProcessFile: file has terminated`, file.filename);
    },
    process(fieldName, file, metadata, load, error, progress, abort) {
      // documentation: https://customerscanvas.com/docs/cc/file-manipulation-api.htm
      this.timestamp = Number(new Date());
      // new_psd_image
      if (this.selectedPath) {
        if (this.extensionCheck) {
          abort();
          this.handleRemoveFile(file);
        } else {
          if (fieldName === 'psd_image') {
            const fullFileName = file.name;
            const splitFilename = fullFileName.split('.');
            const fileName = splitFilename[0];
            let fileExist = false;
            let lastSavedFileIndex = 0;
            let filenameExist = '';
            this.childrenList.forEach((value, index) => {
              if (value === fileName) {
                filenameExist = fileName;
                fileExist = true;
              }
              const splitFilename = value.split(fileName + '_');
              // test if exist duplicate
              if (splitFilename.length > 1) {
                const strgToInteger = parseFloat(splitFilename[1]);
                if (!isNaN(strgToInteger)) {
                  lastSavedFileIndex = strgToInteger + 1;
                }
              }
            });
            if (fileExist && lastSavedFileIndex === 0) {
              lastSavedFileIndex++;
            }

            this.$store
              .dispatch('design_live/updateFileOnDMM', {
                file: file,
                fieldName: fieldName,
                timestamp: this.timestamp,
                folder: this.selectedPath,
                apiKey: this.apiKey
              })
              .then(response => {
                const fullFileName = file.name;
                const splitFilename = fullFileName.split('.');
                const fileName = splitFilename[0];
                let filenameToInsert = '';
                if (response.data) {
                  // check if file exist and last index(in case multiple of same file exist)
                  // if exist push filename_x
                  if (fileExist) {
                    console.log('Exist', filenameExist, '|', lastSavedFileIndex);
                    filenameToInsert = `${fileName}_${lastSavedFileIndex}`;
                  } else {
                    console.log('NNOT Exist');
                    filenameToInsert = fileName;
                  }
                  this.$store.dispatch('design_live/loadDesignLiveListToFirebase');
                  // this.getDesignList()

                  this.childrenList.push(filenameToInsert);
                  this.updateAlert = true;
                  load();
                } else {
                  console.log('Filename error:', response);
                  this.updateAlertError = true;
                  abort();
                }
              })
              .catch(e => {
                console.log(e);
                abort();
              });
          }
          if (fieldName === 'new_psd_image1') {
            const newFolderNameToLowercase = this.newFolderName.toLowerCase();
            const fileredNewFolderName = this.removeSpaceInName(newFolderNameToLowercase);
            const newFolderNameToAdd = `${this.selectedPath}/${fileredNewFolderName}`;

            if (this.newFolderName) {
              this.emptyFolderAlert = false;
              this.$store
                .dispatch('design_live/updateFileOnDMM', {
                  file: file,
                  fieldName: fieldName,
                  timestamp: this.timestamp,
                  folder: newFolderNameToAdd,
                  apiKey: this.apiKey
                })
                .then(response => {
                  if (response.data) {
                    load();
                    this.updateAlertNewFolder = true;
                    // reload the file explorer
                    this.getDesignList();
                    this.$store.dispatch('design_live/loadDesignLiveListToFirebase');
                  } else {
                    console.log('Filename error:', response);
                    this.updateAlertErrorNewFolder = true;
                    abort();
                  }
                })
                .catch(e => {
                  console.log(e);
                  abort();
                });
            } else {
              abort();
              this.emptyFolderAlert = true;
            }
          }
        }
      } else if (fieldName === 'new_root_psd_image') {
        if (this.extensionCheck) {
          abort();
          this.handleRemoveFile(file);
        } else {
          if (this.newRootFolderName) {
            this.isEmptyRootFolderAlert = false;
            this.$store
              .dispatch('design_live/updateFileOnDMM', {
                file: file,
                fieldName: fieldName,
                timestamp: this.timestamp,
                folder: this.newRootFolderName,
                apiKey: this.apiKey
              })
              .then(response => {
                if (response.data) {
                  load();
                  this.isUpdateAlertNewRootFolder = true;

                  // reload the file explorer
                  this.getDesignList();
                  this.$store.dispatch('design_live/loadDesignLiveListToFirebase');
                } else {
                  console.log('Filename error:', response);
                  this.isUpdateAlertErrorNewRootFolder = true;
                  abort();
                }
              })
              .catch(e => {
                console.log(e);
                abort();
              });
          } else {
            abort();
            this.isEmptyRootFolderAlert = true;
          }
        }
      } else {
        this.deletePathAlert = true;
        abort();
      }
    },
    load(uniqueFileId, load, error) {
      // error();
    },

    fetch(url, load, error, progress, abort, headers) {
      error('Local files only');
    },

    restore(uniqueFileId, load, error, progress, abort, headers) {
      // error()
    },

    revert(uniqueFileId, load, error) {
      // load()
    },

    removeSpaceInName(value) {
      return value.replace(/ /g, '_');
    }
  }
};
</script>
<style>
.custom-height {
  height: 34px !important;
}

.custom-height .v-list__tile--avatar {
  height: 40px !important;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 2px;
  padding-right: 0px;
  margin-left: 6px;
  margin-right: 2px;
}

.custom-width {
  margin-top: 20px !important;
  width: 60%;
  /*margin: 0 auto;*/
}

.custom-node a {
  color: black !important;
}

.custom-layout {
  border: 1px solid lightgray;
}

.selected {
  background: #ccc;
  padding-left: 2px;
  /*padding-right: 6px;*/
}

.files--column .v-list--dense .v-list-item .v-list-item__content {
  cursor: pointer;
}

.theme--dark .v-treeview.darkmode-bg.theme--dark {
  background: transparent !important;
}

.theme--dark.v-application .row.custom-row .grey--text.text--darken-3 {
  color: #fff !important;
}

.theme--dark .theme--dark a {
  color: #2196f3 !important;
  color: #fff !important;
}
</style>
