<template>
  <div v-if="!isLoading">
    <v-container fluid>
      <v-card>
        <v-card-title>
          <h3>Customer</h3>
          <v-spacer></v-spacer>
          <v-btn v-if="ospreyCustomer && ospreyCustomer.customerID" color="success" @click="createCustomer()">Create new customer</v-btn>
        </v-card-title>
        <template v-if="customer">
          <v-card-text>
            <v-layout>
              <v-flex xs6 sm6>
                <ul>
                  <li class="mb-2">
                    <v-layout row wrap>
                      <v-flex xs6 sm6>
                        <span class="font-weight-medium grey--text text--darken-1">First name</span>
                      </v-flex>
                      <v-flex xs6 sm6>
                        <span>{{ customer.first_name }}</span>
                      </v-flex>
                    </v-layout>
                  </li>
                  <li class="mb-2">
                    <v-layout row wrap>
                      <v-flex xs6 sm6>
                        <span class="font-weight-medium grey--text text--darken-1">Last name</span>
                      </v-flex>
                      <v-flex xs6 sm6>
                        <span>{{ customer.last_name }}</span>
                      </v-flex>
                    </v-layout>
                  </li>
                  <li class="mb-2">
                    <v-layout row wrap>
                      <v-flex xs6 sm6>
                        <span class="font-weight-medium grey--text text--darken-1">Email</span>
                      </v-flex>
                      <v-flex xs6 sm6>
                        <span>{{ customer.email }}</span>
                      </v-flex>
                    </v-layout>
                  </li>
                  <li class="mb-2">
                    <v-layout row wrap>
                      <v-flex xs6 sm6>
                        <span class="font-weight-medium grey--text text--darken-1">Firebase ID</span>
                      </v-flex>
                      <v-flex xs6 sm6>
                        <span>{{ customer.id }}</span>
                      </v-flex>
                    </v-layout>
                  </li>
                  <li class="mb-2">
                    <v-layout row wrap>
                      <v-flex xs6 sm6>
                        <span class="font-weight-medium grey--text text--darken-1">CRM Customer ID</span>
                      </v-flex>
                      <v-flex xs6 sm6>
                        <span>{{ customer.crm_customer_id }}</span>
                      </v-flex>
                    </v-layout>
                  </li>
                  <li class="mb-2">
                    <v-layout row wrap>
                      <v-flex xs6 sm6>
                        <span class="font-weight-medium grey--text text--darken-1">Stripe ID</span>
                      </v-flex>
                      <v-flex xs6 sm6>
                        <span>{{ customer.stripe_customer }}</span>
                      </v-flex>
                    </v-layout>
                  </li>
                </ul>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions> </v-card-actions>
        </template>
      </v-card>
      <v-card v-if="customer" class="mt-2">
        <v-card-title>
          <h3>Payment methods</h3>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="addPaymentDialogIsOpen = true">Add payment method</v-btn>
        </v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex xs6 sm6>
              <ul v-for="(payment_method, index) in payment_methods" :key="'payment_method_' + index" class="stamp-od-ul mt-2">
                <li class="mb-2">
                  <v-layout row wrap>
                    <v-flex xs6 sm6>
                      <span>Type</span>
                    </v-flex>
                    <v-flex xs6 sm6>
                      <span class="font-weight-medium grey--text text--darken-1">{{ payment_method.card_type }}</span>
                    </v-flex>
                  </v-layout>
                </li>
                <li class="mb-2">
                  <v-layout row wrap>
                    <v-flex xs6 sm6>
                      <span>Firebase ID</span>
                    </v-flex>
                    <v-flex xs6 sm6>
                      <span class="font-weight-medium grey--text text--darken-1">{{ payment_method.id }}</span>
                    </v-flex>
                  </v-layout>
                </li>
                <li class="mb-2">
                  <v-layout row wrap>
                    <v-flex xs6 sm6>
                      <span>Stripe ID</span>
                    </v-flex>
                    <v-flex xs6 sm6>
                      <span class="font-weight-medium grey--text text--darken-1">{{ payment_method.stripe_card }}</span>
                    </v-flex>
                  </v-layout>
                </li>
                <li class="mb-2">
                  <v-layout row wrap>
                    <v-flex xs6 sm6>
                      <span>Last four</span>
                    </v-flex>
                    <v-flex xs6 sm6>
                      <span class="font-weight-medium grey--text text--darken-1">{{ payment_method.last_four }}</span>
                    </v-flex>
                  </v-layout>
                </li>
                <li class="mb-2">
                  <v-layout row wrap>
                    <v-flex xs6 sm6>
                      <span>Expiration</span>
                    </v-flex>
                    <v-flex xs6 sm6>
                      <span class="font-weight-medium grey--text text--darken-1">{{ payment_method.exp_year }}, {{ payment_method.exp_month }}</span>
                    </v-flex>
                  </v-layout>
                </li>
                <li class="mb-2">
                  <v-layout row wrap>
                    <v-flex xs6 sm6>
                      <span>Default</span>
                    </v-flex>
                    <v-flex xs6 sm6>
                      <span class="font-weight-medium grey--text text--darken-1">{{ payment_method.is_default ? 'Yes' : 'No' }}</span>
                    </v-flex>
                  </v-layout>
                </li>
                <v-divider></v-divider>
              </ul>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-container>

    <AddPaymentMethodDialog
      v-if="customer"
      :open="addPaymentDialogIsOpen"
      :customer="customer"
      @addPaymentDialogVisibilityChanged="addPaymentDialogVisibilityChanged"
      @paymentMethodAdded="paymentMethodAdded"
    />
  </div>
</template>

<script>
import AddPaymentMethodDialog from '../stripe/dialogs/AddPaymentMethodDialog';
import { mapGetters } from 'vuex';

export default {
  components: {
    AddPaymentMethodDialog
  },
  props: ['crm_customer_id', 'crm_order_id'],
  data() {
    return {
      addPaymentDialogIsOpen: false,
      ospreyCustomer: null
    };
  },
  computed: {
    ...mapGetters('customer_legacy', {
      customer: 'getCustomerSearch'
    }),
    isStripeCustomerExists() {
      return !!this.customer?.stripe_customer ?? false;
    },
    isLoading() {
      return this.$store.getters.getIsLoading || false;
    },
    payment_methods() {
      return this.customer?.payment_methods || [];
    }
  },
  watch: {
    isStripeCustomerExists(exists) {
      this.$emit('changedIsStripeCustomerExists', exists);
    }
  },
  mounted() {
    this.getCustomerByCrmId();
  },
  methods: {
    getCustomerByCrmId() {
      this.$store.dispatch('showLoader', { message: 'Searching customer...' });
      return this.$store
        .dispatch('customer_legacy/getCustomerByCrmId', { crm_customer_id: this.crm_customer_id })
        .catch(async error => {
          console.log('[ getCustomerByCrmId ] ERROR:', error);
          await this.getCustomerFromOspreyByCrmId();
        })
        .finally(() => {
          this.$store.dispatch('hideLoader');
        });
    },
    getCustomerFromOspreyByCrmId() {
      return this.$store
        .dispatch('customer_legacy/getCustomerFromOspreyByCrmId', { osprey_id: this.crm_customer_id })
        .then(result => {
          console.log('[ getCustomerFromOspreyByCrmId ] RESULT: ', result);
          if (result?.data?.customer) {
            this.ospreyCustomer = result.data.customer;
            console.log('this.ospreyCustomer: ', this.ospreyCustomer);
          } else {
            this.$emit('customerSearchResultError', 'Error while getting the Osprey customer!');
          }
          return Promise.resolve();
        })
        .catch(error => {
          console.log('[ getCustomerFromOspreyByCrmId ] ERROR:', error);
          this.$emit('customerSearchResultError', error?.response?.data ?? 'Unexpected error occured while charging the order!');
          this.message = error?.response?.data ?? error;
          return Promise.reject(error);
        });
    },
    addPaymentDialogVisibilityChanged(isOpen) {
      this.addPaymentDialogIsOpen = isOpen;
    },
    async paymentMethodAdded() {
      this.addPaymentDialogIsOpen = false;
      await this.getCustomerByCrmId();
    },
    createCustomer() {
      if (this.ospreyCustomer) {
        this.$store.dispatch('showLoader', { message: 'Creating customer...' });
        this.$store
          .dispatch('customer_legacy/createCustomer', {
            email: this.ospreyCustomer.CustomerEmail,
            first_name: this.ospreyCustomer.aFirst,
            last_name: this.ospreyCustomer.aLast,
            crm_customer_id: this.ospreyCustomer.customerID,
            brand_id: this.ospreyCustomer.webID,
            firebase_site_id: this.ospreyCustomer.firebase_site_id,
            wp_user_id: this.ospreyCustomer.wp_user_id,
            address: this.ospreyCustomer.CustomerAddress,
            address2: this.ospreyCustomer.customerAddress2,
            city: this.ospreyCustomer.CustomerCity,
            country: this.ospreyCustomer.CustomerCountry,
            state: this.ospreyCustomer.CustomerState,
            postal_code: this.ospreyCustomer.CustomerZip,
            phone: this.ospreyCustomer.CustomerPhone
          })
          .then(response => {
            console.log('Create customer response: ', response);
            if (!response?.data?.Data?.Customer) {
              this.$emit('customerSearchResultError', 'Unexpected error occured while creating customer!');
            }
            return this.getCustomerByCrmId();
          })
          .catch(error => {
            console.log('[ createCustomer ] ERROR:', error);
            this.$emit('customerSearchResultError', error?.response?.data ?? 'Unexpected error occured while creating customer!');
            return Promise.reject(error);
          })
          .finally(() => this.$store.dispatch('hideLoader'));
      }
    }
  }
};
</script>
