<template>
  <div class="site-table">
    <v-dialog v-model="isFinishUpdateDialogVisible" max-width="500px">
      <v-card>
        <v-card-title class="justify-center">
          <v-icon color="success">mdi-checkbox-marked-circle-outline</v-icon>
          <span color="success" class="ml-2">SUCCESS!</span>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <div class="pt-5">
            CSV File successfully saved to Fireabase Storage in folder
            <a :href="firestoreStorageLink">{{ storageFolderPath }}.</a>
          </div>
          <div class="pt-2">
            Filename: <b> {{ storageFileName }} </b>
          </div>
          <div class="pt-2">
            CSV Line Count: <b> {{ csvLineCount }} </b>
          </div>
          <v-row class="justify-center">
            <div class="mt-4">
              <a v-bind:href="downloadCsvUrl">Download CSV <v-icon>mdi-download</v-icon></a>
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text class="mr-2" @click="setFinishUpdateDialogVisibility(false)">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isDeleteDialogVisible" persistent max-width="400">
      <v-card>
        <v-card-title class="headline red">
          <div class="text-xs-center">
            <span class="white--text text-md-center">Warning!</span>
          </div>
        </v-card-title>
        <v-card-text>
          <p class="mt-3 subheader text-center">Are you sure you want to delete this list?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="setDeleteDialogVisibility(false)">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="confirmDelete">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isDeleteNotAllowed" width="500">
      <v-card class="delete-alert">
        <v-card-title>
          <v-icon large color="red" class="mt-n4 mr-2">mdi-alert-outline</v-icon>
          <p class="text-center">Not allowed! This list is related to an order!</p>
        </v-card-title>
        <v-card-text class="text-center mt-n3">
          <span class="subtitle-1">Order ID:</span>
          <a :href="`/orders/view/${relatedOrderId}`" target="_blank" class="subtitle-1 ml-2 mt-n1">{{ relatedOrderId }}</a>
        </v-card-text>
        <v-row class="justify-end mr-1 pb-1 mt-n3"><v-btn text @click="setDeleteNotAllowedState(false)">OK</v-btn></v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isDeleteRaportDialogVisible" persistent max-width="600">
      <v-card>
        <v-card-title class="headline blue"><span class="white--text">Delete raport summary</span></v-card-title>
        <v-card-text>
          <ul class="mt-4">
            <template v-for="item in reportListDisplay">
              <li v-if="item.key" :key="item.key" class="my-2">
                Delete {{ item.key }} ...
                <span v-if="item.value === 'success'" class="blue--text"><v-icon color="blue">mdi-check</v-icon></span>
                <span v-else><v-icon color="red">mdi-close-circle-outline</v-icon></span>
              </li>
            </template>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDeleteRaportDialog()">close</v-btn>
        </v-card-actions>
        <v-progress-linear v-if="isDeleteProcessing" :indeterminate="isDeleteProcessing" class="my-0"></v-progress-linear>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isGenerateCsvDialogVisible" max-width="500px">
      <v-card>
        <v-card-title><v-icon large color="warning">mdi-alert</v-icon><span class="headline ml-2">Warning</span></v-card-title>
        <v-card-text class="subtitle-1">
          Are you sure want to download the requested information?
          <span v-if="isWarningMessageVisible">Please note that this operation incurs costs on our integration with Melissa Data.</span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            :class="`ma-1 ${focusedCancelBtnClass}`"
            @mouseover="setConfirmButtonProperty('agree', true)"
            @mouseleave="setConfirmButtonProperty('agree', false)"
            @click="setGenerateCsvDialogVisibility(false)"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            text
            :class="`ma-1 ${focusedAgreeBtnClass}`"
            @mouseover="setConfirmButtonProperty('agree', true)"
            @mouseleave="setConfirmButtonProperty('agree', false)"
            @click="downloadAgreeded()"
            >I am sure</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <template>
        <v-toolbar flat class="site-table--header site-table--lists">
          <v-col class="pl-0">
            <v-toolbar-title class="headline">
              <a class="black--text" @click="clearDate()">Lists</a>
            </v-toolbar-title>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details @keydown="searchKeydown()"></v-text-field>
            <p v-if="isMinLenghSeachValueShown" class="caption mt-2">Please enter min 3 characters to begin searching!</p>
          </v-col>
          <!-- /SEARCH  -->
          <stamp-date-filters
            v-if="isDataLoadedFromUrl"
            :startDate="selectedStartDate"
            :endDate="selectedEndDate"
            :timeStampAttrName="timeStampAttrName"
            :isFiltersCleared="isFiltersCleared"
            @onRouteHandlerChanged="onRouteHandlerChanged"
            @onDateFilterChanged="onDateFilterChanged"
            @onDateSelectedStateChanged="onDateSelectedStateChanged"
            @onDateCleared="clearDate"
            @onWrongFilter="onWrongFilter"
          ></stamp-date-filters>
        </v-toolbar>
      </template>
    </v-row>
    <v-row class="mt-n4">
      <v-toolbar flat class="site-table--header mb-n2">
        <v-toolbar-title class="body-2 mr-2">Quick filter</v-toolbar-title>
        <v-chip-group v-model="selectedOrderType" active-class="primary--text">
          <template v-for="(filterName, index) in quickFilterItems">
            <v-chip v-if="filterName" :key="index" filter outlined>
              <span>{{ capitalize(filterName) }}</span>
            </v-chip>
          </template>
        </v-chip-group>
        <v-btn fab x-small icon outlined color="grey lighten-2" @click="resetSelectedOrderType()">
          <v-icon color="grey">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-row>
    <stamp-environment-filters
      v-if="isDataLoadedFromUrl"
      :envFromQueryParam="envFromQueryParam"
      :testSitesList="testSitesList"
      :isFiltersCleared="isFiltersCleared"
      siteDbIdAttrName="siteDbId"
      originSiteDbIdAttrName="origin.siteDbId"
      @onEnvTypeChanged="onEnvTypeChanged"
    ></stamp-environment-filters>
    <v-row>
      <v-col>
        <v-alert v-model="isEndDateSmaller" type="error" dismissible outlined> End date cannot be less than Start date! Please choose another End date. </v-alert>
        <div class="site-table mt-n8">
          <stamp-data-table
            :headers="headers"
            :data="mailings"
            :total-server-items-nr="totalLists"
            item-key="firebase_list_id"
            :is-loading="isLoading"
            :item-class="getLineColor"
            :is-items-per-page-disabled="true"
            :sort-by="timeStampAttrName"
            :sort-desc="true"
            :current-page="currentPage"
            @options="optionsHandler"
          >
            <template #[`item.creationDateTimestamp`]="{ item }">{{ formatDate(item.creationDateTimestamp) }}</template>
            <template #[`item.totalPrice`]="{ item }">{{ formatPrice(item.totalPrice) }} {{ getLineColor(item) }}</template>
            <template #[`item.customerName`]="{ item }">{{ item.customer.first_name }} {{ item.customer.last_name }}</template>
            <template #[`item.csv`]="{ item }">
              <v-btn v-if="item.downloadUrl" :href="item.downloadUrl" class="blue-grey--text text--darken-4" small> <v-icon left>mdi-cloud-download-outline</v-icon>CSV </v-btn>
              <template v-else-if="isDownloadCsvVisible(item.type)">
                <v-progress-circular v-if="isRowItemActionInProgress(item)" indeterminate color="grey darken-2" size="25"></v-progress-circular>
                <p v-else-if="isRowItemActionFailed(item)" class="red--text">Error</p>
                <v-btn v-else class="static blue-grey--text text--darken-4" small @click="generateCsvInit(item)">Generate CSV</v-btn>
              </template>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn :to="`/list/view/${item.firebase_list_id}`" icon color="blue-grey darken-1" class="justify-start" v-on="on"><v-icon>mdi-eye</v-icon></v-btn>
                </template>
                <span>Open List Details</span>
              </v-tooltip>
              <v-btn icon color="red" class="justify-end" @click="checkMailingListReferences(item)"><v-icon>mdi-delete</v-icon></v-btn>
            </template>
          </stamp-data-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import EnvironmentFilters from '@/components/filters/EnvironmentFilters.vue';
import DateFilters from '@/components/filters/DateFilters.vue';
import DataTable from '../../common/DataTable.vue';
import orderService from '../../../services/orderService';
import mailingListsService from '../../../services/mailingListsService';

export default {
  name: 'list-algolia',
  components: {
    'stamp-environment-filters': EnvironmentFilters,
    'stamp-date-filters': DateFilters,
    'stamp-data-table': DataTable
  },
  data() {
    return {
      search: '',
      options: {},
      timeStampAttrName: 'creationDateTimestamp',
      headers: [
        { text: 'Date', value: 'creationDateTimestamp', sortable: false, width: '230' },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'List Name', value: 'listName', sortable: false },
        { text: 'List Count', value: 'listCount', sortable: false },
        { text: 'Total Price', value: 'totalPrice', sortable: false },
        { text: 'User Email', value: 'userEmail', sortable: false },
        { text: 'Customer name', value: 'customer_name', sortable: false },
        { text: 'CRM customer ID', value: 'crmCustomerId', sortable: false, align: 'center' },
        { text: 'CSV', value: 'csv', sortable: false, align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center', width: '130' }
      ],
      totalLists: null,
      mailings: [],
      isLoading: false,
      isMinLenghSeachValueShown: false,
      isDateSelected: false,
      date: new Date().toISOString().substr(0, 10),
      selectedStartDate: '',
      selectedEndDate: '',
      isEndDateSmaller: false,
      searchTypeTimer: null,
      isCsvLoading: false,
      storageFileName: '',
      isFinishUpdateDialogVisible: false,
      firebaseIDSpinner: null,
      downloadCsvUrl: '',
      errorOnRequest: false,
      listNameOnError: '',
      apiKey: process.env.VUE_APP_GENERATE_CSV_FROM_INFOGR_TOKEN_KEY,
      genTkn: process.env.VUE_APP_GENERAL_TKN,
      csvLineCount: null,
      selectedOrderType: '',
      quickFilterItems: ['later', 'order', 'upload', 'political'],
      filter: [],
      dateFilter: '',
      isDeleteNotAllowed: false,
      isDeleteDialogVisible: false,
      relatedOrderId: '',
      selectedListItem: null,
      mailingListReferences: {},
      isDeleteRaportDialogVisible: false,
      isDeleteProcessing: false,
      reportList: [],
      isGenerateCsvDialogVisible: false,
      isCancelBtnFocused: false,
      isAgreeBtnFocused: false,
      currentPage: 1,
      storageFolderPath: '',
      testSitesList: null,
      envFromQueryParam: '',
      defaultRedColor: '#F9E1E1',
      testModeColor: '#E6F2F4',
      isFiltersCleared: false,
      isDataLoadedFromUrl: false
    };
  },
  computed: {
    ...mapGetters('users', {
      userStore: 'user'
    }),
    ...mapGetters('lists', {
      deletingRaport: 'getListDeleteReport'
    }),
    ...mapGetters('lists', {
      targetedListType: 'getTargetedListType'
    }),
    reportListDisplay() {
      return this.reportList;
    },
    commonFilter() {
      let common = `${this.dateFilter ? `${this.dateFilter}${this.filter.length ? ` AND ` : ``}` : ``}`;
      if (this.filter.length) {
        this.filter.forEach((value, index) => {
          common += value.envType ? value.envType : `${Object.keys(value)}:${Object.values(value)}`;
          if (index !== this.filter.length - 1) {
            common += ' AND ';
          }
        });
      }
      return common;
    },
    firestoreStorageLink() {
      return `https://console.firebase.google.com/u/1/project/onebrandamp/storage/onebrandamp.appspot.com/files~2F${this.storageFolderPath}`;
    },
    focusedCancelBtnClass() {
      return this.isCancelBtnFocused ? 'blue--text' : '';
    },
    focusedAgreeBtnClass() {
      return this.isAgreeBtnFocused ? 'red--text' : '';
    },
    currentPrimaryColor() {
      return 'test-item-default';
    },
    isPoliticalSelected() {
      return this.selectedListItem.type !== this.targetedListType.POLITICAL;
    },
    isWarningMessageVisible() {
      return this.selectedListItem && this.isPoliticalSelected;
    }
  },
  watch: {
    search(val) {
      if (val.length === 0 && !this.isDateSelected) {
        this.isMinLenghSeachValueShown = false;
        this.initResult();
      } else if (val.length !== 0 && val.length < 3) {
        this.isMinLenghSeachValueShown = true;
      } else {
        this.isMinLenghSeachValueShown = false;
        this.searchTypeTimer = setTimeout(() => {
          this.resetSelectedPage();
          this.getMailingListsFromAlgolia(this.commonFilter, val, 0);
        }, 500);
      }
    },
    selectedOrderType() {
      this.resetSelectedPage();
      const orderType = this.quickFilterItems[this.selectedOrderType] ?? '';
      this.routeHandler('type', this.selectedOrderType ? orderType : '');
      this.setCommonFilter('type', orderType);
      this.getMailingListsFromAlgolia(this.commonFilter, this.search, 0);
    },
    deletingRaport: {
      handler() {
        console.log(this.deletingRaport);
        const deleteRaportObj = this.deletingRaport;
        const key = Object.keys(deleteRaportObj)[0];
        const value = deleteRaportObj[key];
        this.reportList.push({ key, value });
      },
      deep: true
    }
  },
  async created() {
    await this.initTestSitesList();
    await this.setDataFromUrl();
    this.isDataLoadedFromUrl = true;
    this.initResult();
  },
  methods: {
    getLineColor(item) {
      return this.isListItemTest(item) ? this.currentPrimaryColor : '';
    },
    async initTestSitesList() {
      this.testSitesList = await this.$store.dispatch('site/getTestSites');
      Promise.resolve();
    },
    onEnvTypeChanged(payload) {
      this.resetSelectedPage();
      this.routeHandler('envType', payload.selectedEnv);
      this.setCommonFilter('envType', payload.envFilters);

      this.getMailingListsFromAlgolia(this.commonFilter, this.search, 0);
    },
    async getMailingListsFromAlgolia(filter, searchValue, page) {
      this.setLoadingState(true);
      try {
        const content = await this.$store.dispatch('algolia/getDataFromAlgolia', {
          indexName: `mailing_lists${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`,
          filter,
          searchValue: searchValue ?? '',
          page: page && page > 1 ? page - 1 : 0
        });
        this.totalLists = content.nbHits;
        this.mailings = content.hits;
        return Promise.resolve(content);
      } catch (error) {
        console.log(error, error.debugData);
        return Promise.reject(error);
      } finally {
        this.setLoadingState(false);
      }
    },
    async getCsv(item) {
      this.isCsvLoading = true;
      const isPoliticalType = item.type === this.targetedListType.POLITICAL;
      this.firebaseIDSpinner = item.firebase_list_id;
      this.storageFolderPath = isPoliticalType ? this.targetedListType.POLITICAL : this.targetedListType.ORDER;
      const options = isPoliticalType ? item.requestedOptions : item.requestObject;
      const filename = `${this.concatAndFormat(item.listName)}_${Date.now()}`;

      const passingObj = isPoliticalType
        ? { ...item.adjustedFilters, state: options.state, filename, limit: item.targetedAmountLimit, firebaseId: item.firebase_list_id }
        : { requestedOptions: options, filename, targetedAmountLimit: item.targetedAmountLimit, firebaseId: item.firebase_list_id };

      try {
        const response = isPoliticalType ? await this.getCsvFromPolitical(passingObj) : await this.getCsvFromMelissa(passingObj);
        if (!response) {
          throw new Error('Something went wrong. Please try again later.');
        }
        this.storageFileName = response?.storageFileName ?? '';
        this.csvLineCount = response?.csvLineCount ?? 0;
        this.downloadCsvUrl = response?.csvLink ?? '';

        return { status: 'success', downloadUrl: response?.csvLink };
      } catch (error) {
        console.log('Error in [getCsv]: ', error);
        this.errorOnRequest = true;
        throw error;
      } finally {
        this.isCsvLoading = false;
        this.setFinishUpdateDialogVisibility(true);
      }
    },

    async getCsvFromMelissa(payload) {
      try {
        const response = await this.$store.dispatch('lists/generateListCsv', payload);
        if (!response) {
          throw new Error('No data in response');
        }
        return response;
      } catch (error) {
        console.log('Error in [getCsvFromMelissa]: ', error);
        throw error;
      }
    },

    async getCsvFromPolitical(payload) {
      try {
        const response = await this.$store.dispatch('lists/generateListByFilters', {
          url: '/generateCsvFromPoliticalMail',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.VUE_APP_GENERAL_TKN}`
          },
          data: payload
        });
        if (!response?.data) {
          throw new Error('No data in [generateListByFilters] response');
        }

        const storageResponse = await this.$store.dispatch('customer_messaging/checkStoragePathMailingLists', {
          storagePath: this.storageFileName,
          firebaseID: payload.firebase_list_id
        });

        if (!storageResponse?.data) {
          throw new Error('No data in [checkStoragePathMailingLists] response');
        }
        return { storageFileName: response?.data?.data?.filename ?? '', csvLineCount: response?.data?.data?.csvLineCount ?? 0, csvLink: storageResponse?.data?.url ?? '' };
      } catch (error) {
        console.log('Error in [getCsvFromPolitical]: ', error);
        throw error;
      }
    },

    clearDate() {
      this.isFiltersCleared = true;
      this.envFromQueryParam = '';
      this.routeHandler('envType', this.envFromQueryParam);
      this.dateFilter = '';
      this.selectedEndDate = '';
      this.selectedStartDate = '';
      this.isDateSelected = false;
      this.search = '';
      this.routeHandler('search', '');
      this.resetSelectedOrderType();
      this.resetSelectedPage();
      this.initResult();
    },
    resetSelectedPage() {
      this.options.page = 1;
      this.currentPage = 1;
    },
    generateCsvInit(item) {
      this.setGenerateCsvDialogVisibility(true);
      this.selectedListItem = item;
    },
    async downloadAgreeded() {
      this.setGenerateCsvDialogVisibility(false);
      this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });

      this.selectedListItem.status === 'progress';
      try {
        const result = await this.getCsv(this.selectedListItem);
        if (result.status !== 'success') {
          throw new Error('Something went wrong. Please try again later.');
        }
        console.log('Generate CSV result:', result);
        this.selectedListItem.downloadUrl = result.downloadUrl;
        this.selectedListItem.status === '';
      } catch (error) {
        this.selectedListItem.status === 'error';
        console.log('Generating CSV failed:', error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
      let logData = {
        firebaseID: this.selectedListItem.firebase_list_id,
        siteId: this.selectedListItem.siteDbId,
        listLogId: this.selectedListItem.listLogId ? this.selectedListItem.listLogId : '',
        action: {
          timestamp: this.formatDate(Date.now() / 1000),
          customer_email: this.userStore.email,
          action: 'generate_csv_requested',
          count: this.csvLineCount ? this.csvLineCount : null,
          status: this.csvLineCount > 0 ? 'success' : 'error',
          type: this.selectedListItem.type
        }
      };
      if (this.selectedListItem.type === 'order') {
        logData.action.is_business = this.selectedListItem.hasOwnProperty('isBusiness') && this.selectedListItem.isBusiness;
      }

      try {
        const response = this.$store.dispatch('logs/countMailingListRequests', logData);

        console.log('Logs updated:', response);
        return Promise.resolve();
      } catch (error) {
        console.log('An error occured:', error);
        return Promise.reject();
      } finally {
        this.selectedListItem = {};
      }
    },
    initResult() {
      const d = new Date();
      const date = Math.floor(d.setDate(d.getDate()) / 1000);
      this.getMailingListsFromAlgolia(`creationDateTimestamp < ${date}`, '', 0);
    },
    onDateFilterChanged(formattedFilter) {
      this.dateFilter = formattedFilter;
      try {
        return this.getMailingListsFromAlgolia(this.commonFilter, this.search, 0);
      } catch (error) {
        console.log('getMailingListsFromAlgolia had failed: ', error.debugData);
        return error;
      }
    },
    routeHandler(paramName, paramValue) {
      let query = { ...this.$route.query };

      if (!paramValue) {
        if (Object.keys(query).includes(paramName)) {
          delete query[paramName];
          this.$router.replace({ query });
        }
      } else if (JSON.stringify({ [paramName]: query[paramName] }) !== JSON.stringify({ [paramName]: paramValue })) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            [paramName]: paramValue
          }
        });
      }
    },
    setDataFromUrl() {
      return new Promise(resolve => {
        const routeQuery = this.$route.query;

        if (routeQuery) {
          this.selectedStartDate = routeQuery.startDate ?? '';
          this.routeHandler('startDate', this.selectedStartDate);
          this.selectedEndDate = routeQuery.endDate ?? '';
          this.routeHandler('endDate', this.selectedEndDate);
          this.isDateSelected = this.selectedStartDate !== '' && this.selectedEndDate !== '';
          this.envFromQueryParam = routeQuery.envType ?? '';
          this.selectedOrderType = routeQuery.type ? this.quickFilterItems.findIndex(i => i === routeQuery.type) : '';
          this.search = routeQuery.search ?? '';
          this.routeHandler('search', this.search);
        }
        resolve('done');
      });
    },
    searchKeydown() {
      clearTimeout(this.searchTypeTimer);
      this.searchTypeTimer = setTimeout(() => {
        if (this.search === '' || (this.search && this.search.length > 2)) {
          this.routeHandler('search', this.search);
        }
      }, 1000);
    },
    setLoadingState(isVisible) {
      this.isLoading = isVisible;
    },
    setCommonFilter(key, value) {
      if (this.filter.length) {
        let isValueFound = false;
        this.filter.forEach((item, index) => {
          if (Object.keys(item).includes(key) && item[key] !== value) {
            isValueFound = true;
            if (value !== '') {
              this.filter[index][key] = value;
            } else {
              this.filter.splice(index, 1);
            }
          }
        });
        if (!isValueFound) {
          this.filter.push({ [key]: value });
        }
      } else if (value) {
        this.filter.push({ [key]: value });
      }
    },
    async checkMailingListReferences(list) {
      try {
        this.$store.dispatch('showLoader', { message: 'Please wait...' });
        const response = await orderService.checkListInOrdersRef(list.siteDbId, list.firebase_list_id);
        if (response.isRelatedToOrder && response.orderFirebaseID) {
          this.relatedOrderId = response.orderFirebaseID;
          this.setDeleteNotAllowedState(true);
        } else {
          this.selectedListItem = list;
          const mailingListResponse = await mailingListsService.checkMailingListsReferences(list.firebase_list_id);
          if (mailingListResponse.status === 'success') {
            console.log('mailingListReferences', mailingListResponse.listRefList);
            this.mailingListReferences = mailingListResponse.listRefList;
          }
          this.setDeleteDialogVisibility(true);
        }
      } catch (err) {
        console.log('checkMailingListReferences has failed: ', err);
      } finally {
        this.$store.dispatch('hideLoader', {});
      }
    },
    async confirmDelete() {
      this.setDeleteDialogVisibility(false);
      this.isDeleteRaportDialogVisible = true;
      this.isDeleteProcessing = true;
      try {
        await this.$store.dispatch('lists/deleteMailingList', {
          listObject: this.selectedListItem,
          listRef: this.mailingListReferences
        });
        console.log('All done in Front');
        this.isDeleteProcessing = false;
      } catch (error) {
        console.log(`Deleting all catch some error ListsAlgolia: ${error}`);
      }
    },
    onRouteHandlerChanged(payload) {
      this.routeHandler(payload.key, payload.value);
    },
    onDateSelectedStateChanged(isDateSelected) {
      this.isDateSelected = isDateSelected;
    },
    onWrongFilter(isEndDateSmaller) {
      this.isEndDateSmaller = isEndDateSmaller;
      this.mailings = [];
      this.totalLists = 0;
    },
    isListItemTest(item) {
      return (item?.origin?.siteDbId && this.testSitesList?.includes(item?.origin?.siteDbId)) || this.testSitesList?.includes(item.siteDbId);
    },
    closeDeleteRaportDialog() {
      this.isDeleteRaportDialogVisible = false;
      this.initResult();
      this.reportList = [];
    },
    setAgreeBtnFocusedState(state) {
      this.isAgreeBtnFocused = state;
    },
    setCancelBtnFocusedState(state) {
      this.isCancelBtnFocused = state;
    },
    setGenerateCsvDialogVisibility(state) {
      this.isGenerateCsvDialogVisible = state;
    },
    setFinishUpdateDialogVisibility(state) {
      this.isFinishUpdateDialogVisible = state;
    },
    setDeleteDialogVisibility(state) {
      this.isDeleteDialogVisible = state;
    },
    setDeleteNotAllowedState(state) {
      this.isDeleteNotAllowed = state;
    },
    resetSelectedOrderType() {
      this.selectedOrderType = '';
    },
    formatDate(dateTotransform) {
      var date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    formatPrice(price) {
      return price ? `$${price.toFixed(2)}` : '';
    },
    capitalize(text) {
      if (!text || text.length <= 2) {
        return '';
      } else if (text.length >= 2) {
        return text.charAt(0).toUpperCase() + text.slice(1);
      }
    },
    concatAndFormat(text) {
      if (!text) return '';
      const modifiedText = text.toString();
      return modifiedText.toLowerCase().replace(/["':/\s+]/g, '');
    },
    setConfirmButtonProperty(button, value) {
      this[button === 'agree' ? 'isAgreeBtnFocused' : 'isCancelBtnFocused'] = value;
    },
    isDownloadCsvVisible(type) {
      return type === 'order' || type === 'political';
    },
    isRowItemActionInProgress(item) {
      return item?.status === 'progress';
    },
    isRowItemActionFailed(item) {
      return item?.status === 'error';
    },
    async optionsHandler(options) {
      this.options = options;
      if (this.currentPage !== this.options.page) {
        await this.getMailingListsFromAlgolia(this.commonFilter, this.search, this.options.page);
        this.currentPage = this.options.page;
      }
    }
  }
};
</script>

<style scoped>
.delete-alert {
  height: 158px;
}
.test-item-global {
  background-color: #e6f2f4 !important;
}
.test-item-default {
  background-color: #f9e1e1 !important;
}

a {
  text-decoration: none;
}
</style>
