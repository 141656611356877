<template>
  <v-dialog v-model="isDialogOpen" persistent max-width="500">
    <v-card>
      <v-card-title class="text-h5"> {{ title }} </v-card-title>
      <v-card-text>
        <div v-if="text">{{ text }}</div>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey " text @click="closeDialog"> {{ closeText }} </v-btn>
        <v-btn color="green darken-1" text @click="confirmAction"> {{ openText }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: String,
    text: String,
    closeText: {
      type: String,
      default: 'No'
    },
    openText: {
      type: String,
      default: 'Yes'
    }
  },
  computed: {
    isDialogOpen() {
      return this.value;
    }
  },
  methods: {
    confirmAction() {
      this.$emit('onConfirm');
    },
    closeDialog() {
      this.$emit('onClose');
    }
  }
};
</script>
