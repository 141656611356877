<template>
  <v-container fluid>
    <v-container v-if="!isArtworkGenerated">
      <v-row class="justify-center">
        <v-radio-group v-model="artworkType" row @change="clearArtwork">
          <v-radio label="Add PDF/HTML Artwork File" value="file"></v-radio>
          <v-radio label="Add HTML Code" value="code"></v-radio>
        </v-radio-group>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="3">
          <v-text-field
            v-model="artworkName"
            label="Artwork Name *"
            placeholder="Name your artwork"
            dense
            outlined
            clearable
            :rules="[value => !!value || 'The name field is required.']"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="artworkSize" label="Artwork Size" disabled dense outlined></v-text-field>
        </v-col>
      </v-row>
      <template v-if="artworkType === 'file'">
        <v-row class="justify-center">
          <v-col cols="6">
            <v-text-field v-model="fileUrl" label="Paste URL" placeholder="Please specify the URL pointing to your PDF file" dense outlined clearable></v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-chip class="mb-4">OR</v-chip>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="6">
            <v-file-input v-model="fileInput" label="Select from computer" accept=".pdf,.html" dense outlined @click:clear="clearArtwork" @change="clearFileUrl"> </v-file-input>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row class="justify-center">
          <v-col cols="6">
            <prism-editor v-model="html" language="html" :line-numbers="true" :auto-style-line-numbers="true" style="background-color: white" />
          </v-col>
        </v-row>
      </template>
      <v-row class="justify-center">
        <v-btn :disabled="isCreateArtworkDisabled" @click="createArtwork">Create Artwork</v-btn>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row class="justify-center">
        <object class="artwork-preview" :data="artworkUrl" type="application/pdf">
          <embed class="artwork-preview" :src="artworkUrl" type="application/pdf" frameborder="0" />
        </object>
      </v-row>
      <v-row class="justify-center">
        <v-btn class="mt-2" @click="replaceArtwork"> <v-icon class="mr-2">mdi-restore</v-icon> Replace Artwork</v-btn>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import PrismEditor from 'vue-prism-editor';
import { storageRef, storage } from '../../../services/firebaseInit.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    PrismEditor
  },
  props: ['colorOption', 'orderData', 'doubleSided', 'insertBlankPage'],
  data() {
    return {
      queryParams: {},
      artworkType: 'file',
      artworkName: '',
      artworkSize: '8.5x11',
      fileUrl: '',
      html: '',
      fileInput: null,
      generatedArtwork: null
    };
  },
  computed: {
    ...mapGetters('custom_fields', {
      dmmCustomFields: 'getDmmCustomFields'
    }),
    isCreateArtworkDisabled() {
      return !this.artworkName || (!this.fileInput && !this.fileUrl && !this.html);
    },
    orderObject() {
      return this.orderData || {};
    },
    isArtworkGenerated() {
      return !!this.generatedArtwork?.data?.artwork_thumbnails_front?.pdf;
    },
    artworkUrl() {
      return this.generatedArtwork?.data?.artwork_thumbnails_front?.pdf || '';
    }
  },
  mounted() {
    this.queryParams = this.$route.query;
    this.loadArtwork();
  },
  methods: {
    loadArtwork() {
      const artwork = this.orderObject?.assets?.artwork?.[0];
      this.fileUrl = artwork?.url || '';
      this.artworkName = artwork?.name || '';
    },
    clearArtwork() {
      this.fileUrl = '';
      this.html = '';
      this.fileInput = null;
    },
    clearFileUrl() {
      this.fileUrl = '';
    },
    replaceArtwork() {
      this.generatedArtwork = null;
      this.clearArtwork();
      this.sendArtworkDataToParent();
    },
    sendArtworkDataToParent() {
      this.$emit('artworkChanged', this.generatedArtwork);
    },
    getArtworkConfig() {
      return {
        artwork_name: this.artworkName,
        firebase_order_id: this.queryParams.firebase_order_id,
        artwork: this.fileUrl,
        size: this.artworkSize,
        color: this.colorOption,
        double_sided: this.doubleSided,
        insert_blank_page: this.insertBlankPage,
        is_order_updated: true
      };
    },
    async replaceHtmlFileContent() {
      const fileContent = await this.fileInput.text();
      const replacedContent = await this.$store.dispatch('custom_fields/replaceCustomFields', fileContent);
      let blob = new Blob([replacedContent], { type: 'plain/text' });
      this.fileInput = new File([blob], this.fileInput.name, { type: 'plain/text' });
    },
    async createArtwork() {
      try {
        if (this.artworkType === 'code' && !this.fileInput) {
          this.html = await this.$store.dispatch('custom_fields/replaceCustomFields', this.html);
          let blob = new Blob([this.html], { type: 'plain/text' });
          this.fileInput = new File([blob], this.artworkName, { type: 'plain/text' });
        }
        if (!this.fileUrl) {
          await this.uploadToStorage();
        }
        this.$store.dispatch('showLoader', {
          message: `Creating Letter Artwork in progress...`
        });
        const payload = this.getArtworkConfig();
        this.generatedArtwork = await this.$store.dispatch('letters/createLetterArtwork', payload);
        this.sendArtworkDataToParent();
      } catch (error) {
        this.$emit('onError', error);
        console.log('[Create Letter Artwork error ]:', error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async uploadToStorage() {
      try {
        this.$store.dispatch('showLoader', {
          message: `Generating PDF URL in progress...`
        });
        const fileExtension = this.fileInput.name.split('.')[1];
        if (this.fileInput && this.artworkType !== 'code' && fileExtension === 'html' && this.dmmCustomFields.length) {
          await this.replaceHtmlFileContent();
        }
        const path = `letter-campaign/${this.queryParams.crm_order_id}_${this.queryParams.crm_mail_drop_id}`;
        const letterFileRef = `${path}/${this.artworkName}.${fileExtension}`;
        const storageLetterRef = storage.ref(letterFileRef);
        await storageLetterRef.put(this.fileInput);
        this.fileUrl = await storageRef.child(letterFileRef).getDownloadURL();
      } catch (error) {
        this.$emit('onError', error);
        console.log('[Upload Letter Artwork to Storage error] :', error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    }
  }
};
</script>
<style scoped>
.artwork-preview {
  width: 563px;
  height: 750px;
}
</style>
