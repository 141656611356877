<template>
  <v-dialog v-if="fullOrderObject" v-model="chargeModal" persistent max-width="1200px">
    <new-payment-method-dialog
      v-if="addNewPaymentMethodDialog"
      :value="addNewPaymentMethodDialog"
      :customerData="customerFirebaseId"
      :cardHolderName="`${fullOrderObject.first_name} ${fullOrderObject.last_name}`"
      :fbSiteId="fullOrderObject.site_db_id"
      :type="payment_method_type"
      @newPaymentMethodAdded="newPaymentMethodAdded"
      @closeNewPaymentMethodDialog="closeNewPaymentMethodDialog"
    >
    </new-payment-method-dialog>

    <v-card>
      <v-card-title>
        <h3>Order #{{ fullOrderObject.crm_order_id }}</h3>
        <v-icon color="green" class="ml-2">mdi-record</v-icon>
        <v-spacer></v-spacer>
        <v-btn small icon @click="closeChargeModal()"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-progress-linear v-if="isChargeLoading" indeterminate color="cyan darken-2"></v-progress-linear>
      <v-alert v-if="paymentFeedbackMsg" :type="paymentFeedbackType" dismissible border="left" class="mx-4">
        {{ paymentFeedbackMsg }}
      </v-alert>

      <v-card-text>
        <!-- Charge section -->
        <div v-if="!refundMode">
          <v-row class="justify-center mt-2">
            <v-col cols="12" sm="3" md="3" lg="3">
              <h3 class="text-end mt-2">Production:</h3>
            </v-col>
            <v-col cols="12" sm="5" md="5" lg="5">
              <v-text-field
                v-model="remainingAmount"
                :error-messages="amountNotValid"
                :disabled="isChargeLoading || balanceUpdateInProgress"
                outlined
                dense
                prepend-icon="mdi-currency-usd"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center mt-n7 mb-n7">
            <v-col cols="12" sm="3" md="3" lg="3">
              <h3 class="text-end mt-2">Payment method:</h3>
            </v-col>
            <v-col cols="12" sm="5" md="5" lg="5">
              <v-select
                v-model="selectedPaymentMethod"
                :items="paymentMethodsList"
                item-text="details"
                placeholder="Select payment method"
                return-object
                outlined
                :prepend-inner-icon="selectedPaymentMethod ? (selectedPaymentMethod.type === 'card' ? 'mdi-credit-card' : 'mdi-bank') : ''"
                dense
                append-outer-icon="mdi-refresh"
                :disabled="isChargeLoading || balanceUpdateInProgress"
                @click:append-outer="newPaymentMethodAdded('')"
              >
                <template slot="item" slot-scope="data">
                  <template v-if="data.item.type === 'card'">
                    <v-icon class="mr-1" color="cyan darken-2">mdi-credit-card</v-icon>
                    <span>{{ data.item.details }}</span>
                  </template>
                  <template v-else-if="data.item.type === 'bank_account'">
                    <v-icon class="mr-1" color="cyan darken-2">mdi-bank</v-icon>
                    <span>{{ data.item.details }}</span>
                  </template>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row class="justify-center mt-n7 mb-n7">
            <v-col cols="12" sm="5" md="5" lg="5" class="offset-3">
              <v-btn
                text
                color="cyan darken-2"
                class="justify-start"
                @click="
                  payment_method_type = 'card';
                  addNewPaymentMethodDialog = true;
                "
              >
                <v-icon>mdi-credit-card-plus-outline</v-icon><span class="pl-3">add new card</span>
              </v-btn>
              <v-btn
                text
                color="cyan darken-2"
                class="justify-start"
                @click="
                  payment_method_type = 'bank_account';
                  addNewPaymentMethodDialog = true;
                "
              >
                <v-icon>mdi-bank</v-icon><span class="pl-3">add new bank account</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-col cols="12" sm="10" md="10" lg="10" class="mb-n2 mt-6">
            <v-row class="justify-end">
              <p v-if="balanceUpdateInProgress">
                <v-progress-circular indeterminate color="cyan darken-1" size="20" class="mr-3"></v-progress-circular>
                Updating balance...
              </p>
              <v-btn
                v-else
                color="green darken-1"
                class="mr-1 mb-2"
                dark
                :disabled="isChargeLoading || !selectedPaymentMethod || amountNotValid !== ''"
                @click="checkDoubleCharge()"
                >Charge ${{ remainingAmount }}
              </v-btn>
            </v-row>
          </v-col>
          <confirmation-dialog
            :isConfirmationDialogOpened="isConfirmationDialogOpened"
            :action="confirmationDialogMessage"
            @closeConfirmationDialog="closeConfirmationDialog"
            @actionConfirmed="chargePaymentAnyway"
          ></confirmation-dialog>
        </div>
        <!-- Refund section -->
        <div v-else>
          <stamp-data-table :headers="refundLogHeaders" :data="selectedPaymentToRefund" :is-header-hidden="true" :is-footer-hidden="true" item-key="payment_db_id">
            <template #[`item.agent`]="{ item }">
              <p class="body-2">{{ showAgentName(item) }}</p>
              <p class="agentEmail">{{ showAgentEmail(item) }}</p>
            </template>
            <template #[`item.amount`]="{ item }">
              <span class="font-weight-bold primary--text">{{ formatNumeral(item.amount).format('$0,0.00') }}</span>
              <p v-if="item.refunds" class="font-weight-bold blue-grey--text mb-n1 ml-n1">-{{ formatNumeral(calculateRefundSum(item.refunds)).format('$0,0.00') }}</p>
            </template>
            <template #[`item.payment_type`]="{ item }">
              <v-chip v-if="isPartialRefund(item)" small color="blue-grey lighten-2" text-color="white"> Partial refund </v-chip>
              <v-chip v-else-if="isStripePayment(item)" small color="green" text-color="white"> Credit card </v-chip>
            </template>
            <template #[`item.payment_method`]="{ item }">
              <template v-if="isStripePayment(item)">
                <span v-if="isPaymentRemoved(item)" class="font-italic paymentHistoryTable"> Payment method has been removed </span>
                <span v-else class="paymentHistoryTable"> • {{ showPaymentMethodLastFour(item) }} </span>
              </template>
            </template>
            <template #[`item.timestamp`]="{ item }">
              <span class="paymentHistoryTable">{{ formatDate(item.timestamp.seconds) }}</span>
            </template>
          </stamp-data-table>
          <v-divider></v-divider>
          <v-row class="justify-center mt-4">
            <v-col cols="3" sm="3" md="2" lg="2">
              <h3 class="text-end mt-2">Refund:</h3>
            </v-col>
            <v-col cols="9" sm="6" md="5" lg="5">
              <v-text-field v-model="refundingAmount" :error-messages="amountNotValid" outlined dense prepend-icon="mdi-currency-usd"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center mt-n8">
            <v-col cols="3" sm="3" md="2" lg="2">
              <h3 class="text-end mt-2">Reason:</h3>
            </v-col>
            <v-col cols="9" sm="6" md="5" lg="5">
              <v-select v-model="selectedReason" :items="reasonItems" placeholder="Select a reason" outlined dense prepend-icon="mdi-currency-usd"></v-select>
            </v-col>
          </v-row>
          <v-row class="justify-center mt-n8">
            <v-col cols="3" sm="3" md="2" lg="2">
              <h3 class="text-end mt-2">Note:</h3>
            </v-col>
            <v-col cols="9" sm="6" md="5" lg="5">
              <v-textarea v-model="refundNote" outlined rows="3"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="justify-end mt-n8">
            <v-col cols="12" sm="8" md="6" lg="6">
              <v-row class="justify-center ml-5">
                <p v-if="balanceUpdateInProgress">Updating balance...</p>
                <v-btn v-else color="blue-grey lighten-2" dark :disabled="isChargeLoading || amountNotValid !== ''" @click="refundPayment()"> Refund ${{ refundingAmount }} </v-btn>
                <v-btn color="grey darken-1" outlined class="ml-6" :disabled="isChargeLoading" @click="exitRefundMode()"> Cancel </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mt-6 mb-4"></v-divider>
        <span class="title mb-3"> Transactions </span>
        <transactions ref="transactionList" :refundIsEnabled="isRefundEnabled" @refundAmount="refundAmount"></transactions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import NewPaymentMethodDialog from '../../../stripe/dialogs/NewPaymentMethodDialog.vue';
import Transactions from '../../../stripe/Transactions';
import ConfirmationDialog from '../../../order-management/orders/orderDialogs/ConfirmationDialog';
import userService from '../../../../services/userService.js';
import numeral from 'numeral';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: {
    'new-payment-method-dialog': NewPaymentMethodDialog,
    Transactions,
    ConfirmationDialog,
    'stamp-data-table': DataTable
  },
  props: ['modal', 'customerFbId', 'computedAmount', 'ospreyBalance'],
  data() {
    return {
      customerFirebaseId: this.customerFbId,
      chargeModal: this.modal,
      isChargeLoading: false,
      paymentFeedbackType: null,
      paymentFeedbackMsg: '',
      remainingAmount: '',
      selectedPaymentMethod: null,
      amountNotValid: '',
      paidAmount: 0,
      paymentLogHeaders: [
        { text: 'Agent', align: 'start', value: 'agent', sortable: false },
        { text: 'Amount', align: 'center', value: 'amount', sortable: false },
        { text: 'Type', align: 'center', value: 'payment_type', sortable: false },
        { text: 'Method', align: 'center', value: 'payment_method', sortable: false },
        { text: 'Timestamp', align: 'center', value: 'timestamp' },
        { text: 'Refund', align: 'center', value: 'actions', sortable: false },
        { text: '', value: 'expand', sortable: false }
      ],
      refundLogHeaders: [
        { text: 'Agent', align: 'start', value: 'agent', sortable: false },
        { text: 'Amount', align: 'center', value: 'amount', sortable: false },
        { text: 'Type', align: 'center', value: 'payment_type', sortable: false },
        { text: 'Method', align: 'center', value: 'payment_method', sortable: false },
        { text: 'Timestamp', align: 'center', value: 'timestamp' }
      ],
      sortBy: 'timestamp',
      descending: true,
      expanded: [],
      refundMode: false,
      refundingAmount: 0,
      refundMaxValue: 0,
      selectedPaymentToRefund: [],
      selectedReason: '',
      reasonItems: ['Duplicate', 'Fraudulent', 'Requested by customer', 'Other'],
      refundNote: '',
      ospreyTotal: this.ospreyBalance,
      historyLoading: false,
      addNewPaymentMethodDialog: false,
      isRefundEnabled: false,
      payment_method_type: '',
      isConfirmationDialogOpened: false,
      confirmationDialogMessage: '',
      isChargeTimeoutExpired: true,
      chargeSetTimeOut: null,
      // lastPaymentAmount contains the last payment amount made in the last 120 sec.
      lastPaymentAmount: 0
    };
  },
  computed: {
    ...mapGetters('stripe', {
      paymentHistory: 'getPaymentHistory',
      refundsDetails: 'getRefundsDetails',
      balanceUpdateInProgress: 'getBalanceUpdateInProgress'
    }),
    ...mapGetters('payment_methods', {
      paymentMethodsList: 'getPaymentMethods'
    }),
    ...mapGetters('users', {
      userStore: 'user'
    }),
    ...mapGetters('customer_messaging', {
      fullOrderObject: 'getTriggeredOrder'
    })
  },
  watch: {
    modal(value) {
      this.chargeModal = this.modal;
    },
    computedAmount(value) {
      this.remainingAmount = this.formatPrice(this.computedAmount || this.fullOrderObject.order_balance);
    },
    remainingAmount(value) {
      const maxAmount = this.formatPrice(this.computedAmount || this.fullOrderObject.order_balance);
      this.verifyAmount(this.remainingAmount, maxAmount);
    },
    refundingAmount(value) {
      this.verifyAmount(this.refundingAmount, this.refundMaxValue);
    },
    ospreyBalance(value) {
      this.ospreyTotal = this.ospreyBalance;
    },
    paymentHistory(value) {
      if (this.paymentHistory && this.paymentHistory.length) {
        this.historyLoading = false;
      }
    },
    addNewPaymentMethodDialog() {
      this.addNewPaymentMethodDialog ? (this.chargeModal = false) : (this.chargeModal = true);
    },
    balanceUpdateInProgress: {
      handler(updating) {
        if (updating === false) {
          this.remainingAmount = this.formatPrice(this.computedAmount || this.fullOrderObject.order_balance);
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.setRefundEnabledState();
    this.remainingAmount = this.formatPrice(this.computedAmount || this.fullOrderObject.order_balance);
  },
  methods: {
    formatPrice(price) {
      return parseFloat(price).toFixed(2);
    },
    formatDate(dateTotransform) {
      var date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    formatNumeral(amount) {
      return numeral(amount / 100);
    },
    verifyAmount(amount, maxAmount) {
      console.log('AMOUNT: ', amount);
      console.log('MAX AMOUNT: ', maxAmount);
      switch (true) {
        case !amount:
          this.amountNotValid = 'Amount cannot be empty or 0!';
          break;
        case isNaN(amount):
          this.amountNotValid = 'Amount must be a number!';
          break;
        case parseFloat(amount) < 0:
          this.amountNotValid = 'For negative amounts, please process a refund from the payments!';
          break;
        case parseFloat(amount) > parseFloat(maxAmount):
          this.amountNotValid = `Amount cannot be greater than  ${maxAmount}!`;
          break;
        default:
          this.amountNotValid = '';
          break;
      }
    },
    calculateRefundSum(refunds) {
      let sum = 0;
      refunds.forEach(refund => {
        sum += refund.amount;
      });
      return sum;
    },
    isPaymentTypeKnown(paymentType) {
      switch (paymentType) {
        case 'stripe':
          return true;
        case 'pay_later':
          return true;
        case 'balance_adjustment':
          return true;
        case 'adjustment':
          return true;
        case 'corporate':
          return true;
        default:
          return false;
      }
    },
    closeChargeModal() {
      this.selectedPaymentMethod = null;
      this.$emit('closeChargeRefundDialog');
    },
    chargePaymentMethod() {
      this.isChargeTimeoutExpired = false;
      this.chargeSetTimeOut = setTimeout(() => {
        this.isChargeTimeoutExpired = true;
        this.lastPaymentAmount = 0;
      }, 120000);
      this.isChargeLoading = true;
      this.paymentFeedbackMsg = '';
      this.paymentFeedbackType = null;
      const amountInDollars = this.remainingAmount.includes(',') ? parseFloat(this.remainingAmount.replace(/,/g, '')) : parseFloat(this.remainingAmount);
      const amountInCents = parseFloat((amountInDollars * 100).toFixed(2));
      const isBankAccountPayment = this.selectedPaymentMethod.payment_type === 'bank_account';
      const stripeAction = isBankAccountPayment ? 'stripe/chargeACHPayment' : 'stripe/chargeWPPayment';
      const paymentType = isBankAccountPayment ? 'stripe_pending' : 'stripe';
      const paymentSuccessMessage = isBankAccountPayment ? 'Charge request successfully sent to process' : 'chargeSuccess';
      const paymentObject = {
        agent_id: this.userStore.id,
        agent_name: this.userStore.name,
        agent_email: this.userStore.email,
        customer_db_id: this.customerFirebaseId,
        payment_method_db_id: this.selectedPaymentMethod.id,
        amount: amountInCents,
        crm_order_id: this.fullOrderObject.crm_order_id,
        crm_customer_id: this.fullOrderObject.crm_customer_id,
        customer_unique_id: this.fullOrderObject.customer_unique_id,
        payment_type: paymentType,
        site_db_id: this.fullOrderObject.site_db_id,
        site_id: this.fullOrderObject.site_id,
        order_db_id: this.fullOrderObject.firebase_order_id
      };
      this.$store
        .dispatch(stripeAction, paymentObject)
        .then(result => {
          if (result?.data?.Success) {
            this.paidAmount = amountInDollars;
            this.lastPaymentAmount = amountInDollars;
            this.$store.commit('stripe/setBalanceUpdateInProgress', true);
            this.$store.commit('stripe/setPaymentHistoryIsLoading', true);
            this.setFeedbackProps(paymentSuccessMessage, 'success');
            this.closeChargeModal();
          }
        })
        .catch(error => {
          clearTimeout(this.chargeSetTimeOut);
          this.lastPaymentAmount = 0;
          console.log('[chargePaymentMethod error]', error);
          this.setFeedbackProps(
            `An error occured during the payment. Please try again! Error: ${error.response?.data?.message || error.response || error.message || error}`,
            'error'
          );
        })
        .finally(() => {
          this.isChargeLoading = false;
        });
    },
    checkDoubleCharge() {
      let pendingPayments = this.paymentHistory.filter(payment => payment.paymentType === 'stripe_pending');
      let remainingAmount = parseFloat(this.remainingAmount.includes(',') ? this.remainingAmount.replace(/,/g, '') : this.remainingAmount);
      if (this.isChargeTimeoutExpired && this.lastPaymentAmount != remainingAmount && !pendingPayments.length) {
        this.chargePaymentMethod();
      } else {
        this.isConfirmationDialogOpened = true;
        this.confirmationDialogMessage = `charge $${this.remainingAmount}`;
        if (pendingPayments.length) {
          this.confirmationDialogMessage += `? There is already a pending ACH payment. Do you still want to continue`;
        }
      }
    },
    chargePaymentAnyway() {
      this.chargePaymentMethod();
      this.isConfirmationDialogOpened = false;
    },
    closeConfirmationDialog() {
      this.isConfirmationDialogOpened = false;
    },
    addPaymentMethod() {
      this.addNewPaymentMethodDialog = true;
    },
    refundAmount(item) {
      this.selectedPaymentToRefund = [item];
      if (item.refunds) {
        let refundSum = 0;
        item.refunds.forEach(refund => {
          refundSum += refund.amount;
        });
        this.refundingAmount = parseFloat((Math.abs(item.amount - refundSum) / 100).toFixed(2));
        this.refundMaxValue = parseFloat((Math.abs(item.amount - refundSum) / 100).toFixed(2));
      } else {
        this.refundingAmount = parseFloat(Math.abs(item.amount / 100).toFixed(2));
        this.refundMaxValue = parseFloat(Math.abs(item.amount / 100).toFixed(2));
      }
      this.refundMode = true;
    },
    refundPayment() {
      const type = this.selectedPaymentToRefund[0].card_stripe_id ? 'card' : 'bank_account';
      this.isChargeLoading = true;
      const amountInCents = this.refundingAmount * 100;
      this.$store.commit('stripe/setBalanceUpdateInProgress', true);
      this.$store
        .dispatch('stripe/refundPayment', {
          agent_id: this.userStore.id,
          agent_name: this.userStore.name,
          agent_email: this.userStore.email,
          amount: amountInCents,
          payment_id: this.selectedPaymentToRefund[0].payment_db_id,
          reason: this.selectedReason,
          note: this.refundNote,
          type: type
        })
        .then(result => {
          if (result?.data?.Success) {
            if (type === 'card') {
              this.setFeedbackProps('Amount: $' + this.refundingAmount + ' successfully refunded!', 'success');
            } else {
              this.setFeedbackProps('Refund with Amount: $' + this.refundingAmount + ' successfully sent to process!', 'success');
            }
            this.$store.commit('stripe/setPaymentHistoryIsLoading', true);
          }
        })
        .catch(error => {
          console.log('[ refundPayment ] Error:', error);
          this.setFeedbackProps(error?.response?.data ?? `An error occured during the payment. Please try again! Error: ${error}`, 'error');
        })
        .finally(() => {
          this.isChargeLoading = false;
          this.refundMode = false;
          this.$store.commit('stripe/setBalanceUpdateInProgress', false);
        });
    },
    openRefundsDetails(item) {
      this.expanded = [item];
      let refundIds = [];
      this.expanded[0].refunds.forEach(refund => {
        refundIds.push(refund.refund_db_id);
      });
      this.$store.dispatch('stripe/getRefundsDetails', { refundIds: refundIds });
    },
    closeRefundsDetails() {
      this.expanded = [];
    },
    setFeedbackProps(message, type) {
      this.paymentFeedbackMsg =
        message === 'chargeSuccess'
          ? `Charged order #${this.fullOrderObject.crm_order_id} for $${this.paidAmount} using payment method ${
              !this.selectedPaymentMethod.id
                ? ''
                : this.paymentMethodsList[this.paymentMethodsList.findIndex(item => item.id === this.selectedPaymentMethod.id)].details.slice(0, -8)
            }`
          : message;
      this.paymentFeedbackType = type;
    },
    exitRefundMode() {
      this.refundMode = false;
      this.selectedReason = '';
      this.refundNote = '';
    },
    newPaymentMethodAdded(message) {
      this.$store.dispatch('payment_methods/getPaymentMethodsData', {
        customerId: this.customerFirebaseId,
        showAllCards: false
      });
      this.setFeedbackProps(message, 'success');
    },
    closeNewPaymentMethodDialog() {
      this.addNewPaymentMethodDialog = false;
      this.selectedPaymentMethod = null;
    },
    async setRefundEnabledState() {
      try {
        if (this.userStore) {
          const user = await userService.getById(this.userStore.id);
          const allowedGroups = ['Accountant', 'Developer', 'Executive', 'Client Experience Coordinator'];
          this.isRefundEnabled = user.groups.some(group => allowedGroups.includes(group.name));
        }
      } catch (error) {
        console.log('setRefundEnabledState has failed: ', error);
      }
    },
    showAgentName(item) {
      return item.agent_name ?? `${this.fullOrderObject.first_name} ${this.fullOrderObject.last_name}`;
    },
    showAgentEmail(item) {
      return item.agent_email ?? this.fullOrderObject.order_email;
    },
    isStripePayment(item) {
      return item.paymentType === 'stripe';
    },
    isPartialRefund(item) {
      return this.isStripePayment(item) && item.refunds && this.calculateRefundSum(item.refunds) !== item.amount;
    },
    isPaymentRemoved(item) {
      return this.paymentMethodsList.findIndex(paymentMethod => paymentMethod.id === item.payment_method_db_id) === -1;
    },
    showPaymentMethodLastFour(item) {
      return this.paymentMethodsList[this.paymentMethodsList.findIndex(card => card.id === item.payment_method_db_id)].details.slice(0, -8);
    }
  }
};
</script>

<style>
.agentEmail {
  color: #757575;
  letter-spacing: normal !important;
  font-size: 0.75rem !important;
  font-weight: 400;
}

.paymentHistoryTable {
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: normal;
  font-size: 0.75rem !important;
  font-weight: 400;
}
</style>
