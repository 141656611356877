<template>
  <div class="site-table">
    <ConfirmationDialog
      :value="isConfirmDialogOpen"
      title="Confirmation required"
      :text="`Are you sure you want to delete ${item.name}? This will delete the log from Firestore not the backup from Firebase Storage!`"
      @onClose="closeDeleteDialog"
      @onConfirm="confirmDelete"
    />
    <template>
      <v-toolbar flat class="site-table--header site-table--lists">
        <v-col class="pl-0">
          <v-toolbar-title class="headline"> Backup Firebase Firestore Logs </v-toolbar-title>
        </v-col>
        <v-col cols="5" md="4">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </v-col>
      </v-toolbar>
    </template>
    <v-card>
      <stamp-data-table :headers="headers" :data="backupLogsList" :search="search" sort-by="name" :sort-desc="true" item-key="backupLogTable">
        <template #[`item.date`]="{ item }">{{ formatDate(item.date) }}</template>
        <template #[`item.actions`]="{ item }">
          <v-btn icon class="mx-0" @click="openDeleteDialog(item)"><v-icon color="#c90000">mdi-delete</v-icon></v-btn>
        </template>
      </stamp-data-table>
    </v-card>
    <small>*Only the last 30 values are listed.</small>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DataTable from '@/components/common/DataTable.vue';
import ConfirmationDialog from '../dialog/ConfirmationDialog';

export default {
  components: { 'stamp-data-table': DataTable, ConfirmationDialog },
  data() {
    return {
      backupLogs: [],
      sortBy: 'name',
      descending: true,
      headers: [
        { text: 'Date', align: 'left', value: 'date', width: '30%' },
        { text: 'Name', value: 'name', align: 'left', width: '40%' },
        { text: 'Status', value: 'status', align: 'left', width: '20%' },
        { text: 'Actions', value: 'actions', sortable: false, width: 100 }
      ],
      search: '',
      isConfirmDialogOpen: false,
      item: {}
    };
  },
  computed: {
    ...mapGetters('users', {
      userStore: 'user',
      userAccessLevel: 'userAccessLevel'
    }),
    backupLogsList() {
      return this.backupLogs;
    }
  },
  async created() {
    this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
    this.backupLogs = await this.$store.dispatch('logs/listBackupFirebaseLogs');
    this.$store.dispatch('hideLoader');
  },
  methods: {
    formatDate(dateTotransform) {
      return this.$moment(dateTotransform).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    closeDeleteDialog() {
      this.isConfirmDialogOpen = false;
    },
    openDeleteDialog(item) {
      this.item = item;
      this.isConfirmDialogOpen = true;
    },
    confirmDelete() {
      this.$store.dispatch('logs/deleteFirebaseLog', { firebaseID: this.item.document_id }).then(response => {
        if (response.deleted) {
          this.backupLogs.forEach((value, index) => {
            if (value.document_id === this.item.document_id) {
              this.backupLogs.splice(index, 1);
            }
          });
        }
      });
      this.closeDeleteDialog();
    }
  }
};
</script>
