<template>
  <div>
    <v-text-field
      v-model="inputValue"
      :label="label"
      :value="fieldValue"
      :rules="rules"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      :readonly="readonly"
      :disabled="disabled"
      outlined
      dense
      @input="sendToParent"
    ></v-text-field>
    <v-row v-if="logoSource" class="justify-center mt-n3 mb-6" no-gutters>
      <v-img :src="logoSource" max-width="200px"></v-img>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['label', 'fieldValue', 'rules', 'type', 'placeholder', 'required', 'readonly', 'disabled'],
  data() {
    return {
      inputValue: this.fieldValue,
      logoSource: ''
    };
  },
  watch: {
    inputValue(value) {
      this.getIsImageState(value);
      if (!value) this.logoSource = '';
    }
  },
  mounted() {
    this.getIsImageState(this.fieldValue);
  },
  methods: {
    getIsImageState(value) {
      const item = value.toString();
      const isExtensionImage = item.includes('.png') || item.includes('.jpg') || item.includes('.jpeg') || item.includes('.svg');
      if (isExtensionImage) {
        this.logoSource = !item.includes('http') && !item.includes('https') ? `'http://${item}` : item;
      }
      return isExtensionImage;
    },
    sendToParent() {
      this.$emit('input', this.inputValue);
    }
  }
};
</script>
