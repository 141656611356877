<template>
  <v-card class="preflight-card">
    <div id="preflight-parent" class="preflight-parent"></div>
  </v-card>
</template>

<script>
import moduleLoader from '@aurigma/ui-framework/dist/moduleLoader';

export default {
  props: {
    isArtworkBack: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object
    }
  },
  data: function () {
    return {
      editorObj: null,
      driverObj: null
    };
  },
  computed: {
    productData: function () {
      return this.order.product ? this.order.product : {};
    }
  },
  watch: {
    isArtworkBack: function () {
      console.log('isArtworkBack is changed', this.isArtworkBack);
      this.init();
    }
  },
  mounted: function () {
    this.init();
  },
  destroyed: function () {
    delete Array.prototype.distinct;
  },
  methods: {
    init: async function () {
      const config = await moduleLoader.loadJson('https://dl5.onebrand.net/apps/preflight/stable/config.json');
      config.vars.backendUrl = 'https://dl5.onebrand.net/apps/preflight/stable/';

      // Set step text
      config.steps[config.steps.length - 1].name = '3. Approval';

      if (this.isArtworkBack) {
        config.widgets[0].params.config.warningMessages = [
          {
            position: {
              offset: {
                x: 0,
                y: 0
              },
              alignment: 'bottomRight'
            },
            size: {
              width: 288,
              height: 190
            },
            shape: 'square',
            color: 'rgba(0,0,0,0)',
            text: {
              message: 'Please do not fill this area',
              color: 'rgb(255,0,0)',
              alignment: 'center',
              font: 'Roboto-Regular',
              fontSize: 28
            },
            print: {
              print: false,
              fillColor: '#FF00FF'
            }
          }
        ];
      } else {
        delete config.widgets[0].params.config.warningMessages;
      }

      config.widgets[0].params.config.product.size = {
        width: parseFloat(this.productData.size_y) * 72,
        height: parseFloat(this.productData.size_x) * 72
      };

      config.widgets[0].params.config.product.allowAddPages = false;

      // Set the safety lines
      config.widgets[0].params.config.product.safetyLines = [
        {
          name: 'Safe Area',
          enabled: true,
          stepPx: 5,
          borderRadius: 0,
          widthPx: 1,
          color: '#00ff00',
          altColor: '#00ff00',
          margin: 9,
          pages: [1, 2],
          legend: {
            title: 'Safe area',
            description: 'You must keep any importanat text or images within the safe area'
          }
        }
      ];

      config.widgets[0].params.config.mode = {
        ignoreServerErrors: true
      };

      // Add rules
      config.vars.preflightRules.push(
        {
          type: 'allowedfonts',
          severity: 'warning',
          params: {
            fonts: ['Roboto-Regular', 'Calibri', 'LucidaSans']
          }
        },
        {
          type: 'embeddedfonts',
          severity: 'warning'
        }
      );

      console.log('PreFlight Config: ', config);

      const product = {
        id: this.productData.product_id,
        sku: 'DEFAULT-002',
        name: this.order.product_name,
        description: '',
        price: this.productData.price_per_piece,
        options: [],
        attributes: []
      };

      console.log('PreFlight Product: ', product);

      const settings = {
        customersCanvasBaseUrl: 'https://dl5.onebrand.net/apps/design-editor/stable/'
      };
      const quantity = this.order.mail_qty; // Default: 1;
      const user = { id: this.order.customer_unique_id };

      const driverImportData = require('@aurigma/ui-framework/dist/drivers/default-driver.js'); // good

      const editorImportData = await moduleLoader.dynamicImportDefault('editor', `https://staticjs-aurigma.azureedge.net/ui-framework/4.18.1/editor.js`);

      this.editorObj = editorImportData.editor;
      this.driverObj = driverImportData.ecommerceDriver;

      const ecommerce = await this.driverObj.init(product, this.editorObj, config, settings, null, quantity, user);
      ecommerce.products.current.renderEditor(document.getElementById('preflight-parent'));

      // Initializing the E-commerce Driver.

      ecommerce.cart.onSubmitted.subscribe(data => {
        data.lineItems.forEach(order => {
          console.log('Print files: ', order);
          console.log('Print files: ', order.downloadUrls);
          console.log('Previews: ', order.images);
          if (order.downloadUrls.length) {
            this.saveFileData(order);
          }
        });
      });
    },
    saveFileData: function (preflightResult) {
      const config = preflightResult.data.origins.map((item, index) => {
        return {
          fileName: item.fileName,
          url: preflightResult.downloadUrls[index]
        };
      });

      this.$emit('onUploadFile', config);
    }
  }
};
</script>

<style scoped>
#preflight-parent {
  height: 1000px;
}

.preflight-card {
  padding-left: 2.5em;
}
</style>
