<template>
  <v-container fluid>
    <v-dialog v-model="isEditedFromAddress" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline font-weight-regular grey--text text--darken-2">Edit From Address</v-card-title>
        <v-card-text class="mt-5">
          <v-row v-for="([addressProperty, addressValue], index) in Object.entries(fromAddress)" :key="index" class="justify-center mt-n6">
            <v-col cols="12">
              <v-text-field
                :key="index"
                v-model="fromAddress[addressProperty]"
                :label="formatAddress(addressProperty)"
                :value="addressValue"
                :rules="checkZipCode(addressProperty)"
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-n7 justify-end">
          <v-btn text class="mr-2" color="grey darken-3" @click="closeFromAddressEditDialog()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isDmmResultVisible" max-width="500" persistent>
      <v-card>
        <v-card-title class="justify-end">
          <v-btn icon color="grey darken-2" class="mr-n1" @click="closeDmmResultDialog()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center mt-n5 mb-1">
            <v-icon :color="isDmmStatusSuccess ? 'green' : 'red'" size="60">{{ isDmmStatusSuccess ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline' }}</v-icon>
          </v-row>
          <v-row class="justify-center mt-4 mb-1">
            <span v-if="isDmmStatusSuccess" class="headline font-weight-medium green--text text--darken-2">Success</span>
            <span v-else class="headline font-weight-medium red--text text--darken-2">Failed</span>
          </v-row>
          <v-row class="justify-center dmm-result-message">
            <p class="title font-weight-medium grey--text text--darken-2 my-2">
              {{ dmmResult.message }}
            </p>
          </v-row>
          <v-row v-if="isDmmStatusSuccess && addresses > 0" class="justify-center">
            <p class="title text-center font-weight-medium grey--text text--darken-2 mb-5">
              Letters will be mailed as soon as the processing of {{ addresses }} addresses completed
            </p>
          </v-row>
          <v-row v-if="!isDmmStatusSuccess" class="justify-center mt-3 mb-n2">
            <v-btn color="orange darken-2 white--text" :loading="isLetterCreationInProgress" block @click="createLetters"> <v-icon class="mr-2">mdi-restore</v-icon>Retry </v-btn>
          </v-row>
          <v-row v-if="isDmmStatusSuccess" class="justify-center my-3">
            <v-btn block color="blue-grey darken-2" class="white--text" @click="returnToOsprey()">
              <v-img class="mr-4" src="@/assets/logos/ospreyLogo.png" max-width="22" height="30"></v-img>
              Return to Osprey
            </v-btn>
          </v-row>
          <v-row v-if="isDmmStatusSuccess" class="mb-n2">
            <v-btn block color="primary" @click="viewOrderInStamp()">View Order Details</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card class="mb-0" elevation="4">
      <p class="title font-weight-regular pt-4 pl-3">Send Letters with DMM</p>
      <v-stepper v-model="steps">
        <v-stepper-header>
          <v-stepper-step :complete="steps > 1" step="1">Choose Options</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="steps > 2" step="2">Register Custom Fields (optional)</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="steps > 3" step="3">Upload Artwork</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="steps > 4" step="4">Get Counts And Create Mailing List</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="5">Send Letter</v-stepper-step>
        </v-stepper-header>
        <div id="dmm-cncw-alerts">
          <v-alert v-if="feedbackAlert" :type="feedbackType" dismissible outlined prominent dense>{{ feedbackMessage }}</v-alert>
        </div>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card class="elevation-0">
              <v-card-text>
                <v-container>
                  <v-row class="justify-center">
                    <v-col>
                      <p class="subtitle-1 mt-5 mr-5 text-right">Color Options</p>
                    </v-col>
                    <v-col>
                      <v-radio-group v-model="colorOption" row disabled>
                        <v-radio label="Black & White" :value="false"></v-radio>
                        <v-radio label="Color" :value="true"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="justify-center">
                    <v-col>
                      <p class="subtitle-1 mt-5 mr-5 text-right">Double-sided</p>
                    </v-col>
                    <v-col>
                      <v-switch v-model="isDoubleSided" inset disabled></v-switch>
                    </v-col>
                  </v-row>
                  <v-row class="justify-center">
                    <v-col>
                      <p class="subtitle-1 mt-5 mr-5 text-right">Insert Blank Page First</p>
                    </v-col>
                    <v-col>
                      <v-switch v-model="insertBlankPage" inset disabled></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-row class="justify-center mb-2">
                <v-btn color="primary" @click="goToStep(2)">Continue</v-btn>
              </v-row>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card class="elevation-0">
              <v-card-text>
                <DmmCustomFields :customer-id="customerId" />
              </v-card-text>
              <v-row class="justify-center mb-2">
                <v-btn color="primary" outlined @click="goToStep(1)">Back</v-btn>
                <v-btn color="primary" class="ml-8" @click="goToStep(3)">Continue</v-btn>
              </v-row>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-card class="elevation-0">
              <v-card-text>
                <LetterArtworkTool
                  :colorOption="colorOption"
                  :orderData="orderObject"
                  :doubleSided="isDoubleSided"
                  :insertBlankPage="insertBlankPage"
                  @artworkChanged="loadArtwork"
                  @onError="showArtworkError"
                />
              </v-card-text>
              <v-row class="justify-center mb-2">
                <v-btn color="primary" outlined @click="goToStep(2)">Back</v-btn>
                <v-btn v-if="isArtworkGenerated" color="primary" class="ml-8" @click="goToStep(4)">Continue</v-btn>
              </v-row>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-card class="pt-5 mb-4 elevation-0">
              <v-card-text>
                <ImportAddresses
                  v-if="isSelectAddressesOptionsShown"
                  :isImportOptionsVisible="isSelectAddressesOptionsShown"
                  :initialList="mailingList"
                  :orderId="ospreyOrderId"
                  :mailDropId="ospreyMailDropId"
                  :orderType="orderType"
                  :isCustomFieldsEnabled="true"
                  @downloadURL="csvFileDownloadUrl"
                  @notification="displayNotification"
                />
                <v-row v-if="addresses > 0" class="justify-center ml-n5">
                  <v-col cols="12" md="7" xl="5">
                    <v-alert color="#DBE3D3" class="text-center"
                      ><b class="mr-1">{{ addresses }}</b> Addresses Available</v-alert
                    >
                  </v-col>
                </v-row>

                <v-row v-if="uploadedFileUrl" class="ml-n5 mt-n3 justify-center">
                  <v-col cols="12" md="7" xl="5">
                    <v-text-field v-model="mailingListName" label="Mailing List Name" required type="text" outlined></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="uploadedFileUrl && mailingListName" class="justify-center">
                  <v-btn color="primary" class="mr-8" outlined @click="showArtworkStep()">Back</v-btn>
                  <v-btn color="primary" @click="goToStep(5)">Continue</v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="5">
            <v-card>
              <v-card-text class="ml-1">
                <v-row class="justify-space-around">
                  <v-col cols="12" lg="6">
                    <v-row class="mt-n1">
                      <v-icon color="primary" class="ml-n2 mr-2 mt-n4">mdi-chevron-right</v-icon>
                      <p class="subtitle-1 font-weight-medium grey--text text--darken-2">
                        Size:
                        <span class="subtitle-1 font-weight-regular grey--text text--darken-2 ml-1">{{ size }}</span>
                      </p>
                    </v-row>
                    <v-row class="mt-n1">
                      <v-icon color="primary" class="ml-n2 mr-2 mt-n4">mdi-chevron-right</v-icon>
                      <p class="subtitle-1 font-weight-medium grey--text text--darken-2">
                        Color:
                        <span class="subtitle-1 font-weight-regular grey--text text--darken-2 ml-1">{{ colorOption }}</span>
                      </p>
                    </v-row>
                    <v-row class="mt-n1">
                      <v-icon color="primary" class="ml-n2 mr-2 mt-n4">mdi-chevron-right</v-icon>
                      <p class="subtitle-1 font-weight-medium grey--text text--darken-2">
                        Double-sided:
                        <span class="subtitle-1 font-weight-regular grey--text text--darken-2 ml-1">{{ isDoubleSided }}</span>
                      </p>
                    </v-row>
                    <v-row class="mt-n1">
                      <v-icon color="primary" class="ml-n2 mr-2 mt-n4">mdi-chevron-right</v-icon>
                      <p class="subtitle-1 font-weight-medium grey--text text--darken-2">
                        Insert Blank Page First:
                        <span class="subtitle-1 font-weight-regular grey--text text--darken-2 ml-1">{{ insertBlankPage }}</span>
                      </p>
                    </v-row>
                    <v-row class="mt-1 mb-0">
                      <v-icon color="primary" class="ml-n2 mr-2 mt-n3">mdi-chevron-right</v-icon>
                      <p class="subtitle-1 font-weight-medium grey--text text--darken-2">
                        Mailing Date Range:
                        <span class="subtitle-1 font-weight-regular grey--text text--darken-2 ml-1">{{ mailingDateRange }}</span>
                      </p>
                    </v-row>
                    <v-row v-if="addresses" class="mt-n1">
                      <v-icon color="primary" class="ml-n2 mr-2 mt-n4">mdi-chevron-right</v-icon>
                      <v-tooltip bottom open-delay="150">
                        <template v-slot:activator="{ on }">
                          <p class="subtitle-1 font-weight-medium grey--text text--darken-2">
                            Addresses:
                            <span class="subtitle-1 font-weight-regular grey--text text--darken-2 ml-1" v-on="on">{{ addresses }}</span>
                          </p>
                        </template>
                        <span>Addresses will be recalculated based on a date period</span>
                      </v-tooltip>
                    </v-row>
                    <v-row class="mt-n4 mb-n6">
                      <v-icon color="primary" class="ml-n2 mr-2 mt-n6">mdi-chevron-right</v-icon>
                      <p class="subtitle-1 font-weight-medium grey--text text--darken-2 pt-4">Send Date:</p>
                      <v-col cols="6" sm="4" xl="3" class="mt-n2">
                        <v-menu v-model="sendDateMenu" :close-on-content-click="false" max-width="290">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="sendDateComputed"
                              v-bind="attrs"
                              outlined
                              dense
                              class="mt-1"
                              style="cursor: pointer"
                              append-icon="mdi-calendar"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="sendDate" :allowed-dates="allowedDates" @change="sendDateMenu = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <v-row class="mt-n4">
                      <v-icon color="primary" class="mt-n2 ml-n2 mr-2">mdi-chevron-right</v-icon>
                      <p class="subtitle-1 font-weight-medium grey--text text--darken-2 pt-4 mr-6">Choose Mail Type:</p>
                      <v-radio-group v-model="postage" row class="mt-3">
                        <v-radio label="First Class Postage" value="first_class" color="red darken-2" :disabled="!isFirstClassAvailable"></v-radio>
                        <v-radio label="Standard Postage" value="standard" color="red darken-2"></v-radio>
                      </v-radio-group>
                    </v-row>
                    <v-row class="mt-1">
                      <v-icon color="primary" class="ml-n2 mt-n9 mr-2">mdi-chevron-right</v-icon>
                      <v-col cols="11" sm="8" xl="4" class="mt-n4 ml-n3">
                        <v-text-field v-model="letterDescription" label="Description - optional" outlined dense :rules="[v => v.length <= 80 || 'Max 80 characters!']">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-row class="mt-n3 mb-3">
                      <p class="subtitle-1 font-weight-bold grey--text text--darken-3">Mail From Address:</p>
                      <v-tooltip bottom open-delay="300">
                        <template v-slot:activator="{ on }">
                          <v-btn class="ml-5" color="blue-grey darken-1" outlined small v-on="on" @click="isEditedFromAddress = true">
                            <v-icon class="mr-2">mdi-pencil</v-icon>Edit Address
                          </v-btn>
                        </template>
                        <span>Edit Mail From Address</span>
                      </v-tooltip>
                    </v-row>
                    <template v-if="Object.values(fromAddress)[0]">
                      <v-row v-for="(addrInfo, index) in Object.entries(fromAddress)" :key="index" class="justify-start">
                        <v-col cols="12" lg="10" xl="8" class="mt-n2">
                          <v-row>
                            <v-icon color="primary" class="ml-n2 mr-2 mt-n4">mdi-chevron-right</v-icon>
                            <p class="subtitle-1 font-weight-medium grey--text text--darken-2">
                              {{ formatAddress(addrInfo[0]) }}:
                              <span class="subtitle-1 font-weight-regular grey--text text--darken-2 ml-1">{{ addrInfo[1] }}</span>
                            </p>
                          </v-row>
                        </v-col>
                      </v-row>
                    </template>
                    <v-row v-else class="mt-2 offset-sm-1">
                      <v-progress-circular color="primary" indeterminate size="50"></v-progress-circular>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="Object.values(fromAddress)[0] && !isDmmResultVisible" class="justify-center mb-7">
                  <v-btn color="primary" class="mr-8" outlined @click="goToStep(4)">Back</v-btn>
                  <v-btn color="red darken-2 white--text mr-2" :loading="isLetterCreationInProgress" :disabled="isLetterCreationDisabled" @click="createLetters">
                    <v-icon color="white" class="mr-2">mdi-send</v-icon>Send Letters
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-container>
</template>

<script>
import ImportAddresses from '../tools/DmmCustomImport.vue';
import LetterArtworkTool from './LetterArtworkTool.vue';
import DmmCustomFields from './DmmCustomFields.vue';
import { errorHandler } from '@/modules/helpers/errorHandler';

export default {
  components: {
    ImportAddresses,
    LetterArtworkTool,
    DmmCustomFields
  },
  props: ['firestoreMapId', 'orderId', 'customerId', 'startDate', 'endDate', 'mailDropId', 'orderData', 'dropNr', 'campaignId', 'isRetry'],
  data() {
    return {
      steps: 1,
      mapId: this.firestoreMapId,
      ospreyOrderId: this.orderId,
      ospreyMailDropId: this.mailDropId,
      size: '11x8.5',
      feedbackType: 'info',
      feedbackMessage: '',
      feedbackAlert: false,
      mailingListName: '',
      startDateParam: this.startDate,
      endDateParam: this.endDate,
      menuStartDMM: false,
      menuEndDMM: false,
      firstDateDMM: '',
      endDateDMM: '',
      addresses: 0,
      postage: '',
      selectedCsvDateRange: 'default',
      dmmResult: {
        status: '',
        message: ''
      },
      letterDescription: '',
      sendDate: this.getNextDay(),
      sendDateMenu: false,
      fromAddress: {
        address_line1: '',
        address_line2: '',
        address_city: '',
        address_state: '',
        address_zip: ''
      },
      isEditedFromAddress: false,
      isLetterCreationInProgress: false,
      headerMapping: {},
      uploadedFileUrl: '',
      isDmmResultVisible: false,
      weekendDayIndexes: [0, 6], //0-Sunday; 6-Saturday
      zipCodeRule: [
        v => !!v || `ZIP code is Required`,
        v => (v && !isNaN(v)) || `ZIP code must contain only numbers!`,
        v => (v && v.length === 5) || `ZIP code must be 5 numbers!`
      ],
      colorOption: false,
      isDoubleSided: false,
      insertBlankPage: false,
      artworkData: null
    };
  },
  computed: {
    orderObject() {
      return this.orderData || {};
    },
    mailingList() {
      return this.orderObject?.assets?.mailing_list?.[0] || '';
    },
    orderType() {
      return this.orderObject?.order_type;
    },
    computedStart() {
      return this.startDateParam;
    },
    computedEnd() {
      return this.endDateParam;
    },
    firstDateComp() {
      return this.firstDateDMM;
    },
    endDateComp() {
      return this.endDateDMM;
    },
    sendDateComputed() {
      return this.sendDate ? this.$moment(this.sendDate).format('YYYY-MM-DD') : '';
    },
    isSelectAddressesOptionsShown() {
      return !this.uploadedFileUrl;
    },
    defaultListName() {
      return `${this.ospreyOrderId ?? 'list'}_${this.ospreyMailDropId}_${this.dropNr}`;
    },
    isDmmStatusSuccess() {
      return this.dmmResult?.status === 'success';
    },
    isFromAddressZipCodeValid() {
      return this.fromAddress?.address_zip?.length === 5 && !isNaN(this.fromAddress?.address_zip);
    },
    isDateRangeSelectorVisible() {
      return this.computedStart !== this.startDate || this.computedEnd !== this.endDate;
    },
    defaultDateRange() {
      return `${this.$moment().subtract(30, 'days').format('YYYY-MM-DD')} - ${this.$moment().format('YYYY-MM-DD')}`;
    },
    mailingDateRange() {
      return this.selectedCsvDateRange === 'default' ? this.defaultDateRange : `${this.computedStart} - ${this.computedEnd}`;
    },
    firebaseOrderId() {
      return this.orderObject?.firebase_order_id;
    },
    isArtworkGenerated() {
      return !!this.artworkData?.data?.artwork_thumbnails_front?.pdf;
    },
    isFirstClassAvailable() {
      return this.addresses >= 500;
    },
    isLetterCreationDisabled() {
      return !this.isFromAddressZipCodeValid || !this.postage;
    }
  },
  watch: {
    firestoreMapId(value) {
      this.mapId = value;
    },
    orderId(value) {
      this.ospreyOrderId = value;
    },
    startDate(value) {
      this.startDateParam = value;
    },
    endDate(value) {
      this.endDateParam = value;
    },
    firstDateComp(value) {
      if (value) {
        this.startDateParam = value;
      }
    },
    endDateComp(value) {
      if (value) {
        if (this.$moment(this.startDateParam).unix() > this.$moment(value).unix()) {
          this.setFeedback('warning', 'End date cannot be smaller than start date!');
        }
        this.endDateParam = value;
      }
    },
    sendDate(value) {
      const nextDay = this.getNextDay();
      if (value < nextDay) {
        this.sendDate = nextDay;
      }
    },
    mailDropId(value) {
      this.ospreyMailDropId = value;
    },
    steps(value) {
      if (value === 3) {
        this.getFromAddressData();
      }
    },
    isFromAddressZipCodeValid(valid) {
      if (!valid) {
        this.setFeedback('error', 'The given ZIP Code for Mail From address is invalid!');
      } else {
        this.feedbackAlert = false;
      }
    },
    selectedCsvDateRange(value) {
      if (value === 'default') {
        this.firstDateDMM = this.startDate;
        this.endDateDMM = this.endDate;
      }
    }
  },
  async mounted() {
    this.mailingListName = this.defaultListName;
    if (this.weekendDayIndexes.includes(this.$moment(this.sendDate).weekday())) {
      this.sendDate = this.$moment().add(1, 'weeks').day(1).format('YYYY-MM-DD');
    }
    await this.getProductByOrderId();
    this.getLetterOptions();
    this.postage = this.orderData?.mailing?.postage || '';
  },
  methods: {
    goToStep(step) {
      this.steps = step;
    },
    getNextDay() {
      return this.$moment().add(1, 'days').format('YYYY-MM-DD');
    },
    getLetterOptions() {
      this.colorOption = this.orderData?.product?.letter_color?.value || false;
      this.isDoubleSided = this.orderData?.product?.letter_sides?.value || false;
      this.insertBlankPage = this.orderData?.product?.letter_blank_page?.value || false;
    },
    async createLetters() {
      this.isLetterCreationInProgress = true;
      this.$store.dispatch('showLoader', { message: 'Creating letters in progress...' });
      try {
        const passingObj = this.getDropExecutionConfig();
        if (this.letterDescription?.length < 80) {
          passingObj.description = this.letterDescription;
        }
        if (this.isRetry) {
          passingObj.is_retry = true;
        }
        console.log('[ CREATE LETTER Payload ] ::', passingObj);

        const response = await this.$store.dispatch('letters/createLetters', passingObj);
        const dropInformations = response.data ?? '';
        if (dropInformations) {
          console.log('[ Create Letters Result]:', dropInformations);
          this.addresses = dropInformations.drop_quantity;
          this.setDmmResult('success', 'Mailing list created successfully');
        }
      } catch (error) {
        return this.onError(error, '[ Error in sending letters ]:');
      } finally {
        this.isLetterCreationInProgress = false;
        this.$store.dispatch('hideLoader');
      }
    },
    getDropExecutionConfig() {
      return {
        firebase_order_id: this.orderObject.firebase_order_id,
        mail_drop_id: parseInt(this.ospreyMailDropId),
        drop_date: this.sendDate,
        drop_num: parseInt(this.dropNr),
        csv_link: this.uploadedFileUrl || '',
        list_name: this.mailingListName,
        header_mapping: this.headerMapping,
        size: this.size,
        mail_type: this.postage,
        from_address: this.fromAddress,
        color: this.colorOption,
        double_sided: this.isDoubleSided
      };
    },
    async getFromAddressData() {
      try {
        const result = await this.$store.dispatch('om_orders/getOrderDataDoc', { orderId: this.orderObject.firebase_order_id, docName: 'customer' });
        this.fromAddress = {
          address_line1: result.customer_location.street || '',
          address_line2: '',
          address_city: result.customer_location.city || '',
          address_state: result.customer_location.state || '',
          address_zip: result.customer_location.zip_code || ''
        };
        if (result.additional.company_name) {
          this.fromAddress.company_name = result.additional.company_name;
        }
      } catch (error) {
        this.onError(error, '[ Error in getting From Address ]:');
      }
    },
    returnToOsprey() {
      window.open(`${process.env.VUE_APP_OSPREY_ENDPOINT}/orders/view.cfm?id=${this.orderId}`);
    },
    setDmmResult(status, message) {
      this.isDmmResultVisible = true;
      this.dmmResult.status = status;
      this.dmmResult.message = message;
    },
    setFeedback(type, message) {
      this.feedbackType = type;
      this.feedbackMessage = message;
      this.feedbackAlert = true;
      const element = document.getElementById('dmm-cncw-alerts');
      const top = element.offsetTop;
      window.scrollTo({
        behavior: 'smooth',
        top: top + 300
      });
      setTimeout(() => {
        this.feedbackAlert = false;
      }, 20000);
    },
    formatAddress(addressTitle) {
      let splitted = addressTitle.split('_');
      let formatted = '';
      splitted.forEach((chunk, index) => {
        formatted += `${index > 0 ? ' ' : ''}${chunk.charAt(0).toUpperCase()}${chunk.slice(1)}`;
      });
      return formatted;
    },
    csvFileDownloadUrl(data) {
      console.log('[ Uploaded file data ] :: ', data);
      this.addresses = data.totalCount;
      this.uploadedFileUrl = data.url;
      this.headerMapping = data.headers;
    },
    getThumbnailUid(url) {
      return url.substring(url.indexOf('thumbnails/'), url.indexOf('_')).split('/')[1];
    },
    closeDmmResultDialog() {
      this.isDmmResultVisible = false;
    },
    viewOrderInStamp() {
      this.$router.push({
        path: `/orders/view/${this.orderObject.firebase_order_id}`
      });
    },
    getDateProperty(date, propertyName) {
      return parseInt(this.$moment(date || {}).get(propertyName));
    },
    allowedDates(date) {
      return (
        !this.weekendDayIndexes.includes(this.$moment(date).weekday()) &&
        (this.getDateProperty(date, 'year') > this.getDateProperty('', 'year') ||
          this.getDateProperty(date, 'month') > this.getDateProperty('', 'month') ||
          (this.getDateProperty(date, 'month') === this.getDateProperty('', 'month') && this.getDateProperty(date, 'date') > this.getDateProperty('', 'date')))
      );
    },
    checkZipCode(field) {
      return field === 'address_zip' ? this.zipCodeRule : [];
    },
    closeFromAddressEditDialog() {
      this.isEditedFromAddress = false;
    },
    onError(error, errorLogMessage) {
      const errorData = errorHandler(error);
      console.log(errorLogMessage, errorData);
      this.setDmmResult('error', `${errorData.statusCode}: ${errorData.statusText} - ${errorData.message}`);
    },
    showArtworkError(error) {
      this.setFeedback('error', error?.response?.data?.message || error?.message || 'Unexcepted error in Artwork creation.');
    },
    displayNotification(data) {
      if (data?.type && data?.message) {
        this.setFeedback(data.type, data.message);
      }
      if (data?.hasOwnProperty('isVisible')) {
        this.feedbackAlert = data.isVisible;
      }
    },
    showArtworkStep() {
      this.goToStep(3);
      this.addresses = 0;
      this.uploadedFileUrl = '';
      this.headerMapping = {};
    },
    loadArtwork(data) {
      this.artworkData = data;
    },
    async getProductByOrderId() {
      this.orderObject.product = await this.$store.dispatch('getOrderDataItemByOrderId', {
        firebase_order_id: this.firebaseOrderId,
        order_data_item: 'product'
      });
    }
  }
};
</script>

<style scoped>
.dmm-result-message {
  overflow-wrap: anywhere;
}
</style>
