<template>
  <v-card elevation="0">
    <v-card-text class="mt-4">
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-switch
              v-model="isTaxExemptRequested"
              class="ml-4"
              inset
              :label="`Tax exempt requsted: ${isTaxExemptRequested.toString().toUpperCase()}`"
              :disabled="isCustomerUpdating"
            ></v-switch>
          </v-row>
          <v-row>
            <v-switch
              v-model="isTaxExemptApproved"
              class="ml-4"
              inset
              :label="`Tax exempt approved: ${isTaxExemptApproved.toString().toUpperCase()}`"
              :disabled="isCustomerUpdating"
            ></v-switch>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="taxNote"
                label="Note"
                placeholder="Reference"
                outlined
                dense
                :disabled="isCustomerUpdating"
                :rules="[value => !!value || 'You must provide a note/reference.']"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn :disabled="isSaveDisabled" @click="updateCustomer">Save</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="customer.tax_status_modified" cols="12" md="6">
          <ul>
            <li v-for="(data, index) in customerTaxInfo" :key="index">
              <v-row>
                <v-col cols="6">
                  <p class="font-weight-medium grey--text text--darken-1 my-0">{{ data.attribute_name }}</p>
                </v-col>
                <v-col cols="6">
                  <p class="black--text my-0">{{ data.value }}</p>
                </v-col>
              </v-row>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: ['customer'],
  data() {
    return {
      customerId: this.$route.params.customerId,
      isTaxExemptRequested: false,
      isTaxExemptApproved: false,
      taxNote: '',
      isCustomerUpdating: false
    };
  },
  computed: {
    ...mapGetters('users', {
      user: 'user'
    }),
    customerTaxInfo() {
      return [
        { attribute_name: 'Name', value: this.customer?.tax_status_modified?.name },
        { attribute_name: 'Firebase User ID', value: this.customer?.tax_status_modified?.firebase_id },
        { attribute_name: 'Last modified on', value: this.formatDate(this.customer?.tax_status_modified?.date) },
        { attribute_name: 'Note', value: this.customer?.tax_status_modified?.note }
      ];
    },
    isSaveDisabled() {
      return this.isCustomerUpdating || !this.taxNote;
    }
  },
  mounted() {
    this.isTaxExemptRequested = this.customer?.is_tax_exempt_requested || false;
    this.isTaxExemptApproved = this.customer?.is_tax_exempt_approved || false;
    this.taxNote = this.customer?.tax_status_modified?.note || '';
  },
  methods: {
    formatDate(value) {
      if (!value) return '';
      return this.$moment(value).format('lll (UTC Z)');
    },
    async updateCustomer() {
      let taxPayload = {
        is_tax_exempt_requested: this.isTaxExemptRequested,
        is_tax_exempt_approved: this.isTaxExemptApproved,
        tax_status_modified: {
          name: this.user.name,
          firebase_id: this.user.id,
          date: Date.now(),
          note: this.taxNote
        }
      };
      try {
        this.isCustomerUpdating = true;
        this.$store.dispatch('showLoader', { message: 'Updating Customer taxable status...' });
        await this.$store.dispatch('customers/updateCustomer', { id: this.customerId, data: taxPayload });
        this.$store.dispatch('setSnackbar', { type: 'success', message: 'Customer updated successfully!' });
      } catch (error) {
        console.log('[updateCustomerInfo] ERROR:', error);
        this.$store.dispatch('setSnackbar', { type: 'error', message: 'Something went wrong while updating customer tax status!' });
      } finally {
        this.isCustomerUpdating = false;
        this.$store.dispatch('hideLoader');
      }
    }
  }
};
</script>
