<template>
  <div class="site-table">
    <stamp-search-order
      title="Orders"
      :isOrderStatusFilterVisible="false"
      :isOrderTypeFilterVisible="true"
      selectedOrderStatus="incomplete"
      @updateOrderList="updateOrderList"
      @onWrongFilter="onWrongFilter"
      @onLoadingChanged="onLoadingChanged"
    ></stamp-search-order>
    <v-row>
      <v-col>
        <v-alert v-model="isEndDateSmaller" type="error" dismissible outlined> End date cannot be less than Start date! Please choose another End date. </v-alert>
        <v-row class="mt-2">
          <v-col cols="12" sm="12" class="pa-0">
            <stamp-data-table
              :headers="headers"
              :data="orders"
              :is-loading="isLoadingVisible"
              :is-items-per-page-disabled="true"
              :total-server-items-nr="totalOrders"
              item-key="firebase_order_id"
              :sort-by="sortBy"
              :sort-desc="true"
              :current-page="currentPage"
              @options="optionsHandler"
            >
              <template #[`item.creation_date_timestamp`]="{ item }">{{ formatDate(item.creation_date_timestamp) }}</template>
              <template #[`item.brand_id`]="{ item }">{{ getBrandId(item.site_id) }}</template>
              <template #[`item.mailing_service`]="{ item }">{{ item.mailing_service === 'none' ? 'custom' : item.mailing_service }}</template>
            </stamp-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SearchOrder from '@/components/order-management/orders/SearchOrder.vue';
import DataTable from '@/components/common/DataTable.vue';

export default {
  name: 'orders-algolia',
  components: {
    'stamp-search-order': SearchOrder,
    'stamp-data-table': DataTable
  },
  data() {
    return {
      search: '',
      result: [],
      totalOrders: null,
      isLoadingVisible: false,
      orders: [],
      headers: [
        { text: 'Date', align: 'center', value: 'creation_date_timestamp' },
        { text: 'Brand', value: 'brand_id', align: 'left' },
        { text: 'Status', value: 'order_status.status', align: 'left' },
        { text: 'Customer', value: 'first_name', align: 'left' },
        { text: 'Osprey Customer ID', value: 'crm_customer_id', align: 'left' },
        { text: 'Email', value: 'order_email', align: 'left' },
        { text: 'Order label', value: 'label', align: 'center' },
        { text: 'Print Qty', value: 'print_qty', align: 'left' },
        { text: 'Print Name', value: 'product_name', align: 'left' },
        { text: 'Order Type', value: 'mailing_service', align: 'left' }
      ],
      options: {},
      sortBy: 'creation_date_timestamp',
      isItemsPerPageDisabled: true,
      date: new Date().toISOString().substr(0, 10),
      isEndDateSmaller: false,
      setHitsPerPage: 15,
      commonFilter: 'order_status.status: incomplete',
      currentPage: 1
    };
  },
  methods: {
    updateOrderList(payload) {
      this.totalOrders = payload.totalOrders;
      this.orders = payload.orders;
      this.commonFilter = payload.commonFilter;
      this.search = payload.search;
      this.options.page = payload.options.page;
    },
    sortAndPaginate() {
      this.isLoadingVisible = true;
      const { sortBy, sortDesc, page } = this.options;
      // sort by creation_date ascending order
      const algoliaIndexName =
        !Object.values(sortBy).length && !Object.values(sortDesc).length
          ? `orders_creation_date_timestamp_asc${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`
          : `orders${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`;

      return this.$store
        .dispatch('algolia/getDataFromAlgolia', {
          indexName: algoliaIndexName,
          filter: this.commonFilter,
          searchValue: this.search ? this.search : '',
          page: page > 1 ? page - 1 : 0
        })
        .then(content => {
          this.totalOrders = content.nbHits;
          this.orders = content.hits;
          return Promise.resolve();
        })
        .catch(err => {
          console.log(err, err.debugData);
          return Promise.reject(err);
        })
        .finally(() => (this.isLoadingVisible = false));
    },
    onWrongFilter(isEndDateSmaller) {
      this.isEndDateSmaller = isEndDateSmaller;
    },
    onLoadingChanged(isVisible) {
      this.isLoadingVisible = isVisible;
    },
    formatDate(dateTotransform) {
      const date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    getBrandId(siteId) {
      return siteId.split('-')[1] || '-';
    },
    async optionsHandler(options) {
      this.options = options;
      await this.sortAndPaginate();
      this.currentPage = this.options.page;
    }
  }
};
</script>
<style>
.custom-date {
  position: absolute;
}
.stamp-custom-checkbox {
  margin-top: 24px;
}
.stamp-orders-data-table .v-data-footer .v-data-footer__select {
  display: none !important;
}
.stamp-order-custom-td .v-input__slot {
  margin-bottom: 0px !important;
}
</style>
