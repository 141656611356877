<template>
  <v-dialog v-model="updateAttributesDialog" max-width="700" persistent>
    <v-dialog v-model="dialogNewValue" max-width="500">
      <v-card>
        <v-card-title class="headline">Atrributes new value</v-card-title>
        <v-card-text>
          <p>Select attribute type</p>
          <v-radio-group v-model="attributes" :mandatory="false">
            <v-radio label="String" value="string"></v-radio>
            <v-radio label="Boolean" value="boolean"></v-radio>
            <v-radio label="Array" value="array"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogNewValue = false"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title class="headline red">
        <div class="text-xs-center">
          <span class="white--text text-md-center">Update new attribute only for user_defined_fields</span>
        </div>
      </v-card-title>
      <v-card-text>
        <v-alert v-model="emptyAttrName" type="warning" dismissible outlined> Attribute name cannot be empty! </v-alert>
        <v-row align="center" justify="start">
          <v-col cols="12" sm="10">
            <v-text-field v-model="dbSiteId" label="site ID" type="text"></v-text-field>
            <v-btn color="primary" @click="getAttributes()"> check attr </v-btn>
            <div>
              <v-select v-model="selectedAttribute" :items="attributesList" label="Order Type"></v-select>
            </div>
          </v-col>
        </v-row>
        <v-row align="center" justify="start">
          <v-col cols="12" sm="10">
            <h3>Raport:</h3>
            <p>
              Attribute type: <span>{{ typeofCheck }}</span>
            </p>
            <P
              >Value:

              <span v-if="typeofCheck !== 'object'">
                <span v-if="typeofCheck === 'boolean'">
                  {{ attributeValue }}
                </span>
                <span v-if="typeofCheck === 'string'">
                  {{ attributeValue }}
                  <v-text-field v-model="selectedAttrValue" type="text"> </v-text-field>
                </span>
                <span v-if="typeofCheck === 'number'">
                  {{ attributeValue }}
                  <v-text-field v-model="selectedAttrValue" type="text"> </v-text-field>
                </span>
              </span>
              <span v-else>
                <vue-json-pretty :data="attributeValue" :show-double-quotes="true" :show-length="true" :show-line="true"> </vue-json-pretty>
              </span>
            </P>
            <v-btn color="primary" class="mr-3" @click="updateValue()"> Upadate Value </v-btn>
            <v-btn color="primary" @click="addNewValue()"> Add new value(Beta) </v-btn>
          </v-col>
        </v-row>
        <v-row align="center" justify="start">
          <v-col cols="12" sm="10">
            <h3>Update Raport:</h3>
            <div v-if="updateStatus === 1">
              <v-alert dense type="info"> Update Successfull. </v-alert>
              <h4></h4>
              [
              <div v-for="(item, index) in newUserDefinedAttributesReport" :key="index">
                <vue-json-pretty :data="item" :show-double-quotes="true" :show-length="true" :show-line="true"> </vue-json-pretty>
              </div>
              ]
            </div>
            <div v-else-if="updateStatus === 2">
              <v-alert dense type="error"> Error on update the user_defined_fields! </v-alert>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="closeUpdateAttributesDialog"> Cancel </v-btn>
      </v-card-actions>
      <v-progress-linear v-if="loading" :indeterminate="loading" class="my-0"></v-progress-linear>
    </v-card>
  </v-dialog>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import siteSettingsService from '../../../services/siteSettingsService.js';

export default {
  components: {
    VueJsonPretty
  },
  props: ['value'],
  data() {
    return {
      updateAttributesDialog: this.value,
      dbSiteId: '',
      attributesList: [],
      selectedAttribute: '',
      typeofCheck: '',
      attributeValue: null,
      selectedAttrValue: '',
      dialogNewValue: false,
      attributes: '',
      attrName: '',
      emptyAttrName: false,
      loading: false,
      attrFoundList: [],
      headers: [
        { text: 'Site ID', value: 'siteId', align: 'left' },
        { text: 'Site Name', value: 'siteName', align: 'left' }
      ],
      search: '',
      updateStatus: 0,
      mapObject: { test: 'test' },
      newUserDefinedAttributesReport: []
    };
  },
  computed: {},
  watch: {
    value(value) {
      this.updateAttributesDialog = this.value;
    },
    selectedAttribute(value) {
      console.log('val:', value);
      if (value) {
        siteSettingsService
          .getById(this.dbSiteId)
          .then(site => {
            Object.entries(site).forEach(([key, val]) => {
              if (key === value) {
                this.typeofCheck = typeof val;
                this.attributeValue = val;
                this.selectedAttrValue = val;
              }
            });
          })
          .catch(error => {
            console.log('selectedAttribute has failed: ', error);
          });
      }
    }
  },
  methods: {
    closeUpdateAttributesDialog() {
      this.attrFoundList = [];
      this.dbSiteId = '';
      this.selectedAttrValue = '';
      this.attributesList = [];
      this.attributeValue = '';
      this.typeofCheck = '';
      this.updateStatus = 0;
      this.$emit('closeUpdateAttributesDialog');
    },
    getAttributes() {
      this.attributesList = [];
      this.selectedAttribute = '';
      console.log('dbSiteId: ', this.dbSiteId);

      siteSettingsService.getById(this.dbSiteId);
      siteSettingsService
        .getById(this.dbSiteId)
        .then(site => {
          Object.entries(site).forEach(([key, totals]) => {
            this.attributesList.push(key);
            if (key === 'user_defined_fields') {
              this.selectedAttribute = key;
            }
          });
        })
        .catch(error => {
          console.log('getAttributes has failed: ', error);
        });
    },
    updateValue() {
      console.log('updateValue');
      console.log('this.dbSiteId:', this.dbSiteId);
      console.log('this.selectedAttrValue:', this.selectedAttrValue);
      console.log('this.attributeValue:', this.attributeValue);
      let newUserDefinedAttributes = [];
      const attrLength = this.attributeValue.length;
      const totalNumber = 5;
      const diff = totalNumber - attrLength;
      const attrArray = this.attributeValue;
      for (let i = 0; i < diff; i++) {
        attrArray.push('');
      }
      attrArray.forEach((value, index) => {
        const indexArr = index + 1;
        newUserDefinedAttributes.push({
          field_label: value,
          field_description: '',
          field_name: '',
          field_type: 'text',
          field_id: `order_field_${indexArr}`
        });
      });

      console.log('newUserDefinedAttributes: ', newUserDefinedAttributes);
      this.newUserDefinedAttributesReport = newUserDefinedAttributes;
      siteSettingsService
        .update(this.dbSiteId, {
          user_defined_fields: newUserDefinedAttributes
        })
        .then(() => {
          console.log('update Done');
          this.updateStatus = 1;
        })
        .catch(err => {
          this.updateStatus = 2;
        });
    },
    addNewValue() {
      this.dialogNewValue = true;
    },
    deleteAttribute() {
      const attrObj = {
        attrList: this.attrFoundList,
        attrName: this.attrName
      };
      if (this.attrName.length < 1) {
        this.emptyAttrName = true;
      } else {
        this.$emit('deleteAttribute: ', attrObj);
      }
    },
    async checkForAttributes() {
      try {
        if (this.attrName.length < 1) {
          this.emptyAttrName = true;
        } else {
          this.attrFoundList = [];
          const attrName = this.attrName;
          this.loading = true;
          this.sitesList = await siteSettingsService.getAll({ isArray: true });
          this.sitesList.forEach((value, index) => {
            Object.entries(value).forEach(([key, valueO]) => {
              if (key === attrName) {
                this.attrFoundList.push({
                  siteId: value.site_id,
                  siteName: value.site_name,
                  firebaseId: value.firebase_id
                });
              }
            });
          });
          console.log('found list: ', this.attrFoundList);
          this.loading = false;
        }
      } catch (error) {
        console.log('checkForAttributes has failed: ', error);
      }
    }
  }
};
</script>
<style scoped>
.stamp-dsa-wrapper {
  width: 100%;
}
</style>
