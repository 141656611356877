<template>
  <v-container fluid>
    <subscription-edit-warning
      v-if="!isComponentUsedInDmmTool"
      :isVisible="campaignEditNotification.isVisible"
      :firebaseOrderId="orderId"
      :isOrderUpdatedSuccessfully="isOrderUpdatedSuccessfully"
      class="mt-n8"
      @subscriptionStatus="showSubscriptionStatusChange"
    >
    </subscription-edit-warning>

    <div id="dmm-cncw-alerts">
      <v-alert v-if="isArtworkErrorShown" type="error" dismissible outlined prominent dense>{{ artworkErrorMsg }}</v-alert>
      <v-alert v-if="isContentEditable === false" type="warning"
        >Editing content is disabled because one of the reasons: the subscription is canceled, editing is disabled by the Site settings or the order was created on AMP and was sent
        to cAMP as a quote.
      </v-alert>
    </div>

    <template v-if="!isFileUploaderVisible">
      <v-row class="justify-space-around mt-n5" :class="getDisabledStyle" transition="fade-transition">
        <v-col cols="12" md="5">
          <v-row class="justify-center mb-1"><span class="subtitle-1">Front Artwork</span></v-row>
          <template v-if="showFrontArtworkPlaceholder">
            <v-row class="mt-5 justify-center">
              <v-icon size="80" class="justify-center align-center" color="grey lighten-1">mdi-image-outline</v-icon>
            </v-row>
            <v-row class="justify-center mt-5">
              <span class="body-2">No front artwork uploaded yet.</span>
            </v-row>
          </template>
          <v-row class="justify-center">
            <v-img
              v-show="isFrontArtworkLoaded"
              :src="artworkImages.front_artwork_thumbnail"
              class="showPointerOnHover"
              @click="viewImage('front')"
              @error="onImageLoadError($event, 'front')"
              @load="onImageLoad('front')"
            ></v-img>
            <v-skeleton-loader v-show="!isFrontArtworkLoaded" min-width="400" max-width="600" height="280" type="image"></v-skeleton-loader>
          </v-row>
          <v-row class="my-5 justify-center">
            <v-btn :color="colorTheme" class="white--text replace-artwork-btn" @click="replaceArtwork('front')">
              <v-icon class="mr-2">mdi-restore</v-icon>Replace Front Artwork
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="12" md="5">
          <v-row class="justify-center mb-1"><span class="subtitle-1">Back Artwork</span></v-row>
          <template v-if="showBackArtworkPlaceholder">
            <v-row class="mt-5 justify-center">
              <v-icon size="80" class="justify-center align-center" color="grey lighten-1">mdi-image-outline</v-icon>
            </v-row>
            <v-row class="justify-center mt-5">
              <span class="body-2">No back artwork uploaded yet.</span>
            </v-row>
          </template>
          <v-row class="justify-center">
            <v-img
              v-show="isBackArtworkLoaded"
              :src="artworkImages.back_artwork_thumbnail"
              class="showPointerOnHover"
              @click="viewImage('back')"
              @error="onImageLoadError($event, 'back')"
              @load="onImageLoad('back')"
            ></v-img>
            <v-skeleton-loader v-show="!isBackArtworkLoaded" min-width="400" max-width="600" height="280" type="image"></v-skeleton-loader>
          </v-row>
          <v-row class="my-5 justify-center">
            <v-btn :color="colorTheme" class="white--text replace-artwork-btn" @click="replaceArtwork('back')">
              <v-icon class="mr-2">mdi-restore</v-icon>Replace Back Artwork
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </template>

    <v-dialog v-if="isPreflightToolVisible" v-model="isPreflightToolVisible" persistent max-width="1500">
      <v-card>
        <v-card-title class="justify-end">
          <v-btn icon @click="closePreflightModal()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <preflight-tool :isArtworkBack="isArtworkBack" :order="orderObject" @onUploadFile="onUploadFile"></preflight-tool>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import PreflightTool from '../tools/PreflightTool';
import SubscriptionEditWarning from '../orders/orderDialogs/PauseSubscriptionNotification';
import { mapGetters } from 'vuex';

export default {
  components: {
    'preflight-tool': PreflightTool,
    'subscription-edit-warning': SubscriptionEditWarning
  },
  props: ['isPanelOpened', 'firebaseOrderId', 'rootOrder', 'componentMode', 'isContentEditable'],
  data() {
    return {
      artworkSize: '',
      artworkImages: {
        front_artwork_thumbnail: '',
        front_artwork_url: '',
        back_artwork_thumbnail: '',
        back_artwork_url: ''
      },
      artworkErrorMsg: '',
      isArtworkErrorShown: false,
      isFileUploaderVisible: false,
      isUpdateArtworkLoading: false,
      isFrontArtworkLoaded: false,
      isBackArtworkLoaded: false,
      isPreflightToolVisible: false,
      isArtworkBack: false
    };
  },
  computed: {
    orderObject() {
      return this.rootOrder || {};
    },
    showFrontArtworkPlaceholder() {
      return this.isFrontArtworkLoaded && !this.artworkImages.front_artwork_thumbnail;
    },
    showBackArtworkPlaceholder() {
      return this.isBackArtworkLoaded && !this.artworkImages.back_artwork_thumbnail;
    },
    isComponentUsedInDmmTool() {
      return this.componentMode === 'dmm-tool';
    },
    colorTheme() {
      return this.isComponentUsedInDmmTool ? 'primary' : 'cyan darken-2';
    },
    isSubscriptionActive() {
      return (
        this.orderObject.automated?.campaign_status === 'active' &&
        this.orderObject.automated.subscription_status.toUpperCase() === 'ACTIVE' &&
        this.orderObject.automated.subscription_id
      );
    },
    getDisabledStyle() {
      return !this.isComponentUsedInDmmTool && (this.isSubscriptionActive || !this.isContentEditable) ? 'disableContent' : '';
    },
    ...mapGetters('om_orders', {
      campaignEditNotification: 'getCampaignEditNotification'
    }),
    ...mapGetters('users', {
      userStore: 'user'
    })
    ,
    isOrderUpdatedSuccessfully() {
      return this.campaignEditNotification.isOrderUpdated && this.campaignEditNotification.updatedData === 'artwork';
    },
    orderId() {
      return this.orderObject?.firebase_order_id ?? this.firebaseOrderId;
    }
  },
  watch: {
    isPanelOpened: {
      handler: function () {
        this.$store.commit('om_orders/setCampaignEditNotification', { isOrderUpdated: false });
      },
      immediate: true
    },
    isSubscriptionActive(value) {
      this.$store.commit('om_orders/setCampaignEditNotification', { isVisible: value });
    }
  },
  mounted() {
    if (this.orderId) {
      this.$store.dispatch('om_orders/getArtworkThumbnails', { orderId: this.orderId }).then(response => {
        this.artworkSize = response.size;
        this.artworkImages.front_artwork_thumbnail = response.artwork_thumbnails_front?.medium || '';
        this.artworkImages.front_artwork_url = response.artwork_thumbnails_front?.large || '';
        this.isFrontArtworkLoaded = true;
        this.artworkImages.back_artwork_thumbnail = response.artwork_thumbnails_back?.medium || '';
        this.artworkImages.back_artwork_url = response.artwork_thumbnails_back?.large || '';
        this.isBackArtworkLoaded = true;
        this.$emit('artworksData', response);
      });
      this.getProductByOrderId();
    }
  },
  methods: {
    async getProductByOrderId() {
      this.orderObject.product = await this.$store.dispatch('getOrderDataItemByOrderId', {
        firebase_order_id: this.orderId,
        order_data_item: 'product'
      });
    },
    saveArtworks(config) {
      this.isUpdateArtworkLoading = true;
      this.$store.dispatch('showLoader', {
        message: `Updating artworks in progress...`
      });
      this.isFrontArtworkLoaded = false;
      this.isBackArtworkLoaded = false;
      const passingObj = {
        firebaseOrderId: this.orderId,
        size: this.artworkSize,
        campaignName: `${this.orderObject.crm_order_id || 'artwork'}_${Number(Date.now())}`,
        creator: this.userStore
      };

      if (config.urlFront) {
        passingObj.urlFront = config.urlFront;
      }
      if (config.urlBack) {
        passingObj.urlBack = config.urlBack;
      }

      this.$store
        .dispatch('tools/createArtwork', passingObj)
        .then(response => {
          console.log('[ Create artwork response ] :: ', response.data);

          const resultConfig = {};
          let artworkThumbnailsFront = {};
          let artworkThumbnailsBack = {};

          if (config.urlFront) {
            artworkThumbnailsFront = response.data?.artwork_thumbnails_front || {};

            this.artworkImages.front_artwork_thumbnail = artworkThumbnailsFront.medium || '';
            this.artworkImages.front_artwork_url = artworkThumbnailsFront.large || '';

            resultConfig.artwork_front_id = response.data.front_artwork_id;
            resultConfig.artwork_thumbnails_front = artworkThumbnailsFront;
          }

          if (config.urlBack) {
            artworkThumbnailsBack = response.data?.artwork_thumbnails_back || {};

            this.artworkImages.back_artwork_thumbnail = artworkThumbnailsBack.medium || '';
            this.artworkImages.back_artwork_url = artworkThumbnailsBack.large || '';
            this.isBackArtworkLoaded = true;

            resultConfig.artwork_back_id = response.data.back_artwork_id;
            resultConfig.artwork_thumbnails_back = artworkThumbnailsBack;
          }

          this.isFrontArtworkLoaded = true;
          this.isBackArtworkLoaded = true;

          this.$emit('artworksData', resultConfig);
          this.$store.commit('om_orders/setCampaignEditNotification', { isOrderUpdated: true, updatedData: 'artwork', isVisible: this.isSubscriptionActive });
        })
        .catch(error => {
          console.log('[ Create artwork error ]:', error.response.data);
          this.artworkErrorMsg = `${error.response.status} - ${error.response.data?.message || error.response.data.toString()}`;
          this.isArtworkErrorShown = true;
        })
        .finally(() => {
          this.isUpdateArtworkLoading = false;
          this.isPreflightToolVisible = false;
          this.$store.dispatch('hideLoader');
        });
    },
    async replaceArtwork(selectedSide) {
      this.isArtworkBack = selectedSide === 'back';
      // check product data
      if (!this.orderObject.product) {
        await this.getProductByOrderId();
      }

      this.isPreflightToolVisible = true;
    },
    onUploadFile(result) {
      console.log('ArtworkPreflightupload: onUploadFile : ', result);

      this.saveArtworks({
        [this.isArtworkBack ? 'urlBack' : 'urlFront']: result[0] ? result[0].url : ''
      });
    },
    checkDimensions(dimensions) {
      if (dimensions.width < 1875 || dimensions.height < 1275) {
        this.artworkErrorMsg = `Uploaded image dimensions are incorrect! (${dimensions.width}x${dimensions.height}). Expected at least 1875px in width and 1275px in height.`;
        this.isArtworkErrorShown = true;
      }
    },
    viewImage(side) {
      window.open(side === 'front' ? this.artworkImages.front_artwork_url : this.artworkImages.back_artwork_url);
    },
    showSubscriptionStatusChange() {
      this.$store.commit('om_orders/setCampaignEditNotification', { isVisible: this.isSubscriptionActive, isOrderUpdated: false });
    },
    closePreflightModal() {
      this.isPreflightToolVisible = false;
    },
    async onImageLoadError(image, side) {
      if (side === 'front') {
        this.isFrontArtworkLoaded = false;
        await this.delay(2000);
        this.artworkImages.front_artwork_thumbnail = `${image}?front`;
      } else {
        this.isBackArtworkLoaded = false;
        await this.delay(2000);
        this.artworkImages.back_artwork_thumbnail = `${image}?back`;
      }
    },
    onImageLoad(side) {
      side === 'front' ? (this.isFrontArtworkLoaded = true) : (this.isBackArtworkLoaded = true);
    },
    async delay(ms) {
      return new Promise(res => setTimeout(res, ms));
    }
  }
};
</script>

<style scoped>
.showPointerOnHover {
  cursor: pointer;
}
.extraRightMargin {
  margin-right: 3.4%;
}
.disableContent {
  pointer-events: none;
  opacity: 0.4;
}
.replace-artwork-btn {
  margin-top: 1em;
}
</style>
