import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class CustomDesignsService extends FirestoreModel {
  constructor() {
    super(collections.customDesigns);
  }

  async listDesigns() {
    let designsList = [];
    try {
      const querySnapshot = await this.collectionRef.collection('customDesigns').get();

      querySnapshot.forEach(doc => {
        let data = doc.data();
        data.firestoreId = doc.id;
        designsList.push(data);
      });
      return { list: designsList };
    } catch (error) {
      console.log('listDesigns has failed: ', error);
    }
  }

  async listSelectedDesign(designID) {
    try {
      const querySnapshot = await this.collectionRef.doc(designID).get();
      const data = querySnapshot.data();
      const firestoreId = querySnapshot.id;
      let summary = [];
      let config = {};
      let customizedDesignTime = [];
      let proofImages = {};
      let renderedData = {};
      let savedProduct = {};
      let surfaces = {};
      let header = [];
      Object.entries(data).forEach(([key, value]) => {
        if (typeof value !== 'object') {
          switch (key) {
            case 'crm_order_id':
              summary.push({
                key: 'CRM Order Id',
                value: value
              });
              break;
            case 'parentDbId':
              summary.push({
                key: 'Parent Id',
                value: value
              });
              break;
            case 'rev':
              summary.push({
                key: 'Rev',
                value: value
              });
              break;
            case 'siteDbId':
              summary.push({
                key: 'Site Id',
                value: value
              });
              break;
            case 'templateId':
              summary.push({
                key: 'Template Id',
                value: value
              });
              break;
          }
        } else {
          switch (key) {
            case 'customer':
              Object.entries(value).forEach(([key1, value1]) => {
                switch (key1) {
                  case 'clientId':
                    summary.push({
                      key: 'Client ID',
                      value: value1
                    });
                    break;
                  case 'first_name':
                    if (value1) {
                      summary.push({
                        key: 'First Name',
                        value: value1
                      });
                    }
                    break;
                  case 'last_name':
                    if (value1) {
                      summary.push({
                        key: 'Last Name',
                        value: value1
                      });
                    }
                    break;
                  case 'crmCustomerId':
                    summary.push({
                      key: 'CRM Customer ID',
                      value: value1
                    });
                    break;
                  case 'userEmail':
                    if (value1) {
                      summary.push({
                        key: 'User Email',
                        value: value1
                      });
                    }
                    break;
                }
              });
              break;
            case 'config':
              Object.assign(config, value);
              header.push('Configuration');
              break;
            case 'customizedDesignTime':
              customizedDesignTime.push({
                key: key,
                value: value
              });
              header.push('Date');
              break;
            case 'proofImages':
              Object.assign(proofImages, value);
              header.push('Proof images');
              break;
            case 'renderedData':
              Object.assign(renderedData, value);
              header.push('Rendered Data');
              break;
            case 'savedProduct':
              Object.assign(savedProduct, value);
              header.push('Product');
              break;
            case 'surfaces':
              Object.assign(surfaces, value);
              header.push('Surfaces');
              break;
          }
        }
      });

      return {
        summary: summary,
        config: config,
        customizedDesignTime,
        proofImages,
        renderedData,
        savedProduct,
        surfaces,
        header,
        firestoreId
      };
    } catch (error) {
      console.log('listSelectedDesign has failed: ', error);
    }
  }

  async listSelectedDesignMap(referenceId) {
    try {
      const querySnapshot = await this.collectionRef.doc(referenceId).get();

      const data = querySnapshot.data();
      const firestoreId = querySnapshot.id;
      let summary = [];
      const keyMapping = {
        clientId: 'Client ID',
        crmCustomerId: 'CRM Customer ID',
        crm_order_id: 'CRM Order Id',
        parentDbId: 'Parent Id',
        rev: 'Rev',
        siteDbId: 'Site Id',
        templateId: 'Template Id',
        userEmail: 'User Email'
      };

      Object.entries(data).forEach(([key, value]) => {
        if (typeof value !== 'object' && keyMapping[key]) {
          summary.push({
            key: keyMapping[key],
            value: value
          });
        }
      });
      return {
        summary,
        firestoreId
      };
    } catch (error) {
      console.log('listSelectedDesignMap has failed: ', error);
    }
  }
}

export default new CustomDesignsService();
