<template>
  <div>
    <input v-show="false" ref="fileInput" type="file" :accept="accept" :multiple="multiple" @change="handleFileInputChange" />
    <v-card
      :class="[disabled ? 'disabled-card' : dragover ? 'grey lighten-1' : 'grey lighten-3']"
      flat
      @drop.prevent="onDrop($event)"
      @dragover.prevent="dragover = !disabled && true"
      @dragenter.prevent="dragover = !disabled && true"
      @dragleave.prevent="dragover = false"
      @click.prevent="openFileBrowser"
    >
      <v-card-text>
        <v-row class="d-flex flex-column" dense align="center" justify="center">
          <v-icon size="40"> mdi-image-plus-outline </v-icon>
          <p>{{ title }}</p>
        </v-row>
        <v-virtual-scroll v-if="uploadedFiles.length" :items="uploadedFiles" :height="scrollerHeight" item-height="50">
          <template v-slot:default="{ item }">
            <v-list-item :key="item.name">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click.stop="removeFile(item.name)">
                  <v-icon> mdi-close-circle </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>
      </v-card-text>
      <v-card-actions v-if="uploadedFiles.length">
        <v-spacer></v-spacer>

        <v-btn icon @click.stop="clearAll">
          <v-icon id="close-button">mdi-close</v-icon>
        </v-btn>

        <v-btn icon :disabled="!uploadedFiles.length" @click.stop="submit">
          <v-icon id="upload-button">mdi-upload</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: 'image/jpeg, image/jpg, image/png'
    },
    title: {
      type: String,
      default: 'Drop files here, or Click to upload'
    }
  },
  data() {
    return {
      dragover: false,
      uploadedFiles: []
    };
  },
  computed: {
    scrollerHeight() {
      return this.uploadedFiles.length * 75;
    }
  },
  methods: {
    clearAll() {
      // Remove all the uploaded files
      this.uploadedFiles = [];
      this.$emit('clear');
    },
    removeFile(fileName) {
      // Find the index of the
      const index = this.uploadedFiles.findIndex(file => file.name === fileName);
      // If file is in uploaded files remove it
      if (index > -1) {
        this.uploadedFiles.splice(index, 1);
      }
      if (!this.uploadedFiles.length) {
        this.$emit('clear');
      }
    },
    onDrop(e) {
      this.dragover = false;
      // If there are already uploaded files remove them
      if (this.uploadedFiles.length) {
        this.uploadedFiles = [];
      }
      // If user has uploaded multiple files but the component is not multiple throw error
      if (!this.multiple && e.dataTransfer.files.length > 1) {
        this.$store.dispatch('setSnackbar', { type: 'error', message: 'Only one file can be uploaded at a time.' });
        return;
      }
      for (const element of e.dataTransfer.files) {
        if (!this.accept.includes(element.type)) {
          this.$store.dispatch('setSnackbar', { type: 'error', message: `Accepted file types: ${this.accept}` });
        } else {
          this.uploadedFiles.push(element);
        }
      }
    },
    openFileBrowser() {
      this.$refs.fileInput.click();
    },

    handleFileInputChange(e) {
      if (this.uploadedFiles.length) {
        this.clearAll();
      }

      for (const element of e.target.files) {
        if (!this.accept.includes(element.type)) {
          this.$store.dispatch('setSnackbar', { type: 'error', message: `Accepted file types: ${this.accept}` });
        } else {
          this.uploadedFiles.push(element);
        }
      }
    },
    submit() {
      this.$emit('filesAdded', this.uploadedFiles);
    },
    deleteFiles() {
      this.uploadedFiles = [];
    }
  }
};
</script>
<style scoped>
.disabled-card {
  opacity: 0.7;
  pointer-events: none;
}
</style>
