<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12" md="6">
        <v-file-input v-model="file" :label="label" :accept="acceptedFileTypes" @change="handleFileUpload"></v-file-input>
      </v-col>
      <v-col cols="12" md="6">
        <div v-if="downloadURL" class="text-center">
          <img :src="downloadURL" alt="Uploaded Image" class="image rounded" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/storage';

export default {
  props: ['label', 'type', 'fieldValue', 'directory', 'acceptedFileTypes', 'required'],
  data() {
    return {
      file: null,
      downloadURL: this.fieldValue
    };
  },

  methods: {
    async handleFileUpload() {
      const storageRef = firebase.storage().ref();
      const timestamp = Date.now();
      const fileRef = storageRef.child(`${this.directory}/${this.file.name}_${timestamp}`);

      try {
        const snapshot = await fileRef.put(this.file);
        this.downloadURL = await snapshot.ref.getDownloadURL();
        console.log('File uploaded. Download URL:', this.downloadURL);

        this.$emit('input', this.downloadURL);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  }
};
</script>

<style scoped>
.image {
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
}
</style>
