<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-select v-model="selectedSite"
                 :items="sites"
                 item-text="site_name"
                 :disabled="isStitesEmpty"
                 :class="{ disabled: isStitesEmpty }"
                 label="Select Site"
                 return-object
                 @input="onSiteSelected">
     </v-select>
   </v-flex>
 </v-layout>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        selectedSite: ''
      }
    },
    computed: {
      // sites () {
      //   console.log('Sites: ', this.$store.getters.getSites)
      //   return this.$store.getters.getSites
      // },
      ...mapGetters('site', {
          sites: 'getSites'
      }),
      isStitesEmpty () {
        return this.sites === undefined || this.sites === null
      }
    },
    created () {
      if (this.sites === null) {
        this.$store.dispatch('loadSitesFromDb')
      }
    },
    methods: {
      onSiteSelected () {
        console.log('Site is selected: ', this.selectedSite)
        this.$store.commit('setSiteForQuoteTool', this.selectedSite)
        this.$store.commit('setSelectedCustomer', null)
      }
    }
  }
</script>
