<template>
  <div class="text-center">
    <v-dialog v-model="isOpen" persistent max-width="950">
      <v-card>
        <template>
          <v-card-title class="headline grey lighten-2" primary-title>
            <h3>Search</h3>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-alert v-if="successMessage" type="success">
                {{ successMessage }}
              </v-alert>
              <v-alert v-if="errorMessage" type="error">
                {{ errorMessage }}
              </v-alert>
              <v-btn v-if="opportunityLink" :href="opportunityLink" target="_blank" color="success">Open Opportunity</v-btn>
              <v-row v-if="order && order.references && !order.references.salesforce_opportunity_id">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="email"
                    :items="users"
                    :search-input.sync="search"
                    color="white"
                    hide-no-data
                    hide-selected
                    item-text="email"
                    item-value="email"
                    label="Select Opportunity Owner"
                    placeholder="Start typing to Search"
                    prepend-icon="mdi-database-search"
                    @change="emailChanged"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="accountEmail" outlined dense label="Search for customer by email in Salesforce" v-on:keyup.enter="searchUserInSalesforce">
                    <template v-slot:append-outer>
                      <v-btn
                        v-if="order && order.references && !order.references.salesforce_opportunity_id"
                        :disabled="accountEmail === ''"
                        color="success"
                        class="mb-4"
                        style="top: -6px"
                        offset-y
                        @click="searchUserInSalesforce()"
                        >Search in Salesforce
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="order && order.references && !order.references.salesforce_opportunity_id">
                <stamp-data-table
                  v-if="salesforceResult"
                  :headers="fields"
                  :data="salesforceResult"
                  item-key="AccountId"
                  sort-by="FirstName"
                  :sort-desc="true"
                  :is-single-select-enabled="true"
                  @selected="setSelectedItem"
                >
                </stamp-data-table>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="order && order.references && !order.references.salesforce_opportunity_id"
              :disabled="selected.length !== 1"
              color="success"
              class="ml-5"
              @click="createOpportunityInSalesforce()"
              >Create Opportunity</v-btn
            >
            <v-spacer />
            <v-btn class="mr-5" @click="isOpen = false">Close</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import siteSettingsService from '../../../../../services/siteSettingsService.js';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: { 'stamp-data-table': DataTable },
  props: ['order', 'open', 'salesForceUrl'],
  data() {
    return {
      isOpen: true,
      successMessage: '',
      errorMessage: '',
      email: '',
      salesforceUserID: '',
      search: '',
      salesforceResult: null,
      isContactSelected: false,
      fields: [
        { text: 'First name', align: 'start', value: 'FirstName', sortable: true },
        { text: 'Last name', align: 'start', value: 'LastName', sortable: true },
        { text: 'Email', align: 'start', value: 'Email', sortable: true },
        { text: 'Account name', align: 'start', value: 'AccountName', sortable: true },
        { text: 'Account ID', align: 'start', value: 'AccountId', sortable: true },
        { text: 'Brand Name', align: 'start', value: 'BrandName__c', sortable: true }
      ],
      selected: [],
      singleSelect: true,
      selectedContact: null,
      opportunityLink: '',
      accountEmail: ''
    };
  },
  computed: {
    ...mapGetters('users', {
      users: 'getUsersSearch',
      user: 'user'
    }),
    ...mapGetters('site_setting', {
      siteSettings: 'getSiteSettings'
    }),
    ...mapGetters('customer_messaging', {
      orderData: 'getOrderData'
    })
  },
  watch: {
    open(isOpen) {
      this.isOpen = isOpen;
      this.successMessage = '';
      this.errorMessage = '';
    },
    isOpen(isOpen) {
      this.$emit('visibilityChanged', isOpen);
    },
    email(email) {
      if (email) {
        this.salesforceResult = null;
      }
      this.errorMessage = '';
    },
    selected(contacts) {
      this.selectedContact = contacts.length > 0 ? contacts[0] : null;
    }
  },
  mounted() {
    this.$store.dispatch('showLoader', { message: 'Loading order data...' });
    this.$store.dispatch('customer_messaging/listOrderData', { documentId: this.order.firebase_order_id });
    this.getUsers().then(() => {
      this.email = this.user?.email ?? '';
      this.accountEmail = this.order?.order_email ?? '';
      this.getSalesforceUserID();
      this.loadSiteById();
      this.$store.dispatch('hideLoader');
    });
  },
  methods: {
    getUsers() {
      return this.$store.dispatch('users/getUsersSearch', {});
    },
    createOpportunityInSalesforce() {
      if (this.orderData && (!this.orderData.product || !this.orderData.product.salesforce_product_id)) {
        this.errorMessage = 'The product ID is missing!';
      } else {
        this.$store.dispatch('showLoader', { message: 'Creating opportunity...' });
        this.errorMessage = '';
        const savedOrder = this.order;
        savedOrder.order_data = this.orderData;
        const webId =
          this.selectedContact.WebID__c && savedOrder && !savedOrder.origin ? this.selectedContact.WebID__c : this.siteSettings !== '' ? this.siteSettings.brand_web_id : '';
        this.$store
          .dispatch('createOpportunityInSalesforce', {
            order: savedOrder,
            email: this.email,
            accountId: this.selectedContact.AccountId,
            id: this.selectedContact.Id,
            brandId: webId,
            baseUrl: this.siteSettings !== '' ? `https:${this.siteSettings.site_url.split('/wp-json/amp')[0]}/` : `https:${this.siteSettings.site_url.split('/wp-json/amp')[0]}`,
            salesforceUserID: this.salesforceUserID
          })
          .then(result => {
            console.log('Create Opportunity: ', result);
            return this.$store
              .dispatch('updateOpportunityId', {
                order: savedOrder,
                opportunityId: result.success && result.opportunity !== undefined && result.opportunity !== null ? result.opportunity.id : '',
                userId: this.salesforceUserID,
                contactId: this.selectedContact.Id,
                accountId: this.selectedContact.AccountId,
                contact_email: this.email,
                web_id: webId,
                line_item_result: result.success && result.lineItemsResult !== undefined && result.lineItemsResult !== null ? result.lineItemsResult : ''
              })
              .then(() => {
                this.successMessage = 'Create Opportunity in Salesforce has succeeded';
                if (this.salesForceUrl && result.opportunity.id) {
                  this.opportunityLink = `${this.salesForceUrl}/${result.opportunity.id}`;
                }
              });
          })
          .catch(error => {
            console.log('Create Opportunity has failed: ', error);
            this.errorMessage = `Saving the opportunity into the Salesforce has failed: ${JSON.stringify(error?.response?.data?.message ?? error)}`;
          })
          .finally(() => this.$store.dispatch('hideLoader'));
      }
    },
    loadSiteById() {
      return new Promise((resolve, reject) => {
        if (this.order) {
          this.$store.dispatch('showLoader', { message: 'Loading site...' });
          return siteSettingsService
            .getById(this.order.site_db_id)
            .then(siteData => {
              console.log('Load site settings has succeeded: ', siteData);
              return Promise.all([
                this.$store.dispatch('site_setting/setSiteSettings', siteData),
                this.$store.dispatch('site_setting/setBasicApiAuth', siteData.site_api_key),
                this.$store.dispatch('site_setting/setBasicApiUrl', siteData.site_url),
                this.$store.dispatch('site_setting/setWebId', siteData.site_id),
                this.$store.dispatch('site_setting/setCustomQuoteUrl', siteData.custom_quote_url),
                this.$store.dispatch('initApiAuth', siteData)
              ])
                .then(() => resolve(siteData))
                .finally(() => this.$store.dispatch('hideLoader'));
            })
            .catch(error => {
              console.log('loadSiteById has failed: ', error);
            });
        } else {
          reject();
        }
      });
    },
    searchUserInSalesforce() {
      const email = encodeURIComponent(this.accountEmail.replace(/[\]\\^\\']/g, '\\$&').toString());
      console.log('[ searchUserInSalesforce ]: ', email);
      this.$store.dispatch('showLoader', { message: 'Searching...' });
      this.errorMessage = '';
      this.salesforceResult = null;
      this.$store
        .dispatch('searchUserInSalesforce', { email })
        .then(result => {
          this.isSearchResultAvailable = result.success && result.records.length > 0;
          if (this.isSearchResultAvailable) {
            this.salesforceResult = result.records;
            console.log('salesforceResult: ', this.salesforceResult);
          } else {
            this.salesforceResult = null;
            this.errorMessage = `Searching for the ${this.accountEmail} is not possible in Salesforce. The user doesn't have a Salesforce ID.`;
          }
        })
        .catch(error => {
          console.log('[ searchUserInSalesforce ] ERROR:', error);
          this.errorMessage = `Searching for the ${this.accountEmail} is not possible in Salesforce. The user doesn't have a Salesforce ID.`;
        })
        .finally(() => this.$store.dispatch('hideLoader'));
    },
    getSalesforceUserID() {
      if (this.email) {
        const foundUser = this.users.find(user => user.email === this.email);
        this.salesforceUserID = foundUser?.salesforce_user_id ?? '';
      }
    },
    emailChanged() {
      this.getSalesforceUserID();
    },
    setSelectedItem(selected) {
      this.selected = selected;
    }
  }
};
</script>
