import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#c90000',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        warning: '#f0ad4e',
        error: '#b71c1c',
        background: '#FAFAFA'
      },
      dark: {
        primary: '#2892A0',
        background: '#45474A'
      }
    }
  }
};

export default new Vuetify(opts);
