import { storageRef } from '../services/firebaseInit.js';
import orderSettingsService from '../services/orderSettingsService.js';
import { cloudFunctionsPost } from '../modules/cloud-function-wrapper.js';

const stepsStore = {
  state: {
    orderSettingsRef: null,
    selectedStep: null
  },
  getters: {
    getSelectedStep(state) {
      return state.selectedStep;
    }
  },
  mutations: {
    setSelectedStep(state, step) {
      state.selectedStep = step;
    }
  },
  actions: {
    updateOrderSteps({ commit, rootGetters }, payload) {
      const selectedSite = rootGetters['site/getSelectedSite'];
      return orderSettingsService.update(selectedSite, {
        [`orderProcesses.${payload.selectedOrder}.steps`]: payload.stepsOrder,
        [`orderProcesses.${payload.selectedOrder}.orderProcessDetails`]: payload.orderProcessDetails,
        [`orderProcesses.${payload.selectedOrder}.is_quote_enabled`]: payload.isQuoteEnable,
        [`orderProcesses.${payload.selectedOrder}.is_place_order_hidden`]: payload.isPlaceOrderHidden
      });
    },
    resetOrderSteps({ commit, getters, rootGetters }, payload) {
      const selectedSite = rootGetters['site/getSelectedSite'];
      const defaultOrderSettings = getters.getDefaultOrderSettings.orderProcesses[payload.selectedOrder];
      return orderSettingsService.update(selectedSite, {
        [`orderProcesses.${payload.selectedOrder}.orderProcessDetails`]: defaultOrderSettings.orderProcessDetails,
        [`orderProcesses.${payload.selectedOrder}.steps`]: defaultOrderSettings.steps || null,
        [`orderProcesses.${payload.selectedOrder}.is_quote_enabled`]: defaultOrderSettings.is_quote_enabled
      });
    },
    deleteImageFromProcess({ commit }, payload) {
      const orderProcessDetails = payload.orderProcessDetails;
      const imageName = payload.orderProcessDetails.imageName;
      if (!orderProcessDetails.imageName || !orderProcessDetails.imageUrl) {
        return;
      }
      const path = `order-processes/${payload.selectedOrder}/${imageName}`;
      return storageRef(path).delete();
    },
    addOrderProcessLog({ commit, getters }, payload) {
      return cloudFunctionsPost('/orderProcess/addOrderProcessLog', payload);
    },
    loadSteps({ commit, getters }, payload) {
      return orderSettingsService.getSteps(payload.selectedSite, payload.selectedOrder);
    },
    async updateStep({ commit, rootGetters, dispatch }, payload) {
      try {
        const selectedSite = rootGetters['site/getSelectedSite'];
        const selectedOrder = rootGetters['site/getSelectedOrder'];

        const steps = await dispatch('loadSteps', { selectedSite, selectedOrder });
        console.log('steps: ', steps);

        for (let i in steps) {
          if (payload.stepId === steps[i].stepId) {
            steps[i] = payload;
            break;
          }
        }

        console.log('selectedSite: ', selectedSite);
        console.log('selectedOrder: ', selectedOrder);
        console.log('payload: ', payload);

        const data = await orderSettingsService.update(selectedSite, {
          [`orderProcesses.${selectedOrder}.steps`]: steps
        });
        console.log(`Update steps order of the ${selectedOrder} has succeeded: `, data);
        commit('setSelectedStep', payload);
        commit('setStepsInOrderSettings', { selectedSite, selectedOrder, steps });
      } catch (error) {
        console.log('Update step has failed: ', error);
        return error;
      }
    },
    resetStep({ commit, getters, dispatch }, payload) {
      const defaultOrder = getters.getDefaultOrderSettings.orderProcesses[payload.selectedOrder].steps;
      let stepID = '';

      for (let i in defaultOrder) {
        if (payload.selectedStepId === defaultOrder[i].stepId) {
          stepID = i;
          break;
        }
      }
      return dispatch('updateStep', defaultOrder[stepID]);
    }
  }
};

export { stepsStore as default };
