<template>
  <div class="site-table">
    <v-dialog v-model="isDeleteDialogVisible" persistent max-width="500">
      <v-card>
        <v-card-title class="headline red">
          <div class="text-xs-center">
            <span class="white--text text-md-center">Warning!</span>
          </div>
        </v-card-title>
        <v-card-text>
          <p class="mt-3 title">Are you sure you want to delete this order?</p>
          <p class="red--text">*Deleting the Order will delete all the references related to order (check SHOW REPORT).</p>
          <v-row>
            <v-col cols="12" sm="12">
              <p>Check order delete Report</p>
              <v-btn color="green darken-1" text @click="isReportDialogVisible = true"> Show report</v-btn>
              <v-checkbox v-model="isDeleteReferencesSelected" :label="`Delete Map References( ONLY)`"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDialog()"> Cancel </v-btn>
          <v-btn color="green darken-1" text @click="confirmDelete()"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isReportDialogVisible" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeReportDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Delete Report</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="closeReportDialog()">Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <p class="title">Firebase</p>
              </v-list-item-title>
              <v-row align="center" justify="start" class="ml-4">
                <div class="mr-4">
                  <span v-if="isDeleteDialogVisible"
                    >Delete order <b>{{ deletedOrder.firebase_order_id }}</b> from Firebase</span
                  >
                </div>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <p class="title">Algolia</p>
              </v-list-item-title>
              <v-row align="center" justify="start" class="ml-4">
                <div class="mr-4">
                  <p>
                    Delete order from Algolia, index: <b>{{ algoliaOrdersIndex }}</b>
                  </p>
                </div>
              </v-row>
              <v-row align="center" justify="start" class="ml-4">
                <div class="mr-4">
                  <p v-if="isDeleteDialogVisible">
                    Delete order from Algolia, index: <b>site_{{ deletedOrder.site_db_id }}_orders</b>
                  </p>
                </div>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <p class="title">References</p>
              </v-list-item-title>
              <div v-for="(item, index) in references" :key="index">
                <v-row align="center" justify="start" class="ml-4">
                  <div v-if="item.value.length > 0" class="mr-4">
                    <p>
                      <span class="mr-2">Order References:</span>
                      <span>
                        <b>{{ item.value }}</b></span
                      >
                    </p>
                  </div>
                </v-row>
              </div>
              <div>
                <v-divider v-if="referencesList.length > 0" class="my-2"></v-divider>
              </div>
              <div>
                <div v-if="referencesList.length > 0" class="ml-4">
                  <p><b>Algolia</b></p>
                </div>
                <div v-for="(dataRef, indexRef) in referencesList" :key="indexRef" class="ml-4">
                  <div v-if="dataRef.refName == 'mailingLists'">
                    <p>
                      Delete list from Algolia, index: <b>{{ dataRef.refName }}</b>
                    </p>
                    <p>
                      Delete list from Algolia, index: <b>site_{{ deletedOrder.site_db_id }}_{{ dataRef.refName }}</b>
                    </p>
                  </div>
                  <div v-if="dataRef.refName == 'maps'">
                    <p>
                      Delete map from Algolia, index: <b>{{ dataRef.refName }}</b>
                    </p>
                    <p>
                      Delete map from Algolia, index: <b>site_{{ deletedOrder.site_db_id }}_{{ dataRef.refName }}</b>
                    </p>
                  </div>
                  <div v-if="dataRef.refName == 'customDesigns'">
                    <p>
                      Delete design from Algolia, index: <b>{{ dataRef.refName }}</b>
                    </p>
                    <p>
                      Delete design from Algolia, index: <b>site_{{ deletedOrder.site_db_id }}_{{ dataRef.refName }}</b>
                    </p>
                  </div>
                </div>
              </div>
              <div class="ml-4">
                <div v-if="mapReferences.status === 'success'">
                  <p><b>Map</b> references</p>
                  <ul>
                    <li v-for="(data, index2) in mapReferences.mapRefList" :key="index2" class="stamp-od-ul">
                      <!-- {{data}} -->
                      <p v-for="(item, index3) in data" :key="index3">
                        <span class="mr-2">
                          <b>{{ index3 }} :</b>
                        </span>
                        <span>{{ item }}</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <div v-if="mailingListReferences.length > 0">
                  <p><b>List references</b></p>
                  <ul>
                    <li v-for="(dataList, indexList) in mailingListReferences" :key="indexList" class="stamp-od-ul">
                      <!-- {{data}} -->
                      <div>
                        <p>
                          <span class="mr-2"> List file name: </span>
                          <span>{{ dataList.listFileName }}</span>
                        </p>
                        <p>
                          <span class="mr-2"> List file path: </span>
                          <span>{{ dataList.listPath }}</span>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <p class="title">Order Data - Artwork</p>
              </v-list-item-title>

              <v-row align="center" justify="start" class="ml-4">
                <div v-if="artwork.length > 0" class="mr-4">
                  <ul>
                    <li v-for="(dataArt, indexArt) in artwork" :key="indexArt" class="stamp-od-ul">
                      <div>
                        <p>
                          <span class="mr-2"><b>Artwork file name:</b></span>
                          <span>{{ dataArt.artworkName }}</span>
                        </p>
                        <p>
                          <span><b>Artwork file Path:</b></span>
                          <span>{{ dataArt.artworkPath }}</span>
                        </p>
                        <v-divider v-if="indexArt < artwork.length - 1" class="my-2"></v-divider>
                      </div>
                    </li>
                  </ul>
                </div>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isDeleteReportDialogSelected" persistent max-width="600">
      <v-card>
        <v-card-title class="headline blue">
          <div>
            <span class="white--text">Delete order</span>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="mt-4">
            <p v-if="isDeleteProcessing">
              Deleting order data ... <span class="blue--text"> <v-icon color="blue">mdi-timer-sand</v-icon></span>
            </p>
            <p v-else>
              All Order references deleted! <span class="green--text"> <v-icon color="green">mdi-check</v-icon></span>
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDeleteReportDialog()"> close </v-btn>
        </v-card-actions>
        <v-progress-linear v-if="isDeleteProcessing" :indeterminate="isDeleteProcessing" class="my-0"></v-progress-linear>
      </v-card>
    </v-dialog>
    <stamp-search-order
      v-if="isTestListLoaded"
      title="Orders"
      :isOrderStatusFilterVisible="true"
      :isOrderTypeFilterVisible="true"
      :testSitesList="testSitesList"
      @onLoadingChanged="onLoadingChanged"
      @onWrongFilter="onWrongFilter"
      @updateOrderList="updateOrderList"
    ></stamp-search-order>
    <v-row>
      <v-alert v-model="isEndDateSmaller" type="error" dismissible outlined> End date cannot be less than Start date! Please choose another End date. </v-alert>
    </v-row>
    <div class="site-table mt-n4">
      <stamp-data-table
        :headers="headers"
        :data="orders"
        :is-loading="isLoadingVisible"
        :is-items-per-page-disabled="true"
        :total-server-items-nr="totalOrders"
        item-key="firebase_order_id"
        :item-class="getLineColor"
        :sort-desc="true"
        :sort-by="sortBy"
        item-prop-to-display-on-delete-dialog="label"
        :current-page="currentPage"
        @options="optionsHandler"
        @doubleClick="viewOrderDetails"
      >
        <template #[`item.creation_date_timestamp`]="{ item }">{{ formatDate(item.creation_date_timestamp) }}</template>
        <template #[`item.order_status`]="{ item }">
          <span class="darkgrey--text">{{ capitalize(item.order_status.status) }}</span>
        </template>
        <template #[`item.site_id`]="{ item }">
          <v-tooltip bottom open-delay="200">
            <template #activator="{ on }">
              <span class="blue--text text--darken-4 site-full-name" v-on="on" @mouseover="getSiteFullName(item)" @mouseleave="siteFullName = null">
                {{ item.site_id }}
              </span>
            </template>
            <v-progress-circular v-if="!siteFullName" indeterminate color="white"></v-progress-circular>
            <div v-else class="subtitle-1">
              <span>{{ siteFullName.name }}</span>
              <br />
              <span>{{ siteFullName.url }}</span>
            </div>
          </v-tooltip>
        </template>
        <template #[`item.order_total`]="{ item }">{{ formatNumeral(formOrderTotal(item.order_total)).format('$0,0.00') }}</template>
        <template #[`item.order_balance`]="{ item }">{{ formatNumeral(formOrderTotal(item.order_balance)).format('$0,0.00') }}</template>
        <template #[`item.mailing_service`]="{ item }">{{ item.mailing_service === 'none' ? 'custom' : item.mailing_service }}</template>
        <template #[`item.actions`]="{ item }">
          <v-tooltip v-if="!item.crm_order_id" bottom>
            <template #activator="{ on }">
              <v-btn icon v-on="on" @click="deleteOrder(item)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete Order</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn icon :to="`orders/view/${item.firebase_order_id}`" v-on="on" @click="viewOrderDetails(item)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Order Details</span>
          </v-tooltip>
        </template>
      </stamp-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
const numeral = require('numeral');
import DataTable from '@/components/common/DataTable.vue';
import SearchOrder from './SearchOrder.vue';
import mailingListsService from '../../../services/mailingListsService';
import orderLogsService from '../../../services/orderLogsService';
import orderService from '../../../services/orderService';

export default {
  name: 'orders-algolia',
  components: {
    'stamp-search-order': SearchOrder,
    'stamp-data-table': DataTable
  },
  data() {
    return {
      search: '',
      totalOrders: null,
      isLoadingVisible: false,
      orders: [],
      headers: [
        { text: 'Date', align: 'center', value: 'creation_date_timestamp' },
        { text: 'Status', align: 'center', value: 'order_status', sortable: false },
        { text: 'Site ID', value: 'site_id', align: 'left', sortable: false },
        { text: 'Customer', value: 'first_name', align: 'left', sortable: false },
        { text: 'Osprey Order ID', value: 'crm_order_id', align: 'left', sortable: false },
        { text: 'Osprey Customer ID', value: 'crm_customer_id', align: 'left', sortable: false },
        { text: 'Print Qty', value: 'print_qty', align: 'left', sortable: false },
        { text: 'Order total', value: 'order_total', align: 'left', sortable: false },
        { text: 'Order balance', value: 'order_balance', align: 'left', sortable: false },
        { text: 'Order label', value: 'label', align: 'center', sortable: false },
        { text: 'Order email', value: 'order_email', align: 'center', sortable: false },
        { text: 'Order Type', value: 'mailing_service', align: 'left', sortable: false },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false, width: '110' }
      ],
      options: {},
      currentPage: 1,
      sortBy: 'creation_date_timestamp',
      date: new Date().toISOString().substr(0, 10),
      isEndDateSmaller: false,
      isDeleteDialogVisible: false,
      deletedOrder: null,
      isReportDialogVisible: false,
      // delete
      references: [],
      artwork: [],
      mapReferences: {},
      mailingListReferences: {},
      referencesList: [],
      orderLogsId: '',
      isDeleteReferencesSelected: false,
      isDeleteReportDialogSelected: false,
      isDeleteProcessing: false,
      setHitsPerPage: 15,
      selectedOrderList: [],
      siteFullName: null,
      commonFilter: '',
      testSitesList: null,
      isTestListLoaded: false,
      algoliaOrdersIndex: ''
    };
  },
  computed: {
    ...mapGetters('om_orders', {
      deletingParametersList: 'getDeletingParameters'
    }),
    currentPrimaryColor() {
      return 'test-item-default';
    }
  },
  async created() {
    await this.initTestSitesList();
    this.isTestListLoaded = true;
    this.initResult();
    this.algoliaOrdersIndex = `orders${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`;
  },
  methods: {
    async initResult() {
      this.isLoadingVisible = true;
      const d = new Date();
      const date = Math.floor(d.setDate(d.getDate()) / 1000);

      const config = {
        indexName: this.algoliaOrdersIndex,
        filter: `creation_date_timestamp < ${date}`
      };

      if (this.commonFilter) {
        config.filter = this.commonFilter;
      }

      if (this.search) {
        config.searchValue = this.search;
      }

      try {
        await this.getDataFromAlgolia(config);
      } catch (error) {
        console.log('initResult has failed: ', error);
      } finally {
        this.isLoadingVisible = false;
      }
    },
    async initTestSitesList() {
      this.testSitesList = await this.$store.dispatch('site/getTestSites');
      Promise.resolve();
    },
    getLineColor(item) {
      return this.isListItemTest(item) ? this.currentPrimaryColor : '';
    },
    updateOrderList(payload) {
      this.totalOrders = payload.totalOrders;
      this.orders = payload.orders;
      this.commonFilter = payload.commonFilter;
      this.search = payload.search;
      Object.assign(this.options, payload.options);
    },
    async sortAndPaginate() {
      this.isLoadingVisible = true;
      const { sortBy, sortDesc, page } = this.options;
      // sort by creation_date ascending order
      const algoliaIndexName =
        !Object.values(sortBy).length && !Object.values(sortDesc).length
          ? `orders_creation_date_timestamp_asc${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`
          : `orders${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`;

      try {
        const content = await this.getDataFromAlgolia({
          indexName: algoliaIndexName,
          filter: this.commonFilter,
          searchValue: this.search || '',
          page: page > 1 ? page - 1 : 0
        });

        this.totalOrders = content.nbHits;
        this.orders = content.hits;
        return Promise.resolve();
      } catch (error) {
        console.log('[ getDataFromAlgolia failed ]:', error, error.debugData);
        this.$store.dispatch('showAlert', { type: 'error', message: error });
        return Promise.reject();
      } finally {
        this.isLoadingVisible = false;
      }
    },
    async getDataFromAlgolia(config) {
      try {
        const content = await this.$store.dispatch('algolia/getDataFromAlgolia', config);
        if (content) {
          this.totalOrders = content.nbHits;
          this.orders = content.hits;
        }
        return Promise.resolve(content);
      } catch (error) {
        console.log(`getDataFromAlgolia: `, error);
        console.log(`getDataFromAlgolia DebugData: `, error.debugData);
        return Promise.reject(error);
      }
    },
    viewOrderDetails(order) {
      this.$store.commit('customer_messaging/setSelectedOrder', order);
      this.$router.push({ path: `orders/view/${order.firebase_order_id}` });
    },
    async deleteOrder(order) {
      try {
        console.log('Order:', order);
        this.deletedOrder = order;
        const referenceArray = [];
        Object.entries(order.references).forEach(([key, value]) => {
          this.references.push({
            attribute: key,
            value
          });
          if (value && typeof value === 'string') {
            const ref = value.split('/');
            referenceArray.push({
              refName: ref[0],
              refValue: ref[1]
            });
          }
        });

        this.referencesList = referenceArray;
        const promises = [];

        for (const value of referenceArray) {
          if (value.refName === 'maps') {
            const response = await this.$store.dispatch('customer_messaging/checkMapReferences', { firebaseID: value.refValue });
            console.log('mapReferences', response);
            // TODO: check for success
            this.mapReferences = response;

            promises.push(response);
          }
          if (value.refName === 'mailingLists') {
            // kav87TIv2nLveFTCHMDV
            // order: ffq3vuNHuVrZ1oehA1Aq
            const response = await mailingListsService.checkMailingListsReferences(value.refValue);
            if (response.status === 'success') {
              console.log('mailingListReferences', response.listRefList);
              this.mailingListReferences = response.listRefList;
            }
            promises.push(response);
          }
        }

        Promise.all(promises).then(() => {
          console.log('All sites updated!');
        });

        // artwork : ImBazJhwqU5VSMu71aOp
        const orderServiceResponse = await orderService.listOrderDataArtwork(order.firebase_order_id);
        let artworkList = [];
        if (orderServiceResponse.artwork.length > 0) {
          orderServiceResponse.artwork.forEach((value, index) => {
            artworkList.push({
              artworkName: value.name,
              artworkPath: value.path
            });
          });
        }
        console.log('artworkList', artworkList);
        this.artwork = artworkList;

        const orderLogsServiceResponse = await orderLogsService.getOrderLogsId(order.firebase_order_id);
        if (orderLogsServiceResponse.status === 'success') {
          this.orderLogsId = orderLogsServiceResponse.orderLogFirebaseID;
        } else if (orderLogsServiceResponse.status === 'error') {
          this.orderLogsId = '';
        }

        this.isDeleteDialogVisible = true;
      } catch (error) {
        console.log('deleteOrder has failed: ', error);
      }
    },
    closeDialog() {
      this.isDeleteDialogVisible = false;
    },
    async confirmDelete() {
      this.isDeleteDialogVisible = false;
      this.isDeleteReportDialogSelected = true;
      this.isDeleteProcessing = true;

      try {
        await this.$store.dispatch('customer_messaging/deleteOrder', {
          orderObject: this.deletedOrder,
          artwork: this.artwork,
          orderLogId: this.orderLogsId,
          deleteMapReferences: this.isDeleteReferencesSelected,
          mapReferences: this.mapReferences
        });

        this.isDeleteProcessing = false;
        this.initResult();
      } catch (error) {
        console.log('confirmDelete has failed: ', error);
      }
    },
    closeReportDialog() {
      this.references = [];
      this.referencesList = [];
      this.mapReferences = {};
      this.isReportDialogVisible = false;
    },

    closeDeleteReportDialog() {
      this.isDeleteReportDialogSelected = false;
      this.initResult();
    },

    async getSiteFullName(item) {
      try {
        const res = await this.$store.dispatch('om_orders/getSiteFullNameById', { siteId: item.site_db_id });
        this.siteFullName = {
          name: res.name || '',
          url: res.url || ''
        };
      } catch (error) {
        console.log('getSiteFullName has failed: ', error);
      }
    },
    isListItemTest(item) {
      return (item?.origin?.site_db_id && this.testSitesList?.includes(item?.origin?.site_db_id)) || this.testSitesList?.includes(item.site_db_id);
    },
    onWrongFilter(isEndDateSmaller) {
      this.isEndDateSmaller = isEndDateSmaller;
    },
    onLoadingChanged(isVisible) {
      this.isLoadingVisible = isVisible;
    },
    formatNumeral(amount) {
      return numeral(amount);
    },
    formatDate(dateTotransform) {
      var date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    formOrderTotal(order) {
      return parseFloat(order).toFixed(2);
    },
    capitalize(text) {
      if (!text || text.length <= 2) {
        return '';
      } else if (text.length >= 2) {
        return text.charAt(0).toUpperCase() + text.slice(1);
      }
    },
    async optionsHandler(options) {
      this.options = options;
      await this.sortAndPaginate();
      this.currentPage = this.options.page;
    }
  }
};
</script>

<style scoped>
.site-table .site-full-name {
  cursor: pointer;
}
.test-item-default {
  background-color: #f9e1e1 !important;
}
</style>
