<template>
  <div class="site-table">
    <v-dialog v-model="isDialogOpened" max-width="320">
      <v-card>
        <v-card-title class="headline red">
          <div class="text-xs-center">
            <span class="white--text text-md-center">Warning!</span>
          </div>
        </v-card-title>
        <v-card-text v-if="isDeleteMultiple"> Are you sure you want to delete {{ logNr }} logs items? </v-card-text>
        <v-card-text v-else-if="isDeleteAllSelected"> Are you sure you want to delete ALL log items? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="confirmDeleteLogs">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template>
      <v-toolbar flat class="site-table--header site-table--lists">
        <v-col class="pl-0">
          <v-toolbar-title class="headline"> All Logs </v-toolbar-title>
        </v-col>
        <v-col cols="5" md="4">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </v-col>

        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon :disabled="isLogSelected" class="ml-2" v-on="on" @click="deleteSelected">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete all from page</span>
          </v-tooltip>
        </v-col>
        <v-col md="3" xl="2" class="pr-0">
          <div class="table-header--actions">
            <v-btn depressed color="primary" class="white--text" @click="deleteAllLogs"> Delete ALL Logs </v-btn>
          </div>
        </v-col>
      </v-toolbar>
    </template>
    <v-card>
      <stamp-data-table :headers="headers" :data="logs" :search="search" item-key="time" sort-by="date" :sort-desc="false">
        <template #[`item.actions`]="{ item }">
          <v-icon color="#c90000" @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </stamp-data-table>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import allLogsService from '../../services/allLogsService.js';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: { 'stamp-data-table': DataTable },
  data() {
    return {
      logs: [],
      headers: [
        { text: 'Date', align: 'left', value: 'date' },
        { text: 'Action', value: 'action', align: 'left' },
        { text: 'Component', value: 'component', align: 'left' },
        { text: 'User', value: 'user', align: 'left' },
        { text: 'Delete', value: 'actions', align: 'center', width: 100 }
      ],
      selected: [],
      search: '',
      isLogSelected: true,
      isDialogOpened: false,
      isDeleteMultiple: false,
      isDeleteAllSelected: false
    };
  },
  computed: {
    ...mapGetters('users', {
      userStore: 'user'
    })
  },
  watch: {
    selected(value) {
      this.isLogSelected = value.length <= 0;
    }
  },
  async created() {
    this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
    const logs = await allLogsService.getAll();
    Object.entries(logs).forEach(([id, log]) => {
      const data = {
        id: id,
        action: log.action,
        component: log.component,
        date: log.date,
        user: log.user
      };
      this.logs.push(data);
    });
    this.$store.dispatch('hideLoader');
  },
  methods: {
    async deleteItem(item) {
      const docID = item.id;
      let isDeleteConfirmed = confirm('Are you sure you want to delete this log item? ');
      if (isDeleteConfirmed) {
        try {
          await allLogsService.delete(docID);
          this.logs.forEach((value, index) => {
            if (value.id === docID) {
              this.logs.splice(index, 1);
            }
          });
        } catch (error) {
          console.error('Error removing document: ', error);
        }
      }
    },
    deleteSelected() {
      this.logNr = this.selected.length;
      this.isDeleteMultiple = true;
      this.isDialogOpened = true;
    },
    async confirmDeleteLogs() {
      if (this.isDeleteMultiple) {
        for (let value of this.selected) {
          const logKey = value['id'];
          try {
            await allLogsService.delete(logKey);
            this.logs.forEach((value, index) => {
              if (value.id === logKey) {
                this.logs.splice(index, 1);
              }
            });
          } catch (error) {
            console.error('Error removing document: ', error);
          }
        }
      } else if (this.isDeleteAllSelected) {
        try {
          const currentDate = new Date();
          const lastID = await allLogsService.add({
            action: 'delete all logs',
            component: 'AllLogs',
            date: `${currentDate.toISOString()} GMT+00`,
            user: this.userStore.name
          });

          this.logs.forEach(value => {
            if (value.id !== lastID) {
              allLogsService.delete(value.id);
            }
          });
        } catch (error) {
          console.error('Error deleting all documents: ', error);
        }
      }
      this.close();
    },
    deleteAllLogs() {
      this.isDeleteAllSelected = true;
      this.isDialogOpened = true;
    },
    close() {
      this.isDialogOpened = false;
      this.isLogSelected = true;
      this.selected = [];
      this.isDeleteMultiple = false;
    }
  }
};
</script>
