import { cloudFunctionsGet, cloudFunctionsPost } from '../../modules/cloud-function-wrapper.js';
var excelProcess = null;
var Papa = null;
import stampSettingsService from '../../services/stampSettingsService';

const state = {
  initialDataTableState: []
};

const getters = {
  getInitialDataTableState(state) {
    return state.initialDataTableState;
  }
};

const mutations = {
  setInitialDataTableState(state, payload) {
    state.initialDataTableState = payload;
  }
};

const actions = {
  getCsvCounts({ dispatch }, payload) {
    return cloudFunctionsPost('/newmovercampaign/countCsvLines', { csvLink: payload.csvLink, returnCsvFile: payload.returnCsvFile });
  },
  getPreparedCsvForDmm({ dispatch }, payload) {
    return cloudFunctionsPost('/newmovercampaign/generateCsvNMDMM', {
      csvLink: payload.csvLink,
      returnCsvFile: payload.returnCsvFile,
      crmCustomerId: payload.customerId,
      crmOrderId: payload.crmOrderId || '',
      listName: payload.listName || '',
      headerMaps: payload.headerMaps || ''
    }).then(response => {
      let blob = new Blob([response.data], { type: 'text/csv' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'new_movers_dmm.csv';
      link.click();
    });
  },
  createArtwork({ dispatch }, payload) {
    return cloudFunctionsPost('/newmovercampaign/createArtwork', payload);
  },
  getArtworkPreview({ dispatch }, payload) {
    let picSize = payload.size === '6Std' ? '6x9' : payload.size;
    return cloudFunctionsPost('/newmovercampaign/generatePreview', {
      size: picSize,
      artwork_id: payload.artwork_id
    }).then(response => {
      return { url: response.data.preview_url };
    });
  },
  createPostcards({ dispatch }, payload) {
    return cloudFunctionsPost('/automated/executeDrop', payload);
  },
  scheduleDrop({ dispatch }, payload) {
    return cloudFunctionsPost('/automated/scheduleDrops', payload);
  },
  async getJsonDataFromCsvFile({ commit }, payload) {
    const extension = payload.name.substring(payload.name.lastIndexOf('.') + 1, payload.name.length);
    let excelData = null;
    let promises = [];
    if (extension.includes('xls')) {
      if (!excelProcess) excelProcess = require('@/modules/helpers/convertExcelToCsv');
      excelData = await excelProcess.convertExcelToCsv(payload);
    }
    const file = excelData ? excelData : payload;
    if (!Papa) Papa = require('papaparse');
    promises.push(
      new Promise(resolve => {
        Papa.parse(file, {
          complete: function (results) {
            resolve(results.data.length);
          }
        });
      })
    );
    promises.push(
      new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          delimiter: '',
          skipEmptyLines: true,
          preview: 50,
          complete: function (results) {
            const headers =
              results.data?.[0] && Object.keys(results.data[0]).length === results.meta.fields.length ? results.meta.fields : results.meta.fields.filter(header => header);
            resolve({
              headers: headers,
              content: results.data
            });
            if (results.errors) {
              reject(results.error);
            }
          }
        });
      })
    );
    return Promise.all(promises)
      .then(results => {
        return Promise.resolve({
          headers: results[1].headers,
          content: results[1].content,
          fileLength: excelData ? results[0] - 2 : results[0] - 1,
          excelFile: excelData ? excelData : ''
        });
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getCsvFromJSON({ commit }, arrayOfJson) {
    if (!Papa) Papa = require('papaparse');
    return Papa.unparse(arrayOfJson);
  },
  downloadCsvFile({ commit }, payload) {
    const csvBlob = new Blob([payload.csv], { type: 'text/csv;charset=utf-8;' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(csvBlob);
    link.download = `${payload.fileName}.csv`;
    link.click();
  },
  async getMapToolBingKey(_context) {
    try {
      const mapSettings = await stampSettingsService.getById('map');
      return mapSettings.map_bing_key;
    } catch (error) {
      console.log('getMapToolBlingKey has failed: ', error);
    }
  },
  async getCallboxSites() {
    const sites = await cloudFunctionsGet('/callTracking/callbox/getSitesIds');
    return sites;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
