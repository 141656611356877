<template>
	<div>
		<v-row>
			<v-col v-if="isOspreyOrderIdsDropdownVisible" cols="12" sm="7" class="ml-n4 mt-n4 mb-n4">
				<v-select v-model="selectedOspreyId" :items="ospreyOrderIds" label="Select Osprey Order ID" outlined dense class="ml-4 pt-4"></v-select>
			</v-col>
			<span v-else class="ml-3 mb-5 subtitle-1 font-weight-regular warning--text">No Osprey OrderID found for this {{ referenceType }}!</span>
		</v-row>
		<v-row class="mx-0 mt-n3 mb-n2">
			<v-text-field v-model="csvFileName" suffix="_(DATE_TIMESTAMP).csv" outlined dense></v-text-field>
		</v-row>
	</div>
</template>

<script>

export default {
	props: ['referenceType', 'defaultFileName', 'firebaseId'],
	data () {
		return {
			ospreyOrderIds: [],
			selectedOspreyId: '',
			csvFileName: ''
		}
	},
	computed: {
		isOspreyOrderIdsDropdownVisible () {
			return this.ospreyOrderIds.length && this.ospreyOrderIds.filter(id => id !== 0).length
		}
	},
	watch: {
		selectedOspreyId (value) {
			this.csvFileName = `${value ? `${value}_`:''}${this.defaultFileName ? `${this.defaultFileName}`:''}${this.csvFileName}`
			this.$emit('fileName', this.csvFileName)
		},
		csvFileName (fileName) {
			this.$emit('fileName', fileName)
		}
	},
	created () {
		let selectedFunction = this.referenceType === 'map'
			? 'getOspreyOrderIdsByMapId'
			: this.referenceType === 'list' ? 'getOspreyOrderIdsByListId' : ''
		const paramObject = { [this.referenceType === 'map' ? 'mapId' : this.referenceType === 'list' ? 'listId' : '']: this.firebaseId }
		if (selectedFunction) {
			this.$store.dispatch(`om_orders/${selectedFunction}`, paramObject).then(result => {
				this.ospreyOrderIds = result
				if (result && !result.filter(id => id !== 0).length) {
					this.selectedOspreyId = '0'
				}
			}).catch(error => {
				console.log('Getting OspreyIds by Id failed:', error)
			})
		}
	}
}
</script>