<template>
  <stamp-data-table :headers="headers" :data="drops" item-key="mail_drop_id" :search="search" :isLoading="isLetterDropsLoading">
    <template #[`item.firebase_order_id`]="{ item }">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon v-on="on" @click="copyItemToClipboard(item.firebase_order_id)"><v-icon color="amber darken-3">mdi-firebase</v-icon></v-btn>
        </template>
        <span>Copy FirebaseID to clipboard [ {{ item.firebase_order_id }} ]</span>
      </v-tooltip>
    </template>
    <template #[`item.action`]="{ item }">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-icon color="blue darken-1" @click="launchCampaign(item)" v-on="on">mdi-arrow-right-bold-circle-outline</v-icon>
        </template>
        <span>Launch Campaign</span>
      </v-tooltip>
    </template>
  </stamp-data-table>
</template>
<script>
import DataTable from '../../common/DataTable.vue';
import { mapGetters } from 'vuex';

export default {
  components: { 'stamp-data-table': DataTable },
  props: ['search'],
  data() {
    return {
      headers: [
        { text: 'Mail Drop ID', value: 'mail_drop_id', sortable: true },
        { text: 'Drop Nr', value: 'drop_num', sortable: true, align: 'center' },
        { text: 'Drop Date', value: 'drop_date', sortable: true, align: 'center' },
        { text: 'Drop Quantity', value: 'qty', sortable: true },
        { text: 'Osprey Order ID', value: 'osprey_order_id', sortable: true },
        { text: 'Osprey Customer ID', value: 'crm_customer_id', sortable: true },
        { text: 'Firebase Order ID', value: 'firebase_order_id', sortable: false, align: 'center' },
        { text: 'Action', value: 'action', align: 'center', sortable: false }
      ]
    };
  },
  computed: {
    ...mapGetters('letters', {
      drops: 'getLetterDrops',
      isLetterDropsLoading: 'getLetterDropsLoadingState'
    })
  },
  created() {
    this.loadLetterDrops();
  },
  methods: {
    loadLetterDrops() {
      return this.$store.dispatch('letters/loadLetterDrops');
    },
    launchCampaign(order) {
      let startDate = this.$moment().subtract(1, 'months').format('YYYY-MM-DD');
      let endDate = this.$moment().format('YYYY-MM-DD');
      let queryString = `?start_date=${startDate}&end_date=${endDate}&crm_mail_drop_id=${order.mail_drop_id}&drop_num=${order.drop_num}&crm_order_id=${order.osprey_order_id}&crm_customer_id=${order.crm_customer_id}&firebase_order_id=${order.firebase_order_id}`;
      this.$router.push({ path: queryString });
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    }
  }
};
</script>
