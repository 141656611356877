<template>
  <v-dialog v-if="isDialogOpen" v-model="isDialogOpen" transition="fade-transition" persistent max-width="500">
    <v-row class="justify-center ml-0">
      <v-avatar color="primary" size="55" class="dialogIcon mt-n4">
        <v-icon large color="white">mdi-trash-can-outline</v-icon>
      </v-avatar>
    </v-row>
    <v-card>
      <v-system-bar color="primary" height="5"></v-system-bar>
      <v-card-title class="mt-10 justify-center">
        <span class="title font-weight-regular">Delete selected item(s)?</span>
      </v-card-title>
      <v-card-text>
        <v-row v-for="(item, index) in itemNamesToDelete" :key="index" class="justify-center">
          <span :key="index" class="subtitle-1 grey--text text--darken-2"><v-icon small>mdi-circle-small</v-icon> {{ item }}</span>
        </v-row>
      </v-card-text>
      <v-divider class="mt-3"></v-divider>
      <v-card-actions class="justify-space-between">
        <v-btn color="grey darken-1" class="subtitle-1 font-weight-medium text-capitalize px-5" outlined small @click="closeDialog()">Cancel</v-btn>
        <v-btn color="primary" class="subtitle-1 font-weight-medium text-capitalize px-5" small :disabled="isDeleteBtnDisabled" @click="confirmDelete()">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['isDialogOpened', 'itemNames'],
  data() {
    return {
      isDeleteBtnDisabled: false
    };
  },
  computed: {
    itemNamesToDelete() {
      return Array.isArray(this.itemNames) ? this.itemNames : [this.itemNames];
    },
    isDialogOpen() {
      return this.isDialogOpened;
    }
  },
  watch: {
    isDialogOpen() {
      this.isDeleteBtnDisabled = false;
    }
  },
  methods: {
    emitEvent(eventName, parameter) {
      this.$emit(eventName, parameter);
    },
    closeDialog() {
      this.emitEvent('closeDialog');
    },
    confirmDelete() {
      this.isDeleteBtnDisabled = true;
      this.emitEvent('confirmDelete', this.itemNames);
    }
  }
};
</script>

<style scoped>
.dialogIcon {
  position: absolute;
  z-index: 1000;
}
</style>
