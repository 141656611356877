<template>
  <v-row>
    <v-col>
      <v-row class="mt-2 mb-5">
        <span class="headline ml-3">Settings</span>
      </v-row>
      <v-divider class="my-5"></v-divider>

      <v-row>
        <v-col sm="8" xl="6" class="mx-auto">
          <v-alert v-model="alertSuccess" type="success" dismissible outlined>
            <span>Maintenance status changed successfully!</span>
          </v-alert>
          <v-alert v-model="alertError" type="error" dismissible outlined>
            <span>Error(s) occur during maintenance status change!</span>
          </v-alert>
          <v-card class="custom-radius">
            <v-card-text>
              <v-row class="align-center">
                <v-col cols="11">
                  <h4 class="font-weight-medium mb-sm-3">Maintenance mode</h4>
                  <p class="grey--text text--darken-2 font-weight-regular mb-0">Please note that this will activate maintenace mode on all sites</p>
                </v-col>
                <v-col cols="1">
                  <v-switch v-model="isMaintenanceModeEnabled"></v-switch>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-alert v-model="isCustomerFeedbackVisible" :type="customerIdFeedbackType" dismissible outlined class="mt-6" @input="clearFeedbackProps">
            <span>{{ feedbackText }}</span>
          </v-alert>
          <v-dialog v-model="deleteFeedback" persistent max-width="500">
            <v-card>
              <v-card-title class="headline">Are you sure you want to delete this CRM Customer ID?</v-card-title>
              <v-card-actions class="mx-2 mt-6 pb-4">
                <v-btn color="error" text @click="closeDeleteDialog()">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="deleteListItem()">Agree</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-if="isEditCustomerIdDialogOpened" v-model="isEditCustomerIdDialogOpened" max-width="500px">
            <v-card>
              <v-card-title><span class="headline ml-2">Edit CRM Customer ID</span></v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="10" md="10" lg="10">
                      <v-text-field v-model="editedCustomer.customerId" label="CRM Customer ID" type="number"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeEditCustomerIdDialog">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="editListItem">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-card class="elevation-2 my-8 custom-radius">
            <v-row class="mx-0" color="blue">
              <span class="title grey--text text--darken-2 font-weight-regular pl-5 my-2 pt-1">Map Tool Settings</span>
            </v-row>
            <v-row class="mx-2 mt-n2 justify-space-between">
              <v-col cols="12" md="9">
                <v-text-field v-model="settingsData.map.map_bing_key" label="Map Bing Key" class="mb-n3" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn :loading="isSettingsLoading" class="mt-1" small color="primary" @click="saveSettings(['map'])">Save Settings</v-btn>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="elevation-2 my-8 custom-radius">
            <v-row class="mx-0" color="blue">
              <span class="title grey--text text--darken-2 font-weight-regular pl-5 my-2 pt-1">DMM Settings</span>
            </v-row>
            <v-row class="mx-2 mt-n1">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="settingsData.osprey.legacy_dmm_tool_endpoint"
                  label="DMM tool Osprey endpoint"
                  :rules="emptyFieldRule"
                  class="mb-n3 mt-3"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n1">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="settingsData.dmm.base_url_production"
                  label="DMMv3 Base URL - PRODUCTION"
                  :rules="emptyFieldRule"
                  class="mb-n3"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="settingsData.dmm.api_key_production" label="DMMv3 API Key - PRODUCTION" :rules="emptyFieldRule" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n1">
              <v-col cols="12" md="6">
                <v-text-field v-model="settingsData.dmm.base_url_staging" label="DMMv3 Base URL - STAGING" :rules="emptyFieldRule" class="mb-n3" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="settingsData.dmm.api_key_staging" label="DMMv3 API Key - STAGING" :rules="emptyFieldRule" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n2">
              <v-col cols="12" md="6">
                <v-text-field v-model="settingsData.dmm.base_url_develop" label="DMMv3 Base URL - DEVELOP" :rules="emptyFieldRule" class="mb-n3" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="settingsData.dmm.api_key_develop" label="DMMv3 API Key - DEVELOP" :rules="emptyFieldRule" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>

            <v-card-actions class="justify-end mr-3">
              <v-btn :loading="isSettingsLoading" class="ml-3 mb-2 mt-n2" small color="primary" @click="saveSettings(['dmm', 'osprey'])">Save Settings</v-btn>
            </v-card-actions>
          </v-card>

          <v-card class="elevation-2 custom-radius">
            <v-row class="mx-0" color="blue">
              <span class="title grey--text text--darken-2 font-weight-regular pl-5 my-2 pt-1">LDP Settings</span>
            </v-row>
            <v-row class="mx-2 mt-2">
              <v-col cols="12">
                <v-text-field v-model="settingsData.ldp.auth_endpoint" label="Authorization Endpoint - LIVE" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.ldp.auth_endpoint_test" label="Authorization Endpoint - TEST" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.ldp.auth_key_live" label="Authorization Key - LIVE" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.ldp.auth_key_test" label="Authorization Key - TEST" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.ldp.media_plan_endpoint" label="Media Plan Endpoint - LIVE" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.ldp.media_plan_endpoint_test" label="Media Plan Endpoint - TEST" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.ldp.submit_order_endpoint" label="Submit Order Endpoint - LIVE" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.ldp.submit_order_endpoint_test" label="Submit Order Endpoint - TEST" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end mr-3">
              <v-btn :loading="isSettingsLoading" class="ml-3 mb-2 mt-n2" small color="primary" @click="saveSettings(['ldp'])">Save Settings</v-btn>
            </v-card-actions>
          </v-card>

          <v-card class="elevation-2 my-8 custom-radius">
            <v-row class="mx-0" color="blue">
              <span class="title grey--text text--darken-2 font-weight-regular pl-5 my-2 pt-1">Golang Microservice Endpoint Settings</span>
            </v-row>
            <v-row class="mx-2 mt-n2">
              <v-col cols="12">
                <v-text-field v-model="settingsData.valassis.csv_service_url" label="VNEF - CSV Download Service URL" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.valassis.csv_service_key" label="VNEF - CSV Download Service Key" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.go_endpoints.zip_list_service_url" label="Get Zip List Service URL" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.go_endpoints.zip_list_service_key" label="Get Zip List Service Key" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.go_endpoints.generate_csv_from_map_url" label="Generate CSV from map Service URL" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field
                  v-model="settingsData.go_endpoints.eddm_excel_service_url"
                  label="EDDM JSON Process Download Excel Service URL"
                  class="mb-n3"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.go_endpoints.generate_pdf_service_url" label="Generate PDF from map IMAGE/PDF URLs" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.go_endpoints.go_upload_ldp_csv_url" label="Upload LDP CSV - Service URL" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.go_endpoints.go_upload_ldp_csv_api_key" label="Upload LDP CSV - Service Key" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.go_endpoints.go_letter_preview_url" label="GO Letter Preview URL" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.go_endpoints.go_letter_preview_token" label="GO Letter Preview Token" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.go_endpoints.go_postcard_preview_url" label="GO Postcard Preview URL" class="mb-n3" outlined dense></v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end mr-3">
              <v-btn :loading="isSettingsLoading" class="ml-3 mb-2 mt-n2" small color="primary" @click="saveSettings(['valassis', 'go_endpoints'])">Save Settings</v-btn>
            </v-card-actions>
          </v-card>

          <v-card class="elevation-2 custom-radius my-8">
            <v-row class="mx-0" color="blue">
              <span class="title grey--text text--darken-2 font-weight-regular pl-5 my-2 pt-1">Tax Rate Options</span>
            </v-row>
            <v-row class="mx-2 mt-2">
              <v-col cols="12">
                <v-text-field v-model="settingsData.tax_rates.exempt" type="number" label="Tax Exempt" class="mb-n3" outlined dense :rules="taxRateRules"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.tax_rates.partial" type="number" label="Partial Tax Rate" class="mb-n3" outlined dense :rules="taxRateRules"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-n5">
              <v-col cols="12">
                <v-text-field v-model="settingsData.tax_rates.full" type="number" label="Full Tax Rate" class="mb-n3" outlined dense :rules="taxRateRules"></v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end mr-3">
              <v-btn :loading="isSettingsLoading" class="ml-3 mb-2 mt-n2" small color="primary" @click="saveSettings(['tax_rates'])">Save Settings</v-btn>
            </v-card-actions>
          </v-card>

          <v-card class="elevation-2 custom-radius my-8">
            <v-row class="mx-0" color="blue">
              <span class="title grey--text text--darken-2 font-weight-regular pl-5 my-2 pt-1">4over Options</span>
            </v-row>
            <v-row class="mx-2 mt-2">
              <v-col cols="12">
                <v-select
                  v-model="settingsData.fourover_credit_card.profile_token"
                  :items="maskedCreditCards"
                  item-text="masked_number"
                  item-value="profile_token"
                  label="Credit Card"
                  class="mb-n3"
                  outlined
                  dense
                  :rules="emptyFieldRule"
                ></v-select>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end mr-3">
              <v-btn :loading="isSettingsLoading" class="ml-3 mb-2 mt-n2" small color="primary" @click="saveSettings(['fourover_credit_card'])">Save Settings</v-btn>
            </v-card-actions>
          </v-card>

          <v-card class="elevation-2 custom-radius">
            <v-row class="mx-0" color="blue">
              <span class="title grey--text text--darken-2 font-weight-regular pl-5 my-2 pt-1">Template Import Example CSV</span>
            </v-row>
            <v-row class="mx-2 mt-2">
              <v-col cols="12">
                <v-text-field
                  v-model="settingsData.templates.template_upload_example"
                  label="Template Import Example"
                  class="mb-n3"
                  outlined
                  dense
                  :rules="emptyFieldRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="justify-end mr-3">
              <v-btn :loading="isSettingsLoading" class="ml-3 mb-2 mt-n2" small color="primary" @click="saveSettings(['templates'])">Save Settings</v-btn>
            </v-card-actions>
          </v-card>

          <div class="mt-5">
            <v-card class="elevation-2">
              <v-toolbar flat color="white">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-toolbar-title class="grey--text text--darken-2" v-on="on">Sonicprint CRM Customer Ids</v-toolbar-title>
                  </template>
                  <span>The list on CRM Customer Ids that are using SonicPrint Credentials (used on newmovers endpoint for DMM New Campaign and Mail to Audience)</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-dialog v-if="isNewCustomerIdDialogOpened" v-model="isNewCustomerIdDialogOpened" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-btn dark class="primary" small v-on="on"><v-icon small>mdi-plus</v-icon>New ID</v-btn>
                  </template>
                  <v-card>
                    <v-card-title><span class="headline ml-2">Add new CRM Customer ID</span></v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="10" md="10" lg="10">
                            <v-text-field v-model="crmCustomerId" label="CRM Customer ID" type="number"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeNewCustomerIdDialog">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="saveNewCustomerId">Add</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>

              <stamp-data-table :headers="crmListHeaders" :data="crmCustomerIdListItems" sort-by="creationDate" item-key="customerId" :is-footer-hidden="true">
                <template v-slot:[`item.creationDate`]="{ item }">{{ formatDate(item.creationDate) }}</template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" color="success" @click="openEditDialog(item)"> mdi-pencil </v-icon>
                  <v-icon small color="error" @click="openDeleteDialog(item)"> mdi-delete </v-icon>
                </template>
              </stamp-data-table>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';
import { mapGetters } from 'vuex';
import stampSettingsService from '../../services/stampSettingsService.js';

export default {
  components: { 'stamp-data-table': DataTable },
  data() {
    return {
      isMaintenanceModeEnabled: false,
      isMaintenanceModeStatusLoaded: false,
      alertSuccess: false,
      alertError: false,
      crmListHeaders: [
        { text: 'Creation Date', align: 'left', sortable: false, value: 'creationDate' },
        { text: 'CRM Customer ID', align: 'left', sortable: false, value: 'customerId' },
        { text: 'Added by', align: 'left', sortable: false, value: 'addedBy' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      isNewCustomerIdDialogOpened: false,
      crmCustomerId: '',
      isEditCustomerIdDialogOpened: false,
      editedCustomer: {},
      isCustomerFeedbackVisible: false,
      customerIdFeedbackType: 'info',
      feedbackText: '',
      deleteFeedback: false,
      itemSelectedforDelete: {},
      isSettingsLoading: false,
      creditCards: [],
      settingsData: {
        dmm: {
          api_key_develop: '',
          api_key_production: '',
          api_key_staging: '',
          base_url_develop: '',
          base_url_production: '',
          base_url_staging: ''
        },
        map: {
          map_bing_key: ''
        },
        osprey: {
          legacy_dmm_tool_endpoint: ''
        },
        ldp: {
          auth_endpoint: '',
          auth_endpoint_test: '',
          auth_key_live: '',
          auth_key_test: '',
          media_plan_endpoint: '',
          media_plan_endpoint_test: '',
          submit_order_endpoint: '',
          submit_order_endpoint_test: ''
        },
        go_endpoints: {
          eddm_excel_service_url: '',
          generate_csv_from_map_url: '',
          generate_pdf_service_url: '',
          zip_list_service_url: '',
          zip_list_service_key: '',
          go_letter_preview_url: '',
          go_letter_preview_token: ''
        },
        valassis: {
          csv_service_url: '',
          csv_service_key: ''
        },
        tax_rates: {
          exempt: 0,
          partial: 0,
          full: 0
        },
        fourover_credit_card: {
          profile_token: ''
        },
        templates: {
          template_upload_example: ''
        }
      },
      emptyFieldRule: [value => !!value || 'Required'],
      taxRateRules: [value => !!value || 'Required', value => (value >= 0 && value <= 100) || 'Value must be between 0 and 100']
    };
  },
  computed: {
    ...mapGetters('settings', {
      maintenance: 'getMaintenanceState',
      crmCustomerIdListItems: 'getCrmCustomerIdListItems'
    }),
    ...mapGetters('users', {
      userStore: 'user'
    }),
    maskedCreditCards() {
      return this.creditCards.map(card => {
        const masked_number = `**** **** **** ${card.last_four}`;
        return { ...card, masked_number };
      });
    }
  },
  watch: {
    isMaintenanceModeEnabled(value) {
      if (value === false && this.isMaintenanceModeStatusLoaded) {
        this.isMaintenanceModeStatusLoaded = false;
      }
      if (!this.isMaintenanceModeStatusLoaded) {
        this.$store.dispatch('showLoader', { message: 'Loading maintenance state...' });
        this.$store
          .dispatch('settings/changeMaintenanceMode', { mValue: value ? 1 : 0 })
          .then(response => {
            this.$store.dispatch('hideLoader');
            if (response.status === 'done') {
              const message = `Maintenance status changed to ${value ? 'ON' : 'OFF'}`;
              const date = Number(new Date());
              const user = this.userStore.name;
              this.$store.dispatch('notifications/addNotification', { message, date, user });
              this.alertSuccess = true;
            } else {
              this.alertError = true;
            }
          })
          .catch(error => {
            console.log('[ changeMaintenanceMode Error ]:', error);
            this.alertError = true;
          });
      }
    }
  },
  created() {
    this.$store.dispatch('settings/fetchCrmCustomerIdList');
    this.loadStampSettings();
    this.loadMaintenanceState();
  },
  methods: {
    async loadStampSettings() {
      try {
        this.$store.dispatch('showLoader', { message: 'Loading STAMP settings...' });
        this.creditCards = await this.$store.dispatch('fourover/getAllPaymentProfiles');
        const settingsData = await stampSettingsService.getAll();
        this.settingsData = settingsData;
      } catch (error) {
        this.$store.dispatch('showAlert', { type: 'error', message: 'An error occurred while loading the settings.' });
        console.error('[loadStampSettings ERROR:]', error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async loadMaintenanceState() {
      try {
        this.$store.dispatch('showLoader', { message: 'Loading STAMP settings...' });
        await this.$store.dispatch('settings/maintenanceState');
        this.isMaintenanceModeEnabled = this.maintenance.allSites !== 0;
        this.isMaintenanceModeStatusLoaded = true;
      } catch (error) {
        this.$store.dispatch('showAlert', { type: 'error', message: 'An error occurred while loading maintenance state.' });
        console.error('[getMaintenanceState ERROR:]', error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    saveNewCustomerId() {
      this.$store
        .dispatch('settings/addNewCrmCustomerIdToList', {
          creationDate: Number(new Date()),
          addedBy: this.userStore.name,
          customerId: this.crmCustomerId
        })
        .then(response => {
          this.isNewCustomerIdDialogOpened = false;
          this.setUserFeedback(response, this.crmCustomerId, 'adding');
          this.crmCustomerId = '';
        });
    },
    editListItem() {
      this.$store.dispatch('settings/editCrmCustomerId', this.editedCustomer).then(response => this.setUserFeedback(response, this.editedCustomer.customerId, 'editing'));
    },
    deleteListItem() {
      const item = this.itemSelectedforDelete;
      this.$store.dispatch('settings/deleteCrmCustomerIdFromList', item.id).then(response => this.setUserFeedback(response, item.customerId, 'deleting'));
    },
    setUserFeedback(response, id, actionName) {
      const capitalizedAction = actionName.charAt(0).toUpperCase() + actionName.slice(1);
      const isResponseSuccess = response?.status === 'success';
      this.showFeedback(
        isResponseSuccess ? 'success' : 'error',
        isResponseSuccess ? `${capitalizedAction} CRM Customer ID with id: ${id} executed successfully!` : `Error occured during ${actionName} customer!`
      );
    },
    formatDate(date) {
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    openEditDialog(item) {
      this.editedCustomer = item;
      this.isEditCustomerIdDialogOpened = true;
    },
    openDeleteDialog(item) {
      this.itemSelectedforDelete = item;
      this.deleteFeedback = true;
    },
    closeDeleteDialog() {
      this.deleteFeedback = false;
      this.itemSelectedforDelete = {};
    },
    showFeedback(type, message) {
      this.customerIdFeedbackType = type;
      this.feedbackText = message;
      this.isCustomerFeedbackVisible = true;
    },
    clearFeedbackProps() {
      this.customerIdFeedbackType = 'info';
      this.isCustomerFeedbackVisible = false;
      this.feedbackText = '';
    },
    closeEditCustomerIdDialog() {
      this.isEditCustomerIdDialogOpened = false;
      this.editedCustomer = null;
    },
    closeNewCustomerIdDialog() {
      this.isNewCustomerIdDialogOpened = false;
    },
    saveSettings(keys = []) {
      if (!keys.length) {
        return;
      }
      const promises = [];
      keys.forEach(key => {
        console.log('SETTINGS TO SAVE: ', key, this.settingsData[key]);
        promises.push(stampSettingsService.update(key, this.settingsData[key]));
      });
      this.isSettingsLoading = true;
      Promise.all(promises)
        .then(() => {
          this.customerIdFeedbackType = 'success';
          this.feedbackText = 'Settings updated succesfully!';
        })
        .catch(error => {
          console.log('error', error);
          this.customerIdFeedbackType = 'error';
          this.feedbackText = 'Error occured during updating settings!';
        })
        .finally(() => {
          this.isCustomerFeedbackVisible = true;
          this.isSettingsLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.custom-radius {
  border-radius: 10px;
}
</style>
