<template>
  <v-alert v-if="isVisible" :type="type" prominent dense dismissible transition="scroll-y-transition" class="mt-2 mb-n5" @input="hideAlert()">
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  data() {
    return {
      isVisible: '',
      type: 'info',
      message: '',
    };
  },
  computed: {
    alert() {
      return this.$store.getters.getAlertGlobal;
    },
  },
  watch: {
    alert: {
      handler(alert) {
        this.isVisible = alert.isVisible;
        this.type = alert.type;
        this.message = alert.message;
      },
      deep: true,
    },
  },
  methods: {
    hideAlert() {
      this.$store.dispatch('hideAlert');
    },
  },
};
</script>
