<template>
  <v-dialog v-model="dialogSaturationCSV" scrollable persistent max-width="500">
    <v-card>
      <v-card-title>
        <h3 class="headline">Includes</h3>
        <v-spacer></v-spacer>
        <v-btn small icon @click="closeModal()"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-divider v-if="!isSaturationCsvDownloading && !isSaturationCsvFailed" class="mx-1"></v-divider>
      <v-progress-linear v-if="isSaturationCsvDownloading" indeterminate color="primary"></v-progress-linear>
      <v-alert v-if="isSaturationCsvFailed" color="error" type="error" dense dismissible>Download the CSV has failed. Please check the format of the selected map.</v-alert>
      <v-card-text>
        <v-row class="ml-0 mt-2"><span class="subtitle-1 grey--text text--darken-2">Filename for downloaded CSV</span></v-row>
        <csv-filename-editor referenceType="map" :defaultFileName="mapItem.orderType" :firebaseId="firebaseMapId" @fileName="setFileName"> </csv-filename-editor>
        <v-divider />
        <v-row>
          <v-col cols="12">
            <v-switch v-model="includePhones" color="cyan darken-2" label="Include Phones"></v-switch>
          </v-col>
          <v-col cols="12" class="mt-n8">
            <v-switch v-model="includeNames" color="cyan darken-2" label="Include Names"></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="blue-grey lighten-4" class="mb-3 mt-n6" @click="getSaturationCsv()">Download CSV </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileNameEditor from './CsvFileNameEditor';
import { mapGetters } from 'vuex';

export default {
  components: { 'csv-filename-editor': FileNameEditor },
  props: ['modal', 'mapData', 'mapId'],
  data() {
    return {
      dialogSaturationCSV: this.modal,
      includePhones: true,
      includeNames: true,
      isSaturationCsvFailed: false,
      isSaturationCsvDownloading: false,
      mapItem: this.mapData,
      csvFileName: ''
    };
  },
  computed: {
    firebaseMapId() {
      return this.mapId ?? this.mapItem?.firebase_map_id ?? '';
    }
  },
  watch: {
    modal() {
      this.dialogSaturationCSV = this.modal;
    },
    mapData() {
      this.mapItem = this.mapData;
    }
  },
  methods: {
    closeModal() {
      this.ospreyOrderIds = [];
      this.selectedOspreyId = '';
      this.csvFileName = '_saturation';
      this.$emit('closeSaturationModal');
    },
    getSaturationCsv() {
      console.log(`Includes: Phones: ${this.includePhones} Names: ${this.includeNames}`);
      console.log('MapItem: ', this.mapItem);

      this.isSaturationCsvDownloading = true;
      this.isSaturationCsvFailed = false;

      this.$store
        .dispatch('maps/getSaturationCsvData', {
          mapItem: this.mapItem,
          includePhones: this.includePhones,
          includeNames: this.includeNames,
          csvFileName: this.csvFileName
        })
        .then(data => {
          this.isSaturationCsvDownloading = false;
          this.isSaturationCsvFailed = false;
        })
        .catch(error => {
          console.log('Error:', error);
          this.isSaturationCsvDownloading = false;
          this.isSaturationCsvFailed = true;
        });
    },
    setFileName(name) {
      this.csvFileName = name;
    }
  }
};
</script>
