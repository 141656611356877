<template>
	<v-dialog v-model="updateOrderProcessOptions" max-width="1000" persistent>
		<v-card>
			<v-card-title class="headline red">
				<div class="text-xs-center">
					<span class="white--text text-md-center">Update Order Process ...</span>
				</div>
			</v-card-title>
			<v-card-text class="py-3">
				<v-alert v-model="emptyAttrName" type="warning" dismissible outlined>
					Site id cannot be empty!
				</v-alert>
				<v-layout align-center justify-center row>
					<div class="stamp-dsa-wrapper mt-4">
						<v-checkbox v-model="customProcess" :label="`Custom Process`"></v-checkbox>
						<v-text-field v-model="RLsiteID" label="Enter site ID" type="text"></v-text-field>
						<v-btn color="primary" class="mt-4" @click="updateObject()">
							<span>Update </span>
						</v-btn>
					</div>
				</v-layout>
				<v-row align="center" justify="start">
					<v-col cols="12" sm="10">
						<v-alert v-model="siteUpdateSuccesfully" type="success" dismissible outlined>
							Site Order Process -> Switches updated succefully!
						</v-alert>
						<v-alert v-model="siteUpdateError" type="success" dismissible outlined>
							Error on update Site Order Process -> Switches !
						</v-alert>
						<!-- <div class="mt-4">
							<h3>Corespondent process -> switch object after modification:</h3>
							<vue-json-pretty :data="updatedSwitchesObject" :show-double-quotes="true" :show-length="true" :show-line="true">
							</vue-json-pretty>
						</div> -->
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="10">
						<v-simple-table
							:fixed-header="fixedHeader"
							:height="height"
							dense
							class="elevation-1"
						>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">ID</th>
										<th class="text-left">Site id | Site Name</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in sites" :key="item.key">
										<td>{{ item.key }}</td>
										<td class="text-left">
											{{ item.value }}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="green darken-1" text @click="closeOrderProcessOptions">
					Cancel
				</v-btn>
			</v-card-actions>
			<v-progress-linear v-if="loading" :indeterminate="loading" class="my-0"></v-progress-linear>
		</v-card>
	</v-dialog>
</template>
<script>
import siteSettingsService from '../../../services/siteSettingsService.js'
import orderSettingsService from '../../../services/orderSettingsService.js'

// import VueJsonPretty from 'vue-json-pretty'
export default {
	// components: {
	// 	VueJsonPretty
	// },
	props: ['value'],
	data() {
		return {
			updateOrderProcessOptions: this.value,
			loading: false,
			RLsiteID: '',
			emptyAttrName: false,
			processes: [
				'availability',
				'custom',
				'eddm',
				'eddmPrintOnly',
				'newMovers',
				'printprepship',
				'saturation',
				'targeted',
				'template'
			],
			customProcesses: [
				// 'eddm','targeted'
				'saturation'
			],
			siteUpdateSuccesfully: false,
			siteUpdateError: false,
			updatedSwitchesObject: {},
			sites:[],
			fixedHeader: true,
			height: 500,
			customProcess: false
		}
	},
	computed: {},
	watch: {
		value(value) {
			this.updateOrderProcessOptions = this.value
		}
	},
	async created() {
		siteSettingsService.attachRootListener((sites) => {
			this.sitesList = sites
			this.sitesList.forEach((value) => {
				const siteIdAndName = `${value.site_id} | ${value.site_name}`
				this.sites.push({
					key: value.site_id,
					value: siteIdAndName
				})
			})
		}, { isArray: true });
		
	},
	methods: {
		closeOrderProcessOptions() {
			this.RLsiteID = ''
			this.$emit('closeOrderProcessOptions')
		},
		updateObject() {
			// -Lu7t90MWKHODwcW_pxW
			console.log('enter updateObject')
			if (this.RLsiteID.length > 0) {
				// let testArray = []
				let testObject = {}

				const promises = []
				let optionObject = {}
				let pathObject = []
				// -Lu7t90MWKHODwcW_pxW
				if (this.customProcess) {
					console.log('Enter Custom Process')
					this.customProcesses.forEach((value, index) => {
						const orderSettingPath = `${this.RLsiteID}/orderProcesses/${value}/steps`
						console.log(orderSettingPath)
						const prms = orderSettingsService.getSteps(this.RLsiteID, value)
							.then((steps) => {
								// console.log(snapshot.val())
								Object.entries(steps).forEach(([key, valueO]) => {
									if (valueO.stepId === 'delivery') {
										// optionObject = {
										// 	isDeliveryOptionAvailable: true
										// }
										pathObject.push({
											process: value,
											step: key,
											isDeliveryOptionAvailable: true
										})
									}
								})
							})
							.catch((err) => {
								console.log('error:', err)
								return Promise.reject(err)
							})
						promises.push(prms)
					})

					Promise.all(promises).then(() => {
						console.log('All sites parsed!')
						console.log('pathObject', pathObject)
						const promises = []
						pathObject.forEach((value, index) => {
							const orderProcess = value.process
							const step = parseInt(value.step)
							const isDeliveryOptionAvailable = value.isDeliveryOptionAvailable

							const siteId = this.RLsiteID
							const path = `${siteId}/orderProcesses/${orderProcess}/steps/${step}`
							console.log('Update for path:', path)
							const prms = orderSettingsService.update(siteId, {
									[`orderProcesses.${orderProcess}.steps.${step}`]: isDeliveryOptionAvailable
								})
								.then(() => {
									console.log('Update for path:' + path + ' Done')
								})
								.catch(error => {
									console.error(error)
									return Promise.reject(error)
								})
							promises.push(prms)
						})
						// -LVNi5v1vWd_393u9wVY
						Promise.all(promises).then(() => {
							console.log('All processes updated!')
							this.siteUpdateSuccesfully = true
							// this.RLsiteID = ''
						})
						.catch(error => {
							this.siteUpdateError = true
							console.error(error.message)
						})
					})
				} else {
					// TODO: put else here
				}
				// this.processes.forEach((value, index) => {
				// 	// console.log('---value', value)
				// 	console.log('orderSettings/' + this.RLsiteID + '/orderProcesses/' + value + '/steps')
				// 	const prms = db.ref('orderSettings/' + this.RLsiteID + '/orderProcesses/' + value + '/steps')
				// 		.once('value')
				// 		.then((snapshot) => {
				// 			// console.log(snapshot.val())
				// 			const shoot = snapshot.val()
				// 			Object.entries(shoot).forEach(([key, valueO]) => {

				// 				if (valueO.stepId === 'artwork') {
				// 					// console.log('--- value', value)
				// 					// console.log('--- key', key)
				// 					// console.log('isTemplateAvailable', valueO.isTemplateAvailable)
				// 					optionObject = {
				// 						fullCustomDesign: true,
				// 						later: true,
				// 						onlineProofing: true,
				// 						template: valueO.isTemplateAvailable,
				// 						upload: true
				// 					}
				// 					pathObject.push({
				// 						process: value,
				// 						step: key,
				// 						options: optionObject
				// 					})
				// 				}
				// 			})
				// 		})
				// 		.catch((err) => {
				// 			console.log('error:', err)
				// 			return Promise.reject(err)
				// 		})
				// 	promises.push(prms)
				// })

				// Promise.all(promises).then(() => {
				// 	console.log('All sites parsed!')
				// 	// console.log('pathObject', pathObject)
				// 	const promises = []
				// 	pathObject.forEach((value, index) => {
				// 		const orderProcess = value.process
				// 		const step = parseInt(value.step)
				// 		const options = value.options

				// 		const siteId = this.RLsiteID
				// 		const path = `orderSettings/${siteId}/orderProcesses/${orderProcess}/steps/${step}`
				// 		console.log('Update for path:', path)
				// 		const prms = db.ref(path)
				// 			.update({
				// 				options
				// 			})
				// 			.catch(error => {
				// 				console.error(error)
				// 				return Promise.reject(error)
				// 			})
				// 		promises.push(prms)
				// 	})
				// })

				// Promise.all(promises).then(() => {
				// 	console.log('All processes updated!')
				// 	this.siteUpdateSuccesfully = true
				// 	this.RLsiteID = ''
				// })
				// .catch(error => {
				// 	this.siteUpdateError = true
				// 	console.error(error.message)
				// })

				/*Custom step text*/
				// -Lu7t90MWKHODwcW_pxW
				// this.processes.forEach((value, index) => {
				// 	// console.log('---value', value)
				// 	console.log('orderSettings/' + this.RLsiteID + '/orderProcesses/' + value + '/steps')
				// 	const prms = db.ref('orderSettings/' + this.RLsiteID + '/orderProcesses/' + value + '/steps')
				// 		.once('value')
				// 		.then((snapshot) => {
				// 			// console.log(snapshot.val())
				// 			const shoot = snapshot.val()
				// 			Object.entries(shoot).forEach(([key, valueO]) => {

				// 				if (valueO.stepId === 'artwork') {
				// 					// console.log('--- value', value)
				// 					// console.log('--- key', key)
				// 					// console.log('isTemplateAvailable', valueO.isTemplateAvailable)

				// 					pathObject.push({
				// 						process: value,
				// 						step: key,
				// 						stepTexts: {
				// 							proofTitle: 'Online Proof ',
				// 							proofDescription: 'Would you like to approve an online proof before the order goes to print?'
				// 						}
				// 					})
				// 				}
				// 			})
				// 		})
				// 		.catch((err) => {
				// 			console.log('error:', err)
				// 			return Promise.reject(err)
				// 		})
				// 	promises.push(prms)
				// })

				// Promise.all(promises).then(() => {
				// 	console.log('All sites parsed!')
				// 	// console.log('pathObject', pathObject)
				// 	const promises = []
				// 	pathObject.forEach((value, index) => {
				// 		const orderProcess = value.process
				// 		const step = parseInt(value.step)
				// 		const stepTexts = value.stepTexts
				// 		// console.log(`orderProcess: ${orderProcess} | step: ${step} | stepTexts: ${stepTexts}`)
				// 		// console.log('stepTexts: ', stepTexts)
				// 		Object.entries(stepTexts).forEach(([keyO, valueO]) => {
				// 			// console.log(`orderProcess: ${orderProcess} | step: ${step} | stepTextsKey: ${keyO} | stepTextsvalue: ${valueO}`)
				// 			const siteId = this.RLsiteID
				// 			const path = `orderSettings/${siteId}/orderProcesses/${orderProcess}/steps/${step}/stepTexts`
				// 			console.log(`Update for PATH: orderSettings/${siteId}/orderProcesses/${orderProcess}/steps/${step}/stepTexts/${keyO}`)
				// 			const prms = db.ref(path)
				// 				.update({
				// 					[keyO]: valueO
				// 				})
				// 				.catch(error => {
				// 					console.error(error)
				// 					return Promise.reject(error)
				// 				})
				// 			promises.push(prms)
				// 		})
				// 	})
				// })

				// Promise.all(promises).then(() => {
				// 	console.log('All processes updated!')
				// 	this.siteUpdateSuccesfully = true
				// 	// this.RLsiteID = ''
				// })
				// .catch(error => {
				// 	this.siteUpdateError = true
				// 	console.error(error.message)
				// })
			} else {
				this.emptyAttrName = true
			}
		}
	}
}
</script>
<style scoped>
.stamp-dsa-wrapper {
	width: 100%;
}
</style>