<template>
  <v-layout id="quote-tool-content">
    <div class="quote-container">
      <oom-quote v-if="isBasicQuoteAvailable" v-model="basicQuote" :index="basicQuoteIndex" :categories="categories" @onQuoteCloned="onQuoteCloned"></oom-quote>
    </div>
    <div class="quote-container">
      <oom-quote v-if="isClonedQuoteVisible" v-model="clonedQuote" :index="clonedQuoteIndex" :categories="categories" :isQuoteCloned="isClonedQuoteVisible"></oom-quote>
    </div>
  </v-layout>
</template>

<script>
  import Quote from './Quote.vue'

  export default {
    components: {
      'oom-quote': Quote
    },
    data () {
      return {
        basicQuoteIndex: 1,
        clonedQuoteIndex: 2,
        basicQuote: null,
        clonedQuote: null
      }
    },
    computed: {
      defaultQuote () {
        return this.$store.getters.getDefaultQuote
      },
      categories () {
        return this.$store.getters.getCategories
      },
      selectedSiteUrl () {
        return this.$store.getters.getSiteForQuoteTool.site_url
      },
      isBasicQuoteAvailable () {
        return this.basicQuote !== undefined && this.basicQuote !== null
      },
      isClonedQuoteVisible () {
        return this.clonedQuote !== undefined && this.clonedQuote !== null
      }
    },
    watch: {
      defaultQuote () {
        if (this.defaultQuote !== undefined && this.defaultQuote !== null) {
          this.basicQuote = JSON.parse(JSON.stringify(this.defaultQuote))
        }
      },
      selectedSiteUrl () {
        this.basicQuote = null
        this.clonedQuote = null
      }
    },
    created () {
      const _self = this
      this.$store.commit('setProgressValue', true)

      Promise.all([this.$store.dispatch('initDefaultQuote'), this.$store.dispatch('loadCategories')]).then(() => {
        _self.$store.commit('setProgressValue', false)
      }).catch(() => {
        console.log('Init the default quote and loading of the categories has failed.')
        _self.$store.commit('setProgressValue', false)
      })
    },
    methods: {
      reset () {
        this.basicQuote = null
        this.clonedQuote = null
      },
      onQuoteCloned () {
        console.log('BASIC: ', this.basicQuote)
        this.clonedQuote = JSON.parse(JSON.stringify(this.basicQuote))
        console.log('CLONED: ', this.clonedQuote)
      }
    }
  }
</script>

<style>
  .quote-container {
    width: 100%
  }
</style>
