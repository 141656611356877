<template>
	<v-container fluid>
		<v-dialog v-model="isQuickViewDialogOpened" max-width="850">
			<v-card class="pr-3">
				<template slot="progress">
					<v-progress-linear color="cyan darken-2" indeterminate></v-progress-linear>
				</template>
				<v-card-title class="justify-space-between my-n1">
					<span class="subtitle-1 ml-3">{{ referenceType }} / {{ selectedReferenceId }}</span>
					<v-btn icon class="mr-n3" @click="closeRefQuickViewDialog()"><v-icon>mdi-close</v-icon></v-btn>
				</v-card-title>
				<v-divider />
				<v-row class="justify-center">
					<span class="title font-weight-regular my-4 grey--text text--darken-2">{{ selectedReferenceName }}</span>
				</v-row>
				<v-divider class="mb-5" />
				<v-card-text>
					<template v-for="(item, index) in Object.values(selectedReferenceData)">
						<v-row :key="index" class="justify-space-around px-2">
							<v-col cols="12" md="4" class="mt-n2">
								<span class="subtitle-2 grey--text text--darken-2">{{ item.key }}</span>
							</v-col>
							<v-col cols="12" md="8" class="mt-n2">
								<span v-if="item.key === 'Date'" class="font-weight-regular grey--text text--darken-3">{{ formatDate(item.value) }}</span>
								<a v-else-if="item.key.toUpperCase().includes('URL')" :href="item.value" target="_blank">{{ item.value }}</a>
								<span v-else class="subtitle-2 font-weight-regular grey--text text--darken-3">{{ item.value }}</span>
							</v-col>
						</v-row>
					</template>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-card v-if="isPanelOpened" class="elevation-0 mt-n10">
			<v-card-text class="mb-n3">
				<v-row class="ml-1 mb-n3">
					<v-col cols="12" sm="4">
						<p class="body-2 font-weight-regular">Refercence Type</p>
					</v-col>
					<v-col cols="12" sm="3">
						<p class="body-2 font-weight-regular">Item</p>
					</v-col>
				</v-row>
				<v-divider class="mb-3"></v-divider>
				<v-row class="mt-0" no-gutters>
					<v-col cols="12" class="mb-n5">
						<v-data-iterator :items="referenceList" hide-default-footer>
							<template #default="props">
								<v-row class="mt-4">
									<v-col v-for="item in props.items" :key="item.attribute_name" cols="12" class="py-0 ml-0">
										<v-list dense class="py-0">
											<v-list-item>
												<v-list-item-content class="py-0">
													<v-row class="ml-1 pt-1 justify-space-between" no-gutters>
														<v-col cols="12" sm="4" md="3" lg="2">
															<span v-if="item.attribute_name" class="subheading font-weight-regular">{{ item.attribute_name }}</span>
														</v-col>
														<v-col cols="12" sm="6" md="6">
															<v-row no-gutters>
																<div v-if="item.attribute_name && item.value">
																	<div v-if="item.attribute_name.includes('Salesforce')">
																		<a :href="`${rootPathSalesForce}${item.value}`" target="_blank" class="subheading font-weight-regular">{{ item.value }}</a>
																	</div>
																	<div v-else-if="isSaturationOrder && item.attribute_name.includes('Filename')">
																		<a :href="item.url" target="_blank" class="subheading font-weight-regular">{{ item.value }}</a>
																	</div>
																	<div v-else>
																		<a :href="`${rootPath}${referenceName(item.value, false)}/view/${referenceId(item.value)}`" target="_blank" class="subheading font-weight-regular">{{ item.value }}</a>
																	</div>
																</div>
																<v-tooltip bottom>
																	<template #activator="{on}">
																		<v-btn class="ml-3 mt-n2" icon v-on="on" @click="copyReferenceIdToClipboard(item.value)"><v-icon small>mdi-content-copy</v-icon></v-btn>
																	</template>
																	<span>Copy reference ID to clipboard</span>
																</v-tooltip>
															</v-row>
														</v-col>
														<v-col cols="12" sm="2" class="mt-n2">
															<div v-if="item.attribute_name">
																<div v-if="!item.attribute_name.includes('Salesforce') && !item.url">
																	<v-btn text small color="cyan darken-2" @click="refDetails(item.value)">Details</v-btn>
																</div>
																<div v-else-if="item.url && isSaturationOrder && item.attribute_name.includes('Filename')">
																	<v-btn text small color="cyan darken-2" :href="item.url" target="_blank" download>Download</v-btn>
																</div>
															</div>
														</v-col>
													</v-row>
												</v-list-item-content>
											</v-list-item>
										</v-list>
									</v-col>
								</v-row>
							</template>
						</v-data-iterator>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
export default {
    props: ['isPanelOpened','orderData'],
    data () {
        return {
			referenceList: [],
			rootPath: `${window.location.origin}/`,
			rootPathSalesForce: 'https://onebrand.my.salesforce.com/',
			isQuickViewDialogOpened: false,
			referenceType: '',
			selectedReferenceId: '',
			selectedReferenceName: '',
			selectedReferenceData: {}
        }
	},
	computed: {
		isSaturationOrder () {
			return this.orderData && this.orderData.order_type === 'saturation'
		}
	},
	watch: {
		isPanelOpened: {
			handler: function (value) {
				if (value) this.loadReferences()
			},
			immediate: true
		}
	},
	methods: {
		loadReferences() {
			this.referenceList = []
			if (this.orderData.hasOwnProperty('references') && Object.keys(this.orderData.references).length > 0) {
				Object.entries(this.orderData.references).forEach(([key, val]) => {
					// salesforce_line_item_results will not be displayed
					if (val && key !== 'salesforce_line_item_results' && key !== 'encrypted_osprey_order_id') {
						this.referenceList.push({
							attribute_name: this.capitalize(key),
							value: val
						})
					}
				})
			}
			if (this.orderData && this.isSaturationOrder) {
				this.setReferenceToSaturationAsset()
			}
		},
		referenceName(ref, onlyRef) {
			const refSplit = ref.split('/')
			if (onlyRef) {
				return refSplit[0]
			}
			if (refSplit[0] === 'mailingLists') {
				return 'list'
			} else if (refSplit[0] === 'customDesigns') {
				return 'designs'
			}
			return refSplit[0]
		},
		referenceId(ref) {
			return ref.split('/')[1] || ''
		},
		refDetails(ref) {
			this.referenceType = this.referenceName(ref, true)
			this.selectedReferenceId = this.referenceId(ref)

			this.$store.dispatch('customer_messaging/getSummary', { refName: this.referenceType, referenceId: this.selectedReferenceId }).then(result => {
				this.selectedReferenceName = result.data.name ? result.data.name : ''
				this.selectedReferenceData = result.data.summary ? result.data.summary : ''
				if (this.referenceType === 'mailingLists') this.selectedReferenceData.push({ key: "Type", value: result.data.type })
			}).catch(error => this.$emit('updateFeedback', { type: 'error', message: error }))
			.finally(() => this.isQuickViewDialogOpened = true)
		},
		closeRefQuickViewDialog () {
			this.isQuickViewDialogOpened = false
		},
		async setReferenceToSaturationAsset () {
			let fileIndex = 0
			if (this.orderData.firebase_order_id) {
				const items = await this.$store.dispatch('customer_messaging/listOrderData', { documentId: this.orderData.firebase_order_id }).then(response => response.tree)
				items.forEach((value) => {
					if (value.name === 'assets') {
						Object.values(value.children).forEach((child) => {
							if ( Object.values(child)[0].includes('map_list') || Object.values(child)[0].includes('mailing_list') ) {
								Object.values(child)[1].forEach((val) => {
									let assetUrl = ''
									let assetName = ''
									fileIndex++
	
									Object.values(val.children).forEach(prop => {
										if (prop.name.includes('name')) {
											assetName = prop.name.substring(7)
										}
										if (prop.name.includes('url')) {
											assetUrl = prop.name.substring(6)
										}
									})
	
									if (assetName !== '' && assetUrl !== '') {
										this.referenceList.push({
											attribute_name: `Filename ${fileIndex}`,
											value: assetName,
											url: assetUrl
										})
									}
								})
							}
						})
					}
				})
			}
		},
		copyReferenceIdToClipboard(id) {
			const el = document.createElement('textarea');
			el.value = id.split('/')[1] || id;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
		},
		capitalize (text) {
			if (!text) return ''
			const splittedText = text.toString().split('_')
			let formatted = ''
			splittedText.forEach((chunk, index) => {
				formatted += `${index > 0 ? ' ' : ''}${chunk.charAt(0).toUpperCase()}${chunk.slice(1)}` 
			})
			return formatted
		},
		formatDate(dateTotransform) {
			var date = new Date(dateTotransform * 1000)
			return this.$moment(date).format("MMMM DD, YYYY [at] HH:mm:ss")
		}
	}
}
</script>