<template>
  <v-container>
    <v-row class="mt-1">
      <v-col cols="12">
        <span class="headline pt-1 mb-3">Order Reports</span>
      </v-col>
    </v-row>

    <v-alert v-if="feedbackAlert" :type="feedbackType" outlined dismissible>{{ feedbackMessage }}</v-alert>

    <v-row class="justify-center mx-0">
      <v-card class="custom-radius mt-5" width="100%">
        <v-card-text>
          <v-row class="mt-1">
            <v-col cols="12" sm="3" lg="3" xl="4" class="mb-n4">
              <v-menu v-model="menuStart" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field :value="startDate" label="Start Date" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="startDate" @change="menuStart = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="3" lg="3" xl="4" class="mb-n4">
              <v-menu v-model="menuEnd" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field :value="endDate" label="End date" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="endDate" @change="menuEnd = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mt-n4">
            <v-col cols="12">
              <v-autocomplete
                v-model="searchText"
                :items="sitesList"
                label="Firebase Site (Note: If there is no Firebase Site selected, the orders will be collected from all of the sites.)"
                required
                item-value="firebaseId"
                item-text="site_name"
                chips
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="mt-n4">
            <v-toolbar flat class="site-table--header mb-n2">
              <v-toolbar-title class="body-2 mr-2">Type: </v-toolbar-title>
              <v-chip-group v-model="selectedOrderType" active-class="primary--text" multiple>
                <template v-for="(filterName, index) in orderTypeFilters">
                  <v-chip :key="index" filter outlined>
                    <span v-if="filterName === 'eddm'">EDDM</span>
                    <span v-else>{{ capitalize(filterName) }}</span>
                  </v-chip>
                </template>
              </v-chip-group>
            </v-toolbar>
          </v-row>

          <v-row class="mt-5">
            <stamp-order-status-filter @onStatusCleared="onStatusCleared" @onOrderStatusSelected="onOrderStatusSelected"></stamp-order-status-filter>
          </v-row>

          <v-row class="mt-14" justify="center">
            <v-col cols="6">
              <v-btn class="subtitle-2 blue white--text" block dark :disabled="!isDatesValid" @click="geLdpReport()">
                <v-icon class="mr-2">mdi-file-download-outline</v-icon>Download LDP Orders Report
              </v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn class="subtitle-2 blue white--text" block dark :disabled="!isDatesValid" @click="getGeneralReport()">
                <v-icon class="mr-2">mdi-file-download-outline</v-icon>Download CSV Report with Map Data
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { dbFS } from '@/services/firebaseInit';
import { capitalize } from '@/modules/helpers/format';
import orderService from '@/services/orderService.js';
import OrderStatusFilter from '@/components/order-management/orders/OrderStatusFilter.vue';

export default {
  components: {
    'stamp-order-status-filter': OrderStatusFilter
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      menuStart: false,
      menuEnd: false,
      feedbackAlert: false,
      feedbackType: 'info',
      feedbackMessage: '',
      searchText: '',
      selectedOrderType: '',
      orderTypeFilters: ['availability', 'eddm', 'inserts', 'newmovers', 'saturation'],
      orderStatusFilter: '',
      isLdpReportSelected: false
    };
  },
  computed: {
    ...mapGetters('site', {
      sites: 'getSites'
    }),
    isDatesValid() {
      if (!this.startDate || !this.endDate || this.$moment(this.endDate).diff(this.startDate) < 0) {
        this.setFeedback('error', 'End date cannot be less than Start date! Please choose another End date!');
        return false;
      }
      return true;
    },
    isAvailabilitySelected() {
      return this.orderTypeFilters[this.selectedOrderType] === 'availability';
    },
    commonFilter() {
      let filterString = '';
      if (this.orderStatusFilter) {
        filterString = this.orderStatusFilter ? `${this.orderStatusFilter} AND (` : '(';
      }

      if (this.selectedOrderType.length) {
        this.selectedOrderType.forEach((type, index) => {
          filterString += index === this.selectedOrderType.length - 1 ? ` order_type: ${this.orderTypeFilters[type]})` : ` order_type: ${this.orderTypeFilters[type]} OR`;
        });
      }

      return `${filterString} AND (creation_date_timestamp: ${this.utils.getUnix(this.startDate)} TO ${this.utils.getUnix(this.endDate)})`;
    },
    utils() {
      return this.$store.getters.getUtilsInstance;
    },
    sitesList() {
      return this.sites?.length ? this.sites : [];
    }
  },
  created() {
    this.startDate = this.formatDate(this.$moment().startOf('month'), 'YYYY-MM-DD');
    this.endDate = this.formatDate(this.$moment(), 'YYYY-MM-DD');
  },
  async mounted() {
    if (!this.sites) {
      await this.$store.dispatch('loadSitesFromDb');
    }
  },
  methods: {
    onOrderStatusSelected(selectedStatusList) {
      if (selectedStatusList.length) {
        let statusFilter = '(';
        for (let status of selectedStatusList) {
          statusFilter += `${statusFilter === '(' ? '' : ' OR '}order_status.status:${status}`;
        }
        statusFilter += ')';

        this.orderStatusFilter = statusFilter;
      } else {
        this.orderStatusFilter = '';
      }
    },
    onStatusCleared() {
      this.orderStatusFilter = '';
    },
    formatDate(date, format) {
      return format && date ? date.format(format) : this.$moment();
    },
    getOrdersByFilter() {
      this.$store.dispatch('showLoader', { message: 'Processing, please wait...' });
      const config = {
        indexName: `orders${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`,
        filter: this.commonFilter,
        hitsPerPage: 200000
      };

      if (this.searchText) {
        config.searchValue = this.searchText;
      }
      return this.$store
        .dispatch('algolia/getDataFromAlgolia', config)
        .then(result => {
          console.log('Succeeded getLDPMapReport:', result);
          return result.hits;
        })
        .catch(error => {
          console.log('Error getLDPMapReport:', error);
          return this.$store.dispatch('hideLoader');
        });
    },
    geLdpReport() {
      if (!this.isAvailabilitySelected) {
        this.setFeedback('error', 'Please select the Availability order type.');
        return;
      }
      this.isLdpReportSelected = true;
      this.getGeneralReport();
    },
    getGeneralReport() {
      this.getOrdersByFilter().then(result => {
        if (result.length) {
          if (this.isAvailabilitySelected) {
            this.setLiveMediaPlans(result);
          } else {
            this.getMapDataByOrder(result.map(item => item.firebase_order_id));
          }
        } else {
          this.$store.dispatch('hideLoader');
          this.setFeedback('info', 'There is no order that meets the conditions.');
        }
      });
    },
    setLiveMediaPlans(orders) {
      const liveMediaPlan = [];
      const missingMediaPlan = [];
      const missingLocations = [];

      orders.forEach(order => {
        if (order?.ldp_order?.ldp_bookings) {
          liveMediaPlan.push(order);
        } else {
          missingMediaPlan.push(order.firebase_order_id);
        }
      });

      console.log('liveMediaPlan: ', liveMediaPlan);
      console.log('missingMediaPlan: ', missingMediaPlan);
      console.log('missingLocations: ', missingLocations);

      if (this.isLdpReportSelected) {
        if (liveMediaPlan.length) {
          this.getSubmittedLdpOrderByOrder(liveMediaPlan);
        } else {
          this.$store.dispatch('hideLoader');
          this.setFeedback('info', 'There is no order that meets the conditions.');
        }
      } else {
        this.getMapDataByOrder(liveMediaPlan);
      }
    },
    async getSubmittedLdpOrderByOrder(submittedOrders) {
      const csvData = await this.getSubmittedLdpCsvData(submittedOrders);
      this.generateCsvReport(csvData);
      this.isLdpReportSelected = false;
      this.$store.dispatch('hideLoader');
    },
    async getSubmittedLdpCsvData(orders) {
      const csvData = [];
      let submittedLdpDetails = '';
      let customer = '';

      for (let order of orders) {
        submittedLdpDetails = order.ldp_order?.ldp_bookings?.[0]?.ldp_booking_data?.orderBody?.baseProductList?.[0]?.productType || '';
        customer = await this.getCustomerDataByOrderId(order.firebase_order_id);

        csvData.push({
          'First Name': order.first_name || '',
          'Last Name': order.last_name || '',
          'Company Name': customer?.additional?.company_name || '', //TODO: get from order_data.customer.additional
          'Oracle ID': submittedLdpDetails?.oracleAccountNumber || '',
          'Client name on Ad': submittedLdpDetails?.clientNameAppearingOnAd || '',
          'Client Promotion': submittedLdpDetails?.clientPromotion || '',
          'Order Number': order.crm_order_id,
          'Site Name': order.site_name || '',
          'Creation Date': this.formatDate(this.$moment(order.creation_date), 'YYYY-MM-DD')
        });
      }
      return csvData;
    },
    getMapDataByOrder(orders) {
      const promises = [];
      let csvData = [];

      Promise.all(
        orders.map(async order => {
          // Get Order object by Firebase order ID from DB
          const orderData = await this.getOrderById(order);
          console.log('getMapDataByOrder START - Order ID: ', orderData.firebase_order_id);

          if (orderData.references && orderData.references.map_id) {
            // Get Map object by Firebase order ID from DB
            const mapData = await this.getMapById(orderData.references.map_id.split('maps/')[1]);
            console.log('mapData: ', mapData);

            const savedMapObject = await this.getSavedMapObj(mapData);
            console.log('savedMapObject: ', savedMapObject);

            const zipCodeList = this.getZipCodeList(savedMapObject);

            csvData = csvData.concat(this.getCsvData(savedMapObject, zipCodeList, orderData));
          }
        })
      )
        .then(result => {
          console.log('Success: ', result);
          this.generateCsvReport(csvData);
        })
        .catch(error => {
          console.log('getLDPData failed: ', error);
        })
        .finally(() => {
          console.log('CSV DATA: ', csvData);
          this.$store.dispatch('hideLoader');
        });
    },
    async getOrderById(orderId) {
      try {
        return orderService.getById(orderId);
      } catch (error) {
        console.log('getOrderById has failed: ', error);
      }
    },
    async getCustomerDataByOrderId(orderId) {
      console.log('orderID: ', orderId);
      try {
        return (await orderService.getOrderData(orderId))?.customer;
      } catch (error) {
        console.log('getCustomerDataByOrderId has failed: ', error);
        return '';
      }
    },
    async getMapById(mapId) {
      const mapObject = await dbFS.collection('maps').doc(mapId).get();
      return Promise.resolve(mapObject.data());
    },
    getSavedMapObj(mapData) {
      return this.$store
        .dispatch('maps/getZipList', {
          downloadUrl: mapData.uploadedFileUrl,
          fileParsing: false,
          isUrlFromAmazon: false
        })
        .then(response => {
          console.log('Response: ', response);
          if (!response || !response.data) {
            return null;
          }

          return this.getMapStateObj(response.data);
        });
    },
    getMapStateObj(mapItem) {
      return mapItem.jsonContent
        ? mapItem.jsonContent.map_data?.map_state
          ? {
              mapState: mapItem.jsonContent.map_data.map_state,
              stateHash: mapItem.jsonContent.map_data?.map_state_hash || ''
            }
          : mapItem.jsonContent.map_state
          ? {
              mapState: mapItem.jsonContent.map_state,
              stateHash: mapItem.jsonContent.map_state_hash || ''
            }
          : null
        : mapItem.map_data?.map_state || null;
    },
    getZipCodeList(savedMapObject) {
      let zipCode = '';
      let zipCodeList = {};

      if (savedMapObject.atzs || savedMapObject.routes) {
        const atzs = savedMapObject.atzs ?? [];
        const items = savedMapObject.routes ? atzs.concat(savedMapObject.routes) : atzs;
        const isMoversSelected = this.orderTypeFilters[this.selectedOrderType] === 'newmovers';

        items.forEach(item => {
          if (item.selected) {
            let splittedAddress = '';

            const geoCode = item.geocode ? item.geocode.match(/[A-Z]/i) : '';
            zipCode = geoCode?.index ? item.geocode.substr(0, geoCode.index) : item.geocode;

            console.log('ZIPCODE: ', zipCode);

            if (zipCode) {
              if (!zipCodeList[zipCode]) {
                zipCodeList[zipCode] = {
                  qty: isMoversSelected ? item.mover_tot : item.residential,
                  state: item.state,
                  zipcrid: item.zipcrid || item['ZIPCRID']
                };
              } else {
                zipCodeList[zipCode].qty += isMoversSelected ? item.mover_tot : item.residential;
                zipCodeList[zipCode].state = item.state;
                zipCodeList[zipCode].zipcrid = item.zipcrid || item['ZIPCRID'];
              }
            }
          }
        });
      } else if (savedMapObject.atzRoutes) {
        savedMapObject.atzRoutes.forEach(route => {
          if (!zipCodeList[route['ZIP']]) {
            zipCodeList[route['ZIP']] = {
              qty: route['TOTRESCNT'],
              state: route['STATE'],
              zipcrid: route.zipcrid || route['ZIPCRID']
            };
          } else {
            zipCodeList[route['ZIP']].qty += route['TOTRESCNT'];
            zipCodeList[route['ZIP']].state = route['STATE'];
            zipCodeList[route['ZIP']].zipcrid = route.zipcrid || route['ZIPCRID'];
          }
        });
      }
      return zipCodeList;
    },
    getCsvData(savedMapObject, zipCodeList, orderData) {
      const csvData = [];
      let orderItem = '';

      for (const [index, zipCode] of Object.entries(zipCodeList)) {
        // TODO: enable to set the data what will be included;
        orderItem = {
          'Osprey Order ID': orderData.crm_order_id,
          'Order Type': orderData.order_type,
          'Order Date': orderData.creation_date.toDate(),
          'Delivery Date': savedMapObject.week ? savedMapObject.week : '-',
          State: zipCode.state,
          Quantity: zipCode.qty,
          ZipCode: index,
          'Carrier Route': zipCode.zipcrid ? zipCode.zipcrid : '-'
        };

        if (savedMapObject.week) {
          orderItem['Delivery Date'] = savedMapObject.week;
        }
        if (zipCode.zipcrid) {
          orderItem['Carrier Route'] = zipCode.zipcrid;
        }

        csvData.push(orderItem);
      }
      return csvData;
    },
    setFeedback(type, message) {
      this.feedbackType = type;
      this.feedbackMessage = message;
      this.feedbackAlert = true;
      setTimeout(() => {
        this.feedbackAlert = false;
      }, 5000);
    },
    async generateCsvReport(csvData) {
      const csv = await this.$store.dispatch('tools/getCsvFromJSON', csvData);
      this.$store.dispatch('tools/downloadCsvFile', {
        csv,
        fileName: `Order_Report_${this.startDate}_${this.endDate}_${this.utils.getUnix(this.$moment())}`
      });
    },
    capitalize(text) {
      return capitalize(text);
    }
  }
};
</script>
