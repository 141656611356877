<template>
  <v-container fluid transition="fade-transition">
    <confirmation-dialog
      :isConfirmationDialogOpened="isConfirmationDialogOpened"
      :action="confirmationDialogAction"
      @closeConfirmationDialog="closeConfirmationDialog"
      @actionConfirmed="duplicateConfirmed"
    >
    </confirmation-dialog>

    <confirm-delete-dialog :isDialogOpened="isDeleteDialogOpened" :itemNames="selectedProductCategory.name" @confirmDelete="deleteConfirmed" @closeDialog="closeDeleteDialog">
    </confirm-delete-dialog>

    <v-toolbar flat class="site-table--header site-table--lists">
      <v-row class="mt-n3 mb-0">
        <v-toolbar-title class="headline grey--text text--darken-3 ml-n3 pt-1">Product Categories</v-toolbar-title>
        <v-spacer />
        <v-col cols="12" sm="5" md="4" lg="4" xl="3" class="text-center">
          <v-text-field v-model="search" label="Search" class="mt-n3" append-icon="mdi-magnify" outlined dense hide-details></v-text-field>
        </v-col>
        <v-btn color="blue" class="white--text ml-2 mr-n1" min-width="200" @click="addNewProductCategory()"><v-icon left>mdi-plus-circle</v-icon>Add New Product Category</v-btn>
      </v-row>
    </v-toolbar>
    <div class="site-table mt-n5 ml-n1 mr-0">
      <stamp-data-table
        :headers="headers"
        :data="product_categories"
        :actions="actions"
        :search="search"
        :is-loading="isProductCategoriesTableLoading"
        :is-items-per-page-disabled="true"
        item-key="product_category_firebase_id"
        @viewProductCategory="viewProductCategory"
        @editProductCategory="editProductCategory"
        @duplicateProductCategory="duplicateProductCategory"
        @deleteProductCategory="deleteProductCategory"
      >
        <template #[`item.graphic`]="{ item }">
          <v-img max-width="35" :src="item.image"></v-img>
        </template>
        <template #[`item.product_category_firebase_id`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon color="amber darken-3" v-on="on" @click="copyItemToClipboard(item.product_category_firebase_id)">mdi-firebase</v-icon>
            </template>
            <span>Copy FirebaseID ( {{ item.product_category_firebase_id }} ) to clipboard</span>
          </v-tooltip>
        </template>
      </stamp-data-table>
    </div>
  </v-container>
</template>

<script>
import ConfirmDeleteDialog from '../../dialog/ConfirmDeleteDialog';
import ConfirmationDialog from '../../order-management/orders/orderDialogs/ConfirmationDialog';
import { mapGetters } from 'vuex';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: {
    'confirm-delete-dialog': ConfirmDeleteDialog,
    'confirmation-dialog': ConfirmationDialog,
    'stamp-data-table': DataTable
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Graphic', value: 'graphic', sortable: false },
        { text: 'Firebase ID', value: 'product_category_firebase_id', align: 'center', sortable: false }
      ],
      actions: [
        { icon: 'mdi-eye', iconColor: 'blue darken-1', title: 'View Product Category', eventName: 'viewProductCategory' },
        { icon: 'mdi-pencil', iconColor: 'green darken-2', title: 'Edit Product Category', eventName: 'editProductCategory' },
        { icon: 'mdi-plus-box-multiple-outline', iconColor: 'grey darken-1', title: 'Duplicate Product Category', eventName: 'duplicateProductCategory' }
      ],
      isDeleteDialogOpened: false,
      selectedProductCategory: {},
      isConfirmationDialogOpened: false,
      confirmationDialogAction: ''
    };
  },
  computed: {
    ...mapGetters('product_categories', {
      product_categories: 'getProductCategories',
      isProductCategoriesTableLoading: 'getProductCategoriesLoadingState'
    }),
    ...mapGetters('users', {
      userAccessLevel: 'userAccessLevel'
    }),
    isUserSuperAdmin() {
      return this.userAccessLevel && parseInt(this.userAccessLevel.value) === 100;
    }
  },
  created() {
    this.$store.dispatch('product_categories/getAllProductCategories');
  },
  methods: {
    addNewProductCategory() {
      this.$router.replace('/product-categories/new');
    },
    editProductCategory(product_category) {
      this.$router.replace(`/product-categories/edit/${product_category.product_category_firebase_id}`);
    },
    viewProductCategory(product_category) {
      this.$router.replace(`/product-categories/view/${product_category.product_category_firebase_id}`);
    },
    duplicateProductCategory(product_category) {
      this.confirmationDialogAction = `duplicate product category '${product_category.name}'`;
      this.selectedProductCategory.data = product_category;
      this.isConfirmationDialogOpened = true;
    },
    async duplicateConfirmed() {
      this.$store.dispatch('showLoader', { message: `Duplicating product category ${this.selectedProductCategory.data.name} in progress...` });
      try {
        this.selectedProductCategory.data.name += ' Duplicated';
        const { product_category_firebase_id, ...categoryData } = this.selectedProductCategory.data;
        const productCategoryDuplicate = { data: categoryData };
        const result = await this.$store.dispatch('product_categories/addNewProductCategory', productCategoryDuplicate);
        this.setSnackbar('success', `Product Category saved with id: ${result}`);
      } catch (error) {
        this.showAlert('error', error.message || error);
      } finally {
        this.selectedProductCategory = {};
        this.closeConfirmationDialog();
        this.$store.dispatch('hideLoader');
      }
    },
    deleteProductCategory(product_category) {
      this.selectedProductCategory = product_category;
      this.isDeleteDialogOpened = true;
    },
    async deleteConfirmed() {
      this.$store.dispatch('showLoader', { message: `Deleting product category in progress...` });
      try {
        await this.$store.dispatch('product_categories/deleteProductCategory', { product_category_firebase_id: this.selectedProductCategory.product_category_firebase_id });
        this.setSnackbar('success', `Product Category [ ${this.selectedProductCategory.product_category_firebase_id} ] has been deleted successfully!`);
      } catch (error) {
        console.error('[deleteProductCategory error]', error);
        this.showAlert('error', error.message || error || 'Could not delete product category!');
      } finally {
        this.closeDeleteDialog();
        this.selectedProductCategory = {};
        this.$store.dispatch('hideLoader');
      }
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpened = false;
    },
    closeConfirmationDialog() {
      this.isConfirmationDialogOpened = false;
      this.confirmationDialogAction = '';
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    }
  }
};
</script>
