<template>
  <v-row v-if="isComponentVisible">
    <v-col cols="12">
      <v-alert :type="alertType" :color="alertColor" prominent dense elevation="2" class="subtitle-1">
        <v-row class="mx-1 pt-1">
          <span v-if="!isOrderUpdatedSuccessfully">{{ mainWarningMessage }}</span>
          <span v-else>{{ customSuccessMessage ? customSuccessMessage : 'Changes saved successfully.' }} Do you want to reactivate the campaign now?</span>
          <v-spacer />
          <v-btn
            v-if="campaignEditNotification.isActionButtonVisible"
            :disabled="isChangeSubscriptionLoading"
            small
            color="white"
            elevation="2"
            class="blue-grey--text text--darken-3"
            @click="changeSubscriptionStatus(subscriptionEndpoint)"
            >{{ pauseOrActivate }} Campaign</v-btn
          >
          <v-btn v-if="isOrderUpdatedSuccessfully" outlined small color="white" class="ml-3" @click="closeAlert()">Not Now</v-btn>
        </v-row>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['isVisible', 'firebaseOrderId', 'isOrderUpdatedSuccessfully', 'customSuccessMessage'],
  data() {
    return {
      isChangeSubscriptionLoading: false
    };
  },
  computed: {
    isComponentVisible() {
      return this.isVisible || this.isOrderUpdatedSuccessfully;
    },
    ...mapGetters('om_orders', {
      campaignEditNotification: 'getCampaignEditNotification'
    }),
    mainWarningMessage() {
      return this.campaignEditNotification.notificationMessage;
    },
    alertType() {
      return !this.isOrderUpdatedSuccessfully ? 'warning' : 'success';
    },
    alertColor() {
      return !this.isOrderUpdatedSuccessfully ? 'orange darken-2' : 'green darken-1';
    },
    subscriptionEndpoint() {
      return !this.isOrderUpdatedSuccessfully ? 'pause' : 'reactivate';
    },
    pauseOrActivate() {
      return !this.isOrderUpdatedSuccessfully ? 'Pause' : 'Reactivate';
    }
  },
  methods: {
    changeSubscriptionStatus(endpoint) {
      this.$store.dispatch('showLoader', { message: 'Updating subscription status...' });
      this.isChangeSubscriptionLoading = true;
      this.$store
        .dispatch('stripe/changeSubscriptionStatus', {
          endpoint,
          firebase_order_id: this.firebaseOrderId
        })
        .then(() => {
          this.$emit('subscriptionStatus', { status: 'success', message: 'Subscription status updated successfully' });
        })
        .catch(error => this.$emit('subscriptionStatus', { status: 'error', message: error.response.data?.message || error.response.data }))
        .finally(() => {
          this.isChangeSubscriptionLoading = false;
          this.$store.dispatch('hideLoader');
        });
    },
    closeAlert() {
      this.$emit('subscriptionStatus');
    }
  }
};
</script>
