import { dbFS } from '../../services/firebaseInit.js';
import { cloudFunctionsGet } from '../../modules/cloud-function-wrapper.js'

const orderStore = {
  state: {
    ospreyOrder: null,
    savedOrder: null
  },
  getters: {
    getOspreyOrder(state) {
      return state.ospreyOrder;
    }
  },
  mutations: {
    setOspreyOrder(state, order) {
      state.ospreyOrder = order;
    },
    setSavedOrder: function(state, order) {
      state.savedOrder = order;
    }
  },
  actions: {
    async getOspreyOrder({ commit }, payload) {
      try {
        commit('setOspreyOrder', null);
        const response = await cloudFunctionsGet(payload.url);
        if (response.data) {
          console.log('ORDER: ', response.data);
          commit('setOspreyOrder', response.data || null);
          return null;
        }
      } catch (event) {
        return event?.response.data ? event.response.data : { error: 'Unexpected error while getting Osprey order!' };
      }
    },
    updateOpportunityId: function(context, payload) {
      payload.order.references.salesforce_opportunity_id = payload.opportunityId;
      payload.order.references.salesforce_contact_id = payload.contactId;
      payload.order.references.salesforce_user_id = payload.userId;
      payload.order.references.salesforce_account_id = payload.accountId;
      payload.order.references.salesforce_contact_email = payload.contact_email;
      payload.order.references.salesforce_web_id = payload.web_id;
      const promises = [];
      if (payload.line_item_result !== '') {
        const itemResult = {};
        for (let i in payload.line_item_result.created) {
          itemResult[i] = {
            id: payload.line_item_result.created[i].id
          };
        }
        payload.order.references.salesforce_line_item_results = itemResult;
        promises.push(
          dbFS
            .collection('orders')
            .doc(`${payload.order.firebase_order_id}`)
            .update({
              'references.salesforce_line_item_results': itemResult
            })
        );
      }
      promises.push(context.commit('setSavedOrder', payload.order));
      return Promise.all(promises)
        .then(() => {
          return dbFS
            .collection('orders')
            .doc(`${payload.order.firebase_order_id}`)
            .update({
              'references.salesforce_opportunity_id': payload.opportunityId,
              'references.salesforce_contact_id': payload.contactId,
              'references.salesforce_user_id': payload.userId,
              'references.salesforce_account_id': payload.accountId,
              'references.salesforce_contact_email': payload.contact_email,
              'references.salesforce_web_id': payload.web_id
            });
        })
        .catch(error => {
          return context
            .dispatch('checkToken', error)
            .then(() => {
              return context.dispatch('updateOpportunityId', payload);
            })
            .catch(() => {
              return Promise.reject(error);
            });
        });
    },
    getOrderByOspreyID({ commit }, payload) {
      return dbFS
        .collection('orders')
        .where('crm_order_id', 'in', [payload.ospreyOrderID.toString(), parseInt(payload.ospreyOrderID)])
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            return Promise.reject(`Order not found with Osprey Order ID: ${payload.ospreyOrderID}`);
          } else {
            let order = snapshot.docs[0].data();
            order.id = snapshot.docs[0].id;
            return Promise.resolve(order);
          }
        })
        .catch(error => {
          console.log('Error in getting Order by Osprey Order ID:', error);
          return Promise.reject(error);
        });
    },
    getOrderDataItemByOrderId({ commit }, payload) {
      return dbFS
        .collection('orders')
        .doc(payload.firebase_order_id)
        .collection('order_data')
        .doc(payload.order_data_item)
        .get()
        .then(snapshot => (snapshot.empty ? Promise.reject(`Order Data Item by Order Id is not found: ${payload.firebase_order_id}`) : Promise.resolve(snapshot.data())))
        .catch(error => {
          console.log('[ getOrderDataItemByOrderId ] Error:', error);
          return Promise.reject(error);
        });
    },
    getOrderByFirebaseId({ commit }, payload) {
      return dbFS
        .collection('orders')
        .doc(payload.id)
        .get()
        .then(querySnapshot => (querySnapshot.empty ? Promise.reject(`Order not found: ${payload.id}`) : Promise.resolve(querySnapshot.data())))
        .catch(error => {
          console.log('[ getOrderByFirebaseId ] Error:', error);
          return Promise.reject(error);
        });
    }
  }
};

export { orderStore as default };
