<template>
  <div>
    <v-row class="px-8 pt-8">
      <v-data-table id="base-pricing-table" :headers="headers" :items="tiers" hide-default-footer disable-sort disable-pagination class="elevation-2 table-bordered">
        <template #[`header.flat_fee`]="{ header }">
          <v-checkbox v-model="isFlatFeeBatchEditOn" dense :label="header.text" class="mb-n2" :disabled="!isEditable">
            <template #label>
              <span class="text-subtitle-2">{{ header.text }} (opt.)</span>
              <BatchEditDialog v-model="isFlatFeeBatchEditOn" :field-name="header.text" @batchEdit="updateColumn($event, header.value)" />
            </template>
          </v-checkbox>
        </template>
        <template v-for="(header, index) in headers" #[`item.${header.value}`]="{ item }">
          <v-edit-dialog v-if="isFieldEditable(header.value)" :key="index" :return-value.sync="item[header.value]" large save-text="Apply" @save="updateTierLimits(header.value)">
            <span
              ><v-icon v-if="isDollarAmount(header.value)" class="ml-n2" dense>mdi-currency-usd</v-icon
              >{{ isDollarAmount(header.value) ? formatValue(item[header.value]) : item[header.value] }}</span
            >
            <template #input>
              <v-text-field
                v-model="item[header.value]"
                type="number"
                class="mt-4 mb-n4"
                :min="header.value === 'up_to' ? item.from : 0"
                :error-messages="header.value === 'up_to' && item.up_to <= item.from && !isCustomProduct ? 'Last Unit must be higher than First Unit!' : ''"
                :prepend-inner-icon="isDollarAmount(header.value) ? 'mdi-currency-usd' : ''"
                outlined
                dense
                @keydown.enter="handleEnterKeydown($event)"
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <span v-if="!isFieldEditable(header.value) && header.value !== 'name'" :key="index" class="text-body-2 grey--text text--lighten-1">{{ item[header.value] }}</span>
          <span v-if="header.value === 'name'" :key="index" class="text-subtitle-2">{{ item[header.value] }}</span>
        </template>

        <template #[`item.remove`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn v-if="isTierRemovable(item)" icon :disabled="!isEditable" v-on="on" @click="removeTier(item)"
                ><v-icon color="grey lighten-1">mdi-delete-outline</v-icon></v-btn
              >
            </template>
            <span>Remove Tier</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-row>

    <v-row class="px-8 py-4 d-flex">
      <v-tooltip v-if="isTierValuesEmpty" bottom>
        <template #activator="{ on }">
          <v-btn class="primary--text text-none not-allowed" text v-on="on"><v-icon left>mdi-plus</v-icon>Add Pricing Tier</v-btn>
        </template>
        <span v-if="isTierValuesEmpty">Please fill out the empty lines, before adding new pricing tier</span>
      </v-tooltip>
      <v-btn v-else class="primary--text text-none" text :disabled="!isEditable || isCustomProduct" @click="addPricingTier"><v-icon left>mdi-plus</v-icon>Add Pricing Tier</v-btn>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn class="primary--text text-none" text v-on="on"><v-icon left>mdi-eye</v-icon>Preview Price Calculation</v-btn>
        </template>
        <span>{{ totalPricePreview }}</span>
      </v-tooltip>
    </v-row>
    <v-row class="px-8">
      <span v-if="isEditable" class="text-caption grey--text text--darken-1">*Note: By Checking the checkbox on the top of a column you enable batch editing mode. </span>
    </v-row>
  </div>
</template>
<script>
import BatchEditDialog from './BatchEditDialog.vue';

export default {
  components: {
    BatchEditDialog
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      headers: [
        { text: '', value: 'name', class: 'grey lighten-5' },
        { text: 'First Unit', value: 'from', class: 'text-subtitle-2 grey lighten-5' },
        { text: 'Last Unit', value: 'up_to', class: 'text-subtitle-2 grey lighten-5' },
        { text: 'Per Unit', value: 'price_per_piece', class: 'text-subtitle-2 grey lighten-5' },
        { text: 'Flat Fee', value: 'flat_fee', class: 'text-subtitle-2 grey lighten-5' },
        { text: 'Total Weight (LBS)', value: 'total_weight', class: 'text-subtitle-2 grey lighten-5' },
        { text: '', value: 'remove', width: '68px', class: 'text-subtitle-2 grey lighten-5' }
      ],
      tiers: this.value,
      isFlatFeeBatchEditOn: false,
      siteId: 'default',
      customProductTypeId: 'rjLHZK3hUyCgoMJCKfOb'
    };
  },
  computed: {
    isEditable() {
      return this.$route?.name !== 'ViewProduct';
    },
    totalPricePreview() {
      return this.tiers[0]?.up_to && this.tiers[0]?.price_per_piece !== ''
        ? `The price was calculated ${this.tiers[0].up_to} x US$${this.tiers[0].price_per_piece} + US$${this.tiers[0].flat_fee || 0} = US$${(
            parseFloat(this.tiers[0].up_to) * parseFloat(this.tiers[0].price_per_piece) +
            parseFloat(this.tiers[0].flat_fee || 0)
          ).toFixed(2)}`
        : 'The price will be calculated [last unit] x US$[per unit] + US$[flat fee] = US$[price]';
    },
    isTierValuesEmpty() {
      return this.tiers.some(({ from, up_to, price_per_piece }) => !from || !up_to || price_per_piece === '');
    },
    productFirebaseId() {
      return Object.values(this.$route.params)[0];
    },
    isCustomProduct() {
      return this.product.type.id === this.customProductTypeId;
    }
  },
  watch: {
    tiers: {
      handler(value) {
        if (!this.isCustomProduct) {
          this.updateApplyButtonsState();
        }
        this.$emit('input', value);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    isFieldEditable(fieldName) {
      return this.isEditable && ['up_to', 'price_per_piece', 'flat_fee'].includes(fieldName);
    },
    isDollarAmount(fieldName) {
      return ['price_per_piece', 'flat_fee'].includes(fieldName);
    },
    formatValue(value) {
      //value !== '' is necessary so it doesn't show empty values as included
      if (Number(value) === 0 && value !== '') {
        return 'Included';
      }
      if (Number(value) === -1) {
        return 'Not Available';
      }
      return value;
    },
    isTierRemovable(tier) {
      const currentIndex = this.tiers.findIndex(({ from }) => from === tier.from);
      return currentIndex;
    },
    removeTier(tier) {
      const currentIndex = this.tiers.findIndex(({ from }) => from === tier.from);
      this.tiers.splice(currentIndex, 1);
    },
    addPricingTier() {
      const [previousTier] = this.tiers.slice(-1);
      const previousFrom = Number(previousTier.from);
      const previousUpTo = Number(previousTier.up_to);
      this.tiers.push({
        name: 'Total Units',
        from: previousUpTo + 1,
        up_to: 2 * previousUpTo - previousFrom + 1,
        price_per_piece: Number(previousTier.price_per_piece),
        flat_fee: previousTier.flat_fee,
        total_weight: this.getTotalWeight(2 * previousUpTo - previousFrom + 1)
      });
    },
    updateTierLimits(fieldName) {
      if (fieldName !== 'up_to') {
        return;
      }
      for (let i = 0; i < this.tiers.length; i++) {
        let previousLimit = Number(this.tiers[i - 1]?.up_to || 0);
        let currentLimit = Number(this.tiers[i].up_to);
        let step = currentLimit > previousLimit ? currentLimit - previousLimit - 1 : previousLimit - 1;
        this.$set(this.tiers[i], 'from', previousLimit + 1);
        this.$set(this.tiers[i], 'up_to', Number(this.tiers[i]?.from) + step);
        this.$set(this.tiers[i], 'total_weight', this.getTotalWeight(Number(this.tiers[i]?.from) + step));
      }

      this.$nextTick(() => {
        if (!this.isCustomProduct) {
          this.updateApplyButtonsState();
        }
      });
    },
    updateColumn(value, column) {
      this.tiers.forEach(tier => {
        this.$set(tier, column, value);
      });
    },
    getTotalWeight(lastUnit) {
      return ((parseFloat(this.product.size_x) * parseFloat(this.product.size_y) * parseFloat(this.product.material_weight) * parseFloat(lastUnit)) / 16).toFixed(2);
    },
    updateApplyButtonsState() {
      //With Vuetify 2, the Apply button of the v-edit-dialog component can not be set to disabled (neither with props, nor with slots/templates)
      //This caused an issue when the user entered a value for the "Last unit" which was smaller than the "First unit", and the Apply button was not disabled
      //Additionally, the Vuetify 2 components will not disappear from the DOM after they got mounted -> that is why I used querySelectorAll + forEach loop
      this.$nextTick(() => {
        const applyButtons = document.querySelectorAll('.v-small-dialog__actions button:nth-child(2)');
        applyButtons.forEach(button => {
          const isDisabled = this.tiers.some(tier => tier.up_to <= tier.from);
          button.setAttribute('data-disabled', isDisabled.toString());
          button.disabled = isDisabled;
        });
      });
    },
    handleEnterKeydown(event) {
      if (this.tiers.some(tier => tier.up_to <= tier.from) && !this.isCustomProduct) {
        event.stopPropagation();
      }
    }
  }
};
</script>
<style>
/* make table cells bordered */
#base-pricing-table td,
#base-pricing-table th {
  border-right: thin solid rgb(0 0 0 / 12%) !important;
  border-bottom: thin solid rgb(0 0 0 / 12%) !important;
}

/* imitate header style*/
#base-pricing-table td:first-child {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.6);
}
</style>
