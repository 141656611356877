<template>
  <div>
    <stamp-new-attributes v-model="addNewAttributesDialog" @closeAddNewAttributesDialog="closeAddNewAttributesDialog" @addAttribute="addAttribute"> </stamp-new-attributes>
    <stamp-delete-attributes v-model="deleteAttributesDialog" @closeDeleteAttributesDialog="closeDeleteAttributesDialog" @deleteAttribute="deleteAttribute">
    </stamp-delete-attributes>
    <!-- @updateAttributesDialog="updateAttributesDialog" -->
    <stamp-update-attributes v-model="updateAttributesDialog" @closeUpdateAttributesDialog="closeUpdateAttributesDialog"> </stamp-update-attributes>

    <stamp-update-order-proc-sw v-model="updateOrderProcessSwDialog" @closeOrderProcessSwDialog="closeOrderProcessSwDialog"> </stamp-update-order-proc-sw>

    <stamp-update-fault-order-proc-sw v-model="updateOrderProcessSwDialogFault" @closeOrderProcessSwDialogFault="closeOrderProcessSwDialogFault">
    </stamp-update-fault-order-proc-sw>

    <stamp-update-options v-model="updateOrderProcessOptions" @closeOrderProcessOptions="closeOrderProcessOptions"> </stamp-update-options>

    <stamp-update-all-sites-custom v-model="updateAllSettingsDialog" @closeUpdateAllSettings="closeUpdateAllSettings"></stamp-update-all-sites-custom>

    <v-row>
      <v-col cols="12" sm="10">
        <v-btn icon class="px-3 pb-2 pt-1" @click.native="back()">
          <v-icon color="red">mdi-arrow-left</v-icon>
        </v-btn>
        <span class="headline pt-1">Sites Tools</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row align="center" justify="start">
      <v-col cols="12" sm="10">
        <v-btn class="my-3 white--text" color="blue-grey" @click="updateAllOrderProcesses()"><v-icon color="white" class="mr-2">mdi-wizard-hat</v-icon>Step Updater Tool</v-btn>
        <v-divider class="my-3"></v-divider>
        <h3 class="mb-3">Add new site attributes</h3>
        <v-btn v-if="isUserAccessLevelAdmin" color="ml-0 mb-1 grey lighten-2" @click="onAddNewSiteAttr()"> New Site attribute </v-btn>
        <v-divider class="my-3"></v-divider>
        <h3 class="mb-3">Delete site attributes</h3>
        <v-btn v-if="isUserAccessLevelAdmin" color="ml-0 mb-1 grey lighten-2" @click="deleteSiteAttr()"> Delete Site attribute </v-btn>
        <v-divider class="my-3"></v-divider>
        <h3 class="mb-3">Update site attributes</h3>
        <v-btn v-if="isUserAccessLevelAdmin" color="ml-0 mb-1 grey lighten-2" @click="updateSiteAttr()"> Update Site attribute </v-btn>
        <v-divider class="my-3"></v-divider>
        <h3 class="mb-3">Update Order Process Switchs</h3>
        <v-btn v-if="isUserAccessLevelAdmin" color="ml-0 mb-1 grey lighten-2" @click="updateOrderProcessSwitches()"> Update Order Processes attributes </v-btn>
        <v-divider class="my-3"></v-divider>
        <h3 class="mb-3">Update Order Process Switchs</h3>
        <v-btn v-if="isUserAccessLevelAdmin" color="ml-0 mb-1 grey lighten-2" @click="FaultupdateOrderProcessSwitches()"> -FAULT- Update Order Processes attributes </v-btn>
        <v-divider class="my-3"></v-divider>
        <h3 class="mb-3">Update Order Process Orders</h3>
        <v-btn v-if="isUserAccessLevelAdmin" color="ml-0 mb-1 grey lighten-2" @click="updateOrderProcessOptionsAction()"> Update options </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import siteSettingsService from '../../services/siteSettingsService.js';
import NewSiteAttributes from './NewSiteAttributes.vue';
import DeleteSiteAttributes from './dialogs/DeleteSiteAttributes.vue';
import UpdateSiteAttributes from './dialogs/UpdateSiteAttributes.vue';
import UpdateOrderProcessSwitches from './dialogs/UpdateOrderProcessSwitches.vue';
import FaultUpdateOrderProcessSwitches from './dialogs/FaultUpdateOrderProcessSwitches.vue';
import UpdateOptions from './dialogs/UpdateOptions.vue';
import UpdateAllSitesCustom from './dialogs/UpdateAllSitesCustom.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    // 'amp-site': Site,
    'stamp-new-attributes': NewSiteAttributes,
    'stamp-delete-attributes': DeleteSiteAttributes,
    'stamp-update-attributes': UpdateSiteAttributes,
    'stamp-update-order-proc-sw': UpdateOrderProcessSwitches,
    'stamp-update-fault-order-proc-sw': FaultUpdateOrderProcessSwitches,
    'stamp-update-options': UpdateOptions,
    'stamp-update-all-sites-custom': UpdateAllSitesCustom
  },
  data() {
    return {
      addNewAttributesDialog: false,
      deleteAttributesDialog: false,
      updateAttributesDialog: false,
      updateOrderProcessSwDialog: false,
      updateOrderProcessSwDialogFault: false,
      updateOrderProcessOptions: false,
      updateAllSettingsDialog: false
    };
  },
  computed: {
    ...mapGetters('users', {
      userStore: 'user',
      userAccessLevel: 'userAccessLevel'
    }),
    isUserAccessLevelAdmin() {
      return this.userAccessLevel?.value === '100';
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    onAddNewSiteAttr() {
      this.addNewAttributesDialog = true;
    },
    closeAddNewAttributesDialog() {
      this.addNewAttributesDialog = false;
    },
    closeDeleteAttributesDialog() {
      this.deleteAttributesDialog = false;
    },
    deleteSiteAttr() {
      this.deleteAttributesDialog = true;
    },
    deleteAttribute(attrObj) {
      console.log('Deleting object attribute: ', attrObj);
      const attrName = attrObj.attrName;
      attrObj.attrList.forEach((value, key) => {
        let siteId = value.firebaseId;
        siteSettingsService.set(siteId, { [attrName]: '' }).then(() => {
          console.log('Done for: ', siteId);
        });
      });
    },
    updateSiteAttr() {
      this.updateAttributesDialog = true;
    },
    closeUpdateAttributesDialog() {
      this.updateAttributesDialog = false;
    },
    addAttribute(attrObj) {
      const { attrName, attrValue } = attrObj;
      console.log('attrName:', attrName);
      console.log('attrValue:', attrValue);
      siteSettingsService.getAll({ isArray: true }).then(sites => {
        const promises = [];
        sites.forEach(site => {
          const promise = siteSettingsService.set(site.firebase_id, { [attrName]: attrValue }).then(() => {
            console.log('Done for: ', site.id);
          });
          promises.push(promise);
        });
        Promise.all(promises).then(() => {
          console.log('All sites updated!');
          this.addNewAttributesDialog = false;
        });
      });
    },
    closeOrderProcessSwDialog() {
      this.updateOrderProcessSwDialog = false;
    },
    updateOrderProcessSwitches() {
      this.updateOrderProcessSwDialog = true;
    },
    closeOrderProcessSwDialogFault() {
      this.updateOrderProcessSwDialogFault = false;
    },
    FaultupdateOrderProcessSwitches() {
      this.updateOrderProcessSwDialogFault = true;
    },
    updateOrderProcessOptionsAction() {
      this.updateOrderProcessOptions = true;
    },
    closeOrderProcessOptions() {
      this.updateOrderProcessOptions = false;
    },
    updateAllOrderProcesses() {
      this.updateAllSettingsDialog = true;
    },
    closeUpdateAllSettings() {
      this.updateAllSettingsDialog = false;
    }
  }
};
</script>
<style scoped></style>
