<template>
  <v-container fluid>
    <cm-loading-dialog :Dialog="isOrderLoading"></cm-loading-dialog>

    <charge-refund-dialog
      v-if="chargeModal"
      :modal="chargeModal"
      :orderData="fullOrderObject"
      :customerFbId="customerFirebaseId"
      :computedAmount="isOneTimePaymentFailed ? oneTimeAmount : calculatedAmount"
      :ospreyBalance="ospreyOrderTotal"
      @closeChargeRefundDialog="closeChargeModal"
    >
    </charge-refund-dialog>

    <subscription-dialog
      v-if="subscriptionModal"
      :subsModal="subscriptionModal"
      :orderData="fullOrderObject"
      :customerFbId="customerFirebaseId"
      :subscription="subscriptionData"
      :isCardChangeNeeded="isChangePaymentMethodOpened"
      @closeSubscriptionDialog="closeSubscriptionDialog"
    >
    </subscription-dialog>

    <usage-record-dialog v-if="showUsageRecordDialog" :order="fullOrderObject" :isOpen="showUsageRecordDialog" @closeUsageRecordDialog="closeUsageRecordDialog">
    </usage-record-dialog>

    <edit-customer-name
      :isDialogOpened="isCustomerNameEditOpened"
      :customerFirebaseId="customerFirebaseId"
      :firebaseOrderId="firebaseOrderId"
      @customerUpdate="updateCustomerName"
      @updateFeedback="componentFeedback"
      @closeEditCustomerNameDialog="closeCustomerNameEdit()"
    >
    </edit-customer-name>

    <confirmation-dialog
      :isConfirmationDialogOpened="isConfirmationDialogOpened"
      :action="confirmationDialogAction"
      @closeConfirmationDialog="closeConfirmationDialog"
      @actionConfirmed="actionConfirmed"
    >
    </confirmation-dialog>

    <confirmation-dialog
      :isConfirmationDialogOpened="isConfirmRetryChargeShown"
      :action="confirmRetryChargeMessage"
      @closeConfirmationDialog="cancelRetryConfirmation"
      @actionConfirmed="confirmRetry"
    >
    </confirmation-dialog>

    <special-instruction-dialog
      :isOpen="isSpecialInstructionDialogOpened"
      :specialInstructions="fullOrderObject.special_instructions"
      @closeSpecialInstructionDialog="closeSpecialInstructionDialog"
    >
    </special-instruction-dialog>

    <v-row class="mb-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="navigateBackToOrders()" v-on="on"><v-icon color="dark-grey">mdi-arrow-left</v-icon></v-btn>
        </template>
        <span>Back</span>
      </v-tooltip>
      <span class="title font-weight-medium grey--text text--darken-2 ml-2">Orders</span>
      <v-icon class="mx-2">mdi-chevron-right</v-icon>
      <v-img src="@/assets/logos/ospreyLogo.png" max-width="22" height="30"></v-img>
      <span class="title font-weight-medium grey--text text--darken-2 ml-1">Osprey Order ID: </span>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <h2 class="pl-3 title blue-grey--text text--darken-2 font-weight-medium showPointerOnHover" @click="openOrderInOsprey(fullOrderObject.crm_order_id)" v-on="on">
            {{ fullOrderObject.crm_order_id }}
          </h2>
        </template>
        <span>Open Order {{ fullOrderObject.crm_order_id }} in Osprey</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-img
            class="showPointerOnHover mr-3"
            src="@/assets/logos/ospreyLogo.png"
            max-width="22"
            height="30"
            v-on="on"
            @click="copyToClipboard(fullOrderObject.crm_order_id)"
          ></v-img>
        </template>
        <span>Copy Osprey Order ID to clipboard</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn class="pt-1" small icon v-on="on" @click="copyToClipboard(fullOrderObject.firebase_order_id)"><v-icon color="amber darken-3">mdi-firebase</v-icon></v-btn>
        </template>
        <span>Copy Firebase Order ID to clipboard</span>
      </v-tooltip>
    </v-row>
    <v-divider></v-divider>

    <v-alert v-if="infoSnackbar" :type="infoSnackbarType" prominent dense dismissible transition="scroll-y-transition" class="mt-2 mb-2" @input="closeInfoSnackbar()">{{
      infoSnackbarText
    }}</v-alert>

    <v-row class="justify-center mt-2 pl-1 mb-n1" no-gutters>
      <v-col cols="12" xl="8">
        <v-row>
          <p class="headline pt-1 my-4 ml-3">
            {{ fullOrderObject.product_name }}
          </p>
          <v-chip v-if="orderStatus === 'Incomplete'" color="red" text-color="white" class="ml-2 mt-5">{{ orderStatus }}</v-chip>
          <v-chip v-else color="green lighten-4" text-color="green darken-3" class="ml-2 mt-5">{{ orderStatus }}</v-chip>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" xl="8">
        <v-row class="justify-space-between">
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <v-card class="elevation-2" max-height="110px" min-height="110px">
              <v-row align="center" class="widget-content justify-space-between mx-1">
                <v-col cols="3" class="text-center mt-n8 ml-n3">
                  <v-icon large color="cyan darken-1">mdi-account</v-icon>
                </v-col>
                <v-col cols="9" class="mt-n2" align="center">
                  <v-row>
                    <span class="font-weight-light grey--text text--darken-1">Customer</span>
                  </v-row>
                  <v-row class="align-center mt-2">
                    <v-btn
                      v-if="!fullOrderObject.first_name && !fullOrderObject.last_name"
                      small
                      outlined
                      color="cyan darken-2"
                      class="ml-0 my-4"
                      @click="openCustomerNameEditDialog()"
                      >Set name</v-btn
                    >
                    <router-link v-else :to="`/customers/view/${customerFirebaseId}`">
                      <p class="subtitle-1 black--text">
                        <span v-if="fullOrderObject.first_name">
                          <span v-if="fullOrderObject.first_name" class="mr-1">{{ fullOrderObject.first_name }}</span>
                          <span v-else>-</span>
                        </span>
                        <span v-if="fullOrderObject.last_name">{{ fullOrderObject.last_name }}</span>
                        <v-tooltip v-if="isCustomerExists" bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                              <v-icon color="cyan darken-1">mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>View Customer</span>
                        </v-tooltip>
                      </p>
                    </router-link>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <v-card class="elevation-2" max-height="110px" min-height="110px">
              <v-row align="center" class="widget-content mx-1">
                <v-col cols="3" class="text-center mt-n6 ml-n3">
                  <v-icon large color="cyan darken-1">mdi-calendar-text</v-icon>
                </v-col>
                <v-col cols="9" class="mt-n4 align-center">
                  <v-row>
                    <span class="font-weight-light grey--text text--darken-1">Date</span>
                  </v-row>
                  <v-row class="align-center mt-2">
                    <p v-if="fullOrderObject.creation_date" class="subtitle-1 black--text pt-1">
                      {{ formatDate(fullOrderObject.creation_date.seconds) }}
                    </p>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="mt-n3">
            <v-card class="elevation-2" max-height="110px" min-height="110px">
              <v-card-text>
                <v-row class="justify-content-around">
                  <v-col cols="3" lg="2" xl="3" :class="`${isSubscriptionDetailsVisible ? `mt-3` : `mt-5`}`">
                    <v-icon large color="cyan darken-1">mdi-clipboard-text</v-icon>
                  </v-col>
                  <v-col cols="4" :class="`${isSubscriptionDetailsVisible ? `float-left` : `mt-5`}`">
                    <v-row>
                      <span class="font-weight-light grey--text text--darken-1 subtitle-1">Order Type</span>
                    </v-row>
                    <v-row class="align-center">
                      <p class="subtitle-1 black--text">
                        {{ fullOrderObject.order_type }}
                      </p>
                    </v-row>
                  </v-col>
                  <v-col v-if="isSubscriptionExists || isCorporateAutomated" cols="4" class="ml-auto mr-0 mt-3">
                    <v-btn
                      v-if="isCampaignActive"
                      :disabled="isSubscriptionLoading"
                      dark
                      color="orange darken-2"
                      min-width="100"
                      small
                      class="float-right mr-n2"
                      @click="changeSubscriptionStatus('pause')"
                    >
                      <v-icon small class="mr-2">mdi-pause</v-icon>Pause
                    </v-btn>
                    <v-btn
                      v-else-if="isCampaignPaused"
                      :disabled="isSubscriptionLoading"
                      dark
                      color="green darken-1"
                      min-width="128"
                      small
                      class="float-right mr-n2"
                      @click="changeSubscriptionStatus('reactivate')"
                    >
                      <v-icon small class="mr-2">mdi-motion-play-outline</v-icon>Reactivate
                    </v-btn>
                    <v-btn v-else-if="isSubscriptionChargeFailed" small dark color="warning" min-width="145" class="float-right mr-n2" @click="showConfirmRetry">
                      <v-icon class="mr-1">mdi-restore</v-icon>Retry Charge
                    </v-btn>
                  </v-col>
                </v-row>
                <template v-if="isSubscriptionDetailsVisible && isCustomerExists">
                  <v-row v-if="!isSubscriptionExists && !isCorporateAutomated" class="justify-space-between px-1">
                    <v-btn dark color="cyan darken-1" class="mx-0" block small @click="openSubscriptionsModal()"> <v-icon small class="mr-2">mdi-plus</v-icon>Subscription </v-btn>
                  </v-row>
                  <v-row v-else class="px-1">
                    <v-menu close-on-content-click open-on-hover offset-y bottom transition="scroll-y-transition">
                      <template v-slot:activator="{ on }">
                        <v-btn :color="campaignStatusColor" dark block small v-on="on">
                          <span v-if="campaignStatus.length < 15" class="mr-1">Campaign Status: </span>
                          <span v-else class="mr-1">Status:</span>{{ campaignStatus }}
                          <v-spacer></v-spacer>
                          <v-divider class="px-2" vertical></v-divider>
                          <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item v-if="!isCorporateAutomated" link @click="openSubscriptionsModal()">
                          <v-list-item-title><v-icon class="mr-4" color="cyan darken-2">mdi-eye</v-icon>View Subscription</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="!isCorporateAutomated" link @click="openChangePaymentMethodDialog()">
                          <v-list-item-title><v-icon class="mr-4" color="cyan darken-2">mdi-credit-card-sync-outline</v-icon>Change Payment Method</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="isSubscriptionExists && isUserSuperAdmin && !isCorporateAutomated"
                          :disabled="isCampaignPaused || isCampaignCanceled"
                          link
                          @click="openUsageRecordDialog()"
                        >
                          <v-list-item-title><v-icon class="mr-4" color="cyan darken-2">mdi-calculator-variant-outline</v-icon>Usage Record</v-list-item-title>
                        </v-list-item>
                        <v-list-item :disabled="isCancelSubscriptionHidden" link @click="openConfirmationDialog('cancel the subscription')">
                          <v-list-item-title><v-icon class="mr-4" color="cyan darken-2">mdi-close</v-icon>Cancel Subscription</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center mt-1">
      <v-col cols="12" xl="8">
        <v-row class="justify-space-between">
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <v-card class="elevation-2" max-height="123px" min-height="123px">
              <v-card-text class="subtitle-1">
                <v-row class="mx-1 justify-space-between">
                  <v-col cols="2" class="mt-6">
                    <v-icon large color="cyan darken-1">mdi-home-currency-usd</v-icon>
                  </v-col>
                  <v-col cols="9">
                    <v-row class="ml-n5 mt-3">
                      <span class="font-weight-light grey--text text--darken-1">Original Price</span>
                    </v-row>
                    <v-row class="ml-n5 pt-0">
                      <span class="font-weight-bold black--text">{{ formatNumeral(fullOrderObject.order_total).format('$0,0.00') }}</span>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <v-card class="elevation-2" max-height="123px" min-height="123px">
              <v-progress-linear v-if="balanceLoading" indeterminate color="cyan darken-1" size="40"></v-progress-linear>
              <v-card-text class="pt-5 subtitle-1">
                <v-row class="mx-1 justify-space-between">
                  <v-col cols="2" class="text-center pt-6 ml-n2">
                    <v-icon large color="cyan darken-1">mdi-currency-usd</v-icon>
                  </v-col>
                  <v-col cols="9">
                    <v-row class="mt-0 ml-n5">
                      <span class="font-weight-light grey--text text--darken-1">Order Balance</span>
                    </v-row>
                    <v-row v-if="!balanceLoading" class="ml-n5 mb-0">
                      <span v-if="fullOrderObject.order_balance" class="font-weight-bold black--text">
                        {{ formatNumeral(formatPrice(fullOrderObject.order_balance)).format('$0,0.00') }}
                      </span>
                      <span v-else class="font-weight-bold black--text">{{ formatNumeral(calculatedAmount).format('$0,0.00') }}</span>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row v-if="isCustomerExists && !balanceLoading" class="mx-0">
                  <v-btn block color="green darken-1" small class="white--text" :disabled="!isOneTimePaymentFailed && isChargeDisabled" @click="chargeModal = true">
                    <v-icon small>mdi-plus</v-icon><span class="ml-2 mr-1">Charge</span>
                  </v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <v-card class="elevation-2" max-height="123px" min-height="123px">
              <v-progress-linear v-if="balanceLoading" indeterminate color="cyan darken-1" size="40"></v-progress-linear>
              <v-card-text class="pt-5 subtitle-1">
                <v-row class="mx-1 justify-space-between">
                  <v-col cols="3" class="text-center pl-3 pt-5">
                    <v-img src="@/assets/logos/ospreyLogo.png" max-width="30px"></v-img>
                  </v-col>
                  <v-col cols="9">
                    <v-row class="mt-0 ml-n5">
                      <span class="font-weight-light grey--text text--darken-1">Osprey Total</span>
                    </v-row>
                    <v-row v-if="!balanceLoading" class="ml-n5 mb-0">
                      <span v-if="balance.orderTotalAggregate" class="font-weight-bold black--text"> {{ formatNumeral(balance.orderTotalAggregate).format('$0,0.00') }}</span>
                      <span v-else class="font-weight-bold black--text text-center">-</span>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row v-if="isCustomerExists && !balanceLoading" class="mx-0">
                  <v-btn block color="cyan darken-1" small class="white--text" :disabled="isSyncDisabled" @click="syncBalance()">
                    <v-icon small class="mr-1">mdi-sync</v-icon>Sync Balance
                  </v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" xl="8">
        <v-expansion-panels v-model="orderDetailPanels" popout multiple>
          <v-expansion-panel id="summaryContent">
            <v-expansion-panel-header v-slot="{ open }">
              <p :class="isDetailPanelOpened(open)">Summary</p>
              <p v-if="!open" class="panel-subtitle">View Order Summary</p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <order-summary
                :isPanelOpened="isSummaryPanelOpened"
                :orderData="fullOrderObject"
                @updateFeedback="componentFeedback"
                @openSpecialInstructionDialog="openSpecialInstructionDialog"
              ></order-summary>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel id="callTrackingContent">
            <v-expansion-panel-header v-slot="{ open }">
              <p :class="isDetailPanelOpened(open)">Conversion tracking</p>
              <p v-if="!open" class="panel-subtitle">View conversion tracking information</p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <call-tracking-info :isPanelOpened="isCallTrackingPanelOpened" :orderData="fullOrderObject" @updateFeedback="componentFeedback"></call-tracking-info>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel id="referencesContent">
            <v-expansion-panel-header v-slot="{ open }">
              <p :class="isDetailPanelOpened(open)">References</p>
              <p v-if="!open" class="panel-subtitle">View references</p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <order-references :isPanelOpened="isReferencesPanelOpened" :orderData="fullOrderObject" @updateFeedback="componentFeedback"></order-references>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel id="orderDataContent">
            <v-expansion-panel-header v-slot="{ open }">
              <p :class="isDetailPanelOpened(open)">Order Data</p>
              <p v-if="!open" class="panel-subtitle">View order data treeview</p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <order-data-tree-view :isPanelOpened="isOrderDataOpened" :firebaseOrderId="firebaseOrderId"></order-data-tree-view>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel id="deliveryContent" :class="deliveryPanelExtraMargin">
            <v-expansion-panel-header v-slot="{ open }">
              <p :class="isDetailPanelOpened(open)">Delivery Information</p>
              <p v-if="!open" class="panel-subtitle">View drop statuses</p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <delivery-information :isPanelOpened="isDeliveryInfoPanelOpened" :firebaseOrderId="firebaseOrderId" :orderData="fullOrderObject" @updateFeedback="componentFeedback">
              </delivery-information>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-show="isNewMoversOrder" id="editAudienceContent">
            <v-expansion-panel-header v-slot="{ open }">
              <p :class="isDetailPanelOpened(open)">Audience</p>
              <p v-if="!open" class="panel-subtitle">Select new addresses from map</p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <edit-audience
                :isPanelOpened="isEditAudiencePanelOpened"
                :mapId="mapReference"
                :firebaseOrderId="firebaseOrderId"
                :orderData="fullOrderObject"
                :isContentEditable="isAutomatedCampaignEditable"
                @updateFeedback="componentFeedback"
              >
              </edit-audience>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-show="isNewMoversOrder" id="monthlyBudgetContent">
            <v-expansion-panel-header v-slot="{ open }">
              <p :class="isDetailPanelOpened(open)">Monthly Budget</p>
              <p v-if="!open" class="panel-subtitle">Set monthly budget</p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <monthly-budget
                :isPanelOpened="isMonthlyBudgetOpened"
                :firebaseOrderId="firebaseOrderId"
                :orderReferences="references"
                :mailQuantity="fullOrderObject.mail_qty"
                :monthlyQuantity="fullOrderObject.monthly_qty"
                :orderData="fullOrderObject"
                :isContentEditable="isAutomatedCampaignEditable"
                @updateFeedback="componentFeedback"
              >
              </monthly-budget>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-show="isNewMoversOrder" id="editArtworksContent">
            <v-expansion-panel-header v-slot="{ open }">
              <p :class="isDetailPanelOpened(open)">Artwork</p>
              <p v-if="!open" class="panel-subtitle">Edit front and back artworks</p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <edit-creative
                :isPanelOpened="isEditCreativePanelOpened"
                :firebaseOrderId="firebaseOrderId"
                :rootOrder="fullOrderObject"
                :isContentEditable="isAutomatedCampaignEditable"
                componentMode="default"
              >
              </edit-creative>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel id="transactionsContent">
            <v-expansion-panel-header v-slot="{ open }">
              <p :class="isDetailPanelOpened(open)">Transaction History</p>
              <p v-if="!open" class="panel-subtitle">View transaction history</p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <transactions :refundIsEnabled="refundIsEnabled" :customerFirebaseId="customerFirebaseId"></transactions>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel id="logsContent">
            <v-expansion-panel-header v-slot="{ open }">
              <p :class="isDetailPanelOpened(open)">Logs</p>
              <p v-if="!open" class="panel-subtitle">View all activity from this order</p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <order-logs :isPanelOpened="isLogsPanelOpened" :firebaseOrderId="firebaseOrderId"></order-logs>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-show="isAvailabilityOrder || isInsertsOrder" id="ldpContent">
            <v-expansion-panel-header v-slot="{ open }">
              <p :class="isDetailPanelOpened(open)">
                <span v-if="isInsertsOrder">Inserts Order</span>
                <span v-else>LDP Order</span>
              </p>
              <p v-if="!open" class="panel-subtitle">
                Get Media plan and perform
                <span v-if="isInsertsOrder">Inserts</span>
                <span v-else>LDP</span> Order
              </p>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ldp-inserts-order :isPanelOpened="isLdpPanelOpened" :firebaseOrderId="firebaseOrderId" :orderData="fullOrderObject"></ldp-inserts-order>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import LoadingDialog from '../dialogs/LoadingDialog.vue';
import ChargeAndRefundDialog from './orderDialogs/ChargeRefundDialog.vue';
import SubscriptionDialog from './orderDialogs/SubscriptionDialog.vue';
import Transactions from '../../stripe/Transactions';
import UsageRecordDialog from '../../stripe/dialogs/UsageRecordDialog';
import MonthlyBudget from '../detail-components/MonthlyBudget';
import EditCreative from '../detail-components/EditCreative';
import EditAudience from '../detail-components/EditAudience';
import DeliveryInformation from '../detail-components/DeliveryInformation';
import OrderLogs from '../detail-components/OrderLogs';
import OrderSummary from '../detail-components/OrderSummary';
import CallTrackingInfo from '../detail-components/CallTrackingInfo';
import OrderReferences from '../detail-components/OrderReferences';
import OrderDataTreeView from '../detail-components/OrderDataTreeView';
import LDPOrder from '../detail-components/LdpOrder';
import EditCustomerNameDialog from './orderDialogs/SetCustomerNameDialog';
import ConfirmationDialog from './orderDialogs/ConfirmationDialog';
import customerService from '../../../services/customerService';
import SpecialInstructionDialog from './orderDialogs/SpecialInstructionDialog';
const numeral = require('numeral');

export default {
  components: {
    'cm-loading-dialog': LoadingDialog,
    'charge-refund-dialog': ChargeAndRefundDialog,
    'subscription-dialog': SubscriptionDialog,
    transactions: Transactions,
    'usage-record-dialog': UsageRecordDialog,
    'monthly-budget': MonthlyBudget,
    'edit-creative': EditCreative,
    'edit-audience': EditAudience,
    'delivery-information': DeliveryInformation,
    'order-logs': OrderLogs,
    'order-summary': OrderSummary,
    'call-tracking-info': CallTrackingInfo,
    'order-references': OrderReferences,
    'order-data-tree-view': OrderDataTreeView,
    'ldp-inserts-order': LDPOrder,
    'edit-customer-name': EditCustomerNameDialog,
    'confirmation-dialog': ConfirmationDialog,
    'special-instruction-dialog': SpecialInstructionDialog
  },
  data() {
    return {
      orderID: this.$route.params.OrderID,
      isOrderLoading: false,
      orderType: '',
      references: null,
      fullOrderObject: {},
      companyName: null,
      wp_customer_id: null,
      orderDetailPanels: [0],
      orderStatus: '',
      isCustomerExists: null,
      customerFirebaseId: '',
      infoSnackbar: false,
      infoSnackbarText: '',
      infoSnackbarType: '',
      chargeModal: false,
      subscriptionModal: false,
      balance: {},
      balanceLoading: false,
      isCustomerNameEditOpened: false,
      refundIsEnabled: false,
      calculatedAmount: 0,
      isGetPaymentHistoryCalled: false,
      showUsageRecordDialog: false,
      subscriptionData: null,
      isSummaryPanelOpened: true,
      isCallTrackingPanelOpened: false,
      isDeliveryInfoPanelOpened: false,
      isMonthlyBudgetOpened: false,
      isEditCreativePanelOpened: false,
      isEditAudiencePanelOpened: false,
      isLogsPanelOpened: false,
      isReferencesPanelOpened: false,
      isOrderDataOpened: false,
      isLdpPanelOpened: false,
      isConfirmationDialogOpened: false,
      confirmationDialogAction: '',
      isChangePaymentMethodOpened: false,
      isSubscriptionLoading: false,
      isConfirmRetryChargeShown: false,
      isPageFirstInitAlreadyRun: false,
      isSpecialInstructionDialogOpened: false
    };
  },
  computed: {
    ...mapGetters('customer_messaging', {
      selectedOrder: 'getSelectedOrder',
      triggeredOrder: 'getTriggeredOrder',
      orderData: 'getOrderData'
    }),
    ...mapGetters('stripe', {
      paymentHistory: 'getPaymentHistory',
      isPaymentHistoryLoading: 'getPaymentHistoryIsLoading'
    }),
    ...mapGetters('users', {
      userStore: 'user',
      userAccessLevel: 'userAccessLevel',
      userAcessLevelArray: 'userAcessLevelArray'
    }),
    ...mapGetters('site', {
      testSiteIds: 'getTestSiteIds'
    }),
    ...mapGetters('payment_methods', {
      paymentMethodsList: 'getPaymentMethods'
    }),
    isUserSuperAdmin() {
      return !!this.userAcessLevelArray.filter(accessLevel => Number(accessLevel) === 100).length;
    },
    isNewMoversOrder() {
      return this.orderType === 'newmovers';
    },
    isAvailabilityOrder() {
      return this.orderType === 'availability';
    },
    isInsertsOrder() {
      return this.orderType === 'inserts';
    },
    isAutomated() {
      return this.fullOrderObject.hasOwnProperty('automated');
    },
    isChargeDisabled() {
      return !!this.isOrderTotalDifferent || !this.isOrderSavedInOsprey || (this.isAutomated && (!this.isUserSuperAdmin || !this.isCampaignCanceled));
    },
    isCorporateAutomated() {
      return this.isAutomated && this.fullOrderObject.automated.type === 'corporate';
    },
    confirmRetryChargeMessage() {
      const paymentMethod = this.paymentMethodsList?.find(paymentMethod => paymentMethod.id === this.subscriptionData?.payment_method_db_id);
      if (!paymentMethod?.details) {
        return `retry this payment. This action will retry the payment of the last failed invoice. Would you like to proceed?`;
      }
      return `retry this payment. This action will retry the payment of the last failed invoice with the payment method: ${paymentMethod.details}. Would you like to proceed?`;
    },
    isOneTimePaymentFailed() {
      const firstPayment = this.paymentHistory?.[0];
      const firstSuccessfulPaymentAmount = Number(this.paymentHistory?.find(payment => payment.success)?.amount) / 100 || 0;
      const isFirstSuccessfulPaymentTheOneTime = firstSuccessfulPaymentAmount === this.oneTimeAmount;
      return this.isAutomated && this.oneTimeAmount > 0 && !firstPayment?.success && !isFirstSuccessfulPaymentTheOneTime;
    },
    oneTimeAmount() {
      return Number(this.orderData?.pricing?.one_time_amount);
    },
    isSyncDisabled() {
      return !this.isOrderTotalDifferent || (this.isAutomated && !this.isUserSuperAdmin);
    },
    isOrderSavedInOsprey() {
      return !!this.fullOrderObject?.crm_order_id;
    },
    isOrderTotalDifferent() {
      return (
        (this.balance?.orderTotalAggregate &&
          this.fullOrderObject.order_osprey_total &&
          this.formatPrice(this.balance?.orderTotalAggregate) !== this.formatPrice(this.fullOrderObject.order_osprey_total)) ||
        (!this.fullOrderObject.order_osprey_total && this.balance?.orderTotalAggregate)
      );
    },
    isTestSite() {
      return this.fullOrderObject.site_db_id === '-LGjZtGpLePH-61Rp662' || this.fullOrderObject.site_db_id === '-LGjZIjpUA5jHPPUDXdj';
    },
    mapReference() {
      return this.references && this.references.map_id ? this.references.map_id.split('/').pop() : '';
    },
    isSubscriptionExists() {
      return this.fullOrderObject.subscription_id || (this.fullOrderObject.automated && this.fullOrderObject.automated.subscription_id);
    },
    campaignStatus() {
      return this.fullOrderObject?.automated?.campaign_status || '';
    },
    isCampaignActive() {
      return this.campaignStatus === 'active';
    },
    isCampaignPaused() {
      return this.campaignStatus === 'paused';
    },
    isCampaignCancelationInProgress() {
      return this.campaignStatus.includes('cancellation');
    },
    isCampaignCanceled() {
      return this.campaignStatus === 'canceled';
    },
    isSubscriptionChargeFailed() {
      return (
        this.subscriptionData &&
        (['incomplete', 'past_due', 'pending', 'unpaid'].includes(this.subscriptionData.status) ||
          ['incomplete', 'past_due', 'pending', 'unpaid', 'cancellation_failed'].includes(this.campaignStatus))
      );
    },
    isCancelSubscriptionHidden() {
      return this.isCampaignCanceled || this.isCampaignCancelationInProgress || this.isSubscriptionChargeFailed || this.campaignStatus === 'cancellation_pending';
    },
    campaignStatusColor() {
      return this.isCampaignActive
        ? 'green'
        : this.isCampaignPaused
        ? 'orange darken-2'
        : this.isCampaignCanceled
        ? 'grey darken-1'
        : this.isSubscriptionChargeFailed
        ? 'red'
        : this.isCampaignCancelationInProgress
        ? 'brown'
        : 'cyan darken-2';
    },
    isAutomatedCampaignEditable() {
      return this.isNewMoversOrder && !this.isCampaignCanceled;
    },
    ospreyOrderTotal() {
      return this.balance?.orderTotalAggregate ? this.formatPrice(this.balance.orderTotalAggregate) : null;
    },
    deliveryPanelExtraMargin() {
      return (!this.isNewMoversOrder || !this.isAvailabilityOrder) && this.isDeliveryInfoPanelOpened ? 'mb-4' : '';
    },
    isSubscriptionDetailsVisible() {
      return this.isNewMoversOrder;
    },
    firebaseOrderId() {
      return this.fullOrderObject?.firebase_order_id ?? this.orderID;
    }
  },
  watch: {
    isSubscriptionChargeFailed(value) {
      if (value) {
        this.setSnackbarProps(
          'warning',
          'Please note that there is a failed charge on this order, which may limit further actions. Kindly resolve the issue or notify the customer immediately. We have already sent an email regarding this matter. Thank you for your cooperation.'
        );
      }
    },
    orderDetailPanels(value, oldValue) {
      // NOTE: we cannot add separate IDs for panels so I have to handle each separately
      const panelsMap = [
        { variable: 'isSummaryPanelOpened', scrollTo: 'summaryContent' },
        { variable: 'isCallTrackingPanelOpened', scrollTo: 'callTrackingContent' },
        { variable: 'isReferencesPanelOpened', scrollTo: 'referencesContent' },
        { variable: 'isOrderDataOpened', scrollTo: 'orderDataContent' },
        { variable: 'isDeliveryInfoPanelOpened', scrollTo: 'deliveryContent' },
        { variable: 'isEditAudiencePanelOpened', scrollTo: 'editAudienceContent' },
        { variable: 'isMonthlyBudgetOpened', scrollTo: 'monthlyBudgetContent' },
        { variable: 'isEditCreativePanelOpened', scrollTo: 'editArtworksContent' },
        { variable: null, scrollTo: 'transactionsContent' },
        { variable: 'isLogsPanelOpened', scrollTo: 'logsContent' },
        { variable: 'isLdpPanelOpened', scrollTo: 'ldpContent' }
      ];

      // when adding new panels to OrderDetails, make sure you respect the order of the panels in the panelsMap object,
      // because it is directly influenced by the order of the ids the vuetify expansion panel gives
      // add a new variable to data vue property, that would represent the state (opened or not) of the panel, can be null
      // add scrollTo property, which is the id of the panel to scroll to

      const diffClose = oldValue.filter(panelId => !value.includes(panelId))[0] || null;
      const diffOpen = !diffClose ? value[value.length - 1] : null;

      if (panelsMap[diffClose]) {
        this.closePanel(panelsMap[diffClose]);
      } else if (panelsMap[diffOpen]) {
        this.openPanel(panelsMap[diffOpen]);
      }
    },
    paymentHistory(value) {
      this.paymentHistory.forEach(payment => {
        payment.timestamp = payment.paymentTime;
      });
      if (value.length) {
        this.updateOrderBalance();
      }
    },
    triggeredOrder: {
      handler(order, oldOrder) {
        if (order) {
          this.initOrderDetails(order);
          this.getPaymentHistory();
          if (order.crm_order_id > 0 && this.isAutomated && !this.isSubscriptionExists && !this.isCorporateAutomated) {
            this.$store.dispatch('showAlert', { type: 'error', message: 'The order is missing a subscription, please create a subscription before proceeding!' });
          }
          if (order && oldOrder) {
            if (oldOrder.order_balance !== order.order_balance) {
              console.log(`OLD order_balance: `, oldOrder.order_balance);
              console.log(`NEW order_balance: `, order.order_balance);
              if (!this.isGetPaymentHistoryCalled) {
                this.$store.commit('stripe/setPaymentHistoryIsLoading', true);
              }
              this.getPaymentHistory();
            }
          }
        }
      },
      deep: true
    }
  },
  async created() {
    this.isOrderLoading = true;

    try {
      const response = await this.$store.dispatch('customer_messaging/listSelectedOrder', { orderID: this.orderID });

      if (response.customer_unique_id) {
        await this.getCustomerFirebaseId(response.customer_unique_id);
      }
      if (response.crm_order_id) {
        await this.checkOspreyAdjustments(this.firebaseOrderId);
      }
      if (!this.testSiteIds.length) {
        await this.$store.dispatch('site/getTestSites');
      }
    } catch (error) {
      this.setSnackbarProps('error', error);
    } finally {
      this.isOrderLoading = false;
    }
  },
  async mounted() {
    try {
      const response = await this.$store.dispatch('customer_messaging/triggerOrderChange', {
        orderID: this.orderID
      });

      if (response) {
        this.initOrderDetails(response);
      }
    } catch (error) {
      console.log('OrderDetails/mounted has failed: ', error);
    }
  },
  methods: {
    showConfirmRetry() {
      this.isConfirmRetryChargeShown = true;
    },
    confirmRetry() {
      this.isConfirmRetryChargeShown = false;
      this.retrySubscriptionStripeCharge();
    },
    cancelRetryConfirmation() {
      this.isConfirmRetryChargeShown = false;
    },
    openPanel({ variable, scrollTo }) {
      if (variable && this.hasOwnProperty(variable)) {
        this[variable] = true;
      }
      if (scrollTo && scrollTo !== '') {
        this.$store.dispatch('scroll_to', scrollTo);
      }
    },
    closePanel({ variable }) {
      if (variable && this.hasOwnProperty(variable)) {
        this[variable] = false;
      }
    },
    async initOrderDetails(response) {
      this.fullOrderObject = response;
      this.listOrderData();
      this.orderType = response.order_type;
      this.references = response.references || [];
      this.orderStatus =
        response.hasOwnProperty('order_status') && Object.keys(response.order_status).length >= 1 && response.order_status.status
          ? this.capitalize(response.order_status.status)
          : '';

      if (this.fullOrderObject.crm_order_id && this.fullOrderObject.crm_order_id > 0) {
        this.getBalance();
      }
      this.getSubscriptionByID();

      if (this.isNewMoversOrder) {
        const isAutomatedCampaignActive = response.automated?.campaign_status === 'active';
        const notificationConfig = {
          notificationMessage: 'Please note that only paused campaigns can be modified. Pause it before performing any actions.',
          isVisible: isAutomatedCampaignActive,
          isActionButtonVisible: isAutomatedCampaignActive
        };
        this.$store.commit('om_orders/setCampaignEditNotification', notificationConfig);
      }
      this.isPageFirstInitAlreadyRun = true;
    },
    async listOrderData() {
      try {
        const response = await this.$store.dispatch('customer_messaging/listOrderData', {
          documentId: this.orderID
        });
        this.companyName = response.companyName;
        this.wp_customer_id = response.wp_customer_id;
      } catch (error) {
        console.log('lisOrderData has failed: ', error);
      }
    },
    async getCustomerFirebaseId(uniqueId) {
      try {
        let result = await customerService.getCustomerFirebaseId(uniqueId);

        if (result.error) {
          this.setSnackbarProps('error', result.error);
        } else {
          if (!result.firebaseId) {
            this.isCustomerExists = false;
            this.createCustomer();
          } else {
            this.isCustomerExists = true;
            this.fullOrderObject.first_name = result.customerData.first_name;
            this.fullOrderObject.last_name = result.customerData.last_name;
            this.customerFirebaseId = result.firebaseId;

            await this.$store.dispatch('om_orders/updateCustomerNameInOrder', {
              order_id: this.orderID,
              first_name: this.fullOrderObject.first_name,
              last_name: this.fullOrderObject.last_name
            });

            this.fetchCreditCards();

            if (!this.fullOrderObject.first_name && !this.fullOrderObject.last_name) {
              this.openCustomerNameEditDialog();
            }
          }
        }
      } catch (err) {
        this.setSnackbarProps('getCustomerFirebaseId has failed: ', err);
      }
    },
    formatDate(dateTotransform) {
      var date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    formatPrice(price) {
      return parseFloat(price.toFixed(2));
    },
    formatNumeral(amount) {
      return numeral(amount);
    },
    getErrorResults(array) {
      return array.filter(item => item.result === 'Error');
    },
    calculateRefundSum(refunds) {
      let sum = 0;
      refunds.forEach(refund => {
        sum += refund.amount;
      });
      return sum;
    },
    isPaymentTypeKnown(paymentType) {
      switch (paymentType) {
        case 'stripe':
          return true;
        case 'pay_later':
          return true;
        case 'adjustment':
          return true;
        case 'balance_adjustment':
          return true;
        case 'corporate':
          return true;
        default:
          return false;
      }
    },
    setSnackbarProps(type, message) {
      this.infoSnackbar = true;
      this.infoSnackbarType = type;
      this.infoSnackbarText = message;
    },
    closeInfoSnackbar() {
      this.infoSnackbar = false;
      this.infoSnackbarType = 'info';
      this.infoSnackbarText = '';
    },
    createCustomer() {
      this.isOrderLoading = true;
      var timestamp = new Date(this.fullOrderObject.creation_date.seconds * 1000);
      const niceDate = this.$moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
      //I call again...
      this.$store
        .dispatch('customer_messaging/listOrderData', {
          documentId: this.orderID
        })
        .then(response => {
          let customerInfo = {
            created_at: niceDate,
            crm_customer_id: this.fullOrderObject.crm_customer_id,
            customer_unique_id: this.fullOrderObject.customer_unique_id,
            first_name: this.fullOrderObject.first_name,
            last_name: this.fullOrderObject.last_name,
            email: this.fullOrderObject.order_email,
            firebase_site_id: this.fullOrderObject.site_db_id,
            site_id: this.fullOrderObject.site_id,
            wp_user_id: response.wp_customer_id,
            company: response.companyName
          };
          this.$store
            .dispatch('customer_messaging/createNewCustomer', customerInfo)
            .then(response => {
              if (response.error) {
                this.setSnackbarProps('error', response.error.data);
              }
              if (response.id) {
                console.log('[ New customer ]:', response);
                this.isCustomerExists = true;
                this.customerFirebaseId = response.id;
                this.setSnackbarProps('success', 'New customer successfully added!');
              }
            })
            .finally(() => {
              this.isOrderLoading = false;
            });
        });
    },
    closeChargeModal() {
      this.chargeModal = false;
      this.updateOrderBalance();
    },
    getPaymentHistory() {
      this.isGetPaymentHistoryCalled = true;
      let payments = [];
      if (this.fullOrderObject.payments && Object.values(this.fullOrderObject.payments).length) {
        Object.values(this.fullOrderObject.payments).forEach(payment => payments.push(payment));
      }
      if (this.fullOrderObject.payment_id) {
        payments.push(this.fullOrderObject.payment_id);
      }
      this.$store
        .dispatch('stripe/getPaymentHistory', {
          crm_order_id: this.fullOrderObject.crm_order_id,
          paymentIds: payments
        })
        .catch(error => console.log('Error:', error));
    },
    openSubscriptionsModal() {
      this.subscriptionModal = true;
    },
    closeSubscriptionDialog(data) {
      if (data && data.isSubscriptionChanged) {
        this.getSubscriptionByID();
      } else {
        this.subscriptionModal = false;
        this.isChangePaymentMethodOpened = false;
      }
    },
    openUsageRecordDialog() {
      this.showUsageRecordDialog = true;
    },
    openSpecialInstructionDialog() {
      this.isSpecialInstructionDialogOpened = true;
    },
    closeUsageRecordDialog() {
      this.showUsageRecordDialog = false;
    },
    async getBalance() {
      this.balanceLoading = true;
      try {
        const response = await this.$store.dispatch('stripe/getBalance', {
          osprey_order_id: this.fullOrderObject.crm_order_id
        });

        this.balance = response.data.balance;
        console.log('[ Order Balance ]: ', this.balance);
        return Promise.resolve();
      } catch (error) {
        console.log('[ getBalance ] ERROR:', error);
        if (error?.response?.data) {
          console.log('[ getBalance ERROR DATA ]: ', error.response.data);
          this.setSnackbarProps('error', this.fullOrderObject.crm_order_id === 0 ? 'The CRM order ID cannot be 0!\t' + error.response.data : error.response.data);
        }
        return Promise.reject();
      } finally {
        this.balanceLoading = false;
      }
    },
    async checkOspreyAdjustments(firebaseOrderID) {
      try {
        if (firebaseOrderID) {
          const response = await this.$store.dispatch('stripe/checkOspreyAdjustments', {
            firebase_order_id: firebaseOrderID
          });

          if (response?.data?.newAdjustmentInserted) {
            console.log('[ New Osprey Adjustment inserted - Updating Payment History ]');
            this.getPaymentHistory();
          }
          return Promise.resolve();
        }
      } catch (error) {
        if (error?.response?.data) {
          console.log('[ checkOspreyAdjustments ] ERROR:', error.response.data);
          this.setSnackbarProps('error', error.response.data);
        }
        return Promise.reject();
      }
    },
    checkIsChargable() {
      if (this.isChargable) {
        this.chargeModal = true;
      }
    },
    async syncBalance() {
      this.balanceLoading = true;
      try {
        await this.$store.dispatch('stripe/syncBalance', {
          firebase_order_id: this.fullOrderObject.firebase_order_id,
          agent_id: this.userStore.id,
          agent_name: this.userStore.name,
          agent_email: this.userStore.email
        });
      } catch (error) {
        if (error?.response?.data) {
          console.log('[ syncBalance ] ERROR:', error.response.data);
          this.setSnackbarProps('error', error.response.data);
        }
        return Promise.reject(error);
      } finally {
        this.balanceLoading = false;
      }
    },
    fetchCreditCards() {
      if (this.customerFirebaseId) {
        this.$store.dispatch('payment_methods/getPaymentMethodsData', {
          customerId: this.customerFirebaseId,
          showAllCards: false
        });
      }
    },
    recalculateBalance() {
      try {
        let payments = this.paymentHistory.filter(payment => (payment.success || payment.status) && payment.paymentType !== 'pay_later');
        let paymentTotal = payments.length ? payments.map(payment => parseFloat(payment.amount) || 0).reduce((a, b) => a + b) : 0;
        let refundTotal = payments.length
          ? payments.flatMap(payment => (payment.refunds ? payment.refunds.map(refund => parseFloat(refund.amount) || 0) : 0)).reduce((a, b) => a + b)
          : 0;
        let total = this.formatPrice((paymentTotal - refundTotal) / 100);
        let difference = this.formatPrice(total - this.fullOrderObject.order_total);
        return -difference;
      } catch (error) {
        this.$store.dispatch('setSnackbar', { type: 'error', message: `Error while updating balance: ${error}` });
        return null;
      }
    },
    async updateOrderBalance() {
      let calculatedBalance = this.recalculateBalance();
      console.log('recalculated balance: ', calculatedBalance);

      if (calculatedBalance !== null) {
        this.calculatedAmount = calculatedBalance;
        let orderBalance = this.fullOrderObject.order_balance;
        orderBalance = !isNaN(orderBalance) ? orderBalance : this.fullOrderObject.order_total;
        if (this.formatPrice(calculatedBalance) !== this.formatPrice(orderBalance)) {
          try {
            if (!this.isPageFirstInitAlreadyRun) {
              this.$store.dispatch('showLoader', { message: `Updating balance...` });
            }
            await this.$store.dispatch('stripe/updateOrderBalance', {
              id: this.fullOrderObject.firebase_order_id,
              balance: calculatedBalance
            });
          } catch (error) {
            this.$store.dispatch('setSnackbar', { type: 'error', message: `Error while updating balance: ${error}` });
          } finally {
            this.$store.dispatch('hideLoader');
          }
        }
      }
    },
    async getSubscriptionByID() {
      const subscriptionId = this.fullOrderObject.subscription_id || this.fullOrderObject.automated ? this.fullOrderObject.automated.subscription_id : '';

      try {
        if (subscriptionId) {
          if (!this.isPageFirstInitAlreadyRun) {
            this.$store.dispatch('showLoader', {
              message: 'Loading subscription...'
            });
          }
          const response = await this.$store.dispatch('stripe/getSubscriptionById', {
            subscriptionId: subscriptionId
          });

          if (response.error) {
            this.setSnackbarProps('error', response.error);
          } else {
            this.subscriptionData = response;
          }
        }
      } catch (error) {
        this.setSnackbarProps('error', error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async changeSubscriptionStatus(endpoint) {
      try {
        if (endpoint) {
          this.isSubscriptionLoading = true;
          this.$store.dispatch('showLoader', {
            message: 'Updating subscription status...'
          });
          const response = await this.$store.dispatch('stripe/changeSubscriptionStatus', {
            endpoint,
            firebase_order_id: this.fullOrderObject.firebase_order_id
          });

          if (response.data && response.data.Success) {
            this.setSnackbarProps('success', 'Subscription status updated successfully');
            this.getSubscriptionByID();
          }
        }
      } catch (error) {
        if (error.response && error.response.data) {
          console.log('[ Error in updating campaign status]:', error.response.data);
          const errorData = error.response.data;
          if (errorData.message) {
            this.setSnackbarProps('error', errorData.message);
          } else {
            const errorMessage = errorData.substring(errorData.indexOf('message') - 1, errorData.lastIndexOf('."') + 2);
            this.setSnackbarProps('error', errorMessage.length > 1 ? `${JSON.parse(`{ ${errorMessage} }`).message} Please change the payment method and try again!` : errorData);
          }
        }
      } finally {
        this.isSubscriptionLoading = false;
        this.$store.dispatch('hideLoader');
      }
    },
    async retrySubscriptionStripeCharge() {
      this.$store.dispatch('showLoader', {
        message: 'Retrying subscription charge...'
      });
      const retryChargeConfig = {
        subscription_firebase_id: this.subscriptionData.firebase_id,
        firebase_order_id: this.fullOrderObject.firebase_order_id
      };
      if (this.campaignStatus === 'cancellation_failed') {
        Object.assign(retryChargeConfig, {
          is_cancel_retry: true
        });
      }

      try {
        await this.$store.dispatch('stripe/retryStripeSubscriptionCharge', retryChargeConfig);
        this.setSnackbarProps('success', 'Subscription charged successfully!');
      } catch (error) {
        const errorData = error?.response?.data;
        console.log('[ Error in retrying subscription charge]:', errorData);
        this.setSnackbarProps('error', `${errorData?.message} Check subscription details and try again!`);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async getAutomatedEditCampaignConfig(siteId) {
      const data = await this.$store.dispatch('site/listSelectedSite', { siteKey: siteId });
      return data.hasOwnProperty('edit_automated_mailer_enabled') ? data.edit_automated_mailer_enabled : true;
    },
    openChangePaymentMethodDialog() {
      this.isChangePaymentMethodOpened = true;
      this.openSubscriptionsModal();
    },
    componentFeedback(feedback) {
      this.setSnackbarProps(feedback.type, feedback.message);
      window.scrollTo(0, 0);
    },
    isDetailPanelOpened(open) {
      return open ? 'title' : 'panel-title';
    },
    openCustomerNameEditDialog() {
      this.isCustomerNameEditOpened = true;
    },
    updateCustomerName(data) {
      this.fullOrderObject.first_name = data.first_name || '';
      this.fullOrderObject.last_name = data.last_name || '';
      this.setSnackbarProps('success', 'Customer name updated successfully!');
    },
    closeCustomerNameEdit() {
      this.isCustomerNameEditOpened = false;
    },
    openConfirmationDialog(action) {
      this.confirmationDialogAction = action;
      this.isConfirmationDialogOpened = true;
    },
    closeConfirmationDialog() {
      this.isConfirmationDialogOpened = false;
    },
    closeSpecialInstructionDialog() {
      this.isSpecialInstructionDialogOpened = false;
    },
    actionConfirmed(data) {
      console.log(`${data && data.action ? data.action : ''} confirmed`);
      this.closeConfirmationDialog();
      this.changeSubscriptionStatus('cancel');
    },
    navigateBackToOrders() {
      this.$router.go(-1);
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    openOrderInOsprey(ospreyOrderId) {
      window.open(`${process.env.VUE_APP_OSPREY_ENDPOINT}/orders/view.cfm?id=${ospreyOrderId}`, '_blank');
    },
    capitalize(text) {
      if (!text) return '';
      const splittedText = text.toString().split('_');
      let formatted = '';
      splittedText.forEach((chunk, index) => {
        formatted += `${index > 0 ? ' ' : ''}${chunk.charAt(0).toUpperCase()}${chunk.slice(1)}`;
      });
      return formatted;
    }
  }
};
</script>

<style scoped>
.showPointerOnHover {
  cursor: pointer;
}
.custom-header {
  padding: 1px 4px;
  border-top: solid 1px red;
  border-left: solid 1px red;
  border-right: solid 1px red;
}
#hide-paragraph {
  display: none;
}

.panel-title {
  font-size: 15px !important;
}
.panel-subtitle {
  font-weight: 400;
  font-size: 0.85rem;
  color: #757575;
  position: absolute;
  margin-top: 43px;
  padding-left: 1px;
}
.test-set-inline-block {
  display: inline-block;
}

.test-custom-right-border {
  border-right: 1px #d3d3d3 solid;
  height: 123px;
}

.stamp-od-ul {
  list-style-type: none;
}

.stamp-ofd-custom-table table thead {
  display: none;
}

.stamp-ofd-custom-table table tbody tr {
  height: 70px;
}

.stamp-ofd-custom-td-hide {
  display: none;
}

.stamp-ofd-custom-td-witdh {
  width: 2%;
}

.v-expansion-panel-content__wrap {
  margin: 0;
  padding: 0;
}

.agentEmail {
  color: #757575;
  letter-spacing: normal !important;
  font-size: 0.75rem !important;
  font-weight: 400;
}

.paymentHistoryTable {
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: normal;
  font-size: 0.75rem !important;
  font-weight: 400;
}

.widget-content {
  height: 140px !important;
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .amountPosition {
    margin-left: 10%;
  }
  .reasonPosition {
    margin-left: 0%;
  }
  .timePosition {
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1280px) and (max-height: 800px) {
  .amountPosition {
    margin-left: 10%;
  }
  .reasonPosition {
    margin-left: 0%;
  }
  .timePosition {
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1280px) and (max-height: 950px) {
  .amountPosition {
    margin-left: 10%;
  }
  .reasonPosition {
    margin-left: 0%;
  }
  .timePosition {
    margin-left: 1%;
  }
}
@media only screen and (min-width: 1440px) and (max-height: 900px) {
  .amountPosition {
    margin-left: 14%;
  }
  .reasonPosition {
    margin-left: 6%;
  }
  .timePosition {
    margin-left: 9%;
  }
}
@media all and (min-width: 1900px) {
  .amountPosition {
    margin-left: 21%;
  }
  .reasonPosition {
    margin-left: 16%;
  }
  .timePosition {
    margin-left: 23%;
  }
}
</style>
