import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class SiteSettingsService extends FirestoreModel {
  constructor() {
    super(collections.sites);
  }

  async getProductsBySiteID(siteID, property = '', value = '', operator = '==') {
    const siteProductsRef =
      property && value ? this.collectionRef.doc(siteID).collection('products').where(property, operator, value) : this.collectionRef.doc(siteID).collection('products');
    const snapshot = await siteProductsRef.get();
    const products = [];
    snapshot.forEach(doc => {
      products.push({ id: doc.id, ...doc.data() });
    });

    return products;
  }

  async getProductsArrayBySiteID(siteID, productIdArray) {
    const siteProductsRef = this.collectionRef.doc(siteID).collection('products');
    const productsPromises = productIdArray.map(async productId => {
      const productDoc = await siteProductsRef.doc(productId).get();
      return productDoc.exists ? { id: productDoc.id, ...productDoc.data() } : null;
    });

    const products = await Promise.all(productsPromises);
    return products.filter(product => product !== null);
  }

  async getTemplatesBySiteID(siteID) {
    const siteTemplatesRef = this.collectionRef.doc(siteID).collection('templates');
    const snapshot = await siteTemplatesRef.get();
    const templates = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return templates;
  }

  async addTemplateToSite(siteID, templateData) {
    try {
      if (!siteID || !templateData) {
        throw new Error('Missing siteID or template data');
      }
      const siteProductsRef = this.collectionRef.doc(siteID).collection('templates');
      const docRef = await siteProductsRef.add(templateData);
      return docRef.id;
    } catch (error) {
      console.log('[addTemplateToSite]: ', error);
      throw new Error(error);
    }
  }

  async addProductToSite(siteID, productData) {
    try {
      if (!siteID || !productData) {
        throw new Error('Missing siteID or product data');
      }

      const siteProductsRef = this.collectionRef.doc(siteID).collection('products');

      const docRef = await siteProductsRef.add(productData);

      console.log('return in sitesettingsservice: ', docRef.id);
      return docRef.id;
    } catch (error) {
      console.log('[addProductToSite]: ', error);
      throw new Error(error);
    }
  }

  async addProductPricingToSite(siteID, productID) {
    try {
      if (!siteID || !productID) {
        throw new Error('Missing siteID or productID');
      }

      const siteProductsRef = this.collectionRef.doc(siteID).collection('product_pricing');

      const placeholderData = { placeholder: true };
      const docRef = await siteProductsRef.doc(productID).set(placeholderData);

      console.log('Document created with ID: ', productID);
      return productID;
    } catch (error) {
      console.log('[addProductPricingToSite]: ', error);
      throw new Error(error);
    }
  }

  async updateProductPricing(siteID, productID, productPricingData) {
    try {
      if (!siteID || !productID || !productPricingData) {
        throw new Error('Missing siteID, productID, or product pricing data');
      }

      const docRef = this.collectionRef.doc(siteID).collection('product_pricing').doc(productID);
      await docRef.set(productPricingData, { merge: true });

      console.log(`Product pricing updated for product ID: ${productID} in site: ${siteID}`);
      return { siteID, productID, productPricingData };
    } catch (error) {
      console.log('[updateProductPricing]: ', error);
      throw new Error(error);
    }
  }
}

export default new SiteSettingsService();
