// initial state
const state = {
	idletime: null
}

// mutations
const mutations = {
	setIdleTime (state, payload) {
        state.idletime = payload
    }
}
// actions
const actions = {
    /*
    * Change/update Idle time
    * @return (as commit) {string} idleTimeSetSeconds
    * Not implemented: at this point in project , the idle time could be not implemented
    */
    changeIdleTime ({commit}, payload) {
        const idleTimeSetSeconds = payload.idleTime * 1000
        commit('setIdleTime', idleTimeSetSeconds)
    },
    /*
    * Get Idle time
    * @return (as commit) {date} idleTimeFB
    * Not implemented: at this point in project , the idle time could be not implemented
    */
    fetchIdleTime ({commit}) {
        let idleTimeFB = null
    }
}
// getters
const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
