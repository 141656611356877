import { dbFS, storage } from '../../services/firebaseInit.js';
import { cloudFunctionsGet, cloudFunctionsPost, cloudFunctionsPut } from '../../modules/cloud-function-wrapper.js';
import moment from 'moment';
import siteSettingsService from '../../services/siteSettingsService';
import dropTrackerService from '../../services/dropStatusTrackerService';
import orderService from '../../services/orderService';

const state = {
  deletingParameters: null,
  deleteMultipleOrdersReport: {
    artworkFiles: '',
    orderData: '',
    algoliaOrders: ''
  },
  orderDataList: ['artwork', 'assets', 'customer', 'delivery', 'mailing', 'pricing', 'product'],
  firebaseUrl: '',
  triggeredOrderDataDelivery: null,
  automatedCampaignEditNotification: {
    isVisible: false,
    isOrderUpdated: false,
    updatedData: '',
    notificationMessage: '',
    isActionButtonVisible: true
  }
};

const mutations = {
  setDeletingParameters(state, payload) {
    state.deletingParameters = payload;
  },
  setDeleteReport(state, payload) {
    state.deleteMultipleOrdersReport = payload.deleteReport;
  },
  setFirebaseUrl(state, payload) {
    state.firebaseUrl = payload;
  },
  setTriggeredOrderDataDelivery(state, payload) {
    state.triggeredOrderDataDelivery = payload;
  },
  setCampaignEditNotification(state, payload) {
    state.automatedCampaignEditNotification = {
      isVisible:
        payload.hasOwnProperty('isVisible') && state.automatedCampaignEditNotification.isVisible !== payload.isVisible
          ? payload.isVisible
          : state.automatedCampaignEditNotification.isVisible,
      isOrderUpdated:
        payload.hasOwnProperty('isOrderUpdated') && state.automatedCampaignEditNotification.isOrderUpdated !== payload.isOrderUpdated
          ? payload.isOrderUpdated
          : state.automatedCampaignEditNotification.isOrderUpdated,
      updatedData: payload.hasOwnProperty('updatedData') ? payload.updatedData : '',
      notificationMessage: payload.hasOwnProperty('notificationMessage') ? payload.notificationMessage : state.automatedCampaignEditNotification.notificationMessage,
      isActionButtonVisible: payload.hasOwnProperty('isActionButtonVisible') ? payload.isActionButtonVisible : state.automatedCampaignEditNotification.isActionButtonVisible
    };
  }
};

const getters = {
  getDeletingParameters(state) {
    return state.deletingParameters;
  },
  getOrderDataList(state) {
    return state.orderDataList;
  },
  filterFirebaseUrl(state) {
    const url = state.firebaseUrl;
    if (!url) return '';

    const rootUrl = process.env.VUE_APP_FIREBASE_STORAGE_URL;
    const firebaseUrl = url.split('?').length ? url.split('?')[0] : '';

    return firebaseUrl.split(rootUrl).length >= 1 ? firebaseUrl.split(rootUrl)[1] : '';
  },
  getDeleteReport(state) {
    return state.deleteMultipleOrdersReport;
  },
  getTriggeredOrderDelivery(state) {
    return state.triggeredOrderDataDelivery;
  },
  getCampaignEditNotification(state) {
    return state.automatedCampaignEditNotification;
  }
};

const actions = {
  getReferences({ commit }, payload) {
    const selectedOrderList = payload.selectedOrderList;
    const referenceArray = [];
    selectedOrderList.forEach(value => {
      const firebaseOrderId = value.firebase_order_id;
      Object.entries(value.references).forEach(([key, value]) => {
        if (value && typeof value === 'string') {
          const ref = value.split('/');
          let refElement = {};
          if (ref[0] === 'maps') {
            refElement[firebaseOrderId] = {
              refName: ref[0],
              refValue: ref[1]
            };
            referenceArray.push(refElement);
          }
          refElement = {};
        }
      });
    });

    console.log('referenceArray', referenceArray);
    return referenceArray;
  },
  listOrderDataArtwork({ commit }, payload) {
    const selectedOrderList = payload.selectedOrderList;
    const promises = [];
    let artwork = [];
    let artworkList = [];
    let artworkItem = {};
    selectedOrderList.forEach((value, index) => {
      const artworkPromise = dbFS
        .collection('orders')
        .doc(value.firebase_order_id)
        .collection('order_data')
        .doc('assets')
        .get()
        .then(querySnapshot => {
          artwork = querySnapshot.data().artwork;
          if (artwork.length > 0) {
            artworkItem[value.firebase_order_id] = {
              artwork
            };
            artworkList.push(artworkItem);
            artworkItem = {};
          }
        })
        .catch(error => {
          console.log(`Getting order_data / artwork failed in order: ${value.firebase_order_id}. Error: ${error}`);
          return Promise.reject(error);
        });

      promises.push(artworkPromise);
    });
    return Promise.all(promises).then(() => {
      return artworkList;
    });
  },
  getOrderDataDoc({ dispatch }, { orderId, docName }) {
    if (orderId && docName) {
      return dbFS
        .collection('orders')
        .doc(orderId)
        .collection('order_data')
        .doc(docName)
        .get()
        .then(querySnapshot => Promise.resolve(querySnapshot.data()));
    }
    return Promise.reject({ status: 404, message: 'Missing FirebaseOrderID / document name!' });
  },
  initializeMonthlyBudget({ commit, dispatch }, payload) {
    const promises = [];
    promises.push(dispatch('getOrderDataDoc', { orderId: payload.orderId, docName: 'pricing' }));
    promises.push(
      dbFS
        .collection('maps')
        .doc(payload.mapId)
        .get()
        .then(snapshot => {
          const mapData = snapshot.data();
          return Promise.resolve(mapData.moverCounts);
        })
    );
    promises.push(dispatch('getOrderDataDoc', { orderId: payload.orderId, docName: 'delivery' }));
    return Promise.all(promises).then(results => {
      return Promise.resolve({
        pricing: results[0] ? results[0] : '',
        moverCounts: results[1] ? results[1] : '',
        delivery: results[2] ? results[2] : ''
      });
    });
  },
  updateMonthyQtyAndFee({ commit, dispatch }, payload) {
    let promises = [];
    promises.push(
      dispatch(
        'stripe/notifyOspreyOfOrderDataChange',
        {
          firebase_order_id: payload.orderId,
          firebase_site_id: payload.firebase_site_id,
          osprey_order_id: payload.osprey_order_id,
          osprey_customer_id: payload.osprey_customer_id,
          summary: payload.summary
        },
        { root: true }
      ).then(ospreyResponse => {
        if (ospreyResponse.data.Success) {
          return Promise.resolve();
        }
        return Promise.reject();
      })
    );
    promises.push(dbFS.collection('orders').doc(payload.orderId).collection('order_data').doc('pricing').update({ monthly_fee: payload.monthlyFee }));
    promises.push(dbFS.collection('orders').doc(payload.orderId).update({ monthly_qty: payload.monthlyQty }));
    return Promise.all(promises).then(() => {
      return Promise.resolve({ status: 'success' });
    });
  },
  getArtworkThumbnails({ commit, dispatch }, payload) {
    return Promise.all([
      dispatch('getOrderDataDoc', { orderId: payload.orderId, docName: 'delivery' }),
      dispatch('getOrderDataDoc', { orderId: payload.orderId, docName: 'product' })
    ])
      .then(results => {
        return Promise.resolve({
          ...(results[0].drop_artwork ? results[0].drop_artwork : ''),
          size: results[1].size_x && results[1].size_y ? `${results[1].size_x}x${results[1].size_y}` : ''
        });
      })
      .catch(error => {
        console.log('Getting Artwork Thumbnails failed:', error);
        return Promise.reject(error);
      });
  },
  updateOrderRootAndOrderData({ commit, dispatch }, payload) {
    const promises = [];
    promises.push(dbFS.collection('orders').doc(payload.orderId).collection('order_data').doc('pricing').update(payload.orderDataPricingChanges));
    promises.push(
      dispatch('getOrderDataDoc', { orderId: payload.orderId, docName: 'product' }).then(data => {
        let productData = data.product_data;
        productData.price = payload.orderDataProductChanges.price;
        productData.qty = payload.orderDataProductChanges.qty;
        dbFS.collection('orders').doc(payload.orderId).collection('order_data').doc('product').update({ product_data: productData });
      })
    );
    promises.push(
      dbFS
        .collection('orders')
        .doc(payload.orderId)
        .collection('order_data')
        .doc('delivery')
        .set(
          {
            new_movers_options: { ...payload.orderDataDeliveryChanges }
          },
          { merge: true }
        )
    );
    promises.push(dbFS.collection('orders').doc(payload.orderId).update(payload.orderRootChanges));
    return Promise.all(promises)
      .then(() => {
        return Promise.resolve({ status: 'success' });
      })
      .catch(error => {
        return Promise.reject({ status: 'error', message: error });
      });
  },
  getOrderLogsIds({ commit }, payload) {
    const selectedOrderList = payload.selectedOrderList;
    const promises = [];
    let orderLogsList = [];
    let orderLogItem = {};
    selectedOrderList.forEach((value, index) => {
      let orderLogFirebaseID = '';
      const prms = dbFS
        .collection('order_logs')
        .where('firebase_order_id', '==', value.firebase_order_id)
        .get()
        .then(querySnapshot => {
          if (querySnapshot.empty === false) {
            querySnapshot.forEach(doc => {
              orderLogFirebaseID = doc.id;
            });
            orderLogItem[value.firebase_order_id] = {
              orderLogFirebaseID
            };
            orderLogsList.push(orderLogItem);
            orderLogItem = {};
          }
        });
      promises.push(prms);
    });
    return Promise.all(promises).then(() => {
      return orderLogsList;
    });
  },
  // TODO: refactor this function under task: https://onebrand.atlassian.net/browse/AMPDEV-4039
  deleteOrders({ commit, dispatch, getters }, payload) {
    console.log('[ Delete orders ]: ', payload);
    const deletingParametersList = payload.deletingParametersList;
    const deleteMapReferences = payload.deleteMapReferences;
    let mapsListPathFiles = [];
    let ordersObject = [];
    let artworkList = [];
    let ordersIds = [];
    let mapsLists = [];
    const masterPromises = [];
    const clientAlgolia = payload.clientAlgolia;

    deletingParametersList.forEach((value, index) => {
      Object.entries(value).forEach(([keyV, valueV]) => {
        if (keyV === 'artworkReferences') {
          artworkList = valueV;
        }
        if (keyV === 'ordersIds') {
          ordersIds = valueV;
        }
        if (keyV === 'ordersObject') {
          ordersObject = valueV;
        }
        if (keyV === 'mapsReferences') {
          mapsLists = valueV;
        }
        if (keyV === 'mapsListPathFiles') {
          mapsListPathFiles = valueV;
        }
      });
    });

    // 1.Delete Artwork
    if (artworkList.length > 0) {
      const promisesArtwork = [];
      artworkList.forEach((value, index) => {
        Object.entries(value).forEach(([keyV, valueV]) => {
          const artwork = valueV.artwork;
          artwork.forEach((valueA, indexA) => {
            const artworkFileName = valueA.name;
            const artworkFilePath = valueA.path;
            const artworkPromise = dispatch('deleteFileFromStorage', { path: artworkFilePath, fileName: artworkFileName });
            promisesArtwork.push(artworkPromise);
          });
        });
      });
      const step1Promise = Promise.all(promisesArtwork).then(() => {
        console.log('1.== ALL Artwork files deleted!');
        commit('setDeleteReport', {
          deleteReport: {
            artworkFiles: 'success'
          }
        });
      });
      masterPromises.push(step1Promise);
    }

    // 1.1 Delete map references
    if (deleteMapReferences && mapsListPathFiles.length > 0) {
      let filesList = [];
      mapsListPathFiles.forEach((value, index) => {
        Object.entries(value).forEach(([keyO, valueO]) => {
          filesList.push(valueO.mapFilePath);
          filesList.push(valueO.JSONFilePath);
        });
      });
      console.log('1.1 -- filesList', filesList);
      const promisesMapFiles = [];
      filesList.forEach((value, index) => {
        const prms = dispatch('deleteFileFromStorage', { path: value, fileName: '' });
        promisesMapFiles.push(prms);
      });
      const step11Promise = Promise.all(promisesMapFiles).then(() => {
        console.log('1.1 == ALL Map related files deleted from Storage!');
        commit('setDeleteReport', {
          deleteReport: {
            mapFiles: 'success'
          }
        });
      });
      masterPromises.push(step11Promise);
    }

    // 2. Delete order data subcollections
    const promisesOrderData = [];
    ordersIds.forEach((value, index) => {
      const prms = dispatch('deleteOrderDataSubcollection', { fireabseOrderId: value }).then(response => {
        console.log(`2.-- order data from ${value} deleted`);
      });
      promisesOrderData.push(prms);
    });
    const step2Promise = Promise.all(promisesOrderData).then(() => {
      console.log('2.== ALL Order Data files deleted!');
      commit('setDeleteReport', {
        deleteReport: {
          orderData: 'success'
        }
      });
    });
    masterPromises.push(step2Promise);

    // 3. Delete order from Algolia
    let algoliaIndexList = [];
    ordersObject.forEach((value, index) => {
      const algoliaCustomIndex = `site_${value.site_db_id}_orders`;
      const orderId = value.firebase_order_id;
      algoliaIndexList.push({
        firebaseID: orderId,
        algoliaIndex: algoliaCustomIndex
      });
      algoliaIndexList.push({
        firebaseID: orderId,
        algoliaIndex: `orders${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`
      });
    });

    const promisesAlgolia = [];
    algoliaIndexList.forEach((value, index) => {
      const orderFirebaseID = value.firebaseID;
      const algoliaMapsIndex = value.algoliaIndex;
      promisesAlgolia.push(
        dispatch(
          'algolia/deleteOrderFromAlgoliaIndex',
          {
            indexName: algoliaMapsIndex,
            orderID: orderFirebaseID
          },
          { root: true }
        )
          .then(() => {
            console.log(`3.-- order ${orderFirebaseID} deleted from algolia Index ${algoliaMapsIndex}`);
            return Promise.resolve();
          })
          .catch(error => {
            console.log(`An error occured during deleting [${orderFirebaseID}] from algolia index ${algoliaMapsIndex} ERROR: ${error}`);
            return Promise.reject(error);
          })
      );
    });

    const step3Promise = Promise.all(promisesAlgolia).then(() => {
      console.log('3.==  ALL Data from Algolia was deleted!');
      commit('setDeleteReport', {
        deleteReport: {
          algoliaOrders: 'success'
        }
      });
    });
    masterPromises.push(step3Promise);

    // 4. Delete order from Firebase Firestore
    const promisesOrders = [];
    ordersIds.forEach((value, index) => {
      const prms = dbFS
        .collection('orders')
        .doc(value)
        .delete()
        .then(() => {
          console.log(`4.-- order ${value} deleted from Firebase `);
        })
        .catch(error => {
          console.log('Error on delete ' + value + ':' + error);
        });
      promisesOrders.push(prms);
    });
    const step4Promise = Promise.all(promisesOrders).then(() => {
      console.log('4.==  ALL Order From Firebase are deleted!');
      commit('setDeleteReport', {
        deleteReport: {
          ordersFirebaseFirestore: 'success'
        }
      });
    });
    masterPromises.push(step4Promise);

    return Promise.all(masterPromises)
      .then(() => {
        console.log('++++ EVERYTHING DELETED!');
        commit('setDeleteReport', {
          deleteReport: {
            allDataRelatedToOrdersDeleted: 'success'
          }
        });
        return true;
      })
      .catch(err => {
        console.log(`Deleting all catch some error: ${err}`);
        return Promise.reject();
      });
  },
  deleteFileFromStorage({ commit }, payload) {
    const path = payload.path;
    let fileName = payload.fileName;
    if (fileName.length === 0) {
      fileName = path.split('\\').pop().split('/').pop();
    }

    let storageRef = storage.ref(path);
    return storageRef
      .delete()
      .then(() => {
        console.log('1.1-- Map fileName', fileName + '  -- deleted!');
        return {
          status: 'success'
        };
      })
      .catch(error => {
        console.error('Error on delete: ', error);
        return {
          status: 'error',
          error
        };
      });
  },
  deleteOrderDataSubcollection({ commit, getters }, payload) {
    const fireabseOrderId = payload.fireabseOrderId;
    const promises = [];

    getters.getOrderDataList.forEach((value, index) => {
      const prms = dbFS.collection('orders').doc(fireabseOrderId).collection('order_data').doc(value).delete();
      promises.push(prms);
    });

    return Promise.all(promises)
      .then(() => {
        return {
          status: 'success'
        };
      })
      .catch(err => {
        console.log(`Delete order data for ${fireabseOrderId} | error: ${err}`);
        return Promise.reject();
      });
  },
  getMapReferences({ commit, getters, dispatch }, payload) {
    const response = payload.response;

    const mapIdsList = [];
    response.forEach((value, index) => {
      Object.entries(value).forEach(([keyO, valueO]) => {
        if (valueO.refValue) {
          mapIdsList.push(valueO.refValue);
        }
      });
    });
    console.log('mapIdsList', mapIdsList);
    const promises = [];
    let mapReferencesList = [];
    mapIdsList.forEach((value, index) => {
      const prms = dispatch('checkMapReferences', { firebaseID: value }).then(result => {
        if (result.status === 'success') {
          mapReferencesList.push({
            [value]: {
              mapImageName: result.mapRefList[0].mapImageName,
              mapFilePath: result.mapRefList[0].mapFilePath,
              JSONFilePath: result.mapRefList[1].JSONFilePath
            }
          });
        }
      });
      promises.push(prms);
    });
    return Promise.all(promises)
      .then(() => {
        return {
          mapReferencesList
        };
      })
      .catch(err => {
        console.log(`Error on all map references`);
        return Promise.reject();
      });
  },
  checkMapReferences({ commit, getters }, payload) {
    let mapRefList = [];
    const mapFireabseId = payload.firebaseID;
    return dbFS
      .collection('maps')
      .doc(payload.firebaseID)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.exists) {
          const data = querySnapshot.data();
          const mapImageName = data.mapImgName;
          const uploadedFileUrl = data.uploadedFileUrl;

          if (mapImageName !== undefined) {
            const mapImgUrlDecoded = decodeURIComponent(data.mapImgUrl);
            commit('setFirebaseUrl', mapImgUrlDecoded);
            const mapFilePath = getters.filterFirebaseUrl;
            mapRefList.push({
              mapImgUrlDecoded,
              mapImageName,
              mapFilePath,
              mapFireabseId
            });
          }

          if (uploadedFileUrl !== undefined) {
            const JSONUrlDecoded = decodeURIComponent(uploadedFileUrl);
            commit('setFirebaseUrl', JSONUrlDecoded);
            const JSONFilePath = getters.filterFirebaseUrl;
            mapRefList.push({
              JSONUrlDecoded,
              JSONFilePath
            });
          }
          return {
            status: 'success',
            mapRefList
          };
        } else {
          return {
            status: 'error',
            message: 'firebase ID:' + payload.firebaseID + ' does not exist!'
          };
        }
      });
  },
  getSiteFullNameById({ commit }, payload) {
    return siteSettingsService
      .getById(payload.siteId)
      .then(siteSettings => {
        return {
          name: siteSettings.site_name,
          url: siteSettings.site_url
        };
      })
      .catch(error => {
        console.log('getSiteFullNameById has faiiled', error);
      });
  },
  getOspreyOrderIdsByMapId({ commit, dispatch }, payload) {
    const mapId = payload.mapId;
    return dispatch(
      'algolia/getDataFromAlgolia',
      {
        indexName: `orders${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`,
        searchValue: `maps/${mapId}`
      },
      { root: true }
    )
      .then(content => {
        let ospreyOrderIds = [];
        if (content.hits.length) {
          ospreyOrderIds = content.hits.map(order => order.crm_order_id);
        }
        return ospreyOrderIds;
      })
      .catch(error => {
        console.log('Getting OspreyIds from Algolia Orders failed:', error);
        return error;
      });
  },
  getOspreyOrderIdsByListId({ commit, dispatch }, payload) {
    const listId = payload.listId;
    return dispatch(
      'algolia/getDataFromAlgolia',
      {
        indexName: `orders${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`,
        searchValue: `mailingLists/${listId}`
      },
      { root: true }
    )
      .then(content => {
        let ospreyOrderIds = [];
        if (content.hits.length) {
          ospreyOrderIds = content.hits.map(order => order.crm_order_id);
        }
        return ospreyOrderIds;
      })
      .catch(error => {
        console.log('Getting OspreyIds from Algolia Orders failed:', error);
        return error;
      });
  },
  triggerOrderDataDelivery({ commit }, payload) {
    dbFS
      .collection('orders')
      .doc(payload.orderId)
      .collection('order_data')
      .doc('delivery')
      .onSnapshot(doc => {
        const docData = doc.data();
        const deliveryDates = docData.delivery_dates ? docData.delivery_dates.sort((a, b) => (moment(b.date).unix() > moment(a.date).unix() ? 1 : -1)) : [];
        commit('setTriggeredOrderDataDelivery', deliveryDates);
      });
  },
  getProofUploadUrl({ commit }, payload) {
    return cloudFunctionsGet('/api/tools/proof-upload-url', { params: payload });
  },
  async isOrderCreatedOnTestSite({ dispatch }, payload) {
    return await dispatch('site/getTestSites', null, { root: true });
  },
  getAutomatedDropStatuses(_context) {
    return dropTrackerService.getAutomatedDropStatuses();
  },
  saveCustomerPhone({ commit }, payload) {
    return cloudFunctionsPost('/sms/save-phone', {
      firebase_order_id: payload.orderId,
      phone_number: payload.customerPhone,
      previous_phone_number: payload.previousPhone,
      user: payload.user
    });
  },
  saveSmsAllowedState({ commit }, payload) {
    return orderService.set(payload.order_id, { is_sms_allowed: payload.is_sms_allowed });
  },
  saveUnbouncePageId({ commit }, payload) {
    return orderService.set(payload.order_id, { unbounce_page_id: payload.unbounce_page_id });
  },
  saveCallboxPhoneNumber({ commit }, payload) {
    return orderService.set(payload.order_id, { callbox: { phone_number: payload.callbox_phone_number } });
  },
  saveCallboxSiteId({ commit }, payload) {
    return orderService.set(payload.order_id, { callbox: { site_id: payload.callbox_site_id } });
  },
  saveCuttlyURL({ commit }, payload) {
    return orderService.set(payload.order_id, { cuttly_url: payload.cuttly_url });
  },
  saveConvirzaGroupId({ commit }, payload) {
    return orderService.set(payload.order_id, { convirza_group_id: payload.convirza_group_id });
  },
  saveConvirzaStartDate({ commit }, payload) {
    return orderService.set(payload.order_id, { convirza_campaign_start_date: payload.convirza_campaign_start_date });
  },
  setUploadedLdpCsvStateInOrder({ commit }, payload) {
    return orderService.set(payload.id, { is_ldp_csv_uploaded_to_osprey: payload.isLdpCsvUploadedToOsprey }, { merge: true });
  },
  updateCustomerNameInOrder({ commit }, payload) {
    return orderService.set(payload.order_id, { first_name: payload.first_name, last_name: payload.last_name });
  },
  updateDeliveryDateInOsrpey({ commit }, payload) {
    return cloudFunctionsPut('/osprey/update-mail-order', payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
