import SalesforceModel from '../modules/salesforce';
import RestClient from '../modules/rest-client';

const salesforce_store = {
  state: {
    salesforceModel: null,
    opportunityUrl: 'https://onebrand.my.salesforce.com'
  },
  getters: {
    getOpportunityUrl: function (state) {
      return state.opportunityUrl;
    }
  },
  mutations: {
    setOpportunityUrl: function (state, url) {
      state.opportunityUrl = url;
    },
    setSalesforceModel: function (state, model) {
      state.salesforceModel = model;
    }
  },
  actions: {
    resetSalesforceStore: function (context) {
      return Promise.all([]);
    },
    getSalesforceModelInstace: function (context) {
      if (context.state.salesforceModel === null) {
        context.state.salesforceModel = new SalesforceModel(context);
      }
      return context.state.salesforceModel;
    },
    searchUserInSalesforce: function (context, payload) {
      return context.dispatch('getSalesforceModelInstace').then(function (model) {
        console.log('SEARCH: ', model);
        return model.search(payload);
      });
    },
    initApiAuth: function (context, payload) {
      const model = new SalesforceModel(context);
      model.apiAuth(payload);
      context.commit('setSalesforceModel', model);
    },
    createOpportunityInSalesforce: function (context, payload) {
      return context.dispatch('getSalesforceModelInstace').then(function (model) {
        return model.createOpportunity(payload);
      });
    }
  }
};

export { salesforce_store as default };
