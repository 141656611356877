import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class MapsService extends FirestoreModel {
  constructor() {
    super(collections.maps);
  }

  async getMapData(mapId) {
    try {
      const querySnapshot = await this.collectionRef.doc(mapId).get();
      const data = querySnapshot.data();
      return data;
    } catch (err) {
      console.log('getMapData has failed: ', err);
    }
  }

  async getMapCsvLink(firestoreMapId) {
    try {
      const querySnapshot = await this.collectionRef.doc(firestoreMapId).get();
      if (querySnapshot.exists) {
        const data = querySnapshot.data();
        return {
          status: 'success',
          csvLink: data.csvLink,
          crmCustomerId: data.customer !== undefined ? data.customer.crmCustomerId : '',
          uploadedFileUrl: data.uploadedFileUrl,
          siteFirebaseId: data.siteDbId
        };
      }
      return {
        status: 'error',
        message: 'Map do not exist!'
      };
    } catch (err) {
      console.log('getMapCsvLink has failed: ', err);
      return {
        status: 'error',
        message: err
      };
    }
  }

  async checkUploadedFileUrlInMaps(uploadedFileUrl, mapId) {
    try {
      const querySnapshot = await this.collectionRef.where('uploadedFileUrl', '==', uploadedFileUrl).get();

      return !((querySnapshot.docs.length === 1 && querySnapshot.docs[0].id === mapId) || querySnapshot.empty);
    } catch (err) {
      throw { error: err };
    }
  }

  async checkMapImgUrlInMaps(mapImgUrl, mapId) {
    try {
      const querySnapshot = await this.collectionRef.where('mapImgUrl', '==', mapImgUrl).get();

      return !((querySnapshot.docs.length === 1 && querySnapshot.docs[0].id === mapId) || querySnapshot.empty);
    } catch (error) {
      throw { error: error };
    }
  }
}

export default new MapsService();
