import 'firebase/firestore';
import SiteSettingsServiceModel from '../modules/site-settings.js'

const	state = {
	sitesRef: null,
	siteSettingsModel: null,
	siteSettings: null,
	webId: '', // wlos-1,
	userId: '', // 1
	userLevel: '', // administrator, client_admin, client_user
	basicApiUrl: '',
	basigApiAuth: '',
	clientCrmId: '',
	siteFirebaseId: null,
	customQuoteUrl: '',
	userData: null
}

const	getters = {
	getSitesRef: function (state) {
		return state.sitesRef
	},
	getSiteSettings: function (state) {
		return state.siteSettings
	},
	getWebId: function (state) {
		return state.webId
	},
	getUserId: function (state) {
		return state.userId
	},
	getUserLevel: function (state) {
		return state.userLevel
	},
	getBasicApiUrl: function (state) {
		return state.basicApiUrl
	},
	getBasicApiAuth: function (state) {
		return state.basigApiAuth
	},
	getClientCrmId: function (state) {
		return state.clientCrmId
	},
	getSiteFirebaseId: function (state) {
		return state.siteFirebaseId
	},
	getCustomQuoteUrl: function (state) {
		return state.customQuoteUrl
	}
}
const	mutations = {
	setSitesRef: function (state, ref) {
		state.sitesRef = ref
	},
	setSiteSettings: function (state, settings) {
		state.siteSettings = settings
	},
	setWebId: function (state, id) {
		state.webId = id
	},
	setUserId: function (state, id) {
		state.userId = id
	},
	setUserLevel: function (state, level) {
		state.userLevel = level
	},
	setBasicApiUrl: function (state, url) {
		state.basicApiUrl = url
	},
	setBasicApiAuth: function (state, auth) {
		state.basigApiAuth = auth
	},
	setClientCrmId: function (state, id) {
		state.clientCrmId = id
	},
	setSiteFirebaseId: function (state, details) {
		state.siteFirebaseId = details
	},
	setCustomQuoteUrl: function (state, quoteUrl) {
		state.customQuoteUrl = quoteUrl
	},
	setUserData: function (state, userData){
		state.userData = userData;
	}
}
const actions = {
	resetSiteSettingsStore: function (context) {
		context.commit('setSiteSettings', '')
		context.commit('setWebId', '')
		context.commit('setUserId', '')
		context.commit('setCustomQuoteUrl', '')
	},
	getSiteSettingsModelInstance: function (context) {
		if (context.state.siteSettingsModel === null) {
			context.state.siteSettingsModel = new SiteSettingsServiceModel(context)
		}
		return context.state.siteSettingsModel
	},
	getSiteSettings: function (context, payload) {
		return context.dispatch('loadSiteById', payload !== undefined ? payload : {siteId: context.getters.getSiteFirebaseId})
		// return context.dispatch('loadSiteById', context.getters.getSiteFirebaseId)
	},
	loadOboppSites:function(context, payload) {
		const sitesForQuoteSavingAccess = context.getters.getSiteSettings.sites_for_quote_saving_access
		const promises = []

		for (let i in sitesForQuoteSavingAccess) {
			promises.push(context.dispatch('getSiteSettings', {siteId: sitesForQuoteSavingAccess[i], isDataNeeded: true}))
		}

		return Promise.all(promises)
	},
	setSiteSettings: function ( { commit }, payload) {
		commit('setSiteSettings', payload)
	},
	setBasicApiAuth: function ( { commit }, payload) {
		commit('setBasicApiAuth', payload)
	},
	setBasicApiUrl: function ( { commit }, payload) {
		commit('setBasicApiUrl', payload)
	},
	setWebId: function ( { commit }, payload) {
		commit('setWebId', payload)
	},
	setCustomQuoteUrl: function ( { commit }, payload) {
		commit('setCustomQuoteUrl', payload)
	},
	setUserData: function({ commit }, payload){
		commit('setUserData', payload);
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
