<template>
  <v-container fluid transition="fade-transition">
    <confirmation-dialog
      :isConfirmationDialogOpened="isConfirmationDialogOpened"
      :action="confirmationDialogAction"
      @closeConfirmationDialog="closeConfirmationDialog"
      @actionConfirmed="duplicateConfirmed"
    >
    </confirmation-dialog>

    <confirm-delete-dialog :isDialogOpened="isDeleteDialogOpened" :itemNames="selectedRoute.name" @confirmDelete="deleteConfirmed" @closeDialog="closeDeleteDialog">
    </confirm-delete-dialog>

    <v-toolbar flat class="site-table--header site-table--lists">
      <v-row class="mb-0 mt-n3">
        <v-toolbar-title class="pt-1 headline grey--text text--darken-3 ml-n3">Routes</v-toolbar-title>
        <v-spacer />
        <v-col cols="12" sm="5" md="4" lg="4" xl="3" class="text-center">
          <v-text-field v-model="search" label="Search" class="mt-n3" append-icon="mdi-magnify" outlined dense hide-details></v-text-field>
        </v-col>
        <v-btn color="blue" class="ml-2 white--text mr-n1" min-width="200" @click="openNewRouteDialog()"><v-icon left>mdi-plus-circle</v-icon>Add New Route</v-btn>
      </v-row>
    </v-toolbar>
    <div class="mr-0 site-table mt-n5 ml-n1">
      <stamp-data-table
        :headers="headers"
        :data="routes"
        :actions="actions"
        :search="search"
        :is-loading="isRoutesTableLoading"
        :is-items-per-page-disabled="true"
        item-key="route_firebase_id"
        @viewRoute="viewRoute"
        @editRoute="editRoute"
        @duplicateRoute="duplicateRoute"
        @deleteRoute="deleteRoute"
      >
        <template #[`item.access_levels`]="{ item }">
          <span v-for="(level, index) in item.access_levels" :key="index">
            {{ level.name }} ({{ level.value }})
            <template v-if="index < item.access_levels.length - 1">,</template>
          </span>
        </template>
        <template #[`item.icon`]="{ item }">
          <v-icon>{{ item.icon }}</v-icon>
        </template>
        <template #[`item.route_firebase_id`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon color="amber darken-3" v-on="on" @click="copyItemToClipboard(item.route_firebase_id)">mdi-firebase</v-icon>
            </template>
            <span>Copy FirebaseID ( {{ item.route_firebase_id }} ) to clipboard</span>
          </v-tooltip>
        </template>
      </stamp-data-table>
    </div>
  </v-container>
</template>

<script>
import ConfirmDeleteDialog from '../../dialog/ConfirmDeleteDialog';
import ConfirmationDialog from '../../order-management/orders/orderDialogs/ConfirmationDialog';
import { mapGetters } from 'vuex';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: {
    'confirm-delete-dialog': ConfirmDeleteDialog,
    'confirmation-dialog': ConfirmationDialog,
    'stamp-data-table': DataTable
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Path', value: 'path', sortable: true },
        { text: 'Component', value: 'component', sortable: true },
        { text: 'Description', value: 'description', sortable: true },
        { text: 'Access Level', value: 'access_levels', sortable: true },
        { text: 'Icon', value: 'icon', sortable: false },
        { text: 'Firebase ID', value: 'route_firebase_id', align: 'center', sortable: false }
      ],
      actions: [
        { icon: 'mdi-eye', iconColor: 'blue darken-1', title: 'View Route', eventName: 'viewRoute' },
        { icon: 'mdi-pencil', iconColor: 'green darken-2', title: 'Edit Route', eventName: 'editRoute' },
        { icon: 'mdi-plus-box-multiple-outline', iconColor: 'grey darken-1', title: 'Duplicate Route', eventName: 'duplicateRoute' },
        { icon: 'mdi-delete-outline', iconColor: 'red darken-1', title: 'Delete Route', eventName: 'deleteRoute' }
      ],
      isDeleteDialogOpened: false,
      selectedRoute: {},
      isConfirmationDialogOpened: false,
      confirmationDialogAction: ''
    };
  },
  computed: {
    ...mapGetters('routes', {
      routes: 'getRoutes',
      isRoutesTableLoading: 'getRoutesLoadingState'
    })
  },
  created() {
    this.$store.dispatch('routes/getAllRoutes');
  },
  methods: {
    async openNewRouteDialog() {
      this.$store.commit('routes/setSelectedModel', []);
      await this.$store.dispatch('routes/initSelectedModel');
      this.$router.replace('/routes/new');
    },
    async showRoute(event, route) {
      await this.$store.dispatch('routes/initSelectedModel', route);
      this.$router.replace(`/routes/${event}/${route.route_firebase_id}`);
    },
    editRoute(route) {
      this.showRoute('edit', route);
    },
    viewRoute(route) {
      this.showRoute('view', route);
    },
    duplicateRoute(route) {
      this.confirmationDialogAction = `duplicate route ${route.name}`;
      this.selectedRoute.data = route;
      this.isConfirmationDialogOpened = true;
    },
    duplicateConfirmed() {
      this.$store.dispatch('showLoader', { message: `Duplicating route ${this.selectedRoute.data.name} in progress...` });
      this.selectedRoute.data.route_firebase_id = '';
      this.selectedRoute.data.name += ' Duplicated';

      console.log('[ Duplicate route data ]:', this.selectedRoute.data);

      this.$store
        .dispatch('routes/addNewRoute', this.selectedRoute)
        .then(result => {
          console.log('[ Duplicate route result ]:', result);
          this.showAlert('success', `${result}`);
        })
        .catch(error => {
          console.log('Duplicating route failed:', error);
          this.showAlert('error', error.message || error);
        })
        .finally(() => {
          this.selectedRoute = {};
          this.closeConfirmationDialog();
          this.$store.dispatch('hideLoader');
        });
    },
    deleteRoute(route) {
      this.selectedRoute = route;
      console.log('[ Delete route ]:', this.selectedRoute);
      this.isDeleteDialogOpened = true;
    },
    deleteConfirmed() {
      this.$store.dispatch('showLoader', { message: `Deleting route in progress...` });
      this.$store
        .dispatch('routes/deleteRoute', { route_firebase_id: this.selectedRoute.route_firebase_id })
        .then(() => this.showAlert('success', `Route [ ${this.selectedRoute.route_firebase_id} ] has been deleted successfully!`))
        .catch(error => this.showAlert('error', error.message || error))
        .finally(() => {
          this.closeDeleteDialog();
          this.selectedRoute = {};
          this.$store.dispatch('hideLoader');
        });
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpened = false;
    },
    closeConfirmationDialog() {
      this.isConfirmationDialogOpened = false;
      this.confirmationDialogAction = '';
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    }
  }
};
</script>
