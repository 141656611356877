<template>
  <v-switch v-model="inputValue" :rules="rules" :label="label" :value="value" :readonly="readonly" :required="required" class="mt-0" @change="sendToParent"></v-switch>
</template>

<script>
export default {
  props: ['label', 'value', 'rules', 'type', 'placeholder', 'required', 'readonly'],
  data() {
    return {
      inputValue: this.value
    };
  },
  methods: {
    sendToParent() {
      this.$emit('input', this.inputValue);
    }
  }
};
</script>
