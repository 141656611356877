<template>
  <v-dialog v-model="updateAllSettingsDialog" persistent max-width="850">
    <v-card>
      <v-card-title>
        <v-row class="justify-center mb-5">
          <v-tabs v-model="selectedStepManager" grow centered>
            <v-tab color="primary" :class="`${selectedStepManager === 0 ? 'primary--text grey lighten-2' : 'grey lighten-3'}`">
              <v-icon large color="primary" class="mr-2">mdi-update</v-icon>
              UPDATE STEPS
            </v-tab>
            <v-tab color="primary" :class="`${selectedStepManager === 1 ? 'primary--text grey lighten-2' : 'grey lighten-3'}`">
              <v-icon large color="primary" class="mr-2">mdi-plus</v-icon>
              <span class="d-none d-sm-flex">ADD NEW STEP</span>
            </v-tab>
          </v-tabs>
          <v-alert type="warning" dense outlined class="mt-2 mb-n3" dismissible>Reminder! Always make a security backup from the database before any action!</v-alert>
        </v-row>
      </v-card-title>

      <div v-if="selectedStepManager === 0">
        <v-card-text>
          <v-row class="justify-center">
            <v-col cols="12" sm="7">
              <v-select v-model="processSettings" :items="processSettingsItems" label="Select order flow(s)" multiple chips clearable></v-select>
            </v-col>
            <v-col cols="12" sm="7" class="mt-n6">
              <v-select v-model="stepName" :items="processStepNames" label="Select step" chips clearable></v-select>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="12" sm="8" class="mb-n2 mt-n5 offset-sm-1">
              <span>Modify all sites settings or select separately</span>
            </v-col>
            <v-col cols="12" sm="4" class="mt-n5 ml-4">
              <v-radio-group v-model="allSitesOrSeparate" :mandatory="false">
                <v-radio label="All sites" value="all"></v-radio>
                <v-radio label="Select sites by ID" value="separate"></v-radio>
              </v-radio-group>
            </v-col>
            <!-- Selected type ALL -->
            <v-col v-show="allSitesOrSeparate === 'all'" cols="12" sm="3" class="mt-n5">
              <v-switch v-model="addExceptionSwitch" color="cyan darken-2" label="Add exceptions"></v-switch>
              <div v-if="addExceptionSwitch" align="end" class="mt-n8 ml-10">
                <v-btn icon x-large @click="addExceptionSiteIdField()">
                  <v-icon color="primary">mdi-plus-box</v-icon>
                </v-btn>
                <v-btn icon x-large @click="removeExceptionSiteIdField()">
                  <v-icon color="primary">mdi-minus-box</v-icon>
                </v-btn>
              </div>
            </v-col>
            <!-- Selected type SELECT SEPARATE sites -->
            <v-col v-show="allSitesOrSeparate === 'separate'" cols="12" sm="3" align="end" class="mt-4">
              <v-btn icon x-large @click="addSiteIdField()">
                <v-icon color="primary">mdi-plus-box</v-icon>
              </v-btn>
              <v-btn icon x-large @click="removeSiteIdField()">
                <v-icon color="primary">mdi-minus-box</v-icon>
              </v-btn>
            </v-col>

            <v-col v-if="allSitesOrSeparate === 'all' && addExceptionSwitch" cols="12" sm="7" class="mt-n6 mb-n3">
              <v-row v-for="(n, index) in exceptionSitesCount" :key="index" class="justify-center ml-1 mr-0 my-n2">
                <v-text-field v-model="exceptions[index]" :name="`exceptionFieldName-${index}`" :label="`Exception siteID ${n}.`" outlined clearable dense> </v-text-field>
              </v-row>
            </v-col>
            <v-col v-if="allSitesOrSeparate === 'separate'" cols="12" sm="7" class="mt-n6 mb-n3">
              <v-row v-for="(n, index) in selectedSitesToAddNr" :key="index" class="justify-center ml-1 mr-0 my-n2">
                <v-text-field v-model="selectedSiteIds[index]" :name="`attributeFieldName-${index}`" :label="`Site ID ${n}.`" outlined dense clearable> </v-text-field>
              </v-row>
            </v-col>
          </v-row>

          <v-divider class="my-2"></v-divider>
          <v-row class="justify-end mr-4 mb-n8">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on"><v-icon color="blue">mdi-information</v-icon></v-btn>
              </template>
              <span>You can delete an object as well setting the value to null. Example: "attribute_name": null</span>
            </v-tooltip>
          </v-row>
          <v-alert v-if="updateFeedback" :type="feedbackType" dismissible transition="slide-y-transition" @input="alertFeedbackClosed()"> {{ feedbackMessage }} </v-alert>

          <v-card v-if="showReports" scrollable min-height="300" max-height="300" class="mb-3" style="overflow-y: auto">
            <v-container>
              <v-row class="justify-space-between mr-1 mb-1">
                <span class="subtitle-1 font-weight-regular pt-1 ml-4">Steps update reports</span>
                <v-btn icon @click="closeReports()"><v-icon>mdi-close</v-icon></v-btn>
              </v-row>
              <v-divider class="mb-4"></v-divider>
              <v-row class="justify-center">
                <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
              </v-row>
              <v-row v-for="(log, i) in updateLogs" :key="i" class="justify-center mt-n4">
                <v-col cols="1"><v-icon color="blue-grey darken-2">mdi-circle-medium</v-icon></v-col>
                <v-col cols="3">{{ log.siteId }} </v-col>
                <v-col cols="1"><v-icon>mdi-chevron-right</v-icon></v-col>
                <v-col cols="4"
                  ><span>{{ log.orderFlow }}</span
                  ><v-icon>mdi-chevron-right</v-icon><span>{{ log.stepName }}</span></v-col
                >
                <v-col cols="1"><v-icon>mdi-chevron-right</v-icon></v-col>
                <v-col cols="1">
                  <span v-if="log.status === 'success'"><v-icon color="green">mdi-check</v-icon></span>
                  <span v-else><v-icon color="red">mdi-close</v-icon></span>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <!-- Left side menu -->
          <v-row v-show="!isLoading">
            <v-col cols="12" sm="2" class="pl-6">
              <v-card class="pr-5 ml-n3 px-3 pt-1" max-width="200px" min-height="200px">
                <v-radio-group v-model="newAttrType" :mandatory="false">
                  <v-radio label="Object" value="jsonObj"></v-radio>
                  <v-radio label="Value" value="value"></v-radio>
                </v-radio-group>
                <v-divider class="ml-4"></v-divider>
                <v-row class="justify-center ml-0 my-1">
                  <v-btn v-if="newAttrType === 'value'" icon large @click="addNewAttrField()">
                    <v-icon color="primary">mdi-plus</v-icon>
                  </v-btn>
                </v-row>
                <v-divider v-if="newAttrType === 'value'" class="ml-4"></v-divider>
                <v-row class="justify-center ml-0 my-1">
                  <v-btn v-if="newAttrType === 'value'" icon large @click="removeNewAttrField()">
                    <v-icon color="primary">mdi-minus</v-icon>
                  </v-btn>
                </v-row>
              </v-card>
            </v-col>
            <!-- selected type is Value -->
            <v-col v-if="newAttrType === 'value'" cols="12" sm="10" class="mt-4">
              <v-row v-for="(n, index) in newAttrNrToAdd" :key="index" class="mt-n8 ml-2 justify-center">
                <v-col :key="`'attrName-'${index}`" cols="12" sm="5">
                  <v-text-field v-model="attributeName[index]" :name="`attributeFieldName-${index}`" :label="`${n}` + '. New attribute name'" clearable> </v-text-field>
                </v-col>
                <v-col :key="`'attrValue-'${index}`" cols="12" sm="5">
                  <v-text-field v-model="attributeValue[index]" :name="`attributeFieldValue-${index}`" :label="`${n}` + '. attribute value'" clearable> </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <!-- Selected type is Obj -->
            <v-col v-if="newAttrType === 'jsonObj'" cols="12" sm="10" class="mt-n3">
              <v-col cols="12" sm="10">
                <v-textarea v-model="attrObj" auto-grow outlined solo label="New attribute Object" @keydown.tab.prevent="tabber($event)"> </v-textarea>
              </v-col>
              <v-col v-if="isJsonValid" cols="12" sm="9" class="mx-2 mt-n8">
                <VueJsonPretty :data="JSON.parse(this.attrObj)"></VueJsonPretty>
              </v-col>
            </v-col>
          </v-row>
          <!-- <v-btn @click="getAllProcessTypes()"> Get types </v-btn> -->
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-row class="justify-center mx-1">
            <v-btn class="mx-3 mt-3" @click="closeDialog()"><v-icon color="primary" class="mr-2">mdi-exit-run</v-icon> Exit</v-btn>
            <v-btn color="grey lighten-4" class="ma-3 orange--text text--darken-4" @click="clearFields()">Clear fields</v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="!isSubmitButtonClickable || !isJsonValid" class="ma-3 white--text" color="blue-grey darken-3" @click="preProcessData()">Let's go</v-btn>
          </v-row>
        </v-card-actions>
      </div>

      <div v-if="selectedStepManager === 1">
        <v-card-text>
          <v-row class="justify-center mt-n10">
            <v-col cols="12" sm="7" md="7" lg="7" class="mt-n2">
              <v-select v-model="newStepOrderFlows" :items="processSettingsItems" label="Select order flow"></v-select>
            </v-col>
            <v-col cols="12" sm="7" md="7" lg="7" class="mt-n6">
              <v-select v-model="newStepIndexName" :items="processStepNames" label="Select AFTER which step you want to add the new step"></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="mt-n8 mr-n8">
              <v-radio-group v-model="newStepAllOrSelected" :mandatory="false">
                <v-radio label="All sites" value="all"></v-radio>
                <v-radio label="Select sites by ID" value="selected"></v-radio>
              </v-radio-group>
            </v-col>
            <!-- Selected type SELECT SEPARATE sites -->
            <div v-show="newStepAllOrSelected === 'selected'" align="end" class="mt-4 pl-n12">
              <v-btn icon x-large @click="addSiteIdFieldNewStep()">
                <v-icon color="primary">mdi-plus-box</v-icon>
              </v-btn>
              <v-btn icon x-large @click="removeSiteIdFieldNewStep()">
                <v-icon color="primary">mdi-minus-box</v-icon>
              </v-btn>
            </div>
          </v-row>

          <v-row class="justify-center">
            <v-col v-if="newStepAllOrSelected === 'selected'" cols="12" sm="7" class="mt-n6 mb-n3">
              <v-row v-for="(n, index) in newStepSelectedSitesNr" :key="index" class="justify-center ml-1 mr-0 my-n2">
                <v-text-field v-model="newStepSelectedSites[index]" :name="`attributeFieldName-${index}`" :label="`Site ID ${n}.`" outlined dense clearable> </v-text-field>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="newSiteFeedback">
            <v-col cols="12">
              <v-alert :type="newSiteFeedbackType" dismissible transition="slide-y-transition" class="mt-n5 mb-n2 mx-1" @input="newSiteFeedbackClosed()">
                {{ newSiteFeedbackMessage }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="showNewSiteReports" class="justify-center mb-3">
            <v-col cols="12" sm="10">
              <v-card scrollable min-height="300" max-height="300" style="overflow-y: auto">
                <v-container>
                  <v-row class="justify-space-between mr-1 mb-1">
                    <span class="subtitle-1 font-weight-regular pt-1 ml-4">New Step update reports</span>
                    <v-btn icon @click="closeReports()"><v-icon>mdi-close</v-icon></v-btn>
                  </v-row>
                  <v-divider class="mb-4"></v-divider>
                  <v-row class="justify-center">
                    <v-progress-linear v-if="isNewSiteLoading" indeterminate color="primary"></v-progress-linear>
                  </v-row>
                  <v-row v-for="(log, i) in updateNewSiteLogs" :key="i" class="justify-center mt-n4">
                    <v-col cols="1"><v-icon color="blue-grey darken-2">mdi-circle-medium</v-icon></v-col>
                    <v-col cols="4">{{ log.siteId }} </v-col>
                    <v-col cols="1"><v-icon>mdi-chevron-right</v-icon></v-col>
                    <v-col cols="2" class="text-center"
                      ><span>{{ log.orderFlow }}</span></v-col
                    >
                    <v-col cols="1"><v-icon>mdi-chevron-right</v-icon></v-col>
                    <v-col cols="1">
                      <span v-if="log.status === 'success'"><v-icon color="green">mdi-check</v-icon></span>
                      <span v-else><v-icon color="red">mdi-close</v-icon></span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="justify-center mx-6 mt-n3">
            <v-col cols="12" sm="10" md="10" lg="10">
              <span class="subtitle-1 font-weight-regular grey--text text--darken-2">New step data:</span>
              <v-textarea v-model="newSiteObj" auto-grow outlined solo label="New attribute Object" @keydown.tab.prevent="tabberNewSite($event)"> </v-textarea>
            </v-col>
          </v-row>
          <v-row class="justify-center mt-n8">
            <v-col v-if="isJsonValidNewSite" cols="12" sm="9">
              <VueJsonPretty :data="JSON.parse(newSiteObj)"></VueJsonPretty>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mx-3" @click="closeDialog()"><v-icon color="primary" class="mr-2">mdi-exit-run</v-icon>Exit</v-btn>
          <v-btn color="grey lighten-4" class="ma-3 orange--text text--darken-4" @click="clearFields()">Clear fields</v-btn>
          <v-spacer />
          <v-btn :disabled="!isAddNewStepBtnClickable || !isJsonValidNewSite" class="ma-3 white--text" color="blue-grey darken-3" @click="prepareToUpdate()"> DO IT! </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import orderSettingsService from '../../../services/orderSettingsService.js';

export default {
  components: {
    VueJsonPretty
  },
  props: ['value'],
  data() {
    return {
      updateAllSettingsDialog: this.value,
      processSettings: [],
      processSettingsItems: [
        'availability',
        'custom',
        'customEstimation',
        'eddm',
        'eddmPrintOnly',
        'inserts',
        'map',
        'newMovers',
        'printprepship',
        'saturation',
        'targeted',
        'targetedLetter',
        'template'
      ],
      processStepNames: [
        'artwork',
        'audience-options',
        'bindery-option',
        'call-tracking-service',
        'categories',
        'custom-mailing-list',
        'delivery',
        'estimate-id',
        'list-processing',
        'mailing-list-options',
        'map-list-options',
        'map-selector',
        'map-tool',
        'map-types',
        'monthly-budget',
        'postage',
        'products',
        'proof-uploader',
        'quantity',
        'save-map',
        'size-selector',
        'stock-coating',
        'templates',
        'campaign-name',
        'pieces-number',
        'save-map-qty',
        'shipping-options',
        'special-instructions',
        'subscription-selector'
      ],
      stepName: [],
      newAttrType: 'jsonObj',
      attributeName: {},
      attributeValue: {},
      newAttrNrToAdd: 1,
      attrObj: '{\n\t"attribute_name":"value"\n}',
      exceptionSitesCount: 1,
      exceptions: {},
      addExceptionSwitch: false,
      allSitesOrSeparate: 'all',
      selectedSitesToAddNr: 1,
      selectedSiteIds: {},
      updateFeedback: false,
      feedbackType: 'info',
      feedbackMessage: '',
      isLoading: false,
      selectedStepManager: 0,
      newStepOrderFlows: '',
      newStepAllOrSelected: 'selected',
      newStepSelectedSites: {},
      newStepSelectedSitesNr: 1,
      newSiteObj: '{\n\t"stepId":""\n}',
      jsonNewSite: {},
      isNewSiteLoading: false,
      newSiteFeedback: false,
      newSiteFeedbackType: 'info',
      newSiteFeedbackMessage: '',
      newStepIndexName: '',
      showReports: false,
      updateLogs: [],
      showNewSiteReports: false,
      updateNewSiteLogs: []
    };
  },
  computed: {
    isJsonValid() {
      try {
        JSON.parse(this.attrObj);
      } catch (error) {
        return false;
      }
      return true;
    },
    isSubmitButtonClickable() {
      return Object.keys(this.processSettings).length && this.stepName;
    },
    isAddNewStepBtnClickable() {
      return Object.keys(this.newStepOrderFlows).length && Object.keys(this.newStepIndexName).length;
    },
    isJsonValidNewSite() {
      try {
        JSON.parse(this.newSiteObj);
      } catch (error) {
        return false;
      }
      return true;
    }
  },
  watch: {
    value(value) {
      this.updateAllSettingsDialog = this.value;
    }
  },
  methods: {
    closeDialog() {
      this.clearFields();
      this.$emit('closeUpdateAllSettings');
    },
    addNewAttrField() {
      this.newAttrNrToAdd++;
    },
    removeNewAttrField() {
      if (this.newAttrNrToAdd <= 0) {
        return;
      }
      this.newAttrNrToAdd--;
      this.attributeName[Object.keys(this.attributeName)[Object.keys(this.attributeName).length - 1]] = null;
      this.attributeValue[Object.keys(this.attributeValue)[Object.keys(this.attributeValue).length - 1]] = null;
    },
    addSiteIdField() {
      this.selectedSitesToAddNr++;
    },
    removeSiteIdField() {
      if (this.selectedSitesToAddNr <= 0) {
        return;
      }
      this.selectedSitesToAddNr--;
      this.selectedSiteIds[Object.keys(this.selectedSiteIds)[Object.keys(this.selectedSiteIds).length - 1]] = null;
    },
    addSiteIdFieldNewStep() {
      this.newStepSelectedSitesNr++;
    },
    removeSiteIdFieldNewStep() {
      if (this.newStepSelectedSitesNr <= 0) {
        return;
      }
      this.newStepSelectedSitesNr--;
      this.newStepSelectedSites[Object.keys(this.newStepSelectedSites)[Object.keys(this.newStepSelectedSites).length - 1]] = null;
    },
    addExceptionSiteIdField() {
      this.exceptionSitesCount++;
    },
    removeExceptionSiteIdField() {
      if (this.exceptionSitesCount <= 0) {
        return;
      }
      this.exceptionSitesCount--;
      this.exceptions[Object.keys(this.exceptions)[Object.keys(this.exceptions).length - 1]] = null;
    },
    preProcessData() {
      this.updateLogs = [];
      this.showReports = true;
      this.isLoading = true;

      if (this.allSitesOrSeparate === 'separate' && Object.keys(this.selectedSiteIds).length < 1) {
        this.setAndShowFeedback('error', 'You need to add at least one site Id!');
      }

      if (this.newAttrType === 'value') {
        this.addInputFieldValues();
      } else if (this.newAttrType === 'jsonObj') {
        this.addObjectValues();
      }
    },

    addInputFieldValues() {
      let objectToAdd = {};
      Object.values(this.attributeValue).forEach((value, value_key) => {
        if (value.includes('true') || value.includes('false')) {
          this.attributeValue[value_key] = JSON.parse(value);
        } else if (!isNaN(value)) {
          this.attributeValue[value_key] = value.includes('.') ? parseFloat(value) : parseInt(value);
        } else {
          this.attributeValue[value_key] = value === 'null' ? null : value.toString().replace(/['"]+/g, '');
        }
      });
      Object.values(this.attributeName).forEach((name, key) => {
        this.attributeName[key] = name.replace(/['"]+/g, '');
      });

      Object.values(this.attributeName).forEach((name, key) => {
        objectToAdd[this.attributeName[key]] = this.attributeValue[key];
      });

      console.log('Fields to update => ', objectToAdd);
      this.decideUpdateMethod(objectToAdd);
    },

    addObjectValues() {
      const objectToAdd = JSON.parse(this.attrObj);
      console.log('Prepared object to add -> ', objectToAdd);
      this.decideUpdateMethod(objectToAdd);
    },

    decideUpdateMethod(objectToAdd) {
      if (this.processSettings.length && this.stepName && objectToAdd && this.allSitesOrSeparate === 'separate') {
        this.updateSelectedSites(this.processSettings, this.stepName, this.selectedSiteIds, objectToAdd);
      }

      if (this.processSettings.length && this.stepName && objectToAdd && this.allSitesOrSeparate === 'all') {
        this.updateAllSites(this.processSettings, this.stepName, this.exceptions, objectToAdd);
      }
    },

    async updateAllSites(orderFlowName, stepName, exceptions, preparedObj) {
      let promises = [];
      try {
        let processes = await orderSettingsService.getAll({ isIdReturned: true, isArray: true });
        processes.forEach(setting => {
          const siteId = setting._id;
          if (!Object.values(exceptions).includes(siteId)) {
            orderFlowName.forEach(processType => {
              let allSteps = setting.orderProcesses[processType].steps;
              let isStepFound = false;
              allSteps.forEach((stepData, key) => {
                if (stepName === stepData.stepId) {
                  isStepFound = true;
                  let objectToUpdate = this.processDataForUpdate(stepData, preparedObj);
                  allSteps[key] = objectToUpdate;
                  promises.push(
                    orderSettingsService
                      .update(siteId, {
                        [`orderProcesses.${processType}.steps`]: allSteps
                      })
                      .then(() => {
                        return { status: 'success', siteId: siteId, orderFlow: processType, stepName: stepData.stepId };
                      })
                      .catch(error => {
                        console.log(`ERROR updating site: ${siteId}`);
                        console.error(error);
                        return { status: 'error', siteId: siteId, orderFlow: processType, stepName: stepData.stepId };
                      })
                  );
                }
              });
              if (!isStepFound) {
                promises.push({ status: 'error', siteId: siteId, orderFlow: processType, stepName: 'MISSING STEP' });
              }
            });
          }
        });
      } catch (error) {
        console.log('Error!', error);
        this.setAndShowFeedback('error', error);
      }

      try {
        let data = await Promise.all(promises);
        data.forEach(res => {
          this.updateLogs.push({
            siteId: res.siteId,
            orderFlow: res.orderFlow,
            stepName: res.stepName,
            status: res.status === 'success' ? 'success' : 'error'
          });
        });
        return Promise.resolve();
      } catch (error) {
        console.log('Error occurred:', error);
        return Promise.reject();
      } finally {
        this.isLoading = false;
      }
    },
    async updateSelectedSites(orderFlowName, stepName, selectedSiteIds, preparedObj) {
      try {
        Object.keys(selectedSiteIds).forEach(async key => {
          const siteId = selectedSiteIds[key];
          const { orderProcesses: siteData } = await orderSettingsService.getById(siteId);
          orderFlowName.forEach(async processType => {
            const allSteps = siteData[processType].steps;
            let isStepFound = false;
            allSteps.forEach(async (stepData, key) => {
              if (stepName === stepData.stepId) {
                isStepFound = true;
                let objectToUpdate = this.processDataForUpdate(stepData, preparedObj);
                allSteps[key] = objectToUpdate;
                try {
                  await orderSettingsService.update(siteId, { [`orderProcesses.${processType}.steps`]: allSteps });
                  this.updateLogs.push({ siteId, orderFlow: processType, stepName: stepData.stepId, status: 'success' });
                } catch (error) {
                  console.log('ERROR updating site:', siteId);
                  this.updateLogs.push({ siteId, orderFlow: processType, stepName: stepData.stepId, status: 'error' });
                }
              }
            });
            if (!isStepFound) {
              this.setAndShowFeedback('warning', `Site: [${siteId}] does not contain any selected step!`);
            }
          });
        });
      } catch (error) {
        console.log('Error occurred:', error);
        this.setAndShowFeedback('error', error);
      } finally {
        this.isLoading = false;
      }
    },
    processDataForUpdate(stepData, preparedObj) {
      try {
        let updatedData = {};

        for (let propertyName in stepData) {
          if (preparedObj.hasOwnProperty(propertyName)) {
            updatedData[propertyName] = preparedObj[propertyName];
          } else {
            updatedData[propertyName] = stepData[propertyName];
          }
        }

        for (let newPropertyName in preparedObj) {
          if (!updatedData.hasOwnProperty(newPropertyName)) {
            updatedData[newPropertyName] = preparedObj[newPropertyName];
          }
        }

        console.log('UPDATED VALUE ::: ', updatedData);
        return updatedData;
      } catch (error) {
        console.error('Error processing data:', error);
        throw error;
      }
    },
    prepareToUpdate() {
      this.updateNewSiteLogs = [];
      this.showNewSiteReports = true;
      this.isNewSiteLoading = true;

      if (this.newStepAllOrSelected === 'selected' && Object.keys(this.newStepSelectedSites).length < 1) {
        this.setAndShowFeedbackNewSite('error', 'You need to add at least one site Id!');
      }

      const preparedObjectToAdd = JSON.parse(this.newSiteObj);

      if (this.newStepOrderFlows.length && preparedObjectToAdd && this.newStepAllOrSelected === 'selected') {
        this.addNewStepToSelectedSite(this.newStepOrderFlows, this.newStepSelectedSites, this.newStepIndexName, preparedObjectToAdd);
      }

      if (this.newStepOrderFlows.length && preparedObjectToAdd && this.newStepAllOrSelected === 'all') {
        this.addNewStepToAllSites(this.newStepOrderFlows, this.newStepIndexName, preparedObjectToAdd);
      }
    },

    async addNewStepToSelectedSite(processType, selectedSiteIds, indexName, preparedObj) {
      if (!preparedObj?.stepId) {
        console.error('The new step should contain a stepId!');
        return;
      }
      console.log('Object to Add: ', preparedObj);
      let promises = [];
      Object.values(selectedSiteIds).forEach(siteId => {
        promises.push(
          new Promise(resolve => {
            orderSettingsService.getSteps(siteId, processType).then(siteData => {
              const indexNr = siteData.findIndex(value => value.stepId === indexName);

              if (indexNr === -1) {
                console.log(`Step with stepId ${indexName} not found on site:${siteId}.`);
                resolve({ status: 'error', siteId: siteId, orderFlow: processType });
                return;
              }

              const isStepInsertable = !siteData.some(value => value.stepId === preparedObj.stepId);

              if (!isStepInsertable) {
                console.log(`[ ${siteId} / ${processType} ] already has a ${preparedObj.stepId} step ... `);
                resolve({ status: 'error', siteId: siteId, orderFlow: processType });
                return;
              }

              const beforeSelectedIndex = siteData.slice(0, indexNr + 1);
              const afterSelectedIndex = siteData.slice(indexNr + 1);
              const finalSteps = [...beforeSelectedIndex, preparedObj, ...afterSelectedIndex];

              orderSettingsService
                .update(siteId, { [`orderProcesses.${processType}.steps`]: finalSteps })
                .then(() => {
                  resolve({ status: 'success', siteId, orderFlow: processType });
                })
                .catch(error => {
                  console.log('Error: ', error);
                  resolve({ status: 'error', siteId: siteId, orderFlow: processType });
                });
            });
          })
        );
      });
      return Promise.all(promises)
        .then(data => {
          if (data) {
            data.forEach(res => {
              this.updateNewSiteLogs.push({
                siteId: res.siteId,
                orderFlow: res.orderFlow,
                status: res.status === 'success' ? 'success' : 'error'
              });
            });
          }
          this.setAndShowFeedbackNewSite('success', 'Steps updated successfully!');
          return Promise.resolve();
        })
        .catch(error => {
          this.setAndShowFeedbackNewSite('error', `Error occured during updating steps: ${error}`);
        });
    },
    addNewStepToAllSites(processType, indexName, preparedObj) {
      console.log('ProcessType:', processType);
      console.log('Index Name:', indexName);
      console.log('Object to Add: ', preparedObj);
      let promises = [];
      orderSettingsService.getAll({ isIdReturned: true, isArray: true }).then(async orderSettings => {
        orderSettings.forEach(setting => {
          promises.push(
            new Promise(resolve => {
              const siteId = setting._id;
              const siteData = setting;
              const orderProcess = siteData.orderProcesses[processType];

              if (!orderProcess) {
                console.log(`Order process ${processType} not found on site:${siteId}.`);
                resolve({ status: 'error', siteId: siteId, orderFlow: processType });
                return;
              }

              const stepsData = orderProcess.steps;
              const indexNr = stepsData.findIndex(value => value.stepId === indexName);

              if (indexNr === -1) {
                console.log(`Step with stepId ${indexName} not found on site:${siteId}.`);
                resolve({ status: 'error', siteId: siteId, orderFlow: processType });
                return;
              }

              const isStepInsertable = !stepsData.some(value => value.stepId === preparedObj.stepId);

              if (!isStepInsertable) {
                console.log(`[ ${siteId} / ${processType} ] already has a ${preparedObj.stepId} step ... `);
                resolve({ status: 'error', siteId: siteId, orderFlow: processType });
                return;
              }

              const beforeSelectedIndex = stepsData.slice(0, indexNr + 1);
              const afterSelectedIndex = stepsData.slice(indexNr + 1);
              const finalSteps = [...beforeSelectedIndex, preparedObj, ...afterSelectedIndex];

              orderSettingsService
                .update(siteId, { [`orderProcesses.${processType}.steps`]: finalSteps })
                .then(() => {
                  resolve({ status: 'success', siteId, orderFlow: processType });
                })
                .catch(error => {
                  console.log('Error: ', error);
                  resolve({ status: 'error', siteId: siteId, orderFlow: processType });
                });
            })
          );
        });
        return Promise.all(promises)
          .then(data => {
            if (data) {
              data.forEach(res => {
                this.updateNewSiteLogs.push({
                  siteId: res.siteId,
                  orderFlow: res.orderFlow,
                  status: res.status === 'success' ? 'success' : 'error'
                });
              });
            }
            this.setAndShowFeedbackNewSite('success', 'All sites have been updated!');
            return Promise.resolve();
          })
          .catch(error => {
            console.log('Updating steps failed:', error);
            this.setAndShowFeedbackNewSite('error', 'Error occurred during updating steps!');
            return Promise.reject();
          });
      });
    },
    setAndShowFeedback(type, message) {
      this.isLoading = false;
      this.feedbackType = type;
      this.feedbackMessage = message;
      this.updateFeedback = true;
    },
    setAndShowFeedbackNewSite(type, message) {
      this.isNewSiteLoading = false;
      this.newSiteFeedbackType = type;
      this.newSiteFeedbackMessage = message;
      this.newSiteFeedback = true;
    },
    alertFeedbackClosed() {
      this.updateFeedback = false;
      this.feedbackType = 'info';
      this.feedbackMessage = '';
    },
    newSiteFeedbackClosed() {
      this.newSiteFeedback = false;
      this.newSiteFeedbackType = 'info';
      this.newSiteFeedbackMessage = '';
    },
    tabber(event) {
      let text = this.attrObj,
        originalSelectionStart = event.target.selectionStart,
        textStart = text.slice(0, originalSelectionStart),
        textEnd = text.slice(originalSelectionStart);
      this.attrObj = `${textStart}\t${textEnd}`;
      event.target.value = this.attrObj; // required to make the cursor stay in place.
      event.target.selectionEnd = event.target.selectionStart = originalSelectionStart + 1;
    },
    tabberNewSite(event) {
      let text = this.newSiteObj,
        originalSelectionStart = event.target.selectionStart,
        textStart = text.slice(0, originalSelectionStart),
        textEnd = text.slice(originalSelectionStart);
      this.newSiteObj = `${textStart}\t${textEnd}`;
      event.target.value = this.newSiteObj; // required to make the cursor stay in place.
      event.target.selectionEnd = event.target.selectionStart = originalSelectionStart + 1;
    },
    closeReports() {
      this.showReports = false;
      this.updateLogs = [];
      this.showNewSiteReports = false;
      this.updateNewSiteLogs = [];
    },
    clearFields() {
      this.processSettings = [];
      this.stepName = '';
      this.exceptions = [];
      this.attrObj = '{\n\t"attribute_name":"value"\n}';
      this.attributeName = {};
      this.attributeValue = {};
      this.newAttrNrToAdd = 1;
      this.selectedSiteIds = {};
      this.newStepOrderFlows = [];
      this.newStepIndexName = [];
      this.newStepSelectedSites = {};
      this.newStepSelectedSitesNr = 1;
      this.newSiteObj = '{\n\t"stepId":""\n}';
    },
    getAllProcessTypes() {
      orderSettingsService.getAll({ isArray: true }).then(orderSettings => {
        orderSettings.forEach(orderSetting => {
          const temp = orderSetting;
          const orderFlowName = Object.keys(temp);
          delete orderFlowName[2];
          let allStepNames = [];

          orderFlowName.forEach((name, key) => {
            const allSteps = temp[name].steps;
            allSteps.forEach((stepObj, key) => {
              if (!allStepNames.includes(stepObj.stepId)) {
                allStepNames.push(stepObj.stepId);
              }
            });
          });
          console.log('allStepNames = ', allStepNames);
        });
      });
    }
  }
};
</script>
<style scoped>
.scroll {
  overflow-y: scroll;
}
</style>
