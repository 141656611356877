import siteSettingsService from '../../services/siteSettingsService.js';
import orderSettingsService from '../../services/orderSettingsService.js';
import { cloudFunctionsPost } from '../../modules/cloud-function-wrapper.js';

const databaseStore = {
  state: {
    orderSettingsRef: null,
    orderSettings: null,
    defaultOrderSettings: null
  },
  getters: {
    getOrderSettingsRef(state) {
      return state.orderSettingsRef;
    },
    getOrderSettings(state) {
      return state.orderSettings;
    },
    getDefaultOrderSettings(state) {
      return state.defaultOrderSettings;
    }
  },
  mutations: {
    setOrderSettingsRef(state, ref) {
      state.orderSettingsRef = ref;
    },
    setOrderSettings(state, orderSettings) {
      state.orderSettings = orderSettings;
    },
    setStepsInOrderSettings(state, payload) {
      state.orderSettings[payload.selectedSite].orderProcesses[payload.selectedOrder].steps = payload.steps;
    },
    setDefaultOrderSettings(state, defaultOrderSettings) {
      state.defaultOrderSettings = defaultOrderSettings;
    }
  },
  actions: {
    initializeDatabase({ commit }) {
      console.log('Initialize Firebase database');
      commit('setOrderSettingsRef', orderSettingsService);
    },
    async loadOrderSettingsFromDb({ commit }, payload) {
      return orderSettingsService.getAll().then(data => {
        if (data === null) {
          console.log('Load order settings has failed: ');
          return;
        }
        console.log('Load order settings has succeeded: ', data);
        commit('setOrderSettings', data);
        commit('setDefaultOrderSettings', data.default);
      });
    },
    async loadOrderSettingById({ commit }, payload) {
      return Promise.all([orderSettingsService.getById(payload), orderSettingsService.getById('default')])
        .then(([data, defaultSettings]) => {
          if (data === null) {
            console.log('Load order settings has failed: ');
            return;
          }
          console.log('Load order settings has succeeded: ', [data, defaultSettings]);
          commit('setOrderSettings', { [payload]: data, default: defaultSettings });
          commit('setDefaultOrderSettings', defaultSettings);
        })
        .catch(error => {
          console.log('loadOrderSettingById has failed', error);
          return Promise.reject(error);
        });
    },
    async loadSitesFromDb({ commit }, payload) {
      try {
        const siteSettings = await siteSettingsService.getAll({ isArray: true });
        console.log('Load sites settings has succeeded: ', siteSettings);

        const filteredsiteSettings =
          process.env.VUE_APP_NODE_ENV === 'production' ? siteSettings.filter(site => site.site_type === 'live') : siteSettings.filter(site => site.site_type === 'test');

        commit('site/setSites', filteredsiteSettings);
      } catch (error) {
        console.log('Load sites settings has failed: ', error);
      }
    },
    resetAllOrderSettings({ commit, getters }, payload) {
      const defaultOrderSettings = getters.getDefaultOrderSettings;
      return orderSettingsService
        .set(payload, defaultOrderSettings)
        .then(data => {
          console.log('Reset Order Settings to default has succeeded: ', data);
        })
        .catch(error => {
          console.log('Reset Order Settings to default has failed: ', error);
        });
    },
    searchCustomer({ commit }, config) {
      console.log('SearchCustomer Config: ', config);
      return cloudFunctionsPost('/searchCustomer', config)
        .then(result => {
          console.log('SearchCustomer has succeeded: ', result);
          commit('setCustomerList', result.data.records);
          return Promise.resolve(result.data);
        })
        .catch(error => {
          console.log('SearchCustomer has failed: ', error);
          return Promise.reject(error);
        });
    },
    setOrdersAvailability({ commit }, payload) {
      return orderSettingsService.update(payload.site, { [`orderProcesses.${payload.order}.available`]: payload.available });
    },
    setOrderSequence({ commit }, payload) {
      return orderSettingsService.set(payload.site, { orderSequence: payload.sequence });
    }
  }
};

export { databaseStore as default };
