<template>
  <v-container fluid>
    <v-row class="justify-space-between">
      <v-col cols="12" lg="4">
        <dynamic-component :modelMapping="parentModel" :isEditEnabled="isEditEnabled" class="mb-n2" @input="getRootDataFieldValues" @isValid="enableSaveButton"></dynamic-component>
      </v-col>
      <v-col cols="12" lg="8">
        <v-row>
          <v-col v-for="(childModel, childIndex) in Object.values(childModels)" :key="childIndex" cols="12" md="6" xl="4">
            <v-row :key="childIndex" class="mt-n6" no-gutters>
              <p class="subtitle-1">{{ childModel.label }}</p>
              <v-col :key="childIndex" cols="12">
                <dynamic-component
                  :modelMapping="childModel.fields"
                  :isEditEnabled="isEditEnabled"
                  class="mb-n2"
                  @input="getSubobjectFieldValues(childModel.model, $event)"
                  @isValid="enableSaveButton"
                ></dynamic-component>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider v-if="isEditEnabled" class="my-2"></v-divider>
    <v-row v-if="isEditEnabled" class="mt-3 ml-1">
      <v-btn color="primary" class="subtitle-1 font-weight-medium text-capitalize px-5" :loading="isLoading" :disabled="!isFormValid" small @click="saveData()">Save</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import dynamicComponent from './inputComponents/DynamicComponent';

export default {
  components: { 'dynamic-component': dynamicComponent },
  props: ['modelData', 'isEditEnabled', 'isLoading'],
  data() {
    return {
      parentModel: [],
      parentPreparedObject: {},
      childModels: [],
      childPreparedObject: {},
      isFormValid: false
    };
  },
  watch: {
    modelData(model) {
      if (model) {
        this.parentModel = model;
        this.parentModel.forEach(({ label, model, value, input_type }) => {
          if (input_type === 'object') {
            this.childModels[model] = { label, model, fields: value };
            this.childPreparedObject[model] = {};
          }
        });
      } else {
        this.parentModel = [];
        this.childModels = [];
        this.childPreparedObject = {};
      }
    }
  },
  methods: {
    getSubModelDefaultValues(editedModel) {
      let result = {};
      Object.values(this.childModels).forEach(subModel => {
        if (subModel.model === editedModel) {
          subModel?.fields?.map(({ model, value, type }) => {
            result[model] = type === 'number' ? Number(this.childPreparedObject?.[editedModel]?.[model] ?? 0) ?? value : this.childPreparedObject?.[editedModel]?.[model] ?? value;
          });
        }
      });
      return result;
    },
    getRootDataFieldValues(data) {
      Object.entries(data).forEach(([propName, value]) => {
        this.parentPreparedObject[propName] = value;
      });
    },
    getSubobjectFieldValues(parentPropertyName, data) {
      Object.entries(data).forEach(([propName, value]) => {
        this.childPreparedObject[parentPropertyName][propName] = value;
      });
    },
    saveData() {
      let finalObjectToSave = {};
      Object.values(this.modelData).forEach(item => {
        finalObjectToSave[item.model] =
          item.type === 'number'
            ? this.parentPreparedObject[item.model]
              ? Number(this.parentPreparedObject[item.model])
              : item.value
            : item.input_type === 'object'
            ? this.getSubModelDefaultValues(item.model)
            : this.parentPreparedObject[item.model] ?? item.value;
      });

      console.log('[ Final Object to Save ]:', finalObjectToSave);
      this.$emit('saveModel', finalObjectToSave);

      this.parentPreparedObject = {};
      this.childPreparedObject = {};
    },
    enableSaveButton(isValid) {
      this.isFormValid = isValid;
    }
  }
};
</script>
