import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class OrderService extends FirestoreModel {
  constructor() {
    super(collections.orders);
    this._orderDataCollectionName = 'order_data';
    this.orderDataDocs = {
      delivery: 'delivery',
      additional_services: 'additional_services',
      artwork: 'artwork',
      assets: 'assets',
      customer: 'customer',
      mailing: 'mailing',
      pricing: 'pricing',
      product: 'product'
    };
  }

  async getOrderData(orderId) {
    try {
      const snapshot = await this.collectionRef.doc(orderId).collection(this._orderDataCollectionName).get();
      const data = {};
      snapshot.forEach(doc => {
        data[doc.id] = doc.data();
      });
      return data;
    } catch (err) {
      console.log('getOrderData has failed: ', err);
    }
  }

  async updateOrderData(orderId, dataDoc, payload) {
    try {
      return await this.collectionRef.doc(orderId).collection(this._orderDataCollectionName).doc(dataDoc).update(payload);
    } catch (error) {
      console.log('updateOrderData has failed: ', error);
    }
  }

  async listOrderDataArtwork(documentId) {
    try {
      const querySnapshot = await this.collectionRef.doc(documentId).collection(this._orderDataCollectionName).doc(this.orderDataDocs.assets).get();
      const artwork = querySnapshot.exists ? querySnapshot.data().artwork : [];
      return {
        status: 'success',
        artwork
      };
    } catch (err) {
      console.log('updateOrderData has failed: ', err);
    }
  }

  async checkMapInOrdersRef(siteDbId, mapId) {
    try {
      const querySnapshot = await this.collectionRef.where('site_db_id', '==', siteDbId).get();
      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        if (data.references && data.references.map_id) {
          const localMapId = data.references.map_id.split('/')[1];
          if (localMapId && localMapId.toString() === mapId) {
            return {
              isRelatedToOrder: true,
              orderFirebaseID: doc.id
            };
          }
        }
      }
      return { isRelatedToOrder: false };
    } catch (err) {
      throw { error: err };
    }
  }

  async checkListInOrdersRef(siteDbId, listId) {
    try {
      const querySnapshot = await this.collectionRef.where('site_db_id', '==', siteDbId).get();

      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        if (data.references && (data.references.later_list_id || data.references.order_list_id || data.references.upload_list_id)) {
          let mailingListId = '';
          if (data.references.later_list_id) {
            mailingListId = data.references.later_list_id.split('/')[1];
          } else if (data.references.order_list_id) {
            mailingListId = data.references.order_list_id.split('/')[1];
          } else if (data.references.upload_list_id) {
            mailingListId = data.references.upload_list_id.split('/')[1];
          }
          if (mailingListId !== '' && mailingListId.toString() === listId) {
            return {
              isRelatedToOrder: true,
              orderFirebaseID: doc.id
            };
          }
        }
      }
      return { isRelatedToOrder: false };
    } catch (error) {
      console.log('Checking list in orders references has failed:', error);
      throw error;
    }
  }
}

export default new OrderService();
