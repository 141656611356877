import { dbFS } from '@/services/firebaseInit';
import accessLevelService from '../../services/accessLevelService.js';
import routeService from '../../services/routeService.js';

const state = {
  routes: [],
  isRoutesLoading: false,
  routeModel: [
    {
      label: 'Route Name',
      model: 'name',
      value: '',
      type: 'text',
      input_type: 'textfield',
      is_required: true,
      rule: [value => !!value || 'Route Name is required!']
    },
    {
      label: 'Path',
      model: 'path',
      value: '',
      type: 'text',
      input_type: 'textfield',
      is_required: true,
      rule: [value => !!value || 'Path is required!']
    },
    {
      label: 'Component',
      model: 'component',
      value: '',
      type: 'text',
      input_type: 'textfield',
      is_required: true,
      rule: [value => !!value || 'Component name is required!']
    },
    {
      label: 'Description',
      model: 'description',
      value: '',
      type: 'text',
      input_type: 'textfield',
      is_required: true,
      rule: [value => !!value || 'Description is required!']
    },
    {
      label: 'Access Levels',
      model: 'access_levels',
      items: [],
      value: '',
      type: 'text',
      input_type: 'select',
      is_multiple: true
    },
    {
      label: 'Icon',
      model: 'icon',
      value: '',
      type: 'text',
      input_type: 'textfield'
    }
  ],
  selectedModel: []
};

const getters = {
  getRoutes(state) {
    return state.routes;
  },
  getRoutesLoadingState(state) {
    return state.isRoutesLoading;
  },
  getSelectedModel(state) {
    return state.selectedModel;
  },
  getRouteModel(state) {
    return state.routeModel;
  }
};

const mutations = {
  clearRoutes(state) {
    state.routes = [];
  },
  clearModel(state) {
    state.routeModel.map(model => (model.value = ''));
    state.selectedModel = [];
  },
  setRoutes(state, payload) {
    state.routes = payload;
  },
  setSelectedModel(state, payload) {
    state.selectedModel = payload;
  },
  setRoutesLoadingState(state, payload) {
    state.isRoutesLoading = payload;
  }
};

const actions = {
  async initSelectedModel({ commit, getters, dispatch }, payload) {
    let routeData = getters.getRouteModel;
    const accessLevels = await accessLevelService.getAll({ isArray: true, isIdReturned: true });

    routeData.forEach((modelProperty, index) => {
      if (modelProperty.model === 'access_levels') {
        modelProperty.items = accessLevels.map(level => ({
          name: level.name,
          id: level._id,
          value: level.value
        }));
      }
    });

    if (payload) {
      getters.getRouteModel.forEach((modelProperty, index) => {
        if (modelProperty.input_type !== 'object' && payload[modelProperty.model]) {
          routeData[index].value = payload[modelProperty.model];
        }
      });
    }
    commit('setSelectedModel', routeData);
  },
  async getAllRoutes({ commit }, payload) {
    commit('setRoutesLoadingState', true);
    commit('clearRoutes');
    const routes = await routeService.getAll({ isIdReturned: true, isArray: true }).then(routes => routes.map(route => ({ ...route, route_firebase_id: route._id })));
    commit('setRoutes', routes);
    commit('setRoutesLoadingState', false);
    return routes;
  },

  async getRouteById({ commit, dispatch }, payload) {
    dispatch('showLoader', { message: `Loading, please wait...` }, { root: true });
    const routeData = await routeService
      .getById(payload, { isIdReturned: true })
      .then(route => ({ ...route, route_firebase_id: route._id }))
      .catch(error => {
        dispatch('hideLoader', {}, { root: true });
        dispatch('showAlert', { message: error.message, type: 'error' }, { root: true });
        throw { message: error };
      });
    commit('initSelectedModel', routeData);
    dispatch('hideLoader', {}, { root: true });
    return routeData;
  },
  addNewRoute({ commit }, payload) {
    return routeService.add(payload);
  },
  editRoute({ commit }, payload) {
    return routeService.update(payload.id, payload.data);
  },
  deleteRoute({ commit }, payload) {
    return routeService.delete(payload.route_firebase_id);
  },
  saveRoute({ commit, dispatch }, payload) {
    dispatch('showLoader', { message: `Loading, please wait...` }, { root: true });
    const functionName = payload.id ? 'editRoute' : 'addNewRoute';
    return dispatch(functionName, payload)
      .then(result => {
        return { message: `Route saved with id: ${result}` };
      })
      .finally(() => {
        commit('setSelectedModel', {});
        dispatch('hideLoader', {}, { root: true });
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
