<template>
  <v-container fluid>
    <v-row>
      <v-btn icon large @click="openExitDialog()"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <span class="ml-3 pt-1 title font-weight-regular">{{ title }} {{ productName }}</span>
    </v-row>
    <v-divider class="my-4"></v-divider>

    <v-tabs v-model="tab" grow background-color="transparent" @change="openTabSwitchDialog">
      <v-tab>Product Information</v-tab>
      <v-tab :disabled="!productFirebaseId">Pricing Information</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabItem" class="bg-tr">
      <v-tab-item :key="0">
        <ProductInformation
          v-if="isInformationLoaded"
          ref="prodInfo"
          @handleProductInformationChange="handleProductInformationChange"
          @handleIsProductInformationSaveDisabled="setIsProductInfoDisabled"
        />
      </v-tab-item>
      <v-tab-item :key="1">
        <PricingInformation
          v-if="isPricingLoaded"
          ref="priceInfo"
          @handlePricingTiersChange="setIsPricingTiersUnchanged"
          @handlePricingRawDataChange="setIsPricingRawDataUnchanged"
        />
      </v-tab-item>
    </v-tabs-items>
    <ConfirmationDialog
      :value="isConfirmDialogOpen"
      title="Discard Changes?"
      text="Do you want to discard your unsaved changes?"
      @onClose="closeDialog"
      @onConfirm="discardCurrentTab"
    />
  </v-container>
</template>

<script>
import ProductInformation from './ProductInformation.vue';
import PricingInformation from './product-pricing/PricingInformation.vue';
import ConfirmationDialog from '../dialog/ConfirmationDialog.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    ProductInformation,
    PricingInformation,
    ConfirmationDialog
  },
  data() {
    return {
      tab: 0,
      tabItem: 0,
      isPricingLoaded: false,
      isConfirmDialogOpen: false,
      isProductUnchanged: true,
      isPricingRawDataUnchanged: true,
      isPricingTiersUnchanged: true,
      isProductInfoDisabled: true,
      isExitDialogOpen: false,
      isInformationLoaded: false,
      productName: ''
    };
  },
  computed: {
    ...mapGetters({
      product: 'products/getCurrentProduct'
    }),
    getProductName() {
      return this.productName;
    },
    title() {
      return this.productFirebaseId ? `${this.isEditable ? 'Edit' : 'View'}: ${this.productFirebaseId}` : 'Add New Product';
    },
    isEditable() {
      return this.$route.name !== 'ViewProduct';
    },
    selectedSite() {
      return this.$route.params.siteId;
    },
    productFirebaseId() {
      return this.$route.params.productId;
    },
    isDialogDisabled() {
      return !this.isEditable || (this.tabItem === 0 && this.isProductUnchanged) || (this.tabItem === 1 && this.isPricingRawDataUnchanged && this.isPricingTiersUnchanged);
    }
  },
  mounted() {
    this.$store.dispatch('products/getProductAndWatch', { productId: this.productFirebaseId, siteId: this.selectedSite });
    this.fetchProductName();
    this.loadTab();
  },
  methods: {
    setIsProductInfoDisabled(value) {
      this.isProductInfoDisabled = value;
    },
    setProductUnchangedState(value) {
      this.isProductUnchanged = value;
    },
    setIsPricingTiersUnchanged(value) {
      this.isPricingTiersUnchanged = value;
    },
    setIsPricingRawDataUnchanged(value) {
      this.isPricingRawDataUnchanged = value;
    },
    handleProductInformationChange(value) {
      this.setProductUnchangedState(value);
      this.fetchProductName();
    },
    navigateBack() {
      this.$router.replace(`/products/sites/${this.selectedSite}`);
    },
    async fetchProductName() {
      if (!this.productFirebaseId) return;
      try {
        const product =
          this.selectedSite === 'default'
            ? await this.$store.dispatch('products/getDefaultProductByID', this.productFirebaseId)
            : await this.$store.dispatch('products/getCustomProductByID', { siteID: this.selectedSite, productID: this.productFirebaseId });

        this.productName = product?.name ? ` - (${product.name})` : '';
      } catch (error) {
        console.error('Error fetching product name:', error);
        this.productName = '';
      }
    },
    openExitDialog() {
      if (this.$route.name === 'AddProduct' || this.$route.name === 'ViewProduct' || (this.$route.name === 'EditProduct' && this.isDialogDisabled)) {
        this.navigateBack();
      } else if (this.$route.name === 'EditProduct') {
        this.isConfirmDialogOpen = true;
        this.isExitDialogOpen = true;
      }
    },
    openTabSwitchDialog() {
      if (this.isDialogDisabled) {
        this.tabItem = this.tab;
        this.loadTab();
        return;
      }
      this.isConfirmDialogOpen = true;
    },
    discardCurrentTab() {
      this.isConfirmDialogOpen = false;
      if (!this.isExitDialogOpen) {
        if (this.tabItem === 0) {
          // this happens when switching from prodInfo -> priceInfo
          this.loadTab();
        }
        this.tabItem = this.tab;
      } else {
        this.navigateBack();
      }
    },
    closeDialog() {
      this.isConfirmDialogOpen = false;
      this.isExitDialogOpen = false;
      this.tab = this.tabItem;
    },
    loadTab() {
      if (this.tab === 1) {
        this.isPricingLoaded = false;
        this.$nextTick(() => {
          this.isPricingLoaded = true;
          console.log('Pricing loaded successfully!');
        });
      } else {
        this.isInformationLoaded = false;
        this.$nextTick(() => {
          this.isInformationLoaded = true;
          console.log('Product information loaded successfully!');
        });
      }
    }
  }
};
</script>

<style scoped>
.bg-tr {
  background: transparent !important;
}
.full-size-card {
  width: 100%;
  height: 100%;
}
</style>
