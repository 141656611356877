<template>
  <div class="site-table">
    <v-dialog v-model="dialog" max-width="320">
      <v-card>
        <v-card-title class="headline red">
          <div class="text-xs-center">
            <span class="white--text text-md-center">Warning!</span>
          </div>
        </v-card-title>
        <v-card-text v-if="deleteMultiple"> Are you sure you want to delete {{ logNr }} logs items? </v-card-text>
        <v-card-text v-else-if="deleteSingle"> Are you sure you want to delete this log item? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="green darken-1" text @click="confirmDeleteLogs"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-toolbar flat class="site-table--header site-table--lists">
        <v-col class="pl-0">
          <v-toolbar-title class="headline">Logs</v-toolbar-title>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </v-col>
      </v-toolbar>
      <stamp-data-table :headers="headers" :data="logBind" item-key="time" :search="search" sort-by="time" :sort-desc="false">
        <template #[`item.actions`]="{ item }">
          <v-icon text color="red" @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </stamp-data-table>
    </v-card>
  </div>
</template>
<script>
import { auth } from '../../services/firebaseInit.js';
import userLogsService from '../../services/userLogsService.js';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: { 'stamp-data-table': DataTable },
  data() {
    return {
      action: '',
      field: '',
      time: '',
      value: '',
      logs: [],
      search: '',
      headers: [
        { text: 'Time', align: 'left', value: 'time', width: 280 },
        { text: 'Action', value: 'action', align: 'left', width: 220 },
        { text: 'Field', value: 'field', align: 'left', width: 120 },
        { text: 'Value', value: 'value', align: 'left' },
        { text: 'Actions', value: 'actions', align: 'center', width: 100 }
      ],
      selected: [],
      isLogSelected: true,
      dialog: false,
      logNr: null,
      userKey: null,
      deleteMultiple: false,
      deleteSingle: false,
      itemToDelete: null,
      logBind: []
    };
  },
  computed: {
    logSelected() {
      return this.selected;
    }
  },
  watch: {
    logSelected(value) {
      this.isLogSelected = value.length <= 0;
    }
  },
  async created() {
    try {
      this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
      const user = auth.currentUser;
      this.userKey = user.uid;
      this.logs = await userLogsService.getById(this.userKey);
      this.logBind = Array.from(this.logs.logs);
    } catch (error) {
      console.log('Logs get error', error);
    } finally {
      this.$store.dispatch('hideLoader');
    }
  },
  methods: {
    deleteItem(item) {
      this.deleteSingle = true;
      this.itemToDelete = item;
      this.dialog = true;
    },
    deleteSelected() {
      this.logNr = this.selected.length;
      this.deleteMultiple = true;
      this.dialog = true;
    },
    confirmDeleteLogs() {
      if (this.deleteMultiple) {
        const key = this.userKey;
        this.selected.forEach((value, index) => {
          const logTime = value.time;
          this.logBind = this.logBind.filter(({ time }) => time !== logTime);
          userLogsService.set(key, { logs: this.logBind });
        });
      } else if (this.deleteSingle) {
        const key = this.userKey;
        const logTime = this.itemToDelete.time;
        this.logBind = this.logBind.filter(({ time }) => time !== logTime);
        userLogsService.set(key, { logs: this.logBind });
      }

      this.close();
    },
    close() {
      this.dialog = false;
      this.isLogSelected = true;
      this.selected = [];
      this.deleteMultiple = false;
      this.deleteSingle = false;
    }
  }
};
</script>
