<template>
  <div class="site-table">
    <v-row>
      <template>
        <v-toolbar flat class="site-table--header site-table--lists">
          <v-col class="pl-0">
            <v-toolbar-title class="headline">
              <a class="grey--text text--darken-3" @click="clearDate()">Designs</a>
            </v-toolbar-title>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details @keydown="searchKeydown()"></v-text-field>
            <p v-if="isMinLenghSeachValueShown" class="caption mt-2">Please enter min 3 characters to begin searching!</p>
          </v-col>
          <stamp-date-filters
            v-if="isDataLoadedFromUrl"
            :startDate="selectedStartDate"
            :endDate="selectedEndDate"
            :timeStampAttrName="timeStampAttrName"
            :isFiltersCleared="isFiltersCleared"
            @onRouteHandlerChanged="onRouteHandlerChanged"
            @onDateFilterChanged="onDateFilterChanged"
            @onDateSelectedStateChanged="onDateSelectedStateChanged"
            @onDateCleared="clearDate"
            @onWrongFilter="onWrongFilter"
          ></stamp-date-filters>
        </v-toolbar>
      </template>
    </v-row>
    <stamp-environment-filters
      v-if="isDataLoadedFromUrl"
      :envFromQueryParam="envFromQueryParam"
      :testSitesList="testSitesList"
      :isFiltersCleared="isFiltersCleared"
      siteDbIdAttrName="siteDbId"
      originSiteDbIdAttrName="origin.siteDbId"
      @onEnvTypeChanged="onEnvTypeChanged"
    ></stamp-environment-filters>
    <v-row>
      <v-col cols="12">
        <v-alert v-model="isEndDateSmaller" type="error" dismissible outlined> End date cannot be less than Start date! Please choose another End date. </v-alert>
        <div class="site-table mt-n8">
          <stamp-data-table
            :headers="headers"
            :data="designs"
            :is-loading="isLoading"
            :sort-by="timeStampAttrName"
            :sort-desc="true"
            :item-class="getLineColor"
            :is-items-per-page-disabled="true"
            :total-server-items-nr="totalDesigns"
            item-key="firebase_design_id"
            :current-page="currentPage"
            @options="optionsHandler"
          >
            <template #[`item.designTimestamp`]="{ item }">{{ formatDate(item.designTimestamp) }}</template>
            <template #[`item.userEmail`]="{ item }">{{ getDesignCustomerEmail(item) }}</template>
            <template #[`item.crmCustomerId`]="{ item }">{{ getDesignCrmCustomerId(item) }}</template>
            <template #[`item.customerName`]="{ item }">{{ getDesignCustomerName(item) }}</template>
            <template #[`item.actions`]="{ item }">
              <v-btn :to="`/designs/view/${item.firebase_design_id}`" text color="blue">Details</v-btn>
            </template>
          </stamp-data-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DateFilters from '@/components/filters/DateFilters.vue';
import EnvironmentFilters from '@/components/filters/EnvironmentFilters.vue';
import DataTable from '@/components/common/DataTable.vue';

export default {
  name: 'designs-algolia',
  components: {
    'stamp-date-filters': DateFilters,
    'stamp-environment-filters': EnvironmentFilters,
    'stamp-data-table': DataTable
  },
  data() {
    return {
      search: '',
      options: {},
      timeStampAttrName: 'designTimestamp',
      setHitsPerPage: 15,
      headers: [
        { text: 'Date', value: 'designTimestamp', sortable: false },
        { text: 'User Email', value: 'userEmail', sortable: false },
        { text: 'Template ID', value: 'templateId', sortable: false },
        { text: 'CRM Customer ID', value: 'crmCustomerId', sortable: false },
        { text: 'Customer Name', value: 'customerName', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
      ],
      totalDesigns: null,
      designs: [],
      isLoading: false,
      isMinLenghSeachValueShown: false,
      isDateSelected: false,
      date: new Date().toISOString().substr(0, 10),
      selectedStartDate: '',
      selectedEndDate: '',
      isEndDateSmaller: false,
      isDataLoadedFromUrl: false,
      searchTypeTimer: null,
      currentPage: 1,
      testSitesList: null,
      filter: [],
      dateFilter: '',
      isFiltersCleared: false,
      envFromQueryParam: ''
    };
  },
  computed: {
    checkSearchChange() {
      if (this.isDataLoadedFromUrl) {
        if (this.search?.length > 2) {
          this.routeHandler('search', this.search);
        }
        return this.search;
      }
      return '';
    },
    commonFilter() {
      let common = `${this.dateFilter ? `${this.dateFilter}${this.filter.length ? ` AND ` : ``}` : ``}`;
      if (this.filter.length) {
        this.filter.forEach((value, index) => {
          common += value.envType ? value.envType : `${Object.keys(value)}:${Object.values(value)}`;
          if (index !== this.filter.length - 1) {
            common += ' AND ';
          }
        });
      }
      return common;
    },
    currentPrimaryColor() {
      return 'test-item-default';
    }
  },
  watch: {
    checkSearchChange(val) {
      if (val.length === 0 && !this.isDateSelected) {
        this.isMinLenghSeachValueShown = false;
        this.initResult();
      } else if (val.length && val.length < 3) {
        this.isMinLenghSeachValueShown = true;
      } else {
        this.isMinLenghSeachValueShown = false;
        this.searchTypeTimer = setTimeout(() => {
          this.resetSelectedPage();
          this.getDesignsFromAlgolia(this.commonFilter, val, 0).catch(error => {
            console.log('Error in search:', error);
          });
        }, 500);
      }
    }
  },
  async created() {
    await this.initTestSitesList();
    await this.setDataFromUrl();
    this.isDataLoadedFromUrl = true;
    if (!this.isDateSelected) this.initResult();
  },
  methods: {
    async initTestSitesList() {
      this.testSitesList = await this.$store.dispatch('site/getTestSites');
      Promise.resolve();
    },
    getLineColor(item) {
      return this.isListItemTest(item) ? this.currentPrimaryColor : '';
    },
    onEnvTypeChanged(payload) {
      this.resetSelectedPage();
      this.routeHandler('envType', payload.selectedEnv);
      this.setCommonFilter('envType', payload.envFilters);

      this.getDesignsFromAlgolia(this.commonFilter, this.checkSearchChange, 0);
    },
    resetSelectedPage() {
      this.options.page = 1;
      this.currentPage = 1;
    },
    setCommonFilter(key, value) {
      if (this.filter.length) {
        let isValueFound = false;
        this.filter.forEach((item, index) => {
          if (Object.keys(item).includes(key) && item[key] !== value) {
            isValueFound = true;
            if (value !== '') {
              this.filter[index][key] = value;
            } else {
              this.filter.splice(index, 1);
            }
          }
        });
        if (!isValueFound) {
          this.filter.push({ [key]: value });
        }
      } else if (value) {
        this.filter.push({ [key]: value });
      }
    },
    getDesignsFromAlgolia(filter, searchValue, page) {
      this.isLoading = true;

      return this.$store
        .dispatch('algolia/getDataFromAlgolia', {
          indexName: `custom_designs${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`,
          filter,
          searchValue: searchValue ?? '',
          page: page > 1 ? page - 1 : 0
        })
        .then(content => {
          this.designs = content.hits;
          this.totalDesigns = content.nbHits;
          this.isFiltersCleared = false;
          return content;
        })
        .catch(error => {
          console.log(error, error.debugData);
          return error;
        })
        .finally(() => (this.isLoading = false));
    },
    initResult() {
      const d = new Date();
      const date = Math.floor(d.setDate(d.getDate()) / 1000);
      this.getDesignsFromAlgolia(`designTimestamp < ${date}`, '', 0).catch(error => {
        console.log('Error in pagination:', error);
      });
    },
    onDateFilterChanged(formattedFilter) {
      this.dateFilter = formattedFilter;
      return this.getDesignsFromAlgolia(this.commonFilter, this.checkSearchChange, 0).catch(error => {
        console.log('getDesignsFromAlgolia had failed: ', error.debugData);
        return error;
      });
    },
    routeHandler(paramName, paramValue) {
      let query = Object.assign({}, this.$route.query);
      if (paramValue === undefined || paramValue === '' || paramValue === null) {
        if (Object.keys(query).includes(paramName)) {
          delete query[paramName];
          this.$router.replace({ query });
        }
      } else {
        if (JSON.stringify({ [paramName]: query[paramName] }) !== JSON.stringify({ [paramName]: paramValue })) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              [paramName]: paramValue
            }
          });
        }
      }
    },
    setDataFromUrl() {
      return new Promise(resolve => {
        const routeQuery = this.$route.query;

        if (routeQuery) {
          this.selectedStartDate = routeQuery.startDate ?? '';
          this.routeHandler('startDate', this.selectedStartDate);
          this.selectedEndDate = routeQuery.endDate ?? '';
          this.routeHandler('endDate', this.selectedEndDate);
          this.isDateSelected = this.selectedStartDate !== '' && this.selectedEndDate !== '';
          this.envFromQueryParam = routeQuery.envType ?? '';
          this.search = routeQuery.search ? routeQuery.search : '';
          this.routeHandler('search', this.search);
        }

        resolve('done');
      });
    },
    searchKeydown() {
      clearTimeout(this.searchTypeTimer);
      this.searchTypeTimer = setTimeout(() => {
        if (this.checkSearchChange === '' || this.checkSearchChange?.length > 2) {
          this.routeHandler('search', this.checkSearchChange);
        }
      }, 1000);
    },
    formatDate(dateTotransform) {
      const date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    clearDate() {
      this.isFiltersCleared = true;
      this.envFromQueryParam = '';
      this.routeHandler('envType', this.envFromQueryParam);
      this.isEndDateSmaller = false;

      this.selectedEndDate = '';
      this.selectedStartDate = '';
      this.isDateSelected = false;
      this.search = '';
      this.routeHandler('search', '');
      this.dateFilter = '';
      this.options.page = 1;
      this.currentPage = 1;

      this.initResult();
    },
    onRouteHandlerChanged(payload) {
      this.routeHandler(payload.key, payload.value);
    },
    onDateSelectedStateChanged(isDateSelected) {
      this.isDateSelected = isDateSelected;
    },
    onWrongFilter(isEndDateSmaller) {
      this.isEndDateSmaller = isEndDateSmaller;
      this.designs = [];
      this.totalDesigns = 0;
    },
    isListItemTest(item) {
      return (item?.origin?.siteDbId && this.testSitesList?.includes(item?.origin?.siteDbId)) || this.testSitesList?.includes(item.siteDbId);
    },
    async optionsHandler(options) {
      this.options = options;
      if (this.currentPage !== this.options.page) {
        await this.getDesignsFromAlgolia(this.commonFilter, this.checkSearchChange, this.options.page);
        this.currentPage = this.options.page;
      }
    },
    getDesignCustomerEmail(item) {
      return item?.customer?.userEmail || item.userEmail || '';
    },
    getDesignCrmCustomerId(item) {
      return item?.customer?.crmCustomerId || item?.crmCustomerId || '';
    },
    getDesignCustomerName(item) {
      return `${item?.customer?.first_name || item?.first_name || ''} ${item?.customer?.last_name || item?.last_name || ''}`;
    }
  }
};
</script>

<style scoped>
.test-item-global {
  background-color: #e6f2f4 !important;
}
.test-item-default {
  background-color: #f9e1e1 !important;
}
</style>
