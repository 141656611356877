<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-radio-group v-model="processType" row @change="getArtworkThumbnails">
        <v-radio label="Preflight Tool" value="preflight"></v-radio>
        <v-radio label="HTML Artwork" value="html"></v-radio>
      </v-radio-group>
    </v-row>

    <template v-if="processType === 'preflight'">
      <edit-creative class="mt-8 mb-n5" :rootOrder="rootOrder" componentMode="dmm-tool" @artworksData="emitArtworkData"></edit-creative>
    </template>

    <template v-else>
      <v-row class="justify-center mb-5">
        <p class="mt-5 mr-2">Code</p>
        <v-switch v-model="isFile" dense @change="getArtworkThumbnails"></v-switch>
        <p class="mt-5 ml-2">File</p>
      </v-row>

      <v-row class="justify-center">
        <v-col cols="6">
          <v-row class="justify-center">Front Artwork</v-row>
        </v-col>
        <v-col cols="6">
          <v-row class="justify-center">Back Artwork</v-row>
        </v-col>
      </v-row>

      <v-row v-if="isFile" class="justify-center mb-5">
        <v-col cols="6" class="px-8">
          <v-img v-if="isFrontArtworkGenerated" :src="artworkImages.front_artwork_thumbnail" class="showPointerOnHover" @click="viewImage('front')"></v-img>
          <template v-else>
            <v-row class="justify-center">
              <v-text-field v-model="frontFileUrl" label="Use URL for Front HTML" placeholder="Front HTML artwork URL" dense outlined clearable></v-text-field>
            </v-row>
            <v-row class="justify-center">
              <v-chip class="mb-4">OR</v-chip>
            </v-row>
            <v-row class="justify-center">
              <v-file-input v-model="frontFileInput" label="Upload HTML file for Front Artwork " accept=".html" dense outlined @click:clear="clearArtwork('front')"> </v-file-input>
            </v-row>
          </template>
        </v-col>

        <v-col cols="6" class="px-8">
          <v-img v-if="isBackArtworkGenerated" :src="artworkImages.back_artwork_thumbnail" class="showPointerOnHover" @click="viewImage('back')"></v-img>
          <template v-else>
            <v-row class="justify-center">
              <v-text-field v-model="backFileUrl" label="Use URL for Back HTML" placeholder="Front HTML artwork URL" dense outlined clearable></v-text-field>
            </v-row>
            <v-row class="justify-center">
              <v-chip class="mb-4">OR</v-chip>
            </v-row>
            <v-row class="justify-center">
              <v-file-input v-model="backFileInput" label="Upload HTML file for Back Artwork " accept=".html" dense outlined @click:clear="clearArtwork('back')"> </v-file-input>
            </v-row>
          </template>
        </v-col>
      </v-row>

      <v-row v-else class="justify-center mb-5">
        <v-col cols="6">
          <v-img v-if="isFrontArtworkGenerated" :src="artworkImages.front_artwork_thumbnail" class="showPointerOnHover" @click="viewImage('front')"></v-img>
          <prism-editor v-else v-model="frontHtml" language="html" :line-numbers="true" :auto-style-line-numbers="true" style="background-color: white" />
        </v-col>
        <v-col cols="6">
          <v-img v-if="isBackArtworkGenerated" :src="artworkImages.back_artwork_thumbnail" class="showPointerOnHover" @click="viewImage('back')"></v-img>
          <prism-editor v-else v-model="backHtml" language="html" :line-numbers="true" :auto-style-line-numbers="true" style="background-color: white" />
        </v-col>
      </v-row>

      <v-row class="justify-center mb-5">
        <v-col cols="6" class="px-8">
          <v-row class="justify-center">
            <v-btn v-if="isFrontArtworkGenerated" @click="clearArtwork('front')"><v-icon class="mr-2">mdi-restore</v-icon>Replace Front Artwork</v-btn>
            <v-btn v-else :disabled="isCreateFrontArtworkDisabled" @click="createArtwork('front')">Create Front Artwork</v-btn>
          </v-row>
        </v-col>
        <v-col cols="6" class="px-8">
          <v-row class="justify-center">
            <v-btn v-if="isBackArtworkGenerated" @click="clearArtwork('back')"><v-icon class="mr-2">mdi-restore</v-icon>Replace Back Artwork</v-btn>
            <v-btn v-else :disabled="isCreateBackArtworkDisabled" @click="createArtwork('back')">Create Back Artwork</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import EditCreative from '../detail-components/EditCreative.vue';
import PrismEditor from 'vue-prism-editor';
import { storage, storageRef } from '../../../services/firebaseInit.js';
import { mapGetters } from 'vuex';
import { capitalize } from '@/modules/helpers/format';

export default {
  components: {
    EditCreative,
    PrismEditor
  },
  props: ['rootOrder'],
  data() {
    return {
      queryParams: {},
      processType: 'preflight',
      frontHtml: '',
      backHtml: '',
      frontFileUrl: '',
      backFileUrl: '',
      frontFileInput: null,
      backFileInput: null,
      artworkImages: {
        front_artwork_thumbnail: '',
        front_artwork_url: '',
        back_artwork_thumbnail: '',
        back_artwork_url: ''
      },
      isFile: false
    };
  },
  computed: {
    ...mapGetters('custom_fields', {
      dmmCustomFields: 'getDmmCustomFields'
    }),
    isCreateFrontArtworkDisabled() {
      return this.isFile ? !this.frontFileInput && !this.frontFileUrl : !this.frontHtml;
    },
    isCreateBackArtworkDisabled() {
      return this.isFile ? !this.backFileInput && !this.backFileUrl : !this.backHtml;
    },
    isFrontArtworkGenerated() {
      return !!this.artworkImages.front_artwork_thumbnail;
    },
    isBackArtworkGenerated() {
      return !!this.artworkImages.back_artwork_thumbnail;
    }
  },
  async mounted() {
    await this.getArtworkThumbnails();
    this.queryParams = this.$route.query;
  },
  methods: {
    emitArtworkData(data) {
      this.$emit('artworksData', data);
    },
    viewImage(side) {
      window.open(this.artworkImages[`${side}_artwork_url`]);
    },
    clearArtwork(side) {
      this[`${side}Html`] = '';
      this[`${side}FileUrl`] = '';
      this[`${side}FileInput`] = null;
      this.artworkImages[`${side}_artwork_thumbnail`] = '';
      this.artworkImages[`${side}_artwork_url`] = '';
    },
    getArtworkConfig(side) {
      const config = {
        firebaseOrderId: this.rootOrder.firebase_order_id,
        size: this.rootOrder.product.size_name,
        campaignName: `${this.rootOrder.crm_order_id || 'artwork'}_${Number(Date.now())}`
      };
      config[`url${capitalize(side)}`] = this[`${side}FileUrl`];
      return config;
    },
    async getArtworkThumbnails() {
      const response = await this.$store.dispatch('om_orders/getArtworkThumbnails', { orderId: this.rootOrder.firebase_order_id });
      this.artworkSize = response.size;
      this.artworkImages.front_artwork_thumbnail = response.artwork_thumbnails_front?.medium || '';
      this.artworkImages.front_artwork_url = response.artwork_thumbnails_front?.large || '';
      this.artworkImages.back_artwork_thumbnail = response.artwork_thumbnails_back?.medium || '';
      this.artworkImages.back_artwork_url = response.artwork_thumbnails_back?.large || '';
      this.emitArtworkData(response);
    },
    async createArtwork(side) {
      try {
        if (!this.isFile) {
          this[`${side}Html`] = await this.$store.dispatch('custom_fields/replaceCustomFields', this[`${side}Html`]);
          let blob = new Blob([this[`${side}Html`]], { type: 'plain/text' });
          this[`${side}FileInput`] = new File([blob], `${this.queryParams.crm_order_id}_${this.queryParams.crm_mail_drop_id}_${side}_html`, { type: 'plain/text' });
        }
        if (!this[`${side}FileUrl`]) {
          await this.uploadToStorage(side);
        }
        this.$store.dispatch('showLoader', {
          message: `Generating ${capitalize(side)} Artwork in progress...`
        });
        const artworkConfig = this.getArtworkConfig(side);
        const { data } = await this.$store.dispatch('tools/createArtwork', artworkConfig);
        const artworkThumbnails = data[`artwork_thumbnails_${side}`];
        this.artworkImages[`${side}_artwork_thumbnail`] = artworkThumbnails?.medium || '';
        this.artworkImages[`${side}_artwork_url`] = artworkThumbnails?.large || '';

        const resultConfig = {};
        resultConfig[`artwork_${side}_id`] = data[`${side}_artwork_id`];
        resultConfig[`artwork_thumbnails_${side}`] = artworkThumbnails;
        this.emitArtworkData(resultConfig);
      } catch (error) {
        console.log(`[Create ${side} Artwork error ]:`, error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async replaceHtmlFileContent(side) {
      const fileContent = await this[`${side}FileInput`].text();
      const replacedContent = await this.$store.dispatch('custom_fields/replaceCustomFields', fileContent);
      let blob = new Blob([replacedContent], { type: 'plain/text' });
      this[`${side}FileInput`] = new File([blob], this[`${side}FileInput`].name, { type: 'plain/text' });
    },
    async uploadToStorage(side) {
      try {
        this.$store.dispatch('showLoader', {
          message: `Generating ${capitalize(side)} HTML URL in progress...`
        });
        if (this.isFile) {
          await this.replaceHtmlFileContent(side);
        }
        const path = `targeted-postcard-campaign/${this.queryParams.crm_order_id}_${this.queryParams.crm_mail_drop_id}`;
        const postcardFile = `${path}/${this.queryParams.crm_order_id}_${this.queryParams.crm_mail_drop_id}_${side}_html.html`;
        const storageLetterRef = storage.ref(postcardFile);
        await storageLetterRef.put(this[`${side}FileInput`]);
        this[`${side}FileUrl`] = await storageRef.child(postcardFile).getDownloadURL();
      } catch (error) {
        console.log(`[Upload ${side} Artwork to Storage error] :`, error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    }
  }
};
</script>
<style scoped>
.showPointerOnHover {
  cursor: pointer;
}
</style>
