<template>
	<v-dialog v-model="isJsonDialogOpened" max-width="750">
		<v-card>
			<v-card-title class="justify-end"><v-btn icon @click="closeJsonViewDialog()"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
			<v-card-text>
				<vue-json-pretty :data="data" :show-double-quotes="true" :show-length="true" :show-line="true" :deep="2"></vue-json-pretty>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
	components: { VueJsonPretty },
	props: ['isDialogOpened', 'data'],
    data () {
		return {
			isJsonDialogOpened: false
		}
	},
	watch: {
		isDialogOpened (value) {
			this.isJsonDialogOpened = value
		}
	},
	methods: {
		closeJsonViewDialog () {
			this.$emit('closeDialog')
		}
	}
}
</script>