<template>
  <v-dialog v-model="isDialogOpen" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">New File</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <p>If you upload only one image, it will be saved in the root directory.</p>
            <v-text-field id="newFolderName" v-model="newFolderName" label="Folder Name" hint="Enter Folder name" required type="text"> </v-text-field>
          </v-col>
          <v-col cols="12">
            <div>
              <p>In</p>
              <v-autocomplete v-model="selectedNewPath" :items="paths" label="Select Path" required item-value="path" item-text="path"></v-autocomplete>
            </div>
            <p v-if="isSaveDisabled" class="subheading red--text">Uploading an image is required.</p>
            <div>
              <FileUpload ref="fileUploader" accept="image/vnd.adobe.photoshop" @filesAdded="filesAdded" @clear="clearUploadedImage" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary darken-1" text :disabled="isSaveDisabled" @click="saveNewPath">Save</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUpload from '../common/FileUpload.vue';

export default {
  name: 'templates',
  components: {
    FileUpload
  },
  props: {
    paths: {
      type: Array,
      default: () => []
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedNewPath: '',
      newFolderName: '',
      file: null
    };
  },
  computed: {
    isDialogOpen() {
      return this.isOpen;
    },
    isSaveDisabled() {
      return !this.file;
    }
  },
  methods: {
    filesAdded(files) {
      const [image] = files;
      this.file = image;
      this.$emit('filesAdded', image);
    },
    clearUploadedImage() {
      this.file = null;
      this.$refs.fileUploader.deleteFiles();
      this.$emit('clearUploadedImage');
    },
    saveNewPath() {
      this.$emit('saveNewPath', { selectedNewPath: this.selectedNewPath, newFolderName: this.newFolderName });
      this.resetValues();
    },
    closeDialog() {
      this.$emit('onClose');
      this.resetValues();
    },
    resetValues() {
      this.selectedNewPath = '';
      this.newFolderName = '';
      this.file = null;
      this.clearUploadedImage();
    }
  }
};
</script>
