import RestClient from './rest-client';
//import * as Sentry from '@sentry/browser'

class SalesforceModel extends RestClient {
  constructor(context) {
    super('salesforce', context);
    this.context = context;
  }

  search(payload) {
    let _self = this;
    const id = 'search';
    return this.post(
      {
        params: { email: payload.email },
        success: function (response, resolve) {
          console.log(`Search for ${payload.email} in Salesforce has succeeded: `, response);
          return resolve(response.data);
        },
        error: function (error, reject) {
          console.log(`Search for ${payload.email} in Salesforce has failed: `, error);
          return reject(error);
        }
      },
      id
    );
  }

  getOpportunityObj(payload, type) {
    //type could be create or update
    const utilsInstance = this.context.getters.getUtilsInstance;
    const order = payload.order;
    const customerData = order.order_data.customer;
    const orderQty = order.print_qty - order.extra_qty;
    const orderID =
      order.is_quote && (order.crm_order_id === 0 || order.crm_order_id === '0')
        ? order.quote_id
          ? order.quote_id
          : `Q-${utilsInstance.formatDate(order.creation_date.toDate(), 'X')}-${customerData.wp_customer_id}`
        : order.crm_order_id?.toString();
    const eddmShippingPrice =
      order.order_type === 'eddm' && order.order_data.mailing.mailing_shipping_rate > 0 ? order.order_data.mailing.mailing_shipping_rate * order.print_qty : 0;
    const mailingServiceFee = order.order_data.mailing.mailing_service_rate > 0 ? order.order_data.mailing.mailing_service_rate * orderQty : 0;
    const closeDate =
      type === 'update'
        ? utilsInstance.getISO(utilsInstance.getCurrentDate())
        : utilsInstance.getISO(utilsInstance.addDays(utilsInstance.getESTDate(order.creation_date.toDate(), 7)));
    // Custom List Data
    const isCustomMailingListAvailable = order.order_data.mailing.targeted.custom_list !== undefined && order.order_data.mailing.targeted.custom_list !== null;
    const customListPrice = isCustomMailingListAvailable ? order.order_data.mailing.targeted.custom_list.list_price : 0;
    const customListQty = isCustomMailingListAvailable ? order.order_data.mailing.targeted.custom_list.list_qty : 0;
    const customListPricePerPiece =
      isCustomMailingListAvailable && parseFloat(customListPrice) > 0 && parseInt(customListQty) > 0 ? parseFloat(customListPrice) / parseInt(customListQty) : 0;
    // Additional Services
    const additionalServices =
      order.order_data.additional_services?.amount && order.order_data.additional_services?.description ? order.order_data.additional_services : { amount: 0, description: '' };
    // Manual Discounts
    let manualDiscount = {
      amount: 0,
      description: ''
    };

    if (order.order_data.pricing.discounts.length) {
      const discountData = order.order_data.pricing.discounts.filter(discount => discount.discount_type === 'manual_discount');
      if (discountData?.length) {
        manualDiscount = discountData[0];
      }
    }

    // EXTRA Quantity + Price
    const extraQty = order.extra_qty;
    const calculatedExtraPrice = extraQty * 0.16;
    const isExtraQtyPriceAvailable = order && order.order_data && order.order_data.pricing && !isNaN(order.order_data.pricing.extra_qty_price);
    const totalExtraQtyPrice = isExtraQtyPriceAvailable
      ? order.order_data.pricing.extra_qty_price
      : calculatedExtraPrice === 0 || calculatedExtraPrice > 16
      ? calculatedExtraPrice
      : 16;
    const taxFee = order.order_data.pricing.tax || 0;

    const config = {
      account_id: payload.accountId,
      amount: order.order_total,
      owner_id: payload.salesforceUserID,
      contact_id: payload.id,
      contact_email: payload.email,
      order_type: this.getOrderTypeLabel(order.order_type),
      close_date: closeDate,
      created_date: utilsInstance.getESTDate(order.creation_date.toDate()),
      special_instructions: order.special_instructions,
      design_costs: order.order_data.pricing.artwork,
      direct_mail: this.getDirectMailLabel(order.order_type),
      initial_payment: order.order_total / 2,
      order_label: order.label,
      opportunity_type: 'Quote',
      stage_name: type === 'update' ? 'Closed Won' : 'Quoted',
      order_id: orderID,
      order_qty: orderQty,
      product_size: `${order.order_data.product.size_x}x${order.order_data.product.size_y}`,
      product_name: order.product_name,
      shipping_costs: order.order_data.pricing.shipping, //UPS Shipping
      web_id: payload.brandId !== '' ? payload.brandId : this.context.getters.getSiteSettings.brand_web_id,
      mailing_list_costs: order.order_data.pricing.list_purchase_price,
      direct_mail_costs: mailingServiceFee,
      postage: order.order_data.pricing.postage,
      discount: parseFloat(`-${(order.order_data.pricing.discount_total - manualDiscount.amount).toString()}`),
      bindery_costs: 0,
      digital_landingpage_costs: order.order_data.pricing.landing_page_price,
      product_id: order.order_data.product.salesforce_product_id || '',
      digital_ad_costs: order.order_data.pricing.digital_direct_mail || 0,
      price_per_piece: order.order_data.product.product_data.price_per_piece,
      printing_total_price: order.order_data.pricing.total_print_price,
      eddm_shipping_costs: eddmShippingPrice,
      rush_costs: order.order_data.pricing.turnaround_rush_fee || 0,
      call_tracking_costs: 0,
      stocks: order.order_data.pricing.stock,
      coatings: order.order_data.pricing.coating,
      address_update: order.order_data.pricing.list_processing,
      map_image_url: '',
      map_name: '',
      internal_customer_id: order.user_defined_fields?.field_1?.value || '', // EPICore ID
      salesforce_pricebook_entry_id: order.order_data.product.salesforce_pricebook_entry_id || '',
      custom_mailing_list_quantity: customListQty,
      custom_mailing_list_price_per_piece: !isNaN(customListPricePerPiece) ? customListPricePerPiece : 0,
      custom_mailing_list_total_price: customListPrice,
      custom_mailing_list_instructions: isCustomMailingListAvailable ? order.order_data.mailing.targeted.custom_list.instructions : '',
      additional_service_description: additionalServices.description,
      additional_service_value: parseFloat(additionalServices.amount),
      manual_discount_description: manualDiscount.description,
      manual_discount_value: parseFloat(manualDiscount.amount),
      quote_url_data: {
        firebase_order_id: order.firebase_order_id,
        wp_user_id: order.order_data ? order.order_data.customer.wp_customer_id : '',
        wp_user_email: order.order_email,
        base_url: payload.baseUrl ? payload.baseUrl : ''
      },
      extra_copies_quantity: extraQty,
      extra_copies_total_price: totalExtraQtyPrice,
      extra_copies_price_per_piece: extraQty ? parseFloat(totalExtraQtyPrice / extraQty) : 0,
      tax: taxFee,
      tabbing_total_price: !isNaN(order.order_data.pricing.tabs_price) ? order.order_data.pricing.tabs_price : 0,
      tabbing_quantity: orderQty,
      tabbing_price_per_piece: !isNaN(order.order_data.mailing.tabs_rate) ? order.order_data.mailing.tabs_rate : 0
    };

    // SET User Defined Order Fields
    if (order.user_defined_fields?.length) {
      const userDefinedTextFields = order.user_defined_fields.filter(userField => userField.field_type === 'text');
      for (let i in userDefinedTextFields) {
        config[`user_defined_field_${parseInt(i) + 1}`] = `${userDefinedTextFields[i].field_label} | ${userDefinedTextFields[i].field_value}`;
      }
    }

    if (
      order.references.map_id !== '' &&
      (order.order_type === 'eddm' || order.order_type === 'availability' || order.order_type === 'newmovers' || order.order_type === 'saturation')
    ) {
      const map = this.context.getters.getSavedMapObject;

      if (map) {
        config.map_image_url = map.mapImgUrl;
        config.map_name = map.name;
      }
    }

    console.log(`createOpportunity config: `, config);
    return config;
  }

  createOpportunity(payload) {
    console.log('createOpportunity payload: ', payload);

    let _self = this;
    const id = 'opportunity';
    const config = this.getOpportunityObj(payload, 'create');

    return this.post(
      {
        params: config,
        success: function (response, resolve) {
          console.log(`Create Opportunity in Salesforce has succeeded: `, response);
          return resolve(response.data);
        },
        error: function (error, reject) {
          console.log(`Create Opportunity in Salesforce has failed: ${error && error.response ? JSON.stringify(error.response) : JSON.stringify(error)}`);
          console.log(`Create Opportunity in Salesforce has failed. Sent Object: ${JSON.stringify(config)}`);
          if (error && error.code !== 'permission-denied') {
            // 	Sentry.withScope(function(scope) {
            // 	 scope.setTag('Functionality', 'Salesforce');
            // 	 Sentry.captureException(new Error(error && error.response ? `Create Salesforce Opportunity has failed. ERROR: ${JSON.stringify(error.response)}`
            // 																												 : `Create Salesforce Opportunity has failed. ERROR: ${error}`))
            //  });
          }
          return reject(error);
        }
      },
      id
    );
  }

  getOrderTypeLabel(type) {
    switch (type) {
      case 'eddm':
        return 'EDDM';
      case 'targeted':
        return 'Targeted';
      case 'newmovers':
        return 'New Movers';
      case 'availability':
        return 'Availability';
      case 'saturation':
        return 'Saturation';
      case 'custom':
        return 'Custom';
      case 'template':
        return 'Template';
      default:
        return '';
    }
  }

  getDirectMailLabel(type) {
    switch (type) {
      case 'eddm':
        return 'EDDM';
      case 'targeted':
        return 'Targeted';
      case 'newmovers':
        return 'New Movers';
      case 'availability':
        return 'Availability';
      case 'saturation':
        return 'Saturation';
      default:
        return '';
    }
  }
}

export { SalesforceModel as default };
