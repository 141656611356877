<template>
  <div>
    <v-row>
      <v-col cols="12" sm="10">
        <v-btn icon class="px-3 pb-2 pt-1" @click.native="back()">
          <v-icon color="red">mdi-arrow-left</v-icon>
        </v-btn>
        <span class="headline pt-1">Customer Tools</span>
      </v-col>
    </v-row>
    <v-row align="center" justify="start">
      <v-col cols="12" sm="10">
        <v-row>
          <v-col cols="12">
            <v-alert v-if="isAlertVisible" :type="alertType" dismissible class="mb-n1 mt-2"> {{ alertMessage }}</v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete v-model="selectedSiteId" :items="sitesList" label="Select a Firebase Site" required item-value="firebase_id" item-text="site_name" chips>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10">
            <v-textarea v-model="customerList" auto-grow outlined solo label="Insert CSV List of Customers"> </v-textarea>
          </v-col>
          <v-col v-if="isCustomListNotValid" cols="12" sm="9" class="mx-2 mt-n8"> The provided list is not a correct array of objects. </v-col>
        </v-row>
        <v-btn class="my-3 white--text" color="blue-grey" @click="saveCustomerToDb()"><v-icon color="white" class="mr-2">mdi-wizard-hat</v-icon>Save Customers To Database</v-btn>
        <v-divider class="my-3"></v-divider>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { convertCsvToJson } from '@/modules/helpers/convertCsvToJson';
import customerService from '../../services/customerService';

export default {
  data() {
    return {
      customerList: [],
      selectedSiteId: '',
      isCustomListNotValid: false,
      isAlertVisible: false,
      alertType: 'info'
    };
  },
  computed: {
    ...mapGetters('users', {
      userStore: 'user',
      userAccessLevel: 'userAccessLevel'
    }),
    ...mapGetters('site', {
      sites: 'getSites'
    }),
    sitesList() {
      return this.sites?.length ? this.sites : [];
    },
    isSavingCustomerDisabled() {
      return !this.selectedSiteId || !this.customerList;
    }
  },
  mounted() {
    if (!this.sitesList.length) {
      this.$store.dispatch('loadSitesFromDb');
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    async saveCustomerToDb() {
      try {
        this.$store.dispatch('showLoader', { message: 'Saving customers...' });
        if (this.isSavingCustomerDisabled) return;

        const convertedCustomList = await convertCsvToJson(this.customerList);

        console.log('Converted Customer List: ', this.convertedCustomList);

        const selectedSite = this.sitesList.find(item => item.firebase_id === this.selectedSiteId) || null;
        if (!selectedSite) return;

        const siteDbId = this.selectedSiteId;
        const siteId = selectedSite.site_id;
        const availableCustomers = [];
        const missingCustomers = [];
        const promises = [];
        let customerInDb = '';
        let customerUniqueID = '';

        for (let customer of convertedCustomList) {
          if (customer['CRM Cust ID']) {
            customerUniqueID = `${siteDbId}_${siteId}_${customer['User ID']}_${customer['CRM Cust ID']}`;

            customerInDb = await customerService.getCustomerFirebaseId(customerUniqueID);

            if (customerInDb.firebaseId) {
              availableCustomers.push(customer);
            } else {
              missingCustomers.push(customer);
              promises.push(
                this.$store.dispatch('customer_messaging/createNewCustomer', {
                  created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
                  crm_customer_id: customer['CRM Cust ID'],
                  customer_unique_id: `${siteDbId}_${siteId}_${customer['User ID']}_${customer['CRM Cust ID']}`,
                  first_name: customer['First Name'] || '',
                  last_name: customer['Last Name'] || '',
                  email: customer['Email'],
                  firebase_site_id: siteDbId,
                  site_id: siteId,
                  wp_user_id: customer['User ID'],
                  company: customer['Company'] || ''
                })
              );
            }
          }
        }
        console.log('Already saved customers: ', availableCustomers);
        console.log('Missing customers: ', missingCustomers);

        Promise.all(promises)
          .then(() => {
            this.setAlert('success', 'Saving customers to database has succeeded.');
          })
          .catch(error => {
            this.setAlert('error', `Saving customers to database has failed: ${error}`);
          })
          .finally(() => {
            this.$store.dispatch('hideLoader');
          });
      } catch (error) {
        this.setAlert('error', `saveCustomerToDb has failed: ${error}`);
        this.$store.dispatch('hideLoader');
      }
    },
    setAlert(type, message) {
      this.alertType = type;
      this.alertMessage = message;
      this.isAlertVisible = true;
      setTimeout(() => {
        this.isAlertVisible = false;
      }, 15000);
    }
  }
};
</script>
