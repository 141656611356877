import Vue from 'vue';
import Router from 'vue-router';
import { auth } from '../services/firebaseInit.js';

// Home page
import Home from '@/components/dashboard/Home.vue';

// Logs
import Logs from '@/components/logs/Logs.vue';
import AllLogs from '@/components/logs/AllLogs.vue';
import Notifications from '@/components/logs/Notifications.vue';
import BackupFirestore from '@/components/logs/BackupFirestore.vue';

// login-logout-register pages
import Login from '@/components/user/Login.vue';
import UserProfile from '@/components/user/UserProfile.vue';

// Permissions pages
import Users from '@/components/permissions/Users.vue';
import Groups from '@/components/permissions/Groups.vue';
import UserLevels from '@/components/permissions/UserLevels.vue';

import RoutesIndex from '@/components/permissions/routes/RoutesIndex.vue';
import Routes from '@/components/permissions/routes/Routes.vue';
import ManageRoute from '@/components/permissions/routes/ManageRoute.vue';

// Alert pages
import NoAccessLevelSelected from '@/components/alerts/NoAccessLevelSelected.vue';
import NoAccessLevelMessage from '@/components/alerts/NoAccessLevelMessage.vue';
import NoPathDefined from '@/components/alerts/NoPathDefined.vue';

// Customer Messaging Management
import SendEmail from '@/components/messaging/SendEmail.vue';
import EmailTemplates from '@/components/messaging/EmailTemplates.vue';
import SMSTemplates from '@/components/messaging/SMSTemplates.vue';
import SendSMS from '@/components/messaging/SendSMS.vue';

// Manage sites
import Sites from '@/components/site/Sites';
import SiteProcessNew from '@/components/site/SiteProcessNew';
import SiteIndex from '@/components/site/siteIndex';
import Tools from '@/components/site/Tools';

import QuoteToolHome from '@/components/quote-tool/QuoteToolHome';
import OrderProcesses from '@/components/order/OrderProcesses';
import Step from '@/components/order/Step';

// DesignLive template management
import Organizations from '@/components/designlive-templates/Organizations';

import Templates from '@/components/designlive-templates/Templates';
import SiteSpecificTemplates from '@/components/designlive-templates/SiteSpecificTemplates';
import TemplateInformation from '@/components/designlive-templates/TemplateInformation';
import ImportTemplates from '@/components/designlive-templates/ImportTemplates';

import TemplateTypesIndex from '@/components/designlive-templates/template-types/TemplateTypesIndex';
import TemplateTypes from '@/components/designlive-templates/template-types/TemplateTypes';
import ManageTemplateType from '@/components/designlive-templates/template-types/ManageTemplateType';

import TemplateCategoriesIndex from '@/components/designlive-templates/template-categories/TemplateCategoriesIndex';
import TemplateCategories from '@/components/designlive-templates/template-categories/TemplateCategories';
import ManageTemplateCategory from '@/components/designlive-templates/template-categories/ManageTemplateCategory';

import FileExplorer from '@/components/designlive-templates/FileExplorer';
import TemplateWizard from '@/components/designlive-templates/TemplateWizard';

// Orders Management
import OrdersIndex from '@/components/order-management/orders/OrdersIndex';
import OrderFirestoreDetails from '@/components/order-management/orders/OrderDetails';
import OrdersAlgolia from '@/components/order-management/orders/OrdersAlgolia';

import ListIndex from '@/components/order-management/lists/ListIndex';
import ListDetails from '@/components/order-management/lists/ListDetails';
import ListAlgolia from '@/components/order-management/lists/ListAlgolia';

import MapsIndex from '@/components/order-management/maps/MapsIndex';
import MapDetails from '@/components/order-management/maps/MapDetails';
import MapsAlgolia from '@/components/order-management/maps/MapsAlgolia';

import DesignsIndex from '@/components/order-management/designs/DesignsIndex';
import DesignDetails from '@/components/order-management/designs/DesignDetails';
import DesignsAlgolia from '@/components/order-management/designs/DesignsAlgolia';

// Order Management - tools
import EddmProcess from '@/components/order-management/tools/EddmProcess';
import DmmTool from '@/components/order-management/DmmTool';
import DmmLetterTool from '@/components/order-management/dmm/DmmLetterTool';
import FourOverTool from '@/components/order-management/fourover/FourOverTool';
import NMJsonProcess from '@/components/order-management/tools/NMJsonProcess';

import AutomatedDropsTracker from '@/components/status-tracker/AutomatedDropsTracker';

// UI
import Settings from '@/components/ui/Settings';
import TestingFile from '@/components/ui/TestingFile';
import TestPage from '@/components/ui/TestPage';

// Customer

import CustomerIndex from '@/components/customer/CustomerIndex';
import CustomersAlgolia from '@/components/customer/CustomersAlgolia';
import CustomerDetails from '@/components/customer/CustomerDetails';
import CustomerTools from '@/components/customer/CustomerTools';

//legacy
import ChargeLegacyOrder from '@/components/stripe/ChargeLegacyOrder';

// Reports
import MailingListReports from '@/components/logs/reports/MailingListReports';
import OrderReports from '@/components/logs/reports/OrderReports';
import IncompleteOrderReports from '@/components/logs/reports/IncompleteOrderReports';

// ResetPassword
import ResetPassword from '@/components/user/ResetPassword';

// Products
import ProductsIndex from '@/components/products/ProductsIndex';
import Products from '@/components/products/Products';
import ManageProduct from '@/components/products/ManageProduct';
import ImportProduct from '@/components/products/ImportProduct';

import ProductCategoriesIndex from '@/components/products/product-categories/ProductCategoriesIndex';
import SiteSelector from '@/components/products/product-categories/SiteSelector';
import ProductCategories from '@/components/products/product-categories/ProductCategories';
import ManageProductCategory from '@/components/products/product-categories/ManageProductCategory';

import ProductTypesIndex from '@/components/products/product-types/ProductTypesIndex';
import ProductTypes from '@/components/products/product-types/ProductTypes';
import ManageProductType from '@/components/products/product-types/ManageProductType';

import ProductServicesIndex from '@/components/products/product-services/ProductServicesIndex';
import ProductServices from '@/components/products/product-services/ProductServices';
import ManageProductService from '@/components/products/product-services/ManageProductService';

import FouroverStatusesIndex from '@/components/order-management/fourover/status/FouroverStatusesIndex';
import FouroverStatuses from '@/components/order-management/fourover/status/FouroverStatuses';
import ManageFouroverStatus from '@/components/order-management/fourover/status/ManageFouroverStatus';

Vue.use(Router);
let router = new Router({
  routes: [
    {
      path: '/',
      component: Home,
      name: 'home',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/users',
      component: Users,
      name: 'users',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/groups',
      component: Groups,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/routes',
      component: RoutesIndex,
      children: [
        {
          path: '',
          name: 'Routes',
          component: Routes
        },
        {
          path: 'view/:routeId',
          name: 'ViewRoute',
          component: ManageRoute
        },
        {
          path: 'new',
          name: 'AddRoute',
          component: ManageRoute
        },
        {
          path: 'edit/:routeId',
          name: 'EditRoute',
          component: ManageRoute
        }
      ]
    },
    {
      path: '/levels',
      component: UserLevels,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user-profile',
      component: UserProfile,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/logs',
      component: Logs,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/alllogs',
      component: AllLogs,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/notifications',
      component: Notifications,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/backup-firestore',
      component: BackupFirestore,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      component: Login,
      meta: {
        requiresGuest: true
      }
    },
    {
      path: '/noaccesslevelselected',
      component: NoAccessLevelSelected,
      name: 'noaccesslevel',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/noaccesslevelmessage',
      component: NoAccessLevelMessage,
      name: 'noaccesslevelmessage',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/nopathdefined',
      component: NoPathDefined,
      name: 'nopathdefined',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/sendemail',
      component: SendEmail,
      name: 'sendemail',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/emailtemplates',
      component: EmailTemplates,
      name: 'emailtemplates',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/smstemplates',
      component: SMSTemplates,
      name: 'smstemplates',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/sendsms',
      component: SendSMS,
      name: 'sendsms',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/sites',
      component: SiteIndex,
      children: [
        {
          path: '',
          name: 'Sites',
          component: Sites
        },
        {
          path: 'add',
          name: 'siteprocess',
          component: SiteProcessNew
        },
        {
          path: 'edit/:id',
          name: 'siteprocessedit',
          component: SiteProcessNew
        },
        {
          path: 'sites-tools',
          name: 'Sites_Tools',
          component: Tools
        }
      ],
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/quote-tool',
      name: 'QuoteToolHome',
      component: QuoteToolHome,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/status-tracker',
      name: 'StatusTracker',
      component: AutomatedDropsTracker,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dmm-tool',
      name: 'DmmTool',
      component: DmmTool,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/letter-tool',
      name: 'DmmLetterTool',
      component: DmmLetterTool,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/4-over-tool',
      name: 'FourOverTool',
      component: FourOverTool,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/orders-process/:siteId',
      name: 'OrderProcesses',
      component: OrderProcesses,
      children: [
        {
          path: ':order/:step',
          name: 'Step',
          component: Step
        }
      ],
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dl-organizations',
      name: 'Organizations',
      component: Organizations,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dl-templates',
      name: 'templates',
      component: Templates,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dl-templates/:siteId',
      name: 'siteSpecificTemplates',
      component: SiteSpecificTemplates,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dl-templates/:siteId/edit/:templateId',
      name: 'templatesEdit',
      component: TemplateInformation,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dl-templates/:siteId/view/:templateId',
      name: 'templatesView',
      component: TemplateInformation,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dl-templates/:siteId/add',
      name: 'templatesNew',
      component: TemplateInformation,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dl-templates/:siteId/import',
      name: 'importTemplates',
      component: ImportTemplates,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/template-types',
      component: TemplateTypesIndex,
      children: [
        {
          path: '',
          name: 'TemplateTypes',
          component: TemplateTypes
        },
        {
          path: 'view/:templateTypeId',
          name: 'ViewTemplateType',
          component: ManageTemplateType
        },
        {
          path: 'new',
          name: 'AddTemplateType',
          component: ManageTemplateType
        },
        {
          path: 'edit/:templateTypeId',
          name: 'EditTemplateType',
          component: ManageTemplateType
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/template-categories',
      component: TemplateCategoriesIndex,
      children: [
        {
          path: '',
          name: 'TemplateCategories',
          component: TemplateCategories
        },
        {
          path: 'view/:templateCategoryId',
          name: 'ViewTemplateCategory',
          component: ManageTemplateCategory
        },
        {
          path: 'new',
          name: 'AddTemplateCategory',
          component: ManageTemplateCategory
        },
        {
          path: 'edit/:templateCategoryId',
          name: 'EditTemplateCategory',
          component: ManageTemplateCategory
        }
      ],
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/file-explorer',
      name: 'explorer',
      component: FileExplorer,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/template-wizard',
      name: 'TemplateWizard',
      component: TemplateWizard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/list',
      component: ListIndex,
      children: [
        {
          path: '',
          name: 'list-algolia',
          component: ListAlgolia
        },
        {
          path: 'view/:listID',
          name: 'list-details',
          component: ListDetails
        }
      ],
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/maps',
      component: MapsIndex,
      children: [
        {
          path: '',
          name: 'maps-algolia',
          component: MapsAlgolia
        },
        {
          path: 'view/:mapID',
          name: 'MapDetails',
          component: MapDetails
        }
      ],
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/orders',
      component: OrdersIndex,
      children: [
        {
          path: '',
          name: 'orders-algolia',
          component: OrdersAlgolia
        },
        {
          path: 'view/:OrderID',
          name: 'order-firestore-details',
          component: OrderFirestoreDetails
        }
      ],
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/designs',
      component: DesignsIndex,
      children: [
        {
          path: '',
          name: 'designs-algolia',
          component: DesignsAlgolia
        },
        {
          path: 'view/:designID',
          name: 'design-details',
          component: DesignDetails
        }
      ],
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/nm-process',
      name: 'NMJsonProcess',
      component: NMJsonProcess,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/eddm-process',
      name: 'EddmProcess',
      component: EddmProcess,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/testing-page',
      name: 'Testing Page',
      component: TestingFile,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/test-page',
      name: 'Test Page',
      component: TestPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/charge-legacy-order/:crm_order_id?',
      name: 'ChargeLegacyOrder',
      component: ChargeLegacyOrder,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/customers',
      component: CustomerIndex,
      children: [
        {
          path: '',
          name: 'Customers',
          component: CustomersAlgolia
        },
        {
          path: 'view/:customerId',
          name: 'CustomerDetails',
          component: CustomerDetails
        },
        {
          path: 'customer-tools',
          name: 'CustomerTools',
          component: CustomerTools
        }
      ],
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/mailing-list-reports',
      name: 'MailingListReports',
      component: MailingListReports,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/products',
      component: ProductsIndex,
      children: [
        {
          path: '',
          name: 'Products',
          component: Products
        },
        {
          path: 'sites/:siteId/view/:productId',
          name: 'ViewProduct',
          component: ManageProduct
        },
        {
          path: 'new/:siteId',
          name: 'AddProduct',
          component: ManageProduct
        },
        {
          path: 'import/:siteId',
          name: 'ImportProduct',
          component: ImportProduct
        },
        {
          path: 'sites/:siteId/edit/:productId',
          name: 'EditProduct',
          component: ManageProduct,
          props: true
        },
        {
          path: 'sites',
          name: 'SiteSelector',
          component: SiteSelector,
          props: true
        },
        {
          path: 'sites/:siteId',
          name: 'SiteSpecificProducts',
          component: Products,
          props: true
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/product-categories',
      component: ProductCategoriesIndex,
      children: [
        {
          path: '',
          name: 'ProductCategories',
          component: ProductCategories
        },
        {
          path: 'view/:productCategoryId',
          name: 'ViewProductCategory',
          component: ManageProductCategory
        },
        {
          path: 'new',
          name: 'AddProductCategory',
          component: ManageProductCategory
        },
        {
          path: 'edit/:productCategoryId',
          name: 'EditProductCategory',
          component: ManageProductCategory
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/product-services',
      component: ProductServicesIndex,
      children: [
        {
          path: '',
          name: 'ProductServices',
          component: ProductServices
        },
        {
          path: 'view/:productServiceId',
          name: 'ViewProductService',
          component: ManageProductService
        },
        {
          path: 'new',
          name: 'AddProductService',
          component: ManageProductService
        },
        {
          path: 'edit/:productServiceId',
          name: 'EditProductService',
          component: ManageProductService
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/product-types',
      component: ProductTypesIndex,
      children: [
        {
          path: '',
          name: 'ProductTypes',
          component: ProductTypes
        },
        {
          path: 'view/:productTypeId',
          name: 'ViewProductType',
          component: ManageProductType
        },
        {
          path: 'new',
          name: 'AddProductType',
          component: ManageProductType
        },
        {
          path: 'edit/:productTypeId',
          name: 'EditProductType',
          component: ManageProductType
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/order-reports',
      name: 'OrderReports',
      component: OrderReports,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/incomplete-order-reports',
      name: 'IncompleteOrderReports',
      component: IncompleteOrderReports,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: '/fourover-statuses',
      component: FouroverStatusesIndex,
      children: [
        {
          path: '',
          name: 'FouroverStatuses',
          component: FouroverStatuses
        },
        {
          path: 'view/:fouroverStatusId',
          name: 'ViewFouroverStatus',
          component: ManageFouroverStatus
        },
        {
          path: 'new',
          name: 'AddFouroverStatus',
          component: ManageFouroverStatus
        },
        {
          path: 'edit/:fouroverStatusId',
          name: 'EditFouroverStatus',
          component: ManageFouroverStatus
        }
      ],
      meta: {
        requiresAuth: true
      }
    }
  ],
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  // Check for requiresAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NO logged user ; redirect to login page
    if (!auth.currentUser) {
      // Go to login
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      // Proceed to route
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    // Check if logged user
    if (auth.currentUser) {
      // Go to login; redirect to home
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      // Proceed to route
      next();
    }
  } else {
    // Proceed to route
    next();
  }
});

export default router;
