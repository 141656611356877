<template>
  <v-container fluid>
    <v-row>
      <v-btn icon large @click="navigateBack()"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <span class="ml-3 pt-1 title font-weight-regular">{{ title }}</span>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <insert-edit-template-type :modelData="templateType" :isEditEnabled="isEditEnabled" :isLoading="isLoading" @saveModel="saveTemplateType"></insert-edit-template-type>
  </v-container>
</template>

<script>
import InsertEditTemplateType from '../../common/dynamicForm/FormGenerator';
import { mapGetters } from 'vuex';

export default {
  components: {
    'insert-edit-template-type': InsertEditTemplateType
  },
  data() {
    return {
      templateTypeDbId: '',
      isEditEnabled: true,
      isLoading: false,
      templateTypeModel: []
    };
  },
  computed: {
    ...mapGetters('template_types', {
      templateType: 'getSelectedModel'
    }),
    title() {
      return this.templateTypeDbId ? `${this.isEditEnabled ? 'Edit' : 'View'}: ${this.templateTypeDbId}` : 'Add New Template Type';
    }
  },
  async mounted() {
    this.isEditEnabled = this.$route.name !== 'ViewTemplateType';
    this.templateTypeDbId = this.$route.params?.templateTypeId;
    if (this.templateTypeDbId) {
      this.getTemplateTypeById();
    } else {
      await this.clearModel();
      await this.initModel([]);
    }
  },
  methods: {
    async getTemplateTypeById() {
      this.$store.dispatch('showLoader');
      try {
        this.templateTypeModel = await this.$store.dispatch('template_types/getTemplateTypeById', this.templateTypeDbId);
        await this.initModel(this.templateTypeModel);
      } catch (error) {
        console.error('[getTemplateTypeById error ] ', error);
        this.showAlert('error', error.message || error || 'Could not load Template');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async saveTemplateType(template_type) {
      this.isLoading = true;
      const templateTypeObject = {
        id: this.templateTypeDbId || '',
        data: template_type
      };
      try {
        const isNameExisting = await this.$store.dispatch('template_types/isNameDuplicated', templateTypeObject);
        if (isNameExisting) {
          this.setSnackbar('error', `A template type with the name "${template_type.name}" already exists.`);
          this.resetForm(templateTypeObject.id);
          return;
        }
        const result = await this.$store.dispatch('template_types/saveTemplateType', templateTypeObject);
        this.setSnackbar('success', result.message);
        this.navigateBack();
      } catch (error) {
        this.showAlert('error', error.message || error);
      } finally {
        this.isLoading = false;
      }
    },
    initModel(modelValue) {
      return this.$store.dispatch('template_types/initSelectedModel', modelValue);
    },
    clearModel() {
      return this.$store.dispatch('template_types/clearModel');
    },
    async resetForm(templateId) {
      this.$store.commit('template_types/setSelectedModel', []);
      await this.clearModel();
      templateId ? this.getTemplateTypeById() : await this.initModel([]);
    },
    async navigateBack() {
      await this.clearModel();
      this.$router.replace(`/${this.$route.path.split('/')[1]}`);
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    }
  }
};
</script>
