import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class OrderSettingsService extends FirestoreModel {
  constructor() {
    super(collections.order_settings);
  }

  async getSteps(siteId, orderType) {
    try {
      const { orderProcesses } = await this.getById(siteId);
      return orderProcesses?.[orderType]?.steps || [];
    } catch (error) {
      console.log('getSteps has failed:', error);
    }
  }
}

export default new OrderSettingsService();
