<template>
  <div>
    <v-layout row>
      <v-row>
        <v-col cols="6" lg="3">
          <p class="headline pt-1 my-4 ml-3">Order balance {{ formattedBalance }}</p>
        </v-col>
        <template v-if="!crm_order_id_param">
          <v-col cols="4">
            <v-text-field v-model="crm_order_id" type="number" label="Osprey order ID" v-on:keyup.enter="searchOrder"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn :disabled="isSearchButtonDisabled" class="mt-3" color="primary" @click="searchOrder()">Search</v-btn>
          </v-col>
        </template>
      </v-row>
      <v-spacer></v-spacer>
    </v-layout>
    <v-alert v-for="(notification, index) in notificationMessages" :key="'error_' + index" type="info" dismissible>
      {{ notification }}
    </v-alert>
    <v-alert v-for="(errorMessage, index) in errorMessages" :key="'notification_' + index" type="error" dismissible>
      {{ errorMessage }}
    </v-alert>

    <v-btn v-if="isChargeButtonVisible" :disabled="isChargeButtonDisabled" class="ml-3" color="success" @click="chargeDialogVisibilityChanged(true)">Charge</v-btn>

    <SearchCustomer
      v-if="crm_customer_id"
      :crm_customer_id="crm_customer_id"
      :crm_order_id="crm_order_id"
      @customerSearchResultError="customerSearchResultError"
      @changedIsStripeCustomerExists="changedIsStripeCustomerExists"
    />
    <ChargeDialog
      :balance="balance"
      :customer="customer"
      :crm_order_id="crm_order_id"
      :open="isChargeDialogOpen"
      @chargeDialogVisibilityChanged="chargeDialogVisibilityChanged"
      @charged="charged"
    />
  </div>
</template>

<script>
import SearchCustomer from '../customer/SearchCustomer';
import ChargeDialog from '../stripe/dialogs/ChargeLegacyOrderDialog';
import formatNumber from 'numeral';
import { mapGetters } from 'vuex';

export default {
  components: {
    SearchCustomer,
    ChargeDialog
  },
  data() {
    return {
      notificationMessages: [],
      errorMessages: [],
      isChargeDialogOpen: false,
      isStripeCustomerExists: false,
      crm_customer_id: null,
      crm_order_id: null
    };
  },
  computed: {
    ...mapGetters('customer_legacy', {
      customer: 'getCustomerSearch'
    }),
    crm_order_id_param() {
      return Number(this.$route.params?.crm_order_id) ?? null;
    },
    ospreyOrder() {
      return this.$store.getters.getOspreyOrder;
    },
    balance() {
      return this.ospreyOrder?.balance ?? null;
    },
    formattedBalance() {
      return this.balance?.balance ? formatNumber(Math.abs(this.balance.balance)).format('$0,0.00') : '-';
    },
    isSearchButtonDisabled() {
      return !this.crm_order_id;
    },
    isChargeButtonDisabled() {
      return !this.customer || !this.customer?.payment_methods?.length;
    },
    isChargeButtonVisible() {
      return this.isStripeCustomerExists && this.balance?.balance < 0;
    }
  },
  watch: {
    ospreyOrder(newOrder) {
      this.crm_customer_id = newOrder?.osprey_customer_id ?? null;
      if (!newOrder?.balance) {
        this.errorMessages.push('You cannot charge this order because cannot get the balance from Osprey');
      } else if (newOrder?.balance?.balance === 0) {
        this.errorMessages.push(`You cannot charge this order because the balance is ${newOrder.balance.balance}`);
      }
    },
    errorMessages(messages) {
      setTimeout(() => {
        if (messages.length > 0) {
          messages.splice(0, 1);
        }
      }, 10000);
    },
    notificationMessages(messages) {
      setTimeout(() => {
        if (messages.length > 0) {
          messages.splice(0, 1);
        }
      }, 5000);
    }
  },
  mounted() {
    this.crm_order_id = this.crm_order_id_param ?? null;
    this.getOspreyOrder();
  },
  methods: {
    async getOspreyOrder() {
      if (this.crm_order_id) {
        this.$store.dispatch('showLoader', { message: 'Loading order from Osprey...' });
        const error = await this.$store.dispatch('getOspreyOrder', {
          url: `/api/orders/osprey?osprey_order_id=${this.crm_order_id}`
        });
        if (error) {
          this.errorMessages.push(error);
        }
        this.$store.dispatch('hideLoader');
      }
    },
    customerSearchResultError(error) {
      this.errorMessages.push(error);
    },
    changedIsStripeCustomerExists(isStripeCustomerExists) {
      this.isStripeCustomerExists = isStripeCustomerExists;
    },
    chargeDialogVisibilityChanged(isOpen) {
      this.isChargeDialogOpen = isOpen;
    },
    charged(result) {
      if (result.error) {
        this.errorMessages.push(result.error);
      } else {
        this.getOspreyOrder();
        this.notificationMessages.push(result.message || 'Charged successfully!');
      }
      this.isChargeDialogOpen = false;
    },
    searchOrder() {
      this.getOspreyOrder();
    }
  }
};
</script>
