<template>
  <div>
    <v-row no-gutters>
      <h3 class="title primary--text">Automated Drop Status Tracker</h3>
    </v-row>

    <DropTrackerFilters v-model="dropStatusFilters"></DropTrackerFilters>
    <v-row class="d-block" no-gutters>
      <stamp-data-table :is-loading="isLoading" :headers="headers" :data="filteredDrops" item-key="mail_drop_id">
        <template #[`item.icon`]="{ item }">
          <v-icon :color="getIconColor(item)">mdi-email-sync-outline</v-icon>
        </template>
        <template #[`item.drop_status`]="{ item }">
          <span class="font-italic">{{ getDropStatusLabel(item.drop_status) }}</span>
        </template>
        <template #[`item.order.customer_unique_id`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn icon v-on="on" @click="copyItemToClipboard(item.order.customer_unique_id)"><v-icon color="blue-grey darken-1">mdi-account</v-icon></v-btn>
            </template>
            <span>Copy Customer Unique ID to clipboard [ {{ item.order.customer_unique_id }} ]</span>
          </v-tooltip>
        </template>

        <template #[`item.order.firebase_order_id`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn icon v-on="on" @click="copyItemToClipboard(item.order.firebase_order_id)"><v-icon color="amber darken-3">mdi-firebase</v-icon></v-btn>
            </template>
            <span>Copy FirebaseID to clipboard [ {{ item.order.firebase_order_id }} ]</span>
          </v-tooltip>
        </template>
        <template #[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon color="blue darken-1" @click="openOrderDetails(item.order.firebase_order_id)" v-on="on">mdi-arrow-right-bold-circle-outline</v-icon>
            </template>
            <span>View Order Details</span>
          </v-tooltip>
        </template>
      </stamp-data-table>
    </v-row>
  </div>
</template>

<script>
import DataTable from '../common/DataTable.vue';
import DropTrackerFilters from './DropTrackerFilters.vue';

export default {
  components: { 'stamp-data-table': DataTable, DropTrackerFilters },
  data() {
    return {
      headers: [
        { text: '', value: 'icon', align: 'right', sortable: false, width: '30' },
        { text: 'Osprey Order ID', value: 'order.crm_order_id', sortable: true },
        { text: 'Campaign Status', value: 'status', sortable: true },
        { text: 'Mail Drop ID', value: 'mail_drop_id', sortable: true },
        { text: 'Drop Nr', value: 'drop_num', sortable: false, align: 'center' },
        { text: 'Drop Date', value: 'drop_date', sortable: true, align: 'center' },
        { text: 'Drop Status', value: 'drop_status', sortable: true },
        { text: 'Osprey Customer ID', value: 'order.crm_customer_id', sortable: true },
        { text: 'Customer Unique ID', value: 'order.customer_unique_id', sortable: false, align: 'center' },
        { text: 'Firebase ID', value: 'order.firebase_order_id', sortable: false, align: 'center' },
        { text: 'Info', value: 'message', align: 'center', sortable: false },
        { text: 'Action', value: 'action', align: 'center', sortable: false }
      ],
      drops: [],
      isLoading: false,
      dropStatusFilters: { orderStatus: '', dropStatus: '' }
    };
  },
  computed: {
    filteredDrops() {
      if (!this.drops?.length) return [];
      const dropStatus = this.dropStatusFilters.dropStatus;
      const orderStatus = this.dropStatusFilters.orderStatus;
      return this.drops.filter(drop => {
        if (dropStatus && orderStatus) {
          return this.isDropStatusMatching(dropStatus, drop) && this.isOrderStatusMatching(orderStatus, drop);
        }
        if (dropStatus) {
          return this.isDropStatusMatching(dropStatus, drop);
        }
        if (orderStatus) {
          return this.isOrderStatusMatching(orderStatus, drop);
        }
        return true;
      });
    }
  },
  watch: {
    dropStatusFilters: {
      handler() {
        console.log('this.dropStatusFilters :>> ', this.dropStatusFilters);
      },
      deep: true
    }
  },
  async created() {
    this.getAutomatedDrops();
  },
  methods: {
    isDropStatusMatching(dropStatus, drop) {
      return dropStatus.includes(drop.drop_status.toLowerCase());
    },
    isOrderStatusMatching(orderStatus, drop) {
      return orderStatus.includes(drop.status.toLowerCase());
    },
    async getAutomatedDrops() {
      try {
        this.isLoading = true;
        this.drops = await this.$store.dispatch('om_orders/getAutomatedDropStatuses');
      } finally {
        this.isLoading = false;
      }
    },
    getIconColor(item) {
      return item.drop_status.toLowerCase() === 'error' ? 'red darken-1' : 'cyan darken-2';
    },
    getDropStatusLabel(status) {
      return status.split('_').join(' ');
    },
    openOrderDetails(firebaseId) {
      this.$router.push({ path: `orders/view/${firebaseId}` });
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    }
  }
};
</script>
