<template>
  <div>
    <v-banner single-line class="title mb-5" @click:icon="back">
      <v-icon slot="icon" class="showPointerOnHover" color="red"> mdi-chevron-left</v-icon>
      DMM Letter Tool

      <template v-if="!isParamsDefined" v-slot:actions>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dense clearable class="mr-2"></v-text-field>
      </template>
    </v-banner>
    <v-row v-if="!isParamsDefined">
      <v-col cols="12">
        <letter-table :search="search" />
      </v-col>
    </v-row>
    <v-row v-else class="justify-center py-2 mx-0">
      <v-col cols="12">
        <v-card>
          <v-row class="justify-space-between px-5 py-2">
            <h4 class="title font-weight-regular pl-1 mt-1">Query parameters</h4>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row class="px-3 grey--text text--darken-2">
            <v-col cols="12" md="6" class="mb-n2">
              <p class="font-weight-medium">
                Osprey Order ID:
                <span class="font-weight-light">{{ crmOrderId }}</span>
              </p>
              <p class="font-weight-medium">
                Osprey Customer ID:
                <span class="font-weight-light">{{ crmCustomerId }} ({{ dmmCustomerEmail }})</span>
              </p>
              <p class="font-weight-medium">
                Date:
                <span class="font-weight-light">{{ startDate }} - {{ endDate }}</span>
              </p>
            </v-col>
            <v-col cols="12" md="6" class="mb-n2">
              <p class="font-weight-medium">
                DMM Campaign ID:
                <span class="font-weight-light">{{ dmmCampaignId }}</span>
              </p>
              <p class="font-weight-medium">
                Mail Drop ID:
                <span class="font-weight-light">{{ crmMailDropID }}</span>
              </p>
              <p class="font-weight-medium">
                Mail Drop #:
                <span class="font-weight-light">{{ dropNum }}</span>
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <dmm-letter
      v-if="isDmmToolInitialized"
      :customerId="crmCustomerId"
      :orderId="crmOrderId"
      :startDate="startDate"
      :endDate="endDate"
      :mailDropId="crmMailDropID"
      :orderData="orderData"
      :dropNr="dropNum"
      :campaignId="dmmCampaignId"
      :isRetry="isRetryRequested"
    >
    </dmm-letter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DmmLetterProcess from './DmmLetterProcess.vue';
import DmmLetterTable from './DmmLetterTable.vue';

export default {
  components: { 'dmm-letter': DmmLetterProcess, 'letter-table': DmmLetterTable },
  data() {
    return {
      isParamsDefined: false,
      startDate: '',
      endDate: '',
      crmMailDropID: '',
      crmOrderId: '',
      dmmCampaignId: '',
      dropNum: '',
      orderData: '',
      crmCustomerId: '',
      dmmCustomerEmail: 'cs@growmail.com',
      isRetryRequested: false,
      isDmmToolInitialized: false,
      search: ''
    };
  },
  computed: {
    ...mapGetters('customer_messaging', {
      dmmCustomer: 'getCustomer'
    }),
    routeQuery() {
      return this.$route.query;
    }
  },
  watch: {
    routeQuery: {
      handler(parameters) {
        console.log('[ Query params ]:', parameters);

        if (!Object.keys(parameters).length) {
          this.isParamsDefined = false;
          this.isDmmToolInitialized = false;
        } else {
          this.isParamsDefined = true;
          this.crmOrderId = parameters.crm_order_id;
          if (!this.crmOrderId && !parameters.firebase_order_id) {
            this.$store.dispatch('showAlert', { type: 'error', message: 'Missing Osprey Order ID or Firebase Order ID!' });
          } else {
            this.getOrderDetails(parameters.firebase_order_id ?? this.crmOrderId);
            this.crmMailDropID = parameters.crm_mail_drop_id;
            this.dmmCampaignId = parameters.hasOwnProperty('dmm_campaign_id') ? parseInt(parameters.dmm_campaign_id) : 0;
            this.dropNum = parameters.drop_num;
            this.startDate = parameters.start_date;
            this.endDate = parameters.end_date;
            this.crmCustomerId = parameters.crm_customer_id;
            this.isRetryRequested = parameters.hasOwnProperty('is_retry') && parseInt(parameters.is_retry) === 1;
          }
        }
      },
      immediate: true
    }
  },
  async created() {
    const customer = await this.$store.dispatch('customer_messaging/fetchActiveCustomer');
    if (customer.status === 'error') {
      this.$store.dispatch('showAlert', { type: 'error', message: 'Customer email error! Please check that just one customer is ON!' });
    }
  },
  methods: {
    async getAssetsByOrderId() {
      this.orderData.assets = await this.$store.dispatch('getOrderDataItemByOrderId', {
        firebase_order_id: this.orderData?.firebase_order_id,
        order_data_item: 'assets'
      });
    },
    async getMailingByOrderId() {
      this.orderData.mailing = await this.$store.dispatch('getOrderDataItemByOrderId', {
        firebase_order_id: this.orderData?.firebase_order_id,
        order_data_item: 'mailing'
      });
    },
    async getOrderDetails(orderId) {
      this.$store.dispatch('showLoader', { message: 'Getting monthly limit, please wait...' });
      this.orderData = isNaN(Number(orderId))
        ? await this.$store
            .dispatch('getOrderByFirebaseId', { id: orderId })
            .catch(error => this.$store.dispatch('showAlert', { type: 'error', message: `Getting OrderData by FirebaseID failed: ${error}` }))
        : await this.$store
            .dispatch('getOrderByOspreyID', { crmOrderId: orderId })
            .catch(error => this.$store.dispatch('showAlert', { type: 'error', message: `Getting OrderData by OspreyID failed: ${error}` }));
      await Promise.all([this.getAssetsByOrderId(), this.getMailingByOrderId()]);
      this.$store.dispatch('hideLoader');
      this.isDmmToolInitialized = true;
    },
    back() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.showPointerOnHover {
  cursor: pointer;
}
</style>
