<template>
  <v-container fluid>
    <v-row>
      <v-btn icon large @click="navigateBack()"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <span class="ml-3 pt-1 title font-weight-regular">{{ title }}</span>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <insert-edit-template-category
      :modelData="templateCategory"
      :isEditEnabled="isEditEnabled"
      :isLoading="isLoading"
      @saveModel="saveTemplateCategory"
    ></insert-edit-template-category>
  </v-container>
</template>

<script>
import InsertEditTemplateCategory from '../../common/dynamicForm/FormGenerator';
import { mapGetters } from 'vuex';

export default {
  components: {
    'insert-edit-template-category': InsertEditTemplateCategory
  },
  data() {
    return {
      templateCategoryDbId: '',
      isEditEnabled: true,
      isLoading: false,
      templateCategoryModel: []
    };
  },
  computed: {
    ...mapGetters('template_categories', {
      templateCategory: 'getSelectedModel'
    }),
    title() {
      return this.templateCategoryDbId ? `${this.isEditEnabled ? 'Edit' : 'View'}: ${this.templateCategoryDbId}` : 'Add New Template Category';
    }
  },
  async mounted() {
    this.isEditEnabled = this.$route.name !== 'ViewTemplateCategory';
    this.templateCategoryDbId = this.$route.params?.templateCategoryId;
    if (this.templateCategoryDbId) {
      this.getTemplateCategoryById();
    } else {
      await this.clearModel();
      await this.initModel([]);
    }
  },
  methods: {
    async getTemplateCategoryById() {
      this.$store.dispatch('showLoader');
      try {
        this.templateCategoryModel = await this.$store.dispatch('template_categories/getTemplateCategoryById', this.templateCategoryDbId);
        await this.initModel(this.templateCategoryModel);
      } catch (error) {
        console.error('[getTemplateCategoryById error ] ', error);
        this.showAlert('error', error.message || error || 'Could not load Template Category');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async saveTemplateCategory(template_category) {
      this.isLoading = true;
      const templateCategoryObject = {
        id: this.templateCategoryDbId || '',
        data: template_category
      };
      try {
        const isNameExisting = await this.$store.dispatch('template_categories/isNameDuplicated', templateCategoryObject);
        if (isNameExisting) {
          this.setSnackbar('error', `A template category with the name "${template_category.name}" already exists.`);
          this.resetForm(templateCategoryObject.id);
          return;
        }
        const result = await this.$store.dispatch('template_categories/saveTemplateCategory', templateCategoryObject);
        this.setSnackbar('success', result.message);
        this.navigateBack();
      } catch (error) {
        this.showAlert('error', error.message || error);
      } finally {
        this.isLoading = false;
      }
    },
    initModel(modelValue) {
      return this.$store.dispatch('template_categories/initSelectedModel', modelValue);
    },
    clearModel() {
      return this.$store.dispatch('template_categories/clearModel');
    },
    async resetForm(templateId) {
      this.$store.commit('template_categories/setSelectedModel', []);
      await this.clearModel();
      templateId ? this.getTemplateCategoryById() : await this.initModel([]);
    },
    async navigateBack() {
      await this.clearModel();
      this.$router.replace(`/${this.$route.path.split('/')[1]}`);
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    }
  }
};
</script>
