import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class ProductPricingService extends FirestoreModel {
  constructor() {
    super(collections.product_pricing);
  }
}

export default new ProductPricingService();
