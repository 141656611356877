import { dbFS } from '@/services/firebaseInit';
import { auth } from '../../services/firebaseInit.js';
import { cloudFunctionsGet, cloudFunctionsPost, cloudFunctionsPut } from '../../modules/cloud-function-wrapper.js';
import moment from 'moment';

// initial state
const state = {
  paymentHistory: [],
  refundsDetails: [],
  isBalanceUpdateInProgress: false,
  isPaymentHistoryLoading: false,
  refunds: {}
};
// mutations
const mutations = {
  setPaymentHistory(state, payload) {
    state.paymentHistory = payload;
  },
  setRefundsDetails(state, payload) {
    state.refundsDetails.push(payload);
  },
  cleanRefundsDetails(state) {
    state.refundsDetails = [];
  },
  setBalanceUpdateInProgress(state, inProgress) {
    state.isBalanceUpdateInProgress = inProgress;
  },
  setPaymentHistoryIsLoading(state, loading) {
    state.isPaymentHistoryLoading = loading;
  },
  setRefunds(state, refunds) {
    state.refunds = refunds;
  }
};
// getters
const getters = {
  getPaymentHistory(state) {
    return state.paymentHistory;
  },
  getRefundsDetails(state) {
    return state.refundsDetails;
  },
  getBalanceUpdateInProgress(state) {
    return state.isBalanceUpdateInProgress;
  },
  getPaymentHistoryIsLoading(state) {
    return state.isPaymentHistoryLoading;
  },
  getRefunds(state) {
    return state.refunds;
  }
};
// actions
const actions = {
  chargeACHPayment({ commit }, payload) {
    return cloudFunctionsPost('/api/payments/charge-payment-method', payload);
  },
  chargeWPPayment({ commit }, payload) {
    return cloudFunctionsPost('/api/payments/charge-payment-method', payload);
  },
  refundPayment({ commit }, payload) {
    return cloudFunctionsPost('/api/payments/refund', payload);
  },
  getPaymentMethodStripeId({ commit }, payload) {
    return dbFS
      .collection('customers')
      .doc(payload.customerId)
      .collection('payment_methods')
      .doc(payload.paymentMethodFirebaseId)
      .get()
      .then(card => {
        const cardData = card.data();
        return cardData.stripe_card ? cardData.stripe_card : cardData.stripe_bank_account ? cardData.stripe_bank_account : '';
      });
  },
  getPaymentHistory({ commit, dispatch }, payload) {
    commit('setPaymentHistory', []);
    commit('setPaymentHistoryIsLoading', true);
    const paymentIds = payload.paymentIds;
    let promises = [];
    let result = [];

    for (let id in paymentIds) {
      promises.push(
        dbFS
          .collection('payments')
          .doc(paymentIds[id])
          .get()
          .then(async querySnapshot => {
            const doc = querySnapshot;
            if (doc.exists) {
              let data = {
                payment_db_id: doc.id,
                ...doc.data()
              };
              //check if new transaction and not sent to Osprey
              data.sent_to_opsrey_error =
                data.paymentType.toUpperCase() === 'STRIPE' || data.paymentType.toUpperCase() === 'REFUND' ? await dispatch('checkTransactionOspreyError', data) : '';
              result.push(data);
            }
          })
          .catch(error => {
            console.log(`Getting payments from database failed: ${error}`);
            commit('setPaymentHistoryIsLoading', false);
          })
      );
    }

    Promise.all(promises).then(data => {
      commit('setPaymentHistoryIsLoading', false);
      commit('setPaymentHistory', result);
    });
  },
  getSubscriptionById({ commit }, payload) {
    return dbFS
      .collection('subscriptions')
      .doc(payload.subscriptionId)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.exists) {
          let subscription = querySnapshot.data();
          subscription.firebase_id = querySnapshot.id;
          return subscription;
        }
        return null;
      })
      .catch(error => {
        console.log('Error getting subscription by id!');
        return { error: error };
      });
  },
  createUsageRecord({ commit }, payload) {
    return cloudFunctionsPost(`/api/subscriptions/usagerecords/${payload.id}`, {
      quantity: payload.quantity,
      timestamp: payload.timestamp,
      action: payload.action
    });
  },
  getUsageRecordList({ commit }, payload) {
    return cloudFunctionsGet(`/api/subscriptions/usagerecords/${payload.id}`);
  },
  getInvoiceLineItemList({ commit }, payload) {
    return cloudFunctionsGet('/api/invoices/invoicelineitems', {
      params: {
        subscription: payload.subscription
      }
    });
  },
  getRefundsDetails({ commit }, payload) {
    commit('cleanRefundsDetails');
    const refundIds = payload.refundIds;
    refundIds.forEach(id => {
      dbFS
        .collection('payments')
        .doc(id)
        .get()
        .then(querySnapshot => {
          commit('setRefundsDetails', querySnapshot.data());
        });
    });
  },
  checkTransactionOspreyError({ commit }, data) {
    let now = moment();
    let paymentDate = moment(data.paymentTime.seconds * 1000);
    let date = moment(paymentDate).add(2, 'minutes');

    if (!data.crmResponse && data.paymentType !== 'refund_pending' && !data.ospreyResponses && data.paymentTime && paymentDate > moment([2020, 4, 1]) && date < now) {
      return 'Not sent to Osprey';
    } else if (data.crmResponse && (!data.crmResponse.success || data.crmResponse.error_msg)) {
      return data.crmResponse.error_msg || 'No error message';
    } else if (data.ospreyResponses && data.ospreyResponses.length > 0) {
      return data.ospreyResponses[data.ospreyResponses.length - 1].success ? '' : data.ospreyResponses[data.ospreyResponses.length - 1].error_msg || 'Error message not found';
    }
    return '';
  },
  loadRefunds({ commit, getters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      try {
        var promises = [];
        if (payload.transactions) {
          let existingRefunds = getters.getRefunds;
          payload.transactions.forEach(transaction => {
            if (!existingRefunds || existingRefunds) {
              if (transaction.refunds) {
                let refundIDs = transaction.refunds.map(refund => refund.refund_db_id);
                refundIDs.forEach(id => {
                  promises.push(
                    dbFS
                      .collection('payments')
                      .doc(id)
                      .get()
                      .then(async querySnapshot => {
                        let data = querySnapshot.data();
                        //check if new refund not sent to Osprey
                        data.sent_to_opsrey_error = await dispatch('checkTransactionOspreyError', data);
                        data.refund_db_id = querySnapshot.id;
                        if (existingRefunds) {
                          existingRefunds[transaction.payment_db_id] = existingRefunds[transaction.payment_db_id] ? existingRefunds[transaction.payment_db_id] : [];

                          let foundRefund = existingRefunds[transaction.payment_db_id].find(refund => refund.refund_db_id === data.refund_db_id);
                          if (!foundRefund) {
                            existingRefunds[transaction.payment_db_id].push(data);
                          }
                        } else {
                          existingRefunds = {};
                          existingRefunds[transaction.payment_db_id] = [data];
                        }
                      })
                  );
                });
              }
            }
          });
          Promise.all(promises).then(() => {
            commit('setRefunds', {});
            commit('setRefunds', existingRefunds);
            resolve();
          });
        } else {
          resolve();
        }
      } catch (error) {
        console.log(`Error while getting refunds: ${error}`);
        reject(error);
      }
    });
  },
  getBalance({ commit }, payload) {
    return cloudFunctionsGet('/api/payments/balance', {
      params: {
        osprey_order_id: payload.osprey_order_id
      }
    });
  },
  async updateOrderBalance({ commit }, payload) {
    if (payload && payload.id && payload.balance) {
      commit('setBalanceUpdateInProgress', true);
      dbFS
        .collection('orders')
        .doc(payload.id)
        .set({ order_balance: payload.balance }, { merge: true })
        .catch(error => {
          throw error;
        })
        .finally(() => {
          commit('setBalanceUpdateInProgress', false);
        });
    }
    return null;
  },
  syncBalance({ commit }, payload) {
    return cloudFunctionsPost('/api/payments/sync', {
      firebase_order_id: payload.firebase_order_id,
      agent_id: payload.agent_id,
      agent_name: payload.agent_name,
      agent_email: payload.agent_email
    });
  },
  checkOspreyAdjustments({ commit }, payload) {
    return cloudFunctionsPost('/api/payments/check-osprey-adjustments', {
      firebase_order_id: payload.firebase_order_id
    });
  },
  sendRequestToSubscriptionEndpoint({ commit }, payload) {
    const endpoint = payload.isAutomated ? `/automated/${payload.endpoint}` : `/api/subscriptions/${payload.endpoint}`;
    return cloudFunctionsPost(endpoint, payload.data);
  },
  retryStripeSubscriptionCharge({ commit, dispatch }, payload) {
    return dispatch('sendRequestToSubscriptionEndpoint', {
      endpoint: 'retry-payment',
      data: payload
    });
  },
  async changeSubscriptionPaymentMethod({ commit, dispatch }, payload) {
    const paymentMethodStripeId = await dispatch('getPaymentMethodStripeId', { customerId: payload.customerId, paymentMethodFirebaseId: payload.paymentMethodFirebaseId });

    return dispatch('sendRequestToSubscriptionEndpoint', {
      endpoint: 'change-default-method',
      data: {
        subscription_firebase_id: payload.subscriptionFirebaseId,
        stripe_method_id: paymentMethodStripeId
      }
    });
  },
  changeSubscriptionStatus({ commit, dispatch }, payload) {
    return dispatch('sendRequestToSubscriptionEndpoint', {
      isAutomated: true,
      endpoint: `${payload.endpoint}AutomatedMailer`,
      data: {
        firebase_order_id: payload.firebase_order_id,
        currentUser: {
          name: auth.currentUser?.displayName,
          email: auth.currentUser?.email
        }
      }
    });
  },
  notifyOspreyOfOrderDataChange({ commit, dispatch }, payload) {
    console.log('[ Send Osprey Notification payload ]:', payload);
    return cloudFunctionsPut('/api/subscriptions/order-data-change', {
      firebase_site_id: payload.firebase_site_id,
      osprey_order_id: payload.osprey_order_id,
      osprey_customer_id: payload.osprey_customer_id,
      firebase_order_id: payload.firebase_order_id,
      map_id: payload.map_id || '',
      summary: payload.summary,
      currentUser: {
        name: auth.currentUser?.displayName,
        email: auth.currentUser?.email
      }
    });
  },
  sendTransactionsToOsprey({ commit }, payload) {
    return cloudFunctionsPost('/api/payments/sendtransactiontocrm', {
      payment_ids: payload.payment_ids
    });
  },
  chargeLegacyOrder({ commit }, payload) {
    return cloudFunctionsPost('/api/payments/legacy-payment', payload);
  },
  createSubscription({ commit }, payload) {
    return cloudFunctionsPost('/api/subscriptions', payload);
  },
  getStripeData({ commit }, payload) {
    return cloudFunctionsGet(`/api/${payload.endpoint}`, { params: payload.params });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
