class Exception {
	constructor(error) {
		const { status, name, code, message, statusCode, status_code, originalError } = error;
		this.status = Number(status || statusCode || status_code || 400);
		this.code = String(code || name || 'unknown');
		this.message = String(message);
		/**
		 * @type {Record<string, any>}
		 */
		this.originalError = originalError || error;
		Error.captureStackTrace(this, this.constructor);
	}
	toString() {
		return `Exception: ${this.message}`;
	}
	toJson() {
		return {
			status: this.status,
			code: this.code,
			message: this.message,
			stack: this.stack,
		};
	}
}

module.exports = { Exception };