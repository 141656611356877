import templateTypeService from '../../services/templateTypeService';
import { dbFS } from '@/services/firebaseInit';

const state = {
  templateTypes: [],
  isTemplateTypesLoading: false,
  templateTypeModel: [
    {
      label: 'Template Type Name',
      model: 'name',
      value: '',
      type: 'text',
      input_type: 'textfield',
      is_required: true,
      rule: [value => !!value || 'Template Type Name is required!']
    }
  ],
  selectedModel: []
};

const getters = {
  getTemplateTypes(state) {
    return state.templateTypes;
  },
  getTemplateTypesLoadingState(state) {
    return state.isTemplateTypesLoading;
  },
  getSelectedModel(state) {
    return state.selectedModel;
  },
  getTemplateTypeModel(state) {
    return state.templateTypeModel;
  }
};

const mutations = {
  clearTemplateTypes(state) {
    state.templateTypes = [];
  },
  setTemplateTypes(state, payload) {
    state.templateTypes = payload;
  },
  setSelectedModel(state, payload) {
    state.selectedModel = payload;
  },
  setTemplateTypesLoadingState(state, payload) {
    state.isTemplateTypesLoading = payload;
  },
  setTemplateTypeModel(state, payload) {
    state.templateTypeModel = payload;
  }
};

const actions = {
  async isNameDuplicated({ dispatch, getters }, payload) {
    await dispatch('getAllTemplateTypes');
    const templateTypes = getters.getTemplateTypes;
    return templateTypes.some(template => template.name === payload.data.name && template.template_type_firebase_id !== payload.id);
  },
  initSelectedModel({ commit, getters }, payload) {
    let templateTypeData = getters.getTemplateTypeModel;

    if (payload) {
      templateTypeData.forEach((modelProperty, index) => {
        if (payload[modelProperty.model]) {
          templateTypeData[index].value = payload[modelProperty.model];
        }
      });
    }
    commit('setSelectedModel', templateTypeData);
  },
  getAllTemplateTypes({ commit }, payload) {
    commit('setTemplateTypesLoadingState', true);
    return new Promise(resolve => {
      dbFS.collection('template_types').onSnapshot(templateSnapshot => {
        commit('clearTemplateTypes');
        const templateTypeData = templateSnapshot.docs.map(template => {
          return { template_type_firebase_id: template.id, ...template.data() };
        });
        commit('setTemplateTypes', templateTypeData);
        commit('setTemplateTypesLoadingState', false);
        resolve(templateTypeData);
      });
    });
  },
  async getTemplateTypeById(_context, payload) {
    try {
      return templateTypeService.getById(payload);
    } catch (error) {
      console.error('Error in getTemplateTypeById:', error);
    }
  },
  addNewTemplateType(_context, payload) {
    try {
      return templateTypeService.add(payload);
    } catch (error) {
      console.error('Error in getTemplateTypeById:', error);
    }
  },
  editTemplateType(_context, payload) {
    try {
      return templateTypeService.update(payload.id, payload.data);
    } catch (error) {
      console.error('Error in getTemplateTypeById:', error);
    }
  },
  deleteTemplateType(_context, payload) {
    try {
      return templateTypeService.delete(payload.template_type_firebase_id);
    } catch (error) {
      console.error('Error in getTemplateTypeById:', error);
    }
  },
  async saveTemplateType({ commit, dispatch }, payload) {
    const functionName = payload.id ? 'editTemplateType' : 'addNewTemplateType';
    const result = await dispatch(functionName, payload);
    return { message: `Template Type saved with id: ${result}` };
  },
  clearModel({ commit, getters }) {
    commit(
      'setTemplateTypeModel',
      getters.getTemplateTypeModel.map(model => ({ ...model, value: '' }))
    );
    commit('setSelectedModel', []);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
