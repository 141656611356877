import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class TemplateService extends FirestoreModel {
  constructor() {
    super(collections.templates);
  }
}

export default new TemplateService();
