<template>
  <div>
    <new-payment-method-dialog
      v-if="isNewPaymentMethodDialogVisible"
      :value="isNewPaymentMethodDialogVisible"
      :customerData="customerId"
      :fbSiteId="customer.firebase_site_id"
      :cardHolderName="customerFullName"
      :type="paymentMethodType"
      @closeNewPaymentMethodDialog="closeNewPaymentMethodDialog"
      @newPaymentMethodAdded="showNewPaymentMethodResult"
    >
    </new-payment-method-dialog>
    <v-card elevation="0">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-expansion-panels v-model="paymentMethodsPanel" focusable hover readonly>
              <v-expansion-panel v-if="customer.firebase_site_id">
                <v-btn text color="cyan darken-2" class="justify-start pl-6" @click="openNewPaymentMethodDialog('card')">
                  <v-icon>mdi-credit-card-plus-outline</v-icon><span class="pl-3">add new card</span>
                </v-btn>
                <v-btn text color="cyan darken-2" class="justify-start pl-6" @click="openNewPaymentMethodDialog('bank_account')">
                  <v-icon>mdi-bank</v-icon><span class="pl-3">add new bank account</span>
                </v-btn>
              </v-expansion-panel>
              <v-expansion-panel v-for="(item, i) in paymentMethodsList" :key="i">
                <v-expansion-panel-header :class="{ cardHiddenStyle: isHidden(item) }">
                  <template v-if="item.data.stripe_card">
                    <span class="d-flex align-center">
                      <img :src="convertTypeToImage(item.data.card_type)" width="60" />
                      <span class="pl-4">{{ item.data.card_type }}</span>
                    </span>
                    <span>**** {{ item.data.last_four }}</span>
                    <span>{{ pad(item.data.exp_month, 2) }}/{{ item.data.exp_year }}</span>
                    <span style="width: 15%">
                      <v-btn v-if="!item.data.is_default" text small rounded color="cyan darken-2" :disabled="isLoading" @click="setCardToDefault(i)">Set as default </v-btn>
                      <v-btn v-else text small rounded disabled class="ml-3"> Default </v-btn>
                    </span>
                    <span>
                      <v-btn v-if="!item.data.is_visible" text small rounded color="cyan darken-2" :disabled="isLoading" @click="setCardVisibility(i, true)"> Show Card </v-btn>
                      <v-btn v-else text small rounded color="cyan darken-2" :disabled="isLoading" @click="setCardVisibility(i, false)"> Hide Card </v-btn>
                    </span>
                    <span>
                      <v-btn text small class="ml-3" color="red darken-1" :disabled="isLoading" @click="removePaymentMethod(i, 'card')"> Remove </v-btn>
                    </span>
                  </template>
                  <template v-else>
                    <span class="d-flex align-center">
                      <v-icon :color="isPaymentMethodStatusVerified(item) ? 'cyan darken-2' : 'red darken-2'">mdi-bank</v-icon
                      ><span class="pl-3 text-uppercase">{{ item.data.type }}</span>
                    </span>
                    <span>**** {{ item.data.last_four }}</span>
                    <span class="text-uppercase">{{ item.data.status }}</span>
                    <span>
                      <v-btn
                        v-if="!item.data.is_default"
                        text
                        small
                        rounded
                        color="cyan darken-2"
                        :disabled="isLoading || !isPaymentMethodStatusVerified(item)"
                        @click="setCardToDefault(i)"
                        >Set as default
                      </v-btn>
                      <v-btn v-else text small rounded disabled class="ml-3"> Default </v-btn>
                    </span>
                    <span>
                      <v-btn v-if="!item.data.is_visible" text small rounded color="cyan darken-2" :disabled="isLoading" @click="setCardVisibility(i, true)"> Show Account </v-btn>
                      <v-btn v-else text small rounded color="cyan darken-2" :disabled="isLoading" @click="setCardVisibility(i, false)"> Hide Account </v-btn>
                    </span>
                    <span class="customer-details-Remove">
                      <v-btn text small class="ml-3" color="red darken-1" :disabled="isLoading" @click="removePaymentMethod(i, 'bank_account')"> Remove </v-btn>
                    </span>
                  </template>
                  <template v-slot:actions>
                    <v-col cols="5" sm="3" md="3" lg="3">
                      <v-icon @click="expandSelected(i)">$expand</v-icon>
                    </v-col>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ul v-if="item.data.stripe_card" class="my-3">
                    <li class="stamp-od-ul">
                      <v-row>
                        <v-col cols="12" sm="3" class="py-0">
                          <p class="font-weight-medium grey--text text--darken-1 my-0">Type</p>
                        </v-col>
                        <v-col cols="12" sm="5" class="py-0">
                          {{ item.data.card_type }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" class="py-0">
                          <p class="font-weight-medium grey--text text--darken-1 my-0">Created</p>
                        </v-col>
                        <v-col v-if="item.data.created_at" cols="12" sm="5" class="py-0">
                          {{ formatDate(item.data.created_at / 1000) }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" class="py-0">
                          <p class="font-weight-medium grey--text text--darken-1 my-0">Expires</p>
                        </v-col>
                        <v-col cols="12" sm="5" class="py-0"> {{ pad(item.data.exp_month, 2) }}/{{ item.data.exp_year }} </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" class="py-0">
                          <p class="font-weight-medium grey--text text--darken-1 my-0">Name</p>
                        </v-col>
                        <v-col cols="12" sm="5" class="py-0">
                          {{ item.data.label }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" class="py-0">
                          <p class="font-weight-medium grey--text text--darken-1 my-0">Crad Number</p>
                        </v-col>
                        <v-col cols="12" sm="5" class="py-0">
                          {{ item.data.last_four }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" class="py-0">
                          <p class="font-weight-medium grey--text text--darken-1 my-0">Stripe ID</p>
                        </v-col>
                        <v-col cols="12" sm="5" class="py-0">
                          {{ item.data.stripe_card }}
                        </v-col>
                      </v-row>
                    </li>
                  </ul>
                  <ul v-else class="my-3">
                    <li class="stamp-od-ul">
                      <v-row>
                        <v-col cols="12" sm="3" class="py-0">
                          <p class="font-weight-medium grey--text text--darken-1 my-0">Type</p>
                        </v-col>
                        <v-col cols="12" sm="5" class="py-0 text-uppercase">
                          {{ item.data.type }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" class="py-0">
                          <p class="font-weight-medium grey--text text--darken-1 my-0">Created</p>
                        </v-col>
                        <v-col v-if="item.data.created_at" cols="12" sm="5" class="py-0">
                          {{ formatDate(item.data.created_at / 1000) }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" class="py-0">
                          <p class="font-weight-medium grey--text text--darken-1 my-0">Status</p>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0 text-uppercase d-flex">
                          <v-tooltip v-if="item.data.status === 'verification_failed'" bottom>
                            <template #activator="{ on }">
                              <v-chip small color="red" text-color="white" class="mt-2" v-on="on"> {{ item.data.status }} </v-chip>
                            </template>
                            <span>{{ getVerificationErrorMessage(item) }}</span>
                          </v-tooltip>
                          <p v-else class="align-self-center">{{ item.data.status }}</p>

                          <v-text-field
                            v-if="!isPaymentMethodStatusVerified(item)"
                            v-model="verificationAmounts[0]"
                            type="number"
                            step="0.01"
                            min="0"
                            max="1"
                            class="ml-3"
                            prefix="$"
                            placeholder="0.32"
                            outlined
                            dense
                          ></v-text-field>
                          <v-text-field
                            v-if="!isPaymentMethodStatusVerified(item)"
                            v-model="verificationAmounts[1]"
                            type="number"
                            step="0.01"
                            min="0"
                            max="1"
                            class="ml-3"
                            prefix="$"
                            placeholder="0.45"
                            outlined
                            dense
                          ></v-text-field>
                          <v-btn v-if="!isPaymentMethodStatusVerified(item)" color="success" class="ml-3" :disabled="isVerifyDisabled" @click="verifybankAccount(item.id)"
                            >Verify</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" class="py-0">
                          <p class="font-weight-medium grey--text text--darken-1 my-0">Name</p>
                        </v-col>
                        <v-col cols="12" sm="5" class="py-0">
                          {{ item.data.account_holder_name }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" class="py-0">
                          <p class="font-weight-medium grey--text text--darken-1 my-0">Crad Number</p>
                        </v-col>
                        <v-col cols="12" sm="5" class="py-0">
                          {{ item.data.last_four }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" class="py-0">
                          <p class="font-weight-medium grey--text text--darken-1 my-0">Stripe ID</p>
                        </v-col>
                        <v-col cols="12" sm="5" class="py-0">
                          {{ item.data.stripe_bank_account }}
                        </v-col>
                      </v-row>
                    </li>
                  </ul>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import NewPaymentMethodDialog from '@/components/stripe/dialogs/NewPaymentMethodDialog.vue';

export default {
  components: {
    'new-payment-method-dialog': NewPaymentMethodDialog
  },
  props: ['paymentMethodsList', 'customer', 'isLoading'],
  data() {
    return {
      customerId: this.$route.params.customerId,
      paymentMethodType: '',
      paymentMethodsPanel: [],
      isNewPaymentMethodDialogVisible: false,
      verificationAmounts: []
    };
  },
  computed: {
    customerFullName() {
      return `${this.customer.first_name} ${this.customer.last_name}`;
    },
    isVerifyDisabled() {
      return this.verificationAmounts.length !== 2 || this.verificationAmounts.includes('');
    }
  },
  methods: {
    openNewPaymentMethodDialog(methodType) {
      this.paymentMethodType = methodType;
      this.isNewPaymentMethodDialogVisible = true;
    },
    closeNewPaymentMethodDialog() {
      this.isNewPaymentMethodDialogVisible = false;
    },
    showNewPaymentMethodResult(message) {
      this.setSnackbar('success', message);
      this.$emit('newPaymentMethodAdded');
    },
    async setCardToDefault(index) {
      try {
        this.$emit('onLoading', true);
        const response = await this.$store.dispatch('payment_methods/setDefaultPaymentMethod', {
          id: this.paymentMethodsList[index].id,
          customer_id: this.customerId
        });
        if (response?.data?.Success) {
          this.setSnackbar('success', 'Successfully changed the default payment method.');
        } else {
          console.log('[ setCardToDefault ] RESPONSE: ', response);
          this.setSnackbar('error', 'Error occurred during setting the default payment method! Please try again!');
        }
      } catch (error) {
        console.log('[ setCardToDefault ] ERROR: ', error);
        this.setSnackbar('error', 'Error occurred during setting the default payment method! Please try again!');
      } finally {
        this.$emit('onLoading', false);
      }
    },
    async removePaymentMethod(index, type) {
      try {
        this.$emit('onLoading', true);
        const response = await this.$store.dispatch('payment_methods/deletePaymentMethod', {
          payment_method_id: this.paymentMethodsList[index].id,
          customer_id: this.customerId,
          type: type
        });
        if (response?.data?.Success) {
          this.setSnackbar('success', 'Payment method successfully deleted!');
        } else {
          console.log('[ removePaymentMethodFromList ] RESPONSE: ', response);
          this.setSnackbar('error', 'Something went wrong deleting the payment method! Please try again!');
        }
      } catch (error) {
        console.log('[ removePaymentMethodFromList ] ERROR: ', error);
        this.setSnackbar('error', 'Something went wrong deleting the payment method! Please try again!');
      } finally {
        this.$emit('onLoading', false);
      }
    },
    expandSelected(index) {
      this.paymentMethodsPanel = index + 1 === this.paymentMethodsPanel ? -1 : index + 1;
    },
    async setCardVisibility(index, isVisible) {
      try {
        this.$emit('onLoading', true);
        await this.$store.dispatch('payment_methods/updatePaymentMethod', {
          customerId: this.customerId,
          paymentMethodId: this.paymentMethodsList[index].id,
          data: { is_visible: isVisible }
        });
      } catch (error) {
        this.setSnackbar('error', 'Something went wrong! We could not change the card visibility.');
        console.log('[setCardVisibility] ERROR:', error);
      } finally {
        this.$emit('onLoading', false);
      }
    },
    async verifybankAccount(id) {
      try {
        this.$store.dispatch('showLoader', { message: 'Verifying bank account...' });
        await this.$store.dispatch('payment_methods/verifyBankAccount', {
          customer_db_id: this.customerId,
          payment_method_db_id: id,
          amounts: this.verificationAmounts.map(amount => this.convertToCents(amount))
        });
        this.setSnackbar('success', 'Account verified.');
      } catch (error) {
        console.log('[verifybankAccount] ERROR:', error);
        this.setSnackbar('error', 'Something went wrong while verifying bank account.');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    isPaymentMethodStatusVerified(item) {
      return item?.data?.status === 'verified';
    },
    getVerificationErrorMessage(item) {
      return item?.data?.verification_errors?.length ? item.data.verification_errors[item.data.verification_errors.length - 1] : '';
    },
    isHidden(item) {
      return item.data.is_visible === false;
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    },
    convertTypeToImage(val) {
      try {
        var images = require.context('@/assets/cards/', false, /\.png$/);
        return images(`./${val.toLowerCase()}.png`);
      } catch (notExists) {
        return images('./creditcards.png');
      }
    },
    formatDate(dateToTransform) {
      const date = new Date(dateToTransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = '0' + num;
      return num;
    },
    convertToCents(dollarAmount) {
      return parseFloat(dollarAmount) * 100;
    }
  }
};
</script>
<style scoped>
.cardHiddenStyle {
  color: #9e9e9e;
  background-color: #f5f5f5;
  opacity: 0.9;
}
</style>
