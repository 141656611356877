<template>
  <div class="site-table">
    <v-row class="mt-n1 mb-2">
      <v-toolbar-title class="headline pl-4 pt-4">All Notifications Logs</v-toolbar-title>
    </v-row>
    <v-card>
      <stamp-data-table
        :headers="headers"
        :data="notifications"
        item-key="date"
        :is-items-per-page-disabled="true"
        :total-server-items-nr="notificationsCount"
        :actions="[{ icon: 'mdi-check', iconColor: 'teal', title: 'Mark as Read', eventName: 'markAsRead' }]"
        @markAsRead="markNotificationReadableStatus"
        @options="getNotifications"
      >
        <template #[`item.date`]="{ item }">{{ formatDate(item.date) }}</template>
        <template #[`item.is_read`]="{ item }">{{ formatMessageReadStatus(item.is_read) }}</template>
      </stamp-data-table>
    </v-card>
  </div>
</template>

<script>
import DataTable from '../common/DataTable.vue';
import { mapGetters } from 'vuex';

export default {
  components: { 'stamp-data-table': DataTable },
  data() {
    return {
      notifications: [],
      sortBy: 'date',
      headers: [
        { text: 'Date', align: 'left', value: 'date', width: '260' },
        { text: 'Message', value: 'message', align: 'left', width: '50%', sortable: false },
        { text: 'Seen', value: 'is_read', align: 'left', width: 100 },
        { text: 'User', value: 'user', align: 'left', width: '20%' }
      ]
    };
  },
  computed: {
    ...mapGetters('users', {
      userStore: 'user'
    }),
    ...mapGetters('notifications', {
      notificationsCount: 'getNotificationsMaxCount'
    })
  },
  methods: {
    getNotifications(page) {
      const currentPage = page?.page || 1;
      this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
      this.$store
        .dispatch('notifications/fetchNotifications', { page: currentPage })
        .then(results => (this.notifications = results))
        .catch(error => console.log(error))
        .finally(() => this.$store.dispatch('hideLoader'));
    },
    markNotificationReadableStatus(notification) {
      this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
      return this.$store
        .dispatch('notifications/changeNotificationReadableState', {
          firestoreID: notification.id,
          is_read: true
        })
        .then(() => {
          this.notifications = [];
          this.getNotifications();
        })
        .catch(error => {
          console.log('Updating notification status failed:', error);
          return Promise.reject(error);
        })
        .finally(() => this.$store.dispatch('hideLoader'));
    },
    formatDate(dateTotransform) {
      return this.$moment(dateTotransform).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    formatMessageReadStatus(status) {
      return status ? 'Yes' : 'No';
    }
  }
};
</script>
