import { cloudFunctionsPost } from '../../modules/cloud-function-wrapper.js';
import { dbFS } from '@/services/firebaseInit';

const state = {
  deleteListReport: {},
  TARGETED_LIST_TYPE: {
    POLITICAL: 'political',
    ORDER: 'order'
  }
};

const getters = {
  getListDeleteReport(state) {
    return state.deleteListReport;
  },
  getTargetedListType(state) {
    return state.TARGETED_LIST_TYPE;
  }
};

const mutations = {
  setListDeleteReport(state, payload) {
    state.deleteListReport = payload.deleteReport;
  }
};

const actions = {
  executeHttpRequest({ commit }, payload) {
    return cloudFunctionsPost(payload.url, payload.data);
  },
  downloadCsvFile({ commit }, payload) {
    const csvBlob = new Blob([payload.csv], { type: 'text/csv;charset=utf-8;' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(csvBlob);
    link.download = `${payload.fileName}.csv`;
    link.click();
  },
  getListCriteria({ commit, dispatch }, payload) {
    const options = payload.requestedOptions;
    const fileName = payload.csvFileName;
    const isBusiness = payload.isBusiness;
    let statusArray = [];
    let adressLine = [];

    adressLine.push('Address');
    Object.entries(options.filter.propositions).forEach(([i, prop]) => {
      if (prop.attribute === 'postal_code') {
        if (prop.value.length === 1) {
          adressLine.push(prop.value[0]);
        } else if (prop.value.length > 1) {
          prop.value.forEach(val => {
            adressLine.push(val);
          });
        }
      }
    });

    statusArray.push(adressLine);
    let selectsLine = [];
    selectsLine.push('Filters');
    statusArray.push(selectsLine);
    Object.entries(options.filter.propositions).forEach(([i, prop]) => {
      let detailsArray = [];
      if (prop.attribute) {
        if (typeof prop.value !== 'object') {
          detailsArray.push(prop.attribute);
          detailsArray.push(typeof prop.value === 'boolean' ? (prop.value ? 'Yes' : 'No') : prop.value);
          statusArray.push(detailsArray);
        } else if (prop.value.length > 0 && prop.attribute !== 'postal_code') {
          detailsArray.push(prop.attribute);
          prop.value.forEach(val => {
            detailsArray.push(val);
          });
          statusArray.push(detailsArray);
        }
        detailsArray = [];
      } else {
        Object.entries(prop).forEach(key => {
          detailsArray.push(key[0]);
          if (typeof Object.values === 'object' && Object.values(key[1]).length > 0) {
            Object.values(key[1]).forEach(val => {
              detailsArray.push(val);
            });
          } else {
            detailsArray.push(key[1]);
          }
          statusArray.push(detailsArray);
          detailsArray = [];
        });
      }
    });
    statusArray.push(['Limit', payload.limit]);
    statusArray.push(['Business', isBusiness ? 'Yes' : 'No']);

    return dispatch('executeHttpRequest', {
      url: '/getScroll',
      data: { options, isBusiness }
    })
      .then(async response => {
        console.log('[ GetScroll Data ]:', response.data);
        const requestedCount = response.data.count;
        statusArray.push(['Total', requestedCount]);

        const csv = await dispatch('logs/convertToCSV', { objArray: statusArray }, { root: true });
        dispatch('downloadCsvFile', { csv, fileName });

        return { requestedCount };
      })
      .catch(error => {
        console.log('Error during getting scroll:', error);
        return error;
      });
  },
  async getPoliticalListCriteria({ commit, dispatch }, payload) {
    const fileName = payload.fileName;
    const options = payload.requestedOptions;
    const listCount = payload.listCount;
    let statusArray = [];

    statusArray.push(['Filters', 'Results']);
    if (options.filters) {
      Object.entries(options.filters).forEach(([filterName, filterValue]) => {
        if (Object.values(filterValue).length > 1) {
          if (typeof filterValue[0] !== 'object') {
            statusArray.push([filterName, filterValue[0]]);
          }
          Object.values(filterValue).forEach((value, index) => {
            if (index > 0) {
              if (typeof value === 'object') {
                let objectFilters = [];
                objectFilters.push('');
                Object.entries(value).forEach(objVals => {
                  objectFilters.push(objVals[1] === true ? 'Yes' : objVals[1] === false ? 'No' : objVals[1]);
                });
                statusArray.push(objectFilters);
                objectFilters = [];
              } else {
                statusArray.push(['', value === true ? 'Yes' : value === false ? 'No' : value]);
              }
            } else if (typeof value === 'object') {
              let complexFilter = [];
              complexFilter.push(filterName);
              Object.entries(value).forEach(objVals => {
                complexFilter.push(objVals[1] === true ? 'Yes' : objVals[1] === false ? 'No' : objVals[1]);
              });
              statusArray.push(complexFilter);
              complexFilter = [];
            }
          });
        } else if (Object.values(filterValue).length === 1) {
          if (typeof filterValue[0] !== 'object') {
            statusArray.push([filterName, filterValue[0]]);
          } else {
            let detailsArray = [];
            detailsArray.push(filterName);
            Object.entries(filterValue[0]).forEach((objVals, index) => {
              detailsArray.push(objVals[1] === true ? 'Yes' : objVals[1] === false ? 'No' : objVals[1]);
            });
            statusArray.push(detailsArray);
            detailsArray = [];
          }
        }
      });
      statusArray.push(['Total', listCount]);

      const csv = await dispatch('logs/convertToCSV', { objArray: statusArray }, { root: true });
      dispatch('downloadCsvFile', { csv, fileName });
    } else {
      console.log('There is no filter selected!');
      return { status: 'error', message: 'There is no filter selected!' };
    }
  },
  async generateListCsv({ commit, dispatch }, payload) {
    const firebaseId = payload.firebaseId;
    let storageFileName = '';
    let csvLineCount = 0;
    let passingObj = payload.requestedOptions;
    passingObj.params.fileName = payload?.filename;
    if (payload.targetedAmountLimit) {
      passingObj.params.records = payload.targetedAmountLimit;
    }

    passingObj.params.records = process.env?.VUE_APP_NODE_ENV !== 'production' ? 5 : passingObj.params.records;

    try {
      const response = await cloudFunctionsPost('/targetedProvider/getRecordsList', passingObj);
      if (!response?.data?.order?.Id) {
        throw new Error('Error generating CSV from MELISSA DATA');
      }
      console.log('[ Generate CSV from MELISSA response]:', response);
      storageFileName = response.data.fileName;
      if (response.data.order.DeliveredQty) {
        csvLineCount = response.data.order.DeliveredQty;
      }
      const data = await dispatch('customer_messaging/checkStoragePathMailingLists', { storagePath: storageFileName, firebaseID: firebaseId }, { root: true });

      console.log('[ Generate CSV from MELISSA response]: [Firebase url]', data);
      if (data?.error || !data?.url) {
        console.log('Error getting csv download url:', data.error);
        throw new Error('Error getting csv download url');
      }
      return {
        csvFileExist: true,
        csvLink: data.url,
        storageFileName,
        csvLineCount
      };
    } catch (error) {
      console.log('Error generating CSV from MELISSA DATA:', error);
      throw error;
    }
  },
  generatePoliticalListCsv({ commit, dispatch }, payload) {
    const requestedOptions = payload.requestedOptions;
    let storageFileName = '';
    let passingObj = {
      ...payload.politicalFilters,
      state: requestedOptions.state,
      filename: payload.filename
    };
    if (payload.targetedAmountLimit) {
      passingObj.limit = payload.targetedAmountLimit;
    }
    return dispatch('executeHttpRequest', {
      url: '/generateCsvFromPoliticalMail',
      data: passingObj
    })
      .then(response => {
        storageFileName = response.data.data.filename;
        return dispatch('customer_messaging/checkStoragePathMailingLists', { storagePath: storageFileName, firebaseID: payload.firebaseId }, { root: true }).then(data => {
          if (data.url) {
            return {
              csvLink: data.url,
              storageFileName
            };
          } else if (data.error) {
            console.log('Error getting csv download url:', data.error);
            throw data.error;
          }
        });
      })
      .catch(error => {
        console.log('Generating Political CSV failed:', error);
        throw { error };
      });
  },
  generateListByFilters({ dispatch }, { url, headers, data }) {
    console.log('[ generateListByFilters ] payload:', { url, headers, data });
    return cloudFunctionsPost(url, data, headers);
  },
  addListDeleteReportLog({ commit }, { reportObjectName, type }) {
    commit('setListDeleteReport', {
      deleteReport: {
        [reportObjectName]: type
      }
    });
  },
  deleteMailingList({ commit, dispatch, getters }, payload) {
    const baseIndexName = `mailing_lists${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`;
    const customIndexName = `site_${payload.listObject.siteDbId}_mailing_list`;
    const references = payload.listRef;
    const listFirebaseId = payload.listObject.firebase_list_id;
    const promises = [];

    if (references.length) {
      const listFileName = references[0].listFileName;
      const listFilePath = references[0].listPath;

      promises.push(
        dispatch('customer_messaging/deleteFileFromStorage', { path: listFilePath, fileName: listFileName }, { root: true })
          .then(result => {
            console.log('delete uploadedList result: ', result);
            commit('setListDeleteReport', {
              deleteReport: {
                deleteUploadedList: 'success'
              }
            });
            return Promise.resolve();
          })
          .catch(error => {
            console.log('error during deleting uploaded list from Storage:', error);
            commit('setListDeleteReport', {
              deleteReport: {
                deleteUploadedList: 'error'
              }
            });
            return Promise.reject();
          })
      );
    }
    // Algolia - delete list from mailinglist index
    promises.push(
      dispatch(
        'algolia/deleteOrderFromAlgoliaIndex',
        {
          orderID: listFirebaseId,
          indexName: baseIndexName
        },
        { root: true }
      )
        .then(result => {
          console.log('[ delete list from Algolia ]:', baseIndexName, 'result:', result.data);
          dispatch('addListDeleteReportLog', {
            reportObjectName: 'deleteListFromAlgoliaIndex',
            type: 'success'
          });
          return Promise.resolve();
        })
        .catch(error => {
          console.log('An error occured during delete item from Algolia:', error);
          dispatch('addListDeleteReportLog', {
            reportObjectName: 'deleteListFromAlgoliaIndex',
            type: 'error'
          });
          return Promise.reject(error);
        })
    );
    // delete list from custom index
    promises.push(
      dispatch(
        'algolia/deleteOrderFromAlgoliaIndex',
        {
          orderID: listFirebaseId,
          indexName: customIndexName
        },
        { root: true }
      )
        .then(result => {
          console.log('[ delete list from Algolia ]:', customIndexName, 'result:', result.data);
          dispatch('addListDeleteReportLog', {
            reportObjectName: 'deleteListFromAlgoliaCustomIndex',
            type: 'success'
          });
          return Promise.resolve();
        })
        .catch(error => {
          console.log('An error occured during delete item from Algolia:', error);
          dispatch('addListDeleteReportLog', {
            reportObjectName: 'deleteListFromAlgoliaCustomIndex',
            type: 'error'
          });
          return Promise.reject(error);
        })
    );
    // Delete map from Firebase
    promises.push(
      dbFS
        .collection('mailingLists')
        .doc(listFirebaseId)
        .delete()
        .then(() => {
          console.log('List: ' + listFirebaseId + ' deleted From Firebase');
          dispatch('addListDeleteReportLog', {
            reportObjectName: 'deleteFirebaseList',
            type: 'success'
          });
          return Promise.resolve();
        })
        .catch(error => {
          console.log('Error on delete ' + listFirebaseId + ':' + error);
          dispatch('addListDeleteReportLog', {
            reportObjectName: 'deleteFirebaseList',
            type: 'error'
          });
          return Promise.reject();
        })
    );
    return Promise.all(promises)
      .then(() => {
        console.log(' Delete list from Algolia(map and custom index) and from Firebase!');
        dispatch('addListDeleteReportLog', {
          reportObjectName: 'deleteAll',
          type: 'success'
        });
        return Promise.resolve();
      })
      .catch(error => {
        console.log(`Deleting all catch some error: ${error}`);
        dispatch('addListDeleteReportLog', {
          reportObjectName: 'deleteAll',
          type: 'error'
        });
        return Promise.reject();
      });
  }
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
};
