<template>
  <v-container v-if="customer" fluid>
    <edit-customer-info-dialog
      v-if="isEditCustomerInfoDialogVisible"
      :value="isEditCustomerInfoDialogVisible"
      :customer-data="customer"
      :customer-id="customerId"
      @close="closeEditCustomerInfoDialog"
      @update="showCustomerUpdateResult"
    ></edit-customer-info-dialog>

    <v-row class="justify-space-between mb-n2">
      <v-col cols="12">
        <v-btn icon to="/customers">
          <v-icon color="grey">mdi-arrow-left</v-icon>
        </v-btn>
        <span class="title font-weight-medium grey--text text--darken-2">
          Customers
          <v-icon class="mx-2">mdi-chevron-right</v-icon>
          {{ customerFullName }}
        </span>
      </v-col>
    </v-row>

    <v-row class="justify-center mt-3">
      <v-col cols="12" xl="9">
        <v-row class="justify-space-between">
          <v-col cols="12" md="6">
            <customer-info-card
              icon-type="avatar"
              :icon="getInitials"
              :title="customerFullName"
              :content="customer.email"
              action="setName"
              action-icon="mdi-pencil"
              action-text="Set Name"
              @customerEdit="openEditCustomerInfoDialog"
            />
          </v-col>
          <v-col cols="12" md="6">
            <customer-info-card
              icon-type="image"
              icon="@/assets/logos/ospreyLogo.png"
              title="Osprey ID"
              :content="customer.crm_customer_id"
              action="openInOsprey"
              action-icon="mdi-eye"
              action-text="View Customer in Osprey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <customer-info-card icon-type="icon" icon="mdi-briefcase-outline" title="Company" :content="customer.company" />
          </v-col>
          <v-col cols="12" md="6">
            <customer-info-card icon-type="icon" icon="mdi-calendar-account" title="User created at" :content="customer.created_at" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" xl="9">
        <v-expansion-panels v-model="panel" popout>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <p class="subheading font-weight-medium">Account Information</p>
                <p v-if="panel !== 0" class="body-2 font-weight-medium grey--text text--darken-1">Identifiers &amp; Address Information</p>
              </div>
              <v-col cols="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon @click.stop="openEditCustomerInfoDialog" v-on="on"><v-icon color="cyan darken-2">mdi-pencil</v-icon></v-btn>
                  </template>
                  <span>Edit Customer Details</span>
                </v-tooltip>
              </v-col>
            </v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content>
              <customer-info-panel :customer="customer" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <p class="subheading font-weight-medium">Tax Information</p>
                <p v-if="panel !== 1" class="body-2 font-weight-medium grey--text text--darken-1">Tax exempt status of the customer</p>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <customer-tax-panel :customer="customer" />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel @click="loadPaymentMethods">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <div>
                  <p class="subheading font-weight-medium">Payment Methods</p>
                  <v-row v-if="open && isCustomerOnCampSite" class="mb-n8 align-center">
                    <v-col cols="auto" class="ml-5">
                      <span class="subheading">Cash on Delivery</span>
                    </v-col>
                    <v-col cols="auto">
                      <v-switch v-model="isCashOnDeliveryEnabled" color="cyan darken-2" @change="saveCashOnDelivery" @click.stop></v-switch>
                    </v-col>
                  </v-row>
                  <p v-if="!open" class="body-2 font-weight-medium grey--text text--darken-1">Used credit / debit cards</p>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-progress-linear v-if="isLinearLoaderVisible" indeterminate color="cyan darken-2"></v-progress-linear>
            <v-expansion-panel-content>
              <customer-payment-methods
                :payment-methods-list="paymentMethodsList"
                :customer="customer"
                :is-loading="isLinearLoaderVisible"
                @newPaymentMethodAdded="getPaymentMethods"
                @onLoading="setLoader"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <p class="subheading font-weight-medium">Orders</p>
                <p v-if="panel !== 3" class="body-2 font-weight-medium grey--text text--darken-1">All orders made by this customer</p>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" class="site-table">
                  <stamp-data-table
                    :headers="ordersHeaders"
                    :data="orders"
                    :total-server-items-nr="totalOrders"
                    item-key="firebase_order_id"
                    :is-items-per-page-disabled="true"
                    :sort-desc="true"
                    sort-by="creation_date_timestamp"
                    @options="optionsHandler($event, 'orders')"
                  >
                    <template #[`item.status`]="{ item }">
                      <v-chip v-if="isIncomplete(item.order_status)" color="red" text-color="white">{{ item.order_status.status }}</v-chip>
                      <v-chip v-else color="green lighten-4" text-color="green darken-3">{{ item.order_status.status }}</v-chip>
                    </template>
                    <template #[`item.creation_date_timestamp`]="{ item }">
                      <span>{{ formatDate(item.creation_date_timestamp) }}</span>
                    </template>
                    <template #[`item.order_total`]="{ item }">
                      <span>${{ formatDecimal(item.order_total) }}</span>
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn :to="`/orders/view/${item.firebase_order_id}`" target="_blank" icon v-on="on"><v-icon color="blue-grey">mdi-eye</v-icon></v-btn>
                        </template>
                        <span>View Order</span>
                      </v-tooltip>
                    </template>
                  </stamp-data-table>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <p class="subheading font-weight-medium">Maps</p>
                <p v-if="panel !== 4" class="body-2 font-weight-medium grey--text text--darken-1">All maps created by this customer</p>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" class="site-table">
                  <stamp-data-table
                    :headers="mapsHeaders"
                    :data="maps"
                    :total-server-items-nr="totalMaps"
                    item-key="firebase_map_id"
                    :is-items-per-page-disabled="true"
                    sort-by="creationDateTimestamp"
                    @options="optionsHandler($event, 'maps')"
                  >
                    <template #[`item.creationDateTimestamp`]="{ item }">
                      <span>{{ formatDate(item.creationDateTimestamp) }}</span>
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn :to="`/maps/view/${item.firebase_map_id}`" target="_blank" icon v-on="on">
                            <v-icon color="blue-grey">mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>View Map</span>
                      </v-tooltip>
                    </template>
                  </stamp-data-table>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <p class="subheading font-weight-medium">Lists</p>
                <p v-if="panel !== 5" class="body-2 font-weight-medium grey--text text--darken-1">All purchased/uploaded list by this customer</p>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" class="site-table">
                  <stamp-data-table
                    :headers="mailingListsHeaders"
                    :data="mailingLists"
                    :total-server-items-nr="totalMailingLists"
                    item-key="firebase_list_id"
                    :is-items-per-page-disabled="true"
                    sort-by="creationDateTimestamp"
                    @options="optionsHandler($event, 'mailing_lists')"
                  >
                    <template #[`item.creationDateTimestamp`]="{ item }">
                      <span>{{ formatDate(item.creationDateTimestamp) }}</span>
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn :to="`/list/view/${item.firebase_list_id}`" target="_blank" icon v-on="on"><v-icon color="blue-grey">mdi-eye</v-icon></v-btn>
                        </template>
                        <span>View List</span>
                      </v-tooltip>
                    </template>
                  </stamp-data-table>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import EditCustomerInfoDialog from './dialogs/EditCustomerInfoDialog.vue';
import CustomerInfoCard from './panels/CustomerInfoCard.vue';
import CustomerInfoPanel from './panels/CustomerInfoPanel.vue';
import CustomerTaxPanel from './panels/CustomerTaxPanel.vue';
import CustomerPaymentMethods from './panels/CustomerPaymentMethods.vue';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: {
    'edit-customer-info-dialog': EditCustomerInfoDialog,
    'customer-info-card': CustomerInfoCard,
    'customer-info-panel': CustomerInfoPanel,
    'customer-tax-panel': CustomerTaxPanel,
    'customer-payment-methods': CustomerPaymentMethods,
    'stamp-data-table': DataTable
  },
  data() {
    return {
      customerId: this.$route.params.customerId,
      panel: 0,
      orders: [],
      totalOrders: 0,
      ordersHeaders: [
        { text: 'Order Label', value: 'label', align: 'left', sortable: false },
        { text: 'Status', value: 'status', align: 'left', sortable: false },
        { text: 'Date', align: 'left', value: 'creation_date_timestamp', sortable: false },
        { text: 'Order Total', value: 'order_total', align: 'left', sortable: false },
        { text: 'Order Email', value: 'order_email', align: 'left', sortable: false },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false }
      ],
      maps: [],
      totalMaps: 0,
      mapsHeaders: [
        { text: 'Map Name', value: 'name', align: 'left', sortable: false },
        { text: 'Type', value: 'orderType', align: 'center', sortable: false },
        { text: 'Emap Mode', value: 'emapMode', align: 'center', sortable: false },
        { text: 'Total Count', value: 'totalCount', align: 'center', sortable: false },
        { text: 'Email', value: 'customer.email', align: 'center', sortable: false },
        { text: 'Date', align: 'center', value: 'creationDateTimestamp', sortable: false },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false }
      ],
      mailingLists: [],
      totalMailingLists: 0,
      mailingListsHeaders: [
        { text: 'List Name', value: 'listName', align: 'left', sortable: false },
        { text: 'Type', value: 'type', align: 'center', sortable: false },
        { text: 'List Count', value: 'listCount', align: 'center', sortable: false },
        { text: 'Date', align: 'center', value: 'creationDateTimestamp', sortable: false },
        { text: 'Email', value: 'customer.userEmail', align: 'center', sortable: false },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false }
      ],
      isLinearLoaderVisible: false,
      isEditCustomerInfoDialogVisible: false,
      isCashOnDeliveryEnabled: false,
      termId: 0,
      customerSiteType: ''
    };
  },
  computed: {
    ...mapGetters('customers', {
      customer: 'getCustomer'
    }),
    ...mapGetters('payment_methods', {
      paymentMethodsList: 'getExtendedPaymentMethods'
    }),
    customerFullName() {
      return `${this.customer.first_name} ${this.customer.last_name}`;
    },
    getInitials() {
      const firstChar = this.customer?.first_name?.charAt(0).toUpperCase() || '';
      const secondChar = this.customer?.last_name?.charAt(0).toUpperCase() || '';
      return firstChar + secondChar;
    },
    isCustomerOnCampSite() {
      return this.customerSiteType === 'obopp';
    }
  },
  mounted() {
    this.getCustomerData();
  },
  methods: {
    async getCustomerData() {
      try {
        this.$store.dispatch('showLoader', { message: 'Loading customer...' });
        await this.$store.dispatch('customers/loadCustomer', { customerId: this.customerId });
        this.customerSiteType = await this.$store.dispatch('customers/getCustomerSiteType', this.customer.firebase_site_id);
      } catch (error) {
        this.$store.dispatch('showAlert', { type: 'error', message: 'An error occurred while loading customer data.' });
        console.log('[getCustomerData] ERROR:', error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async saveCashOnDelivery() {
      console.log('Updating customer C.O.D.', this.customerId);
      this.termId = this.isCashOnDeliveryEnabled ? 20 : 0; // termId can be 0 (prepay) or 20 (cash on delivery)
      console.log('Cash On Delivery Value:', this.termId);
      try {
        this.$store.dispatch('showLoader', { message: 'Saving customer...' });
        const response = await this.$store.dispatch('customers/updateCustomer', { id: this.customerId, data: { term_id: this.termId } });
        this.$emit('update', { type: 'success', message: `Customer [${response}] updated!` });
      } catch (error) {
        console.log('[updateCustomerCashOnDelivery] ERROR:', error);
        this.$store.dispatch('showAlert', { type: 'error', message: 'An error occurred while loading customer data.' });
      } finally {
        this.$store.dispatch('hideLoader');
      }
      console.log('Saving Cash on Delivery setting:', this.isCashOnDeliveryEnabled);
    },
    openEditCustomerInfoDialog() {
      this.isEditCustomerInfoDialogVisible = true;
    },
    closeEditCustomerInfoDialog() {
      this.isEditCustomerInfoDialogVisible = false;
    },
    showCustomerUpdateResult(response) {
      this.closeEditCustomerInfoDialog();
      this.setSnackbar(response.type, response.message);
    },
    getDataFromAlgolia({ indexName, searchValue = this.customer.customer_unique_id, page }) {
      return this.$store.dispatch('algolia/getDataFromAlgolia', { indexName, page, searchValue });
    },
    async optionsHandler(options, index) {
      try {
        const { page } = options || 0;
        const indexName = `${index}${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`;
        const selectedCollection = this.toCamelCase(index);
        const totalSelectedCollection = `total${this.capitalize(selectedCollection)}`;

        const content = await this.getDataFromAlgolia({ indexName: indexName, page: page > 1 ? page - 1 : 0 });
        this[selectedCollection] = content.hits;
        this[totalSelectedCollection] = content.nbHits;
      } catch (error) {
        this.setSnackbar('error', `Getting ${index} from Algolia failed.`);
        console.log('[ optionsHandler ] ERROR:', error);
      }
    },
    isIncomplete(order_status) {
      return order_status?.status === 'incomplete';
    },
    async getPaymentMethods() {
      try {
        this.isLinearLoaderVisible = true;
        await this.$store.dispatch('payment_methods/getPaymentMethodsData', {
          customerId: this.customerId,
          showAllCards: true
        });
        this.isCashOnDeliveryEnabled = this.customer?.cash_on_delivery_enabled ?? false;
      } catch (error) {
        this.setSnackbar('error', 'Could not load payment methods for this customer');
        console.log('[ getPaymentMethods ] ERROR:', error);
      } finally {
        this.isLinearLoaderVisible = false;
      }
    },
    loadPaymentMethods() {
      if (this.panel !== 1) {
        this.getPaymentMethods();
      }
    },
    setLoader(value) {
      this.isLinearLoaderVisible = value;
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    },
    capitalize(text) {
      return (
        text
          ?.toLowerCase()
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ') || ''
      );
    },
    toCamelCase(text) {
      return text.replace(/_./g, word => word.slice(1).toUpperCase()) || '';
    },
    formatDate(dateToTransform) {
      const date = new Date(dateToTransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    formatDecimal(number) {
      return parseFloat(number).toFixed(2);
    }
  }
};
</script>
