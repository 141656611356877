<template>
  <v-dialog v-model="addNewPaymentMethodDialog" max-width="700px">
    <v-card>
      <v-card-title>
        <v-row class="justify-space-between mt-0 mb-2">
          <template v-if="type === 'card'">
            <h1 class="headline ml-3"><v-icon large color="cyan darken-2" class="mb-1 mr-2">mdi-credit-card-plus-outline</v-icon>Add New Card</h1>
          </template>
          <template v-else-if="type === 'bank_account'">
            <h1 class="headline ml-3"><v-icon large color="cyan darken-2" class="mb-1 mr-2">mdi-bank-plus</v-icon>Add New Bank Account</h1>
          </template>
          <v-icon v-else color="green" class="ml-2 mt-n2">mdi-record</v-icon>
          <v-spacer></v-spacer>
          <v-btn icon large color="red darken-1" class="mt-n1" @click="closeNewPaymentMethodDialog()"><v-icon>mdi-close</v-icon></v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="message !== ''" type="error">{{ message }}</v-alert>
        <v-row class="justify-center align-center">
          <v-progress-linear v-if="loadingSpinner" indeterminate color="cyan darken-2"></v-progress-linear>
        </v-row>
        <div :class="`px-4 ${loadingSpinner ? 'loadingInProgress' : ''}`">
          <template v-if="type === 'card'">
            <v-row>
              <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                <span class="subtitle-1">Cardholder Name</span>
                <v-text-field v-model="newCardData.cardHolderName" outlined dense :rules="[v => !!v || 'Cardholder name is required!']"> </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                <span class="subtitle-1">Card Number</span>
                <div id="card-number"></div>
              </v-col>
              <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="ml-n3 mt-6">
                <img v-if="newCardData.cardType === 'amex'" src="@/assets/cards/card-americanexpress.svg" width="48px" class="mt-n1 ml-2" />
                <img v-if="newCardData.cardType === 'mastercard'" src="@/assets/cards/card-mastercard.svg" width="50px" class="mt-n1 ml-2" />
                <img v-if="newCardData.cardType === 'visa'" src="@/assets/cards/card-visa.svg" width="48px" class="mt-n1 ml-2" />
                <img v-if="newCardData.cardType === 'discover'" src="@/assets/cards/discover.svg" width="59px" class="mt-n2 ml-2" />
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col cols="12" sm="4" md="4" lg="5" xl="4">
                <span class="subtitle-1">Expiry Date</span>
                <div id="card-expiry"></div>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                <v-row class="align-center justify-center mt-7 ml-9">
                  <div id="card-errors" class="title red--text" role="alert"></div>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-n3">
              <v-col cols="12" sm="4" md="4" lg="5" xl="4">
                <span class="subtitle-1">Security Code (CVC)</span>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon color="info" dark class="ml-2 mb-1" v-on="on">mdi-information</v-icon>
                  </template>
                  <span>Last 3 or 4 numbers on the back or front.</span>
                </v-tooltip>
                <div id="card-cvc"></div>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="type === 'bank_account'">
            <v-row>
              <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                <span class="subtitle-1">Account Holder Name</span>
                <v-text-field v-model="bankAccountData.name" outlined dense :rules="[v => !!v || 'Name is required!']"> </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                <span class="subtitle-1">Routing Number</span>
                <v-text-field v-model="bankAccountData.routingNumber" outlined dense :rules="[v => !!v || 'Routing Number is required!']"> </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                <span class="subtitle-1" @wheel="$event.target.blur()">Account Number</span>
                <v-text-field v-model="bankAccountData.accountNumber" outlined dense :rules="[v => !!v || 'Account Number is required!']"> </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                <span class="subtitle-1">Account Type</span>
                <v-radio-group v-model="bankAccountData.accountType" row>
                  <v-radio label="Individual" value="individual"></v-radio>
                  <v-radio label="Company" value="company"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="bankAccountError" class="mt-n3">
              <v-col cols="12">
                <div class="title red--text" role="alert">{{ bankAccountError }}</div>
              </v-col>
            </v-row>
          </template>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row class="mx-4 mb-2 mt-n2">
          <v-btn v-if="type === 'card'" color="success" :disabled="loadingSpinner" @click="addNewCard()">Add Card</v-btn>
          <v-btn
            v-else-if="type === 'bank_account'"
            color="success"
            :disabled="loadingSpinner || bankAccountError !== '' || bankAccountData.name === '' || bankAccountData.accountNumber === ''"
            @click="addBankAccount()"
            >Add Bank Account</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import siteSettingsService from '../../../services/siteSettingsService';

export default {
  props: ['value', 'customerData', 'fbSiteId', 'cardHolderName', 'type'],
  data() {
    return {
      addNewPaymentMethodDialog: this.value,
      newCardData: {
        cardHolderName: this.cardHolderName,
        isDefaultCard: false,
        cardType: ''
      },
      newCard: {
        cardNumber: {},
        cardExpiry: {},
        cardCvc: {}
      },
      message: '',
      customerId: this.customerData,
      loadingSpinner: false,
      firebaseSiteId: this.fbSiteId,
      stripe_live_key: '',
      stripe_test_key: '',
      stripe: null,
      stripeElementsStyle: {
        base: {
          iconColor: '#37474F',
          color: '#37474F',
          fontWeight: 500,
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '22px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: 'black'
          },
          '::placeholder': {
            color: 'grey'
          }
        },
        invalid: {
          iconColor: 'red',
          color: 'red'
        }
      },
      bankAccountData: {
        name: '',
        //iban: '',
        routingNumber: '',
        accountNumber: '',
        accountType: 'individual'
      },
      bankAccountError: ''
    };
  },
  watch: {
    value(value) {
      this.addNewPaymentMethodDialog = this.value;
    },
    'bankAccountData.routingNumber'(newValue) {
      this.bankAccountError = '';
    },
    'bankAccountData.accountNumber'(newValue) {
      this.bankAccountError = '';
    }
  },
  async mounted() {
    try {
      if (this.firebaseSiteId) {
        const siteSettings = await siteSettingsService.getById(`${this.firebaseSiteId}`);
        this.stripe_live_key = siteSettings.stripe_live_key;
        this.stripe_test_key = siteSettings.stripe_test_key;

        this.stripe = window.Stripe(this.stripe_live_key);
        if (this.type === 'card') {
          this.createStripeCardElement();
        } else if (this.type === 'bank_account') {
          this.bankAccountData.name = this.cardHolderName;
        }
      } else {
        console.log('NO Firebase Site ID detected ...');
      }
    } catch (error) {
      console.log('mounted has failed: ', error);
    }
  },
  methods: {
    addNewCard() {
      this.stripe.createToken(this.newCard.cardNumber).then(result => {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          this.loadingSpinner = true;
          // Send the token to your server.
          // console.log('Add card result:', result)
          let cardToAdd = {
            is_visible: 0,
            card: result.token.card,
            token: result.token.id,
            id: this.customerId,
            name_on_card: this.newCardData.cardHolderName
          };

          this.$store
            .dispatch('payment_methods/addPaymentMethod', { data: cardToAdd })
            .then(result => {
              if (result?.data?.Success) {
                console.log('[ RESULT ] -> ', result.data);
                this.$emit('newPaymentMethodAdded', 'New card added successfully!');
                this.$emit('closeNewPaymentMethodDialog');
              } else {
                this.message = 'Error while adding new card!';
              }
              return Promise.resolve();
            })
            .catch(error => {
              console.log('Error while adding new card, error:', error);
              this.message =
                error?.response?.data?.raw?.message && error?.response?.data?.raw?.code
                  ? `${error.response.data.raw.message} (Error Code: ${error?.response?.data?.raw?.code})`
                  : 'Something went wrong when adding new card!';
              return Promise.reject(error);
            })
            .finally(() => (this.loadingSpinner = false));
        }
      });
    },
    addBankAccount() {
      this.message = '';
      this.stripe
        .createToken('bank_account', {
          country: 'US',
          currency: 'usd',
          routing_number: this.bankAccountData.routingNumber,
          account_number: this.bankAccountData.accountNumber,
          account_holder_name: this.bankAccountData.name,
          account_holder_type: this.bankAccountData.accountType
        })
        .then(result => {
          if (result.error) {
            this.bankAccountError = result.error.message;
          } else {
            this.loadingSpinner = true;
            console.log('SOURCE: ', result);
            let bankAccountData = {
              account_holder_name: result.token.bank_account.account_holder_name,
              account_holder_type: result.token.bank_account.account_holder_type,
              bank_name: result.token.bank_account.bank_name,
              country: result.token.bank_account.country,
              currency: result.token.bank_account.currency,
              token: result.token.id,
              last4: result.token.bank_account.last4,
              routing_number: result.token.bank_account.routing_number,
              status: result.token.bank_account.status,
              customer_db_id: this.customerId
            };
            this.$store
              .dispatch('payment_methods/addBankAccount', bankAccountData)
              .then(result => {
                if (result.data && result.data.Success) {
                  this.$emit('newPaymentMethodAdded', 'New bank account added successfully!');
                  this.$emit('closeNewPaymentMethodDialog');
                } else {
                  this.message = 'Error while adding new bank account!';
                }
                return Promise.resolve();
              })
              .catch(error => {
                console.log('[ addBankAccount ] ERROR: ', error);
                if (error?.response?.data) {
                  this.message = error.response.data;
                }
                return Promise.reject(error);
              })
              .finally(() => (this.loadingSpinner = false));
          }
        });
    },
    createStripeCardElement() {
      let self = this;
      var elements = self.stripe.elements();
      var cardNumberElement = elements.create('cardNumber', {
        style: self.stripeElementsStyle
      });

      var cardExpiryElement = elements.create('cardExpiry', {
        style: self.stripeElementsStyle
      });
      var cardCvcElement = elements.create('cardCvc', {
        style: self.stripeElementsStyle
      });
      // var card = elements.create('card')
      // card.mount('#card-element')
      cardNumberElement.mount('#card-number');
      cardExpiryElement.mount('#card-expiry');
      cardCvcElement.mount('#card-cvc');

      cardNumberElement.addEventListener('change', event => {
        var displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
        if (event.brand) {
          self.newCardData.cardType = event.brand;
        }
      });
      cardExpiryElement.addEventListener('change', event => {
        var displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
      cardCvcElement.addEventListener('change', event => {
        var displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
      self.newCard.cardNumber = cardNumberElement;
      self.newCard.cardExpiry = cardExpiryElement;
      self.newCard.cardCvc = cardCvcElement;
      self.loadingSpinner = false;
    },
    closeNewPaymentMethodDialog() {
      this.$emit('closeNewPaymentMethodDialog');
    }
  }
};
</script>
<style scoped>
#card-number,
#card-expiry,
#card-cvc {
  border: 1px solid #ccc !important;
  padding: 0.3em 16px;
  border-radius: 4px;
}

.loadingInProgress {
  opacity: 0.2;
}
</style>
