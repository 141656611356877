<template>
  <div>
    <v-row class="mt-n4">
      <v-toolbar flat class="site-table--header site-table--lists">
        <v-col class="pl-0">
          <v-toolbar-title class="headline">
            <a class="black--text" @click="clearDate()">{{ title }}</a>
          </v-toolbar-title>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details @keydown="searchKeydown()"> </v-text-field>
          <p v-if="isMinLengthWarnShown" class="caption mt-2">Please enter min 3 characters to begin searching!</p>
        </v-col>
        <stamp-date-filters
          v-if="isDataLoadedFromUrl"
          :startDate="selectedStartDate"
          :endDate="selectedEndDate"
          :timeStampAttrName="timeStampAttrName"
          :isFiltersCleared="isFiltersCleared"
          @onRouteHandlerChanged="onRouteHandlerChanged"
          @onDateFilterChanged="onDateFilterChanged"
          @onDateSelectedStateChanged="onDateSelectedStateChanged"
          @onDateCleared="clearDate"
          @onWrongFilter="onWrongFilter"
        ></stamp-date-filters>
      </v-toolbar>
    </v-row>
    <v-row v-if="isOrderTypeFilterVisible" class="mt-n4 mb-1">
      <v-toolbar flat class="site-table--header mb-n2">
        <v-toolbar-title class="body-2 mr-2">Type</v-toolbar-title>
        <v-chip-group v-model="selectedOrderType" active-class="primary--text">
          <template v-for="(filterName, index) in quickFilterItems">
            <v-chip :key="index" filter outlined>
              <span v-if="filterName === 'eddm'">EDDM</span>
              <span v-else>{{ capitalize(filterName) }}</span>
            </v-chip>
          </template>
        </v-chip-group>
        <v-btn fab x-small icon outlined color="grey lighten-2" :disabled="selectedOrderType === ''" @click="clearSelectedType()">
          <v-icon color="grey">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-row>
    <v-row v-if="isOrderStatusFilterVisible" class="mt-n3 mb-1">
      <v-toolbar flat class="site-table--header mb-n2">
        <v-toolbar-title class="body-2 mr-2">Status</v-toolbar-title>
        <v-chip-group v-model="selectedStatus" active-class="primary--text">
          <template v-for="(statusName, index) in statusNames">
            <v-chip :key="index" filter outlined>
              <span>{{ capitalize(statusName) }}</span>
            </v-chip>
          </template>
        </v-chip-group>

        <v-btn fab x-small icon outlined color="grey lighten-2" :disabled="selectedStatus === ''" @click="clearSelectedStatus()">
          <v-icon color="grey">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-row>
    <stamp-environment-filters
      v-if="isDataLoadedFromUrl"
      :envFromQueryParam="envFromQueryParam"
      :testSitesList="testSitesList"
      :isFiltersCleared="isFiltersCleared"
      siteDbIdAttrName="site_db_id"
      originSiteDbIdAttrName="origin.site_db_id"
      @onEnvTypeChanged="onEnvTypeChanged"
    ></stamp-environment-filters>
  </div>
</template>

<script>
import EnvironmentFilters from '@/components/filters/EnvironmentFilters.vue';
import DateFilters from '@/components/filters/DateFilters.vue';

export default {
  components: {
    'stamp-date-filters': DateFilters,
    'stamp-environment-filters': EnvironmentFilters
  },
  props: ['title', 'isOrderTypeFilterVisible', 'isOrderStatusFilterVisible', 'selectedOrderStatus', 'testSitesList'],
  data() {
    return {
      timeStampAttrName: 'creation_date_timestamp',
      envFromQueryParam: '',
      selectedStartDate: '',
      selectedEndDate: '',
      search: '',
      searchTypeTimer: null,
      isMinLengthWarnShown: false,
      isDataLoadedFromUrl: false,
      dateFilter: '',
      selectedOrderType: '',
      quickFilterItems: ['availability', 'custom', 'eddm', 'inserts', 'newmovers', 'saturation', 'targeted', 'automated'],
      selectedStatus: '',
      statusNames: ['quote', 'incomplete', 'active', 'complete'],
      options: {},
      filter: [],
      routeQuery: null,
      isFiltersCleared: false
    };
  },
  computed: {
    commonFilter() {
      let common = `${this.dateFilter ? `${this.dateFilter}${this.filter.length ? ` AND ` : ``}` : ``}`;
      if (this.filter.length) {
        this.filter.forEach((value, index) => {
          common += Object.values(value).includes('automated')
            ? 'order_type:newmovers OR order_type:birthdays'
            : value.envType
            ? value.envType
            : Object.values(value)
            ? `${Object.keys(value)}:${Object.values(value)}`
            : '';
          if (index !== this.filter.length - 1) {
            common += ' AND ';
          }
        });
      }
      return common;
    },
    maxAvailableDate() {
      return this.$moment().format('YYYY-MM-DD');
    }
  },
  watch: {
    search(value) {
      if ((value.length === 0 && !this.dateSelected) || value.length > 2) {
        this.isMinLengthWarnShown = false;
        this.searchTypeTimer = setTimeout(() => {
          this.getDataFromAlgolia();
        }, 500);
      } else {
        this.isMinLengthWarnShown = true;
      }
    },
    selectedOrderType(value) {
      this.options.page = 1;
      const orderType = this.quickFilterItems[value] ? this.quickFilterItems[value] : '';

      this.routeHandler('orderType', orderType);
      this.setCommonFilter('order_type', orderType);

      this.getDataFromAlgolia();
    },
    selectedStatus(val) {
      this.options.page = 1;

      const orderStatus = isNaN(val) && this.statusNames.indexOf(val) > -1 ? val : this.statusNames[this.selectedStatus] !== undefined ? this.statusNames[this.selectedStatus] : '';

      this.routeHandler('orderStatus', orderStatus);
      this.setCommonFilter('order_status.status', orderStatus);

      this.getDataFromAlgolia();
    },
    testSitesList: function () {
      if (this.testSitesList?.length) {
        this.initOrderFilters();
      }
    }
  },
  async mounted() {
    this.initOrderFilters();
  },
  methods: {
    async initOrderFilters() {
      await this.setDataFromUrl();
      this.isDataLoadedFromUrl = true;
      this.onLoadingChanged(false);
    },
    onEnvTypeChanged(payload) {
      this.options.page = 1;
      this.routeHandler('envType', payload.selectedEnv);
      this.setCommonFilter('envType', payload.envFilters);

      this.getDataFromAlgolia();
    },
    onDateFilterChanged(formattedFilter) {
      this.dateFilter = formattedFilter;
      return this.getDataFromAlgolia();
    },
    setCommonFilter(key, value) {
      if (this.filter.length) {
        let isValueFound = false;
        this.filter.forEach((item, index) => {
          if (Object.keys(item).includes(key) && item[key] !== value) {
            isValueFound = true;
            if (value !== '') {
              this.filter[index][key] = value;
            } else {
              this.filter.splice(index, 1);
            }
          }
        });
        if (!isValueFound && value) {
          this.filter.push({ [key]: value });
        }
      } else if (value) {
        this.filter.push({ [key]: value });
      }
    },
    setDataFromUrl() {
      this.onLoadingChanged(true);
      return new Promise(resolve => {
        this.routeQuery = this.$route.query;
        console.log('data url: ', this.routeQuery);
        if (this.routeQuery) {
          this.selectedStartDate = this.routeQuery.startDate ?? '';
          this.routeHandler('startDate', this.selectedStartDate);

          this.selectedEndDate = this.routeQuery.endDate ?? '';
          this.routeHandler('endDate', this.selectedEndDate);

          this.isDateSelected = this.selectedStartDate !== '' && this.selectedEndDate !== '';
          this.envFromQueryParam = this.routeQuery?.envType ?? '';

          this.selectedOrderType = this.routeQuery.orderType ? this.quickFilterItems.findIndex(i => i === this.routeQuery.orderType) : '';

          this.selectedStatus = this.routeQuery.orderStatus ? this.statusNames.findIndex(i => i === this.routeQuery.orderStatus) : this.selectedOrderStatus ?? '';

          this.search = this.routeQuery.search ?? '';
          this.routeHandler('search', this.search);
        }
        resolve('done');
      });
    },
    clearDate() {
      this.isFiltersCleared = true;
      this.envFromQueryParam = '';
      this.routeHandler('envType', this.envFromQueryParam);
      this.selectedStartDate = '';
      this.selectedEndDate = '';
      this.dateFilter = '';
      this.isDateSelected = false;
      this.search = '';
      this.routeHandler('search', '');
      this.selectedOrderType = '';
      this.selectedStatus = this.selectedOrderStatus || '';
      this.options.page = 1;

      return this.getDataFromAlgolia();
    },
    clearSelectedType() {
      this.selectedOrderType = '';
      this.options.page = 1;
    },
    clearSelectedStatus() {
      this.selectedStatus = '';
      this.options.page = 1;
    },
    routeHandler(paramName, paramValue) {
      let query = { ...this.$route.query };

      if (!paramValue) {
        if (Object.keys(query).includes(paramName)) {
          delete query[paramName];
          this.$router.replace({ query });
        }
      } else if (JSON.stringify({ [paramName]: query[paramName] }) !== JSON.stringify({ [paramName]: paramValue })) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            [paramName]: paramValue
          }
        });
      }
    },
    searchKeydown() {
      clearTimeout(this.searchTypeTimer);
      this.searchTypeTimer = setTimeout(() => {
        if (this.search === '' || (this.search && this.search.length > 2)) {
          this.routeHandler('search', this.search);
        }
      }, 1000);
    },
    getDataFromAlgolia() {
      this.onLoadingChanged(true);
      return this.$store
        .dispatch('algolia/getDataFromAlgolia', {
          indexName: `orders${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`,
          filter: this.commonFilter,
          searchValue: this.search ?? ''
        })
        .then(content => {
          if (content) {
            this.totalOrders = content.nbHits;
            this.orders = content.hits;
            this.isFiltersCleared = false;
            this.updateOrderList(content);
          }

          return Promise.resolve(content);
        })
        .catch(err => {
          console.log(`getDataFromAlgolia: `, err);
          console.log(`getDataFromAlgolia DebugData: `, err.debugData);
          return Promise.reject(err);
        })
        .finally(() => this.onLoadingChanged(false));
    },
    updateOrderList(content) {
      this.$emit('updateOrderList', {
        totalOrders: content.nbHits,
        orders: content.hits,
        commonFilter: this.commonFilter,
        search: this.search,
        options: this.options
      });
    },
    onWrongFilter(isEndDateSmaller) {
      this.$emit('onWrongFilter', isEndDateSmaller);
    },
    onLoadingChanged(isVisible) {
      this.$emit('onLoadingChanged', isVisible);
    },
    onRouteHandlerChanged(payload) {
      this.routeHandler(payload.key, payload.value);
    },
    onDateSelectedStateChanged(isDateSelected) {
      this.isDateSelected = isDateSelected;
    },
    capitalize(text) {
      if (!text || text.length <= 2) {
        return '';
      } else if (text.length >= 2) {
        return text.charAt(0).toUpperCase() + text.slice(1);
      }
    }
  }
};
</script>
