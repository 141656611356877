import { getCurrentUserToken } from '../services/firebaseInit.js';
import axios, { AxiosRequestConfig } from 'axios';
const BASE_URL = process.env.VUE_APP_API_URL;

async function getAuthorizationHeader() {
  const token = await getCurrentUserToken();
  return `Bearer ${token}`;
}

async function getFunctionConfig(httpConfig = null, functionPath) {
  if (!functionPath) {
    throw new Error('functionPath is required');
  }
  let config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  if (httpConfig) {
    config = Object.assign({}, config, httpConfig);
  }
  config.headers.Authorization = await getAuthorizationHeader();

  return config;
}

/**
 *
 * @param {string} functionPath the function path, ex. 'saveOrderToCrm'
 * @param {any} data the data to be sent to the function
 * @param {AxiosRequestConfig} httpConfig - optional, if not provided, the function will use the default config
 *
 * @returns {AxiosPromise<any>} an axios promise
 */
export async function cloudFunctionsPost(functionPath, data = null, httpConfig) {
  const config = await getFunctionConfig(httpConfig, functionPath);
  return axios.post(`${BASE_URL}${functionPath}`, data, config);
}
/**
 *
 * @param {string} functionPath the function path, ex. 'saveOrderToCrm'
 * @param {any} data the data to be sent to the function
 * @param {AxiosRequestConfig} httpConfig - optional, if not provided, the function will use the default config
 *
 * @returns {AxiosPromise<any>} an axios promise
 */
export async function cloudFunctionsPut(functionPath, data = null, httpConfig) {
  const config = await getFunctionConfig(httpConfig, functionPath);
  return axios.put(`${BASE_URL}${functionPath}`, data, config);
}

/**
 *
 * @param {string} functionPath the function path, ex. 'saveOrderToCrm'
 * @param {any} data the data to be sent to the function
 * @param {AxiosRequestConfig} httpConfig - optional, if not provided, the function will use the default config
 *
 * @returns {AxiosPromise<any>} an axios promise
 */
export async function cloudFunctionsPatch(functionPath, data = null, httpConfig) {
  const config = await getFunctionConfig(httpConfig, functionPath);
  return axios.patch(`${BASE_URL}${functionPath}`, data, config);
}

/**
 *
 * @param {string} functionPath the function path, ex. 'saveOrderToCrm'
 * @param {AxiosRequestConfig} httpConfig - optional, if not provided, the function will use the default config, ex. { params: { order-id: 'string' } }
 *
 * @returns {AxiosPromise<any>} an axios promise
 */
export async function cloudFunctionsGet(functionPath, httpConfig) {
  const config = await getFunctionConfig(httpConfig, functionPath);
  return axios.get(`${BASE_URL}${functionPath}`, config);
}

/**
 *
 * @param {string} functionPath the function path, ex. 'saveOrderToCrm'
 * @param {AxiosRequestConfig} httpConfig - optional, if not provided, the function will use the default config, ex. { params: { order-id: 'string' } }
 *
 * @returns {AxiosPromise<any>} an axios promise
 */
export async function cloudFunctionsDelete(functionPath, httpConfig) {
  const config = await getFunctionConfig(httpConfig, functionPath);
  return axios.delete(`${BASE_URL}${functionPath}`, config);
}

export const cloudFunctions = {
  get: cloudFunctionsGet,
  post: cloudFunctionsPost,
  put: cloudFunctionsPut,
  patch: cloudFunctionsPatch,
  delete: cloudFunctionsDelete,
  getAuthorizationHeader
};
