<template>
  <v-app :style="mainBackgroundColor">
    <v-system-bar v-if="isEnvTypeBarVisible" app window fixed>
      <v-row class="justify-center" no-gutters>
        <v-spacer />
        <v-icon color="warning">mdi-alert-octagon-outline</v-icon>
        <span
          >You are using a <b>{{ envTypeSystemBarLabel }}</b> environment</span
        >
        <v-spacer />
        <v-btn icon x-small class="mr-2" @click="hideEnvTypeBar"><v-icon class="pl-2">mdi-close</v-icon></v-btn>
      </v-row>
    </v-system-bar>

    <v-app-bar v-if="isUserAuthenticated" color="primary" dense app clipped-left height="60">
      <v-app-bar-nav-icon color="white" @click="openMainSidebar()"></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text">
        <v-row class="justify-start align-center d-none d-sm-flex">
          <v-col cols="12" :sm="logoColWidth">
            <img class="custom-mouse-hover d-flex align-center justify-center" src="@/assets/OneBrandMT_pipe.png" height="55" @click="goToHome" />
          </v-col>
          <v-col cols="12" sm="6">
            <span class="font-weight-normal">STAMP</span>
            <span class="body-2 pl-1">{{ envTypeNavbarLabel }}</span>
          </v-col>
        </v-row>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <template v-if="isUserAccessAllowed">
        <v-progress-circular v-if="isNotificationsLoading" :size="25" color="white" indeterminate></v-progress-circular>
        <v-btn v-else icon @click="openNotificationSidePanel()">
          <v-badge color="red" overlap :content="notificationCounter" offset-x="12" offset-y="10">
            <v-icon color="white">mdi-bell</v-icon>
          </v-badge>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon color="indigo" @click="settings()">
              <v-icon medium color="white" v-on="on">mdi-cog</v-icon>
            </v-btn>
          </template>
          <span>Settings</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-if="isUserSuperAdmin" icon color="indigo" @click="testPage()">
              <v-icon medium color="white" v-on="on">mdi-anvil</v-icon>
            </v-btn>
          </template>
          <span>Testing page</span>
        </v-tooltip>
      </template>

      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-avatar size="36px">
              <img v-if="photoUrl" :src="photoUrl" alt="Avatar image" />
              <v-icon v-else size="30" color="white">mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-list subheader class="custom-menu">
          <v-list-item-group color="primary">
            <v-list-item to="/user-profile">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onLogout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-if="isUserAccessAllowed"
      v-model="notificationDrawer"
      app
      clipped
      width="420px"
      right
      temporary
      floating
      :hide-overlay="false"
      elevation="1"
      color="#FAFAFA"
      class="sidebar-nav-notifications"
      transition="slide-x-reverse-transition"
    >
      <v-list color="#FAFAFA" class="mt-n1 pa-3" transition="slide-x-reverse-transition">
        <notification-card
          v-for="(notification, index) in notificationList"
          :key="index"
          :notification="notification"
          :type="notification.type"
          class="mb-2"
          @hideNotification="clearNotifications(notification)"
        ></notification-card>
      </v-list>
      <v-list v-if="notificationList.length > 1">
        <v-row class="justify-end">
          <v-btn text x-small color="blue-grey darken-1" class="mr-4 subtitle-2" @click="clearNotifications()">Mark all as read</v-btn>
        </v-row>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer v-if="isUserAccessAllowed" v-model="drawer" app clipped width="285px" :class="mainNavbarClass">
      <v-list dense>
        <v-list-item v-for="mainItem in mainMenuItems" :key="mainItem.text" :to="mainItem.link">
          <v-list-item-icon>
            <v-icon>{{ mainItem.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ mainItem.text }}</v-list-item-title>
        </v-list-item>

        <v-list-group v-for="item in menuItemsWithChildren" :key="item.text" v-model="item.model" value="true" class="v-listgroup--dropdown">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="subItem in item.children" v-bind:key="subItem.text" :to="subItem.link">
            <v-list-item-icon>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ subItem.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main :class="mainContentClass">
      <snackbarGlobal></snackbarGlobal>
      <alert-global></alert-global>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <loaderGlobal></loaderGlobal>
  </v-app>
</template>

<script>
import LoaderGlobal from '@/components/ui/LoaderGlobal';
import SnackbarGlobal from '@/components/ui/SnackbarGlobal';
import AlertGlobal from '@/components/alerts/AlertGlobal';
import { auth } from './services/firebaseInit.js';
import { mapGetters } from 'vuex';
import Utils from './modules/utils.js';
import NotificationCard from '@/components/alerts/SidebarNotification';

export default {
  name: 'App',
  components: {
    loaderGlobal: LoaderGlobal,
    snackbarGlobal: SnackbarGlobal,
    'notification-card': NotificationCard,
    'alert-global': AlertGlobal
  },
  data: () => ({
    drawer: true,
    countLog: 0,
    notificationDrawer: null,
    defaultItems: [
      { icon: 'mdi-home', text: 'Home', link: '/' },
      { icon: 'mdi-view-dashboard', text: 'Manage Sites', link: '/sites' },
      {
        icon: 'keyboard_arrow_up',
        iconAlt: 'keyboard_arrow_down',
        text: 'Order Management',
        model: true,
        children: [
          { icon: 'mdi-clipboard-text', text: 'Orders', link: '/orders' },
          { icon: 'mdi-account-multiple', text: 'Customers', link: '/customers' },
          { icon: 'mdi-map', text: 'Maps', link: '/maps' },
          { icon: 'mdi-reorder-horizontal', text: 'Lists', link: '/list' },
          { icon: 'mdi-wallpaper', text: 'Designs', link: '/designs' },
          { icon: 'mdi-send', text: 'DMM Postcards Tool', link: '/dmm-tool' },
          { icon: 'mdi-email-newsletter', text: 'DMM Letter Tool', link: '/letter-tool' },
          { icon: 'mdi-numeric-4-box', text: '4over Tool', link: '/4-over-tool' },
          { icon: 'mdi-fast-forward', text: 'Eddm JSON Process', link: '/eddm-process' },
          { icon: 'mdi-filter-variant', text: 'NewMovers JSON Process', link: '/nm-process' },
          { icon: 'mdi-plus-box-multiple', text: 'Quote Tool', link: '/quote-tool' },
          { icon: 'mdi-radar', text: 'Status Tracker', link: '/status-tracker' }
        ]
      },
      { icon: 'mdi-home-edit-outline', text: 'Config  Menu(β)', link: '/menuconfig' },
      {
        icon: 'keyboard_arrow_up',
        iconAlt: 'keyboard_arrow_down',
        text: 'DesignLive templates',
        model: true,
        children: [
          { icon: 'mdi-pan-horizontal', text: 'Template Wizard', link: '/template-wizard' },
          { icon: 'mdi-table', text: 'Templates', link: '/dl-templates' },
          { icon: 'mdi-view-grid-outline', text: 'Template Categories', link: '/template-categories' },
          { icon: 'mdi-alpha-t-circle', text: 'Template Types', link: '/template-types' },
          { icon: 'mdi-domain', text: 'Organizations', link: '/dl-organizations' },
          { icon: 'mdi-shape', text: 'Categories', link: '/dl-categories' },
          { icon: 'mdi-file-tree', text: 'File Explorer', link: '/file-explorer' }
        ]
      },
      {
        icon: 'keyboard_arrow_up',
        iconAlt: 'keyboard_arrow_down',
        text: 'Stripe',
        model: true,
        children: [
          { icon: 'mdi-currency-usd', text: 'Charge legacy order', link: '/charge-legacy-order' },
          { icon: 'mdi-currency-usd', text: 'Stripe Test', link: '/stripe-test' }
        ]
      },
      {
        icon: 'keyboard_arrow_up',
        iconAlt: 'keyboard_arrow_down',
        text: 'Permissions',
        model: true,
        children: [
          { icon: 'mdi-account-group', text: 'Groups', link: '/groups' },
          { icon: 'mdi-account', text: 'Users', link: '/users' },
          { icon: 'mdi-routes', text: 'Routes', link: '/routes' },
          { icon: 'mdi-signal-cellular-outline', text: 'Levels', link: '/levels' }
        ]
      },
      {
        icon: 'keyboard_arrow_up',
        iconAlt: 'keyboard_arrow_down',
        text: 'Logs',
        model: true,
        children: [
          { icon: 'mdi-format-list-bulleted', text: 'User Logs', link: '/logs' },
          { icon: 'mdi-view-list', text: 'All Logs', link: '/alllogs' },
          { icon: 'mdi-bell', text: 'Notifications', link: '/notifications' },
          { icon: 'mdi-database-export', text: 'Backup Firestore', link: '/backup-firestore' }
        ]
      },
      {
        icon: 'keyboard_arrow_up',
        iconAlt: 'keyboard_arrow_down',
        text: 'Product Management',
        model: true,
        children: [
          { icon: 'mdi-cart-outline', text: 'Products', link: '/products/sites' },
          { icon: 'mdi-view-grid-outline', text: 'Product Categories', link: '/product-categories' },
          { icon: 'mdi-alpha-t-circle', text: 'Product Types', link: '/product-types' },
          { icon: 'mdi-cart-plus', text: 'Product Services', link: '/product-services' },
          { icon: 'mdi-tag-multiple-outline', text: 'Product Pricing', link: '/product-pricing' }
        ]
      },
      {
        icon: 'keyboard_arrow_up',
        iconAlt: 'keyboard_arrow_down',
        text: 'Reports',
        model: true,
        children: [
          { icon: 'mdi-playlist-edit', text: 'Mailing List Reports', link: '/mailing-list-reports' },
          { icon: 'mdi-playlist-edit', text: 'Order Reports', link: '/order-reports' },
          { icon: 'mdi-playlist-edit', text: 'Incomplete Order Reports', link: '/incomplete-order-reports' }
        ]
      },
      {
        icon: 'keyboard_arrow_up',
        iconAlt: 'keyboard_arrow_down',
        text: 'Customer Messaging',
        model: true,
        children: [
          { icon: 'mdi-email-send', text: 'Send email', link: '/sendemail' },
          { icon: 'mdi-mail', text: 'Email Templates', link: '/emailtemplates' },
          { icon: 'mdi-message-arrow-right-outline', text: 'Send SMS', link: '/sendsms' },
          { icon: 'mdi-message-text-outline', text: 'SMS Templates', link: '/smstemplates' },
          { icon: 'http', text: 'Test Http', link: '/httpexample' }
        ]
      }
    ],
    isNotificationsLoading: false,
    isEnvTypeBarVisible: false,
    productionColor: '#C90000',
    stagingColor: '#2892A0',
    developColor: '#5A8547'
  }),
  computed: {
    items() {
      const items = this.defaultItems;
      const routesStore = this.routesStorePermissions.length === 0 ? this.routesStoreUser : this.routesStorePermissions;
      const itemToShow = [];
      let childrenArray = [];
      let finalMenu = [];
      if (this.userAcessLevelArray.length < 1) {
        console.log('user access level could not be found');
      } else {
        this.userAcessLevelArray.forEach((userAccessLvl, accessIndex) => {
          items.forEach(itemValue => {
            if (itemValue.children) {
              childrenArray = [];
              itemValue.children.forEach(childValue => {
                const link = childValue.link;
                routesStore.forEach(value => {
                  const path = value.path;
                  if (path === link) {
                    const routeAccessLvlArray = value.access_levels;
                    routeAccessLvlArray?.forEach(routeValue => {
                      const routeAccessLvl = routeValue.value;
                      if (Number(userAccessLvl) === Number(routeAccessLvl)) {
                        childrenArray.push(childValue);
                      }
                    });
                  }
                });
              });
              if (childrenArray.length !== 0) {
                itemToShow.push({
                  icon: itemValue.icon,
                  iconAlt: itemValue?.iconAlt,
                  text: itemValue.text,
                  model: true,
                  children: childrenArray
                });
              }
            } else {
              const link = itemValue.link;
              routesStore.forEach((value, index) => {
                const path = value.path;
                if (path === link) {
                  const routeAccessLvlArray = value.access_levels;
                  routeAccessLvlArray.forEach((routeValue, indexR) => {
                    const routeAccessLvl = routeValue.value;
                    if (userAccessLvl === routeAccessLvl) {
                      itemToShow.push(itemValue);
                    }
                  });
                }
              });
            }
          });
        });
        if (this.userAcessLevelArray.length > 1 && itemToShow.length) {
          const frequency = itemToShow
            .map(({ text }) => text)
            .reduce((menu, item) => {
              const count = menu[item] || 0;
              menu[item] = count + 1;
              return menu;
            }, {});
          itemToShow.forEach(item => {
            if (frequency[item.text] === 1) {
              if (item.text !== 'Home' && !item.children) {
                finalMenu.splice(1, 0, item);
              } else {
                finalMenu.push(item);
              }
            } else {
              if (item.children) {
                let index = itemToShow.map(el => el.text === item.text).lastIndexOf(true);
                let duplicatedMenuItem = itemToShow[index];
                finalMenu.push({
                  icon: item.icon,
                  iconAlt: item.iconAlt,
                  text: item.text,
                  model: true,
                  children:
                    JSON.stringify(item.children) === JSON.stringify(duplicatedMenuItem.children) ? item.children : [...new Set([...item.children, ...duplicatedMenuItem.children])]
                });
                delete itemToShow[index];
              } else {
                if (!finalMenu.includes(item)) {
                  finalMenu.push(item);
                }
              }
            }
          });
        }
      }
      return finalMenu.length ? finalMenu : itemToShow;
    },
    mainMenuItems() {
      return this.items.filter(item => !item.children);
    },
    menuItemsWithChildren() {
      return this.items.filter(item => item.children);
    },
    menuItems() {
      return !this.isUserAuthenticated ? { icon: 'lock_open', title: 'Login', link: '/login' } : [];
    },
    isUserAuthenticated() {
      return this.userStore !== null && this.userStore;
    },
    isUserAccessAllowed() {
      return this.isUserAuthenticated && this.userAccessLevel;
    },
    userName() {
      return this.isUserAuthenticated ? this.userStore.name : null;
    },
    photoUrl() {
      return this.isUserAuthenticated ? this.userPhotoUrl : '';
    },
    mainBackgroundColor() {
      return { background: this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'].background };
    },
    notificationCounter() {
      return this.notificationsCount || '0';
    },
    isUserSuperAdmin() {
      return this.userAcessLevelArray.filter(accessLevel => [100, 89].includes(Number(accessLevel))).length;
    },
    ...mapGetters('users', {
      userStore: 'user',
      routesStoreUser: 'routes',
      userAccessLevel: 'userAccessLevel',
      userAcessLevelArray: 'userAcessLevelArray',
      userPhotoUrl: 'photoUrl',
      savedThemeType: 'getThemeType'
    }),
    ...mapGetters('permissions', {
      routesStorePermissions: 'routes'
    }),
    ...mapGetters('notifications', {
      notificationsCount: 'getNotificationsCount',
      notificationList: 'getNotifications'
    }),
    isDevelopmentEnv() {
      return !this.$isProductionEnv;
    },
    envTypeNavbarLabel() {
      return this.isDevelopmentEnv ? (this.$isLocalEnv ? 'LOCAL' : process.env.VUE_APP_NODE_ENV.toUpperCase()) : '';
    },
    envTypeSystemBarLabel() {
      return this.isDevelopmentEnv ? (this.$isDevEnv ? 'DEVELOPMENT' : process.env.VUE_APP_NODE_ENV.toUpperCase()) : '';
    },
    logoColWidth() {
      return this.isDevelopmentEnv ? 4 : 6;
    },
    testEnvSuffix() {
      return this.isEnvTypeBarVisible ? '-testing' : '';
    },
    mainContentClass() {
      return `main--content${this.testEnvSuffix}`;
    },
    mainNavbarClass() {
      return `main--navbar${this.testEnvSuffix}`;
    }
  },
  watch: {
    userStore(value) {
      if (value !== null && value !== undefined) {
        if (value.logout === true) {
          this.$router.go('/login');
        }
      }
    }
  },
  created() {
    this.$vuetify.theme.themes.light.primary = this.$isLocalEnv ? '#7B6B51' : this[`${process.env.VUE_APP_NODE_ENV}Color`];
    const user = auth.currentUser;

    if (user) {
      const expiration = parseInt(localStorage.getItem('tokenExpiration'));
      this.$store.dispatch('users/extendTokenExpiry', { user, now: expiration ? Date.now() : null });
      this.$store.dispatch('notifications/triggerNotificationChanges');
    }

    this.$store.dispatch('users/fetchUserInApp').then(() => {
      this.$vuetify.theme.dark = this.savedThemeType;
    });

    this.$store.dispatch('users/fetchPhoto');

    this.$store.dispatch('permissions/fetchRoutes');

    this.$store.dispatch('setUtilsInstance', new Utils(this));

    this.isEnvTypeBarVisible = this.isDevelopmentEnv;
  },
  methods: {
    openMainSidebar() {
      this.drawer = !this.drawer;
    },
    openNotificationSidePanel() {
      this.notificationDrawer = !this.notificationDrawer;
    },
    onLogout() {
      this.$store.dispatch('users/logout');
    },
    goToHome() {
      this.$router.push('/', () => {});
    },
    settings() {
      this.$router.push({ path: `/settings` });
    },
    testPage() {
      this.$router.push({ path: `/test-page` });
    },
    clearNotifications(item) {
      this.isNotificationsLoading = true;
      this.$store
        .dispatch('notifications/markAsRead', { firestoreID: item ? item.firestoreID : '' })
        .catch(error => console.log('Error in removing notification from list:', error))
        .finally(() => {
          this.isNotificationsLoading = false;
          if (!item || this.notificationList.length < 1) this.notificationDrawer = false;
        });
    },
    hideEnvTypeBar() {
      this.isEnvTypeBarVisible = false;
    }
  }
};
</script>

<style>
.v-main__wrap {
  padding-left: 20px;
  padding-right: 20px;
}

.contentWidth {
  margin-left: 5px !important;
}

@media (min-width: 1260px) {
  .contentWidth {
    margin-left: 205px !important;
  }
}

.site-table .v-data-footer {
  justify-content: flex-end;
}

#app .custom-color {
  background: #c90000;
}

.custom-mouse-hover {
  cursor: pointer;
}

.menuable__content__active {
  margin-left: -70px;
}

.custom-snackbar123 {
  padding: 10px 10px;
}

.menuable__content__active {
  margin-left: 10px !important;
}

.notification-custom-card {
  border-radius: 3%;
  margin: 8px 12px;
  padding-right: 8px;
  padding-left: 8px;
}

.topright {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 18px;
}

.custom-navigation-list {
  background-color: #e0e0e0 !important;
  padding-top: 16px !important;
}

.custom-progress {
  position: absolute;
  top: 240px;
  left: 180px;
}

iframe {
  width: 100%;
  max-width: 650px;
}

.flip-ro-flag {
  transform: rotateY(180deg);
}

.v-listgroup--dropdown {
  width: 100% !important;
}

nav .main--navbar {
  padding-top: 5px;
  top: 60px !important;
}

nav .main--navbar-testing {
  padding-top: 5px;
  top: 92px !important;
}

.v-listgroup--dropdown.v-list-group--active {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.main--navbar .v-list {
  padding-top: 3px;
}

.main--navbar-testing .v-list {
  padding-top: 3px;
}

.flexing-to-end {
  display: flex;
  justify-content: flex-end;
}

.site-table {
  width: 100%;
  z-index: 1;
  padding-top: 15px;
}

body .site-table .site-table--header {
  background: transparent;
  margin-bottom: 10px;
}

.site-table--lists .v-input {
  max-height: 33px;
  opacity: 0.5;
}

.table-header--actions {
  display: flex;
  justify-content: flex-end;
}

.main--content {
  padding-top: 60px !important;
}

.main--content-testing {
  padding-top: 92px !important;
}

@media (min-width: 1264px) {
  .container {
    max-width: 100%;
  }
}

.sidebar-nav-notifications {
  top: 60.5px !important;
}

.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close),
.v-navigation-drawer--temporary:not(.v-navigation-drawer--close) {
  box-shadow: none !important;
}

/* table header transparent background, nullify the grey background */
body .v-application--wrap main.v-main.main--content .site-table--header,
body .v-application--wrap main.v-main.main--content-testing .site-table--header {
  background: transparent;
}
</style>
