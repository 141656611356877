<template>
	<v-container fluid>
		<v-row class="justify-center mt-n5 ml-n5">
			<v-col cols="12">
				<v-card class="elevation-0" outlined>
					<v-row class="justify-center align-center">
						<v-col cols="12" sm="4" class="mb-n4">
							<v-menu v-model="isFirstDateMenuOpened" :close-on-content-click="false" max-width="290">
								<template v-slot:activator="{ on }">
									<v-text-field :value="computedStart" label="Start Date" readonly v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="firstDate" @change="isFirstDateMenuOpened = false"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="12" sm="4" class="mb-n4">
							<v-menu v-model="isEndDateMenuOpened" :close-on-content-click="false" max-width="290">
								<template v-slot:activator="{ on }">
									<v-text-field :value="computedEnd" label="End Date" readonly v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="endDate" @change="isEndDateMenuOpened = false"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="12" sm="3" class="text-end">
							<v-btn color="blue-grey white--text mt-1" :loading="isCountsLoading" @click="getCounts()">Get Counts</v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		<v-row v-if="addresses > 0" class="justify-center ml-n4">
			<v-col cols="12">
				<v-alert color="#DBE3D3" class="text-center" tile><b class="mr-1">{{ addresses }}</b> Addresses Available</v-alert>
			</v-col>
		</v-row>
		<v-row class="justify-center mt-1">
			<v-btn :loading="isCsvAddressesLoading" text color="blue-grey darken-2" @click="getAddressesCsv()"><v-icon class="mr-2">mdi-download</v-icon>Addresses CSV</v-btn>
			<v-btn :loading="isDmmCsvLoading" text color="blue-grey darken-2" @click="generateCsvDMM()"><v-icon class="mr-2">mdi-download</v-icon>DMM CSV</v-btn>
		</v-row>
	</v-container>
</template>

<script>
export default {
	props: ['link', 'customerId'],
	data () {
		return {
			csvLink: this.link,
			addresses: 0,
			isFirstDateMenuOpened: false,
			isEndDateMenuOpened: false,
			firstDate: this.$moment().subtract(30, 'days').format('YYYY-MM-DD'),
			endDate: this.$moment().format('YYYY-MM-DD'),
			genTkn: process.env.VUE_APP_GENERAL_TKN,
			isCountsLoading: false,
			isCsvAddressesLoading: false,
			isDmmCsvLoading: false,
			customerID: this.customerId
		}
	},
	computed: {
		computedStart() {
			return this.firstDate ? this.$moment(this.firstDate).format('YYYY-MM-DD') : ''
		},
		computedEnd() {
			return this.endDate ? this.$moment(this.endDate).format('YYYY-MM-DD') : ''
		},
	},
	watch: {
		link (val) { this.csvLink = val	},
		customerId (val) { this.customerID = val }
	},
	methods: {
		getCounts () {
			this.isCountsLoading = true
			const csvLink = `${this.csvLink}&startDate=${this.computedStart}&endDate=${this.computedEnd}`
			this.$store.dispatch('tools/getCsvCounts', { csvLink, genTkn: this.genTkn, returnCsvFile: false }).then(response => {
				this.addresses = response.data.rowCount ? response.data.rowCount : 0
			}).catch(error => {
				console.log('Error in getting counts:', error)
			}).finally(() => this.isCountsLoading = false)
		},
		getAddressesCsv() {
			this.isCsvAddressesLoading = true
			const csvLink = `${this.csvLink}&startDate=${this.computedStart}&endDate=${this.computedEnd}`
			this.$store.dispatch('tools/getCsvCounts', { csvLink, genTkn: this.genTkn, returnCsvFile: true }).then(response => {
				let blob = new Blob([response.data], { type: 'text/csv' })
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = 'new_movers_addresses.csv'
				link.click()
			}).catch(error => {
				console.log('Error in getting addresses:', error)
			}).finally (() => this.isCsvAddressesLoading = false)
		},
		generateCsvDMM() {
			this.isDmmCsvLoading = true
			const csvLink = `${this.csvLink}&startDate=${this.computedStart}&endDate=${this.computedEnd}`
			this.$store.dispatch('tools/getPreparedCsvForDmm', { csvLink, genTkn: this.genTkn, returnCsvFile: true, customerId: this.customerID }).then(() => {
				this.isDmmCsvLoading = false
			})
		}
	}
}
</script>