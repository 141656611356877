<template>
  <v-container>
    <saturation-csv-modal
      v-if="isSaturationDialogVisible"
      :modal="isSaturationDialogVisible"
      :mapData="mapItem"
      :mapId="mapID"
      @closeSaturationModal="isSaturationDialogVisible = false"
    >
    </saturation-csv-modal>

    <manage-newmover-campaign
      v-if="isNewMovers"
      :is-dialog-opened="isNewMoverCampaignDialogVisible"
      :csv-link="csvLink"
      :map-id="mapID"
      @close="closeNewMoverCampaignDialog"
    ></manage-newmover-campaign>

    <v-dialog v-model="isViewMapImageVisibile" max-width="800">
      <v-card>
        <v-card-title class="headline">Map Image</v-card-title>
        <v-card-text>
          <div v-if="imgPath">
            <v-text-field id="imgPath" v-model="imgPath" label="Map Image Path" hint="Map Image Path" type="text"></v-text-field>
            <img class="map-image-custom-height" :src="imgPath" alt="Avatar image" />
          </div>
          <div v-else>
            <p class="subtitle-1 mt-3 ml-n1"><v-icon color="warning" class="mt-n1">mdi-exclamation-thick</v-icon>There is no image found for this map!</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="isViewMapImageVisibile = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isDeleteDialogVisible" max-width="320">
      <v-card>
        <v-card-title class="headline red">
          <div class="text-xs-center">
            <span class="white--text text-md-center">Warning!</span>
          </div>
        </v-card-title>
        <v-card-text> Are you sure that you want to clear storage fields and delete CSV file from Storage? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="isDeleteDialogVisible = false">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="clearStorageFields()">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isCustomiseCsvVisible" scrollable persistent max-width="800">
      <v-card>
        <v-card-title class="title grey--text text--darken-2 ml-1 font-weight-regular">Download CSV by Period</v-card-title>
        <v-card-text>
          <csv-counts :link="csvLink" :customerId="customer.crmCustomerId"></csv-counts>
          <v-row class="justify-end mr-0">
            <v-btn color="grey darken-3" text @click="isCustomiseCsvVisible = false">Close</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isZipListDialogVisible" scrollable persistent max-width="800">
      <v-card>
        <v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab :disabled="!carrierRouteZips.length"> crRoutes </v-tab>
          <v-tab :disabled="!atzZips.length"> atzRoutes </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="crRoutes">
            <v-card flat>
              <v-card-text>
                <span class="title mr-2">Zip List from:</span>
                <span class="title font-weight-regular">{{ zipListLabel }}</span>
                <div v-if="carrierRouteZips">
                  <v-text-field v-model="carrierRouteZipStringList" label="Zip String List" hint="Zip String List" type="text"></v-text-field>
                  <p class="title">Zip Table</p>
                  <v-row class="align-center justify-center">
                    <v-col cols="12" sm="7">
                      <v-card>
                        <v-card-title class="justify-center">
                          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dense></v-text-field>
                        </v-card-title>
                        <stamp-data-table
                          :headers="[{ text: 'Zip', value: 'zip', align: 'center' }]"
                          :data="carrierRouteZips"
                          :search="search"
                          :is-items-per-page-disabled="true"
                          item-key="zip"
                        />
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="atzRoutes">
            <v-card flat>
              <v-card-text>
                <span class="title mr-2">Zip List from:</span>
                <span class="title font-weight-regular">{{ zipListLabel }}</span>
                <v-text-field v-model="atzStringList" label="Zip String List" hint="Zip String List" type="text"></v-text-field>
                <p class="title">Zip Table</p>
                <v-row class="align-center justify-center">
                  <v-col cols="12" sm="7">
                    <v-card>
                      <v-card-title class="justify-center">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dense></v-text-field>
                      </v-card-title>
                      <stamp-data-table
                        :headers="[{ text: 'Zip', value: 'zip', align: 'center' }]"
                        :data="atzZips"
                        :search="search"
                        :is-items-per-page-disabled="true"
                        item-key="zip"
                      />
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions class="stamp-map-details-custom-action">
          <v-btn color="grey darken-1" text class="my-2 mx-2" @click="isZipListDialogVisible = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isSaturationCountsDialogVisible" max-width="400">
      <v-card class="pt-3">
        <stamp-data-table
          :headers="[
            { text: 'AddressType', value: 'AddressType', align: 'center', sortable: false, class: 'subtitle-2' },
            { text: 'Records', value: 'Records', align: 'center', sortable: false, class: 'subtitle-2' }
          ]"
          :data="saturationCountsData"
          :is-footer-hidden="true"
          item-key="AddressType"
        />
        <v-card-actions class="justify-end">
          <v-btn text small @click="closeSaturationCsvModal()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" sm="10">
        <v-btn icon class="px-3 pb-2 pt-1" @click.native="back()">
          <v-icon color="red">mdi-arrow-left</v-icon>
        </v-btn>
        <span class="headline pt-1">Map</span>
        <span class="headline pt-1 pl-4"><b class="mr-2">ID: </b> {{ mapID }}</span>
        <span class="headline ml-4 pt-1"><b>type:</b> {{ type }} </span>
      </v-col>
    </v-row>
    <v-row>
      <p class="title ml-5 headline pt-1 pl-4 ml-4">
        <span class="mr-2"><b></b></span>{{ mapName }}
      </p>
    </v-row>
    <v-divider></v-divider>
    <v-row v-if="isAlertShown">
      <v-col cols="12">
        <v-alert :type="alertType" dismissible outlined>{{ alertMessage }}</v-alert>
      </v-col>
    </v-row>

    <v-row v-if="isMapIdExists">
      <v-col cols="12" sm="8">
        <p class="title">Summary</p>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="stamp-md-simple-table-custom-td">Field Name</th>
                <th>Field Details</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in summary" :key="item.key">
                <td>{{ item.key }}</td>
                <td v-if="item.key === 'Site Name'">{{ siteName }}</td>
                <td v-else-if="item.key === 'Date'">{{ formatDate(item.value) }}</td>
                <td v-else-if="isValidHttpUrl(item.value)">
                  <a :href="item.value" target="_blank">{{ item.value }}</a>
                </td>
                <td v-else>
                  {{ item.value }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12" sm="4" xl="3" class="mt-9">
        <v-card>
          <v-row class="my-3 pl-4">
            <h4 class="mx-3 mt-4 subtitle-1 font-weight-light">Display Map URL and Image</h4>
          </v-row>
          <v-divider class="mx-3 my-2"></v-divider>
          <v-row class="mx-3 mb-1 mt-3">
            <v-btn class="mb-3" block @click="isViewMapImageVisibile = true">View Map</v-btn>
          </v-row>
        </v-card>

        <v-card>
          <v-row class="my-3 pl-4">
            <h4 class="mx-3 mt-3 subtitle-1 font-weight-light">Generate list of zips based on the JSON file stored on Google Storage or Amazon AWS</h4>
          </v-row>
          <v-divider class="mx-3 my-2"></v-divider>
          <v-row class="mt-3 mx-3">
            <v-btn color="grey lighten-3 mb-3" block @click="checkZip()">Generate Zip List</v-btn>
          </v-row>
        </v-card>

        <v-card v-if="isSaturationMap || isAvailabilityMap || isEddm" class="mt-6">
          <v-row class="my-1 pl-4">
            <h4 class="mx-3 mt-3 subtitle-1 font-weight-light">
              <span v-if="isAvailabilityMap">Download Saturation Mailing List</span>
              <span v-else>Download CSV From Valassis</span>
            </h4>
          </v-row>
          <v-divider class="mx-3 my-2"></v-divider>
          <v-row class="mx-1">
            <v-col cols="12" lg="6">
              <v-btn color="grey lighten-3" class="" block @click="getSaturationCounts()">Get Counts</v-btn>
            </v-col>
            <v-col cols="12" lg="6">
              <v-btn color="grey lighten-3" class="" block @click="isSaturationDialogVisible = true">Download CSV</v-btn>
            </v-col>
          </v-row>
        </v-card>

        <template v-if="isNewMovers">
          <v-row class="mr-0">
            <v-card class="mb-4 ml-3 mt-6">
              <v-row class="mb-3 pl-4">
                <h4 class="mx-3 mt-3 subtitle-1 font-weight-light">Generate CSV based on the selected period</h4>
                <h4 class="mx-3 mt-2 body-2 font-weight-light">OBS: By default, the period is last 30 days</h4>
              </v-row>
              <v-divider class="mx-3 my-2"></v-divider>
              <v-row class="mx-3 my-3">
                <v-btn color="grey lighten-3" block @click="isCustomiseCsvVisible = true">Download CSV</v-btn>
              </v-row>
            </v-card>
          </v-row>
          <v-card class="pb-2 mt-n3">
            <v-row class="my-3 pl-4">
              <h4 class="mx-3 mt-4 subtitle-1 font-weight-light">Send a NewMover Campaign to DMM</h4>
            </v-row>
            <v-divider class="mx-3 my-2"></v-divider>
            <v-row class="ml-3 mb-2 mt-2">
              <v-btn color="grey lighten-3" @click="openNewMoverCampaignDialog">Open NewMover Campaign in DMM Tool</v-btn>
            </v-row>
          </v-card>
        </template>

        <v-row v-if="isEddm || isMap">
          <v-col cols="12">
            <v-card class="mb-4">
              <v-row class="my-3 pl-4">
                <h4 class="mx-3 mt-3 subtitle-1 font-weight-light">Generate CSV file based on the data from uploaded JSON file (Uploaded File Url)</h4>
              </v-row>
              <v-divider class="mx-3 my-2"></v-divider>
              <v-row class="mx-3 my-3">
                <v-btn v-if="!isExcelFileExists" color="grey lighten-3" block :disabled="!downloadUrl" class="v-btn theme--light mb-3" @click="downloadRoutesCsv()"
                  >Generate CSV</v-btn
                >
                <v-btn v-else class="mb-3" block color="grey lighten-3"><a v-bind:href="excelLink">Download Excel</a></v-btn>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" sm="10">
        <v-alert prominent type="error">
          <v-row align="center">
            <v-col class="grow"
              >Map id <b>{{ mapID }}</b> is not found!</v-col
            >
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="isMapDetailsExists" class="mt-2">
      <v-col cols="12" sm="8">
        <p class="title ml-1">Details</p>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Field Name</th>
                <th>Field Details</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="[key, value] in mapDetails" :key="key">
                <td>{{ key }}</td>
                <td v-if="isValidHttpUrl(value)">
                  <a :href="value" target="_blank">{{ value }}</a>
                </td>
                <td v-else>{{ value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-layout v-if="isNewMoversDataExists" class="mt-4">
      <v-col cols="8" sm="8">
        <div v-if="downloadStorageUrl">
          <v-btn v-if="isClearSuccess" color="blue darken-1" text @click="isDeleteDialogVisible = true">Clear storage fields</v-btn>
        </div>
      </v-col>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import SaturationCsvModal from './mapDialogs/SaturationCsvModal.vue';
import CsvCountsPanel from './mapDialogs/CsvCountsPanel.vue';
import DataTable from '@/components/common/DataTable.vue';
import ManageNewMoverCampaign from './mapDialogs/ManageNewMoverCampaign.vue';

export default {
  components: {
    'saturation-csv-modal': SaturationCsvModal,
    'csv-counts': CsvCountsPanel,
    'stamp-data-table': DataTable,
    'manage-newmover-campaign': ManageNewMoverCampaign
  },
  data() {
    return {
      mapID: this.$route.params.mapID,
      summary: [],
      type: null,
      mapName: '',
      list: [],
      mapState: [],
      newMovers: [],
      availability: [],
      downloadStorageUrl: '',
      isClearSuccess: true,
      isDeleteDialogVisible: false,
      isViewMapImageVisibile: false,
      imgPath: '',
      isZipListDialogVisible: false,
      downloadUrl: '',
      carrierRouteZips: [],
      carrierRouteZipStringList: '',
      atzZips: [],
      atzStringList: '',
      search: '',
      isAmazonUrl: false, // amazon or google,
      isCustomiseCsvVisible: false,
      csvLink: '',
      excelLink: '',
      isExcelFileExists: false,
      status: '',
      customer: '',
      fileSize: 0,
      isMapIdExists: true,
      tab: null,
      mapItem: null,
      isSaturationDialogVisible: false,
      isAlertShown: false,
      alertType: 'info',
      alertMessage: '',
      isSaturationCountsDialogVisible: false,
      saturationCountsData: [],
      isNewMoverCampaignDialogVisible: false,
      mapStateData: null
    };
  },
  computed: {
    ...mapGetters('customer_messaging', {
      siteName: 'getSiteName'
    }),
    ...mapGetters('users', {
      userStore: 'user'
    }),
    isNewMovers() {
      return this.type === 'newmovers';
    },
    isSaturationMap() {
      return this.mapItem?.emapMode === 'Saturation';
    },
    isEddm() {
      return this.type === 'eddm';
    },
    isMap() {
      return this.type === 'map';
    },
    isAvailabilityMap() {
      return this.type === 'availability';
    },
    isInsertsMap() {
      return this.type === 'inserts';
    },
    isMapStateExists() {
      return this.mapState.length > 0;
    },
    isNewMoversDataExists() {
      return this.newMovers.length > 0;
    },
    isAvailabilityExists() {
      return this.availability.length > 0;
    },
    zipListLabel() {
      return this.isAmazonUrl ? 'Amazon AWS' : 'Google Firebase Storage';
    },
    isMapDetailsExists() {
      return this.isAvailabilityExists || this.isSaturationMap || this.isMapStateExists || this.isNewMoversDataExists;
    },
    mapDetails() {
      return this.isSaturationMap || this.isMapStateExists
        ? Object.entries(this.mapStateData)
        : this.isAvailabilityExists
        ? this.availability
        : this.isNewMoversDataExists
        ? this.newMovers.map(item => Object.values(item))
        : [];
    }
  },
  mounted() {
    this.listSelectedMap();
  },
  methods: {
    async clearStorageFields() {
      this.isDeleteDialogVisible = false;
      let storageFilePath = '';
      this.newMovers.forEach((value, key) => {
        if (value.key === 'Storage Path') {
          storageFilePath = value.value;
        }
      });
      this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
      const response = await this.$store.dispatch('customer_messaging/clearMapsStoragePath', {
        firebaseID: this.mapID,
        storageFilePath
      });
      this.$store.dispatch('hideLoader');
      if (response.status) {
        this.showFeedback('info', 'Storage URL and progress status are cleared successfully! Firebase storage file is deleted successfully!');
        this.isClearSuccess = false;
        this.newMovers.forEach((value, index) => {
          if (value.key === 'Storage Path' || value.key === 'Storage URL') {
            this.newMovers[index].value = '';
          }
        });
      } else {
        this.showFeedback('error', 'Error on deleting storage fields and file!');
      }
    },
    async checkZip() {
      if (this.downloadUrl.length < 1) {
        this.showFeedback('warning', 'Uploaded File Url is empty! Zip list cannot be generated for empty URL!');
      } else {
        this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
        console.log('DownloadUrl:', this.downloadUrl);
        const isUrlFromAmazon = (this.isAmazonUrl = this.downloadUrl.includes('amazon'));

        try {
          const response = await this.$store.dispatch('maps/getZipList', {
            downloadUrl: this.downloadUrl,
            type: this.mapItem.emapMode.toLowerCase(),
            isUrlFromAmazon,
            fileParsing: 'server',
            carrierRoutesOnly: true
          });
          console.log('[ Generate ZipList response]:', response);
          if (response && response.data && response.data.hasOwnProperty('zipcrids') && response.data.zipcrids) {
            this.carrierRouteZips = this.createZipDataTableArray(response.data.zipcrids);
            this.carrierRouteZipStringList = response.data.zipcrids.toString();
          }
          if ((response.data && response.data.hasOwnProperty('atzs') && response.data.atzs) || (response.data.hasOwnProperty('geocodes') && response.data.geocodes)) {
            this.atzZips = this.createZipDataTableArray(response.data.atzs ? response.data.atzs : response.data.geocodes);
            this.atzStringList = response.data.atzs ? response.data.atzs.toString() : response.data.geocodes.toString();
          }
          this.isZipListDialogVisible = true;
        } catch (error) {
          console.log('Generating ZipList failed:', error);
          this.showFeedback('error', JSON.stringify(error));
        } finally {
          this.$store.dispatch('hideLoader');
        }
      }
    },
    createZipDataTableArray(array) {
      const zipDataTableArray = [];
      array.forEach(value => {
        zipDataTableArray.push({
          zip: value
        });
      });
      return zipDataTableArray;
    },
    downloadRoutesCsv() {
      if (this.downloadUrl.length < 1) {
        this.showFeedback('warning', 'Uploaded File Url is empty! Zip list cannot be generated for empty URL!');
      } else {
        this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
        this.$store
          .dispatch('maps/generateCsvFromMapJson', {
            downloadUrl: this.downloadUrl,
            csvFileName: `map_routes_${this.mapID}`
          })
          .catch(error => this.showFeedback('error', error))
          .finally(() => this.$store.dispatch('hideLoader'));
      }
    },
    async getSaturationCounts() {
      try {
        this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
        const result = await this.$store.dispatch('maps/getSaturationCsvCounts', { mapItem: this.mapItem });
        this.saturationCountsData = result || [];
        this.isSaturationCountsDialogVisible = true;
      } catch (error) {
        this.showFeedback('error', error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    closeSaturationCsvModal() {
      this.saturationCountsData = [];
      this.isSaturationCountsDialogVisible = false;
    },
    showFeedback(type, message) {
      this.alertType = type;
      this.alertMessage = message;
      this.isAlertShown = true;
      setTimeout(() => {
        this.isAlertShown = false;
        this.alertMessage = '';
        this.alertType = 'info';
      }, 20000);
    },
    formatDate(dateTotransform) {
      var date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    back() {
      this.$router.back();
    },
    openNewMoverCampaignDialog() {
      this.isNewMoverCampaignDialogVisible = true;
    },
    closeNewMoverCampaignDialog() {
      this.isNewMoverCampaignDialogVisible = false;
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (e) {
        return false;
      }
      return url.protocol === 'http:' || url.protocol === 'https:';
    },
    async listSelectedMap() {
      try {
        this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
        const response = await this.$store.dispatch('customer_messaging/listSelectedMap', { mapID: this.mapID });
        console.log('[ Map Data ]:', response);
        if (!response) {
          this.isMapIdExists = false;
          return;
        }
        this.mapItem = response.data;
        this.isMapIdExists = true;
        this.type = response.data.orderType;
        this.status = response.data.status;
        this.customer = response?.data?.customer ?? '';
        if (!response.data.uploadedFileUrl) {
          this.showFeedback('warning', 'Uploaded File Url is empty! Zip list cannot be generated for empty URL!');
        }
        this.csvLink = response.data.csvLink;
        this.mapName = response.data.name;
        this.summary = response.summary || [];
        this.summary.forEach((value, index) => {
          if (value.key === 'Uploaded File Url') {
            this.downloadUrl = value.value;
          }
          if (value.key === 'Excel Url') {
            this.excelLink = value.value;
            this.isExcelFileExists = true;
          }
        });
        this.mapState = response.mapState;
        this.mapStateData = response.data.mapState;
        this.newMovers = response.newMovers;
        this.availability = response.availability.map(item => [item.key, item.value]);
        this.downloadStorageUrl = response.downloadStorageUrl;
        this.imgPath = response.mapImage;
      } catch (error) {
        console.log('[listSelectedMap]', error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    }
  }
};
</script>
<style scoped>
.map-image-custom-height {
  height: 650px;
  width: 100%;
}
.stamp-md-simple-table-custom-td {
  width: 30%;
}
.stamp-map-details-custom-action {
  background: white;
}
</style>
