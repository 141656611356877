<template>
  <v-dialog v-model="isDialogOpen" width="400" persistent>
    <v-card>
      <v-card-title class="justify-center">
        <p class="subtitle-1 primary--text">Set the customer's first name and last name</p>
      </v-card-title>
      <v-progress-linear v-if="customerNameUpdating" indeterminate color="cyan darken-2" class="mb-2 mt-n3"></v-progress-linear>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-text-field v-model="customerName.first_name" :rules="[v => !!v || 'First name is required!']" label="First name" class="mx-2" color="cyan darken-3" dense required>
            </v-text-field>
          </v-row>
          <v-row class="mt-2">
            <v-text-field v-model="customerName.last_name" :rules="[v => !!v || 'Last name is required!']" label="Last name" class="mx-2" color="cyan darken-3" dense required>
            </v-text-field>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-space-between pb-3 mx-3">
        <v-btn text outlined small color="grey darken-3" :disabled="customerNameUpdating" @click="closeDialog()">Cancel</v-btn>
        <v-btn small outlined color="cyan darken-2" :disabled="customerNameUpdating || !valid" @click="updateCustomerName()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['isDialogOpened', 'customerFirebaseId', 'firebaseOrderId'],
  data() {
    return {
      valid: true,
      customerNameUpdating: false,
      customerName: {
        first_name: '',
        last_name: ''
      }
    };
  },
  computed: {
    isDialogOpen() {
      return this.isDialogOpened;
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeEditCustomerNameDialog');
    },
    async updateCustomerName() {
      try {
        this.customerNameUpdating = true;
        const response = await this.$store.dispatch('customers/updateCustomer', { id: this.customerFirebaseId, data: this.customerName });
        if (!this.customerName.first_name || !this.customerName.last_name) {
          return;
        }
        const orderResponse = await this.$store.dispatch('om_orders/updateCustomerNameInOrder', {
          order_id: this.firebaseOrderId,
          first_name: this.customerName.first_name,
          last_name: this.customerName.last_name
        });
        if (response && orderResponse) {
          this.$emit('customerUpdate', this.customerName);
        }
      } catch (error) {
        this.$emit('updateFeedback', { type: 'error', message: error });
      } finally {
        this.customerNameUpdating = false;
        this.closeDialog();
      }
    }
  }
};
</script>
