<template>
  <v-container fluid>
    <subscription-edit-warning
      :isVisible="campaignEditNotification.isVisible"
      :firebaseOrderId="orderData.firebase_order_id"
      :isOrderUpdatedSuccessfully="isOrderUpdatedSuccessfully"
      class="mt-n8"
      @subscriptionStatus="showSubscriptionStatusChange"
    >
    </subscription-edit-warning>

    <v-alert v-if="isSelectedOptionStartWithEarlierMonth" type="warning">Monthly Budget is disabled because the Delivery option is "Mail to Previous Months".</v-alert>
    <v-alert v-if="!isContentEditable" type="warning">Editing content is disabled because the subscription is canceled.</v-alert>

    <v-card v-if="showMonthlyBudget" class="elevation-0" :loading="newMoversBudget.isBudgetLoading" :disabled="isEditMonthlyBudgetDisabled">
      <template slot="progress">
        <v-progress-linear color="cyan darken-2" indeterminate class="mb-3"></v-progress-linear>
      </template>
      <v-card-text>
        <v-row class="justify-space-between">
          <v-col cols="12" sm="2" class="align-center mt-n3">
            <v-row class="justify-center"><span class="subtitle-1">Min. Budget</span></v-row>
            <v-row class="justify-center">
              <span class="subtitle-1 grey--text text--darken-3">{{ formatNumeral(newMoversBudget.productPrice, '$0,0.00') }}</span>
            </v-row>
          </v-col>
          <v-col cols="12" sm="7">
            <div id="recommended-budget">
              <p class="recommended-budget-txt subtitle-2 font-weight-regular grey--text text--darken-2">Recommended Range</p>
            </div>
            <v-slider
              v-model="monthlyFee"
              :min="parseFloat(newMoversBudget.productPrice)"
              :max="calculatedMaxMonthlyFee"
              step="0.01"
              class="monthly-slider"
              color="primary"
              track-color="primary"
              thumb-label
              thumb-size="50"
            >
              <template v-slot:thumb-label="item">
                <span class="subtitle-2">{{ item.value.toFixed(2) }}</span>
              </template>
            </v-slider>
          </v-col>
          <v-col cols="12" sm="3" class="mt-2">
            <v-row class="justify-center">
              <span class="headline grey--text text--darken-3">{{ formatNumeral(monthlyFee, '$0,0.00') }}</span>
            </v-row>
            <v-row class="justify-center subtitle-2 font-weight-regular"><span>Max monthly spend</span></v-row>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row class="justify-center pt-2">
          <v-col cols="4">
            <v-row class="justify-center"
              ><span class="title">{{ Math.round(newMoversBudget.adjustedAvg) }}</span></v-row
            >
            <v-row class="justify-center"><span>Est. Maximum Monthly Reach</span></v-row>
          </v-col>
          <v-col cols="4">
            <v-row class="justify-center">
              <span :class="{ 'red--text text--darken-2 title': missedNewMovers > 0, 'green--text text--darken-2 title': missedNewMovers === 0 }">
                {{ Math.round(missedNewMovers) }}
              </span>
            </v-row>
            <v-row class="justify-center"><span>Missed New Movers Each Year</span></v-row>
          </v-col>
          <v-col cols="4" class="align-center d-flex">
            <v-row class="justify-center">
              <v-btn color="cyan darken-2" class="white--text" small :loading="newMoversBudget.isUpdateInProgress" @click="updateMonthlyBudget()">
                <span v-if="isMonthlyQtyExists">Set monthly budget</span>
                <span v-else>Update monthly budget</span>
              </v-btn>
              <v-btn outlined class="cyan--text text--darken ml-3" small @click="resetMonthlyFee()">Reset</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SubscriptionEditWarning from '../orders/orderDialogs/PauseSubscriptionNotification';
const numeral = require('numeral');
import { mapGetters } from 'vuex';

export default {
  components: { 'subscription-edit-warning': SubscriptionEditWarning },
  props: ['isPanelOpened', 'firebaseOrderId', 'orderReferences', 'mailQuantity', 'monthlyQuantity', 'orderData', 'isContentEditable'],
  data() {
    return {
      showMonthlyBudget: this.isPanelOpened,
      monthlyFee: 0,
      originalPrice: 0,
      newMoversBudget: {
        minMoversCount: 0,
        maxMoversCount: 0,
        productPrice: 0,
        totalPrice: 0,
        adjustedAvg: 0,
        isBudgetLoading: false,
        isUpdateInProgress: false
      },
      deliveryTimes: 0,
      selectedDeliveryOption: '',
      monthlyAvgPrice: 0,
      orderAvgQty: 0
    };
  },
  computed: {
    references() {
      return this.orderReferences ? this.orderReferences : {};
    },
    mailQty() {
      return this.mailQuantity ? this.mailQuantity : 0;
    },
    monthlyQty() {
      return this.monthlyQuantity ? this.monthlyQuantity : 0;
    },
    currentMailingPricePerPiece() {
      return this.newMoversBudget.totalPrice / this.mailQty; // $
    },
    maxMonthlyFee() {
      return this.isSelectedOptionStartWithEarlierMonth ? this.monthlyFee : this.newMoversBudget.maxMoversCount * this.currentMailingPricePerPiece;
    },
    calculatedMaxMonthlyFee() {
      return (this.maxMonthlyFee + this.maxMonthlyFee * 0.1) * this.deliveryTimes; // max value from months (returned from the map) + 10%
    },
    missedNewMovers() {
      const maxMoversDifference = this.newMoversBudget.maxMoversCount - this.newMoversBudget.adjustedAvg;
      return maxMoversDifference > 0 ? maxMoversDifference * 12 : 0;
    },
    isMonthlyQtyExists() {
      return this.monthly_qty > 0;
    },
    isSubscriptionActive() {
      return (
        this.orderData.automated &&
        this.orderData.automated.campaign_status === 'active' &&
        this.orderData.automated.subscription_status.toUpperCase() === 'ACTIVE' &&
        this.orderData.automated.subscription_id
      );
    },
    isSelectedOptionStartWithEarlierMonth() {
      return this.selectedDeliveryOption === 'start_with_earlier_month';
    },
    isEditMonthlyBudgetDisabled() {
      return !this.isContentEditable || this.isSubscriptionActive || this.isSelectedOptionStartWithEarlierMonth;
    },
    ...mapGetters('edit_audience', {
      isAudienceUpdated: 'getEditAudienceUpdateStatus',
      isAudienceUpdateLoading: 'getAudienceLoadingState'
    }),
    ...mapGetters('om_orders', {
      campaignEditNotification: 'getCampaignEditNotification'
    }),
    isOrderUpdatedSuccessfully() {
      return this.campaignEditNotification.isOrderUpdated && this.campaignEditNotification.updatedData === 'budget';
    }
  },
  watch: {
    isPanelOpened: {
      handler(value) {
        this.$store.commit('om_orders/setCampaignEditNotification', { isOrderUpdated: false });
        this.showMonthlyBudget = value;
        if (value) this.initializeMonthlyBudget();
      },
      immediate: true
    },
    orderData(oldValue, newValue) {
      if (oldValue.monthly_qty !== newValue.monthly_qty || oldValue.order_total !== newValue.order_total) {
        this.initializeMonthlyBudget();
      }
    },
    monthlyFee() {
      if (!this.isSelectedOptionStartWithEarlierMonth) {
        this.newMoversBudget.adjustedAvg = Math.round(this.monthlyFee / this.currentMailingPricePerPiece);
      }
    },
    isAudienceUpdated(value) {
      if (value) {
        this.monthlyFee = 0;
        this.originalPrice = 0;
        // I have to reset values this way because of the computed variables
        this.newMoversBudget.minMoversCount = 0;
        this.newMoversBudget.maxMoversCount = 0;
        this.newMoversBudget.productPrice = 0;
        this.newMoversBudget.totalPrice = 0;
        this.newMoversBudget.adjustedAvg = 0;
        this.initializeMonthlyBudget();
      }
    },
    isAudienceUpdateLoading(value) {
      if (value) this.newMoversBudget.isBudgetLoading = true;
    },
    isSubscriptionActive(value) {
      this.$store.commit('om_orders/setCampaignEditNotification', { isVisible: value });
    }
  },
  methods: {
    initializeMonthlyBudget() {
      if (!this.references.map_id) {
        this.$emit('updateFeedback', { type: 'warning', message: 'Map ID not found in references!' });
      } else {
        this.newMoversBudget.isBudgetLoading = true;
        this.$store
          .dispatch('om_orders/initializeMonthlyBudget', {
            orderId: this.firebaseOrderId,
            mapId: this.references.map_id.split('/')[1]
          })
          .then(response => {
            console.log('[ Init Monthly Budget ]:', response);

            if (response.delivery) {
              this.deliveryTimes = response.delivery.delivery_times ? response.delivery.delivery_times : 0;
              this.selectedDeliveryOption = response.delivery.new_movers_options ? response.delivery.new_movers_options.sel_opt : '';
            }

            this.newMoversBudget.adjustedAvg = Number(this.monthlyQty);
            this.newMoversBudget.minMoversCount = Number(Math.min(...response.moverCounts.months));
            this.newMoversBudget.maxMoversCount = Number(Math.max(...response.moverCounts.months));

            this.monthlyAvgPrice =
              response.delivery && response.delivery.new_movers_options.average_price ? response.delivery.new_movers_options.average_price : response.pricing.order_total;
            this.orderAvgQty = response.moverCounts.avg || 0;

            this.newMoversBudget.productPrice = this.isSelectedOptionStartWithEarlierMonth ? this.monthlyAvgPrice : Number(response.pricing.product_price);
            this.newMoversBudget.totalPrice = Number(response.pricing.total_mailing_price);

            this.monthlyFee = Number(response.pricing.monthly_fee && response.pricing.monthly_fee > 0 ? response.pricing.monthly_fee : this.maxMonthlyFee) * this.deliveryTimes;
            this.originalPrice = this.monthlyFee;
          })
          .catch(error => {
            this.$emit('updateFeedback', { type: 'error', message: error });
          })
          .finally(() => (this.newMoversBudget.isBudgetLoading = false));
      }
    },
    updateMonthlyBudget() {
      this.newMoversBudget.isUpdateInProgress = true;
      this.$store
        .dispatch('om_orders/updateMonthyQtyAndFee', {
          orderId: this.firebaseOrderId,
          monthlyFee: this.monthlyFee,
          monthlyQty: Math.round(this.newMoversBudget.adjustedAvg),
          firebase_site_id: this.orderData.site_db_id,
          osprey_order_id: this.orderData.crm_order_id,
          osprey_customer_id: this.orderData.crm_customer_id,
          summary: {
            monthly_max_price: this.monthlyFee,
            monthly_max_qty: Math.round(this.newMoversBudget.adjustedAvg),
            monthly_avg_price: this.monthlyAvgPrice,
            monthly_avg_qty: this.orderAvgQty,
            original_monthly_max_price: this.originalPrice
          }
        })
        .then(result => {
          if (result && result.status === 'success') {
            this.$store.commit('om_orders/setCampaignEditNotification', { isOrderUpdated: true, updatedData: 'budget', isVisible: this.isSubscriptionActive });
          }
        })
        .catch(error => this.$emit('updateFeedback', { type: 'error', message: error }))
        .finally(() => (this.newMoversBudget.isUpdateInProgress = false));
    },
    resetMonthlyFee() {
      this.monthlyFee = this.originalPrice;
    },
    formatNumeral(amount, format) {
      return numeral(amount).format(format);
    },
    displayFeedback(type, message) {
      this.$emit('updateFeedback', { type, message });
    },
    showSubscriptionStatusChange(data) {
      this.$store.commit('om_orders/setCampaignEditNotification', { isVisible: this.isSubscriptionActive, isOrderUpdated: false });
      if (data && data.status === 'error') {
        this.displayFeedback('error', data.message);
      }
    }
  }
};
</script>
<style scoped>
#recommended-budget {
  width: 31%;
  height: 30px;
  margin-top: 1px;
  background-color: rgb(0, 151, 167, 30%);
  position: absolute;
  border-radius: 1em;
  right: 25.5%;
  text-align: center;
}
#recommended-budget p.recommended-budget-txt {
  margin-top: 2.4em;
}
.monthly-slider {
  width: 100%;
  float: right;
}
.v-slider__thumb:before {
  left: -8px !important;
  margin-top: 3px !important;
}
.v-slider__thumb {
  width: 20px;
  height: 20px;
  padding-top: 20px;
}
.v-slider__thumb-label {
  left: 0.4em !important;
}
</style>
