<template>
  <v-container fluid>
    <json-viewer :isDialogOpened="isJsonDialogOpened" :data="selectedJsonData" @closeDialog="closeJsonDialog()"></json-viewer>

    <v-dialog v-model="isSummaryDialogOpen" max-width="800" transition="dialog-bottom-transition" persistent>
      <v-card class="mb-0">
        <v-card-title>
          <span class="subtitle-1">{{ dialogTitle }}</span>
          <div v-if="isLdpOrderLoading" class="ml-3 mt-n2">
            <div class="dot ml-1"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeSummaryDialog(false)"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider class="mb-3"></v-divider>
        <v-card-text class="pb-7 pt-2">
          <v-row v-for="(process, index) in createLdpOrderProcess" :key="index" class="justify-space-around mb-n1">
            <v-col cols="7" md="8">
              <v-icon left>mdi-chevron-right</v-icon>
              <span :key="index" class="subtitle-1">{{ process.message }}</span>
            </v-col>
            <v-col cols="2">
              <v-progress-circular v-if="!process.status" class="ml-1" color="cyan darken2" size="25" indeterminate></v-progress-circular>
              <v-icon v-else-if="process.status === 'success'" :key="`${index}-success`" color="green" size="32">mdi-check</v-icon>
              <v-icon v-else-if="process.status === 'error'" :key="`${index}-error`" color="red" size="32">mdi-close</v-icon>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn v-if="process.data" text small color="cyan darken-2" outlined @click="showJsonDialog(process.data)">Details</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-row v-if="unavailableAtzCodes">
          <v-col cols="12">
            <v-spacer></v-spacer>
            <v-alert class="mx-7" type="error">
              <strong>Warning</strong>, there are some unavailable ATZ codes in the selected media plan.
              <br />
              Please make sure to check the unavailable ATZs before submitting the VDP order.
              <br />
              <strong> Unavailable ATZ codes: {{ unavailableAtzCodes }} </strong>
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="isMediaPlanCreated" class="justify-center mx-3 pb-4 mb-0">
          <v-col cols="12" md="8">
            <v-tooltip :disabled="!isSubmitVdpOrderButtonDisabled" bottom>
              <template #activator="{ on }">
                <div v-on="on">
                  <v-btn class="white--text" :disabled="isSubmitVdpOrderButtonDisabled" color="cyan darken-1" block @click="closeSummaryDialog(true)">Submit VDP Order</v-btn>
                </div>
              </template>
              <span>Please set first name and last name or company name</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import JsonViewDialog from '../orders/orderDialogs/JsonDataDialog.vue';

export default {
  components: {
    'json-viewer': JsonViewDialog
  },
  props: ['isSummaryDialogOpened', 'dialogTitle', 'isLdpOrderLoading', 'isClientDataEmpty'],
  data() {
    return {
      isJsonDialogOpened: false,
      selectedJsonData: null
    };
  },
  computed: {
    ...mapGetters('ldp', {
      createLdpOrderProcess: 'getLdpOrderProcess',
      isMediaPlanCreated: 'getMediaPlanStatus'
    }),
    unavailableAtzCodes() {
      // get second step of media plan process
      const atzArray = this.createLdpOrderProcess?.find(process => [2, '2'].includes(process?.step?.[0]))?.data?.data;
      return (
        atzArray
          ?.filter(atz => !atz?.available)
          .map(atz => atz?.code)
          ?.join(', ') || ''
      );
    },
    isSummaryDialogOpen() {
      return this.isSummaryDialogOpened;
    },
    isOrderProcessedSuccessfully() {
      return this.createLdpOrderProcess.filter(process => process.status === 'error').length === 0;
    },
    isSubmitVdpOrderButtonDisabled() {
      return !this.isOrderProcessedSuccessfully || this.isClientDataEmpty;
    }
  },
  methods: {
    showJsonDialog(data) {
      this.selectedJsonData = data;
      this.isJsonDialogOpened = true;
    },
    closeJsonDialog() {
      this.isJsonDialogOpened = false;
    },
    closeSummaryDialog(isSubmitMediaPlanClicked) {
      this.$emit('closeDialog', { [isSubmitMediaPlanClicked ? 'isSubmitMediaPlanClicked' : 'isSuccess']: this.isOrderProcessedSuccessfully });
    }
  }
};
</script>

<style scoped>
.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #aaa;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
  animation: loadingAnimation 2400ms linear infinite;
}
.dot:nth-child(1) {
  animation-delay: 0ms;
}
.dot:nth-child(2) {
  animation-delay: 250ms;
  margin: 0 3px;
}
.dot:nth-child(3) {
  animation-delay: 600ms;
}
@keyframes loadingAnimation {
  0% {
    background: #aaa;
  }
  25% {
    background: #00838f;
  }
  50% {
    background: #aaa;
  }
}
</style>
