<template>
  <div>
    <confirm-delete-dialog
      :isDialogOpened="isDeleteConfirmationDialogOpened"
      :itemNames="companyNameToDelete"
      @closeDialog="closeDeleteConfirmationDialog"
      @confirmDelete="confirmDeleteCompany"
    ></confirm-delete-dialog>

    <v-row>
      <v-dialog v-model="isEditDialogOpened" max-width="500px">
        <v-card>
          <v-card-title class="headline">{{ formTitle }}</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="isFormValid">
              <v-row>
                <v-col cols="12">
                  <v-text-field id="companyName" v-model="companyName" label="Name" :rules="companyNameRules" hint="Enter company name" required type="text"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    id="organization_id"
                    v-model="organizationId"
                    label="Organization ID"
                    :rules="organizationRules"
                    hint="Enter Organization ID"
                    required
                    type="text"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field id="email" v-model="email" label="Email" :rules="emailRules" hint="Enter email address" required type="text"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field id="phone" v-model="phone" label="Phone" :rules="phoneRules" hint="Enter phone" required type="number"></v-text-field>
                </v-col>
                <v-col v-if="isEditMode" cols="12">
                  <v-text-field id="token" v-model="token" label="Token" hint="Authenication  token" type="text"></v-text-field>
                </v-col>
                <v-col>
                  <small>*indicates required fields</small>
                </v-col>
                <v-col>
                  <div class="flexing-to-end">
                    <v-btn color="blue" text class="mr-2" @click="close">Close</v-btn>
                    <v-btn :disabled="!isFormValid" color="success" @click="saveCompany">Save</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div class="site-table">
        <v-toolbar flat class="site-table--header">
          <v-col class="pl-0">
            <v-toolbar-title class="headline">Organizations/Companies</v-toolbar-title>
          </v-col>
          <v-col md="4">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          </v-col>
          <v-col cols="2" xl="2" class="pr-0">
            <div class="table-header--actions">
              <v-btn dark color="primary" class="white--text" @click="newCompany">+ New Company</v-btn>
            </div>
          </v-col>
        </v-toolbar>
        <data-table
          :headers="headers"
          :data="listOfcompanies"
          :actions="actions"
          :item-key="'document_id'"
          :search="search"
          :is-loading="isDataLoading"
          @editItem="editItem"
          @deleteItem="openDeleteDialog"
        >
        </data-table>
      </div>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DataTable from '../common/DataTable.vue';
import ConfirmDeleteDialog from '../dialog/ConfirmDeleteDialog';

export default {
  name: 'organizations',
  components: { DataTable, ConfirmDeleteDialog },
  data() {
    return {
      isEditDialogOpened: false,
      search: '',
      headers: [
        { text: 'Name', align: 'left', value: 'name', width: 170 },
        { text: 'Organization ID', value: 'organization_id', align: 'left', width: 140 },
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'Phone', value: 'phone', align: 'left' },
        { text: 'Token', value: 'token', align: 'left' }
      ],
      actions: [
        { icon: 'mdi-pencil', iconColor: 'teal', title: 'Edit', eventName: 'editItem' },
        { icon: 'mdi-delete', iconColor: 'red', title: 'Delete', eventName: 'deleteItem' }
      ],
      isEditMode: false,
      selectedKey: '',
      companyName: null,
      companyNameRules: [v => !!v || 'Company name is required'],
      organizationId: null,
      organizationRules: [v => !!v || 'Organization ID is required'],
      email: null,
      emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'Enter a valid email address'],
      phone: null,
      phoneRules: [v => !!v || 'Valid phone number is required'],
      token: '',
      isDataLoading: true,
      isFormValid: true,
      isDeleteConfirmationDialogOpened: false,
      companyNameToDelete: ''
    };
  },
  computed: {
    formTitle() {
      return this.isEditMode ? 'Edit Company' : 'New Company';
    },
    ...mapGetters('design_live', {
      listOfcompanies: 'companies'
    })
  },
  created() {
    this.$store.dispatch('design_live/initRealtimeListeners');
    this.$store.dispatch('design_live/listCompanies').then(data => {
      if (data.loaded) {
        this.isDataLoading = false;
      }
    });
  },
  methods: {
    newCompany() {
      this.isEditDialogOpened = true;
    },
    saveCompany() {
      const companyConfig = {
        companylNameToInsert: this.companyName,
        organizationIdToInsert: this.organizationId,
        emailToInsert: this.email,
        phoneToInsert: this.phone
      };
      if (this.isEditMode) {
        Object.assign(companyConfig, {
          companyKey: this.selectedKey,
          token: this.token
        });
      }
      this.$store
        .dispatch(`design_live/${!this.isEditMode ? 'save' : 'update'}Company`, companyConfig)
        .catch(error => {
          console.log('Save new company has failed: ', error);
          this.$store.dispatch('showAlert', { type: 'error', message: error });
        })
        .finally(() => this.close());
    },
    editItem(item) {
      this.isEditMode = true;
      this.email = item.email;
      this.companyName = item.name;
      this.organizationId = item.organization_id;
      this.phone = item.phone;
      this.selectedKey = item.document_id;
      this.token = item.authToken;
      this.isEditDialogOpened = true;
    },
    close() {
      this.isEditDialogOpened = false;
      this.isEditMode = false;
      this.companyName = '';
      this.organizationId = '';
      this.email = '';
      this.phone = '';
    },
    openDeleteDialog(item) {
      this.selectedKey = item.document_id;
      const selectedCompanyToDelete = this.listOfcompanies.find(company => company.document_id === this.selectedKey);
      this.companyNameToDelete = selectedCompanyToDelete ? selectedCompanyToDelete.name : this.selectedKey;
      this.isDeleteConfirmationDialogOpened = true;
    },
    closeDeleteConfirmationDialog() {
      this.companyNameToDelete = '';
      this.isDeleteConfirmationDialogOpened = false;
    },
    async confirmDeleteCompany() {
      this.$store.dispatch('showLoader');
      await this.$store.dispatch('design_live/deleteCompany', { key: this.selectedKey });
      this.companyNameToDelete = '';
      this.selectedKey = '';
      this.$store.dispatch('hideLoader');
    }
  }
};
</script>
<style scoped></style>
