<template>
  <v-container fluid transition="fade-transition">
    <confirmation-dialog
      :isConfirmationDialogOpened="isConfirmationDialogOpened"
      :action="confirmationDialogAction"
      @closeConfirmationDialog="closeConfirmationDialog"
      @actionConfirmed="duplicateConfirmed"
    >
    </confirmation-dialog>

    <confirm-delete-dialog :isDialogOpened="isDeleteDialogOpened" :itemNames="selectedTemplateCategory.name" @confirmDelete="deleteConfirmed" @closeDialog="closeDeleteDialog">
    </confirm-delete-dialog>

    <v-toolbar flat class="site-table--header site-table--lists">
      <v-row class="mt-n3 mb-0">
        <v-toolbar-title class="headline grey--text text--darken-3 ml-n3 pt-1">Template Categories</v-toolbar-title>
        <v-spacer />
        <v-col cols="12" sm="5" md="4" lg="4" xl="3" class="text-center">
          <v-text-field v-model="search" label="Search" class="mt-n3" append-icon="mdi-magnify" outlined dense hide-details></v-text-field>
        </v-col>
        <v-btn color="blue" class="white--text ml-2 mr-n1" min-width="200" @click="addNewTemplateCategoryDialog()"
          ><v-icon left>mdi-plus-circle</v-icon>Add New Template Category</v-btn
        >
      </v-row>
    </v-toolbar>
    <div class="site-table mt-n5 ml-n1 mr-0">
      <stamp-data-table
        :headers="headers"
        :data="template_categories"
        :actions="actions"
        :search="search"
        :is-loading="isTemplateCategoriesTableLoading"
        :isItemsPerPageDisabled="true"
        item-key="template_category_firebase_id"
        @viewTemplateCategory="viewTemplateCategory"
        @editTemplateCategory="editTemplateCategory"
        @duplicateTemplateCategory="duplicateTemplateCategory"
        @deleteTemplateCategory="deleteTemplateCategory"
      >
        <template #[`item.graphic`]="{ item }">
          <v-img max-width="35" :src="item.image"></v-img>
        </template>
        <template #[`item.template_category_firebase_id`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon color="amber darken-3" v-on="on" @click="copyItemToClipboard(item.template_category_firebase_id)">mdi-firebase</v-icon>
            </template>
            <span>Copy FirebaseID ( {{ item.template_category_firebase_id }} ) to clipboard</span>
          </v-tooltip>
        </template>
      </stamp-data-table>
    </div>
  </v-container>
</template>

<script>
import ConfirmDeleteDialog from '../../dialog/ConfirmDeleteDialog';
import ConfirmationDialog from '../../order-management/orders/orderDialogs/ConfirmationDialog';
import { mapGetters } from 'vuex';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: {
    'confirm-delete-dialog': ConfirmDeleteDialog,
    'confirmation-dialog': ConfirmationDialog,
    'stamp-data-table': DataTable
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Firebase ID', value: 'template_category_firebase_id', align: 'center', sortable: false }
      ],
      actions: [
        { icon: 'mdi-eye', iconColor: 'blue darken-1', title: 'View Template Category', eventName: 'viewTemplateCategory' },
        { icon: 'mdi-pencil', iconColor: 'green darken-2', title: 'Edit Template Category', eventName: 'editTemplateCategory' },
        { icon: 'mdi-plus-box-multiple-outline', iconColor: 'grey darken-1', title: 'Duplicate Template Category', eventName: 'duplicateTemplateCategory' }
      ],
      isDeleteDialogOpened: false,
      selectedTemplateCategory: {},
      isConfirmationDialogOpened: false,
      confirmationDialogAction: ''
    };
  },
  computed: {
    ...mapGetters('template_categories', {
      template_categories: 'getTemplateCategories',
      isTemplateCategoriesTableLoading: 'getTemplateCategoriesLoadingState'
    }),
    ...mapGetters('users', {
      userAccessLevel: 'userAccessLevel'
    }),
    isUserSuperAdmin() {
      return this.userAccessLevel && parseInt(this.userAccessLevel.value) === 100;
    }
  },
  created() {
    this.$store.dispatch('template_categories/getAllTemplateCategories');
    if (this.isUserSuperAdmin) {
      this.actions.push({ icon: 'mdi-delete-outline', iconColor: 'red darken-1', title: 'Delete Template Category', eventName: 'deleteTemplateCategory' });
    }
  },
  methods: {
    addNewTemplateCategoryDialog() {
      this.$router.replace('/template-categories/new');
    },
    editTemplateCategory(template_category) {
      this.$router.replace(`/template-categories/edit/${template_category.template_category_firebase_id}`);
    },
    viewTemplateCategory(template_category) {
      this.$router.replace(`/template-categories/view/${template_category.template_category_firebase_id}`);
    },
    duplicateTemplateCategory(template_category) {
      this.confirmationDialogAction = `duplicate template category '${template_category.name}'`;
      this.selectedTemplateCategory.data = template_category;
      this.isConfirmationDialogOpened = true;
    },
    async duplicateConfirmed() {
      this.$store.dispatch('showLoader', { message: `Duplicating template category ${this.selectedTemplateCategory.data.name} in progress...` });
      try {
        this.selectedTemplateCategory.data.name = `${this.selectedTemplateCategory.data.name}  Duplicated`;
        const { template_category_firebase_id, ...categoryData } = this.selectedTemplateCategory.data;
        const templateCategoryDuplicate = { data: categoryData };
        const result = await this.$store.dispatch('template_categories/addNewTemplateCategory', templateCategoryDuplicate);
        this.setSnackbar('success', `Template Category saved with id: ${result}. `);
      } catch (error) {
        this.showAlert('error', error.message || error);
      } finally {
        this.selectedTemplateCategory = {};
        this.closeConfirmationDialog();
        this.$store.dispatch('hideLoader');
      }
    },
    deleteTemplateCategory(template_category) {
      this.selectedTemplateCategory = template_category;
      this.isDeleteDialogOpened = true;
    },
    async deleteConfirmed() {
      this.$store.dispatch('showLoader', { message: `Deleting template category in progress...` });
      try {
        await this.$store.dispatch('template_categories/deleteTemplateCategory', { template_category_firebase_id: this.selectedTemplateCategory.template_category_firebase_id });
        this.setSnackbar('success', `Template Category [ ${this.selectedTemplateCategory.template_category_firebase_id} ] has been deleted successfully!`);
      } catch (error) {
        console.error('[deleteTemplateCategory error]', error);
        this.showAlert('error', error.message || error || 'Could not delete template category!');
      } finally {
        this.closeDeleteDialog();
        this.selectedTemplateCategory = {};

        this.$store.dispatch('hideLoader');
      }
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpened = false;
    },
    closeConfirmationDialog() {
      this.isConfirmationDialogOpened = false;
      this.confirmationDialogAction = '';
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    }
  }
};
</script>
