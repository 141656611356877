<template>
  <v-select
    v-model="selectedValue"
    :items="items"
    item-text="name"
    item-value="id"
    :label="label"
    :rules="rules"
    :type="type"
    :multiple="multiple"
    :readonly="readonly"
    dense
    outlined
    return-object
    @input="sendToParent"
  ></v-select>
</template>

<script>
export default {
  props: ['label', 'items', 'fieldValue', 'rules', 'type', 'placeholder', 'required', 'readonly', 'multiple'],
  data() {
    return {
      selectedValue: this.fieldValue
    };
  },
  methods: {
    sendToParent() {
      this.$emit('input', this.selectedValue);
    }
  }
};
</script>
