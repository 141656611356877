<template>
    <div>
      <!-- dark -->
      <v-jumbotron :gradient="gradient" class="increase-height">
        <v-container fill-height>
          <v-layout align-center>
            <v-flex text-xs-center>
                <h3 class="display-3">No Path defined with this name or misspelled! </h3>
            </v-flex>
          </v-layout>
        </v-container>
      </v-jumbotron>
    </div>
</template>

<script>
export default {
    data () {
        return {
            gradient: 'to top, #15701f, #11af14'
        }
    }
}
</script>

<style scoped>
.increase-height{
    height: 1000px !important;
}
</style>