<template>
	<v-container id="loginPage">
		<v-row justify="center">
			<v-col sm="6" lg="4">
				<v-card>
					<v-card color="primary" class="loginHeader">
						<v-row class="justify-center">
							<img src="@/assets/OneBrandMT_pipe.png" height="55">
							<span class="white--text headline mt-3 ml-3 font-weight-light">STAMP</span>
						</v-row>
					</v-card>
					<v-card-title class="justify-center pt-8 mb-2">
						<span class="headline grey--text text--darken-2 font-weight-light">{{ cardTitle }}</span>
					</v-card-title>
					<v-card-text>
						<template v-if="!isForgotPasswordFormVisible">
							<v-row v-if="!isPasswordFormChanged" class="justify-center">
								<v-col cols="12" md="10" lg="8">
									<v-btn type="submit" block outlined large @click="loginWithGoogle">
										<div>
											<v-img src="@/assets/logos/google_logo.svg" class="google-logo"></v-img>
											<span class="google-btn-text grey--text text--darken-2 text-normal">Sign in with Google</span>
										</div>
									</v-btn>
								</v-col>
							</v-row>
							<v-row v-if="!isPasswordFormChanged" class="justify-center mt-2">
								<v-col cols="5">
									<v-divider></v-divider>
								</v-col>
								<span>OR</span>
								<v-col cols="5">
									<v-divider></v-divider>
								</v-col>
							</v-row>
						</template>
						<v-alert v-if="alertMessage" :type="alertType" outlined dense dismissible class="mt-5 mb-5">{{ alertMessage }}</v-alert>
						<template v-if="!isForgotPasswordFormVisible">
							<v-form v-if="!isPasswordFormChanged" ref="login" v-model="isFormValid">
								<v-row class="justify-center">
									<v-col cols="12" md="10" xl="8">
										<label>Email</label>
										<v-text-field
											v-model="email"
											:rules="emailRules"
											type="email"
											required
											outlined
											dense
										></v-text-field>
									</v-col>
								</v-row>
								<v-row class="justify-center mt-n10">
									<v-col cols="12" md="10" xl="8">
										<label>Password</label>
										<v-text-field
											v-model="password"
											:rules="passwordRules"
											type="password"
											outlined
											dense
										></v-text-field>
									</v-col>
								</v-row>
								<v-row class="justify-center mt-n6 mb-5">
									<v-col cols="12" md="10" xl="8">
										<v-btn block large color="primary"
											class="white--text"
											type="submit"
											:disabled="!isFormValid"
											@click="loginWithEmailAndPassword($event)">
											Sign in
										</v-btn>
									</v-col>
								</v-row>
								<v-row class="justify-center mt-n10">
									<v-col cols="12" md="10" xl="8" class="text-center">
										<v-btn text x-small color="blue-grey darken-1" class="subtitle-2 no-padding mb-3 mt-4 no-uppercase" @click="toggleForgotPasswordForm()">Forgot your password?</v-btn>
									</v-col>
								</v-row>
							</v-form>
							<v-form v-if="isPasswordFormChanged" ref="newPassword" v-model="isFormValid">
								<v-row class="justify-center">
									<v-col cols="12" md="10" xl="8">
										<label>Please enter a new password</label>
										<v-text-field
											v-model="newPassword"
											:rules="passwordRules"
											type="password"
											outlined
											dense>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="justify-center mt-n6 mb-5">
									<v-col cols="12" md="10" xl="8">
										<v-btn block large color="primary"
											class="white--text"
											type="submit"
											:disabled="!isFormValid"
											@click="loginWithEmailAndPassword($event)">
											Save and Continue
										</v-btn>
									</v-col>
								</v-row>
							</v-form>
						</template>
						<v-form v-if="isForgotPasswordFormVisible" ref="forgotPassword" v-model="isFormValid">
							<v-row class="justify-center">
								<v-col cols="12" md="10" xl="8">
									<label>Please enter your email</label>
									<v-text-field
										v-model="email"
										:rules="emailRules"
										type="email"
										required
										outlined
										dense>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row class="justify-center mt-n6 mb-5">
								<v-col cols="12" md="10" xl="8">
									<v-btn block large color="primary"
										class="white--text"
										:disabled="!isFormValid"
										@click="sendForgotPasswordEmail()">
										Send reset password email
									</v-btn>
								</v-col>
							</v-row>
							<v-row class="justify-center mt-n10">
								<v-col cols="12" md="10" xl="8" class="text-center">
									<v-btn text x-small color="blue-grey darken-1" class="subtitle-2 no-padding mb-3 mt-4 no-uppercase" @click="toggleForgotPasswordForm()">Back to login</v-btn>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { auth } from '../../services/firebaseInit.js'

export default {
	name: 'login',
	data() {
		return {
			isFormValid: true,
			email: '',
			emailRules: [
				v => !!v || 'E-mail is required',
				v => /.+@.+/.test(v) || 'E-mail must be valid'
			],
			password: '',
			newPassword: '',
			passwordRules: [
				v => !!v || 'Password is required'
			],
			registerPage: '/register',
			dateNow: new Date(),
			date: '',
			env: process.env.VUE_APP_APP_URL,
			alertMessage: '',
			alertType: 'error',
			isPasswordFormChanged: false,
			newPasswordEntered: false,
			isGoogleLogin: false,
			acceptedDomains: ['onebrandmarketing.com','imagemedia.com','forhim.com',
				'cardigent.com','postcardpros.com','eddmexperts.com',
				'sonicprint.com','growmail.com','vericast.com',
				'clippermagazine.com','webgurus.biz', 'webgurus.io'
			],
			isForgotPasswordFormVisible: false,
		}
	},
	computed: {
		cardTitle() {
			return this.isForgotPasswordFormVisible ? 'Forgot password' : 'Sign in';
		},
		...mapGetters('users', {
			user: 'user',
			error: 'error',
			loading: 'loading'
		})
	},
	watch: {
		user(value) {
			if (value) {
				if (this.isGoogleLogin) {
					let domain = value.email.replace(/.*@/, '') // .split('.')[0]
					if (!this.acceptedDomains.includes(domain)) {
						auth.signOut().then(() => {
							this.$store.dispatch('users/deleteUser', {key: value.id})
							window.location.href = this.env
						})
					}
				}
				this.$router.push(this.$route.query.redirect ? this.$route.query.redirect.toString() : '/')
			}
		},
		newPassword (value) {
			this.newPasswordEntered = value ? true : false
		}
	},
	created() {
		this.date = this.dateNow.toISOString() + ' GMT+00'
	},
	methods: {
		loginWithEmailAndPassword(event) {
			this.isGoogleLogin = false;
			event.preventDefault();
			if (this.email && this.password && !this.newPasswordEntered && this.newPassword === '') {
				this.$store.dispatch('users/signUserIn', {
					email: this.email,
					password: this.password,
					loginTime: this.date,
					isPasswordChanged: false
				}).then(response => {
					if (response && response.setNewPassword) {
						this.isPasswordFormChanged = true
					}
				}).catch(error => {
					console.log('Error:', error)
					this.alertMessage = error;
					this.alertType = 'error';
				})
			} else if (this.email && this.newPassword !== '' && this.newPasswordEntered) {
				this.$store.dispatch('users/signUserIn', {
					email: this.email,
					password: this.newPassword,
					loginTime: this.date,
					isPasswordChanged: true
				})
				.catch(error => {
					console.log('Error:', error)
					this.alertMessage = error;
					this.alertType = 'error';
				})
			}
		},
		loginWithGoogle() {
			this.isGoogleLogin = true
			this.$store.dispatch('users/signUserInWithGoogle', { time: this.date })
		},
		async sendForgotPasswordEmail() {
			try {
				const response = await this.$store.dispatch('users/sendForgotPasswordEmail', this.email);
				this.alertMessage = response;
				this.alertType = 'success';
			} catch (error) {
				this.alertMessage = error;
				this.alertType = 'error';
			}
		},
		toggleForgotPasswordForm() {
			this.isForgotPasswordFormVisible = !this.isForgotPasswordFormVisible;
			this.alertMessage = '';
		}
	} // end methods
}
</script>
<style scoped>
#loginPage .google-logo {
	width: 23px;
	height: 24px;
	display: inline-block;
	vertical-align: middle;
	margin: 0px 30px 0px 0px;
}

#loginPage .google-btn-text {
	text-transform: none;
	display: inline-block;
	vertical-align: middle;
	letter-spacing: 0px;
	font-size: 16px;
	font-weight: bold;
	font-family: 'Roboto', arial, sans-serif;
}

#loginPage .theme--light.v-btn {
    color: rgba(0, 0, 0, 0.3);
}

.no-padding {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.no-uppercase {
    text-transform: none;
}

/** XMAS DECOR */
/*.v-card > *:first-child:not(.v-btn):not(.v-chip), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip) {
	margin-left: 0px;
	margin-right: 0px;
	background-image: url('../../assets/christmas.jpg');
	background-size: 1000px 110px;
	padding-top: 3px;
}*/
</style>