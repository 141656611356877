import { dbFS } from '@/services/firebaseInit';
import fouroverStatusService from '../../services/fouroverStatusService.js';

const state = {
  fouroverStatuses: [],
  isFouroverStatusesLoading: false,
  fouroverStatusModel: [
    {
      label: '4over Status ID',
      model: 'fourover_status_id',
      value: '',
      type: 'text',
      input_type: 'textfield',
      is_required: true,
      rule: [value => !!value || '4over Status ID is required!']
    },
    {
      label: '4over Status Name',
      model: 'fourover_status_name',
      value: '',
      type: 'text',
      input_type: 'textfield'
    },
    {
      label: 'Osprey Order Status Number',
      model: 'order_status_number',
      value: '',
      type: 'number',
      input_type: 'textfield',
      is_required: true,
      rule: [value => !!value || 'Osprey Order Status Number is required!']
    },
    {
      label: 'Osprey Order Status Name',
      model: 'order_status_name',
      value: '',
      type: 'text',
      input_type: 'textfield'
    },
    {
      label: 'Osprey Maildrop Status Number',
      model: 'mail_drop_status_number',
      value: '',
      type: 'number',
      input_type: 'textfield',
      is_required: true,
      rule: [value => !!value || 'Osprey Maildrop Status Number is required!']
    },
    {
      label: 'Osprey Maildrop Status Name',
      model: 'mail_drop_status_name',
      value: '',
      type: 'text',
      input_type: 'textfield'
    }
  ],
  selectedModel: []
};

const getters = {
  getFouroverStatuses(state) {
    return state.fouroverStatuses;
  },
  getFouroverStatusesLoadingState(state) {
    return state.isFouroverStatusesLoading;
  },
  getSelectedModel(state) {
    return state.selectedModel;
  },
  getFouroverStatusModel(state) {
    return state.fouroverStatusModel;
  }
};

const mutations = {
  clearFouroverStatuses(state) {
    state.fouroverStatuses = [];
  },
  clearModel(state) {
    state.fouroverStatusModel.map(model => (model.value = ''));
    state.selectedModel = [];
  },
  setFouroverStatuses(state, payload) {
    state.fouroverStatuses = payload;
  },
  setSelectedModel(state, payload) {
    state.selectedModel = payload;
  },
  setFouroverStatusesLoadingState(state, payload) {
    state.isFouroverStatusesLoading = payload;
  }
};

const actions = {
  initSelectedModel({ commit, getters }, payload) {
    let fouroverStatusData = getters.getFouroverStatusModel;

    if (payload) {
      fouroverStatusData.forEach((modelProperty, index) => {
        if (payload[modelProperty.model]) {
          fouroverStatusData[index].value = payload[modelProperty.model];
        }
      });
    }
    commit('setSelectedModel', fouroverStatusData);
  },
  getAllFouroverStatuses({ commit }, payload) {
    commit('setFouroverStatusesLoadingState', true);
    return new Promise(resolve => {
      dbFS.collection('fourover_statuses').onSnapshot(fouroverStatusesSnapshot => {
        commit('clearFouroverStatuses');
        const fouroverStatusData = fouroverStatusesSnapshot.docs.map(fouroverStatus => {
          return { fourover_status_firebase_id: fouroverStatus.id, ...fouroverStatus.data() };
        });
        commit('setFouroverStatuses', fouroverStatusData);
        commit('setFouroverStatusesLoadingState', false);
        resolve(fouroverStatusData);
      });
    });
  },
  getFouroverStatusById(_context, payload) {
    try {
      return fouroverStatusService.getById(payload, { isIdReturned: true });
    } catch (error) {
      console.log('getFouroverStatusById has failed: ', error);
    }
  },
  addNewFouroverStatus(_context, payload) {
    return fouroverStatusService.add(payload);
  },
  editFouroverStatus(_context, payload) {
    return fouroverStatusService.update(payload.id, payload.data);
  },
  deleteFouroverStatus(_context, payload) {
    return fouroverStatusService.delete(payload.fourover_status_firebase_id);
  },
  async saveFouroverStatus({ dispatch }, payload) {
    const functionName = payload.id ? 'editFouroverStatus' : 'addNewFouroverStatus';
    return dispatch(functionName, payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
