<template>
  <div class="site-table">
    <manage-user-dialog
      :isDialogOpened="isManageUserDialogOpened"
      :title="manageUserDialogTitle"
      :isEditMode="isEditMode"
      :userData="selectedUser"
      @displayFeedback="displayFeedback"
      @closeDialog="closeManageUserDialog()"
    ></manage-user-dialog>

    <confirm-delete-dialog
      :isDialogOpened="isDeleteDialogOpened"
      :itemNames="selectedUserEmail"
      @confirmDelete="deleteConfirmed()"
      @closeDialog="closeDeleteDialog()"
    ></confirm-delete-dialog>

    <v-toolbar flat class="site-table--header site-table--lists">
      <v-row class="justify-space-between">
        <v-col cols="12" sm="3" md="6">
          <v-toolbar-title class="headline">Users</v-toolbar-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="5" md="4" xl="2">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details class="text-end"></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="2" xl="1">
          <v-row class="justify-end">
            <v-btn dark class="mt-1 blue darken-1 white--text mr-3" @click="openManageUserDialog(false)"> <v-icon class="mr-1">mdi-plus</v-icon>New User </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-alert v-model="mainAlert" :type="mainAlertType" dismissible>{{ mainAlertText }}</v-alert>

    <stamp-data-table
      :headers="headers"
      :data="usersList"
      :search="search"
      item-key="_id"
      sort-by="name"
      :sort-desc="false"
      :is-items-per-page-disabled="true"
      :actions="actions"
      @editItem="editItem"
      @deleteItem="deleteItem"
    >
      <template #[`item.group`]="{ item }">
        <span v-for="(groupItem, index) in Object.values(item.groups)" :key="index"
          >{{ groupItem.name }} <template v-if="index < Object.values(item.groups).length - 1"> / </template></span
        >
      </template>
      <template #[`item.last_login`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <td v-on="on">{{ shortenDate(item.last_login) }}</td>
          </template>
          <span>{{ item.last_login }}</span>
        </v-tooltip>
      </template>
      <template #[`item._id`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon v-on="on" @click="copyItemToClipboard(item._id)"><v-icon color="amber darken-3">mdi-firebase</v-icon></v-btn>
          </template>
          <span>Copy user's FirebaseID to clipboard [ {{ item._id }} ]</span>
        </v-tooltip>
      </template>
    </stamp-data-table>
  </div>
</template>

<script>
import ManageUserDialog from './dialogs/ManageUserDialog.vue';
import ConfirmDeleteDialog from '../dialog/ConfirmDeleteDialog.vue';
import DataTable from '../common/DataTable.vue';
import userService from '../../services/userService.js';

export default {
  name: 'users',
  components: { ManageUserDialog, ConfirmDeleteDialog, 'stamp-data-table': DataTable },
  data() {
    return {
      isManageUserDialogOpened: false,
      manageUserDialogTitle: '',
      search: '',
      headers: [
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'Groups', value: 'group', align: 'left' },
        { text: 'SalesForceUserID', value: 'salesforce_user_id', align: 'left' },
        { text: 'Last login', value: 'last_login', align: 'left', width: '120' },
        { text: 'FirebaseID', value: '_id', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
      ],
      actions: [
        { icon: 'mdi-pencil', iconColor: 'teal', title: 'Edit User', eventName: 'editItem' },
        { icon: 'mdi-delete', iconColor: 'red', title: 'Delete User', eventName: 'deleteItem' }
      ],
      mainAlert: false,
      mainAlertText: '',
      mainAlertType: 'info',
      isDeleteDialogOpened: false,
      selectedUser: null,
      isEditMode: false,
      isDeleteLoading: false,
      usersList: [],
      isLoading: false
    };
  },
  computed: {
    selectedUserEmail() {
      return this.selectedUser?.email ?? '';
    }
  },
  async created() {
    this.isLoading = true;
    userService.attachRootListener(
      users => {
        this.usersList = users;
        this.isLoading = false;
      },
      { isArray: true, isIdReturned: true }
    );
  },
  methods: {
    openManageUserDialog(isEditMode, title) {
      this.manageUserDialogTitle = title || `${isEditMode ? 'Edit' : 'New'} User`;
      this.isManageUserDialogOpened = true;
    },
    closeManageUserDialog() {
      this.manageUserDialogTitle = '';
      this.selectedUser = {};
      this.isEditMode = false;
      this.isManageUserDialogOpened = false;
    },
    displayFeedback(data) {
      this.setMainAlert(data.type, data.message);
    },
    editItem(item) {
      this.selectedUser = item;
      this.isEditMode = true;
      this.openManageUserDialog(true);
    },
    deleteItem(item) {
      this.selectedUser = item;
      this.isDeleteDialogOpened = true;
    },
    deleteConfirmed() {
      this.isDeleteLoading = true;
      const key = this.selectedUser._id;
      this.$store
        .dispatch('users/deleteUser', { key: key })
        .then(() => {
          this.setMainAlert('success', 'User deleted successfully!');
        })
        .catch(error => {
          console.log('Deleting user failed:', error);
          this.setMainAlert('error', `Deleting user failed! ${error}`);
        })
        .finally(() => {
          this.isDeleteDialogOpened = false;
          this.isDeleteLoading = false;
        });
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpened = false;
      this.selectedUser = {};
    },
    shortenDate(date) {
      const shortDate = date ? date.toString().split('T') : '';
      return shortDate ? shortDate[0] : '';
    },
    setMainAlert(type, message) {
      this.mainAlertType = type;
      this.mainAlertText = message;
      this.mainAlert = true;
      setTimeout(() => {
        this.mainAlert = false;
      }, 8000);
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    }
  }
};
</script>
