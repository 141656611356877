<template>
  <v-col cols="12" md="5">
    <v-row class="justify-center mb-1"
      ><span class="subtitle-1">{{ sideLabel }} Artwork</span></v-row
    >
    <template v-if="showArtworkPlaceholder">
      <v-row class="mt-5 justify-center">
        <v-icon size="80" class="justify-center align-center" color="grey lighten-1">mdi-image-outline</v-icon>
      </v-row>
      <v-row class="justify-center mt-5">
        <span class="body-2">No {{ artworkSide }} artwork uploaded yet.</span>
      </v-row>
    </template>
    <v-row class="justify-center">
      <v-img v-show="isArtworkLoaded" :src="artworkSource" class="showPointerOnHover" @click="viewImage" @error="onImageLoadError($event)" @load="onImageLoad"></v-img>
      <v-skeleton-loader v-show="!isArtworkLoaded" min-width="400" max-width="600" height="280" type="image"></v-skeleton-loader>
    </v-row>
    <v-row class="my-5 justify-center">
      <v-btn color="primary" class="white--text mt-3" @click="replaceArtwork"> <v-icon class="mr-2">mdi-restore</v-icon>Replace {{ sideLabel }} Artwork </v-btn>
    </v-row>
  </v-col>
</template>
<script>
export default {
  props: ['artworkSide', 'isLoaded', 'artworkUrl'],
  data() {
    return {
      isArtworkLoaded: false,
      artworkSource: ''
    };
  },
  computed: {
    sideLabel() {
      return `${this.artworkSide[0].toUpperCase()}${this.artworkSide.slice(1)}`;
    },
    showArtworkPlaceholder() {
      return this.isArtworkLoaded && !this.artworkSource;
    }
  },
  watch: {
    isLoaded(value) {
      this.isArtworkLoaded = value;
    },
    artworkUrl(value) {
      this.artworkSource = value;
    }
  },
  mounted() {
    this.isArtworkLoaded = this.isLoaded;
    this.artworkSource = this.artworkUrl;
  },
  methods: {
    viewImage() {
      window.open(this.artworkSource);
    },
    async onImageLoadError(image) {
      this.isArtworkLoaded = false;
      await this.delay(2000);
      this.artworkSource = `${image}?${this.artworkSide}`;
    },
    onImageLoad() {
      this.isArtworkLoaded = true;
    },
    replaceArtwork() {
      this.$emit('onReplace', this.artworkSide);
    },
    async delay(ms) {
      return new Promise(res => setTimeout(res, ms));
    }
  }
};
</script>
<style scoped>
.showPointerOnHover {
  cursor: pointer;
}
</style>
