import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import { store } from './store';
import * as Sentry from '@sentry/vue';
let packageJson = require('../package.json');

import { auth } from './services/firebaseInit.js';
import VueFire from 'vuefire';

Vue.use(VueFire);

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

// PrismEditor
import 'prismjs';
import 'prismjs/themes/prism.css';
import 'vue-prism-editor/dist/VuePrismEditor.css';

import uploader from 'vue-simple-uploader';
Vue.use(uploader);

import tinymce from 'tinymce';
Vue.use(tinymce);

import DeleteDialog from './components/dialog/DeleteDialog';
Vue.component('amp-delete-dialog', DeleteDialog);

Vue.use(require('vue-moment'));

export const eventBus = new Vue();
Vue.config.productionTip = false;

Vue.prototype.$isLocalEnv = process.env.VUE_APP_NODE_ENV === 'local-dev';
Vue.prototype.$isDevEnv = process.env.VUE_APP_NODE_ENV === 'develop';
Vue.prototype.$isStagingEnv = process.env.VUE_APP_NODE_ENV === 'staging';
Vue.prototype.$isProductionEnv = process.env.VUE_APP_NODE_ENV === 'production';
Vue.prototype.$appVersion = packageJson.version;

//Microsoft Clarity Script
if (process.env.VUE_APP_NODE_ENV === 'production') {
  var clarityScript = document.createElement('script');
  clarityScript.type = 'text/javascript';
  clarityScript.text =
    '(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "jcihi16p36");';
  document.head.appendChild(clarityScript);
}

//Sentry Script
if (['production', 'staging'].includes(process.env.VUE_APP_NODE_ENV)) {
  Sentry.init({
    Vue,
    dsn: 'https://8951249d1b4c4f25a225a6791c724e83@o52343.ingest.sentry.io/4504797552181248',
    environment: process.env.VUE_APP_NODE_ENV,
    release: packageJson.version,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['https://onebrand-amp-develop.web.app/', 'https://onebrand-amp-staging.web.app/', 'https://stamp.onebrand.io/', /^\//]
      }),
      new Sentry.Replay()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}
let app;
auth.onAuthStateChanged(user => {
  if (!app) {
    new Vue({
      store,
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app');
  }
});
