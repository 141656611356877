import algoliasearch from 'algoliasearch';
import { cloudFunctionsPost } from '../../modules/cloud-function-wrapper.js';

const state = {
  searchClient: algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_API_KEY)
};

const getters = {
  getSearchClient(state) {
    return state.searchClient;
  }
};

const actions = {
  getDataFromAlgolia({ getters }, payload) {
    const searchClient = getters.getSearchClient;
    // console.log('[index]:', payload.indexName, ' [Filter]:', payload.filter, ' [searchValue]:', payload.searchValue, ' [Page]:', payload.page)
    if (payload.indexName) {
      return searchClient
        .initIndex(payload.indexName)
        .search({
          query: payload.searchValue ?? '',
          filters: payload.filter ?? '',
          page: payload.page ?? 0,
          hitsPerPage: payload.hitsPerPage ?? 15
        })
        .then(content => {
          return Promise.resolve({
            hits: content.hits,
            nbHits: content.nbHits
          });
        })
        .catch(err => {
          console.log(err);
          console.log(err.debugData);
          return Promise.reject(err);
        });
    }
  },
  async getAlgoliaObjectId({ getters }, { indexName, config }) {
    const searchClient = getters.getSearchClient;
    try {
      const algoliaIndex = await searchClient.initIndex(indexName);
      const searchResp = await algoliaIndex.search(config);
      const algoliaObjectIds = searchResp?.hits?.length ? searchResp.hits.map(hit => hit.objectID) : [];
      return algoliaObjectIds.length ? algoliaObjectIds[0] : false;
    } catch (error) {
      console.warn(`Error while getAlgoliaObjectIds: ${error}`);
      throw error;
    }
  },
  deleteItemFromAlgolia({ commit }, { objectId, indexName }) {
    return cloudFunctionsPost('/deleteObjectsFromAlgoliaIndex', { objectId, indexName });
  },
  async deleteOrderFromAlgoliaIndex({ dispatch }, { indexName, orderID }) {
    try {
      const algoliaObjectId = await dispatch('getAlgoliaObjectId', {
        indexName,
        config: {
          query: orderID,
          attributesToRetrieve: ['objectID']
        }
      });
      if (algoliaObjectId) {
        return dispatch('deleteItemFromAlgolia', { objectId: algoliaObjectId, indexName });
      }
      return;
    } catch (error) {
      console.log('[ deleteOrderFromAlgoliaIndex ] ERROR:', error);
      throw error;
    }
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
