<template>
    <v-container id="loginPage">
		<v-row justify="center">
			<v-col sm="6" lg="4">
				<v-card>
					<v-card color="primary" class="loginHeader">
						<v-row class="justify-center">
							<img src="@/assets/OneBrandMT_pipe.png" height="55">
							<span class="white--text headline mt-3 ml-3 font-weight-light">STAMP</span>
						</v-row>
					</v-card>
					<v-card-title class="justify-center pt-8 mb-2">
						<span class="headline grey--text text--darken-2 font-weight-light">Reset Password</span>
					</v-card-title>
					<v-card-text>
						<v-form ref="resetPassword" v-model="valid">
                            <v-row class="justify-center">
								<v-col cols="12" md="10" xl="8">
                                    <v-alert v-if="alertMessage" :type="alertType" outlined dense dismissible class="mt-5 mb-5">{{ alertMessage }}</v-alert>
                                </v-col>
							</v-row>
							<v-row class="justify-center">
								<v-col cols="12" md="10" xl="8">
									<label>Please enter your new password</label>
									<v-text-field
										v-model="password"
										:rules="passwordRules"
                                        :append-icon="eyeIconType"
                                        :type="inputType"
										required
										outlined
										dense
                                        @click:append="toggleIcon()">
									</v-text-field>
								</v-col>
							</v-row>
							<v-row class="justify-center mt-n6 mb-5">
								<v-col cols="12" md="10" xl="8">
									<v-btn v-if="!isResetPasswordSuccessful" block large color="primary"
										class="white--text"
										:disabled="!valid"
										@click="sendResetPassword()">
										Reset Password
									</v-btn>
                                    <v-btn v-else block large color="primary"
										class="white--text"
										:disabled="!valid"
										@click="backToLogin()">
										Back to login
									</v-btn>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

export default {
    data () {
        return {
            password: '',
            passwordRules: [
				v => !!v || 'Password is required'
            ],
            valid: false,
            isPasswordShown: false,
            alertMessage: '',
            alertType: '',
            isResetPasswordSuccessful: false,
        }
    },
    computed: {
        eyeIconType() {
            return this.isPasswordShown ? 'mdi-eye' : 'mdi-eye-off';
        },
        inputType() {
            return this.isPasswordShown ? 'text' : 'password';
        }
    },
    methods: {
        sendResetPassword: async function () {
			try {
				const response = await this.$store.dispatch('users/sendResetPassword', { apiKey: this.$route.query.apiKey, oobCode: this.$route.query.oobCode, newPassword: this.password});
				this.alertMessage = response;
				this.alertType = 'success';
				this.isResetPasswordSuccessful = true;
			} catch (error) {
				this.alertMessage = error;
                this.alertType = 'error'
			}
        },
        toggleIcon: function () {
            this.isPasswordShown = !this.isPasswordShown;
        },
        backToLogin: function () {
            this.$router.push('/');
        }
    },
}
</script>

<style scoped>
</style>