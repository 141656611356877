<template>
  <v-container fluid>
    <v-card v-if="isPanelOpened" class="elevation-0 mt-n10" :loading="isOrderDataLoading">
      <template #progress>
        <v-progress-linear color="cyan darken-2" indeterminate></v-progress-linear>
      </template>
      <v-card-text>
        <v-treeview v-model="tree" :items="orderDataItems" :open="open" activatable transition item-key="name" open-on-click class="white custom-tree ml-3">
          <template slot="prepend" slot-scope="{ item, open }">
            <v-icon v-if="!item.file">{{ open ? 'mdi-minus-box-outline' : 'mdi-minus-box' }}</v-icon>
            <v-icon v-else>{{ files[item.file] }}</v-icon>
          </template>
          <template slot="label" slot-scope="{ item }">
            <a v-if="isDataContainsUrlOrId(item.name)" class="custom-a-atag" @click="openReference(item.name)">{{ item.name }}</a>
            <div v-else>{{ item.name }}</div>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ['isPanelOpened', 'firebaseOrderId'],
  data() {
    return {
      tree: [],
      open: ['assets'],
      files: {
        txt: 'mdi-chevron-right'
      },
      orderDataItems: []
    };
  },
  computed: {
    isOrderDataLoading() {
      return !this.orderDataItems.length;
    }
  },
  watch: {
    isPanelOpened: {
      handler: function (value) {
        if (value) this.listOrderData();
      },
      immediate: true
    }
  },
  methods: {
    async listOrderData() {
      if (this.firebaseOrderId) {
        this.orderDataItems = await this.$store.dispatch('customer_messaging/listOrderData', { documentId: this.firebaseOrderId }).then(res => res.tree);
      }
    },
    isDataContainsUrlOrId(name) {
      return name.includes('map_id') || name.includes('suppression_list_url') || name.includes('http');
    },
    openReference(data) {
      const splittedData = data.split(' : ');
      const refValue = splittedData[1] || '';
      if (refValue) {
        if (splittedData[0].includes('map_id')) {
          this.$router.push({ path: `/maps/view/${refValue}` });
        } else {
          var a = document.createElement('a');
          a.setAttribute('target', '_blank');
          a.href = refValue;
          a.click();
        }
      }
    }
  }
};
</script>

<style scoped>
.custom-tree .v-treeview-node__label {
  font-size: 14px !important;
}
.custom-a-atag {
  text-decoration: underline;
}
</style>
