<template>
	<v-dialog
    v-model="deleteDialog"
    persistent
    max-width="400"
    >
        <v-card>
            <v-card-title class="headline red">
                <div class="text-xs-center">
                    <span class="white--text text-md-center">Warning!</span>
                </div>
            </v-card-title>
            <v-card-text v-if="imageName">
              <p v-if="isOnlyFileInFolder" class="red--text text--darken-3 subheading font-weight-medium">Deleting the only file - {{imageName}} - , will also delete {{folder}} folder, if the folder does not have subfolders!</p>
              <p class="mt-3">Are you sure you want to delete {{text}} <b>{{imageName}}</b> from {{folder}} folder?</p>
           </v-card-text>
           <v-card-text v-else>
              <div class="text-center mt-4">
                <p class="body-1">Are you sure you want to delete this {{text}} {{imageName}}?</p>
              </div>
               <div v-if="deleteAll" class="mt-3">
                 <p class="red--text subheading bold">Warning!</p>
                 <p><b>This will delete the all the indexs related to this site from Algolia, including data!</b></p>
               </div>
           </v-card-text>
           <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="green darken-1"
                text
                @click="closeDialog"
                >
                Cancel
                </v-btn>
                <v-btn
                color="green darken-1"
                text
                @click="confirmDelete"
                >
                Ok
                 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
  export default {
    props: ['value', 'text', 'imageName', 'folder', 'isOnlyFileInFolder', 'deleteAll'],
    data () {
      return {
        deleteDialog: this.value
      }
    },
    watch: {
      value (value) {
        this.deleteDialog = this.value
      }
    },
    methods: {
      closeDialog () {
        this.$emit('closeDialog')
      },
      confirmDelete () {
        this.$emit('confirmDelete')
      }
    }
  }
</script>