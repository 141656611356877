import firebase from 'firebase/app';
import firebaseConfig from './firebaseConfig';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

/* Firestore */
export const dbFS = firebase.firestore();

/* Storage */
export const storage = app.storage();
// Create a storage reference
export const storageRef = storage.ref();
// Create a child reference
export const imagesRef = storageRef.child('images');
// Firebase timestamp
export const firestoreTimestamp = firebase.firestore.Timestamp;
// Field value
export const firestoreFieldValue = firebase.firestore.FieldValue;

async function getCurrentUserToken() {
    return auth.currentUser.getIdToken();
}

export { firebase, getCurrentUserToken };
