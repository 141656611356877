<template>
  <div>
    <p class="headline">Quote Tool</p>
    <v-dialog
      v-model="progress"
      persistent
      content-class="progress-dialog"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </v-dialog>

    <v-card class="site-selector-card">
      <v-card-title class="headline">
        <span>1. Select Site</span>
      </v-card-title>
      <v-container fluid grid-list-md>
        <oom-site-selector></oom-site-selector>
      </v-container>
    </v-card>

    <v-card v-if="isSiteSelected" class="customer-search-card">
      <v-card-title class="headline">
        <span>2. User Search</span>
      </v-card-title>
      <v-container fluid grid-list-md>
        <oom-customer></oom-customer>
      </v-container>
    </v-card>

    <v-card v-if="isQuoteSelected" class="quote-card">
      <v-card-title class="headline">
        <span>3. Quote</span>
      </v-card-title>
      <v-container fluid grid-list-md>
        <oom-quote-tool></oom-quote-tool>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  import SiteSelector from './SiteSelector.vue'
  import QuoteTool from './QuoteTool.vue'
  import Customer from './Customer.vue'

  export default {
    components: {
      'oom-site-selector': SiteSelector,
      'oom-quote-tool': QuoteTool,
      'oom-customer': Customer
    },
    data () {
      return {}
    },
    computed: {
      progress () {
        return this.$store.getters.getProgressValue
      },
      isSiteSelected () {
        return this.$store.getters.getSiteForQuoteTool !== undefined && this.$store.getters.getSiteForQuoteTool !== null
      },
      isCustomerSelected () {
        return this.$store.getters.getSelectedCustomer !== undefined && this.$store.getters.getSelectedCustomer !== null
      },
      isQuoteSelected () {
        return this.isSiteSelected && this.isCustomerSelected
      }
    }
  }
</script>

<style>
  .v-dialog.progress-dialog  {
    box-shadow: none;
    text-align: center;
    width: 100%;
    transition: none;
    overflow: hidden;
  }

  .site-selector-card, .customer-search-card, .quote-card {
    margin-bottom: 1em;
  }

  .site-selector-card .v-card__title.headline, .customer-search-card .v-card__title.headline, .quote-card .v-card__title.headline {
    border-bottom: 1px solid #eee;
  }
</style>
