<template>
    <v-layout>
      <v-card class="quote-card">
        <v-card-title class="headline">
          <v-flex xs9>{{index}}. Quote</v-flex>
          <v-btn v-if="!isQuoteCloned" color="info" @click="onQuoteClone">Clone Quote</v-btn>
        </v-card-title>
        <v-container fluid grid-list-md>
          <v-layout row wrap>
            <!-- Category/Product -->
            <v-flex xs9>
              <v-select v-model="quote.selectedCategory"
                         :items="categories"
                         item-text="name"
                         item-value="slug"
                         :disabled="categories === ''"
                         :class="{ disabled: categories === '' }"
                         label="Choose Product"
                         return-object
                         @input="onCategoryChanged">
             </v-select>
           </v-flex>
           <v-flex xs3>
             <span>Choose Product</span>
           </v-flex>
           <!-- Category/Product end -->
           <!-- Product Size -->
           <v-flex xs9>
             <v-select v-model="quote.selectedSize"
                       :items="quote.products"
                       item-text="product_name"
                       item-value="product_id"
                       label="Choose Size"
                       :disabled="isSelectedCategoryEmpty"
                       :class="{ disabled: isSelectedCategoryEmpty }"
                       return-object
                       @input="onProductChanged">
            </v-select>
          </v-flex>
          <v-flex xs3>
            <span>Choose Size</span>
          </v-flex>
          <!-- Product Size end -->
          <!-- Quantity -->
          <v-flex xs9>
            <v-text-field v-model="quote.qty" label="Quantity"></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-btn color="info"
                  :disabled="isGetPricesDisabled"
                  :class="{ disabled: isGetPricesDisabled }"
                   @click="onGetPrices">
              <v-icon>search</v-icon>Get Prices
            </v-btn>
          </v-flex>
          <!-- Quantity end -->
          <!-- Product Data -->
          <v-layout v-if="isProductDataAvailable" row wrap>
            <hr />
            <!-- Turnaround -->
            <v-flex xs8>
              <v-select v-model="quote.turnaroundOption"
                        :items="getAvailableOptions(quote.productData.turnaround)"
                        item-text="label"
                        item-value="value !== false"
                        :disabled="isSelectedCategoryEmpty || isTurnaroundEmpty"
                        :class="{ disabled: isSelectedCategoryEmpty || isTurnaroundEmpty }"
                        return-object
                        @change="test">
             </v-select>
           </v-flex>
           <v-flex xs4>
             <span>Choose from Available turnaround</span>
           </v-flex>
           <!-- Turnaround end -->
           <!-- Stock -->
           <v-flex xs8>
             <v-select v-model="quote.stockOption"
                       :items="getAvailableOptions(quote.productData.stocks)"
                       item-text="label"
                       item-value="value !== false"
                       :disabled="isSelectedCategoryEmpty || isStocksEmpty"
                       :class="{ disabled: isSelectedCategoryEmpty || isStocksEmpty }"
                       return-object
                       @change="test">
            </v-select>
          </v-flex>
          <v-flex xs4>
            <span>Choose from Available stock options</span>
          </v-flex>
          <!-- Stock end -->
          <!-- Coating -->
          <v-flex xs8>
            <v-select v-model="quote.coatingOption"
                      :items="getAvailableOptions(quote.productData.coatings)"
                      item-text="label"
                      item-value="value !== false"
                      :disabled="isSelectedCategoryEmpty || isCoatingEmpty"
                      :class="{ disabled: isSelectedCategoryEmpty || isCoatingEmpty }"
                      return-object
                      @change="test">
            </v-select>
           </v-flex>
           <v-flex xs4>
             <span>Choose from Available Coating options</span>
           </v-flex>
           <!-- Coating end -->

           <hr />

           <v-flex xs12><h3>Choose from Bindery options</h3></v-flex>
           <br/>
           <v-checkbox v-for="(item, key) in quote.productData.bindery_items" :key="key" v-model="quote.binderyOption" :label="item.label" :value="item" :checked="isIncluded(item)"></v-checkbox>

           <hr />

           <!-- Design -->
           <v-flex xs8>
             <v-select v-model="quote.designOption"
                       :items="getAvailableOptions(quote.productData.design_services)"
                       item-text="label"
                       item-value="value !== false"
                       :disabled="isSelectedCategoryEmpty || isDesignEmpty"
                       :class="{ disabled: isSelectedCategoryEmpty || isDesignEmpty }"
                       return-object
                       @change="test">
             </v-select>
            </v-flex>
            <v-flex xs4>
              <span>Choose from Available Design options</span>
            </v-flex>
            <!-- Design end -->

            <!-- Mailing Service -->
            <v-flex xs8>
              <v-select v-model="quote.mailingService"
                        :items="mailingServiceOptions"
                        item-text="label"
                        :disabled="isMailingServiceEmpty"
                        :class="{ disabled: isMailingServiceEmpty }"
                        return-object
                        @change="test">
              </v-select>
             </v-flex>
             <v-flex xs4>
               <span>Choose Mailing service option</span>
             </v-flex>
             <!-- Mailing Service end -->

             <!-- Postage -->
             <v-flex xs8>
               <v-select v-model="quote.postage"
                         :items="postageOptions"
                         item-text="label"
                         :disabled="isPostageEmpty"
                         :class="{ disabled: isPostageEmpty }"
                         return-object
                         @change="test">
               </v-select>
              </v-flex>
              <v-flex xs4>
                <span>Choose from Available Postage options</span>
              </v-flex>
              <!-- Postage end -->

              <hr />

              <!-- Zip Code -->
              <v-layout v-if="!quote.selectedProductById.product.is_edd" row wrap>
                <v-flex xs8>
                  <v-text-field v-model="quote.zipCode" label="Enter ZIP Code"></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-btn color="info" @click="onGetShippingRates">
                    <v-icon>refresh</v-icon>Get Shipping Price
                  </v-btn>
                </v-flex>
              </v-layout>
              <!-- Zip Code end -->
              <!-- Shipping Rates -->
              <v-radio-group v-if="quote.shippingRates !== ''" v-model="quote.shippingOption" row @change="test">
                <v-radio
                  v-for="(item, key) in quote.shippingRates"
                  :key="key"
                  :label="`$${item.name} - $${item.value}`"
                  :value="item"
                ></v-radio>
              </v-radio-group>
              <!-- Shipping Rates end -->

              <!-- Discount -->
              <v-flex xs8>
                <v-text-field v-model="quote.discount" label="Promo Discount"></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-btn color="info" @click="onApplyDiscount">
                  <v-icon>refresh</v-icon>Apply Discount
                </v-btn>
              </v-flex>
              <!-- Discount end -->

              <hr />

              <oom-price-table :quote="quote"></oom-price-table>
         </v-layout>

         <v-btn @click="onShowQuote">Show Quote</v-btn>
         <v-dialog
           v-model="quoteDialog"
           content-class="progress-dialog"
         >
         <v-card class="quote-container">
           <v-card-title>
             <span class="headline">Quote:</span>
           </v-card-title>

           <v-container>
             <div>{{quote}}</div>
           </v-container>

           <v-card-actions>
             <v-spacer></v-spacer>
             <v-btn color="info" @click.native="onClose">OK</v-btn>
           </v-card-actions>
         </v-card>
         </v-dialog>
         <!-- Product Data end -->
       </v-layout>
     </v-container>
   </v-card>
 </v-layout>
</template>

<script>
  import PriceTable from './PriceTable.vue'

  export default {
    components: {
      'oom-price-table': PriceTable
    },
    props: ['value', 'index', 'categories', 'isQuoteCloned'],
    data () {
      return {
        defaultQuote: this.$store.getters.getDefaultQuote,
        quoteDialog: false
      }
    },
    computed: {
      /* eslint-disable */
      quote () {
        return this.value
      },
      isSelectedCategoryEmpty () {
        return this.quote.selectedCategory === ''
      },
      isSelectedSizeEmpty () {
        return this.quote.selectedSize === ''
      },
      isQtyEmpty () {
        return this.quote.qty === '' || isNaN(this.quote.qty)
      },
      isProductDataAvailable () {
        return this.quote.productData !== '' && Object.keys(this.quote.productData).length > 0
      },
      isTurnaroundEmpty () {
        const selectedItem = this.getFirstValidItem(this.quote.productData.turnaround)
        if (selectedItem !== null) {
          this.quote.turnaroundOption = selectedItem
          return false
        }
        return true
      },
      isStocksEmpty () {
        const selectedItem = this.getFirstValidItem(this.quote.productData.stocks)

        if (selectedItem !== null) {
          this.quote.stockOption = selectedItem
          return false
        }
        return true
      },
      isCoatingEmpty () {
        const selectedItem = this.getFirstValidItem(this.quote.productData.coatings)

        if (selectedItem !== null) {
          this.quote.coatingOption = selectedItem
          return false
        }
        return true
      },
      isDesignEmpty () {
        return this.getFirstValidItem(this.quote.productData.design_services) === null
      },
      isMailingServiceEmpty () {
        if (!this.isSelectedCategoryEmpty && this.quote.productData.mailing_services !== undefined) {
          if (Object.keys(this.quote.productData.mailing_services).length === 1 && this.quote.productData.mailing_services.hasOwnProperty('tabs')) {
            return true
          } else {
            this.setSelectedMailingService(this.quote)
          }
          return false
        }
        return true
      },
      isPostageEmpty () {
        if (this.isMailingServiceEmpty || (this.quote.productData.mailing_services.standard_postage === undefined &&
          this.quote.productData.mailing_services.first_class_postage === undefined &&
          this.quote.productData.mailing_services.eddm_postage === undefined)) {
          return true
        } else {
          this.setSelectedPostage()
        }
        return false
      },
      isSaveQuoteDisabled () {
        console.log('this.quote: ', this.quote)
        return this.quote === undefined || this.quote === null || this.quote.qty === '' || parseInt(this.quote.qty) === 0
      },
      isGetPricesDisabled () {
        return this.isSelectedCategoryEmpty || this.isSelectedSizeEmpty || this.isQtyEmpty
      },
      mailingServiceOptions () {
        return this.getMailingServiceOptions()
      },
      postageOptions () {
        return this.getPostageOptions()
      }
      /* eslint-enable */
    },
    watch: {
      quote: {
        handler () {
          console.log('Quote changed: ', this.quote)
          this.$emit('input', this.quote)
        },
        deep: true
      }
    },
    methods: {
      getProductsByCategory () {
        const _self = this
        if (this.quote.selectedCategory !== '') {
          this.$store.dispatch('getProductsByCategory', this.quote.selectedCategory.term_id).then(result => {
            this.quote.products = this.convertJsonToArray(result)
            _self.$store.commit('setProgressValue', false)
          }).catch(() => {
            _self.$store.commit('setProgressValue', false)
          })
        } else {
          _self.$store.commit('setProgressValue', false)
        }
      },
      getProduct (selectedQty) {
        const _self = this
        if (this.quote.selectedSize !== '') {
          this.$store.dispatch('getProduct', this.quote.selectedSize.product_id).then(result => {
            _self.quote.selectedProductById = result

            if (selectedQty !== undefined) {
                _self.getProductDataByQuantity(_self.quote)
            }
            _self.$store.commit('setProgressValue', false)
          }).catch(() => {
            _self.$store.commit('setProgressValue', false)
          })
        }
      },
      getProductDataByQuantity () {
        const addons = this.convertJsonToArray(this.quote.selectedProductById.product_addons)

        if (parseInt(addons[0].qty) > this.quote.quantityInt) {
          this.quote.productData = addons[0]
        } if (parseInt(addons[addons.length - 1].qty) < this.quote.quantityInt) {
          this.quote.productData = addons[addons.length - 1]
        } else {
          for (let i in addons) {
            if (parseInt(addons[i].qty) === this.quote.quantityInt) {
              this.quote.productData = addons[i]
              break
            } else if (parseInt(addons[i].qty) < this.quote.quantityInt && this.quote.quantityInt < parseInt(addons[parseInt(i) + 1].qty)) {
              this.quote.productData = addons[parseInt(i) + 1]
              break
            }
          }
        }

        console.log('Product data', this.quote.productData)
      },
      onCategoryChanged () {
        this.$store.commit('setProgressValue', true)
        const selectedCategory = this.quote.selectedCategory
        this.quote = JSON.parse(JSON.stringify(this.defaultQuote))
        this.quote.selectedCategory = selectedCategory

        this.getProductsByCategory()
      },
      onProductChanged () {
        const defaultData = JSON.parse(JSON.stringify(this.defaultQuote))
        for (let i in defaultData) {
          if (i !== 'selectedCategory' && i !== 'selectedSize' && i !== 'products' && i !== 'selectedProductById') {
            this.quote[i] = defaultData[i]
          }
        }
      },
      onGetPrices () {
        this.$store.commit('setProgressValue', true)

        const quantity = this.quote.qty
        this.onProductChanged()

        this.quote.qty = quantity
        this.quote.quantityInt = quantity !== '' ? parseInt(quantity) : 0

        this.getProduct(this.quote.qty)
      },
      onGetShippingRates () {
        const _self = this
        this.$store.commit('setProgressValue', true)
        this.$store.dispatch('loadShippingRates', this.quote).then(result => {
          _self.quote.shippingRates = result
          _self.$store.commit('setProgressValue', false)
          console.log(_self.quote)
        }).catch(() => {
          _self.$store.commit('setProgressValue', false)
        })
      },
      onApplyDiscount () {
        this.quote.discountPrice = this.quote.discount !== '' ? parseInt(this.quote.discount) : 0
      },
      onQuoteClone () {
        this.$emit('onQuoteCloned')
      },
      onShowQuote () {
        console.log('Show: quote: ', this.quote)
        this.quoteDialog = true
      },
      onClose () {
        this.quoteDialog = false
      },
      convertJsonToArray (json) {
        const result = []
        for (let i in json) {
          result.push(json[i])
        }
        return result
      },
      getFirstValidItem (list) {
        let firstValidItem = null
        for (let i in list) {
          if (list[i].value !== false) {
              if (list[i].value === 0) {
                return list[i]
              } else if (firstValidItem === null) {
                firstValidItem = list[i]
              }
          }
        }
        return firstValidItem
      },
      setSelectedMailingService () {
        if (this.quote.selectedProductById.product.is_targeted) {
          this.quote.mailingService = this.quote.productData.mailing_services.targeted_mailing_service_fee
        } else if (this.quote.selectedProductById.product.is_eddm) {
          this.quote.mailingService = this.quote.productData.mailing_services.eddm_mailing_service_fee
        }
      },
      setSelectedPostage () {
        if (this.quote.selectedProductById.product.is_eddm) {
          this.quote.postage = this.quote.productData.mailing_services.eddm_postage
        } else if (this.quote.selectedProductById.product.is_targeted) {
          this.quote.postage = this.quote.productData.mailing_services.first_class_postage === 0 || this.quote.productData.mailing_services.standard_postage === undefined
                            ? this.quote.productData.mailing_services.first_class_postage : this.quote.productData.mailing_services.standard_postage
        }
      },
      getAvailableOptions (list) {
        console.log('getAvailableOptions:', list)
        const result = []

        for (let i in list) {
          if (list[i].value !== false) {
            result.push(list[i])
          }
        }
        return result
      },
      getMailingServiceOptions () {
        const result = []

        if (this.quote.selectedProductById.product !== undefined) {
          if (this.quote.selectedProductById.product.is_targeted) {
            result.push(this.quote.productData.mailing_services.targeted_mailing_service_fee)
          } else if (this.quote.selectedProductById.product.is_eddm) {
            result.push(this.quote.productData.mailing_services.eddm_mailing_service_fee)
          }
        }

        return result
      },
      getPostageOptions () {
        const result = []

        if (this.quote.selectedProductById.product !== undefined) {
          if (this.quote.selectedProductById.product.is_targeted) {
            if (this.quote.productData.mailing_services.standard_postage) {
              result.push(this.quote.productData.mailing_services.standard_postage)
            } else if (this.quote.productData.mailing_services.first_class_postage) {
              result.push(this.quote.productData.mailing_services.first_class_postage)
            }
          } else if (this.quote.selectedProductById.product.is_eddm) {
            result.push(this.quote.productData.mailing_services.eddm_postage)
          }
        }

        return result
      },
      isIncluded (item) {
        if (this.quote.binderyOption !== undefined && this.quote.binderyOption.includes(item.label)) {
          return
        }
        return item.value === 0
      },
      test () {
        console.log('TEST: ', this.quote)
      }
    }
  }
</script>

<style scoped>
  .quote-card {
    width: 98%;
  }

  hr {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }
</style>
