<template>
  <v-container>
    <v-dialog v-model="extensionAlert" max-width="350">
      <v-card>
        <v-card-title class="headline red">
          <div class="text-md-center">
            <span class="white--text">Warning</span>
          </div>
        </v-card-title>
        <v-card-text class="subheading text-md-center mt-3"> File extension must be <b>.psd</b> ! </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="extensionAlert = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="10">
        <v-btn icon class="mb-2" @click.native="back()">
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>
        <span class="headline">New Template Wizard</span>
      </v-col>
    </v-row>
    <v-layout row justify-end>
      <v-dialog v-model="isNewOrganizationDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">New Organization</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field id="companyName" v-model="companyName" label="Name" hint="Enter company name" required type="text"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field id="organization_id" v-model="organization_id" label="Organization ID" hint="Enter Organization ID" required type="text"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field id="email" v-model="email" label="Email" hint="Enter a valid email address" required type="text"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field id="phone" v-model="phone" label="Phone" hint="Enter phone" required type="text"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="close">Close</v-btn>
            <v-btn color="primary darken-1" text @click="saveCompany">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-dialog v-model="isNewCategoryDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">New Category</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field id="categoryName" v-model="categoryName" label="Name" hint="Enter category name" required type="text"> </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field id="description" v-model="description" label="Description" hint="Enter description" required type="text"> </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="closeCat">Close</v-btn>
          <v-btn color="primary darken-1" text @click="saveCategory">Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isNewFolderDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">New Folder</span>
        </v-card-title>
        <v-card-text>
          <p class="subheading red--text">Obs: Empty folder cannont be created. To create a new folder, please upload an image.</p>
          <v-row>
            <v-col cols="12">
              <v-alert v-model="alertSelectNewPath" type="error" dismissible icon="warning" outlined> Please enter folder name and select a path ! </v-alert>
            </v-col>
            <v-col cols="12">
              <p>Create new folder</p>
              <v-text-field id="folderName" v-model="folderName" label="Folder Name" hint="Enter Folder name" required type="text"> </v-text-field>
            </v-col>
            <v-col cols="12">
              <div>
                <p>In</p>
                <v-autocomplete v-model="selectedNewPath" :items="onlyPaths" label="Select Path" chips required item-value="path" item-text="path"></v-autocomplete>
              </div>
              <div>
                <file-pond
                  ref="pond"
                  name="front_path_new"
                  label-idle="Drop files here or Browse"
                  allow-multiple="false"
                  allowFileTypeValidation="false"
                  :server="{ process }"
                  v-on:addfile="handleFilePondAddFile"
                  @removefile="handleRemoveFile"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="closeNewFolder">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isImageSummary" max-width="600">
      <v-card>
        <v-card-title class="headline">Image summary</v-card-title>
        <v-card-text>
          <v-text-field v-model="fPsd" label="Front image PSD" type="text"> </v-text-field>
          <v-text-field v-model="bPsd" label="Back image PSD" type="text"> </v-text-field>
          <div class="my-4"></div>
          <v-text-field v-model="fImg" label="Front image" type="text"> </v-text-field>
          <v-text-field v-model="fTimg" label="Front Thumbanail image" type="text"> </v-text-field>
          <v-text-field v-model="bImg" label="Back image" type="text"> </v-text-field>
          <v-text-field v-model="bTimg" label="Back Thumbanail image" type="text"> </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeFinalImageSummary"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-stepper v-model="e13" vertical>
      <!-- Step 1 -->
      <v-stepper-step :complete="e13 > 1" step="1">Name and Organization ID</v-stepper-step>
      <v-stepper-content step="1">
        <v-card color="grey lighten-5" class="mb-5 px-2">
          <v-row>
            <v-col xl="6">
              <v-col cols="12">
                <v-text-field id="templateName" v-model="templateName" label="Name" hint="Enter template name" required type="text"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field id="onebrand_id" v-model="onebrand_id" label="OneBrand ID" hint="Enter OneBrand ID" required type="text"></v-text-field>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
        <v-btn color="primary" @click="e13 = 2">Continue</v-btn>
      </v-stepper-content>
      <!-- Step 2 -->
      <v-stepper-step :complete="e13 > 2" step="2"> Select Organization </v-stepper-step>
      <v-stepper-content step="2">
        <v-card color="grey lighten-5" class="mb-3 pt-3 pb-4">
          <v-container>
            <v-row>
              <v-col md="5">
                <div class="ml-2">
                  <v-autocomplete
                    v-model="selectedOrganization"
                    :items="companies"
                    label="Select Organization to continue"
                    chips
                    required
                    item-value="authToken"
                    item-text="name"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col md="2">
                <div class="ml-2 text-md-center">
                  <h2>OR</h2>
                </div>
              </v-col>
              <v-col md="5">
                <div class="ml-2">
                  <v-alert v-model="alertNewOrganization" type="success" dismissible icon="mdi-check" outlined> New organization successfully created! </v-alert>
                  <p class="subheading">Create new organization</p>
                  <v-btn outlined rounded color="primary" @click="newOrganization">New Organization</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-btn color="primary" :disabled="isSelected" @click="e13 = 3">Continue</v-btn>
        <v-btn text @click.native="e13 = 1">Back</v-btn>
      </v-stepper-content>
      <!-- Step 3 -->
      <v-stepper-step :complete="e13 > 3" step="3">Select category</v-stepper-step>
      <v-stepper-content step="3">
        <v-card color="grey lighten-5" class="mb-3 pt-3 pb-4">
          <v-container>
            <v-row>
              <v-col md="5">
                <div class="ml-2">
                  <v-autocomplete
                    v-model="selectedCategory"
                    :items="categories"
                    label="Select Category"
                    multiple
                    chips
                    required
                    item-value="name"
                    item-text="name"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col md="2">
                <div class="ml-2 text-md-center">
                  <h2>OR</h2>
                </div>
              </v-col>
              <v-col md="5">
                <div class="ml-2">
                  <v-alert v-model="alertNewCategory" type="success" dismissible icon="mdi-check" outlined> New category successfully created! </v-alert>
                  <p class="subheading">Create new Category</p>
                  <v-btn outlined rounded color="primary" @click="newCategory">New Category</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-btn color="primary" :disabled="isSelected" @click="e13 = 4">Continue</v-btn>
        <v-btn text @click.native="e13 = 2">Back</v-btn>
      </v-stepper-content>
      <!-- Step 4 -->
      <v-stepper-step :complete="e13 > 4" step="4">Front Image</v-stepper-step>
      <v-stepper-content step="4">
        <v-card color="grey lighten-5" class="mb-3 pt-3 pb-4">
          <v-container>
            <v-row>
              <v-col md="5">
                <div>
                  <v-alert v-model="alertSelectPath" type="error" dismissible icon="warning" outlined> Please select a path before upload a file! </v-alert>
                  <v-alert v-model="alertNewFolder" type="success" dismissible icon="mdi-check" outlined> Image added successfully to folder! </v-alert>
                </div>
                <div class="pb-2">
                  <p class="subheading">PSD Front Image</p>
                </div>
                <div class="ml-2">
                  Select (path to) folder where file is uploaded
                  <v-autocomplete
                    v-model="selectedPath"
                    :items="onlyPaths"
                    label="Select Path"
                    chips
                    required
                    item-value="path"
                    item-text="path"
                    :disabled="isEditing"
                  ></v-autocomplete>
                </div>
                <div v-if="isFolderCreated" class="px-3">
                  <file-pond
                    ref="pond"
                    name="front_path"
                    label-idle="Drop files here or Browse"
                    allow-multiple="false"
                    allowFileTypeValidation="false"
                    accepted-file-types=""
                    allowImageTransform="false"
                    :server="{ process }"
                    v-on:addfile="handleFilePondAddFile"
                    @removefile="handleRemoveFile"
                  />
                </div>
              </v-col>
              <v-col md="2">
                <div class="ml-2 text-md-center">
                  <h2>OR</h2>
                </div>
              </v-col>
              <v-col md="5">
                <p class="subheading">Create new folder</p>
                <v-btn outlined rounded color="primary" :disabled="isEditing" @click="newFolder">New Folder</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="ml-3">
                  <v-text-field id="front_image_psd" v-model="front_image_psd" label="Front PSD Image Path" type="text" :disabled="true"></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-5"></v-divider>
            <v-row>
              <v-col md="6">
                <v-alert v-model="isOrgNotSelected" dismissible outlined color="warning" icon="priority_high">
                  Organization was not selected! Please,go back to step no 2 and select an organization.
                </v-alert>
                <p class="subheading">Front Thumbnail</p>
                <v-row>
                  <v-col md="6">
                    <file-pond ref="pond" name="front" label-idle="Drop files here or Browse for Front Img" accepted-file-types="image/jpeg, image/png" :server="{ process }" />
                  </v-col>
                  <v-col md="6">
                    <div v-if="showTransImgTothumb" class="column-wrapper">
                      <v-row>
                        <v-col cols="12">
                          <div class="pl-2 pt-2 text-md-center">
                            <span class="text-md-center">Transform image to thumbanil.</span>
                          </div>
                          <div class="px-2">
                            <v-progress-linear :indeterminate="true"></v-progress-linear>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                    <v-alert v-model="showFinishTransImgToThumb" type="success" dismissible> Thumbnail created! </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div v-if="photoUrlFront.length != 0" class="custom-height-wizard">
                      <img class="image-custom-height-wizard" :src="photoUrlFront" alt="Avatar image" />
                    </div>
                    <div v-else>
                      <v-icon style="font-size: 60px" class="ml-3">mdi-image</v-icon>
                    </div>
                    <div>
                      <v-text-field id="front_thumbnail" v-model="front_thumbnail" label="Front full image path" hint="Front Thumbail" required type="text"></v-text-field>
                      <v-text-field
                        id="front_thumbnail_path"
                        v-model="front_thumbnail_path"
                        label="Front thumbanil image path"
                        hint="Front Thumbail"
                        required
                        type="text"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-tooltip top>
          <v-btn color="primary" :disabled="isSelectedFor" @click="e13 = 5"> Continue </v-btn>
          <span v-if="!isSelectedFor"> Click to Continue</span>
          <span v-else> Upload front PSD image to Continue</span>
        </v-tooltip>
        <v-btn text @click.native="e13 = 3">Back</v-btn>
      </v-stepper-content>
      <!-- Step 5 -->
      <v-stepper-step :rules="[() => true]" step="5" :complete="e13 > 4"> Back Images </v-stepper-step>
      <v-stepper-content step="5">
        <v-card color="grey lighten-5" class="mb-3 pb-3">
          <v-container>
            <v-row>
              <v-col md="6">
                <div>
                  <v-alert v-model="alertSelectPathBack" type="error" dismissible icon="warning" outlined> Please select a path before upload a file! </v-alert>
                  <v-alert v-model="alertNewFolderBack" type="success" dismissible icon="mdi-check" outlined> Image added successfully to folder! </v-alert>
                </div>
                <div class="pb-2">
                  <p class="subheading">PSD Back Image</p>
                </div>
                <div>
                  Select (path to) folder where file is uploaded
                  <v-autocomplete
                    v-model="selectedPathBack"
                    :items="onlyPaths"
                    label="Select Path"
                    chips
                    required
                    item-value="path"
                    item-text="path"
                    :disabled="isEditingBack"
                  ></v-autocomplete>
                  <small class="red--text"
                    >*Suggestion: Use same folder from PSD FRONT image: <b>{{ frontPSDpath }}</b></small
                  >
                </div>
                <div v-if="isFolderCreatedBack" class="mt-2">
                  <file-pond
                    ref="pond"
                    name="back_path"
                    label-idle="Drop files here or Browse"
                    allow-multiple="false"
                    allowFileTypeValidation="false"
                    accepted-file-types=""
                    allowImageTransform="false"
                    :server="{ process }"
                    v-on:addfile="handleFilePondAddFile"
                    @removefile="handleRemoveFile"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <v-text-field id="back_image_psd" v-model="back_image_psd" label="Back PSD Image Path" type="text" :disabled="true"> </v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-5"></v-divider>
            <v-row>
              <v-col md="6">
                <v-alert v-model="isOrgNotSelected" dismissible outlined color="warning" icon="priority_high">
                  Organization was not selected! Please,go back to step no 2 and select an organization.
                </v-alert>
                <p class="subheading">Back Thumbnail</p>
                <v-row>
                  <v-col md="6">
                    <file-pond
                      ref="pond"
                      name="back"
                      label-idle="Drop files here or Browse for Back images"
                      allow-multiple="true"
                      accepted-file-types="image/jpeg, image/png"
                      :server="{ process }"
                    />
                  </v-col>
                  <v-col md="6">
                    <div v-if="showTransImgTothumbBack" class="column-wrapper">
                      <v-row>
                        <v-col cols="12">
                          <div class="pl-2 pt-2 text-md-center">
                            <span class="text-md-center">Transform image to thumbanil.</span>
                          </div>
                          <div class="px-2">
                            <v-progress-linear :indeterminate="true"></v-progress-linear>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                    <v-alert v-model="showFinishTransImgToThumbBack" type="success" dismissible> Thumbnail created! </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div v-if="photoUrlBack.length != 0" class="custom-height-wizard">
                      <img class="image-custom-height-wizard" :src="photoUrlBack" alt="Avatar image" />
                    </div>
                    <div v-else>
                      <v-icon style="font-size: 60px" class="ml-3">mdi-image</v-icon>
                    </div>
                    <div>
                      <v-text-field id="back_thumbnail" v-model="back_thumbnail" label="Back full image path" hint="Back full image path" required type="text"></v-text-field>
                      <v-text-field id="back_thumbnail_path" v-model="back_thumbnail_path" label="Back thumbanil" hint="Back Thumbnail" required type="text"></v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col md="6">
                    <input id="front_thumbnail_img_name" v-model="front_thumbnail_img_name" type="hidden" value="" />
                  </v-col>
                  <v-col md="6">
                    <input id="back_thumbnail_img_name" v-model="back_thumbnail_img_name" type="hidden" value="" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-btn color="primary" @click="e13 = 6">Continue</v-btn>
        <v-btn text @click.native="e13 = 4">Back</v-btn>
      </v-stepper-content>
      <!-- Step 7 -->
      <v-stepper-step step="6" :complete="e13 > 6">Summary</v-stepper-step>
      <v-stepper-content step="6">
        <v-card color="grey lighten-5" class="mb-5 px-3">
          <v-container>
            <v-row>
              <v-col>
                <h5 class="title my-2 pb-2">General information:</h5>
                <p>
                  <span> <b>Name:</b> {{ templateName }}</span>
                </p>
                <p>
                  <span> <b>OneBrand ID:</b> {{ onebrand_id }}</span>
                </p>
                <p>
                  <span> <b>Selected Organization:</b> {{ organizationNameSelected }}</span>
                </p>
                <p>
                  <span>
                    <b>Selected Category:</b>
                    <span v-for="(item, index) in selectedCategory" :key="index">
                      {{ item }}
                    </span>
                  </span>
                </p>
                <v-divider class="my-5"></v-divider>
                <h5 class="title my-2 pb-2">Images:</h5>
                <p>
                  <span> <b>PSD front path: </b> {{ front_image_psd }}</span>
                </p>
                <p>
                  <span> <b>PSD back path: </b> {{ back_image_psd }}</span>
                </p>
                <p>
                  <span>
                    <b>Front image path: </b>
                    <a v-bind:href="front_thumbnail_path">{{ front_thumbnail_path }}</a>
                  </span>
                </p>
                <p>
                  <span>
                    <b>Front thumbnail image path: </b>
                    <a v-bind:href="front_thumbnail">{{ front_thumbnail }}</a>
                  </span>
                </p>
                <p>
                  <span>
                    <b>Back image path: </b>
                    <a v-bind:href="back_thumbnail">{{ back_thumbnail }}</a>
                  </span>
                </p>
                <p>
                  <span>
                    <b>Back thumbnail image path: </b>
                    <a v-bind:href="back_thumbnail_path">{{ back_thumbnail_path }}</a>
                  </span>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-btn color="primary" @click="finish">Save template</v-btn>
        <v-btn text @click.native="e13 = 5">back</v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

import UploadButton from 'vuetify-upload-button';

// Import Vue FilePond
// Docs: https://github.com/pqina/vue-filepond/issues/15
// setOption example: import  vueFilePond, { setOptions } from 'vue-filepond';
import vueFilePond, { setOptions } from 'vue-filepond'; // { setOptions }

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
  name: 'maps',
  components: {
    FilePond
  },
  data() {
    return {
      e1: 0,
      e13: 1,
      isSelected: true,
      isSelectedFor: true,
      selectedCategory: null,
      selectedOrganization: null,
      selectedFrontPath: null,
      selectedBackPath: null,
      isNewOrganizationDialog: false,
      companyName: '',
      organization_id: '',
      email: '',
      phone: '',
      isNewCategoryDialog: false,
      categoryName: '',
      description: '',
      templateName: '',
      onebrand_id: '',
      front_thumbnail: '',
      front_thumbnail_path: '',
      showTransImgTothumb: false,
      showFinishTransImgToThumb: false,
      photoUrlFront: '',
      photoUrlBack: '',
      back_thumbnail: '',
      back_thumbnail_path: '',
      showFinishTransImgToThumbBack: false,
      showTransImgTothumbBack: false,
      alertNewOrganization: false,
      alertNewCategory: false,
      front_thumbnail_img_name: '',
      back_thumbnail_img_name: '',
      isOrgNotSelected: false,
      organizationNameSelected: '',
      onlyPaths: [],
      selectedPath: '',
      isNewFolderDialog: false,
      folderName: '',
      isEditing: false,
      front_image_psd: '',
      isFolderCreated: true,
      isFolderCreatedBack: true,
      selectedNewPath: '',
      alertSelectPath: false,
      alertSelectPathBack: false,
      alertSelectNewPath: false,
      alertNewFolder: false,
      alertNewFolderBack: false,
      selectedPathBack: '',
      isEditingBack: false,
      back_image_psd: '',
      isSummaryData: false,
      isImageSummary: false,
      fPsd: '',
      bPsd: '',
      fImg: '',
      fTimg: '',
      bImg: '',
      bTimg: '',
      frontPSDpath: '',
      extensionCheck: false,
      extensionAlert: false,
      apiKey: process.env.VUE_APP_DESIGN_LIVE_API_KEY
    };
  },
  computed: {
    ...mapGetters('design_live', {
      companies: 'companies',
      categories: 'categories',
      // paths: 'paths', // to be commented
      logThumb: 'logThumbnails'
    }),
    companySelected() {
      return this.selectedOrganization;
    },
    frontPsdImagePath() {
      return this.front_image_psd;
    }
  },
  watch: {
    companySelected(value) {
      // console.log(value)
      if (value.length > 0) {
        this.isSelected = false;
      }
      /*
			Obs:
			1.Selected value of the organisation is his token
			2. When front image is save , organisation Id must be in the new created folder
			3. To fulfill  obs nr2, a new action is created : get the organization id by organization token
			4. This action is is async so upload file should is disabled until obs n3 has the response - postpone
			 */
      this.$store
        .dispatch('design_live/getOrganisationNameByToken', {
          token: value
        })
        .then(data => {
          if (data) {
            this.organizationIDSelected = data.organizationId;
            this.organizationNameSelected = data.organizationName;
          }
        })
        .catch(error => {
          console.log('- Error ', error);
        });
    },
    frontPsdImagePath(value) {
      if (value) {
        this.isSelectedFor = false;
        this.frontPSDpath = value.substr(0, value.lastIndexOf('/')) + '/';
      }
    },
    // logThumb is modified after data is added to logs
    logThumb(val) {
      this.showTransImgTothumb = false;
      this.showTransImgTothumbBack = false;
    },
    showTransImgTothumb(val) {
      if (this.logThumb !== null) {
        const metadata = this.logThumb.data.metadataName;
        const logKey = this.logThumb.id;
        if (!val) {
          this.$store
            .dispatch('design_live/getDownloadUrl', {
              metadataName: metadata,
              logKey: logKey
            })
            .then(dataR => {
              // console.log(dataR.downloadURL)
              if (this.front_thumbnail_created === true) {
                this.showFinishTransImgToThumb = true;
                this.front_thumbnail_path = dataR.downloadURL;
                this.front_thumbnail_created = false;
              }
            });
        }
      }
    },
    showTransImgTothumbBack(val) {
      if (this.logThumb !== null) {
        const metadata = this.logThumb.data.metadataName;
        const logKey = this.logThumb.id;
        if (!val) {
          // TODO: get url path based in metadata, delete log
          this.$store
            .dispatch('design_live/getDownloadUrl', {
              metadataName: metadata,
              logKey: logKey
            })
            .then(dataR => {
              if (this.back_thumbnail_created) {
                this.showFinishTransImgToThumbBack = true;
                this.back_thumbnail_path = dataR.downloadURL;
                this.back_thumbnail_created = false;
              }
            });
        }
      }
    }
  },
  created() {
    this.$store.dispatch('design_live/initRealtimeListenersForTemplatesThumbnail');
    this.$store.dispatch('design_live/listCompanies');
    this.$store.dispatch('design_live/listCategories');
    this.$store.dispatch('design_live/clearLogThumbnails');

    this.$store.dispatch('design_live/loadDesignLiveListToFirebase').then(response => {
      this.$store.dispatch('design_live/listDesignsFolders').then(responseList => {
        this.onlyPaths = responseList.uniqueComplete;
      });
    });
  },
  methods: {
    back() {
      this.$router.back();
    },
    cancel() {
      this.e13 = 0;
      console.log('cancel');
    },
    newOrganization() {
      this.isNewOrganizationDialog = true;
    },
    close() {
      this.isNewOrganizationDialog = false;
    },
    saveCompany() {
      const companylNameToInsert = this.companyName;
      const organizationIdToInsert = this.organization_id;
      const emailToInsert = this.email;
      const phoneToInsert = this.phone;

      this.$store
        .dispatch('design_live/saveCompany', {
          companylNameToInsert: companylNameToInsert,
          organizationIdToInsert: organizationIdToInsert,
          emailToInsert: emailToInsert,
          phoneToInsert: phoneToInsert
        })
        .then(response => {
          this.companyName = '';
          this.organization_id = '';
          this.email = '';
          this.phone = '';
          this.alertNewOrganization = true;
          this.selectedOrganization = response.token;
          this.close();
        })
        .catch(error => {
          console.log('Save new company has failed: ', error);
        });
    },
    newCategory() {
      this.isNewCategoryDialog = true;
    },
    closeCat() {
      this.isNewCategoryDialog = false;
    },
    saveCategory() {
      // selectedCategory
      const categoryNameToInsert = this.categoryName;
      const descriptionToInsert = this.description;
      this.$store
        .dispatch('design_live/saveCategory', {
          categoryName: categoryNameToInsert,
          description: descriptionToInsert
        })
        .then(() => {
          this.closeCat();
          this.categoryName = '';
          this.description = '';
          this.alertNewCategory = true;
          this.selectedCategory = categoryNameToInsert;
        })
        .catch(e => {
          console.log(e);
        });
    },
    finish() {
      const templateNameToInsert = this.templateName;
      const onebrandIdToInsert = this.onebrand_id;
      let categoryToInsert = this.selectedCategory;
      const organizationIdToInsert = this.selectedOrganization;
      const frontPathToInsert = this.front_image_psd;
      const backPathToInsert = this.back_image_psd;
      const frontThumbnailToInsert = this.front_thumbnail;
      const backThumbnailToInsert = this.back_thumbnail;
      const frontThumbnailImgNameToInsert = this.front_thumbnail_img_name;
      const backThumbnailImgNameToInsert = this.back_thumbnail_img_name;
      const frontThumbnailImgPathToInsert = this.front_thumbnail_path;
      const backThumbnailImgPathToInsert = this.back_thumbnail_path;
      const templateStatusToInsert = true;
      if (categoryToInsert === null) {
        categoryToInsert = [];
      } else {
        if (categoryToInsert.length === 0) {
          categoryToInsert = [];
        }
      }
      this.$store
        .dispatch('design_live/saveTemplate', {
          templateName: templateNameToInsert,
          onebrandId: onebrandIdToInsert,
          category: categoryToInsert,
          companyId: organizationIdToInsert,
          frontPath: frontPathToInsert,
          backPath: backPathToInsert,
          frontThumbnail: frontThumbnailToInsert,
          backThumbnail: backThumbnailToInsert,
          frontThumbnailImgName: frontThumbnailImgNameToInsert,
          backThumbnailImgName: backThumbnailImgNameToInsert,
          templateStatus: templateStatusToInsert,
          frontThumbnailImgPath: frontThumbnailImgPathToInsert,
          backThumbnailImgPath: backThumbnailImgPathToInsert
        })
        .then(() => {
          this.fPsd = frontPathToInsert;
          this.bPsd = backPathToInsert;
          this.fImg = frontThumbnailToInsert;
          this.fTimg = frontThumbnailImgPathToInsert;
          this.bImg = backThumbnailToInsert;
          this.bTimg = backThumbnailImgPathToInsert;
          this.isImageSummary = true;
        })
        .catch(error => {
          console.log('Save new template has failed: ', error);
        });
    },
    handleFilePondAddFile(err, file) {
      const fileExtension = file.fileExtension;
      if (fileExtension === 'psd') {
        // file type allowed
        this.extensionCheck = false;
      } else {
        // file type NO allowed
        this.extensionCheck = true;
        this.extensionAlert = true;
      }
    },
    handleRemoveFile(file) {
      console.log(`Remove: file has terminated`, file);
    },
    process(fieldName, file, metadata, load, error, progress, abort) {
      console.log('Start process');
      if (this.extensionCheck) {
        abort();
        this.handleRemoveFile(file);
        setOptions({ allowImagePreview: false });
      } else {
        if (fieldName === 'front_path') {
          if (this.selectedPath.length < 1) {
            this.alertSelectPath = true;
            abort();
          } else {
            this.$store
              .dispatch('design_live/updateFileOnDMM', {
                file: file,
                fieldName: fieldName,
                folder: this.selectedPath,
                apiKey: this.apiKey
              })
              .then(response => {
                load();
                this.front_image_psd = response.data;
                this.isFolderCreated = false;
                this.isEditing = true;
                this.alertNewFolder = true;
              });
          }
        } else if (fieldName === 'back_path') {
          if (this.selectedPathBack.length < 1) {
            this.alertSelectPathBack = true;
            abort();
          } else {
            this.$store
              .dispatch('design_live/updateFileOnDMM', {
                file: file,
                fieldName: fieldName,
                folder: this.selectedPathBack,
                apiKey: this.apiKey
              })
              .then(response => {
                load();
                this.back_image_psd = response.data;
                this.isFolderCreatedBack = false;
                this.isEditingBack = true;
                this.alertNewFolderBack = true;
              });
          }
        } else if (fieldName === 'front_path_new') {
          if (this.selectedNewPath.length < 1 || this.folderName.length < 1) {
            this.alertSelectNewPath = true;
            abort();
          } else {
            const folder = `${this.selectedNewPath}${this.folderName}/`;
            this.$store
              .dispatch('design_live/updateFileOnDMM', {
                file: file,
                fieldName: fieldName,
                folder: folder,
                apiKey: this.apiKey
              })
              .then(response => {
                load();
                this.closeNewFolder();
                this.front_image_psd = response.data;
                this.isFolderCreated = false;
                this.isEditing = true;
                this.alertNewFolder = true;
              });
          }
        } else {
          if (this.organizationIDSelected !== undefined) {
            this.$store
              .dispatch('design_live/addImage', {
                file: file,
                fieldName: fieldName,
                companyID: this.organizationIDSelected,
                frontThumbObj: {
                  imgName: this.front_thumbnail_img_name,
                  url: this.front_thumbnail
                },
                backThumbObj: {
                  imgName: this.back_thumbnail_img_name,
                  url: this.back_thumbnail
                }
              })
              .then(data => {
                load();
                // unblock upload
                if (data.fieldName === 'front') {
                  this.showTransImgTothumb = true;
                  this.front_thumbnail_created = true;
                  this.front_thumbnail = data.downloadURL;
                  this.photoUrlFront = data.downloadURL;
                  this.front_thumbnail_img_name = data.filename;
                } else if (data.fieldName === 'back') {
                  this.showTransImgTothumbBack = true;
                  this.back_thumbnail_created = true;
                  this.back_thumbnail = data.downloadURL;
                  this.photoUrlBack = data.downloadURL;
                  this.back_thumbnail_img_name = data.filename;
                }
              })
              .catch(error => {
                console.error('Error vue: ', error);
                abort();
              });
          } else {
            abort();
            this.isOrgNotSelected = true;
          }
        }
      }
    },
    newFolder() {
      this.isNewFolderDialog = true;
    },
    closeNewFolder() {
      this.isNewFolderDialog = false;
      this.selectedNewPath = [];
      this.folderName = '';
      this.alertSelectNewPath = false;
      this.$store.dispatch('design_live/loadDesignLiveListToFirebase').then(response => {
        this.$store.dispatch('design_live/listDesignsFolders').then(responseList => {
          this.onlyPaths = responseList.uniqueComplete;
        });
      });
    },
    closeSummary() {
      this.isSummaryData = false;
    },
    closeFinalImageSummary() {
      this.isImageSummary = false;
      this.fPsd = '';
      this.bPsd = '';
      this.fImg = '';
      this.fTimg = '';
      this.bImg = '';
      this.bTimg = '';
      this.$router.push({ path: `/` });
    }
  }
};
</script>
<style scoped>
.custom-height-wizard {
  height: 100px;
}

.image-custom-height-wizard {
  height: 100% !important;
}
</style>
