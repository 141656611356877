import { dbFS, firestoreTimestamp } from '@/services/firebaseInit';
import allLogsService from '../../services/allLogsService.js';
import moment from 'moment';

// initial state
const state = {
  mailingListLogs: []
};

// mutations
const mutations = {
  setMailingListLogs(state, payload) {
    state.mailingListLogs = payload;
  }
};
// actions
const actions = {
  async firestoreAddToLogs(_, payload) {
    try {
      console.log('payload', payload);
      await allLogsService.add({
        data: {
          action: payload.action || '',
          component: payload.component || '',
          date: payload.date || '',
          user: payload.user || ''
        }
      });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  },
  convertToCSV({ commit }, payload) {
    var array = typeof payload.objArray != 'object' ? JSON.parse(payload.objArray) : payload.objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ',';
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  },
  async generateCsvFromMailingListLogs({ commit, dispatch }, payload) {
    var itemsFormatted = [];
    payload.logs.forEach(item => {
      itemsFormatted.push({
        customer: item.customer_email,
        action: item.action,
        requestedCount: item.count || 0,
        listCount: item.listCount || 0,
        type: item.type || '',
        isBusiness: item.is_business ? 'yes' : 'no',
        customerEmail: item.customerEmail,
        customerName: item.customerName,
        customerCompany: item.customerCompany,
        status: item.status,
        date: item.timestamp,
        firebaseID: item.listFirebaseId
      });
    });
    var headers = {
      customer_email: 'User',
      action: 'Action',
      count: 'Requested Count',
      listCount: 'List Count',
      type: 'Type',
      is_business: 'isBusiness',
      customerEmail: 'Customer Email',
      customerName: 'Customer Name',
      customerCompany: 'Customer Company',
      status: 'Status',
      date: 'Date',
      listFirebaseId: 'FirebaseID'
    };
    itemsFormatted.unshift(headers);
    var jsonObject = JSON.stringify(itemsFormatted);
    var csv = await dispatch('convertToCSV', { objArray: jsonObject });
    var csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    var link = document.createElement('a');
    if (link.download !== undefined) {
      var url = URL.createObjectURL(csvBlob);
      link.setAttribute('href', url);
      link.setAttribute('download', payload.fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  async getBusinessAndConsumerListCounts({ commit }, payload) {
    const logs = payload.logs;
    let businessCounts = 0;
    let consumerCounts = 0;
    let totalCounts = 0;
    let successCounts = 0;
    let errorCounts = 0;
    let selectedLogs = [];

    const promises = logs.map(async log => {
      const querySnapshot = await dbFS.collection('mailingLists').doc(log.listFirebaseId).get();
      if (!querySnapshot.empty) {
        const listData = querySnapshot.data();
        let customerData = {
          first_name: '',
          last_name: '',
          company: ''
        };
        if (listData?.clientId) {
          const customerSnapshot = await dbFS.collection('customers').where('customer_unique_id', '==', listData.clientId).get();
          if (!customerSnapshot.empty) {
            customerData = customerSnapshot.docs[0].data();
          }
        }
        if (listData?.type === payload.type) {
          totalCounts += log.count;

          if (payload.type === 'order') {
            if (listData.hasOwnProperty('is_business') && listData.is_business) {
              businessCounts += log.count;
            } else {
              consumerCounts += log.count;
            }
          }

          if (log.status === 'success') {
            successCounts += log.count;
          } else {
            errorCounts += log.count;
          }
          selectedLogs.push({
            ...log,
            listCount: listData.listCount,
            customerName: `${customerData.first_name} ${customerData.last_name}`,
            customerCompany: customerData.company === 'null' ? '' : customerData.company,
            customerEmail: customerData.email
          });
        }
      }
    });
    await Promise.all(promises);
    return { businessCounts, consumerCounts, totalCounts, successCounts, errorCounts, logs: selectedLogs };
  },
  getAllMailingListLogs({ dispatch }, payload) {
    const monthsArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'Deccember'];
    const requestType = payload.requestType === 'generate_csv' ? 'generate_csv_requested' : payload.requestType === 'list_criteria' ? 'list_criteria_requested' : '';

    let allLogs = [];

    return dbFS
      .collection('mailingLists_logs')
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const data = doc.data();
          data.actions.forEach(action => {
            let generatedDate = null;
            if (typeof action.timestamp === 'string') {
              let actionDate = action.timestamp.split('at');
              let time = actionDate[1].replace(/\s/g, '').split(':');
              let longDate = actionDate[0].split(',');
              let monthAndDay = longDate[0].split(' ');
              generatedDate = new Date(
                Date.UTC(
                  longDate[1].replace(/\s/g, '').toString(),
                  monthsArr.indexOf(monthAndDay[0]).toString(),
                  monthAndDay[1].toString(),
                  (time[0] - 3).toString(),
                  time[1].toString(),
                  time[2].toString()
                )
              );
            } else {
              generatedDate = action.timestamp.toDate();
            }
            let unixTimestamp = generatedDate.getTime();
            if (unixTimestamp >= payload.startDate && unixTimestamp <= payload.endDate && (action.action === requestType || requestType === '')) {
              allLogs.push({ ...action, timestamp: moment(generatedDate).format('MMMM DD YYYY [at] HH:mm:ss'), listFirebaseId: data.firebase_mailing_list_id });
            }
          });
        });
        return dispatch('getBusinessAndConsumerListCounts', { logs: allLogs, type: payload.selectedType });
      });
  },
  listBackupFirebaseLogs() {
    return dbFS
      .collection('backup_logs')
      .orderBy('name', 'desc')
      .limit(365)
      .get()
      .then(querySnapshot => {
        let backupLogs = [];
        querySnapshot.forEach(doc => {
          const data = {
            date: doc.data().date.toDate(),
            name: doc.data().name,
            status: doc.data().status,
            document_id: doc.id
          };
          backupLogs.push(data);
        });
        return backupLogs;
      });
  },
  deleteFirebaseLog({ commit }, payload) {
    return dbFS
      .collection('backup_logs')
      .doc(payload.firebaseID)
      .delete()
      .then(() => {
        return { deleted: true };
      })
      .catch(e => {
        console.log(e);
        return { deleted: false };
      });
  },
  countMailingListRequests({ commit, dispatch }, payload) {
    if (payload.listLogId) {
      let actions = [];
      return dbFS
        .collection('mailingLists_logs')
        .doc(payload.listLogId)
        .get()
        .then(querySnapshot => {
          const queryData = querySnapshot.data();
          actions = queryData.actions ? queryData.actions : [];
          actions.push(payload.action);
          return actions;
        })
        .then(actions => {
          return dbFS
            .collection('mailingLists_logs')
            .doc(payload.listLogId)
            .update({ actions })
            .then(() => {
              dispatch('getListActionLogs', { listLogId: payload.listLogId });
              return Promise.resolve({ status: 'success' });
            })
            .catch(error => {
              console.log('Updating mailinglist log failed:', error);
              return Promise.reject(error);
            });
        })
        .catch(error => {
          console.log('Getting Log Data failed:', error);
          return Promise.reject(error);
        });
    } else {
      return dbFS
        .collection('mailingLists_logs')
        .add({
          actions: [{ ...payload.action }],
          site_id: payload.siteId,
          firebase_mailing_list_id: payload.firebaseID
        })
        .then(res => {
          return dbFS
            .collection('mailingLists')
            .doc(payload.firebaseID)
            .update({ listLogId: res.id })
            .then(() => {
              dispatch('getListActionLogs', { listLogId: res.id });
              return Promise.resolve({ status: 'success', listLogId: res.id });
            })
            .catch(error => {
              console.log('Updating counts failed:', error);
              return Promise.reject(error);
            });
        });
    }
  },
  getListActionLogs({ commit }, payload) {
    if (payload.listLogId) {
      return dbFS
        .collection('mailingLists_logs')
        .doc(payload.listLogId)
        .get()
        .then(querySnapshot => {
          const queryData = querySnapshot.data().actions;
          const isStringTimestampRegularExp = /^(\w+) (\d{1,2}), (\d{4}) at (.*)$/;
          const months = {
            January: 0,
            February: 1,
            March: 2,
            April: 3,
            May: 4,
            June: 5,
            July: 6,
            August: 7,
            September: 8,
            October: 9,
            November: 10,
            December: 11
          };
          const cleanedQueryData = queryData.map(singleData => {
            // check old timestamp data that are incorrectly type of string and convert them to timestamp in if
            if (typeof singleData.timestamp === 'string' && singleData.timestamp.match(isStringTimestampRegularExp)) {
              const dateString = singleData.timestamp;

              const [, month, day, year, time] = dateString.match(isStringTimestampRegularExp);
              const [hour, minute, second] = time.split(':');

              const monthIndex = months[month];
              const formattedDate = new Date(year, monthIndex, parseInt(day, 10), parseInt(hour, 10), parseInt(minute, 10), parseInt(second, 10));
              const date = new Date(formattedDate);
              singleData.timestamp = date.getTime() / 1000;
            } else {
              singleData.timestamp = singleData.timestamp.seconds;
            }
            return singleData;
          });
          if (cleanedQueryData) {
            commit('setMailingListLogs', cleanedQueryData);
            return { status: 'success' };
          }
        })
        .catch(error => {
          console.log('An error occured during fetching logs to mailing list');
          console.log('[getListActionLogs]', error);
          return { error: error };
        });
    } else {
      commit('setMailingListLogs', []);
    }
  },
  listOrderLog({ commit }, payload) {
    const firebaseId = payload.documentId;
    return dbFS
      .collection('order_logs')
      .where('firebase_order_id', '==', firebaseId)
      .get()
      .then(querySnapshot => {
        let actions = [];
        let orderLogId = '';
        querySnapshot.forEach(doc => {
          orderLogId = doc.id;
          actions = doc.data().actions;
        });
        return { actions, orderLogId };
      });
  },
  async addOrderLog({ dispatch }, payload) {
    const logData = await dispatch('listOrderLog', { documentId: payload.firebase_order_id });
    if (logData.orderLogId) {
      let actions = logData.actions;
      actions.push({
        timestamp: firestoreTimestamp.fromDate(new Date()),
        action: payload.action,
        data: payload.data || null
      });
      dbFS.collection('order_logs').doc(logData.orderLogId).update({ actions });
    }
  }
};
// getters
const getters = {
  getMailingListLogs(state) {
    return state.mailingListLogs;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
