<template>
  <div>
    <v-dialog v-if="orderData" v-model="isSubsModalOpen" persistent max-width="800px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <h3>{{ dialogTitle }} subscription</h3>
          <v-spacer></v-spacer>
          <v-btn small icon @click="closeSubscriptionsModal()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-alert v-if="subsFeedbackMsg" :type="subsFeedbackType" dismissible border="left" class="mx-4">
          {{ subsFeedbackMsg }}
        </v-alert>
        <v-card-text>
          <div v-if="isSubscriptionIdEmpty">
            <v-row v-if="selectedProduct">
              <v-col cols="12" sm="3">
                <v-row class="justify-end">
                  <h3 class="pt-5">Stripe product:</h3>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <v-row class="mt-1 ml-1">
                  <span class="subtitle-1">{{ selectedProduct.name }}</span>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col cols="12" sm="3">
                <v-row class="justify-end">
                  <h3 class="pt-5">Plans:</h3>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-model="selectedPlan" :items="plans" placeholder="Select plan" outlined dense item-text="id" item-value="id"> </v-select>
              </v-col>
            </v-row>
          </div>
          <div v-else-if="subscriptionData && !isSubscriptionIdEmpty && !isPaymentMethodsSelectorVisible">
            <ul class="stamp-od-ul mt-2">
              <li class="mb-n2">
                <v-row>
                  <v-col cols="12" sm="3"><span>Started:</span></v-col>
                  <v-col cols="12" sm="6"
                    ><span class="font-weight-medium grey--text text--darken-1">{{ formatDate(subscriptionData.created_at) }}</span></v-col
                  >
                </v-row>
              </li>
              <li class="mb-n2">
                <v-row>
                  <v-col cols="12" sm="3"><span>Firebase ID:</span></v-col>
                  <v-col cols="12" sm="6"
                    ><span class="font-weight-medium grey--text text--darken-1">{{ subscriptionData.firebase_id }}</span></v-col
                  >
                </v-row>
              </li>
              <li class="mb-n2">
                <v-row>
                  <v-col cols="12" sm="3"><span>Stripe ID:</span></v-col>
                  <v-col cols="12" sm="6"
                    ><span class="font-weight-medium grey--text text--darken-1">{{ subscriptionData.stripe_id }}</span></v-col
                  >
                </v-row>
              </li>
              <li class="mb-n2">
                <v-row>
                  <v-col cols="12" sm="3"><span>Plan Stripe ID:</span></v-col>
                  <v-col cols="12" sm="6"
                    ><span class="font-weight-medium grey--text text--darken-1">{{ subscriptionData.plan_stripe_id }}</span></v-col
                  >
                </v-row>
              </li>
              <li class="mb-n2">
                <v-row>
                  <v-col cols="12" sm="3"><span>Billing:</span></v-col>
                  <v-col cols="12" sm="6"
                    ><span class="font-weight-medium grey--text text--darken-1">{{ subscriptionData.billing }}</span></v-col
                  >
                </v-row>
              </li>
              <li class="mb-n2">
                <v-row>
                  <v-col cols="12" sm="3"><span>Status:</span></v-col>
                  <v-col cols="12" sm="6"
                    ><span class="font-weight-medium grey--text text--darken-1">{{ getSubscriptionStatus(subscriptionData.status, subscriptionData.stripe_id) }}</span></v-col
                  >
                </v-row>
              </li>
              <li class="mb-n2">
                <v-row transition="fade-transition">
                  <v-col cols="12" sm="3"><span>Payment Method:</span></v-col>
                  <v-col cols="12" sm="4"
                    ><span class="font-weight-medium grey--text text--darken-1">{{ getCardDetails(subscriptionData.payment_method_db_id) }}</span></v-col
                  >
                  <v-col cols="12" sm="5">
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-btn outlined color="cyan darken-2" class="mt-n1" small v-on="on" @click="showSelectPaymentMethods()">
                          <v-icon class="mr-2">mdi-credit-card-sync-outline</v-icon>Change Payment Method
                        </v-btn>
                      </template>
                      <span>Change default payment method</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </li>
            </ul>
          </div>
          <v-row
            v-if="isPaymentMethodsSelectorVisible"
            :class="`${isChangePaymentMethodSelected || isCardChangeNeeded ? 'mt-3 offset-sm-1' : 'mt-n7 mb-n7'}`"
            transition="slide-y-transition"
          >
            <v-col cols="12" sm="3">
              <v-row class="justify-end">
                <h3 class="pt-5">Payment method:</h3>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="selectedPaymentMethod"
                :items="paymentMethodsList"
                item-text="details"
                placeholder="Select payment method"
                outlined
                return-object
                dense
                append-outer-icon="mdi-refresh"
                @click:append-outer="newPaymentMethodAdded('')"
              >
                <template slot="item" slot-scope="data">
                  <v-icon class="mr-2" color="cyan darken-2">{{ getPaymentMethodIcon(data.item.type) }}</v-icon>
                  <span>{{ data.item.details }}</span>
                </template>
              </v-select>
              <v-btn text color="cyan darken-2" class="justify-start pl-6" @click="openEditPaymentMethodDialog('card')">
                <v-icon>mdi-credit-card-plus-outline</v-icon><span class="pl-3">Add New Card</span>
              </v-btn>
              <v-btn text color="cyan darken-2" class="justify-start pl-6" @click="openEditPaymentMethodDialog('bank_account')">
                <v-icon>mdi-bank</v-icon><span class="pl-3">Add New Bank Account</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="isChangePaymentMethodSelected" text color="grey darken-3" class="ml-3 mb-1" @click="closePaymentSelector()">Back</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="isSubscriptionIdEmpty" color="success" class="mr-4 mb-1" :disabled="!selectedPlan || !selectedPaymentMethod" @click="createSubscription()"
            >Create Subscription</v-btn
          >
          <v-btn
            v-if="isChangePaymentMethodSelected || isCardChangeNeeded"
            dark
            outlined
            color="cyan darken-2"
            class="mr-4"
            :disabled="!selectedPaymentMethod"
            @click="changeDefaultPaymentMethod()"
            >Save Changes</v-btn
          >
          <v-btn class="mr-4 mb-1" text @click="closeSubscriptionsModal()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <new-payment-method-dialog
      v-if="addNewPaymentMethodDialog"
      :value="addNewPaymentMethodDialog"
      :customerData="customerFbId"
      :fbSiteId="orderData.site_db_id"
      :cardHolderName="customer.first_name + ' ' + customer.last_name"
      :type="paymentMethodType"
      @closeNewPaymentMethodDialog="closeNewPaymentMethodDialog"
      @newPaymentMethodAdded="newPaymentMethodAdded"
    >
    </new-payment-method-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NewPaymentMethodDialog from '../../../stripe/dialogs/NewPaymentMethodDialog.vue';
const numeral = require('numeral');
export default {
  components: {
    'new-payment-method-dialog': NewPaymentMethodDialog
  },
  props: ['subsModal', 'orderData', 'customerFbId', 'subscription', 'isCardChangeNeeded'],
  data() {
    return {
      selectedPaymentMethod: null,
      plans: [],
      selectedPlan: null,
      subscriptionQuantity: 0,
      subsFeedbackType: null,
      subsFeedbackMsg: '',
      subscriptionData: null,
      products: [],
      selectedProduct: null,
      addNewPaymentMethodDialog: false,
      paymentMethodType: 'card',
      isChangePaymentMethodSelected: false
    };
  },
  computed: {
    isSubsModalOpen() {
      return this.subsModal;
    },
    subscription_id() {
      if (this.orderData) {
        return this.orderData.subscription_id;
      }
      return '';
    },
    ...mapGetters('users', {
      userStore: 'user'
    }),
    ...mapGetters('customers', {
      customer: 'getCustomer'
    }),
    ...mapGetters('payment_methods', {
      paymentMethodsList: 'getPaymentMethods'
    }),
    isSubscriptionIdEmpty() {
      return !this.orderData.hasOwnProperty('automated')
        ? !this.orderData.hasOwnProperty('subscription_id') || !this.orderData.subscription_id.length
        : !this.orderData.automated.subscription_id || !this.orderData.automated.subscription_id.length;
    },
    dialogTitle() {
      return !this.isSubscriptionIdEmpty ? 'View' : 'Create';
    },
    isPaymentMethodsSelectorVisible() {
      return this.isSubscriptionIdEmpty || this.isChangePaymentMethodSelected || this.isCardChangeNeeded;
    }
  },
  async mounted() {
    if (this.subscription) {
      this.subscriptionData = this.subscription;
    } else {
      this.getPlans();
      this.getProductByStripeID();
      this.subscriptionQuantity = this.orderData.mail_qty;
    }
    this.selectedPaymentMethod = this.paymentMethodsList.find((item) => item.id === this.subscriptionData.payment_method_db_id);
    await this.getCustomerData();
  },
  methods: {
    formatDate(dateTotransform) {
      var date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    formatNumeral(amount) {
      return numeral(amount / 100);
    },
    closeSubscriptionsModal() {
      this.selectedPaymentMethod = null;
      this.isChangePaymentMethodSelected = false;
      this.$emit('closeSubscriptionDialog');
    },
    createSubscription() {
      this.$store.dispatch('showLoader', { message: 'Creating subscription...' });
      this.$store
        .dispatch('stripe/createSubscription', {
          quantity: 0,
          plan_stripe_id: this.selectedPlan.id,
          customer_db_id: this.customerFbId,
          payment_method_db_id: this.selectedPaymentMethod.id,
          payment_type: 'stripe',
          order_db_id: this.orderData.firebase_order_id,
          crm_order_id: this.orderData.crm_order_id,
          crm_customer_id: this.orderData.crm_customer_id,
          customer_unique_id: this.orderData.customer_unique_id,
          site_db_id: this.orderData.site_db_id,
          site_id: this.orderData.site_id,
          agent_id: this.userStore.id,
          agent_name: this.userStore.name,
          agent_email: this.userStore.email
        })
        .then(result => {
          this.getSubscriptionByID(result?.data?.Data?.id ?? '');
          this.setFeedbackProps('Subscription created successfully!', 'success');
        })
        .catch(error => {
          console.log('[ createSubscription ] ERROR:', error);
          this.setFeedbackProps(error?.response?.data ?? `Unexpected error occurred while creating subscription. Error: ${error}`, 'error');
        })
        .finally(() => this.$store.dispatch('hideLoader'));
    },
    setFeedbackProps(message, type) {
      this.subsFeedbackMsg = message;
      this.subsFeedbackType = type;
    },
    getPlans() {
      this.$store.dispatch('showLoader', { message: 'Loading plans...' });
      this.subsFeedbackMsg = '';
      this.$store
        .dispatch('stripe/getStripeData', {
          endpoint: `plans/${process.env.VUE_APP_STRIPE_PRODUCT_ID}`
        })
        .then(result => {
          if (result?.data?.Success) {
            this.plans = result.data.Data.Plans;
            if (this.plans.length > 0) {
              this.selectedPlan = this.plans[0];
            }
          }
        })
        .catch(error => {
          console.log('[ getStripeData - plans ] ERROR:', error);
          this.setFeedbackProps(error?.response?.data ?? `Unexpected error occurred while getting the plans. Error: ${error}`, 'error');
        })
        .finally(() => this.$store.dispatch('hideLoader'));
    },
    getProducts() {
      this.subsFeedbackMsg = '';
      this.$store.dispatch('showLoader', { message: 'Loading products...' });
      this.plansDropdown = [];
      this.$store
        .dispatch('stripe/getStripeData', {
          endpoint: 'products'
        })
        .then(result => (this.products = result?.data?.Success ? result.data.Data.Products : []))
        .catch(error => {
          console.log('[ getStripeData - products ] ERROR:', error);
          this.setFeedbackProps(error?.response?.data ?? `Unexpected error occurred while getting the products. Error: ${error}`, 'error');
        })
        .finally(() => this.$store.dispatch('hideLoader'));
    },
    getProductByStripeID() {
      this.$store.dispatch('showLoader', { message: 'Loading product...' });
      this.subsFeedbackMsg = '';
      this.plansDropdown = [];
      this.$store
        .dispatch('stripe/getStripeData', {
          endpoint: `products/${process.env.VUE_APP_STRIPE_PRODUCT_ID}`
        })
        .then(result => (this.selectedProduct = result?.data?.Success ? result.data.Data.Product : null))
        .catch(error => {
          console.log('[ getStripeData - products/id ] ERROR:', error);
          this.setFeedbackProps(error?.response?.data ?? `Unexpected error occurred while getting the product. Error: ${error}`, 'error');
        })
        .finally(() => this.$store.dispatch('hideLoader'));
    },
    getSubscriptionByID(subscriptionId) {
      if (subscriptionId) {
        this.$store.dispatch('showLoader', { message: 'Loading subscription...' });
        this.$store
          .dispatch('stripe/getSubscriptionById', { subscriptionId })
          .then(response => {
            if (response.error) {
              this.setFeedbackProps(response.error, 'error');
            } else {
              this.subscriptionData = response;
            }
          })
          .catch(error => {
            console.log('[ getSubscriptionById ] Error occured:', error);
            this.setFeedbackProps(error, 'error');
          })
          .finally(() => this.$store.dispatch('hideLoader'));
      }
    },
    async getCustomerData() {
      this.$store.dispatch('showLoader', { message: 'Loading customer...' });
      await this.$store.dispatch('customers/loadCustomer', { customerId: this.customerFbId });
      this.$store.dispatch('hideLoader');
    },
    closeNewPaymentMethodDialog() {
      this.addNewPaymentMethodDialog = false;
    },
    newPaymentMethodAdded(message) {
      this.setFeedbackProps(message, 'success');
      this.$store.dispatch('payment_methods/getPaymentMethodsData', {
        customerId: this.customerFbId,
        showAllCards: false
      });
    },
    getCardDetails(payment_method_db_id) {
      return this.paymentMethodsList.filter(card => card.id === payment_method_db_id).length > 0
        ? this.paymentMethodsList.filter(card => card.id === payment_method_db_id)[0].details
        : '';
    },
    getPaymentMethodIcon(type) {
      return type === 'card' ? 'mdi-credit-card' : type === 'bank_account' ? 'mdi-bank' : '';
    },
    getSubscriptionStatus(status, stripe_id) {
      return status ? status : stripe_id !== '' ? 'active' : '';
    },
    openEditPaymentMethodDialog(payment_method_type) {
      this.paymentMethodType = payment_method_type;
      this.addNewPaymentMethodDialog = true;
    },
    showSelectPaymentMethods() {
      this.isChangePaymentMethodSelected = true;
    },
    closePaymentSelector() {
      this.isChangePaymentMethodSelected = false;
    },
    changeDefaultPaymentMethod() {
      this.$store.dispatch('showLoader', { message: 'Please wait ...' });
      this.$store
        .dispatch('stripe/changeSubscriptionPaymentMethod', {
          customerId: this.subscriptionData.customer,
          paymentMethodFirebaseId: this.selectedPaymentMethod.id,
          subscriptionFirebaseId: this.subscriptionData.firebase_id
        })
        .then(() => {
          this.setFeedbackProps('Payment method replaced successfully!', 'success');
          this.getSubscriptionByID(this.subscriptionData.firebase_id);
          this.$emit('closeSubscriptionDialog', { isSubscriptionChanged: true });
        })
        .catch(error => this.setFeedbackProps(error?.response?.data ?? error, 'error'))
        .finally(() => {
          this.isChangePaymentMethodSelected = false;
          this.$store.dispatch('hideLoader');
        });
    }
  }
};
</script>

<style></style>
