<template>
  <div class="text-center">
    <v-dialog v-model="isOpen" persistent width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <h3>Add payment method</h3>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-alert v-if="errorMessage" type="error">
              {{ errorMessage }}
            </v-alert>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="name_on_card" :rules="[v => !!v || 'Name on card is required']" label="Name on card" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div id="card-element"></div>
                  <div id="card-errors" class="red--text text--lighten-3"></div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="cardError !== ''" color="success" @click="createPaymentMethod()">Add</v-btn>
          <v-btn @click="isOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';

export default {
  props: ['open', 'customer'],
  data() {
    return {
      card: null,
      errorMessage: '',
      isOpen: false,
      valid: false,
      stripe: null,
      name_on_card: '',
      cardError: '',
      loading: false
    };
  },
  watch: {
    open(isOpen) {
      this.isOpen = isOpen;
      if (isOpen) {
        this.prepareStripeCard();
      }
    },
    isOpen(isOpen) {
      this.$emit('addPaymentDialogVisibilityChanged', isOpen);
    },
    errorMessage(newMessage) {
      if (newMessage) {
        setTimeout(() => {
          this.errorMessage = '';
        }, 5000);
      }
    },
    cardError(newMessage) {
      if (newMessage !== '') {
        this.loading = false;
      }
    }
  },
  mounted() {
    if (this.customer && this.customer.first_name && this.customer.last_name) {
      this.name_on_card = `${this.customer.first_name} ${this.customer.last_name}`;
    }
  },

  methods: {
    createPaymentMethod() {
      this.validateCreate();
      if (this.valid && !this.cardError) {
        this.loading = true;
        this.stripe.createToken(this.card).then(result => {
          if (result.error) {
            let errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
            this.cardError = result.error.message;
          } else {
            return this.$store
              .dispatch('payment_method/addPaymentMethod', {
                data: {
                  is_visible: 0,
                  card: result.token.card,
                  token: result.token.id,
                  id: this.customer.id,
                  name_on_card: this.name_on_card
                }
              })
              .then(result => {
                if (result?.data?.Success) {
                  this.$emit('paymentMethodAdded', {});
                } else {
                  this.errorMessage = 'Error while adding new card!';
                }
                return Promise.resolve();
              })
              .catch(error => {
                console.log('[ createPaymentMethod ] ERROR:', error);
                if (error?.response?.data) {
                  this.message = error.response.data;
                  this.errorMessage = error.response.data;
                }
                return Promise.reject(error);
              })
              .finally(() => (this.loading = false));
          }
        });
      }
    },
    async prepareStripeCard() {
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      var elements = this.stripe.elements();
      var style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };
      var card = elements.create('card', { style: style });
      card.mount('#card-element');
      card.addEventListener('change', function (event) {
        var displayError = document.getElementById('card-errors');
        if (event.error) {
          this.cardError = event.error.message;
          displayError.textContent = event.error.message;
        } else {
          this.cardError = '';
          displayError.textContent = '';
        }
      });
      this.card = card;
    },
    validateCreate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    }
  }
};
</script>
