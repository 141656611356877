import httpClient from '@/modules/httpModule';
import { defaultAvatarUrl } from '@/services/links';
import { auth, firebase, storage, storageRef, dbFS } from '../../services/firebaseInit.js';
import userLogsService from '../../services/userLogsService.js';
import userService from '../../services/userService.js';
import userGroupsService from '../../services/userGroupsService.js';
import accessLevelService from '../../services/accessLevelService.js';
import routeService from '../../services/routeService.js';
import { cloudFunctionsPost } from '../../modules/cloud-function-wrapper.js';

const state = {
  user: null,
  error: null,
  userName: '',
  accessLevel: null,
  accessLevelArray: null,
  idletime: null,
  userAccesLevelValue: null,
  loading: null,
  photo: null,
  loadingAccesLevel: false,
  routes: [],
  themeType: false,
  usersSearch: []
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setUserAccesLevelValue(state, payload) {
    state.userAccesLevelValue = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  clearError(state) {
    state.error = null;
  },
  setUserName(state, payload) {
    state.userName = payload;
  },
  setUserAccesLevel(state, payload) {
    state.accessLevel = payload;
  },
  setUserAccesLevelArray(state, payload) {
    state.accessLevelArray = payload;
  },
  setPhoto(state, payload) {
    state.photo = payload;
  },
  setLoadingAccesLevel(state, payload) {
    state.loadingAccesLevel = payload;
  },
  setIdleTime(state, payload) {
    state.idletime = payload;
  },
  setRoutes(state, payload) {
    state.routes = payload;
  },
  setThemeType(state, payload) {
    state.themeType = payload;
  },
  setUsersSearch(state, payload) {
    state.usersSearch = payload;
  }
};

const getters = {
  user(state) {
    return state.user;
  },
  userAccesLevelValue(state) {
    return state.userAccesLevelValue;
  },
  userAccessLevel(state) {
    return state.accessLevel;
  },
  userAcessLevelArray(state) {
    return state.accessLevelArray;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
  userName(state) {
    return state.userName;
  },
  photoUrl(state) {
    return state.photo;
  },
  getLoadingAccesLevel(set) {
    return state.loadingAccesLevel;
  },
  routes(state) {
    return state.routes;
  },
  getThemeType(state) {
    return state.themeType;
  },
  getUsersSearch(state) {
    return state.usersSearch;
  }
};

const actions = {
  signUserIn({ commit, dispatch }, payload) {
    commit('setLoading', true);
    return new Promise((resolve, reject) => {
      userService
        .getByProperty('email', payload.email, { isIdReturned: true, isArray: true })
        .then(snapshot => {
          if (snapshot.length > 0) {
            const userData = snapshot[0];
            const invitationObjectId = userData._id;

            if (userData.last_login === 'No login record.') {
              if (!payload.isPasswordChanged && userData.one_time_pass) {
                if (userData.one_time_pass === payload.password) {
                  resolve({ setNewPassword: true });
                } else {
                  reject('Please enter a valid password!');
                }
              } else if (payload.isPasswordChanged && userData.one_time_pass !== payload.password) {
                return dispatch('createNewUserWithEmailAndPassword', { registeredUserData: userData, currentUser: payload, invitationObjectId });
              }
            } else {
              return dispatch('signInWithExistingAccount', {
                email: payload.email,
                password: payload.password,
                name: userData.name,
                loginTime: payload.loginTime
              });
            }
          } else {
            return dispatch('signInWithExistingAccount', {
              email: payload.email,
              password: payload.password,
              loginTime: payload.loginTime
            });
          }
        })
        .catch(error => {
          console.log('[ signUserIn ] Getting saved users from database failed:', error);
          reject(error);
        })
        .finally(() => commit('setLoading', false));
    });
  },
  async sendForgotPasswordEmail(context, email) {
    try {
      await auth.sendPasswordResetEmail(email);
      return 'Password reset email sent!';
    } catch (error) {
      let errorMessage = error.code === 'auth/user-not-found' ? 'No user found for this email.' : error.code === 'auth/invalid-email' ? 'This email is incorrect.' : error.message;
      throw errorMessage;
    }
  },
  async sendResetPassword(context, { apiKey, oobCode, newPassword }) {
    try {
      await httpClient.post({
        url: `https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${apiKey}`,
        data: { oobCode, newPassword }
      });
      return 'Successfull reset password!';
    } catch (error) {
      console.log('sendResetPassword store catch', error.response.data);
      let errorMessage = 'Error in reset password!';
      switch (error.response?.data?.error?.message) {
        case 'OPERATION_NOT_ALLOWED':
          errorMessage = `${errorMessage} Password sign-in is disabled for this project.`;
          break;
        case 'EXPIRED_OOB_CODE':
          errorMessage = `${errorMessage} The action code has expired.`;
          break;
        case 'INVALID_OOB_CODE':
          errorMessage = `${errorMessage} The action code is invalid. This can happen if the code is malformed, expired, or has already been used.`;
          break;
        case 'USER_DISABLED':
          errorMessage = `${errorMessage} The user account has been disabled by an administrator.`;
          break;
      }
      throw errorMessage;
    }
  },
  updateFirebaseUserProfile({ commit }, { displayName, photoURL }) {
    return auth.currentUser
      .updateProfile({ displayName, photoURL })
      .then(() => {
        const updatedUser = auth.currentUser;
        commit('setUser', {
          id: updatedUser.uid,
          email: updatedUser.email,
          name: updatedUser.displayName,
          photo: updatedUser.photoURL
        });
        commit('setUserName', updatedUser.displayName);
        commit('setPhoto', updatedUser.photoURL);
        return Promise.resolve(updatedUser);
      })
      .catch(error => {
        console.log('[ updateFirebaseUserProfile ] ERROR:', error);
        return Promise.reject(error);
      });
  },
  async initLogin({ commit, dispatch }, payload) {
    const key = payload.key;
    userService
      .getById(key)
      .then(async user => {
        const userAccessLevel = await accessLevelService.getByIdArray(user.access_levels, { isArray: true });
        const levelValues = userAccessLevel.map(level => Number(level.value));

        commit('setUserAccesLevelArray', levelValues);
        if (userAccessLevel && userAccessLevel.length === 1) {
          console.log('[ User Access Level ]: ', userAccessLevel[0]);
          commit('setUserAccesLevel', userAccessLevel[0]);
        } else if (userAccessLevel && userAccessLevel.length > 1) {
          let max = Math.max.apply(
            Math,
            Object.values(userAccessLevel).map(function (o) {
              return o.value;
            })
          );
          const priorityAccessLevel = userAccessLevel[userAccessLevel.findIndex(item => item.value === max)];
          console.log('[ User Access Level ]: ', priorityAccessLevel);
          commit('setUserAccesLevel', priorityAccessLevel);
        }
        commit('setLoadingAccesLevel', true);
      })
      .catch(error => {
        console.log('No access level selected for this user.', error);
      });

    const routes = await routeService.getAll({ isArray: true });
    commit('setRoutes', routes);

    dispatch('loadOrderSettingsFromDb', null, { root: true });
  },
  createNewUserWithEmailAndPassword({ commit, dispatch }, { registeredUserData, currentUser, invitationObjectId }) {
    return auth
      .createUserWithEmailAndPassword(currentUser.email, currentUser.password)
      .then(async user => {
        const key = user.user.uid;
        try {
          await dispatch('updateFirebaseUserProfile', {
            displayName: registeredUserData.name,
            photoURL: defaultAvatarUrl
          });

          const firebaseUserData = Object.assign(
            {},
            {
              ...registeredUserData,
              last_login: currentUser.loginTime,
              name: registeredUserData.name,
              avatar_image_url: defaultAvatarUrl,
              first_name: '',
              last_name: '',
              is_email_password_auth: true,
              one_time_pass: ''
            }
          );

          delete firebaseUserData._id;

          await userService.set(key, firebaseUserData);

          await dispatch('initLogin', { key });

          const userLogsArray = [
            {
              action: 'First time logged in with email/password',
              time: currentUser.loginTime
            }
          ];

          await userLogsService.set(key, { logs: userLogsArray });

          await dispatch('deleteUser', { key: invitationObjectId });

          return { setNewPassword: false };
        } catch (error) {
          throw { message: error };
        } finally {
          commit('setLoading', false);
        }
      })
      .catch(error => {
        console.log('[ createUserWithEmailAndPassword ] Error:', error);
        return Promise.reject(error?.message ?? error);
      });
  },
  signInWithExistingAccount({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      auth
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(user => {
          const key = user.user.uid;
          const lastLoginTime = payload.loginTime;
          const userName = payload.name ? payload.name : user.user.email.split('@')[0];
          let promises = [];
          if (!user.user.displayName) {
            promises.push(
              dispatch('updateFirebaseUserProfile', {
                displayName: userName,
                photoURL: defaultAvatarUrl
              })
            );
          } else {
            commit('setUser', {
              id: key,
              email: user.user.email,
              name: user.user.displayName,
              photo: user.user.photoURL
            });
            commit('setUserName', user.user.displayName);
            commit('setPhoto', user.user.photoURL);
          }
          promises.push(dispatch('initLogin', { key: key }));
          promises.push(
            userService.getById(key).then(async userKey => {
              if (userKey) {
                userService
                  .update(key, {
                    last_login: lastLoginTime
                  })
                  .catch(error => {
                    console.error('signInwithExistingAccount has failed: ', error);
                  });
              } else {
                console.log('User still NOT exists');
                userService
                  .set(key, {
                    name: userName,
                    avatar_img_url: defaultAvatarUrl,
                    first_name: '',
                    last_name: '',
                    is_email_password_auth: true,
                    one_time_pass: '',
                    access_levels: '',
                    email: user.user.email,
                    last_login: lastLoginTime
                  })
                  .catch(error => {
                    console.error('signInwithExistingAccount has failed: ', error);
                  });
              }
              const userLogs = await userLogsService.getById(key).catch(error => {
                console.error('signInwithExistingAccount has failed: ', error);
              });
              const userLogsArray = Array.from(userLogs.logs);
              userLogsArray.push({
                action: `${userKey ? 'Logged in with email/password' : 'First logged in with email/password'}`,
                time: lastLoginTime
              });
              userLogsService.set(key, { logs: userLogsArray });
            })
          );
          return Promise.all(promises)
            .catch(error => {
              console.log(`[ signInWithExistingAccount ] Sign in user failed: ${error}`);
              reject(error);
            })
            .finally(() => {
              commit('setLoading', false);
              resolve();
            });
        })
        .catch(error => {
          console.log('[ signInWithExistingAccount ] Error:', error);
          reject(error && error.message ? error.message : error);
        });
    });
  },
  signUserInWithGoogle({ commit, dispatch }, payload) {
    auth.onAuthStateChanged(user => {
      if (user) {
        dispatch('extendTokenExpiry', { user });
      } else {
        const provider = new firebase.auth.GoogleAuthProvider();
        auth
          .signInWithPopup(provider)
          .then(result => {
            let user = result.user;
            const key = user.uid;
            const userName = user.displayName;
            const email = user.email;
            const photoUrl = user.photoURL;
            const lastLoginTime = payload.time;

            commit('setUser', {
              id: key,
              name: userName,
              email: email,
              photo: photoUrl
            });
            commit('setPhoto', photoUrl);
            dispatch('initLogin', { key: key });

            // search for user before insert to DB
            userService
              .getById(key)
              .then(async user => {
                userService.update(key, {
                  last_login: lastLoginTime
                });
                const userLogs = await userLogsService.getById(key);
                const userLogsArray = userLogs.logs;
                userLogsArray.push({
                  action: 'logged in with google',
                  time: lastLoginTime
                });
                userLogsService.set(key, { logs: userLogsArray });
              })
              .catch(() => {
                return new Promise((resolve, reject) => {
                  userService
                    .getByProperty('email', email, { isIdReturned: true, isArray: true })
                    .then(async users => {
                      let promises = [];
                      if (users?.length) {
                        const invitationObjectId = user._id;
                        users.forEach(userInDb => {
                          if (userInDb.email === email && userInDb.last_login === 'No login record.') {
                            promises.push(
                              userService.set(key, {
                                ...userInDb,
                                last_login: lastLoginTime,
                                name: userName,
                                avatar_image_url: photoUrl,
                                first_name: '',
                                last_name: ''
                              })
                            );
                            promises.push(dispatch('deleteUser', { key: invitationObjectId }));
                          }
                        });
                      } else {
                        console.log('User do NOT exist ... add user with default values');
                        promises.push(
                          userService.set(key, {
                            access_levels: [],
                            name: userName,
                            email: email,
                            avatar_image_url: defaultAvatarUrl,
                            first_name: '',
                            last_name: '',
                            last_login: lastLoginTime,
                            groups: []
                          })
                        );
                      }
                      const userLogs = await userLogsService.getById(key);
                      const userLogsArray = userLogs.logs;
                      userLogsArray.push({
                        action: 'First time logged in with google',
                        time: lastLoginTime
                      });
                      promises.push(userLogsService.set(key, { logs: userLogsArray }));
                      return Promise.all(promises);
                    })
                    .catch(error => {
                      console.log('An error occured during fetching users from database:', error);
                      return reject(error);
                    });
                });
              });
          })
          .catch(error => {
            console.log('[ SignIn user with popup error ]:', error);
            return Promise.reject(error);
          });
      }
    });
  },
  extendTokenExpiry({ commit, dispatch }, payload) {
    const user = payload.user;
    const refreshTimeout = 3300000; // 55m
    const sessionDuration = 1000 * 60 * 60 * 24; // 24h

    user
      .getIdTokenResult()
      .then(idTokenResult => {
        const authTime = idTokenResult.claims.auth_time * 1000; //milliseconds
        if (payload.now) {
          localStorage.removeItem('tokenExpiration');
          localStorage.setItem('tokenExpiration', payload.now + sessionDuration);
        } else {
          localStorage.setItem('tokenExpiration', authTime + sessionDuration);
        }
        /* Obs: every 55 minutes we are manually refreshing the access token,
         *  because right now google doesn't let us to set the expiry of the token.
         *  Tokens have a default 1 hour expiration time.
         */
        setInterval(() => {
          if (Date.now() < parseInt(localStorage.getItem('tokenExpiration'))) {
            user.getIdToken(true);
            console.log('[ TOKEN REFRESHED ]');
          } else {
            dispatch('logout');
          }
        }, refreshTimeout);
      })
      .catch(error => {
        console.log('An error occured:', error);
      });
  },
  fetchUser({ commit }) {
    const currentUser = auth.currentUser;
    if (currentUser) commit('setUserName', currentUser.displayName);
  },
  async fetchUserInApp({ commit, getters }) {
    try {
      const userFirebase = auth.currentUser;
      if (userFirebase) {
        const userId = userFirebase.uid;
        const userName = userFirebase.displayName;
        const email = userFirebase.email;
        const photoUrl = userFirebase.photoURL;
        let levelValues = [];
        commit('setLoadingAccesLevel', false);
        const userLoggedIn = {
          id: userId,
          name: userName,
          email: email,
          photo: photoUrl
        };
        commit('setUser', userLoggedIn);
        const user = await userService.getById(userId);
        let userAccessLevel = null;
        let userAccessLevelValue = 0;
        if (!user?.access_levels?.length) return false;
        const accessLevels = await accessLevelService.getByIdArray(user?.access_levels, { isArray: true });
        accessLevels.forEach(accessLevel => {
          if (parseInt(accessLevel.value) > parseInt(userAccessLevelValue)) {
            userAccessLevelValue = Number(accessLevel.value);
            userAccessLevel = accessLevel;
          }
          levelValues.push(Number(accessLevel.value));
        });
        commit('setUserAccesLevel', userAccessLevel);
        commit('setUserAccesLevelValue', userAccessLevelValue);
        commit('setLoadingAccesLevel', true);
        commit('setUserAccesLevelArray', levelValues);
        return true;
      }
      return false;
    } catch (error) {
      console.log('fetchUserInApp has failed:', error);
    }
  },
  logout({ commit, getters }) {
    return auth
      .signOut()
      .then(() => {
        console.log('Logout!');
        localStorage.removeItem('tokenExpiration');
        commit('setUser', { logout: true });
        return Promise.resolve();
      })
      .catch(error => {
        console.log('Logging out user failed:', error);
        return Promise.reject(error);
      });
  },
  addNewUser({ commit }, payload) {
    return userService
      .getByProperty('email', payload.email, { isIdReturned: true, isArray: true })
      .then(async allUsers => {
        const isUserExists = !!allUsers?.length;
        if (isUserExists) return Promise.reject('User with this email already exists!');
        const newUserConfig = {
          name: payload.name,
          email: payload.email,
          salesforce_user_id: payload.salesforceUserID,
          one_time_pass: payload.one_time_pass || null,
          last_login: 'No login record.'
        };

        try {
          const groupValues = await userGroupsService.getByIdArray(Object.values(payload.selectedGroups), { isArray: true, isIdReturned: true });
          newUserConfig.groups = groupValues.map(group => ({
            id: group._id,
            name: group.name
          }));
          newUserConfig.access_levels = groupValues.map(group => group.access_levels.map(accessLevel => accessLevel.id)).flat();
          const id = await userService.add({ data: newUserConfig });
          console.log('[ User added with firebaseId ]:', id);
          return id;
        } catch (error) {
          console.log('Addig new user failed:', error);
          throw error;
        }
      })
      .catch(error => {
        console.log('[ Getting Users failed ]:', error);
        throw error;
      });
  },
  async removeUserFromGroups({ commit, dispatch }, payload) {
    try {
      const promises = [];
      const user = await userService.getById(payload.userId);
      user._id = payload.userId;
      const groups = await userGroupsService.getByIdArray(payload.groups, { isArray: true, isIdReturned: true });
      groups.forEach(group => {
        promises.push(dispatch('permissions/deleteGroupFromUser', { group_data: group, user_data: user }, { root: true }));
      });
      await Promise.all(promises);
    } catch (error) {
      console.log('removeUserFromGroups has failed:', error);
      return Promise.reject(error);
    }
  },
  async addSelectedGroupToUser({ commit }, payload) {
    try {
      const user = await userService.getById(payload.userId);
      return userService
        .set(payload.userId, {
          groups: user.groups.concat({
            id: payload.id,
            name: payload.nameGroup
          })
        })
        .then(() => {
          return Promise.resolve({ status: 'success', message: 'User updated with selected group(s).' });
        })
        .catch(error => {
          console.log('Add selected group(s) to user has failed: ', error);
          return Promise.reject({ status: 'error', message: error });
        });
    } catch (error) {
      console.log('addSelectedGroupToUse has failed:', error);
      return Promise.reject({ status: 'error', message: 'Error fetching user by ID.' });
    }
  },
  async addAccesLevelToUser({ commit }, payload) {
    try {
      const { access_levels } = await userService.getById(payload.userId);
      payload.accessLevelGroup.forEach(accessLevel => {
        if (!access_levels.includes(accessLevel.id)) {
          access_levels.push(accessLevel.id);
        }
      });
      await userService.set(payload.userId, {
        access_levels
      });
    } catch (error) {
      console.log('Add access level to user has failed: ', error);
      throw error;
    }
  },
  async updateUser({ dispatch }, payload) {
    const userUpdatePromises = [];
    userUpdatePromises.push(
      userService.update(payload.id, {
        name: payload.name,
        email: payload.email,
        salesforce_user_id: payload.salesforceUserID
      })
    );

    if (payload.hasOwnProperty('isUserGroupChanged') && payload.isUserGroupChanged) {
      if (payload.removeUserFromGroups?.length) {
        userUpdatePromises.push(
          dispatch('removeUserFromGroups', { userId: payload.id, groups: payload.removeUserFromGroups })
            .then(() => Promise.resolve({ message: 'User successfully deleted from group!' }))
            .catch(error => Promise.reject({ message: 'Deleting user from group failed:' + error }))
        );
      }

      if (payload.addUserToGroups?.length) {
        const groupPromises = [];
        payload.addUserToGroups.forEach(groupValue => {
          groupPromises.push(
            userGroupsService
              .getById(groupValue)
              .then(group => ({ ...group, id: groupValue }))
              .catch(error => {
                console.error('updateUser has failed: ', error);
              })
          );
        });
        const selectedGroupValues = await Promise.all(groupPromises).then(groupResults =>
          groupResults.map(groupData => ({
            access_levels: groupData.access_levels,
            name: groupData.name,
            id: groupData.id
          }))
        );

        selectedGroupValues.forEach(groupValue => {
          if (groupValue.access_levels) {
            userUpdatePromises.push(dispatch('addSelectedGroupToUser', { id: groupValue.id, userId: payload.id, nameGroup: groupValue.name }));
            userUpdatePromises.push(
              dispatch('addAccesLevelToUser', { id: groupValue.id, accessLevelGroup: groupValue.access_levels, userId: payload.id, nameGroup: groupValue.name })
            );
          }
        });
      }
    }
    return Promise.all(userUpdatePromises)
      .then(() => Promise.resolve('User has been updated successfully!'))
      .catch(error => {
        console.log('Updating user data failed:', error);
        return Promise.reject(`Error in updating groups: ${error}`);
      });
  },
  async deleteUser({ getters, dispatch }, payload) {
    const isUserSuperAdmin = await dispatch('checkUserInGroup', {
      groupId: '-LOHzXdN1uVBhk_goldO',
      userId: payload.key
    });

    if (parseInt(getters.userAccessLevel.value) !== 100 && isUserSuperAdmin) return Promise.reject('Cannot delete this user from STAMP!');
    if (getters.user.id === payload.key) return Promise.reject('Cannot delete your own account!');

    const userKey = payload.key;

    const promises = [];
    promises.push(
      cloudFunctionsPost('/deleteUserFromFirebase', { uid: userKey })
        .then(() => {
          console.log('User deleted successfully from firebase.');
          return Promise.resolve();
        })
        .catch(error => {
          console.log('An error occured during deleting user from firebase:', error);
          return Promise.reject(error);
        })
    );

    promises.push(
      userService.delete(userKey).catch(error => {
        console.log('User delete action has failed: ', error);
        return Promise.reject();
      })
    );

    return Promise.allSettled(promises)
      .then(results => {
        console.log('[ Delete user from STAMP results]:', results);
        return Promise.resolve();
      })
      .catch(error => {
        console.log('Deleting user from STAMP failed:', error);
        return Promise.reject();
      });
  },
  async selectedGroups({ commit }, payload) {
    try {
      const { groups } = await userService.getById(payload.userKey);
      return groups.map(({ id }) => id);
    } catch (error) {
      console.log('selectedGroups has failed: ', error);
    }
  },
  saveProfileUpdatedData({ commit, dispatch }, payload) {
    const user = payload.user;
    const uid = payload.uid;
    const dateToInsert = payload.dateToInsert;
    return userService.getById(uid).then(userVal => {
      if (userVal) {
        let promises = [];
        if (!userVal.salesforceUserID || (userVal.salesforceUserID && userVal.salesforceUserID !== payload.salesforceUserID)) {
          const field = 'salesforce_user_id';
          const fieldValue = payload.salesforceUserID;
          promises.push(dispatch('updateField', { user: user, uid: uid, field: field, fieldValue: fieldValue, dateToInsert: dateToInsert }));
        }
        if (!userVal.name || (userVal.name && userVal.name !== payload.displayName)) {
          const userUpdate = true;
          const field = 'name';
          const fieldValue = payload.displayName;
          const firebaseField = 'displayName';
          promises.push(
            dispatch('updateField', {
              user: user,
              userUpdate: userUpdate,
              uid: uid,
              field: field,
              fieldValue: fieldValue,
              dateToInsert: dateToInsert,
              firebaseField: firebaseField
            })
          );
        }
        if (userVal.email !== payload.email) {
          promises
            .push(
              user.updateEmail(payload.email).then(() => {
                return userService
                  .update(uid, {
                    email: payload.email
                  })
                  .then(async () => {
                    const userLogs = await userLogsService.getById(uid);
                    const userLogsArray = userLogs.logs;
                    userLogsArray.push({
                      field: 'email',
                      value: payload.email,
                      action: 'update',
                      time: dateToInsert
                    });
                    userLogsService.set(uid, { logs: userLogsArray });
                    return Promise.resolve();
                  });
              })
            )
            .catch(error => {
              console.log('saveProfileUpdatedData has failed: ', error);
            });
        }
        return Promise.all(promises);
      }
      return Promise.reject('No user found in database.');
    });
  },
  updateField({ commit }, payload) {
    const user = payload.user;
    const userUpdate = payload.userUpdate ? payload.userUpdate : false;
    const uid = payload.uid;
    const field = payload.field;
    const fieldValue = payload.fieldValue;
    const dateToInsert = payload.dateToInsert;
    const firebaseField = payload.firebaseField ? payload.firebaseField : '';
    if (userUpdate) {
      return user
        .updateProfile({
          [firebaseField]: fieldValue
        })
        .then(() => {
          return userService
            .update(uid, {
              [field]: fieldValue
            })
            .then(async () => {
              const userLogs = await userLogsService.getById(uid);
              const userLogsArray = userLogs.logs;
              userLogsArray.push({
                field: field,
                value: fieldValue,
                action: 'update',
                time: dateToInsert
              });
              userLogsService.set(uid, { logs: userLogsArray });
              return Promise.resolve();
            });
        })
        .catch(error => {
          console.log('Error occured during updating user profile:', error);
          return Promise.reject(error);
        });
    } else {
      return userService
        .update(uid, {
          [field]: fieldValue
        })
        .then(async () => {
          const userLogs = await userLogsService.getById(uid);
          const userLogsArray = userLogs.logs;
          userLogsArray.push({
            field: field,
            value: fieldValue,
            action: 'update',
            time: dateToInsert
          });
          userLogsService.set(uid, { logs: userLogsArray });
          return Promise.resolve();
        })
        .catch(error => {
          console.log('Error occured during updating user profile:', error);
          return Promise.reject(error);
        });
    }
  },
  fetchPhoto({ commit }) {
    const user = auth.currentUser;
    if (user) {
      const photoUrl = user.photoURL;
      commit('setPhoto', photoUrl);
    }
  },
  changeUserPhoto({ commit }, payload) {
    const file = payload.file;
    let storageImgfRef = storage.ref('images/' + file.name);
    const dateImageInsert = new Date().toISOString() + ' GMT+00';

    return storageImgfRef.put(file).then(snapshot => {
      const fileNameMeta = snapshot.metadata.name;
      return storageRef
        .child('images/' + fileNameMeta)
        .getDownloadURL()
        .then(downloadURL => {
          console.log('File available at', downloadURL);
          const user = auth.currentUser;
          const uid = user.uid;
          const photoUrlToIns = downloadURL;
          return user
            .updateProfile({
              photoURL: downloadURL
            })
            .then(() => {
              commit('setPhoto', photoUrlToIns);
              return userService
                .update(uid, {
                  avatar_image_url: downloadURL
                })
                .then(async () => {
                  const userLogs = await userLogsService.getById(uid);
                  const userLogsArray = userLogs.logs;
                  userLogsArray.push({
                    field: 'photo',
                    value: downloadURL,
                    action: 'update',
                    time: dateImageInsert
                  });
                  userLogsService.set(uid, { logs: userLogsArray });
                  return downloadURL;
                });
            })
            .catch(error => {
              console.log(error);
            });
        });
    });
  },
  getUsersSearch({ commit }, payload) {
    let usersForSearch = [];
    return new Promise(resolve => {
      userService.attachRootListener(
        users => {
          if (!users?.length) {
            return;
          }
          commit('setUsersSearch', users);
          resolve(users);
        },
        {
          isArray: true,
          isIdReturned: true,
          onError: function (error) {
            console.log(error);
            resolve();
          }
        }
      );
    });
  },
  checkUserInGroup({ getters }, { groupId, userId }) {
    return userService
      .getById(userId)
      .then(user => !!user.groups.find(group => group.id === groupId))
      .catch(error => {
        console.log('checkUserInGroup has failed: ', error);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
