import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class TemplateTypeService extends FirestoreModel {
  constructor() {
    super(collections.template_types);
  }
}

export default new TemplateTypeService();
