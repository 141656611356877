import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class StampSettingsService extends FirestoreModel {
  constructor() {
    super(collections.stamp_settings);
  }
}

export default new StampSettingsService();
