<template>
  <v-container fluid>
    <search-salesforce-dialog
      v-if="isSearchSalesforceDialogOpened"
      :order="orderData"
      :open="isSearchSalesforceDialogOpened"
      :salesForceUrl="salesForceUrl"
      @visibilityChanged="salesforceSearchDialogVisibilityChanged"
    >
    </search-salesforce-dialog>

    <v-card v-if="isPanelOpened" class="elevation-0 mt-n10">
      <v-card-text class="mb-n3 mt-n5">
        <v-row class="mt-n6">
          <v-col cols="12" md="6">
            <v-row v-for="(data, index) in leftColumn" :key="index" class="stamp-od-ul mb-n7">
              <v-col cols="5" sm="6" md="4">
                <span class="font-weight-medium grey--text text--darken-1">{{ data.attribute_name }}</span>
              </v-col>
              <v-col cols="7" sm="6" md="8">
                <span v-if="data.value" class="black--text">{{ data.value }}</span>
                <span v-else>-</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row v-for="(data, index) in rightColumn" :key="index" class="stamp-od-ul mb-n7">
              <v-col cols="5" sm="6" md="4">
                <span class="font-weight-medium grey--text text--darken-1">{{ data.attribute_name }}</span>
              </v-col>
              <v-col cols="7" sm="6" md="8">
                <template v-if="data.value">
                  <span class="black--text">{{ data.value }}</span>
                  <button v-if="data.attribute_name === 'Special Instruction' && isSpecialInstructionDialogEnabled" @click="openSpecialInstructionDialog">Show more</button>
                </template>
                <span v-else>-</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-divider class="mt-8"></v-divider>

        <v-row class="mt-0">
          <v-col cols="2" sm="2">
            <span class="font-weight-medium grey--text text--darken-1">Customer Phone</span>
          </v-col>
          <v-col cols="8" sm="4">
            <v-checkbox
              v-model="isSmsSelected"
              label="Receive helpful text notifications regarding the order"
              :false-value="false"
              :true-value="true"
              class="mt-n1 mb-1"
              @change="setSmsAllowedState"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              id="customer_phone"
              v-model="customerPhone"
              :disabled="!isSmsSelected"
              :rules="phoneInputRules"
              prefix="+1"
              hint="Enter customer phone"
              type="number"
              class="phone-input"
              append-outer-icon="mdi-check"
              @click:append-outer="saveCustomerPhone"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="phoneLoadingMsg || isPhoneResultVisible || isPhoneLoadingVisible">
          <v-col :class="{ 'phone-loading': isPhoneLoadingVisible }">
            <v-progress-circular v-if="isPhoneLoadingVisible" indeterminate color="cyan darken-1" size="30"></v-progress-circular>
            <v-alert v-if="isSavingPhoneSucceeded" v-model="isPhoneResultVisible" type="success" dismissible>{{ phoneLoadingMsg }}</v-alert>
            <v-alert v-else v-model="isPhoneResultVisible" type="error" dismissible>{{ phoneLoadingMsg }}</v-alert>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row class="mt-0">
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <span class="font-weight-medium grey--text text--darken-1">Order upload url</span>
              </v-col>
              <v-col v-if="isProofDataLoading">
                <v-progress-circular indeterminate color="cyan darken-1" size="30"></v-progress-circular>
              </v-col>
              <v-col v-else cols="12" sm="6" md="8">
                <span v-if="httpimg_upload_url" class="black--text">
                  <a :href="httpimg_upload_url" target="_blank">Open Upload URL</a>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon class="ml-2" small v-on="on" @click="copyUrlToClipboard(httpimg_upload_url)">mdi-content-copy</v-icon>
                    </template>
                    <span>Copy Upload URL to clipboard</span>
                  </v-tooltip>
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <span class="font-weight-medium grey--text text--darken-1">Order proof url</span>
              </v-col>
              <v-col v-if="isProofDataLoading">
                <v-progress-circular indeterminate color="cyan darken-1" size="30"></v-progress-circular>
              </v-col>
              <v-col v-else cols="12" sm="6" md="8">
                <span v-if="httpimg_proof_url" class="black--text">
                  <a :href="httpimg_proof_url" target="_blank">Open Proof URL</a>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon class="ml-2" small v-on="on" @click="copyUrlToClipboard(httpimg_proof_url)">mdi-content-copy</v-icon>
                    </template>
                    <span>Copy Proof URL to clipboard</span>
                  </v-tooltip>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-4 mb-n2 ml-0">
          <v-col cols="12">
            <v-row>
              <v-btn v-if="!isSalesforceOpportunityIdAvailable" dark color="cyan darken-1" small @click="openSearchSalesforceDialog()">Create Opportunity in Salesforce</v-btn>
              <v-btn v-else :href="salesForceRef" target="_blank" dark color="cyan darken-1" small>Open Opportunity in Salesforce</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SearchSalesforceDialog from '../orders/orderDialogs/SearchSalesForce/SearchSalesforce';
import sanitizeHtml from 'sanitize-html';

export default {
  components: { 'search-salesforce-dialog': SearchSalesforceDialog },
  props: ['isPanelOpened', 'orderData'],
  data() {
    return {
      leftColumn: [],
      rightColumn: [],
      isProofDataLoading: false,
      httpimg_upload_url: '',
      httpimg_proof_url: '',
      isSearchSalesforceDialogOpened: false,
      salesForceUrl: 'https://onebrand.my.salesforce.com',
      phoneInputRules: [value => value.length === 10 || 'Phone number is not valid.'],
      customerPhone: '',
      phoneLoadingMsg: '',
      isSavingPhoneSucceeded: true,
      isSmsSelected: false,
      isPhoneResultVisible: false,
      isPhoneLoadingVisible: false,
      isSpecialInstructionDialogEnabled: false
    };
  },
  computed: {
    references() {
      return this.orderData?.references || {};
    },
    isSalesforceOpportunityIdAvailable() {
      return this.references?.hasOwnProperty('salesforce_opportunity_id') && this.references.salesforce_opportunity_id !== '';
    },
    salesForceRef() {
      return `${this.salesForceUrl}/${this.references.salesforce_opportunity_id}`;
    },
    isCustomerPhoneValid() {
      return this.customerPhone.length === 10;
    }
  },
  watch: {
    orderData(value) {
      if (value) {
        this.isSmsSelected = this.orderData.is_sms_allowed ?? false;
        this.customerPhone = this.orderData.customer_phone || '';
        this.leftColumn = this.orderDetailsColumnLeft(this.orderData);
        this.rightColumn = this.orderDetailsColumnRight(this.orderData);
        this.getHttpImgUrl();
      }
    }
  },
  methods: {
    orderDetailsColumnLeft(order) {
      const orderStatus = order.hasOwnProperty('order_status') && Object.keys(order.order_status).length && order.order_status.status ? order.order_status.status : '';

      const summaryArray = [
        {
          attribute_name: 'Order Status',
          value: orderStatus
        },
        {
          attribute_name: 'Is Test',
          value: order.hasOwnProperty('is_test') ? order.is_test : ''
        },
        {
          attribute_name: 'Site ID',
          value: order.site_id || ''
        },
        {
          attribute_name: 'Site DB id',
          value: order.site_db_id || ''
        },
        {
          attribute_name: 'Firebase Order ID',
          value: order.firebase_order_id || ''
        },
        {
          attribute_name: 'Database Path',
          value: order.database_path || ''
        },
        {
          attribute_name: 'Extra Quantity',
          value: order.extra_qty || ''
        },
        {
          attribute_name: 'Customer unique ID',
          value: order.customer_unique_id || ''
        }
      ];

      let userDefineFields = [];
      if (order.user_defined_fields && Object.keys(order.user_defined_fields).length >= 1) {
        Object.entries(order.user_defined_fields).forEach(([key, valueEPC]) => {
          if (valueEPC.label) {
            userDefineFields.push({
              attribute_name: 'Epicore with label ' + valueEPC.label,
              value: valueEPC.value
            });
          }
        });
      }
      return summaryArray.concat(userDefineFields);
    },
    orderDetailsColumnRight(order) {
      const summaryArray = [
        {
          attribute_name: 'CRM Customer ID',
          value: order.crm_customer_id || ''
        },
        {
          attribute_name: 'Mailing List Service',
          value: order.mailing_service || ''
        },
        {
          attribute_name: 'Print Quantity',
          value: order.print_qty || ''
        },
        {
          attribute_name: 'Monthly Quantity',
          value: order.monthly_qty || '-'
        },
        {
          attribute_name: 'Product Name',
          value: order.product_name || ''
        },
        {
          attribute_name: 'Order Email',
          value: order.order_email || ''
        },
        {
          attribute_name: 'Label',
          value: order.label || ''
        },
        {
          attribute_name: 'Special Instruction',
          value: this.getSpecialInstructionReadableForm(order.special_instructions || '')
        }
      ];
      return summaryArray;
    },
    getHttpImgUrl() {
      if (this.orderData?.firebase_order_id && !this.httpimg_upload_url) {
        this.isProofDataLoading = true;
        this.$store
          .dispatch('om_orders/getProofUploadUrl', {
            firebase_order_id: this.orderData.firebase_order_id,
            osprey_order_id: this.orderData.crm_order_id,
            order_id: this.orderData.firebase_order_id,
            site_db_id: this.orderData.site_db_id
          })
          .then(result => {
            this.httpimg_upload_url = result?.data?.httpimg_upload_url || '';
            this.httpimg_proof_url = result?.data?.httpimg_proof_url || '';
          })
          .catch(error => {
            this.$emit('updateFeedback', {
              type: 'error',
              message: error?.response?.data || 'Unexpected error occurred while getting proof upload url!'
            });
          })
          .finally(() => (this.isProofDataLoading = false));
      }
    },
    openSearchSalesforceDialog() {
      this.isSearchSalesforceDialogOpened = true;
    },
    openSpecialInstructionDialog() {
      this.$emit('openSpecialInstructionDialog');
    },
    salesforceSearchDialogVisibilityChanged(isOpen) {
      this.isSearchSalesforceDialogOpened = isOpen;
    },
    async saveCustomerPhone() {
      console.log('saveCustomerPhone: ', this.customerPhone);
      if (!this.isPhoneNumberValid(this.customerPhone)) return;
      this.resetAlert();
      if (this.isCustomerPhoneValid) {
        const user = this.$store.getters['users/user'];

        try {
          const result = await this.$store.dispatch('om_orders/saveCustomerPhone', {
            orderId: this.orderData.firebase_order_id,
            customerPhone: this.customerPhone,
            previousPhone: this.orderData.customer_phone || '',
            user: {
              id: user.id || '',
              email: user.email || '',
              name: user.name || ''
            }
          });

          if (!result) {
            throw new Error('No result from saveCustomerPhone');
          }

          this.isSavingPhoneSucceeded = true;
          this.phoneLoadingMsg = 'Customer Phone is saved successfully.';
        } catch (error) {
          this.isSavingPhoneSucceeded = false;
          this.phoneLoadingMsg = `Saving Customer Phone has failed: ${error?.response?.data?.message || error}`;
        } finally {
          this.isPhoneResultVisible = true;
          this.isPhoneLoadingVisible = false;
        }
      }
    },
    isPhoneNumberValid(phone) {
      return !isNaN(phone) && phone?.length === 10;
    },
    async setSmsAllowedState() {
      console.log('setSmsAllowedState: ', this.isSmsSelected);

      this.resetAlert();
      await this.$store
        .dispatch('om_orders/saveSmsAllowedState', {
          order_id: this.orderData.firebase_order_id,
          is_sms_allowed: this.isSmsSelected
        })
        .then(result => {
          this.isSavingPhoneSucceeded = true;
          this.phoneLoadingMsg = 'SMS Allowed State is saved successfully.';
        })
        .catch(error => {
          this.isSavingPhoneSucceeded = false;
          this.phoneLoadingMsg = `Saving SMS Allowed State has failed: ${error?.response?.data?.message || error}`;
        })
        .finally(() => {
          this.isPhoneResultVisible = true;
          this.isPhoneLoadingVisible = false;
        });
    },
    resetAlert() {
      this.phoneLoadingMsg = '';
      this.isPhoneResultVisible = false;
      this.isPhoneLoadingVisible = true;
      this.isSavingPhoneSucceeded = true;
    },
    copyUrlToClipboard(url) {
      const el = document.createElement('textarea');
      el.value = url;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    getSpecialInstructionReadableForm(specialInstructions) {
      const editedInstructions = sanitizeHtml(specialInstructions, {
        allowedTags: [],
        allowedAttributes: {}
      });
      if (editedInstructions.length > 50) {
        this.isSpecialInstructionDialogEnabled = true;
        return editedInstructions.substring(0, 50) + '...';
      }
      return editedInstructions;
    }
  }
};
</script>

<style>
.phone-input {
  padding-top: 0px;
}

.phone-input .v-text-field__prefix {
  font-weight: 600;
}

.phone-loading {
  text-align: center;
  padding-bottom: 2em;
}
</style>
