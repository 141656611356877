import { dbFS } from '@/services/firebaseInit';
import siteSettingsService from '../../services/siteSettingsService';

// initial state
const state = {
  maintenanceState: null,
  crmCustomerIdListItems: [],
  fourOverPaymentToken: ''
};
// mutations
const mutations = {
  setFourOverPaymentToken(state, payload) {
    state.fourOverPaymentToken = payload;
  },
  setMaintenanceState(state, payload) {
    state.maintenanceState = payload;
  },
  fetchCrmCustomerIds(state, payload) {
    state.crmCustomerIdListItems = payload;
  },
  addCrmCustomerIdToList(state, payload) {
    state.crmCustomerIdListItems.push({ ...payload });
  },
  deleteCrmCustomerIdFromList(state, payload) {
    const crmCustomerIdList = state.crmCustomerIdListItems;
    crmCustomerIdList.forEach((value, index) => {
      if (value.id === payload) {
        state.crmCustomerIdListItems.splice(index, 1);
      }
    });
  },
  updateCrmCustomerIdItem(state, payload) {
    const crmCustomerIdList = state.crmCustomerIdListItems;
    crmCustomerIdList.forEach((value, index) => {
      if (value.id === payload.id) {
        state.crmCustomerIdListItems[index].customerId = payload.customerId;
      }
    });
  }
};
// getters
const getters = {
  getFourOverPaymentToken(state) {
    return state.fourOverPaymentToken;
  },
  getMaintenanceState(state) {
    return state.maintenanceState;
  },
  getCrmCustomerIdListItems(state) {
    return state.crmCustomerIdListItems;
  }
};
// actions
const actions = {
  initFourOverPaymentToken({ commit }) {
    try {
      return new Promise((resolve, reject) => {
        const document = dbFS.collection('stamp_settings').doc('fourover_credit_card');
        document.onSnapshot(tokenSnapshot => {
          if (tokenSnapshot.exists) {
            const profileToken = tokenSnapshot.data().profile_token;
            commit('setFourOverPaymentToken', profileToken);
            resolve(profileToken);
          } else {
            console.error('Document with ID "fourover_credit_card" does not exist');
            commit('setFourOverPaymentToken', '');
            reject(new Error('Document with ID "fourover_credit_card" does not exist'));
          }
        });
      });
    } catch (error) {
      console.error('Error fetching Payment Token:', error);
      throw error;
    }
  },

  changeMaintenanceMode({ commit, dispatch, getters }, payload) {
    if (payload.mValue !== undefined) {
      return dbFS
        .collection('maintenanceStatus')
        .doc('all')
        .update({
          allSites: payload.mValue
        })
        .then(() => {
          return siteSettingsService
            .getAll({ isArray: true })
            .then(sites => {
              const promises = [];
              sites.forEach(site => {
                const prms = siteSettingsService.update(site.firebase_id, { is_maintenance: payload.mValue });
                promises.push(prms);
              });
              return Promise.all(promises)
                .then(() => {
                  return { status: 'done' };
                })
                .catch(error => {
                  console.log('- Error on Promise all - ', error);
                  return { status: 'error' };
                });
            })
            .catch(error => {
              console.log('- Error on gettig data from Firebase RD - ', error);
              return { status: 'error' };
            });
        })
        .catch(error => {
          console.log('- Error on update state to Firestore - ', error);
          return { status: 'error' };
        });
    }
  },
  maintenanceState({ commit, dispatch, getters }, payload) {
    let allState = null;
    return dbFS
      .collection('maintenanceStatus')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc.id === 'all') {
            allState = doc.data();
          }
        });
        commit('setMaintenanceState', allState);
      });
  },
  fetchCrmCustomerIdList({ commit, getters }, payload) {
    let crmCustomerIdTemp = [];
    return dbFS
      .collection('sonicprintDmmCustomers')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          crmCustomerIdTemp.push({
            ...doc.data(),
            id: doc.id
          });
        });
        commit('fetchCrmCustomerIds', crmCustomerIdTemp);
      });
  },
  addNewCrmCustomerIdToList({ commit, getters }, payload) {
    return dbFS
      .collection('sonicprintDmmCustomers')
      .add({
        creationDate: payload.creationDate,
        customerId: payload.customerId,
        addedBy: payload.addedBy
      })
      .then(function (docRef) {
        commit('addCrmCustomerIdToList', {
          creationDate: payload.creationDate,
          customerId: payload.customerId,
          addedBy: payload.addedBy,
          id: docRef.id
        });
        return { status: 'success' };
      })
      .catch(error => {
        console.log('Adding new CRM Customer ID to list FAILED!', error);
        return { status: 'error' };
      });
  },
  deleteCrmCustomerIdFromList({ commit }, payload) {
    return dbFS
      .collection('sonicprintDmmCustomers')
      .doc(payload)
      .delete()
      .then(() => {
        commit('deleteCrmCustomerIdFromList', payload);
        return { status: 'success' };
      })
      .catch(error => {
        console.error('Error removing document: ', error);
        return { status: 'error' };
      });
  },
  editCrmCustomerId({ commit }, payload) {
    return dbFS
      .collection('sonicprintDmmCustomers')
      .doc(payload.id)
      .update({
        ...payload
      })
      .then(() => {
        commit('updateCrmCustomerIdItem', payload);
        return { status: 'success' };
      })
      .catch(error => {
        console.error('Error in editing document: ', error);
        return { status: 'error' };
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
