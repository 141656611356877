<template>
  <div class="text-center">
    <v-dialog v-model="isDialogOpen" persistent width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <h3>Usage record history</h3>
          <v-spacer></v-spacer>
          <v-btn small icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-alert v-if="feedbackMessage" :type="feedbackMessageType" dismissible border="left" class="mx-4">
          {{ feedbackMessage }}
        </v-alert>
        <v-card-text>
          <template v-if="isDevelopmentEnv">
            <v-row>
              <v-col cols="12" sm="3">
                <v-row class="justify-end">
                  <h3 class="pt-8">Usage Record Action:</h3>
                </v-row>
              </v-col>
              <v-col cols="12" sm="3">
                <v-radio-group v-model="selectedUsageRecordAction">
                  <v-radio label="Increment" value="increment"></v-radio>
                  <v-radio label="Set" value="set"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col cols="12" sm="3" class="pt-6">
                <v-row class="justify-end">
                  <h3 class="pt-5">{{ isTimestampTextFieldVisible ? 'Timestamp' : 'Date' }}:</h3>
                </v-row>
              </v-col>
              <v-col cols="4" :sm="`${isTimestampTextFieldVisible ? '5' : '3'}`" class="pt-6">
                <v-text-field v-if="isTimestampTextFieldVisible" v-model.number="usageRecordTimestamp" outlined dense type="number"></v-text-field>
                <v-menu v-else v-model="dateMenu" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-text-field :value="formattedDate" outlined dense readonly v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="usageRecord.date" @change="dateMenu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col v-if="!isTimestampTextFieldVisible" cols="4" sm="2">
                <input id="appt" v-model="usageRecord.time" class="timepicker" type="time" name="appt" required />
              </v-col>
            </v-row>
            <v-row class="mt-n5">
              <v-col cols="12" sm="3">
                <v-row class="justify-end">
                  <h3 class="pt-5">Quantity:</h3>
                </v-row>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field v-model="usageRecord.quantity" outlined dense type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-2"></v-divider>
          </template>
          <template v-if="invoiceLineItem">
            <v-row class="mt-2">
              <v-col cols="12" sm="3" md="3" lg="3">
                <v-row class="justify-end">
                  <h3 class="pt-4">Total quantity:</h3>
                </v-row>
              </v-col>
              <v-col cols="12" sm="5" md="5" lg="5">
                <span class="subtitle-1 font-weight-medium grey--text text--darken-1 pt-0">{{ invoiceLineItem.quantity }}</span>
              </v-col>
            </v-row>
            <v-row v-if="invoiceLineItem">
              <v-col cols="12" sm="3" md="3" lg="3">
                <v-row class="justify-end">
                  <h3 class="pt-5">Period:</h3>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" class="mt-1">
                <span v-if="isInvoicePeriodAvailable" class="subtitle-1 font-weight-medium grey--text text--darken-1"
                  >{{ formatDate(invoiceLineItem.period.start) }} - {{ formatDate(invoiceLineItem.period.end) }}</span
                >
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="isDevelopmentEnv" color="success" class="mr-4 mb-1" :disabled="isCreateUsageRecordDisabled" @click="createUsageRecord()">Create Usage Record</v-btn>
          <v-btn class="mr-4 mb-1" @click="closeDialog()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['isOpen', 'order'],
  data() {
    return {
      usageRecord: { date: new Date().toISOString().substr(0, 10), time: this.$moment().format('HH:mm'), quantity: 0, action: 'increment' },
      dateMenu: false,
      subscription: null,
      feedbackMessage: '',
      feedbackMessageType: '',
      fields: [
        { text: 'Subscription item', align: 'start', value: 'subscription_item', sortable: false },
        { text: 'Total usage', align: 'start', value: 'total_usage', sortable: false }
      ],
      invoiceLineItem: null,
      selectedUsageRecordAction: 'increment',
      isTimestampTextFieldVisible: false,
      usageRecordTimestamp: 0
    };
  },
  computed: {
    isDevelopmentEnv() {
      return !this.$isProductionEnv;
    },
    isDialogOpen() {
      return this.isOpen;
    },
    formattedDate() {
      return this.$moment(this.usageRecord.date).format('L');
    },
    subscriptionId() {
      if (this.order) {
        return !this.order.hasOwnProperty('automated') ? this.order.subscription_id || '' : this.order.automated.subscription_id || '';
      }
      return '';
    },
    isInvoicePeriodAvailable() {
      return this.invoiceLineItem?.period?.start && this.invoiceLineItem?.period?.end;
    },
    isCreateUsageRecordDisabled() {
      return this.usageRecord?.quantity < 0 || !this.subscription;
    }
  },
  watch: {
    selectedUsageRecordAction(value) {
      this.isTimestampTextFieldVisible = value === 'set';
    }
  },
  mounted() {
    this.getSubscriptionByID();
  },
  methods: {
    closeDialog() {
      this.$emit('closeUsageRecordDialog');
    },
    createUsageRecord() {
      this.feedbackMessage = '';
      const timestamp = +new Date(`${this.usageRecord.date} ${this.usageRecord.time}`) / 1000;
      this.$store.dispatch('showLoader', { message: 'Saving...' });
      this.$store
        .dispatch('stripe/createUsageRecord', {
          id: this.subscription.subscription_stripe_items[0].id,
          quantity: this.usageRecord.quantity,
          timestamp: this.isTimestampTextFieldVisible ? this.usageRecordTimestamp : timestamp,
          action: this.selectedUsageRecordAction
        })
        .then(usageRecordResult => {
          console.log('[ USAGE RECORD RESULT ]:', usageRecordResult);
          this.usageRecord.quantity = 0;
          this.getInvoiceLineItemList();
          this.setFeedbackProps('Usage record created successfully', 'success');
          return;
        })
        .catch(error => {
          console.log(' [ createUsageRecord ] ERROR:', error);
          if (error?.response?.data) this.setFeedbackProps(error.response.data, 'error');
          throw error;
        })
        .finally(() => this.$store.dispatch('hideLoader'));
    },
    getSubscriptionByID() {
      this.feedbackMessage = '';
      if (this.subscriptionId) {
        this.$store.dispatch('showLoader', { message: 'Loading subscription...' });
        this.$store
          .dispatch('stripe/getSubscriptionById', { subscriptionId: this.subscriptionId })
          .then(response => {
            if (response.error) {
              this.setFeedbackProps(response.error, 'error');
            } else {
              this.subscription = response;
              this.getInvoiceLineItemList();
              this.getUsageRecordList();
            }
          })
          .catch(error => {
            console.log('Error occured:', error);
          })
          .finally(() => {
            this.$store.dispatch('hideLoader');
          });
      }
    },
    getUsageRecordList() {
      this.$store.dispatch('showLoader', { message: 'Loading usage record summary...' });
      this.feedbackMessage = '';

      this.$store
        .dispatch('stripe/getUsageRecordList', {
          id: this.subscription.subscription_stripe_items[0].id
        })
        .then(result => {
          if (result?.data?.Data?.UsageRecords) {
            this.usagerecords = result.data.Data.UsageRecords;
          }
          return Promise.resolve();
        })
        .catch(error => {
          console.log('[ getUsageRecordList ] ERROR: ', error);
          if (error?.response?.data) {
            this.setFeedbackProps(error.response.data, 'error');
          }
          return Promise.reject(error);
        })
        .finally(() => this.$store.dispatch('hideLoader'));
    },
    getInvoiceLineItemList() {
      this.$store.dispatch('showLoader', { message: 'Loading usage record summary...' });
      this.feedbackMessage = '';
      this.$store
        .dispatch('stripe/getInvoiceLineItemList', {
          subscription: this.subscription.stripe_id
        })
        .then(result => {
          console.log('invoice line items: ', result);
          if (result?.data?.Data?.LineItems?.length > 0) {
            this.invoiceLineItem = result.data.Data.LineItems[0];
          }
          return;
        })
        .catch(error => {
          console.log('[ getInvoiceLineItemList ] ERROR:', error);
          if (error?.response?.data) {
            this.setFeedbackProps(error.response.data, 'error');
          }
          throw error;
        })
        .finally(() => this.$store.dispatch('hideLoader'));
    },

    setFeedbackProps(message, type) {
      this.feedbackMessage = message;
      this.feedbackMessageType = type;
    },
    formatDate(dateTotransform) {
      var date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY');
    }
  }
};
</script>

<style scoped>
.timepicker {
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}
</style>
