<template>
	<v-dialog v-model="updateOrderProcessSwDialog" max-width="800" persistent>
		<v-card>
			<v-card-title class="headline red">
				<div class="text-xs-center">
					<span class="white--text text-md-center">Update Order Process Switches</span>
				</div>
			</v-card-title>
			<v-card-text class="py-3">
				<v-alert v-model="emptyAttrName" type="warning" dismissible outlined>
					Site id cannot be empty!
				</v-alert>
				<v-layout align-center justify-center row>
					<div class="stamp-dsa-wrapper mt-4">
						<v-text-field v-model="RLsiteID" label="Enter site ID" type="text"></v-text-field>
						<v-btn color="primary" class="mt-4" @click="changeSwitchObject()">
							<span>change switch object </span>
						</v-btn>
					</div>
				</v-layout>
				<v-row align="center" justify="start">
					<v-col cols="12" sm="10">
						<v-alert v-model="siteUpdateSuccesfully" type="success" dismissible outlined>
							Site Order Process -> Switches updated succefully!
						</v-alert>
						<v-alert v-model="siteUpdateError" type="success" dismissible outlined>
							Error on update Site Order Process -> Switches !
						</v-alert>
						<div class="mt-4">
							<h3>Corespondent process -> switch object after modification:</h3>
							<vue-json-pretty :data="updatedSwitchesObject" :show-double-quotes="true" :show-length="true" :show-line="true">
							</vue-json-pretty>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="green darken-1" text @click="closeOrderProcessSwDialog">
					Cancel
				</v-btn>
			</v-card-actions>
			<v-progress-linear v-if="loading" :indeterminate="loading" class="my-0"></v-progress-linear>
		</v-card>
	</v-dialog>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import orderSettingsService from '../../../services/orderSettingsService.js'

export default {
	components: {
		VueJsonPretty
	},
	props: ['value'],
	data() {
		return {
			updateOrderProcessSwDialog: this.value,
			loading: false,
			RLsiteID: '',
			emptyAttrName: false,
			processes: [
				'availability',
				'custom',
				'eddm',
				'eddmPrintOnly',
				'newMovers',
				'printprepship',
				'saturation',
				'targeted',
				'template'
			],
			siteUpdateSuccesfully: false,
			siteUpdateError: false,
			updatedSwitchesObject: {}
		}
	},
	computed: {},
	watch: {
		value(value) {
			this.updateOrderProcessSwDialog = this.value
		}
	},
	created() {

	},
	methods: {
		closeOrderProcessSwDialog() {
			this.$emit('closeOrderProcessSwDialog')
		},
		changeSwitchObject() {
			// -Lu7t90MWKHODwcW_pxW
			console.log('enter changeSwitchObject')
			if (this.RLsiteID.length > 0) {
				// let testArray = []
				let testObject = {}
				// const prms = db.ref('orderSettings/' + this.RLsiteID + '/orderProcesses/' + value + '/orderProcessDetails' )

				const promises = []
				// let switchObject = {}
				let orderSwitches = {}
				this.processes.forEach((value, index) => {
					const prms = orderSettingsService.getById(this.RLsiteID)
						.then((orderSetting) => {
							console.log(orderSetting)
							const orderSwitcheArray = orderSetting?.orderProcesses?.[value]?.orderProcessDetails?.orderSwitches;
							let switchObject = {}
							let i = 1
							Object.entries(orderSwitcheArray).forEach(([key, value]) => {
								switchObject = {
									field_description: '',
									field_label: value.label,
									field_name: '',
									field_type: 'switch',
									field_id: 'order_switch_' + i,
									value: value.value
								}
								orderSwitches[key] = switchObject
								// console.log('switchObject:', switchObject)
								// console.log('---', orderSwitches)
								switchObject = {}
								i++
							})
							// console.log('switchObject:', switchObject)
							console.log('---')
							console.log('---', orderSwitches)
							// testArray.push({
							//     [value]: orderSwitches
							// })
							testObject[value] = orderSwitches
							orderSwitches = {}
							i = 0
						})
						.catch((err) => {
							console.log('error:', err)
							return Promise.reject(err)
						})
					promises.push(prms)
				})

				Promise.all(promises).then(() => {
					console.log('All sites parsed!')

					console.log('testObject', testObject)
					// -Lu7t90MWKHODwcW_pxW
					const promises = []
					Object.entries(testObject).forEach(([key, value]) => {
						// console.log(key)
						// console.log(value)

						const prms = orderSettingsService.update(this.RLsiteID, {
								[`orderProcesses.${key}.orderProcessDetails.orderSwitches`]: value
							})
							.catch(error => {
								console.error(error)
								return Promise.reject(error)
							})
						promises.push(prms)
					})
					Promise.all(promises).then(() => {
						console.log('All processes updated!')
						this.updatedSwitchesObject = testObject
						this.siteUpdateSuccesfully = true
					})
					.catch(error => {
						this.siteUpdateError = true
						console.error(error.message)
					})
				})
				.catch(error => {
					this.siteUpdateError = true
					console.error(error)
				})

			} else {
				this.emptyAttrName = true
			}
		}
	}
}
</script>
<style scoped>
.stamp-dsa-wrapper {
	width: 100%;
}
</style>