import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';
import { cloudFunctionsGet } from '../modules/cloud-function-wrapper.js';

class DropTrackerService extends FirestoreModel {
  constructor() {
    super(collections.dropStatusTracker);
  }

  async getAutomatedDropStatuses() {
    const orderDrops = await cloudFunctionsGet('/dropStatusTracker');
    console.log('orderDrops :>> ', orderDrops);
    return orderDrops.data;
  }
}

export default new DropTrackerService();
