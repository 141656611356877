<template>
	<v-dialog
    v-model="addNewAttributesDialog"
    max-width="500"
    >
        <v-card>
            <v-card-title class="headline red">
                <div class="text-xs-center">
                    <span class="white--text text-md-center">Add new attribute</span>
                </div>
            </v-card-title>
            <v-card-text>
                <v-alert
                   v-model="emptyAttrName"
                   type="warning"
                   dismissible
                   outlined>
                        Attribute name cannot be empty!
                </v-alert>
                <v-text-field
                    v-model="attrName"
                    label="Attribute name"
                    type="text"
                    >
                </v-text-field>
                <p>Choose Value or Boolean</p>
                <v-switch v-model="chooseStateType"
                    color="cyan darken-2"
                    :label="`State: ${activeText}`"
                    class="pt-2"
                 ></v-switch>
                 <div v-if="chooseStateType">
                    <v-radio-group v-model="valueType" :mandatory="false">
                      <v-radio label="Number" value="number"></v-radio>
                      <v-radio label="String" value="string"></v-radio>
                    </v-radio-group>
                     <v-text-field
                         v-model="attrValue"
                         label="Attribute Value"
                         type="text"
                         >
                     </v-text-field>
                 </div>
                 <div v-else>
                     <v-switch v-model="booleanValue"
                         color="cyan darken-2"
                         :label="`Value: ${booleanValue}`"
                         class="pt-2"
                      ></v-switch>
                 </div>
           </v-card-text>
           <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="green darken-1"
                text
                elevation=1
                @click="closeAddNewAttributesDialog"
                >
                Cancel
                </v-btn>
                <v-btn
                color="green darken-1"
                text
                elevation=1
                @click="addAttribute"
                >
                Add attribute
                 </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
  export default {
    props: ['value'],
    data () {
      return {
        addNewAttributesDialog: this.value,
        attrName: '',
        attrValue: '',
        booleanValue: false,
        chooseStateType: true,
        emptyAttrName: false,
        valueType: 'number'
      }
    },
    computed: {
        activeText () {
            if (this.chooseStateType === true) {
                return 'Value'
            } else {
                return 'Boolean'
            }
        }
    },
    watch: {
      value (value) {
        this.addNewAttributesDialog = this.value
      }
    },
    methods: {
      closeAddNewAttributesDialog () {
        this.attrName = ''
        this.attrValue = ''
        this.$emit('closeAddNewAttributesDialog')
      },
      addAttribute () {
        const attrName = this.attrName
        let attrValue = this.attrValue
        const booleanValue = this.booleanValue
        const chooseStateType = this.chooseStateType
        let attrObj = {}
        if (chooseStateType) {
            console.log(this.valueType)
            if (this.valueType === 'number') {
              attrValue = parseInt(attrValue)
            }
            attrObj = {
                attrName,
                attrValue
            }
        } else {
            attrObj = {
                attrName,
                attrValue: booleanValue
            }
        }
        if (attrName.length < 1) {
            this.emptyAttrName = true
        } else {
            this.$emit('addAttribute', attrObj)
        }
      }
    }
  }
</script>