<template>
  <v-container>
    <v-row class="mt-1">
      <v-col cols="6">
        <span class="headline pt-1 mb-3">Mailing List Reports</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col v-if="actionLogs.length" cols="2" class="text-end">
        <v-btn class="caption text-end" text outlined small @click="resetDates()">Reset dates</v-btn>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>

    <v-row class="justify-center mx-0">
      <v-card class="custom-radius mt-5" width="100%">
        <v-card-text>
          <v-row class="mb-n4 justify-space-around">
            <v-col cols="12" md="4" xl="3">
              <v-row class="mr-4">
                <v-menu v-model="isMonthMenuOpened" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectedMonth"
                      label="Select Month"
                      outlined
                      dense
                      v-bind="attrs"
                      :append-outer-icon="selectedMonth ? 'mdi-close' : ''"
                      class="mt-2"
                      v-on="on"
                      @click:append-outer="clearMonth()"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="selectedMonth" type="month" no-title scrollable @input="isMonthMenuOpened = false"> </v-date-picker>
                </v-menu>
              </v-row>
              <v-row class="pl-1 mr-5 my-2">
                <v-divider />
                <p class="mt-n3 mx-3 text-center blue-grey--text text--darken-3">OR</p>
                <v-divider />
              </v-row>
              <v-row class="mr-4">
                <v-menu v-model="startDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      label="Choose Start Date"
                      prepend-icon="mdi-calendar-arrow-right"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startDate" @input="startDateMenu = false"></v-date-picker>
                </v-menu>
              </v-row>
              <v-row class="mr-4">
                <v-menu v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="endDate" label="Choose End Date" prepend-icon="mdi-calendar-arrow-left" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="endDate" @input="endDateMenu = false"></v-date-picker>
                </v-menu>
              </v-row>
            </v-col>
            <v-col cols="12" md="3">
              <v-radio-group v-model="selectedRequestType">
                <v-row class="mb-3 mt-n3">
                  <v-radio label="All" value="all" color="blue"></v-radio>
                </v-row>
                <v-row class="">
                  <v-radio label="Generate CSV Requests" value="generate_csv" color="blue"></v-radio>
                </v-row>
                <v-row class="mt-3">
                  <v-radio label="List Criteria Requests" value="list_criteria" color="blue"></v-radio>
                </v-row>
              </v-radio-group>
              <v-radio-group v-model="selectedType">
                <v-row class="mb-3 mt-n3">
                  <v-radio label="Order" value="order" color="blue"></v-radio>
                </v-row>
                <v-row class="">
                  <v-radio label="Political" value="political" color="blue"></v-radio>
                </v-row>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="3" class="d-flex flex-column align-content-center">
              <v-row class="justify-center align-end">
                <v-btn class="subtitle-2 blue-grey--text text--darken-3 mb-2" block :disabled="!isDatesValid" @click="getReport()">
                  <v-icon class="mr-2">mdi-table-arrow-down</v-icon>View Report Results
                </v-btn>
              </v-row>
              <v-row class="mt-4 justify-center align-start">
                <v-btn class="subtitle-2 blue white--text" block dark :disabled="!isDatesValid" @click="downloadReport()">
                  <v-icon class="mr-2">mdi-file-download-outline</v-icon>Download Report CSV
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="justify-center mx-0 mt-6 mb-0">
      <v-card width="100%">
        <v-row v-if="totalCounts" class="ml-1 pt-3 pb-2">
          <v-col cols="2">
            <span class="grey--text text--darken-2">Totals:</span>
            <span class="black--text ml-1">{{ totalCounts }}</span>
          </v-col>
          <v-col cols="3" class="ml-n4 text-center">
            <span class="grey--text text--darken-2">Consumer Counts:</span>
            <span class="black--text ml-1">{{ consumerCounts }}</span>
          </v-col>
          <v-col cols="3">
            <span class="grey--text text--darken-2">Business Counts:</span>
            <span class="black--text ml-1">{{ businessCounts }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="grey--text text--darken-2">Success:</span>
            <span class="black--text ml-1">{{ successCounts }}</span>
          </v-col>
          <v-col cols="2">
            <span class="grey--text text--darken-2">Error:</span>
            <span class="black--text ml-1">{{ errorCounts }}</span>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-row v-if="actionLogs.length" class="mt-1">
      <v-col cols="12">
        <stamp-data-table :headers="logHeaders" :data="actionLogs" :is-loading="logsLoading" item-key="listFirebaseId-timestamp" sort-by="timestamp" :sort-desc="true">
          <template #[`item.listFirebaseId`]="{ item }">
            <a :href="`/list/view/${item.listFirebaseId}`" target="_blank" class="blue--text text--darken-2">{{ item.listFirebaseId }}</a>
          </template>
          <template #[`item.is_business`]="{ item }">
            <span>{{ item.is_business ? 'Yes' : 'No' }}</span>
          </template>
        </stamp-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: { 'stamp-data-table': DataTable },
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
      startDate: '',
      endDate: '',
      logHeaders: [
        { text: 'User', align: 'left', value: 'customer_email' },
        { text: 'Action', align: 'left', value: 'action' },
        { text: 'Requested Count', align: 'left', value: 'count' },
        { text: 'List Count', align: 'left', value: 'listCount' },
        { text: 'Type', align: 'left', value: 'type' },
        { text: 'isBusiness', align: 'center', value: 'is_business' },
        { text: 'Customer Email', align: 'left', value: 'customerEmail' },
        { text: 'Customer Name', align: 'left', value: 'customerName' },
        { text: 'Customer Company', align: 'left', value: 'customerCompany' },
        { text: 'Date', align: 'center', value: 'timestamp' },
        { text: 'Status', align: 'left', value: 'status' },
        { text: 'List Firebase ID', align: 'center', value: 'listFirebaseId', sortable: false }
      ],
      logsLoading: false,
      actionLogs: [],
      isMonthMenuOpened: false,
      selectedMonth: '',
      fileNameDates: {
        startDate: '',
        endDate: ''
      },
      selectedRequestType: 'all',
      selectedType: 'order',
      totalCounts: 0,
      businessCounts: 0,
      consumerCounts: 0,
      successCounts: 0,
      errorCounts: 0
    };
  },
  computed: {
    isDatesValid() {
      if (Number(new Date(this.endDate)) < Number(new Date(this.startDate))) {
        this.setFeedback('error', 'End date cannot be less than Start date! Please choose another End date!');
        return false;
      }
      return true;
    }
  },
  watch: {
    selectedMonth(oldValue, newValue) {
      if (this.selectedMonth === '' || oldValue !== newValue) {
        this.actionLogs = [];
        this.totalCounts = 0;
        this.businessCounts = 0;
      }
    }
  },
  created() {
    this.startDate = this.$moment().startOf('month').format('YYYY-MM-DD');
    this.endDate = this.$moment().format('YYYY-MM-DD');
  },
  methods: {
    async getReport() {
      this.logsLoading = true;
      try {
        this.resetCounts();
        const { startDateParam, endDateParam } = this.getDateIntervals();
        console.log(`[StartDate]: ${startDateParam} --- [EndDate]: ${endDateParam}`);
        const result = await this.$store.dispatch('logs/getAllMailingListLogs', {
          startDate: startDateParam,
          endDate: endDateParam,
          requestType: this.selectedRequestType,
          selectedType: this.selectedType
        });
        if (!result?.logs?.length) {
          this.setFeedback('info', 'No logged events found for this period!');
          this.actionLogs = [];
          return;
        }
        this.actionLogs = result.logs;
        this.totalCounts = result.totalCounts;
        this.businessCounts = result.businessCounts;
        this.consumerCounts = result.consumerCounts;
        this.successCounts = result.successCounts;
        this.errorCounts = result.errorCounts;
      } catch (error) {
        this.setFeedback('error', 'Something went wrong while fetching mailing list logs.');
        console.error('[getReport error]: ', error);
      } finally {
        this.logsLoading = false;
      }
    },

    async downloadReport() {
      this.$store.dispatch('showLoader', { message: 'Processing, please wait...' });

      try {
        let result = [];
        if (!this.actionLogs?.length) {
          result = await this.getReport();
        }
        if (!result?.length && !this.actionLogs?.length) {
          this.setFeedback('info', 'No logged events found for this period!');
          return;
        }
        const fileName = `mailingLists_${this.selectedRequestType}_report_from_${this.fileNameDates.startDate}_to_${this.fileNameDates.endDate}.csv`;
        await this.$store.dispatch('logs/generateCsvFromMailingListLogs', { logs: this.actionLogs, fileName });
      } catch (error) {
        this.setFeedback('error', 'Something went wrong while generating CSV.');
        console.error('[downloadReport error]: ', error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    setFeedback(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    },
    clearMonth() {
      this.selectedMonth = '';
      this.totalCounts = 0;
      this.businessCounts = 0;
    },
    resetDates() {
      this.selectedMonth = '';
      this.actionLogs = [];
      this.startDate = this.$moment().startOf('month').format('YYYY-MM-DD');
      this.endDate = this.$moment().format('YYYY-MM-DD');
      this.resetCounts();
    },
    resetCounts() {
      this.totalCounts = 0;
      this.businessCounts = 0;
      this.consumerCounts = 0;
      this.successCounts = 0;
      this.errorCounts = 0;
    },
    getDateIntervals() {
      let startDateParam = '';
      let endDateParam = '';
      if (this.selectedMonth) {
        startDateParam = this.getDatePart(this.selectedMonth, 'month', 'start');
        this.fileNameDates.startDate = this.formatDate(startDateParam);
        endDateParam = this.getDatePart(this.selectedMonth, 'month', 'end');
        this.fileNameDates.endDate = this.formatDate(endDateParam);
      } else {
        startDateParam = this.getDatePart(this.startDate, 'day', 'start');
        this.fileNameDates.startDate = this.formatDate(startDateParam);
        endDateParam = this.getDatePart(this.endDate, 'day', 'end');
        this.fileNameDates.endDate = this.formatDate(endDateParam);
      }
      return { startDateParam, endDateParam };
    },
    getDatePart(date, datePart, part) {
      return part === 'start' ? this.$moment(date).startOf(datePart).toDate() : this.$moment(date).endOf(datePart).toDate();
    },
    formatDate(date = null, format = 'MM_DD_YYYY') {
      const dateToFormat = date ? new Date(date) : new Date();
      return this.$moment(dateToFormat).format(format);
    }
  }
};
</script>
