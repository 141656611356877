<template>
  <div>
    <v-toolbar flat class="site-table--header site-table--lists mb-3">
      <v-row class="mt-n3 mb-0">
        <v-toolbar-title class="headline grey--text text--darken-3 ml-3 mt-3 pt-1">Templates</v-toolbar-title>
      </v-row>
    </v-toolbar>

    <v-divider class="mb-4"></v-divider>

    <div class="default-products-edit-section mb-3">
      <v-container>
        <p>Edit default templates</p>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-toolbar flat>
                <v-toolbar-title>Default Templates</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="navigateToTemplates('default')"> Edit Default Templates </v-btn>
              </v-toolbar>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-divider class="mb-4"></v-divider>

    <v-container>
      <v-row>
        <p class="ml-3 mt-4 pt-1">Select a site to manage templates</p>
        <v-spacer />
        <v-col cols="12" sm="5" md="4" lg="4" xl="3" class="text-center">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense clearable></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="sites" class="site-table">
      <stamp-data-table
        id="templates-table"
        :headers="headers"
        :data="sitesWithTemplateCounts"
        :is-border-visible="true"
        :search="search"
        :is-items-per-page-disabled="true"
        :items-per-page="50"
        sort-by="created_at"
        :sort-desc="true"
        item-key="firebase_id"
      >
        <template #[`item.site_name`]="{ item }">
          <a :href="`${formatLink(item.site_url)}`" target="_blank" rel="noopener noreferrer" class="blue--text">{{ item.site_name }}</a>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn text color="blue" @click="navigateToTemplates(item.firebase_id)">MANAGE TEMPLATES</v-btn>
        </template>
      </stamp-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: { 'stamp-data-table': DataTable },
  data() {
    return {
      sortBy: 'created_at',
      descending: true,
      headers: [
        { text: 'Site Name', value: 'site_name' },
        { text: 'Site URL', value: 'site_url' },
        { text: 'Site DB ID', value: 'firebase_id', width: 220 },
        { text: 'Number of Templates', value: 'template_counts', width: 200 },
        { text: 'Actions', value: 'actions', align: 'center' }
      ],
      search: '',
      isDeleteDialogOpen: false,
      deletedSite: null,
      siteTemplateCounts: {}
    };
  },
  computed: {
    orderSettings() {
      return this.$store.getters.getOrderSettings;
    },
    ...mapGetters('site', {
      sites: 'getSites',
      searchText: 'getSearchedSite',
      siteRoutes: 'getSitesAcceptedRoutes',
      templateCounts: 'getTemplateCounts'
    }),
    sitesWithTemplateCounts() {
      return this.sites.map(obj => ({
        ...obj,
        template_counts: this.templateCounts[obj.firebase_id]
      }));
    }
  },
  watch: {
    search(value) {
      this.$store.commit('site/setSearchedSite', value || '');
    }
  },
  async created() {
    await this.$store.dispatch('loadSitesFromDb');
    await this.$store.dispatch('site/initTemplateCounts');

    this.search = this.searchText?.length && (this.siteRoutes === 'siteProcess' || this.siteRoutes === 'orderProcesses') ? this.searchText : '';
    this.$store.commit('site/setSitesAcceptedRoutes', this.$options.name);
  },
  methods: {
    formatLink(link) {
      return `https://${link.split('//')[1].split('/')[0]}`;
    },
    navigateToTemplates(siteId) {
      this.$router.push({ name: 'siteSpecificTemplates', params: { siteId } });
    }
  }
};
</script>
