import productServiceService from '../../services/productServiceService';
import { cloudFunctionsPost, cloudFunctionsPatch } from '../../modules/cloud-function-wrapper.js';
import { dbFS } from '@/services/firebaseInit';

const state = {
  productServices: [],
  isProductServicesLoading: false
};

const getters = {
  getProductServices(state) {
    return state.productServices;
  },
  getProductServicesLoadingState(state) {
    return state.isProductServicesLoading;
  }
};

const mutations = {
  setProductServices(state, payload) {
    state.productServices = payload;
  },
  setProductServicesLoadingState(state, payload) {
    state.isProductServicesLoading = payload;
  }
};

const actions = {
  fetchProductServices({ commit }) {
    commit('setProductServicesLoadingState', true);
    return new Promise(resolve => {
      dbFS.collection('product_services').onSnapshot(snapshot => {
        commit('setProductServices', []);
        const productServices = snapshot.docs.map(service => ({ firebase_service_id: service.id, ...service.data() }));
        commit('setProductServices', productServices);
        commit('setProductServicesLoadingState', false);
        resolve(productServices);
      });
    });
  },
  getProductServiceByID(_context, payload) {
    return productServiceService.getById(payload);
  },
  async addProductService({ dispatch }, payload) {
    const firebase_service_id = await productServiceService.add(payload);
    const service = { firebase_service_id, ...payload.data };
    try {
      const { data } = await dispatch('saveProductServiceToDatabase', { endpoint: 'create', data: service });
      payload.data.service_id = service.service_id = data.service_id;
      const netsuiteResponse = await dispatch('saveServiceToNetsuite', { endpoint: 'create', data: service });
      console.log('netsuite id', netsuiteResponse.data.result);
      service.netsuite_id = payload.data.netsuite_id = Number(netsuiteResponse?.data?.result || '');
      await Promise.all([productServiceService.update(firebase_service_id, payload.data), dispatch('saveProductServiceToDatabase', { endpoint: 'update', data: service })]);
      return firebase_service_id;
    } catch (error) {
      dispatch('deleteProductService', service);
      throw error;
    }
  },
  async editProductService({ dispatch }, payload) {
    const service = { firebase_service_id: payload.id, ...payload.data };
    const endpoint = payload.data.service_id ? 'update' : 'create';
    const { data } = await dispatch('saveProductServiceToDatabase', { endpoint, data: service });
    payload.data.service_id = service.service_id = data.service_id || payload.data.service_id;
    const netsuiteEndpoint = payload.data.netsuite_id ? `update/${payload.data.netsuite_id}` : 'create';

    let netsuiteResponse = {};
    try {
      netsuiteResponse = await dispatch('saveServiceToNetsuite', { endpoint: netsuiteEndpoint, data: service });
    } catch (error) {
      console.log('Error saving product service in netsuite: ', error);
    }

    service.netsuite_id = payload.data.netsuite_id = Number(netsuiteResponse?.data?.result || payload.data.netsuite_id);
    await Promise.all([productServiceService.update(payload.id, payload.data), dispatch('saveProductServiceToDatabase', { endpoint: 'update', data: service })]);
    return payload.id;
  },
  deleteProductService(_context, payload) {
    return productServiceService.delete(payload.firebase_service_id);
  },
  saveProductServiceToDatabase(_context, { endpoint, data }) {
    try {
      return cloudFunctionsPost(`/services/${endpoint}`, data);
    } catch (error) {
      console.error('[saveProductServiceToDatabase error ]', error);
      throw new Error(`Could not save product service to SQL database. ${error.response?.data?.message || error.message || ''}`);
    }
  },
  saveServiceToNetsuite(_context, { endpoint, data }) {
    try {
      const netsuiteData = {
        externalId: data.firebase_service_id,
        itemId: data.name,
        displayName: data.name,
        isInactive: !data.is_active,
        custitemproduct_firebase_id: data.firebase_service_id,
        custitemtype_name: data.name,
        custiteminternal_product_id: data.service_id,
        custitemsalesforce_product_id: data.salesforce_id
      };
      console.log('[saveServiceToNetsuite payload ]: ', netsuiteData);

      return endpoint === 'create' ? cloudFunctionsPost(`/netsuite/items/${endpoint}`, netsuiteData) : cloudFunctionsPatch(`/netsuite/items/${endpoint}`, netsuiteData);
    } catch (error) {
      console.error('[saveServiceToNetsuite error ]', error);
      throw new Error(`Could not save product service to Netsuite. ${error.response?.data?.message || error.message || ''}`);
    }
  },
  async updateServices({ dispatch }, payload) {
    console.log('updateServices payload: ', payload);

    try {
      //1. Update the products saved under the product_pricing collection
      await dispatch('updateServicesForCollection', { collectionPath: 'product_pricing', payload });

      //2. Update the products saved under the sites collection
      const sitesRef = dbFS.collection('sites');
      const sitesSnapshot = await sitesRef.get();
      await Promise.all(sitesSnapshot.docs.map(siteDoc => 
        dispatch('updateServicesForCollection', { collectionPath: `sites/${siteDoc.id}/product_pricing`, payload })
      ));
    } catch (error) {
      console.log('Error updating product services: ', error);
    }
  },
  async updateServicesForCollection({ _ }, { collectionPath, payload }) {
    const serviceOptions = payload.data.options;
    const serviceFirebaseId = payload.id;

    try {
      const pricingRef = dbFS.collection(collectionPath);
      const querySnapshot = await pricingRef.get();

      await Promise.all(querySnapshot.docs.map(async (productDoc) => {
        const originalProductData = productDoc.data();
        const updatedProductData = JSON.parse(JSON.stringify(originalProductData));

        if (hasServiceToUpdate(originalProductData, serviceFirebaseId)) {
          Object.values(updatedProductData).forEach(tier => {

            if (typeof tier === 'object' && tier?.services) {
              Object.values(tier.services).forEach(service => {

                if (service?.firebase_service_id === serviceFirebaseId && service?.headers) {
                  service?.headers.forEach(header => {
        
                    if (header.value !== 'from' && header.value !== 'up_to') {
                      const option = findMatchingOption(serviceOptions, header);
                      if (option) {
                        header.text = option.name;
                        if (option?.thickness) {
                          header.thickness = option.thickness;
                        }
                      }
                    }
                  });
                }
              });
            }
          });

          productDoc.ref.set(updatedProductData);
          console.log(`Updated pricing in ${collectionPath} for product: `, productDoc.id);
        }
      }));

      console.log(`All products in ${collectionPath} processed.`);
    } catch (error) {
      console.error(`Error updating product services in ${collectionPath}:`, error);
    }
  }
};

function hasServiceToUpdate(productData, serviceFirebaseId) {
  return Object.values(productData).some(tier => 
    Object.values(tier.services || {}).some(service => 
      service.firebase_service_id === serviceFirebaseId
    )
  );
}

function findMatchingOption(options, item) {
  const key = item.value;
  return options.hasOwnProperty(key) ? options[key] : undefined;
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
