import RestClient from './rest-client.js'

class ShippingModel extends RestClient {
	constructor (context) {
		super('shipping', context)
		this.context = context
	}

  loadShippingRates (config) {
    return this.post({
      params: config,
			success: (response, resolve) => {
        console.log('response: ', response)
				resolve(response.data.shipping_rates)
			},
			error: (error, reject) => {
				console.log('Get categories from the server has failed', error)
				reject()
			}
		})
  }
}

export { ShippingModel as default }
