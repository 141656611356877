<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" md="3">
        <v-text-field v-model="name" label="Name" class="search-input"></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="email" label="Email" class="search-input"></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="phone" label="Phone"></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pt-3 text-center">
        <v-btn color="info" @click="searchCustomer">Search Customer</v-btn>
      </v-col>
    </v-row>
    <div v-if="!isCustomerListEmpty" class="site-table">
      <stamp-data-table :headers="headers" :data="customerList" item-key="Id">
        <template #[`item.actions`]="{ item }">
          <v-btn @click="onSelectCustomer(item)">Select</v-btn>
        </template>
      </stamp-data-table>
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: { 'stamp-data-table': DataTable },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      customerList: [],
      headers: [
        { text: 'ID', value: 'Id' },
        { text: 'Name', value: 'Name' },
        { text: 'First Name', value: 'FirstName' },
        { text: 'Last Name', value: 'LastName' },
        { text: 'Phone', value: 'Phone' },
        { text: 'Email', value: 'Email' },
        { text: 'Actions', value: 'actions' }
      ]
    };
  },
  computed: {
    isSearchInputEmpty() {
      return this.name === '' && this.email === '' && this.phone === '';
    },
    isCustomerListEmpty() {
      return this.customerList.length === 0;
    },
    selectedSiteUrl() {
      return this.$store.getters.getSiteForQuoteTool.site_url;
    }
  },
  watch: {
    selectedSiteUrl() {
      // If the site is changed the customer data has to be reseted
      this.reset();
    }
  },
  methods: {
    reset() {
      this.customerList = [];
      this.onSelectCustomer(null);
      this.name = '';
      this.email = '';
      this.phone = '';
    },
    searchCustomer() {
      if (!this.isSearchInputEmpty) {
        this.$store.commit('setProgressValue', true);
        this.$store
          .dispatch('searchCustomer', {
            name: this.name,
            email: this.email,
            phone: this.phone
          })
          .then(result => {
            this.customerList = result.records;
            this.$store.commit('setProgressValue', false);
          });
      } else {
        console.log('Search input is empty');
        this.$store.commit('setProgressValue', false);
      }
    },
    onSelectCustomer(customer) {
      console.log('Selected Customer: ', customer);
      this.$store.commit('setSelectedCustomer', customer);

      this.$store.dispatch('scroll_to', 'quote-tool-content');
    }
  }
};
</script>

<style>
.search-input {
  width: 90%;
}

.fullWidth {
  width: 100%;
}
</style>
