<template>
	<v-dialog
    v-model="deleteAttributesDialog"
    max-width="650"
    persistent
    >
        <v-card>
            <v-card-title class="headline red">
                <div class="text-xs-center">
                    <span class="white--text text-md-center">Delete new attribute</span>
                </div>
            </v-card-title>
            <v-card-text>
                <v-alert
                   v-model="emptyAttrName"
                   type="warning"
                   dismissible
                   outlined>
                        Attribute name cannot be empty!
                </v-alert>
                <v-layout align-center justify-center row>
                    <div class="stamp-dsa-wrapper">
                        <v-text-field
                            v-model="attrName"
                            label="Attribute name"
                            type="text"
                            >
                        </v-text-field>
                    </div>
                    <div>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="checkForAttributes()"
                        >
                        check attr
                        </v-btn>
                    </div>
                </v-layout>
                <div v-if="Object.entries(attrFoundList).length > 0">
                    <v-layout row wrap justify-end mb-3 mr-0>
                        <v-flex xs12 sm6 md6 lg6>
                            <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                    <stamp-data-table :headers="headers" :data="attrFoundList" :search="search" />
                </div>
           </v-card-text>
           <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="green darken-1"
                text
                @click="closeDeleteAttributesDialog"
                >
                Cancel
                </v-btn>
                <v-btn
                color="green darken-1"
                text
                @click="deleteAttribute"
                >
                Delete attribute
                 </v-btn>
            </v-card-actions>
            <v-progress-linear v-if="loading" :indeterminate="loading" class="my-0"></v-progress-linear>
        </v-card>
    </v-dialog>
</template>

<script>
  import siteSettingsService from '../../../services/siteSettingsService.js'
  import DataTable from '@/components/common/DataTable.vue';

  export default {
    components: { 'stamp-data-table': DataTable },
    props: ['value'],
    data () {
      return {
        deleteAttributesDialog: this.value,
        attrName: '',
        emptyAttrName: false,
        loading: false,
        attrFoundList: [],
        headers: [
            { text: 'Site ID', value: 'siteId', align: 'left' },
            { text: 'Site Name', value: 'siteName', align: 'left' }
        ],
        search: '',
        sitesList: [],
      }
    },
    watch: {
      value (value) {
        this.deleteAttributesDialog = this.value
      }
    },
    methods: {
      closeDeleteAttributesDialog () {
        this.attrFoundList = []
        this.attrName = ''
        this.$emit('closeDeleteAttributesDialog')
      },
      deleteAttribute () {
        const attrObj = {
           attrList: this.attrFoundList,
           attrName: this.attrName
        }
        if (this.attrName.length < 1) {
            this.emptyAttrName = true
        } else {
            this.$emit('deleteAttribute', attrObj)
        }
      },
      async checkForAttributes () {
            if (this.attrName.length < 1) {
                this.emptyAttrName = true
            } else {
                this.attrFoundList = []
                const attrName = this.attrName
                this.loading = true;
                this.sitesList = await siteSettingsService.getAll({ isArray: true });
                this.sitesList.forEach((value, index) => {
                    Object.entries(value).forEach(([key, valueO]) => {
                        if (key === attrName) {
                            this.attrFoundList.push({
                                siteId: value.site_id,
                                siteName: value.site_name,
                                firebaseId: value.firebase_id
                            })
                        }
                    })
                })
                console.log('found list', this.attrFoundList)
                this.loading = false
            }
        }
    }
  }
</script>

<style scoped>
.stamp-dsa-wrapper {
    width: 100%;
}
</style>
