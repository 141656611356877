<template>
  <v-container fluid transition="fade-transition">
    <ConfirmationDialog
      :value="isConfirmationDialogOpened"
      title="Confirmation Required"
      :text="`Are you sure you want to ${confirmationDialogAction}`"
      @onClose="closeConfirmationDialog"
      @onConfirm="duplicateConfirmed"
    />

    <ConfirmationDialog
      :value="isDeleteDialogOpened"
      title="Delete selected item(s)?"
      :text="`${selectedFouroverStatus.fourover_status_id}`"
      @onClose="closeDeleteDialog"
      @onConfirm="deleteConfirmed"
    />
    <v-banner single-line class="mb-4 title" @click:icon="back">
      <v-icon slot="icon" class="showPointerOnHover">mdi-chevron-left</v-icon>
      4over Statuses

      <template v-slot:actions>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense clearable class="mr-2"></v-text-field>
        <v-btn color="blue" class="white--text ml-2 mr-n1" min-width="200" @click="openNewFouroverStatusDialog()"><v-icon left>mdi-plus-circle</v-icon>Add New 4over Status</v-btn>
      </template>
    </v-banner>

    <div class="site-table mt-n5 ml-n1 mr-0">
      <stamp-data-table
        :headers="headers"
        :data="fourover_statuses"
        :actions="actions"
        :search="search"
        :is-loading="isFouroverStatusesTableLoading"
        :is-items-per-page-disabled="true"
        sort-by="order_status_number"
        item-key="fourover_status_firebase_id"
        @viewFouroverStatus="viewFouroverStatus"
        @editFouroverStatus="editFouroverStatus"
        @duplicateFouroverStatus="duplicateFouroverStatus"
        @deleteFouroverStatus="deleteFouroverStatus"
      >
        <template #[`item.graphic`]="{ item }">
          <v-img max-width="35" :src="item.image"></v-img>
        </template>
        <template #[`item.fourover_status_firebase_id`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon color="amber darken-3" v-on="on" @click="copyItemToClipboard(item.fourover_status_firebase_id)">mdi-firebase</v-icon>
            </template>
            <span>Copy FirebaseID ( {{ item.fourover_status_firebase_id }} ) to clipboard</span>
          </v-tooltip>
        </template>
      </stamp-data-table>
    </div>
  </v-container>
</template>

<script>
import ConfirmationDialog from '@/components/dialog/ConfirmationDialog';
import { mapGetters } from 'vuex';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: {
    ConfirmationDialog,
    'stamp-data-table': DataTable
  },
  data() {
    return {
      search: '',
      headers: [
        { text: '4over Status ID', value: 'fourover_status_id', sortable: true },
        { text: '4over Status Name', value: 'fourover_status_name', sortable: true },
        { text: 'Osprey Order Status Number', value: 'order_status_number', sortable: true },
        { text: 'Osprey Order Status Name', value: 'order_status_name', sortable: true },
        { text: 'Osprey Maildrop Status Number', value: 'mail_drop_status_number', sortable: true },
        { text: 'Osprey Maildrop Status Name', value: 'mail_drop_status_name', sortable: true },
        { text: 'Firebase ID', value: 'fourover_status_firebase_id', align: 'center', sortable: false }
      ],
      actions: [
        { icon: 'mdi-eye', iconColor: 'blue darken-1', title: 'View 4over Status', eventName: 'viewFouroverStatus' },
        { icon: 'mdi-pencil', iconColor: 'green darken-2', title: 'Edit 4over Status', eventName: 'editFouroverStatus' },
        { icon: 'mdi-plus-box-multiple-outline', iconColor: 'grey darken-1', title: 'Duplicate 4over Status', eventName: 'duplicateFouroverStatus' },
        { icon: 'mdi-delete-outline', iconColor: 'red darken-1', title: 'Delete 4over Status', eventName: 'deleteFouroverStatus' }
      ],
      isDeleteDialogOpened: false,
      selectedFouroverStatus: {},
      isConfirmationDialogOpened: false,
      confirmationDialogAction: ''
    };
  },
  computed: {
    ...mapGetters('fourover_statuses', {
      fourover_statuses: 'getFouroverStatuses',
      isFouroverStatusesTableLoading: 'getFouroverStatusesLoadingState'
    })
  },
  created() {
    this.$store.dispatch('fourover_statuses/getAllFouroverStatuses');
  },
  methods: {
    back() {
      this.$router.back();
    },
    openNewFouroverStatusDialog() {
      this.$store.commit('fourover_statuses/setSelectedModel', []);
      this.$store.dispatch('fourover_statuses/initSelectedModel');
      this.$router.replace('/fourover-statuses/new');
    },
    showFouroverStatus(event, fourover_status) {
      this.$store.dispatch('fourover_statuses/initSelectedModel', fourover_status);
      this.$router.replace(`/fourover-statuses/${event}/${fourover_status.fourover_status_firebase_id}`);
    },
    editFouroverStatus(fourover_status) {
      this.showFouroverStatus('edit', fourover_status);
    },
    viewFouroverStatus(fourover_status) {
      this.showFouroverStatus('view', fourover_status);
    },
    duplicateFouroverStatus(fourover_status) {
      this.confirmationDialogAction = `duplicate 4over status '${fourover_status.fourover_status_id}'`;
      this.selectedFouroverStatus.data = fourover_status;
      this.isConfirmationDialogOpened = true;
    },
    async duplicateConfirmed() {
      this.$store.dispatch('showLoader', { message: `Duplicating 4over status ${this.selectedFouroverStatus.data.fourover_status_id} in progress...` });
      this.selectedFouroverStatus.data.fourover_status_id += '(1)';
      const { fourover_status_firebase_id, ...typeData } = this.selectedFouroverStatus.data;
      const fouroverStatusDuplicate = { data: typeData };
      try {
        const result = await this.$store.dispatch('fourover_statuses/addNewFouroverStatus', fouroverStatusDuplicate);
        this.setSnackbar('success', `4over Status saved with id: ${result} `);
      } catch (error) {
        this.showAlert('error', error.message || error);
      } finally {
        this.selectedFouroverStatus = {};
        this.closeConfirmationDialog();
        this.$store.dispatch('hideLoader');
      }
    },
    deleteFouroverStatus(fourover_status) {
      this.selectedFouroverStatus = fourover_status;
      this.isDeleteDialogOpened = true;
    },
    async deleteConfirmed() {
      this.$store.dispatch('showLoader', { message: `Deleting 4over status in progress...` });
      try {
        await this.$store.dispatch('fourover_statuses/deleteFouroverStatus', { fourover_status_firebase_id: this.selectedFouroverStatus.fourover_status_firebase_id });
        this.setSnackbar('success', `4over Status [ ${this.selectedFouroverStatus.fourover_status_firebase_id} ] has been deleted successfully!`);
      } catch (error) {
        this.showAlert('error', error.message || error);
      } finally {
        this.closeDeleteDialog();
        this.selectedFouroverStatus = {};
        this.$store.dispatch('hideLoader');
      }
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpened = false;
    },
    closeConfirmationDialog() {
      this.isConfirmationDialogOpened = false;
      this.confirmationDialogAction = '';
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    }
  }
};
</script>
