<template>
  <v-dialog v-model="isConfirmationDialogOpen" persistent max-width="650">
    <v-card class="pb-2">
      <v-card-title class="subtitle-1 font-weight-regular">Confirmation Required</v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="1">
            <v-icon color="warning" large class="mt-n1">mdi-information</v-icon>
          </v-col>
          <v-col cols="11" class="pl-5">
            <v-row v-if="additionalMessage" class="mt-n4 mb-1">
              <span class="subtitle-1">{{ additionalMessage }}</span>
            </v-row>
            <v-row>
              <span class="subtitle-1">Are you sure you want to {{ action ? action : 'save the changes' }}?</span>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end mt-n3 mr-2">
        <v-btn small outlined color="grey darken-2" min-width="80" @click="closeConfirmationDialog()">No</v-btn>
        <v-btn small color="green darken-2" min-width="80" class="white--text ml-5" @click="updateConfirmed()">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['isConfirmationDialogOpened', 'action', 'additionalMessage'],
  computed: {
    isConfirmationDialogOpen() {
      return this.isConfirmationDialogOpened;
    }
  },
  methods: {
    closeConfirmationDialog() {
      this.$emit('closeConfirmationDialog');
    },
    updateConfirmed() {
      this.$emit('actionConfirmed', { action: this.action || '' });
    }
  }
};
</script>
