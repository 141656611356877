<template>
	<v-container>
		<v-dialog v-model="dialogCustomizeCSV" scrollable persistent max-width="850">
			<v-card>
				<v-card-title class="headline">
					Download CSV by period
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col>
							<v-alert v-model="errors" type="error" dismissible outlined>
								<span v-if="errorNo === 1">Error on receive the number of addresses (counts)!</span>
								<span v-else-if="errorNo === 2">Erorr: CSV link is Empty!</span>
								<span v-else-if="errorNo === 3">Please add CRM Customer ID!</span>
							</v-alert>
						</v-col>
					</v-row>
					<v-row>
						<v-col sm="6">
							<v-menu v-model="menuStart" :close-on-content-click="false" max-width="290">
								<template v-slot:activator="{ on }">
									<v-text-field :value="computedStart" label="Start Date" readonly v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="firstDateDP" @change="menuStart = false"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col sm="6">
							<v-menu v-model="menuEnd" :close-on-content-click="false" max-width="290">
								<template v-slot:activator="{ on }">
									<v-text-field :value="computedEnd" label="End date" readonly v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="endDateDP" @change="menuEnd = false"></v-date-picker>
							</v-menu>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-text-field v-model="crmCustomerId" name="CRM Customer ID" label="CRM Customer ID (DMM)" hint="CRM Customer ID is needed when DMM CSV is generated!" type="number" filled></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn block class="static mr-4" @click="allFieldsCsv()">
								<span>Generate CSV</span>
							</v-btn>
						</v-col>
						<v-col>
							<v-btn block class="static mr-4" @click="dmmCsv()">
								<span>DMM</span>
							</v-btn>
						</v-col>
						<v-col>
							<v-btn block class="static" @click="getCounts()">Get Counts</v-btn>
						</v-col>
					</v-row>
					<!-- <v-layout align-center justify-center row> -->
					<v-row class="justify-center align-center">
						<v-col>
							<div class="my-5">
								<div v-if="addresses > 0">
									<p class="title pr-4 mt-5 black--text text-center">{{addresses}} Addresses Available</p>
								</div>
							</div>
						</v-col>
					</v-row>
					<!-- </v-layout> -->
					<v-row v-if="generateZipListSuccess">
						<v-col>
							<p>CSV Link</p>
							<div class="pnmj-url-wrapper">
								<a v-bind:href="dynamicCsvLink" target="_blank">{{ dynamicCsvLink }}</a>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" text @click="close()">Close</v-btn>
				</v-card-actions>
				<!-- <v-progress-linear :indeterminate="loadingGetMailCountsDCBP" class="my-0" v-if="loadingGetMailCountsDCBP"></v-progress-linear> -->
			</v-card>
		</v-dialog>
		<v-col cols="12" class="pl-0">
			<v-btn icon class="mb-2" @click.native="back()">
				<v-icon color="primary">mdi-arrow-left</v-icon>
			</v-btn>
			<span class="headline">Process NewMovers JSON</span>
		</v-col>
		<v-divider></v-divider>
		<v-row>
			<v-col>
				<v-alert v-model="emptyDownloadUrlAlert" type="warning" dismissible outlined>
					JSON URL is empty! Zip list cannot be generated for empty URL!
				</v-alert>
				<v-alert v-model="zipStringError" type="error" dismissible outlined>
					Error on create Zips String!
					<span v-if="fileSize.length > 0"> JSON size: {{fileSize}}</span>
				</v-alert>
				<v-text-field v-model="jsonUrl" label="JSON URL" single-line hide-details></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<p>JSON file parsing</p>
				<v-radio-group v-model="fileParsing" :mandatory="false">
					<v-radio label="Server side" value="server"></v-radio>
					<v-radio label="Browser" value="browser"></v-radio>
				</v-radio-group>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-btn color="grey lighten-2 mr-3" @click="checkZip()">
					Generate Zip list
				</v-btn>
				<v-btn color="grey lighten-2" @click="downloadUrlloadCSVcustom()">
					Generate CSV
				</v-btn>
			</v-col>
		</v-row>
		<v-divider class="my-4"></v-divider>
		<div v-if="responseSuccess">
			<v-row>
				<v-col>
					<span class="headline">
						Zip Result from &nbsp;
						<span v-if="urlEnv"> Amazon AWS</span>
						<span v-else> Google Fireabase Storage</span>
					</span>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<p class="title">Zip String</p>
					<v-textarea v-model="zipStringList" hint="Zip String List" auto-grow filled></v-textarea>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-card>
						<v-card-title>
							<v-col cols="12" md="3" class="pl-0">
								<span class="title">Zip Table</span>
							</v-col>
							<v-col>
								<v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
							</v-col>
						</v-card-title>
						<stamp-data-table :headers="[{ text: 'Zip', value: 'zip', align: 'center' }]" :data="zips" item-key="zip" :search="search" />
					</v-card>
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';

export default {
	components: { 'stamp-data-table': DataTable },
	data() {
		return {
			jsonUrl: '',
			emptyDownloadUrlAlert: false,
			zipStringList: '',
			zips: [],
			search: '',
			urlEnv: false,
			responseSuccess: false,
			dialogCustomizeCSV: false,
			menuStart: false,
			menuEnd: false,
			firstDateDP: '',
			endDateDP: new Date().toISOString().substr(0, 10),
			addresses: '',
			generateZipListSuccess: false,
			csvLink: '',
			csvLinkUrl: '',
			crmCustomerId: null,
			errors: false,
			errorNo: 0,
			zipStringError: false,
			fileSize: '',
			fileParsing: 'server'
		}
	},
	computed: {
		computedStart() {
			return this.firstDateDP ? this.$moment(this.firstDateDP).format('YYYY-MM-DD') : ''
		},
		computedEnd() {
			return this.endDateDP ? this.$moment(this.endDateDP).format('YYYY-MM-DD') : ''
		},
		dynamicCsvLink() {
			return this.csvLinkUrl
		}
	},
	watch: {
		computedStart(val) {
			// console.log(val)
			this.csvLinkUrl = this.csvLink + '&startDate=' + val + '&endDate=' + this.computedEnd
		},
		computedEnd(val) {
			// console.log(val)
			this.csvLinkUrl = this.csvLink + '&startDate=' + this.computedStart + '&endDate=' + val
		}
	},
	created() {
		this.firstDateDP = this.$moment().subtract(30, 'days').format('YYYY-MM-DD')
	},
	methods: {
		back() {
			this.$router.back()
		},
		checkZip() {
			if (this.jsonUrl.length < 1) {
				this.emptyDownloadUrlAlert = true
			} else {
				const isUrlFromAmazon = this.urlEnv = this.jsonUrl.includes('amazon')
				this.$store.dispatch('showLoader', { message: 'Processing, please stand by...' });
				const downloadUrl = this.jsonUrl
				const type = 'newmovers'
				this.$store.dispatch('customer_messaging/zipList', { downloadUrl, type, isUrlFromAmazon, fileParsing: this.fileParsing })
					.then((response) => {
						console.log('Zip list response:', response)
						this.$store.dispatch('hideLoader');
						if (response.status === 'error') {
							this.zipStringError = true
							this.fileSize = response.fileSizeInMegabytes.length !== 0 ? response.fileSizeInMegabytes : ''
						} else if (response.status === 'success') {
							this.responseSuccess = true
							this.zips = this.createZipDataTableArray(response.zipArray)
							this.zipStringList = response.zipArray.toString()
						} else {
							console.log('Object returned do not contain the validation data:', response)
						}
					})
			}
		},
		createZipDataTableArray(array) {
			let zipDataTableArray = []
			array.forEach((value, index) => {
				zipDataTableArray.push({
					zip: value
				})
			})
			return zipDataTableArray
		},
		downloadUrlloadCSVcustom() {
			if (this.jsonUrl.length < 1) {
				this.emptyDownloadUrlAlert = true
			} else {
				this.$store.dispatch('showLoader', { message: 'Getting IDs, please stand by...' });
				const isUrlFromAmazon = this.urlEnv = this.jsonUrl.includes('amazon')
				const downloadUrl = this.jsonUrl
				const type = 'newmovers'
				this.$store.dispatch('customer_messaging/zipList', { downloadUrl, type, isUrlFromAmazon, fileParsing: this.fileParsing })
					.then(response => {
						if (response.status === 'success') {
							const zipStringList = response.zipArray.toString()

							this.generateZipListSuccess = true
							const startDate = this.computedStart
							const endDate = this.computedEnd

							this.csvLink = 'https://emapdata.adc4gis.com/movers.csv?ids=' + zipStringList
							this.csvLinkUrl = this.csvLink + '&startDate=' + startDate + '&endDate=' + endDate
							this.dialogCustomizeCSV = true
						} else {
							console.log('Object returned do not contain the validation data:', response)
						}
					}).finally(() => this.$store.dispatch('hideLoader'))
			}
		},
		close() {
			this.dialogCustomizeCSV = false
			this.addresses = 0
		},
		getCounts() {
			this.loading = true
			if (this.csvLink.length > 0) {
				const csvLink = `${this.csvLink}&startDate=${this.computedStart}&endDate=${this.computedEnd}`;
				this.addresses = 0;
				this.$store.dispatch('showLoader', { message: 'Processing, please stand by...' });
				this.$store.dispatch('tools/getCsvCounts', { csvLink, returnCsvFile: false }).then(response => {
					console.log('CsvLines response:', response)
					this.$store.dispatch('hideLoader');
					if (response?.data?.rowCount > 0) {
						this.addresses = response.data.rowCount;
					} else {
						this.errors = true
						this.errorNo = 1
					}
				})
			} else {
				this.errors = true
				this.errorNo = 2
			}
		},
		dmmCsv() {
			if (this.crmCustomerId) {
				this.$store.dispatch('showLoader', { message: 'Processing, please stand by...' });
				const csvLink = `${this.csvLink}&startDate=${this.computedStart}&endDate=${this.computedEnd}`;
				this.$store.dispatch('tools/getPreparedCsvForDmm', {
					csvLink,
					returnCsvFile: true,
					customerId: this.crmCustomerId
				})
				.then(() => this.$store.dispatch('hideLoader'))
			} else {
				this.errors = true
				this.errorNo = 3
			}
		},
		allFieldsCsv() {
			this.$store.dispatch('showLoader', { message: 'Processing, please stand by...' });
			const csvLink = `${this.csvLink}&startDate=${this.computedStart}&endDate=${this.computedEnd}`;
			this.$store.dispatch('tools/getCsvCounts', { csvLink, returnCsvFile: true }).then(response => {
				console.log('Response:', response)
				this.$store.dispatch('hideLoader', {});
				let blob = new Blob([response.data], { type: 'text/csv' })
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = 'new_movers.csv'
				link.click()
			})
		}
	},
}
</script>
<style scoped>
.pnmj-url-wrapper {
	word-break: break-word;
}

#nmjson-loader {
	height: 40px;
	width: 40px;
	background: rgba(0, 0, 0, 0.8);
	border-radius: 50%;
	border: 1px solid #1D9AF2;
	opacity: 1;
	animation: spinner4 800ms linear infinite;
}

@keyframes spinner4 {
	to {
		transform: scale(1.5);
		opacity: 0;
	}
}
</style>