<template>
  <div>
    <confirm-delete-dialog
      :isDialogOpened="isDeleteConfirmationDialogOpened"
      :itemNames="selectedTemplate.name"
      @closeDialog="closeDeleteConfirmationDialog"
      @confirmDelete="confirmDeleteTemplate"
    ></confirm-delete-dialog>

    <product-import-dialog
      :isDialogOpened="isImportProductsDialogOpened"
      :text="`Currently, there are ${newProducts?.length} products associated with the templates you need to import that are not presented on the site yet. Would you like to import these products? Without them, the templates cannot be imported.`"
      closeText="Close"
      confirmText="Import"
      @onClose="closeProductImportDialog"
      @onConfirm="importProducts"
    >
    </product-import-dialog>

    <confirm-import-dialog
      :isDialogOpened="isImportDialogOpened"
      :selectedItemsCount="selectedTemplates?.length"
      :duplicatedItemsCount="duplicatedTemplates?.length"
      @closeDialog="closeDefaultImportDialog"
      @excludeItems="excludeItems"
      @overwriteItems="overwriteItems"
    ></confirm-import-dialog>

    <v-dialog v-model="isImportFromCsvDialogOpened" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Import templates</span>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="radioGroup">
            <div class="d-flex flex-row align-content-center">
              <v-radio v-for="importType in importOptions" :key="importType" class="mb-0 mr-2" :label="`${importType}`" :value="importType"></v-radio>
            </div>
          </v-radio-group>
          <v-row>
            <v-col v-if="isTemplateImportUrl" cols="12">
              <v-text-field id="templateLabel" v-model="templateImportUrl" label="Template Download URL" hint="Enter a URL to your templates" required type="text"></v-text-field>
            </v-col>
            <v-col v-else>
              <small>Upload Image</small>
              <FileUpload ref="fileUploader" :accept="'text/csv'" @filesAdded="saveFile" @clear="clearUploadedFile" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mb-2"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="closeCsvImportDialog">Cancel</v-btn>
          <v-btn :disabled="isAddTemplatesDisabled" color="primary" text @click="addTemplatesFromCsv">Confirm</v-btn>
        </v-card-actions>
        <v-card-text v-if="templateUploadExample">
          <p>
            Example:
            <a :href="templateUploadExample" download>Download Template CSV</a>
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row>
      <div class="site-table">
        <v-toolbar flat class="site-table--header site-table--lists">
          <v-row class="mt-n3 mb-0">
            <v-btn v-if="!isImportMode" icon large class="mt-3" @click="navigateBack()"><v-icon class="mb-1">mdi-chevron-left</v-icon></v-btn>
            <v-toolbar-title class="headline grey--text text--darken-3 mt-3 pt-1">{{ getTiltle }}</v-toolbar-title>
            <v-spacer />
            <v-col cols="12" sm="2" md="2" lg="3" xl="4" class="text-center">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense clearable></v-text-field>
            </v-col>
            <div v-if="!isImportMode">
              <v-menu v-if="!isDefaultSite" close-on-content-click open-on-hover offset-y bottom transition="scroll-y-transition">
                <template v-slot:activator="{ on }">
                  <v-btn dark class="mt-3 grey lighten-3 black--text mr-3" v-on="on">Import Templates</v-btn>
                </template>
                <v-list dense>
                  <v-list-item link @click="openCsvImportDialog">
                    <v-list-item-title>From CSV</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="loadImports">
                    <v-list-item-title>From defaults</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn v-else dark class="mt-3 grey lighten-3 black--text mr-3" @click="openCsvImportDialog">Import From CSV</v-btn>
              <v-btn dark color="mt-3 primary" class="white--text" @click="modifyTemplate">New Template</v-btn>
            </div>
            <v-btn v-else dark color="mt-3 primary" class="white--text" @click="initImportTemplates">Import templates</v-btn>
          </v-row>
        </v-toolbar>

        <v-divider class="mb-8"></v-divider>

        <stamp-data-table
          id="templates-table"
          class="mb-8"
          :headers="headers"
          :data="listOfTemplates"
          :is-border-visible="true"
          :actions="!isImportMode ? actions : []"
          :is-multi-select-enabled="isImportMode"
          :search="search"
          item-key="template_firebase_id"
          :is-loading="isLoading"
          :is-items-per-page-disabled="true"
          :items-per-page="50"
          :sort-by="'name'"
          :sort-desc="false"
          @editItem="editItem"
          @viewItem="viewItem"
          @deleteItem="deleteItem"
          @onItemSelect="onTemplateSelect"
        >
          <template #[`item.preview_front_url`]="{ item }">
            <div class="custom-height">
              <img class="image-custom-height" :src="item.preview_front_url" />
            </div>
          </template>
          <template #[`item.preview_back_url`]="{ item }">
            <div class="custom-height">
              <img class="image-custom-height" :src="item.preview_back_url" />
            </div>
          </template>
          <template #[`item.type`]="{ item }">
            <div class="custom-height">
              <span>{{ item.type?.name }}</span>
            </div>
          </template>
          <template #[`item.category`]="{ item }">
            <div v-for="(cat, catIndex) in item.category" :key="catIndex">
              <span>{{ cat.name }}</span>
              <span v-if="catIndex < item.category.length - 1">, </span>
            </div>
          </template>
          <template #[`item.is_default`]="{ item }">
            <div class="custom-height">
              <v-chip small :color="templateInfoColor(item.is_default)"> {{ templateInfoType(item.is_default) }} </v-chip>
            </div>
          </template>
        </stamp-data-table>
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from '../common/DataTable.vue';
import ConfirmDeleteDialog from '../dialog/ConfirmDeleteDialog';
import ConfirmImportDialog from '../dialog/ConfirmImportDialog';
import ConfirmationWarningDialog from '../dialog/ConfirmationWarningDialog.vue';
import FileUpload from '../common/FileUpload.vue';

export default {
  name: 'templates',
  components: {
    'stamp-data-table': DataTable,
    ConfirmDeleteDialog,
    FileUpload,
    'confirm-import-dialog': ConfirmImportDialog,
    'product-import-dialog': ConfirmationWarningDialog
  },
  props: {
    isImportMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      isLoading: false,
      isDeleteConfirmationDialogOpened: false,
      isImportFromCsvDialogOpened: false,
      selectedTemplate: {},
      templateNameToDelete: '',
      siteName: '',
      importOptions: ['Upload File', 'URL'],
      radioGroup: 'Upload File',
      isImportDialogOpened: false,
      isImportProductsDialogOpened: false,
      selectedTemplates: [],
      duplicatedTemplates: [],
      newTemplates: [],
      newProducts: [],
      file: null,
      templateImportUrl: '',
      templateUploadExample: ''
    };
  },
  computed: {
    ...mapGetters('design_live', {
      errors: 'errors'
    }),
    ...mapGetters('templates', {
      listOfTemplates: 'getTemplates',
      siteId: 'getSiteId'
    }),
    ...mapGetters('users', {
      userAccessLevel: 'userAccessLevel'
    }),
    isUserSuperAdmin() {
      return this.userAccessLevel && parseInt(this.userAccessLevel.value) === 100;
    },
    isAddTemplatesDisabled() {
      return this.isTemplateImportUrl ? !this.templateImportUrl : !this.file;
    },
    isTemplateImportUrl() {
      return this.radioGroup === 'URL';
    },
    getTiltle() {
      return this.isImportMode ? `Select templates` : `${this.siteName}: Templates`;
    },
    isDefaultSite() {
      return this.siteId === 'default' && !this.isImportMode;
    },
    actions() {
      let actions = [
        { icon: 'mdi-eye', iconColor: 'blue darken-1', title: 'View Template', eventName: 'viewItem' },
        { icon: 'mdi-pencil', iconColor: 'teal', title: 'Edit Template', eventName: 'editItem' }
      ];

      if (this.isUserSuperAdmin) {
        actions.push({ icon: 'mdi-delete', iconColor: 'red', title: 'Delete Template', eventName: 'deleteItem' });
      }
      return actions;
    },
    headers() {
      const headers = [
        { text: 'Name', align: 'left', value: 'name', width: 300 },
        { text: 'Onebrand ID', value: 'onebrand_id', align: 'left', width: 130 },
        { text: 'Front Path', value: 'dl_template_front', align: 'left', width: 130 },
        { text: 'Back Path', value: 'dl_template_back', align: 'left', width: 130 },
        { text: 'Front Artwork', value: 'preview_front_url', align: 'left', width: 140 },
        { text: 'Back Artwork', value: 'preview_back_url', align: 'left', width: 140 },
        { text: 'Type', value: 'type', align: 'left', width: 140 },
        { text: 'Category', value: 'category', align: 'left', width: 150 },
        { text: 'Last Edited', value: 'edited_at', align: 'left', width: 150 }
      ];

      if (this.siteId === 'default' || this.isImportMode) {
        return headers;
      }

      headers.unshift({ text: 'Customization', align: 'left', value: 'is_default', width: 150 });

      return headers;
    }
  },
  async created() {
    try {
      this.isLoading = true;
      const siteId = this.$route.params.siteId;
      this.$store.commit('templates/setSiteId', siteId);
      this.templateUploadExample = await this.$store.dispatch('templates/getTemplateUploadExample');

      if (this.isImportMode) {
        const siteDetails = await this.$store.dispatch('site/getSiteDetailsInfo', { key: siteId });
        this.siteName = siteDetails.site_name;
        await this.$store.dispatch('templates/initTemplates', { siteId: 'default', isImport: true });
      } else {
        if (siteId === 'default') {
          this.siteName = 'Default';
        } else {
          const siteDetails = await this.$store.dispatch('site/getSiteDetailsInfo', { key: siteId });
          this.siteName = siteDetails.site_name;
        }
        await this.$store.dispatch('templates/initTemplates', { siteId: siteId });
      }
    } catch (error) {
      console.error('SiteSpecificTemplates error: ', error);
    } finally {
      this.isImportMode ? console.log(`List of templates to import:`, this.listOfTemplates) : console.log(`List of templates on ${this.siteName}`, this.listOfTemplates);
      this.isLoading = false;
    }
  },
  methods: {
    async addTemplatesFromCsv() {
      try {
        this.$store.dispatch('showLoader');
        if (this.isTemplateImportUrl) {
          await this.$store.dispatch('templates/uploadTemplatesFromUrl', { url: this.templateImportUrl });
        } else if (this.file) {
          await this.$store.dispatch('templates/uploadTemplatesFromCsv', { file: this.file });
        }
        this.$store.dispatch('showAlert', { type: 'success', message: 'Templates imported successfully!' });
      } catch (error) {
        console.error('addTemplatesFromCsv has failed.', error);
        this.$store.dispatch('showAlert', { type: 'error', message: `Importing templates from CSV failed!` });
      } finally {
        this.closeCsvImportDialog();
        this.$store.dispatch('hideLoader');
      }
    },
    saveFile(files) {
      [this.file] = files;
    },
    clearUploadedFile() {
      this.file = null;
    },
    templateInfoColor(isDefault) {
      return isDefault ? 'primary' : 'secondary';
    },
    templateInfoType(isDefault) {
      return isDefault ? 'Default' : 'Custom';
    },
    modifyTemplate() {
      this.$router.push({ name: 'templatesNew', params: { siteId: this.siteId } });
    },
    editItem(item) {
      this.$router.push({ name: 'templatesEdit', params: { siteId: this.siteId, templateId: item.template_firebase_id } });
    },
    viewItem(item) {
      this.$router.push({ name: 'templatesView', params: { siteId: this.siteId, templateId: item.template_firebase_id } });
    },
    deleteItem(item) {
      if (item?.template_usage) {
        this.$store.dispatch('showAlert', { type: 'error', message: `The template "${item.name}" was used in ${item.template_usage} orders, can not delete it.` });
        return;
      }
      this.selectedTemplate = item;
      this.isDeleteConfirmationDialogOpened = true;
    },
    closeDeleteConfirmationDialog() {
      this.isDeleteConfirmationDialogOpened = false;
    },
    async confirmDeleteTemplate() {
      this.$store.dispatch('showLoader');
      try {
        await this.$store.dispatch('templates/deleteTemplate', { key: this.selectedTemplate.template_firebase_id });
        this.$store.dispatch('showAlert', { type: 'success', message: 'Template deleted successfully!' });
      } catch (error) {
        console.error('[ deleteTemplate ] TRY-CATCH ERROR:', error);
        this.$store.dispatch('showAlert', { type: 'error', message: error });
      } finally {
        this.selectedTemplate = {};
        this.$store.dispatch('hideLoader');
        this.isDeleteConfirmationDialogOpened = false;
      }
    },

    async initImportTemplates() {
      this.selectedTemplates = this.$store.getters['templates/getSelectedTemplates'];

      if (!this.selectedTemplates.length) {
        this.$store.dispatch('showAlert', `Please select at least one template!`);
        return;
      }

      try {
        this.templateObject = {
          data: this.selectedTemplates,
          siteId: this.siteId
        };

        this.duplicatedTemplates = await this.$store.dispatch('templates/getDuplicatedTemplates', this.templateObject);

        this.newTemplates = this.selectedTemplates.filter(
          selectedTemplate => !this.duplicatedTemplates.some(duplicatedTemplate => duplicatedTemplate.template_firebase_id === selectedTemplate.template_firebase_id)
        );

        const linkedProducts = [];
        this.newTemplates.forEach(template => {
          if (!linkedProducts.includes(template.linked_product)) {
            linkedProducts.push(template.linked_product);
          }
        });

        this.newProducts = await this.$store.dispatch('products/getNewProducts', { linkedProducts, siteId: this.siteId });

        if (this.newProducts?.length) {
          this.isImportProductsDialogOpened = true;
          return;
        }

        if (this.duplicatedTemplates?.length) {
          this.isImportDialogOpened = true;
          return;
        }

        await this.importTemplates();
      } catch (error) {
        console.log('Error importing templates: ', error);
        this.$store.dispatch('showAlert', { type: 'error', message: error });
      }
    },

    async excludeItems() {
      this.isImportDialogOpened = false;
      try {
        const duplicatedTemplateIDs = this.duplicatedTemplates.map(obj => obj.template_firebase_id);
        const filteredTemplates = this.templateObject.data.filter(obj => !duplicatedTemplateIDs.includes(obj.template_firebase_id));

        this.templateObject = {
          data: filteredTemplates,
          siteId: this.siteId
        };

        if (this.newProducts?.length) {
          await this.$store.dispatch('products/importProducts', { data: this.newProducts, siteID: this.siteId });
        }
        await this.importTemplates();
      } catch (error) {
        console.error('An error occurred:', error);
        this.$store.dispatch('showAlert', { type: 'error', message: error });
      }
    },

    async overwriteItems() {
      this.isImportDialogOpened = false;
      try {
        await this.importTemplates();
      } catch (error) {
        console.error('An error occurred:', error);
        this.$store.dispatch('showAlert', { type: 'error', message: error });
      }
    },

    async importProducts() {
      this.isImportProductsDialogOpened = false;
      try {
        await this.$store.dispatch('products/importProducts', { data: this.newProducts, siteID: this.siteId });
        if (this.duplicatedTemplates?.length) {
          this.isImportDialogOpened = true;
          return;
        }
        this.importTemplates();
      } catch (error) {
        console.error('Error: ', error);
      }
    },

    async importTemplates() {
      try {
        await this.$store.dispatch('templates/importTemplates', this.templateObject);
        this.$store.commit('templates/setSelectedTemplates', []);
        this.setSnackbar('success', `Selected templates have been imported successfully!`);
        this.navigateBack();
      } catch (error) {
        console.error('Error: ', error);
      }
    },

    closeProductImportDialog() {
      this.isImportProductsDialogOpened = false;
      this.navigateBack();
    },

    closeDefaultImportDialog() {
      this.isImportDialogOpened = false;
    },
    closeCsvImportDialog() {
      this.isImportFromCsvDialogOpened = false;
      this.clearUploadedFile();
    },
    openCsvImportDialog() {
      this.isImportFromCsvDialogOpened = true;
    },
    navigateBack() {
      if (this.isImportMode) {
        this.$router.push({ name: 'siteSpecificTemplates', params: { siteId: this.siteId } });
        return;
      }
      this.$router.push({ name: 'templates' });
    },
    loadImports() {
      this.$router.push({ name: 'importTemplates', params: { siteId: this.siteId } });
    },
    onTemplateSelect(selectedTemplates) {
      this.$store.commit('templates/setSelectedTemplates', selectedTemplates);
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    }
  }
};
</script>

<style scoped>
.custom-height {
  height: 60px;
}

.image-custom-height {
  height: 100% !important;
}

.custom-color {
  background-color: #f1f1f1 !important;
}
</style>
