import { render, staticRenderFns } from "./OrderDataTreeView.vue?vue&type=template&id=271d30dd&scoped=true"
import script from "./OrderDataTreeView.vue?vue&type=script&lang=js"
export * from "./OrderDataTreeView.vue?vue&type=script&lang=js"
import style0 from "./OrderDataTreeView.vue?vue&type=style&index=0&id=271d30dd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "271d30dd",
  null
  
)

export default component.exports