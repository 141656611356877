<template>
  <div>
    <v-alert v-model="isChangesSaved" type="success" dismissible> Changes saved! </v-alert>
    <v-alert v-model="changeImage" type="success" dismissible> Image changed successfully. </v-alert>
    <v-row>
      <v-col xl="10" class="mx-auto">
        <v-row>
          <v-col cols="12" sm="4" xl="3">
            <v-card height="100%">
              <v-container>
                <v-row class="justify-center align-center pt-5">
                  <v-list-item-avatar size="150" class="mx-0">
                    <img v-if="photoUrl && photoUrl.length !== 0" :src="photoUrl" alt="Avatar image" />
                    <v-icon v-else style="font-size: 40px" class="ml-3">mdi-account-circle</v-icon>
                  </v-list-item-avatar>
                </v-row>
                <v-row class="justify-center align-center">
                  <v-col>
                    <div class="user-profile--change-image">
                      <input id="img" ref="myBtn" type="file" @change="changePicture" />
                      <label for="img" class="btn btn-primary image-label text-center primary">Change image</label>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8" md="8" lg="7">
            <v-card>
              <v-card-text class="ml-2">
                <v-alert v-model="succesOld" type="error" dismissible>
                  {{ errors }}
                </v-alert>
                <v-row>
                  <v-col><span class="headline">Account profile</span></v-col>
                  <v-spacer></v-spacer>
                  <v-col><v-switch v-model="theme" :label="`Theme: ${themeText}`" class="font-weight-bold display-1 mt-0 ml-4" color="primary"></v-switch></v-col>
                </v-row>
                <v-row>
                  <v-col cols="11">
                    <v-text-field id="displayName" v-model="displayName" name="displayName" label="Name" type="text" required></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-n5">
                  <v-col cols="11">
                    <v-text-field id="email" v-model="email" name="email" label="Email" type="email" required :rules="[rules.required, rules.email]"></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-n5">
                  <v-col cols="11">
                    <v-text-field id="phone" v-model="phone" name="phone" label="Phone" type="phone"></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-n5">
                  <v-col cols="11">
                    <v-text-field id="address" v-model="address" name="address" label="Address" type="address"></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-n5">
                  <v-col cols="11">
                    <v-text-field id="salesforceUserID" v-model="salesforceUserID" name="salesforceUserID" label="Salesforce User ID" type="text"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="!isEmailPasswordAuth" class="mt-n5 ml-n3 mb-3">
                  <v-col cols="8">
                    <v-text-field v-model="password" type="password" class="mt-n2 mb-n4" label="Set/Change Password for -Email/Password- Authentication" hint="*Not compulsory">
                    </v-text-field>
                  </v-col>
                  <v-col cols="3" class="text-end">
                    <v-btn :disabled="!password.length" class="mt-2" color="primary" :loading="setPasswordLoading" tile small @click="linkPasswordToExistingAccount()">
                      Set Password
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-n5 mr-n4 mb-n3 text-end">
                  <v-col cols="12" sm="11"
                    ><span
                      >Firebase ID: <b>{{ key }}</b></span
                    ></v-col
                  >
                </v-row>
                <v-card-actions>
                  <v-btn color="primary" class="white--text ml-n1" @click="saveAll">Save</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { auth, firebase } from '../../services/firebaseInit.js';
import userLogsService from '../../services/userLogsService.js';
import userService from '../../services/userService.js';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      storageAvatarUrl: '',
      name: '',
      email: '',
      salesforceUserID: '',
      password: '',
      oldpassword: '',
      newpassword: '',
      succes: false,
      succesOld: false,
      avatarUrl: '',
      date: '',
      phone: '',
      address: '',
      firstName: '',
      lastName: '',
      errors: '',
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        }
      },
      displayName: '',
      userID: '',
      isChangesSaved: false,
      changeImage: false,
      theme: false,
      key: '',
      setPasswordLoading: false,
      isEmailPasswordAuth: null
    };
  },
  computed: {
    ...mapGetters('users', {
      userStore: 'user',
      photoV: 'photoUrl',
      savedThemeType: 'getThemeType'
    }),
    photoUrl() {
      return this.photoV;
    },
    themeText() {
      return this.theme ? 'Dark' : 'Light';
    }
  },
  created() {
    this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
    let data0 = new Date();
    this.date = data0.toISOString() + ' GMT+00';
    const user = auth.currentUser;
    let key = (this.key = user.uid);
    this.userID = user.uid;
    this.name = user.displayName;
    this.displayName = user.displayName;
    this.email = user.email;

    userService
      .getById(key)
      .then(content => {
        let avatarUrlPath = content.avatar_image_url;
        this.avatarUrl = avatarUrlPath;
        this.phone = content?.phone ?? '';
        this.address = content?.address ?? '';
        this.firstName = content?.first_name ?? '';
        this.lastName = content?.last_name ?? '';
        (this.salesforceUserID = content?.salesforce_user_id ?? ''), (this.isEmailPasswordAuth = content?.is_email_password_auth ?? false);
        this.theme = content.themeType ?? false;
        this.$store.dispatch('hideLoader');
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        this.$store.dispatch('hideLoader');
      });
  },
  methods: {
    saveAll() {
      this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });
      const user = auth.currentUser;
      const uid = user.uid;
      this.$store
        .dispatch('users/saveProfileUpdatedData', {
          user: user,
          uid: uid,
          displayName: this.displayName,
          email: this.email,
          salesforceUserID: this.salesforceUserID,
          phone: this.phone,
          address: this.address,
          dateToInsert: this.date,
          theme: this.theme
        })
        .then(() => {
          this.isChangesSaved = true;
          if (this.theme !== this.savedThemeType) {
            location.reload();
          }
        })
        .catch(error => {
          console.log('Changing user data failed: ', error);
        })
        .finally(() => this.$store.dispatch('hideLoader'));
    },
    /* Deprecated - leave for example */
    updateName(e) {
      this.changeNameOnHeader();
      const user = auth.currentUser;
      user
        .updateProfile({
          displayName: this.name
        })
        .then(() => {
          const key = user.uid;
          const userName = user.displayName;
          userService
            .update(key, {
              name: userName
            })
            .then(async () => {
              const userLogs = await userLogsService.getById(key);
              const logs = Array.from(userLogs.logs);
              logs.push({
                action: 'update',
                time: this.date
              });
              const promises = [];
              promises.push(userLogsService.set(key, { logs: logs }));
              promises.push(userLogsService.set(key, { name: userName }));
              return Promise.all(promises);
            });
        })
        .catch(error => {
          if (error) {
            this.succesOld = true;
            this.errors = error.message;
          }
        });
    },
    /* Deprecated : login with Google is used */
    /* Return a promise*/
    updatePassword() {
      auth.currentUser
        .reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(auth.currentUser.email, this.oldpassword))
        .then(() => {
          auth.currentUser
            .updatePassword(this.newpassword)
            .then(() => {
              this.succes = true;
              this.oldpassword = '';
              this.newpassword = '';
            })
            .catch(error => {
              console.log('updatePasword has failed: ', error);
            });
        })
        .catch(error => {
          if (error) {
            this.succesOld = true;
            this.errors = error;
          }
        });
    },
    changePicture(e) {
      let file = e.target.files[0];

      this.$store
        .dispatch('users/changeUserPhoto', {
          file: file
        })
        .then(data => {
          console.log('Image changed successfully,path: ', data);
          this.changeImage = true;
        });
    },
    linkPasswordToExistingAccount() {
      this.setPasswordLoading = true;
      const email = this.email;
      const password = this.password;
      if (password) {
        var credential = firebase.auth.EmailAuthProvider.credential(email, password);
        auth.currentUser
          .linkWithCredential(credential)
          .then(usercred => {
            var user = usercred.user;
            console.log('Account linking success: ', user);
            this.setPasswordLoading = false;
            this.isChangesSaved = true;
          })
          .catch(error => {
            console.log('Account linking error: ', error);
            this.setPasswordLoading = false;
            this.errors = error;
            this.succesOld = true;
          });
      }
    }
  }
};
</script>
<style scoped>
.user-profile--change-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__media {
  height: 100px !important;
}

#user-profile {
  height: auto;
  background-color: lightblue;
}

.padding-class {
  padding-top: 50px;
}

ul {
  list-style: none;
}

.row-wrapper {
  padding: 50px 20px;
  border: 1px solid black;
  border-radius: 10px;
}

.passord-seaction {
  padding-top: 10px;
  border-top: 1px solid black;
}

.img-wrapper img {
  width: 80%;
}

input[type='file'] {
  display: none;
}

.form-control {
  width: 60%;
  display: inline-block;
}

.image-label {
  width: 150px;

  border: 1px solid black;
  border-radius: 2px;
  padding: 6px 12px;

  background-color: #2196f3;
  border: none;
  border-radius: 4px;
  color: white;
}

.image-label:hover {
  cursor: pointer;
}

.hidden-class {
  display: none;
}
</style>
