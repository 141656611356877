import XLSX from 'xlsx';

export const convertExcelToCsv = (file) => {
	return new Promise(resolve => {
		const reader = new FileReader()
		reader.readAsArrayBuffer(file)
		reader.onload = (e) => {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, {type: 'buffer'});
			const csv = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]], { blankrows: false });
			const filteredCSV = csv.replace(/,{2,}\n|,+$/g, '\n'); // remove empty columns from the end of rows
			const blob = new Blob([filteredCSV], { type: 'text/csv' });
			blob.name = `${file.name.substring(0, file.name.lastIndexOf('.'))}.csv`
			resolve(blob)
		}
	})
}