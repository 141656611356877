import { dbFS } from '@/services/firebaseInit';
import { cloudFunctionsGet, cloudFunctionsPost } from '../../modules/cloud-function-wrapper.js';

const state = {
  selectedCustomer: null,
  customerList: null,
  customerSearch: null
};

const getters = {
  getSelectedCustomer(state) {
    return state.selectedCustomer;
  },
  getCustomerList(state) {
    return state.selectedOrder;
  },
  getCustomerSearch(state) {
    return state.customerSearch;
  }
};

const mutations = {
  setSelectedCustomer(state, customer) {
    state.selectedCustomer = customer;
  },
  setCustomerList(state, list) {
    state.customerList = list;
  },
  setCustomerSearch(state, customer) {
    state.customerSearch = customer;
  }
};

const actions = {
  getCustomerPaymentMethods({ commit }, { customerId }) {
    const paymentMethods = [];
    return dbFS
      .collection('customers')
      .doc(customerId)
      .collection('payment_methods')
      .get()
      .then(paymentMetodsSnapshot => {
        if (!paymentMetodsSnapshot.empty) {
          paymentMetodsSnapshot.forEach(paymentMethodDoc => {
            let paymentMethod = paymentMethodDoc.data();
            paymentMethod.id = paymentMethodDoc.id;
            paymentMethods.push(paymentMethod);
          });
        }
        return Promise.resolve(paymentMethods);
      })
      .catch(error => {
        console.log('[ getCustomerPaymentMethods ] ERROR:', error);
        return Promise.reject(error);
      });
  },
  async getCustomerByCrmId({ commit, dispatch }, payload) {
    commit('setCustomerSearch', null);
    return dbFS
      .collection('customers')
      .where('crm_customer_id', 'in', [payload.crm_customer_id.toString(), parseInt(payload.crm_customer_id)])
      .limit(1)
      .get()
      .then(async customerSnapshot => {
        if (!customerSnapshot.empty) {
          let customer = customerSnapshot.docs[0].data();
          customer.id = customerSnapshot.docs[0].id;
          customer.payment_methods = await dispatch('getCustomerPaymentMethods', { customerId: customer.id });
          console.log('CUSTOMER ::::::: ', customer);
          commit('setCustomerSearch', customer);
          return Promise.resolve(customer);
        }
        return Promise.reject(`Customer not found with id: ${payload.crm_customer_id}`);
      })
      .catch(error => {
        console.log('[ getCustomerByCrmId ] ERROR:', error);
        return Promise.reject(error);
      });
  },
  getCustomerByUniqueID({ commit }, payload) {
    return dbFS
      .collection('customers')
      .where('customer_unique_id', '==', payload.customerUniqueID)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          return Promise.reject(`Customer not found with Unique ID: ${payload.customerUniqueID}`);
        } else {
          let customer = snapshot.docs[0].data();
          customer.id = snapshot.docs[0].id;
          return Promise.resolve(customer);
        }
      })
      .catch(error => {
        console.log('[ getCustomerByUniqueID ] ERROR:', error);
        return Promise.reject(error);
      });
  },
  getCustomerFromOspreyByCrmId({ commit }, payload) {
    return cloudFunctionsGet('/api/customers/osprey', {
      params: {
        osprey_id: payload.osprey_id
      }
    });
  },
  createCustomer({ commit }, payload) {
    return cloudFunctionsPost('/api/customers/legacy-order', payload);
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
