<template>
  <v-textarea
    v-model="inputValue"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
    :type="type"
    :value="value"
    :required="required"
    :readonly="readonly"
    outlined
    dense
    @input="sendToParent"
  ></v-textarea>
</template>

<script>
export default {
  props: ['label', 'value', 'rules', 'type', 'placeholder', 'required', 'readonly'],
  data() {
    return {
      inputValue: this.value
    };
  },
  methods: {
    sendToParent() {
      this.$emit('input', this.inputValue);
    }
  }
};
</script>
