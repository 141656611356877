<template>
  <v-dialog v-model="isNewMoverDialogOpened" scrollable persistent max-width="550">
    <v-card>
      <v-card-title class="justify-end"><v-icon @click="closeDialog">mdi-close</v-icon></v-card-title>
      <v-card-text class="pt-2 pl-8">
        <v-row class="subtitle-2">CSV Link:</v-row>
        <v-row class="mb-4">
          <v-col cols="12">
            <span class="font-weight-light grey--text text--darken-2">{{ csvLink }}</span>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5 mb-2 ml-n3">
          <v-col cols="12" sm="6">
            <v-text-field v-model.number="ospreyOrderId" label="Osprey Order ID" hint="Enter Osprey (CRM) Order ID" type="number"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-n3 ml-n3">
          <v-col cols="12" sm="6">
            <v-text-field v-model.number="mailDropId" label="Mail Drop ID" hint="Enter Mail Drop ID" type="number"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5 mb-2 ml-n3">
          <v-col cols="12" sm="6">
            <v-text-field v-model.number="dropNum" label="Drop Number" hint="Enter Drop Number" type="number"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="blue-grey lighten-4" class="mb-3" @click="openNewmoverCampaignInDMMTool">Open DMM Tool</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['isDialogOpened', 'csvLink', 'mapId'],
  data() {
    return {
      isNewMoverDialogOpened: false,
      ospreyOrderId: 0,
      mailDropId: 0,
      dropNum: 1
    };
  },
  watch: {
    isDialogOpened(value) {
      this.isNewMoverDialogOpened = value;
    }
  },
  methods: {
    openNewmoverCampaignInDMMTool() {
      const params = {
        start_date: this.$moment().subtract(30, 'days').format('YYYY-MM-DD'),
        end_date: this.$moment().format('YYYY-MM-DD'),
        crm_order_id: this.ospreyOrderId,
        crm_mail_drop_id: this.mailDropId,
        drop_num: this.dropNum,
        firestore_map_id: this.mapId
      };
      const urlReadyParameters = Object.entries(params)
        .map(property => property.join('='))
        .join('&');
      this.$router.push({ path: `/dmm-tool/?${urlReadyParameters}` });
    },
    closeDialog() {
      this.$emit('close');
    }
  }
};
</script>
