import { dbFS } from '@/services/firebaseInit';
import productCategoryService from '../../services/productCategoryService.js';

const state = {
  productCategories: [],
  isProductCategoriesLoading: false,
  productCategoryModel: [
    {
      label: 'Product Category Name',
      model: 'name',
      value: '',
      type: 'text',
      input_type: 'textfield',
      is_required: true,
      rule: [value => !!value || 'Product Category Name is required!']
    },
    {
      label: 'Product Category Image',
      model: 'image',
      value: '',
      type: 'file',
      input_type: 'fileinput',
      directory: 'product-categories',
      acceptedFileTypes: '.jpg, .jpeg, .png, .svg'
    }
  ],
  selectedModel: []
};

const getters = {
  getProductCategories(state) {
    return state.productCategories;
  },
  getProductCategoriesLoadingState(state) {
    return state.isProductCategoriesLoading;
  },
  getSelectedModel(state) {
    return state.selectedModel;
  },
  getProductCategoryModel(state) {
    return state.productCategoryModel;
  }
};

const mutations = {
  clearProductCategories(state) {
    state.productCategories = [];
  },
  clearModel(state) {
    state.productCategoryModel.map(model => (model.value = ''));
    state.selectedModel = [];
  },
  setProductCategories(state, payload) {
    state.productCategories = payload;
  },
  setSelectedModel(state, payload) {
    state.selectedModel = payload;
  },
  setProductCategoriesLoadingState(state, payload) {
    state.isProductCategoriesLoading = payload;
  }
};

const actions = {
  async initSelectedModel({ commit, getters, dispatch }, payload) {
    let productCategoryData = getters.getProductCategoryModel;
    const checkBoxes = await dispatch('products/getOrderProcesses', null, { root: true });
    productCategoryData = productCategoryData.concat(checkBoxes);

    if (payload) {
      productCategoryData.forEach((modelProperty, index) => {
        if (payload[modelProperty.model]) {
          productCategoryData[index].value = payload[modelProperty.model];
        }
      });
    }
    commit('setSelectedModel', productCategoryData);
  },
  getAllProductCategories({ commit }) {
    commit('setProductCategoriesLoadingState', true);
    return new Promise(resolve => {
      dbFS.collection('product_categories').onSnapshot(productCategorySnapshot => {
        commit('clearProductCategories');
        const productCategoryData = productCategorySnapshot.docs.map(product => {
          return { product_category_firebase_id: product.id, ...product.data() };
        });
        commit('setProductCategories', productCategoryData);
        commit('setProductCategoriesLoadingState', false);
        resolve(productCategoryData);
      });
    });
  },
  getProductCategoryById(_context, payload) {
    return productCategoryService.getById(payload);
  },
  addNewProductCategory(_context, payload) {
    return productCategoryService.add(payload);
  },
  editProductCategory(_context, payload) {
    return productCategoryService.update(payload.id, payload.data);
  },
  deleteProductCategory(_context, payload) {
    return productCategoryService.delete(payload.product_category_firebase_id);
  },
  async saveProductCategory({ dispatch }, payload) {
    const functionName = payload.id ? 'editProductCategory' : 'addNewProductCategory';
    const result = await dispatch(functionName, payload);
    return { message: `Product Category saved with id: ${result}` };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
