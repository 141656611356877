<template>
  <div class="text-center">
    <v-dialog v-model="isOpen" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <h3>Charge</h3>
          <v-spacer></v-spacer>
          <v-chip v-if="is_test_mode" class="ma-2" color="orange" text-color="white">Test mode</v-chip>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-alert v-if="errorMessage" type="error">
              {{ errorMessage }}
            </v-alert>
            <v-row>
              <v-col cols="12"> Balance: {{ orderBalanceFormatted }} </v-col>
              <v-col cols="12">
                <v-text-field v-model="amount" :error-messages="amountError" :max="orderBalance" type="number" label="Amount" required></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <v-select v-model="selectedPaymentMethodId" :items="paymentMethods" item-text="name" item-value="id" label="Payment methods" required></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="amountError !== '' || !selectedPaymentMethodId" color="success" @click="charge()">Charge</v-btn>
          <v-btn @click="isOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import formatNumber from 'numeral';
import { mapGetters } from 'vuex';

export default {
  components: {},
  props: ['open', 'is_test_mode', 'balance', 'customer', 'crm_order_id'],
  data() {
    return {
      amount: 0,
      isOpen: false,
      amountError: '',
      loading: false,
      errorMessage: '',
      selectedPaymentMethodId: ''
    };
  },
  computed: {
    ...mapGetters('users', {
      user: 'user'
    }),
    orderBalance() {
      return this.balance && this.balance.balance ? Math.abs(this.balance.balance) : 0;
    },
    orderBalanceFormatted() {
      return this.balance && this.balance.balance ? formatNumber(Math.abs(this.balance.balance)).format('$0,0.00') : 0;
    },
    paymentMethods() {
      if (this.customer && this.customer.payment_methods) {
        return this.customer.payment_methods.map(function (paymentMethod) {
          return { name: `${paymentMethod.card_type} - ${paymentMethod.last_four}`, id: paymentMethod.id };
        });
      }
      return [];
    }
  },
  watch: {
    open(isOpen) {
      this.isOpen = isOpen;
      if (isOpen) {
        if (this.balance && this.balance.balance) {
          this.amount = Math.abs(this.balance.balance);
        }
      }
    },
    isOpen(isOpen) {
      this.selectedPaymentMethodId = '';
      this.$emit('chargeDialogVisibilityChanged', isOpen);
    },
    balance(balance) {
      if (balance && balance.balance) {
        this.amount = Math.abs(balance.balance);
      }
    },
    amount(newAmount) {
      if (newAmount <= 0 || newAmount < 0.5 || newAmount > this.orderBalance) {
        this.amountError = 'Invalid amount!';
      } else {
        this.amountError = '';
      }
    },
    errorMessage(newMessage) {
      if (newMessage) {
        setTimeout(() => {
          this.errorMessage = '';
        }, 10000);
      }
    }
  },
  mounted() {
    console.log('Current User: ', this.user);
  },
  methods: {
    charge() {
      if (this.amount <= 0 || this.amount > this.orderBalance) {
        this.amountError = 'Invalid amount!';
      } else if (!this.selectedPaymentMethodId) {
        this.amountError = 'Payment method not selected!';
      } else {
        this.loading = true;
        this.$store
          .dispatch('stripe/chargeLegacyOrder', {
            is_test: this.is_test_mode,
            customer_db_id: this.customer.id,
            crm_order_id: this.crm_order_id,
            payment_method_db_id: this.selectedPaymentMethodId,
            amount: this.amount,
            agent_id: this.user.id
          })
          .then(result => {
            if (result?.data?.Success) {
              this.$emit('charged', { message: 'Order charged successfully!' });
            } else {
              this.errorMessage = 'Error while charging the order!';
            }
            return Promise.resolve();
          })
          .catch(error => {
            console.log('[ charge - api/payments/legacy-payment ] ERROR:', error);
            if (error?.response?.data) {
              this.message = error.response.data;
              this.errorMessage = error.response.data;
            } else {
              this.errorMessage = 'Unexpected error occured while charging the order!';
            }
            return Promise.reject(error);
          })
          .finally(() => (this.loading = false));
      }
    }
  }
};
</script>
