import { dbFS, storageRef } from '@/services/firebaseInit';
import httpClient from '@/modules/httpModule';
import { cloudFunctionsPost } from '../../modules/cloud-function-wrapper.js';
import orderSettingsService from '../../services/orderSettingsService';
import stampSettingsService from '../../services/stampSettingsService';

const actions = {
  executeHttpRequest(_context, payload) {
    let httpRequestConfig = {
      url: payload.url,
      headers: payload.headers,
      data: payload.data
    };
    if (payload.responseType) {
      httpRequestConfig.responseType = payload.responseType;
    }
    return httpClient.post(httpRequestConfig);
  },
  getGoEndpointSettings(_context) {
    return stampSettingsService.getAll().then(({ go_endpoints, valassis }) => ({ ...go_endpoints, ...valassis }));
  },
  getMapDataById(_context, payload) {
    return dbFS
      .collection('maps')
      .doc(payload.mapId)
      .get()
      .then(mapDataSnap => {
        return !mapDataSnap.empty ? mapDataSnap.data() : '';
      })
      .catch(error => {
        console.log(`Getting map data failed: ${error}`);
        return error;
      });
  },
  uploadMapToStorage(_context, payload) {
    let uploadPromises = [];
    let blob = new Blob([JSON.stringify(payload.mapData)], { type: 'application/json' });
    const fileRef = storageRef.child(`${payload.destinationUrl}/${payload.mapData.map_id}.json`);

    uploadPromises.push(
      cloudFunctionsPost('/downloadImg', {
        image: payload.image,
        destinationUrl: payload.destinationUrl,
        fileName: payload.fileName
      })
        .then(() => {
          return storageRef
            .child(`${payload.destinationUrl}/${payload.fileName}`)
            .getDownloadURL()
            .then(url => {
              return Promise.resolve({ mapUrl: url, fileName: payload.fileName });
            });
        })
        .catch(error => {
          console.log('[ Upload map image to Firebase storage has failed ]: ', error);
          return Promise.reject(error);
        })
    );

    uploadPromises.push(
      fileRef
        .put(blob)
        .then(result => {
          return result.ref.getDownloadURL().then(downloadUrl => {
            return Promise.resolve({ jsonUrl: downloadUrl });
          });
        })
        .catch(error => {
          console.log('[ Upload map to Firebase storage has failed ]: ', error);
          return Promise.reject(error);
        })
    );
    return Promise.all(uploadPromises).then(results => {
      return Promise.resolve({
        mapImgName: results && results[0] && results[0].fileName ? results[0].fileName : '',
        mapImgUrl: results && results[0] && results[0].mapUrl ? results[0].mapUrl : '',
        uploadedFileUrl: results && results[1] && results[1].jsonUrl ? results[1].jsonUrl : ''
      });
    });
  },
  updateMapData(_context, payload) {
    return dbFS
      .collection('maps')
      .doc(payload.mapId)
      .update(payload.data)
      .then(() => {
        return Promise.resolve({ status: 'success' });
      })
      .catch(error => {
        console.log('[ Updating map object with new map data failed! ]:', error);
        return Promise.reject({ status: 'error', message: error });
      });
  },
  getMarkupPrice(_context, payload) {
    return orderSettingsService.getSteps(payload.site_db_id, 'newMovers').then(steps => {
      const mapToolStep = steps.filter(step => step.stepId === 'map-tool');
      const markupPrice = mapToolStep.length > 0 && mapToolStep[0] && mapToolStep[0].markup_price ? parseFloat(mapToolStep[0].markup_price) : 0;
      return markupPrice >= 1 || markupPrice <= -1 ? markupPrice / 100 : markupPrice;
    });
  },
  async getZipList({ dispatch }, payload) {
    const endpointConfig = await dispatch('getGoEndpointSettings');
    console.log('[ Get ZipList Payload ]:', payload);
    return dispatch('executeHttpRequest', {
      url: endpointConfig.zip_list_service_url,
      headers: {
        'Content-Type': 'application/json',
        Key: endpointConfig.zip_list_service_key
      },
      data: payload
    });
  },
  getZipListConfig({ dispatch }, payload) {
    return dispatch('getZipList', {
      downloadUrl: payload.mapItem.uploadedFileUrl,
      type: 'saturation',
      isUrlFromAmazon: payload.mapItem.uploadedFileUrl.includes('amazon'),
      fileParsing: 'server',
      carrierRoutesOnly: true
    })
      .then(response => {
        const mapState = payload.mapItem && payload.mapItem.mapState ? payload.mapItem.mapState : {};
        const config = {
          carrier_routes: response.data.zipcrids,
          include_poboxes:
            response.data.hasOwnProperty('includeBox') && typeof response.data.includeBox === 'boolean'
              ? response.data.includeBox
              : mapState.hasOwnProperty('po_box') && mapState.po_box > 0,
          include_businesses:
            response.data.hasOwnProperty('includeBiz') && typeof response.data.includeBiz === 'boolean'
              ? response.data.includeBiz
              : mapState.hasOwnProperty('business') && mapState.business > 0,
          include_phones: payload.includePhones || true,
          include_names: payload.includeNames || true,
          seasonal: mapState.hasOwnProperty('seasonal') ? (!isNaN(mapState.seasonal) ? mapState.seasonal > 0 : mapState.seasonal) : true,
          format: 'csv',
          is_test: payload.hasOwnProperty('isTest') ? payload.isTest : false
        };
        console.log('[ Get ZipCrids config]:', config);
        return Promise.resolve(config);
      })
      .catch(error => {
        console.log('Getting Zipcrids from map failed:', error);
        return Promise.reject(error);
      });
  },
  async getSaturationCsvCounts({ dispatch }, payload) {
    const vnefConfig = await dispatch('getGoEndpointSettings');
    const bodyData = await dispatch('getZipListConfig', payload);

    return dispatch('executeHttpRequest', {
      url: 'https://vnef-api-go-mu5roxpxcq-ue.a.run.app/getCounts',
      headers: {
        'Content-Type': 'application/json',
        Key: vnefConfig.csv_service_key
      },
      data: {
        carrier_routes: bodyData.carrier_routes,
        include_businesses: bodyData.include_businesses,
        include_poboxes: bodyData.include_poboxes,
        seasonal: bodyData.seasonal,
        is_test: bodyData.is_test,
        format: 'csv'
      }
    })
      .then(response => {
        if (response.data) {
          console.log('[ Get Counts response]:', response.data);
          return Promise.resolve(response.data);
        }
        return Promise.reject({ message: 'Missing response data!' });
      })
      .catch(error => {
        console.log('Error: get saturation counts failed:', error.response.data);
        return Promise.reject(error.response.data);
      });
  },
  async getSaturationCsvData({ dispatch }, payload) {
    const bodyData = await dispatch('getZipListConfig', payload);
    const vnefConfig = await dispatch('getGoEndpointSettings');

    return dispatch('executeHttpRequest', {
      url: vnefConfig.csv_service_url,
      headers: {
        'Content-Type': 'application/json',
        Key: vnefConfig.csv_service_key
      },
      data: bodyData,
      responseType: 'blob'
    })
      .then(response => {
        console.log('[ Get addresses response]: ', response.status, response);
        if (response.data) {
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(response.data);
          link.download = `${payload.csvFileName}_${Number(new Date())}.csv`;
          link.click();
          return Promise.resolve();
        }
        return Promise.reject({ message: 'Missing response data!' });
      })
      .catch(error => {
        console.log('[ ERROR: getAddresses has failed ]:', error);
        return Promise.reject(error);
      });
  },
  async uploadLdpCsvData({ dispatch }, payload) {
    const mapData = await dispatch('getMapDataById', payload);
    const { data } = await dispatch('getZipList', {
      downloadUrl: mapData.uploadedFileUrl,
      fileParsing: false,
      isUrlFromAmazon: false
    });
    const atzCounts = data.map_data.map_state.atzs.map(atz => ({ zip: atz.geocode, quantity: atz.residential }));
    const crCounts = data.map_data.map_state.routes.map(route => ({ carrier_route: route.zipcrid, quantity: route.residential }));
    const bodyData = {
      carrier_routes: crCounts || [],
      atzs: atzCounts || [],
      cr_total: mapData.mapState?.crCount || 0,
      atz_total: mapData.mapState?.atzCount || 0,
      osprey_order_id: payload.ospreyOrderId,
      mail_order_ids: payload.mailDropIdList
    };

    console.log('[uploadLdpCsvData] payload : ', bodyData);
    const goServiceSettings = await dispatch('getGoEndpointSettings');

    return dispatch('executeHttpRequest', {
      url: goServiceSettings.go_upload_ldp_csv_url,
      headers: {
        'Content-Type': 'application/json',
        'api-key': goServiceSettings.go_upload_ldp_csv_api_key
      },
      data: bodyData,
      responseType: 'blob'
    })
      .then(response => {
        console.log('[ Upload LDP CSV Data response ]: ', response.status, response);
        return response;
      })
      .catch(error => {
        console.log('[ ERROR:  Upload LDP CSV Data has failed ]:', error);
        throw error;
      });
  },
  async generateCsvFromMapJson({ dispatch }, payload) {
    const endpointConfig = await dispatch('getGoEndpointSettings');

    return dispatch('executeHttpRequest', {
      url: endpointConfig.generate_csv_from_map_url,
      headers: {
        'Content-Type': 'application/json',
        Key: endpointConfig.zip_list_service_key
      },
      data: { downloadUrl: payload.downloadUrl },
      responseType: 'blob'
    })
      .then(response => {
        console.log('[ Generate csv from Json map response]: ', response.status, response);
        if (response.data) {
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(response.data);
          link.download = `${payload.csvFileName}_${Number(new Date())}.csv`;
          link.click();
          return Promise.resolve();
        }
        return Promise.reject({ message: 'Missing response data!' });
      })
      .catch(error => {
        console.log('[ ERROR: getAddresses has failed ]:', error);
        return Promise.reject(error);
      });
  },
  async generateExcelFileEddm({ dispatch }, payload) {
    const endpointConfig = await dispatch('getGoEndpointSettings');
    return httpClient
      .post({
        url: endpointConfig.eddm_excel_service_url,
        headers: {
          'Content-Type': 'application/json',
          Key: endpointConfig.zip_list_service_key
        },
        data: {
          downloadUrl: payload.downloadUrl,
          orderID: payload.orderID,
          isUrlFromAmazon: payload.isUrlFromAmazon,
          bundleValue: payload.bundleValue,
          responseFormat: payload.fileType
        },
        responseType: 'blob'
      })
      .then(response => {
        const blob = new Blob([response.data], { type: payload.fileType === 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${payload.orderID}_FM_IMPORTREADY_ALLROUTES.${payload.fileType === 'excel' ? 'xlsx' : 'csv'}`;
        link.click();
        return Promise.resolve();
      })
      .catch(error => {
        console.log('[ generateExcelFileEddm ] ERROR:', error?.response?.data ?? error);
        return Promise.reject(error);
      });
  }
};

export default {
  namespaced: true,
  actions
};
