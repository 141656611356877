<template>
  <div>
    <stamp-data-table :headers="headers" :data="list" :is-footer-hidden="true" item-key="product_or_service" custom-class="fullWidth" />
    <v-row>
      <v-col cols="12" md="4">
        <span class="bold">{{ totalPrices.product_or_service }}</span>
      </v-col>
      <v-col cols="12" md="4">
        <span class="bold">{{ totalPrices.price_per_piece }}</span>
      </v-col>
      <v-col cols="12" md="4">
        <span class="bold">{{ totalPrices.total }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import DataTable from '@/components/common/DataTable.vue';

  export default {
    components: { 'stamp-data-table': DataTable },
    filters: {
      currency: (num, currency, numOfDecimal) => {
        return currency + parseFloat(num).toFixed(numOfDecimal)
      }
    },
    props: ['quote'],
    data () {
      return {
        headers: [{
          text: 'PRODUCT / SERVICE',
          value: 'product_or_service'
        }, {
          text: 'PRICE PER PIECE',
          value: 'price_per_piece'
        }, {
          text: 'TOTAL',
          value: 'total'
        }]
      }
    },
    computed: {
      list () {
        const pricing = []

        pricing.push({
          product_or_service: 'Printing',
          price_per_piece: this.$options.filters.currency(this.printingTotal, '$', 3),
          total: this.$options.filters.currency(this.printingTotal * this.quote.quantityInt, '$', 3)
        })
        pricing.push({
          product_or_service: 'Bindery',
          price_per_piece: this.$options.filters.currency(this.binderyTotal, '$', 3),
          total: this.$options.filters.currency(this.binderyTotal * this.quote.quantityInt, '$', 3)
        })
        pricing.push({
          product_or_service: 'Design',
          price_per_piece: this.$options.filters.currency(this.designTotal / this.quote.quantityInt, '$', 3),
          total: this.$options.filters.currency(this.designTotal, '$', 3)
        })
        pricing.push({
          product_or_service: 'Mailing Service',
          price_per_piece: this.$options.filters.currency(this.mailingServiceTotal, '$', 3),
          total: this.$options.filters.currency(this.mailingServiceTotal * this.quote.quantityInt, '$', 3)
        })
        pricing.push({
          product_or_service: 'Postage',
          price_per_piece: this.$options.filters.currency(this.quote.postage.value, '$', 3),
          total: this.$options.filters.currency(this.quote.postage.value * this.quote.quantityInt, '$', 3)
        })
        pricing.push({
          product_or_service: 'Shipping',
          price_per_piece: this.$options.filters.currency(this.shippingTotal / this.quote.quantityInt, '$', 3),
          total: this.$options.filters.currency(this.shippingTotal, '$', 3)
        })
        pricing.push({
          product_or_service: 'Promo Discount',
          price_per_piece: this.$options.filters.currency(this.quote.discountPrice / this.quote.quantityInt, '$', 3),
          total: this.$options.filters.currency(this.quote.discountPrice, '$', 3)
        })

        return pricing
      },
      totalPrices () {
        return {
          product_or_service: 'TOTAL',
          price_per_piece: this.$options.filters.currency(this.totalPerPiece, '$', 2),
          total: this.$options.filters.currency(this.total, '$', 2)
        }
      },
      printingTotal () {
        const turnaroundOption = this.quote.turnaroundOption !== undefined ? this.quote.turnaroundOption.value : 0
        const stockOption = this.quote.stockOption !== undefined ? this.quote.stockOption.value : 0
        const coatingOption = this.quote.coatingOption !== undefined ? this.quote.coatingOption.value : 0
        return this.quote.productData.price_per_piece + turnaroundOption + stockOption + coatingOption
      },
      binderyTotal () {
        let result = 0
        for (let i in this.quote.binderyOption) {
          result += this.quote.binderyOption[i].value
        }
        return result
      },
      designTotal () {
        return this.quote.designOption.value === undefined ? 0 : this.quote.designOption.value
      },
      mailingServiceTotal () {
        const tabs2 = this.quote.productData.mailing_services !== undefined ? this.quote.productData.mailing_services.tabs : { label: '', value: 0 }
        const mailingServicePrice = this.quote.mailingService.value !== undefined ? this.quote.mailingService.value : 0
        return this.quote.mailingServiceTabs ? tabs2.value + mailingServicePrice : mailingServicePrice
      },
      shippingTotal () {
        if (this.quote.productData !== '' && this.quote.productData.shipping_services !== undefined && this.quote.productData.shipping_services.eddm_shipping !== undefined && this.quote.productData.shipping_services.eddm_shipping.value !== 'N/A') {
          return this.quote.productData.shipping_services.eddm_shipping.value * this.quote.quantityInt
        } else if (this.quote.shippingOption !== undefined && this.quote.shippingOption !== '' && Object.keys(this.quote.shippingOption).length > 0) {
          return this.quote.shippingOption.value
        }
        return 0
      },
      totalPerPiece () {
        let totalPerPiecePrice = 0
        if (this.quote.productData !== '') {
          totalPerPiecePrice = (this.printingTotal + this.binderyTotal + (this.designTotal / this.quote.quantityInt) + this.mailingServiceTotal + this.quote.postage.value + (parseInt(this.shippingTotal) / this.quote.quantityInt))

          let discountPricePerPiece = this.quote.discountPrice / this.quote.quantityInt
          if (totalPerPiecePrice - Math.abs(discountPricePerPiece) < 0) {
            return 0
          } else {
            totalPerPiecePrice = totalPerPiecePrice - Math.abs(discountPricePerPiece)
          }
        }

        return totalPerPiecePrice
      },
      total () {
        let totalPrice = 0

        if (this.quote.productData !== '') {
          totalPrice = (this.printingTotal * this.quote.quantityInt) + (this.binderyTotal * this.quote.quantityInt) + this.designTotal + (this.mailingServiceTotal * this.quote.quantityInt) + (this.quote.postage.value * this.quote.quantityInt) + parseInt(this.shippingTotal)
        }

        if (totalPrice - Math.abs(this.quote.discountPrice) < 0) {
          return 0
        } else {
          totalPrice = totalPrice - Math.abs(this.quote.discountPrice)
        }

        return totalPrice
      }
    }
  }
</script>

<style scoped>
  .fullWidth {
    width: 100%
  }

  .bold {
    font-weight: bold
  }
</style>
