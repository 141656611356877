import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class SmsTemplateService extends FirestoreModel {
  constructor() {
    super(collections.sms_templates);
  }
}

export default new SmsTemplateService();
