<template>
  <v-dialog v-model="isLoaderVisible" persistent width="350">
    <v-card color="primary" dark class="pt-3">
      <v-card-text>
        <p class="text-center christmasDialogTitle">{{ loadingMessage }}</p>
        <v-progress-linear indeterminate color="white"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      isLoaderVisible: false,
      message: ''
    };
  },
  computed: {
    loadingMessage() {
      return this.message || 'Loading... Please wait';
    },
    loader() {
      return this.$store.getters.getLoaderGlobal;
    }
  },
  watch: {
    'loader.loading': function (loading) {
      this.isLoaderVisible = loading > 0;
    },
    'loader.message': function (message) {
      this.message = message;
    }
  }
};
</script>