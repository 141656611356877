<template>
  <v-container fluid>
    <v-row>
      <v-btn icon large @click="navigateBack()"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <span class="ml-3 pt-1 title font-weight-regular">Import products from default</span>
    </v-row>
    <v-divider class="my-4"></v-divider>

    <Products :isImportMode="true"></Products>
  </v-container>
</template>

<script>
import Products from './Products.vue';

import { mapGetters } from 'vuex';

export default {
  components: {
    Products
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      product: 'products/getCurrentProduct'
    }),
    selectedSite() {
      return this.$route.params.siteId;
    }
  },
  methods: {
    navigateBack() {
      this.$router.replace(`/products/sites/${this.selectedSite}`);
    }
  }
};
</script>
