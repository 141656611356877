<template>
  <v-chip small :color="chipConfig.color" text-color="white"> {{ chipConfig.text }} </v-chip>
</template>

<script>
export default {
  props: {
    transaction: { type: Object, required: true }
  },
  computed: {
    chipConfig() {
      const item = this.transaction;
      const caseMap = {
        balance_adjustment: () => ({ color: 'deep-orange accent-2', text: 'Balance Adjustment' }),
        adjustment: () => ({ color: 'deep-orange accent-2', text: 'Osprey Adjustment' }),
        pay_later: () => ({ color: 'orange', text: 'Pay later' }),
        stripe: () => {
          if (item.refunds) {
            return { color: 'blue-grey lighten-2', text: this.getRefundText(item) };
          } else if (item.card_stripe_id) {
            return { color: 'green', text: 'Credit card' };
          } else if (item.bank_account_stripe_id) {
            return { color: 'green', text: 'ACH' };
          }
        },
        corporate: () => ({ color: 'blue', text: 'Bill Corporate' }),
        stripe_pending: () => ({ color: 'grey', text: 'ACH Pending' }),
        default: () => ({ color: 'grey', text: item.paymentType })
      };
      return caseMap?.[item.paymentType]?.() || caseMap.default();
    }
  },
  methods: {
    getRefundText(transaction) {
      let text = '';
      if (transaction.card_stripe_id) {
        if (transaction.refunds?.length) {
          text = this.refundsAmountCheck(transaction);
        }
      } else if (transaction.bank_account_stripe_id) {
        text = transaction.refunds.find(item => item.status === 'pending') ? 'Refund pending' : this.refundsAmountCheck(transaction);
      }
      return text;
    },
    refundsAmountCheck(transaction) {
      let total = 0;
      transaction.refunds.forEach(refund => {
        total += refund.amount || 0;
      });
      return transaction.amount.toFixed(2) === total.toFixed(2) ? 'Full refund' : 'Partial refund';
    }
  }
};
</script>
