<template>
  <div class="site-table">
    <v-row>
      <v-toolbar flat class="site-table--header site-table--lists">
        <v-col class="pl-0">
          <v-toolbar-title class="headline">
            <a class="grey--text text--darken-3" @click="clearFilters()">Customers</a>
          </v-toolbar-title>
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details clearable @keydown="searchKeydown()"></v-text-field>
          <p v-if="showMinLenghtSearchValue" class="caption mt-2">Please enter min 3 characters to begin searching!</p>
        </v-col>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-if="isUserSuperAdmin" icon class="ml-5 mr-n3" v-on="on" @click="openCustomerTools()"><v-icon>mdi-tools</v-icon></v-btn>
          </template>
          <span>Customer Tools</span>
        </v-tooltip>
      </v-toolbar>
    </v-row>
    <div class="site-table mt-n8">
      <stamp-data-table
        :headers="headers"
        :data="customers"
        :is-loading="isLoading"
        :is-items-per-page-disabled="true"
        :total-server-items-nr="totalCustomers"
        item-key="firebase_customer_id"
        :sort-desc="true"
        sort-by="created_at"
        :current-page="currentPage"
        @options="optionsHandler"
      >
        <template #[`item.avatar`]="{ item }">
          <v-avatar color="cyan darken-2" size="36px">
            <span class="white--text title">{{ filterFirstName(item.first_name, item.last_name) }}</span>
          </v-avatar>
        </template>
        <template #[`item.name`]="{ item }">{{ item.first_name }}&nbsp;{{ item.last_name }}</template>
        <template #[`item.crm_customer_id`]="{ item }">
          <v-tooltip v-if="item.crm_customer_id" bottom>
            <template v-slot:activator="{ on }">
              <a :href="`${osprey_endpoint}/crm/customer/view.cfm?id=${item.crm_customer_id}`" target="_blank" class="cyan--text text--darken-3" v-on="on">
                {{ item.crm_customer_id }}
              </a>
            </template>
            <span>View customer in Osprey</span>
          </v-tooltip>
        </template>
        <template #[`item.company`]="{ item }">{{ item.company ? item.company : '-' }}</template>
        <template #[`item.site_id`]="{ item }"
          ><v-tooltip bottom open-delay="200">
            <template v-slot:activator="{ on }">
              <span class="cyan--text text--darken-3 siteFullName" v-on="on" @mouseover="getSiteFullName(item)" @mouseleave="siteFullName = null">
                {{ item.site_id }}
              </span>
            </template>
            <v-progress-circular v-if="!siteFullName" indeterminate color="white"></v-progress-circular>
            <div v-else class="subtitle-1">
              <span>{{ siteFullName.name }}</span>
              <br />
              <span>{{ siteFullName.url }}</span>
            </div>
          </v-tooltip>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn text color="cyan darken-3" :to="`/customers/view/${item.firebase_customer_id}`">Details</v-btn>
        </template>
      </stamp-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from '@/components/common/DataTable.vue';

export default {
  components: { 'stamp-data-table': DataTable },
  data() {
    return {
      search: '',
      customers: [],
      totalCustomers: null,
      headers: [
        { text: '', value: 'avatar', align: 'right', sortable: false, width: '10' },
        { text: 'Name', value: 'name', sortable: false, align: 'start' },
        { text: 'Email', value: 'email', sortable: false, align: 'start' },
        { text: 'Osprey Customer ID', value: 'crm_customer_id', sortable: false, align: 'center' },
        { text: 'Company', value: 'company', sortable: false },
        { text: 'WP ID', value: 'wp_user_id', sortable: false },
        { text: 'Brand ID', value: 'brand_id', sortable: false, align: 'center' },
        { text: 'Site ID', value: 'site_id', sortable: false, align: 'center' },
        { text: 'Created', value: 'created_at', sortable: false, align: 'center' },
        { text: 'Action', value: 'actions', sortable: false, align: 'center' }
      ],
      isLoading: false,
      setHitsPerPage: 15,
      options: {},
      sortBy: 'created_at',
      descending: true,
      timeout: null,
      showMinLenghtSearchValue: false,
      osprey_endpoint: process.env.VUE_APP_OSPREY_ENDPOINT,
      dataLoadedFromUrl: false,
      commonFilter: '',
      currentPage: 1,
      siteFullName: null
    };
  },
  computed: {
    ...mapGetters('users', {
      userAccessLevel: 'userAccessLevel'
    }),
    isUserSuperAdmin() {
      return this.userAccessLevel && parseInt(this.userAccessLevel.value) === 100;
    },
    checkSearchChange() {
      if (this.search && this.search.length > 2) {
        this.routeHandler('search', this.search);
        return this.search;
      }
      return '';
    }
  },
  watch: {
    checkSearchChange(val) {
      if (!val) {
        this.showMinLenghtSearchValue = false;
        this.routeHandler('search', '');
        if (!Object.keys(this.$route.query).length) {
          this.initResult();
        } else {
          this.setDataFromUrl();
        }
      } else if (val.length && val.length < 3) {
        this.showMinLenghtSearchValue = true;
      } else {
        this.showMinLenghtSearchValue = false;
        this.timeout = setTimeout(() => {
          this.options.page = 1;
          this.currentPage = 1;
          this.getCustomersFromAlgolia(this.commonFilter, val, 0);
        }, 800);
      }
    }
  },
  async created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.setDataFromUrl();
      this.dataLoadedFromUrl = true;
    } else {
      this.initResult();
    }
  },
  methods: {
    getCustomersFromAlgolia(filter, searchValue, page) {
      this.isLoading = true;
      const indexName = `customers${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`;
      return this.$store
        .dispatch('algolia/getDataFromAlgolia', {
          indexName,
          filter,
          searchValue: searchValue ?? '',
          page: page && page > 1 ? page - 1 : 0
        })
        .then(content => {
          this.totalCustomers = content.nbHits;
          this.customers = content.hits;
          return Promise.resolve();
        })
        .catch(error => {
          console.log('[ getCustomersFromAlgolia ERROR ]:', error, error.debugData);
          this.$store.dispatch('showAlert', { type: 'error', message: error });
          return Promise.reject(error);
        })
        .finally(() => (this.isLoading = false));
    },
    async initResult() {
      const d = new Date();
      const date = Math.floor(d.setDate(d.getDate()) / 1000);
      await this.getCustomersFromAlgolia(`creationDateTimestamp < ${date}`);
      this.currentPage = 1;
      this.options.page = 1;
    },
    clearFilters() {
      this.search = '';
      this.commonFilter = '';
      this.routeHandler('search', '');
      this.routeHandler('startDate', '');
      this.routeHandler('endDate', '');
      this.initResult();
    },
    async setDataFromUrl() {
      const routeQuery = this.$route.query;
      const firstDateDP = routeQuery?.startDate ?? '';
      this.routeHandler('startDate', firstDateDP);
      const endDateDP = routeQuery?.endDate ?? '';
      this.routeHandler('endDate', endDateDP);
      this.search = routeQuery?.search ?? '';
      this.routeHandler('search', this.search);

      const startDateNumber = Number(new Date(this.$moment(firstDateDP).format('L')));
      const valStart = parseInt(startDateNumber / 1000);
      const endDateNumber = Number(new Date(this.$moment(endDateDP).format('L')));
      let val1 = parseInt(endDateNumber / 1000);
      const oneDay = 24 * 60 * 60 - 1;
      let valEnd = val1 + oneDay;

      if (valStart && valEnd && !isNaN(valStart) && !isNaN(valEnd)) {
        if (valStart > valEnd) {
          this.initResult();
        } else {
          this.commonFilter = `creationDateTimestamp:${valStart} TO ${valEnd}`;
          await this.getCustomersFromAlgolia(this.commonFilter, this.search, 0);
        }
      }
    },
    routeHandler(paramName, paramValue) {
      let query = Object.assign({}, this.$route.query);
      if (paramValue === undefined || paramValue === '' || paramValue === null) {
        if (Object.keys(query).includes(paramName)) {
          delete query[paramName];
          this.$router.replace({ query });
        }
      } else {
        if (JSON.stringify({ [paramName]: query[paramName] }) !== JSON.stringify({ [paramName]: paramValue })) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              [paramName]: paramValue
            }
          });
        }
      }
    },
    searchKeydown() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.checkSearchChange === '' || (this.checkSearchChange && this.checkSearchChange.length > 2)) {
          this.routeHandler('search', this.checkSearchChange);
        }
      }, 800);
    },
    getSiteFullName(item) {
      this.$store.dispatch('om_orders/getSiteFullNameById', { siteId: item.firebase_site_id }).then(res => {
        this.siteFullName = {
          name: res.name ?? '',
          url: res.url ?? ''
        };
      });
    },
    filterFirstName(firstName, lastName) {
      const firstChar = firstName ? firstName.charAt(0).toUpperCase() : '';
      const secondChar = lastName ? lastName.charAt(0).toUpperCase() : '';
      return firstChar + secondChar;
    },
    async optionsHandler(options) {
      this.options = options;
      if (this.options.page !== this.currentPage) {
        await this.getCustomersFromAlgolia(this.commonFilter, this.search, this.options.page);
        this.currentPage = this.options.page;
      }
    },
    openCustomerTools() {
      this.$router.push({ path: `/customers/customer-tools` });
    }
  }
};
</script>
