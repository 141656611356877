import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class designListService extends FirestoreModel {
  constructor() {
    super(collections.designList);
  }
}

export default new designListService();
