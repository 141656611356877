<template>
  <v-container fluid>
    <v-dialog v-model="isDropDetailDialogOpened" max-width="550">
      <v-card>
        <v-card-title class="justify-space-between">
          <span class="title">Drop Information</span>
          <v-btn icon @click="closeDropDetailDialog()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pb-8">
          <v-row class="justify-space-around mb-2">
            <v-col v-if="selectedDrop.thumbnail_front_uid" cols="6" class="mt-2">
              <v-row class="justify-center mb-2"><span class="subtitle-2">Front</span></v-row>
              <v-img :src="getArtworkThumbnailSource(selectedDrop.thumbnail_front_uid)" class="showPointerOnHover" @click="openArtworkInNewTab(selectedDrop.thumbnail_front_uid)">
              </v-img>
            </v-col>
            <v-col v-if="selectedDrop.thumbnail_back_uid" cols="6" class="mt-2">
              <v-row class="justify-center mb-2"><span class="subtitle-2">Back</span></v-row>
              <v-img :src="getArtworkThumbnailSource(selectedDrop.thumbnail_back_uid)" class="showPointerOnHover" @click="openArtworkInNewTab(selectedDrop.thumbnail_back_uid)">
              </v-img>
            </v-col>
          </v-row>
          <template v-for="(dropData, index) in Object.entries(selectedDrop)">
            <v-row v-if="!dropData[0].includes('thumbnail')" :key="index" class="mb-n6 ml-0">
              <v-col cols="6">
                <span v-if="!dropData[0].includes('thumbnail')" class="subtitle-2">{{ formatPropertyName(dropData[0]) }}</span>
              </v-col>
              <v-col cols="6" class="body-2 grey--text text--darken-3">
                <span v-if="dropData[0] === 'date'">{{ formatDate(dropData[1]) }}</span>
                <span v-else-if="dropData[0].includes('price')">{{ formatPrice(dropData[1]) }}</span>
                <a v-else-if="dropData[0] === 'csv_storage_url'" :href="dropData[1]">Download Address List CSV<v-icon color="primary" class="pl-1">mdi-download</v-icon></a>
                <span v-else>{{ dropData[1] }}</span>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card v-if="isDropInfoPanelOpened" class="elevation-0 mt-n6" :loading="isDeliveryDatesLoading">
      <template slot="progress">
        <v-progress-linear color="cyan darken-2" indeterminate></v-progress-linear>
      </template>
      <v-card-text v-if="isAnyDropExecuted" class="mb-n3">
        <template v-for="(drop, index) in automatedDrops">
          <v-row v-if="drop.date" :key="`${index}-${drop.date}`">
            <v-col cols="2" sm="1">
              <v-row class="justify-center align-center mt-4">
                <v-tooltip v-if="isDropStatusSuccess(drop.drop_status)" bottom>
                  <template #activator="{ on }"><v-icon color="green" large v-on="on">mdi-email-check-outline</v-icon></template>
                  <span>Executed Drop</span>
                </v-tooltip>
                <v-tooltip v-else-if="isDropCanceled(drop.drop_status)" bottom>
                  <template #activator="{ on }"><v-icon color="black" large v-on="on">mdi-email-off-outline</v-icon></template>
                  <span>Drop Execution Canceled</span>
                </v-tooltip>
                <v-tooltip v-else-if="isDropStatusFailed(drop.drop_status)" bottom>
                  <template #activator="{ on }"><v-icon color="red" large v-on="on">mdi-email-remove-outline</v-icon></template>
                  <span>Drop Execution Failed</span>
                </v-tooltip>
                <v-tooltip v-else-if="isDropPaused(drop.drop_status, index)" bottom>
                  <template #activator="{ on }"><v-icon color="orange darken-2" large v-on="on">mdi-email-edit-outline</v-icon></template>
                  <span>Paused Drop</span>
                </v-tooltip>
                <v-progress-circular v-else-if="isDropInProgress(drop.drop_status)" indeterminate color="cyan darken-3"></v-progress-circular>
                <v-tooltip v-else bottom>
                  <template #activator="{ on }"><v-icon color="blue" large v-on="on">mdi-email-send-outline</v-icon></template>
                  <span v-if="isDropDALSubmitted(drop.drop_status)">DAL Submitted</span>
                  <span v-else>Scheduled Drop</span>
                </v-tooltip>
              </v-row>
            </v-col>
            <v-col cols="10" sm="8" class="subtitle-1">
              <v-row class="justify-space-between mt-3 mb-3">
                <v-col cols="12" sm="4" class="subtitle-2">
                  <v-row :class="`${!drop.qty ? 'mt-0' : ''}`">
                    <span v-if="!isLegacyNewMoversOrder"
                      >Mail Drop <b>{{ drop.drop_num }} of {{ deliveryDates.length }}</b></span
                    >
                    <span v-else
                      >Mail Drop <strong>{{ drop.drop_num }}</strong></span
                    >
                  </v-row>
                  <v-row v-if="drop.qty"
                    ><span
                      >Quantity: <strong>{{ drop.qty }}</strong></span
                    ></v-row
                  >
                </v-col>
                <v-col cols="12" sm="8">
                  <v-row>
                    <span class="subtitle-2 font-weight-regular"
                      >Mail Drop ID: <strong>{{ drop.mail_drop_id }}</strong></span
                    >
                  </v-row>
                  <v-row v-if="!isDropStatusFailed(drop.drop_status, drop.date)">
                    <span v-if="isDropPaused(drop.drop_status, index)" class="subtitle-2 font-weight-regular">
                      <i>Drop will be re-scheduled when the campaign is reactivated</i>
                    </span>
                    <span v-else-if="isDropInProgress(drop.drop_status)" class="subtitle-2 blue-grey--text"><i>Drop execution in progress... (Will be mailed on: <strong>{{ formatDate(drop.date) }}</strong>)</i></span>
                    <span v-else class="subtitle-2 font-weight-regular">
                      {{ drop.drop_status ? 'Execution Date' : 'Scheduled Date' }}:
                      <strong>{{ formatDate(drop.date) }}</strong>
                    </span>
                  </v-row>
                  <v-row v-else>
                    <span v-if="drop.error_message && !isErrorMsgExpanded(index)" class="showPointerOnHover pr-10" @click="expandErrorMessage(index)">
                      Message: {{ drop.error_message.length > 20 ? `${drop.error_message.substring(0, 40)}...` : drop.error_message }}
                    </span>
                    <template v-if="isErrorMsgExpanded(index)">
                      <v-expand-transition
                        ><span>Message: {{ drop.error_message }}</span></v-expand-transition
                      >
                      <v-expand-transition
                        ><span v-if="drop.error_code">Error Code: {{ drop.error_code }}</span></v-expand-transition
                      >
                    </template>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex align-center">
              <v-row v-if="checkSpecialNewmoversStatus(drop)" class="justify-center mr-0">
                <span v-if="isInProgressPast(drop.drop_status, drop.date)" class="subtitle-2 blue-grey--text">
                  Drop may have failed, please check the drop details and retry the drop execution.
                </span>
                <v-btn block small class="white--text caption font-weight-medium mb-1" color="cyan darken-2" @click="showConfirmRetryDropDialog(drop)">
                  <v-icon class="mr-2">mdi-restore</v-icon>Retry Drop Execution
                </v-btn>
                <v-btn block small class="white--text caption font-weight-medium mb-3" color="primary" @click="executeWithDmmTool(drop)">
                  <v-icon class="mr-2">mdi-send</v-icon>Execute with DMM Tool
                </v-btn>
              </v-row>
              <v-row v-else-if="isDropStatusSuccess(drop.drop_status)" class="justify-center mr-0">
                <v-btn outlined block color="cyan darken-3" small @click="openDropDetailDialog(drop)">View Drop Details</v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-divider v-if="showDivider(index)" :key="index" />
        </template>
        <v-row v-if="isLoadMoreButtonVisible" class="justify-center mb-3 mt-n2">
          <v-btn outlined small class="caption font-weight-medium" color="grey darken-1" min-width="130" @click="loadMoreDrops()">Load More ...</v-btn>
        </v-row>
        <template v-if="totalMailedDrops > 0">
          <v-divider class=""></v-divider>
          <v-row class="mt-3 mb-0">
            <v-col cols="2" sm="1" class="">
              <v-row class="justify-center">
                <v-icon color="cyan darken-3" size="32">mdi-send-check</v-icon>
              </v-row>
            </v-col>
            <v-col cols="10" class="mt-n2 ml-n3">
              <span class="subtitle-2"
                >Total Mailed: <b>{{ totalMailedDrops }}</b></span
              >
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <span v-else-if="!isAnyDropExecuted && !isDeliveryDatesLoading" class="ml-5 grey--text text--darken-2 subtitle-1"
        ><v-icon color="blue" class="mr-2">mdi-information</v-icon>No mail drop executed or scheduled yet!</span
      >
    </v-card>
    <confirmation-dialog
      :isConfirmationDialogOpened="isConfirmRetryDropShown"
      :action="confirmRetryDropMessage"
      @closeConfirmationDialog="cancelDropRetry"
      @actionConfirmed="confirmRetryDrop"
    >
    </confirmation-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
const numeral = require('numeral');
import ConfirmationDialog from '../orders/orderDialogs/ConfirmationDialog.vue';

export default {
  components: {
    'confirmation-dialog': ConfirmationDialog
  },
  props: ['isPanelOpened', 'firebaseOrderId', 'orderData', 'isLiveMode'],
  data() {
    return {
      isDropInfoPanelOpened: this.isPanelOpened,
      isDeliveryDatesLoading: false,
      expandedErrorMessages: [],
      isDropDetailDialogOpened: false,
      selectedDrop: {},
      dropsNrToShow: 6,
      isConfirmRetryDropShown: false,
      confirmRetryDropMessage: '',
      selectedDropToRetry: null
    };
  },
  computed: {
    ...mapGetters('om_orders', {
      deliveryDates: 'getTriggeredOrderDelivery'
    }),
    isLegacyNewMoversOrder() {
      return !this.orderData.automated;
    },
    isAnyDropExecuted() {
      return this.deliveryDates && this.deliveryDates.length && this.deliveryDates.filter(drop => drop.date).length > 0;
    },
    isSubscriptionPaused() {
      return this.orderData.automated && this.orderData.automated.campaign_status === 'paused';
    },
    automatedDrops() {
      return this.deliveryDates.slice(0, this.dropsNrToShow);
    },
    isLoadMoreButtonVisible() {
      return this.deliveryDates.length && this.dropsNrToShow < this.deliveryDates.length;
    },
    totalMailedDrops() {
      return this.deliveryDates.map(drop => (drop.qty ? parseInt(drop.qty) : 0)).reduce((previous, current) => previous + current, 0);
    },
    isNewMoversOrder() {
      return this.orderData?.order_type === 'newmovers';
    }
  },
  watch: {
    isPanelOpened: {
      handler: function (value) {
        this.isDropInfoPanelOpened = value;
        this.dropsNrToShow = 6;
        if (this.isDropInfoPanelOpened) {
          this.initDropsInformation();
        }
      },
      immediate: true
    }
  },
  methods: {
    checkSpecialNewmoversStatus(drop) {
      return (this.isDropStatusFailed(drop.drop_status) || this.isInProgressPast(drop.drop_status, drop.date)) && this.isNewMoversOrder;
    },
    async initDropsInformation() {
      if (this.firebaseOrderId) {
        this.isDeliveryDatesLoading = true;
        await this.$store.dispatch('om_orders/triggerOrderDataDelivery', { orderId: this.firebaseOrderId });
        this.isDeliveryDatesLoading = false;
      }
    },
    showConfirmRetryDropDialog(drop) {
      this.selectedDropToRetry = drop;
      const newDropDate = this.$moment().add(2, 'days').format('YYYY-MM-DD');
      this.confirmRetryDropMessage = `Are you sure you want to retry the execution of mail drop #${drop.drop_num}, id: ${drop.mail_drop_id}, the new drop date will be ${newDropDate}`;
      this.isConfirmRetryDropShown = true;
    },
    cancelDropRetry() {
      this.selectedDropToRetry = null;
      this.isConfirmRetryDropShown = false;
    },
    confirmRetryDrop() {
      this.executeAutomatically(this.selectedDropToRetry);
      this.isConfirmRetryDropShown = false;
    },
    async executeAutomatically(dropData) {
      this.$store.dispatch('showLoader', { message: 'Executing drop in progress...' });
      return this.$store
        .dispatch('tools/createPostcards', {
          order_id: this.firebaseOrderId,
          mail_drop_id: dropData.mail_drop_id,
          drop_num: dropData.drop_num,
          drop_date: this.$moment().add(1, 'days').format('YYYY-MM-DD'),
          is_retry: true
        })
        .then(() => {
          this.initDropsInformation();
          this.$emit('updateFeedback', { type: 'success', message: `Mail drop #${dropData.drop_num} with ID: ${dropData.mail_drop_id} has been executed successfully!` });
          this.selectedDropToRetry = null;
        })
        .catch(error => this.$emit('updateFeedback', { type: 'error', message: error?.response?.data?.message || error }))
        .finally(() => this.$store.dispatch('hideLoader'));
    },
    executeWithDmmTool(dropData) {
      const params = {
        start_date: this.$moment(dropData.date ? dropData.date : this.$moment.DATE)
          .subtract(30, 'days')
          .format('YYYY-MM-DD'),
        end_date: this.$moment(dropData.date ? dropData.date : this.$moment.DATE).format('YYYY-MM-DD'),
        crm_order_id: this.orderData.crm_order_id,
        firebase_order_id: this.orderData.firebase_order_id,
        crm_mail_drop_id: dropData.mail_drop_id,
        dmm_campaign_id: 0,
        crm_customer_id: this.orderData.crm_customer_id,
        drop_num: dropData.drop_num,
        firestore_map_id: this.orderData.references.map_id.split('/').pop(),
        is_retry: 1
      };
      let urlReadyParameters = Object.entries(params)
        .map(e => e.join('='))
        .join('&');
      this.$router.push({ path: `/dmm-tool/?${urlReadyParameters}` });
    },
    openDropDetailDialog(drop) {
      this.selectedDrop = drop;
      this.isDropDetailDialogOpened = true;
    },
    closeDropDetailDialog() {
      this.selectedDrop = {};
      this.isDropDetailDialogOpened = false;
    },
    formatDate(date) {
      return this.$moment(date).format('dddd, MMMM DD YYYY');
    },
    isDropStatusSuccess(status) {
      return status === 700 || status === 12 || (status && ['COMPLETE', 'COMPLETED'].includes(status.toString().toUpperCase()));
    },
    isDropDALSubmitted(status) {
      return status === 692;
    },
    isDropStatusFailed(status, date) {
      return status && ['ERROR', 'FAILED'].includes(status.toString().toUpperCase());
    },
    isInProgressPast(status, date) {
      const isInProgress = status === 'in_progress';
      if(!date) {
        return false;
      }
      // this means that the drop was not put into a complete state on the drop date, so something went wrong, let coordinator retry drop
      return isInProgress && this.$moment(date).isBefore(this.$moment());
    },
    isDropPaused(status, index) {
      return this.isSubscriptionPaused && index === 0 && (!status || !this.isDropStatusSuccess(status) || !this.isDropStatusFailed(status));
    },
    isDropInProgress(status) {
      return status === 'in_progress';
    },
    isDropCanceled(status) {
      return [2, 3, 'canceled', 'Canceled'].includes(status);
    },
    expandErrorMessage(dropIndex) {
      this.expandedErrorMessages.push(dropIndex);
    },
    isErrorMsgExpanded(dropIndex) {
      return this.expandedErrorMessages.filter(index => index === dropIndex).length > 0;
    },
    formatPropertyName(name) {
      return name
        .split('_')
        .map(chunk => `${chunk.charAt(0).toUpperCase()}${chunk.slice(1)}`)
        .join(' ');
    },
    formatPrice(price) {
      return numeral(price).format('$0,0.00');
    },
    getArtworkThumbnailSource(uid) {
      return `${process.env.VUE_APP_HTML2PDF_AMAZON_URL}/previews/thumbnails/${uid}_front_small.jpeg`;
    },
    openArtworkInNewTab(uid) {
      window.open(`${process.env.VUE_APP_HTML2PDF_AMAZON_URL}/previews/thumbnails/${uid}_front_large.jpeg`);
    },
    loadMoreDrops() {
      this.dropsNrToShow += 5;
    },
    showDivider(index) {
      return this.automatedDrops.length && index !== this.automatedDrops.length - 1;
    }
  }
};
</script>
<style scoped>
.showPointerOnHover {
  cursor: pointer;
}
</style>
