import Vue from 'vue';
import Vuex from 'vuex';

//Customer
import customer_legacy from './stamp/customer_legacy.js';
import customers from './stamp/customers.js';

import quoteStore from './quote_store.js';
import stepsStore from './steps_store.js';

// AMP
import artworkStore from './amp/artwork_store.js';
import mailingListStore from './stamp/mailing_lists.js';
import siteStore from './amp/site_store.js';
import siteSettingStore from './site_settings.js';
import salesforceStore from './salesforce.js';
import orderStore from './amp/order_store.js';
import databaseStore from './amp/database_store.js';

//DMM
import custom_fields from './dmm/custom_fields.js';
import letters from './dmm/letters.js';
import postcards from './dmm/postcards.js';

// permissions
import userStore from './stamp/user_store';
import idle from './osprey/idle';
import permissions from './osprey/permissions';
import logs from './osprey/logs';

// Templates management
import template_categories from './stamp/template_categories_store.js';
import template_types from './stamp/template_types_store.js';
import templates from './stamp/templates_store';

// DesignLive template management
import designLive from './osprey/design_live';

// Customer Messaging
import customerMessaging from './osprey/customer_messaging';

// UI
import settings from './osprey/settings';

// Notification
import notifications from './osprey/notifications';

// Stripe
import stripe from './osprey/stripe';
import payment_methods from './stamp/payment_methods';

// Order Management
import tools from './stamp/tools';
import om_orders from './stamp/om_orders';
import mapStore from './stamp/map_store';
import edit_audience from './stamp/edit_audience';
import sms_templates from './stamp/sms_templates';
import email_templates from './stamp/email_templates';
import ldp from './stamp/ldp_store';
import routes from './stamp/routes_store';

//product Management
import products from './stamp/products_store';
import product_types from './stamp/product_types_store';
import product_services from './stamp/product_services_store.js';
import product_categories from './stamp/product_categories_store';
import product_pricing from './stamp/product_pricing_store.js';

// Algolia
import algolia from './osprey/algolia';

//4over
import fourover from './stamp/fourover';
import fourover_statuses from './stamp/fourover_statuses.js';

import scheduled_drops from './stamp/scheduled_drops.js';

import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from '../modules/localStorage';

Vue.use(Vuex);

var vueScrollTo = require('vue-scrollto');

export const store = new Vuex.Store({
  modules: {
    database: databaseStore,
    customer_legacy,
    customers,
    design: artworkStore,
    site: siteStore,
    site_setting: siteSettingStore,
    salesforce: salesforceStore,
    steps: stepsStore,
    order: orderStore,
    edit_audience,
    lists: mailingListStore,
    maps: mapStore,
    quote: quoteStore,
    users: userStore,
    idle,
    permissions,
    logs,
    design_live: designLive,
    customer_messaging: customerMessaging,
    settings,
    notifications,
    stripe,
    payment_methods,
    tools,
    om_orders,
    algolia,
    sms_templates,
    email_templates,
    ldp,
    product_categories,
    product_pricing,
    product_services,
    product_types,
    products,
    routes,
    fourover,
    fourover_statuses,
    custom_fields,
    letters,
    postcards,
    template_categories,
    template_types,
    templates,
    scheduled_drops
  },
  state: {
    progress: false,
    loaderGlobal: {
      loading: 0,
      message: ''
    },
    snackbarGlobal: { type: '', message: '' },
    alertGlobal: {
      type: 'info',
      message: '',
      isVisible: true
    },
    utils: null
  },
  getters: {
    getProgressValue(state) {
      return state.progress;
    },
    getLoaderGlobal(state) {
      return state.loaderGlobal;
    },
    getIsLoading(state) {
      return state.loaderGlobal.loading > 0;
    },
    getSnackbarGlobal(state) {
      return state.snackbarGlobal;
    },
    getUtilsInstance(state) {
      return state.utils;
    },
    getAlertGlobal(state) {
      return state.alertGlobal;
    }
  },
  mutations: {
    setProgressValue(state, value) {
      state.progress = value;
    },
    setLoaderGlobal(state, loader) {
      state.loaderGlobal = loader;
    },
    setSnackbarGlobal(state, snackbar) {
      state.snackbarGlobal = snackbar;
    },
    setGlobalAlert(state, payload) {
      state.alertGlobal = payload;
    }
  },
  actions: {
    scroll_to(context, step) {
      var options = {
        easing: 'ease-in',
        offset: -60,
        onDone: () => {
          console.log('Scroll done');
        },
        onCancel: () => {
          console.log('Scroll canceled');
        }
      };

      setTimeout(() => {
        vueScrollTo.scrollTo(`#${step}`, 500, options);
      }, 100);
    },
    showLoader({ commit, getters }, payload) {
      let loader = getters.getLoaderGlobal;
      loader.message = payload?.message ?? '';
      loader.loading++;
      commit('setLoaderGlobal', loader);
    },
    setSnackbar({ commit }, payload) {
      commit('setSnackbarGlobal', payload);
    },
    hideLoader({ commit, getters }, payload) {
      let loader = getters.getLoaderGlobal;
      loader.message = '';
      loader.loading--;
      loader.loading < 0 ? 0 : loader.loading;
      commit('setLoaderGlobal', loader);
    },
    setUtilsInstance: function (context, instance) {
      context.state.utils = instance;
    },
    showAlert({ commit }, payload) {
      commit('setGlobalAlert', {
        isVisible: true,
        type: payload.type,
        message: payload.message
      });
      const element = document.querySelector('.v-alert');
      if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    hideAlert({ commit }, payload) {
      commit('setGlobalAlert', {
        isVisible: false,
        type: 'info',
        message: ''
      });
    }
  }
});
