import productPricingService from '../../services/productPricingService';
import siteSettingsService from '../../services/siteSettingsService.js';
import { dbFS, storageRef } from '@/services/firebaseInit';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getDefaultProductPricingById(_context, payload) {
    try {
      const pricing = await productPricingService.getById(payload.product_firebase_id);

      return pricing;
    } catch (error) {
      console.log(error);
    }
  },

  async getCustomProductPricingById(_context, payload) {
    try {
      const pricing = await siteSettingsService.getById(`${payload.siteId}/product_pricing/${payload.product_firebase_id}`);

      return pricing;
    } catch (error) {
      console.error(error);
    }
  },

  addNewProductPricing(_context, payload) {
    return productPricingService.set(payload.id, payload.data, { merge: false });
  },

  async updateProductPricing({ dispatch }, payload) {
    console.log('[updateProductPricing] payload: ', payload);
    if (payload.siteID === 'default') {

      if (!payload?.id) {
        console.log('Error: Product ID does not exist.');
        return;
      }

      await dispatch('updateAllDefaultProductPricingByID', { productPricing: payload.data[payload.id], productID: payload.id });
      
      return productPricingService.set(payload.id, payload.data[payload.id], { merge: false });
    } else {
      siteSettingsService.set(`${payload.siteID}/products/${payload.id}`, { is_default: false });
      payload.data[payload.id].is_default = false;
      return siteSettingsService.set(`${payload.siteID}/product_pricing/${payload.id}`, payload.data[payload.id], { merge: false });
    }
  },

  async updateTotalWeight({ dispatch }, payload) {
    try {
      console.log('[updateTotalWeight] payload: ', payload);

      const productID = payload.productFirebaseId;
  
      let pricingObject = {};
      if (payload.siteID === 'default') {
        const defaultPricing = await productPricingService.getById(payload.productFirebaseId);
        if (!defaultPricing) {
          console.log('No default pricing object to update');
          return;
        }
      } else {
        pricingObject = await siteSettingsService.getById(`${payload.siteID}/product_pricing/${productID}`);
      }
  
      for (const tier of Object.keys(pricingObject)) {
        if (tier !== 'is_default') {
          pricingObject[tier].total_weight = parseFloat(
            (
              (parseFloat(payload.product.size_x) * parseFloat(payload.product.size_y) * parseFloat(payload.product.material_weight) * parseFloat(pricingObject[tier].up_to)) /
              16
            ).toFixed(2)
          );
        }
      }
  
      if (payload.siteID === 'default') {
        productPricingService.set(payload.productFirebaseId, pricingObject);
        pricingObject.is_default = true;
        return dispatch('updateAllDefaultProductPricingByID', { productPricing: pricingObject, productID: productID });
      }
  
      pricingObject.is_default = false;
      return siteSettingsService.update(`${payload.siteID}/product_pricing/${productID}`, pricingObject);
      
    } catch (error) {
      console.log('Error: ', error);
    }
  },

  async updateAllDefaultProductPricingByID(_context, payload) {
    console.log('[updateAllDefaultProductPricingByID]: ', payload);
    try {
      const productID = payload?.productID;
      const sitesRef = dbFS.collection('sites');
      let processedSites = 0;

      const querySnapshot = await sitesRef.get();

      querySnapshot.forEach(async siteDoc => {
        try {
          const siteId = siteDoc.id;
          const productsRef = siteDoc.ref.collection('product_pricing');
          console.log('Processing site:', siteId);

          const productsSnapshot = await productsRef.get();

          productsSnapshot.forEach(productDoc => {
            const productData = productDoc.data();
            if (productDoc.id === productID && productData.is_default) {
              console.log('Updating product pricing in site:', siteId);
              payload.productPricing.is_default = true;
              productDoc.ref.set(payload.productPricing);
            }
          });

          processedSites++;
          if (processedSites === querySnapshot.size) {
            console.log('All sites processed.');
          }
        } catch (error) {
          console.error('Error processing site:', siteDoc.id, error);
        }
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
