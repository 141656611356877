import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class RouteService extends FirestoreModel {
  constructor() {
    super(collections.routes);
  }
}

export default new RouteService();
