import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class EmailTemplateService extends FirestoreModel {
  constructor() {
    super(collections.emailtemplates);
  }
}

export default new EmailTemplateService();
