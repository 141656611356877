import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class UserService extends FirestoreModel {
  constructor() {
    super(collections.users);
  }
}

export default new UserService();
