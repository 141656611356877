<template>
  <v-container fluid>
    <v-row>
      <v-btn icon large @click="navigateBack()"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <span class="ml-3 pt-1 title font-weight-regular">{{ title }}</span>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <insert-edit-product-category
      :modelData="productCategory"
      :isEditEnabled="isEditEnabled"
      :isLoading="isLoading"
      @saveModel="saveProductCategory"
    ></insert-edit-product-category>
  </v-container>
</template>

<script>
import InsertEditProductCategory from '../../common/dynamicForm/FormGenerator';
import { mapGetters } from 'vuex';

export default {
  components: {
    'insert-edit-product-category': InsertEditProductCategory
  },
  data() {
    return {
      productCategoryDbId: '',
      isEditEnabled: true,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('product_categories', {
      productCategory: 'getSelectedModel'
    }),
    title() {
      return this.productCategoryDbId ? `${this.isEditEnabled ? 'Edit' : 'View'}: ${this.productCategoryDbId}` : 'Add New Product Category';
    }
  },
  async mounted() {
    this.isEditEnabled = this.$route.name !== 'ViewProductCategory';
    this.productCategoryDbId = Object.values(this.$route.params)[0];
    if (this.productCategoryDbId) {
      this.getProductCategoryById();
    } else {
      this.$store.commit('product_categories/clearModel');
      await this.$store.dispatch('product_categories/initSelectedModel', []);
    }
  },
  methods: {
    async getProductCategoryById() {
      this.$store.dispatch('showLoader');
      try {
        const productCategoryModel = await this.$store.dispatch('product_categories/getProductCategoryById', this.productCategoryDbId);
        await this.$store.dispatch('product_categories/initSelectedModel', productCategoryModel);
      } catch (error) {
        console.error('[getProductCategoryById error ] ', error);
        this.showAlert('error', error.message || error || 'Could not load Product');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async saveProductCategory(product_category) {
      this.isLoading = true;

      if (!product_category?.image || !product_category?.name) {
        this.showAlert('error', 'Category image and name fields are required.');
        this.isLoading = false;
        return;
      }

      const productCategoryObject = {
        id: this.productCategoryDbId || '',
        data: {
          image: product_category.image,
          name: product_category.name
        }
      };
      try {
        const result = await this.$store.dispatch('product_categories/saveProductCategory', productCategoryObject);
        this.setSnackbar('success', result.message);
        this.navigateBack();
      } catch (error) {
        this.showAlert('error', error.message || error);
      } finally {
        this.isLoading = false;
      }
    },
    navigateBack() {
      this.$store.commit('product_categories/clearModel');
      this.$router.replace(`/${this.$route.path.split('/')[1]}`);
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    setSnackbar(type, message) {
      this.$store.dispatch('setSnackbar', { type, message });
    }
  }
};
</script>
