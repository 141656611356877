<template>
  <div class="site-table">
    <v-dialog v-model="isDeleteDialogVisible" persistent max-width="400">
      <v-card>
        <v-card-title class="headline red">
          <div class="text-xs-center">
            <span class="white--text text-md-center">Warning!</span>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <p class="mt-3 subheader">Are you sure you want to delete this map?</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDeleteDialog"> Cancel </v-btn>
          <v-btn color="green darken-1" text @click="confirmDelete"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isDeleteNotAllowed" width="500">
      <v-card class="delete-alert">
        <v-card-title>
          <v-icon large color="red" class="mt-n4 mr-2">mdi-alert-outline</v-icon>
          <p class="text-center">Not allowed! This map is related to an order!</p>
        </v-card-title>
        <v-card-text class="text-center mt-n3">
          <span class="subtitle-1">Order ID:</span>
          <a :href="`/orders/view/${relatedOrderId}`" target="_blank" class="subtitle-1 ml-2 mt-n1">{{ relatedOrderId }}</a>
        </v-card-text>
        <v-row class="justify-end mr-1 pb-1 mt-n3"><v-btn text @click="isDeleteNotAllowed = false">OK</v-btn></v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isNewMoversCsvDialogOpened" scrollable persistent max-width="800">
      <v-card>
        <v-card-title class="title grey--text text--darken-2 ml-1 font-weight-regular">Download CSV by Period</v-card-title>
        <v-card-text>
          <newmover-csv-modal v-if="isNewMoversCsvDialogOpened" :link="csvLink" :customerId="ospreyCustomerId"> </newmover-csv-modal>
          <v-row class="justify-end mr-0">
            <v-btn color="grey darken-3" text @click="isNewMoversCsvDialogOpened = false">Close</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <saturation-csv-modal v-if="isSaturationCSVDialogVisible" :modal="isSaturationCSVDialogVisible" :mapData="mapItem" @closeSaturationModal="closeSaturationCsvModal()">
    </saturation-csv-modal>

    <v-dialog v-model="isDeleteRaportDialogVisible" persistent max-width="600">
      <v-card>
        <v-card-title class="headline blue">
          <div class="text-xs-center">
            <span class="white--text text-md-center">Delete raport summary</span>
          </div>
        </v-card-title>
        <v-card-text>
          <ul class="mt-4">
            <template v-for="item in reportListDisplay">
              <li v-if="item.key" :key="item.key" class="my-2">
                Delete {{ item.key }} ...
                <span v-if="item.value === 'success'">
                  <span class="blue--text">
                    <v-icon color="blue">mdi-check</v-icon>
                  </span>
                </span>
                <span v-else>
                  <span>
                    <v-icon color="red">mdi-close-circle-outline</v-icon>
                  </span>
                </span>
              </li>
            </template>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDeleteRaportDialog()"> close </v-btn>
        </v-card-actions>
        <v-progress-linear v-if="isDeleteProcessing" :indeterminate="isDeleteProcessing" class="my-0"></v-progress-linear>
      </v-card>
    </v-dialog>

    <v-row>
      <v-toolbar flat class="site-table--header site-table--lists">
        <v-col class="pl-0">
          <v-toolbar-title class="headline">
            <a class="grey--text text--darken-3" @click="clearDate()">Maps</a>
          </v-toolbar-title>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details @keydown="searchKeydown()"></v-text-field>
          <p v-if="isMinLengthWarnShown" class="caption mt-2">Please enter min 3 characters to begin searching!</p>
        </v-col>
        <stamp-date-filters
          v-if="isDataLoadedFromUrl"
          :startDate="selectedStartDate"
          :endDate="selectedEndDate"
          :timeStampAttrName="timeStampAttrName"
          :isFiltersCleared="isFiltersCleared"
          @onRouteHandlerChanged="onRouteHandlerChanged"
          @onDateFilterChanged="onDateFilterChanged"
          @onDateSelectedStateChanged="onDateSelectedStateChanged"
          @onDateCleared="clearDate"
          @onWrongFilter="onWrongFilter"
        ></stamp-date-filters>
      </v-toolbar>
    </v-row>

    <v-row class="mt-n4">
      <v-toolbar flat class="site-table--header mb-n2">
        <v-toolbar-title class="body-2 mr-2">Order Type</v-toolbar-title>
        <v-chip-group v-model="selectedOrderType" active-class="primary--text">
          <template v-for="(filterName, index) in quickFilterItems">
            <v-chip v-if="filterName" :key="index" filter outlined>{{ filterName }}</v-chip>
          </template>
        </v-chip-group>
        <v-btn fab x-small icon outlined color="grey lighten-2" @click="selectedOrderType = ''">
          <v-icon color="grey">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-row>
    <stamp-environment-filters
      v-if="isDataLoadedFromUrl"
      :envFromQueryParam="envFromQueryParam"
      :testSitesList="testSitesList"
      :isFiltersCleared="isFiltersCleared"
      siteDbIdAttrName="siteDbId"
      originSiteDbIdAttrName="origin.siteDbId"
      @onEnvTypeChanged="onEnvTypeChanged"
    ></stamp-environment-filters>
    <v-row>
      <v-col>
        <v-alert v-model="endDateSmaller" type="error" dismissible outlined> End date cannot be less than Start date! Please choose another End date. </v-alert>
        <div class="site-table mt-n8">
          <stamp-data-table
            :headers="headers"
            :data="maps"
            :is-loading="isLoading"
            :is-items-per-page-disabled="true"
            :total-server-items-nr="totalMaps"
            item-key="firebase_map_id"
            :item-class="getLineColor"
            :sort-desc="true"
            :sort-by="timeStampAttrName"
            :current-page="currentPage"
            @options="optionsHandler"
          >
            <template #[`item.creationDateTimestamp`]="{ item }">{{ formatDate(item.creationDateTimestamp) }}</template>
            <template #[`item.crmCustomerId`]="{ item }">{{ item.customer.crmCustomerId }}</template>
            <template #[`item.customerName`]="{ item }">{{ item.customer.first_name }} {{ item.customer.last_name }}</template>
            <template #[`item.customerEmail`]="{ item }">{{ item.customer.email }}</template>
            <template #[`item.siteDbId`]="{ item }">
              <v-tooltip bottom open-delay="200">
                <template v-slot:activator="{ on }">
                  <span class="blue--text text--darken-4 siteFullName" v-on="on" @mouseover="getSiteFullName(item.siteDbId)" @mouseleave="clearSiteFullName">
                    {{ item.siteDbId }}
                  </span>
                </template>
                <v-progress-circular v-if="!siteFullName" indeterminate color="white"></v-progress-circular>
                <div v-else class="subtitle-1">
                  <span>{{ siteFullName.name }}</span>
                  <br />
                  <span>{{ siteFullName.url }}</span>
                </div>
              </v-tooltip>
            </template>
            <template #[`item.csv`]="{ item }">
              <v-btn v-if="item.orderType === 'newmovers'" class="static" text color="grey darken-3" @click="getCsvLink(item)">
                <v-icon left>mdi-cloud-download-outline</v-icon>CSV
              </v-btn>
              <v-btn v-if="item.orderType === 'saturation' && item.emapMode === 'Saturation'" class="static" text color="grey darken-3" @click="openSaturationCsvModal(item)">
                <v-icon left>mdi-cloud-download-outline</v-icon>CSV
              </v-btn>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon @click="deleteMap(item)">
                    <v-icon medium color="red" v-on="on">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Map</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon :to="`/maps/view/${item.firebase_map_id}`">
                    <v-icon medium color="blue-grey" v-on="on">mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Map Details</span>
              </v-tooltip>
            </template>
          </stamp-data-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SaturationCsvModal from './mapDialogs/SaturationCsvModal.vue';
import NewMoversCsvCount from './mapDialogs/CsvCountsPanel';
import EnvironmentFilters from '@/components/filters/EnvironmentFilters.vue';
import DateFilters from '@/components/filters/DateFilters.vue';
import DataTable from '@/components/common/DataTable.vue';
import orderService from '../../../services/orderService';
import mapsService from '../../../services/mapsService';

export default {
  name: 'maps',
  components: {
    'saturation-csv-modal': SaturationCsvModal,
    'newmover-csv-modal': NewMoversCsvCount,
    'stamp-environment-filters': EnvironmentFilters,
    'stamp-date-filters': DateFilters,
    'stamp-data-table': DataTable
  },
  data() {
    return {
      search: '',
      options: {},
      timeStampAttrName: 'creationDateTimestamp',
      setHitsPerPage: 15,
      headers: [
        { text: 'Date', value: 'creationDateTimestamp', sortable: false },
        { text: 'emapMode', value: 'emapMode', sortable: false },
        { text: 'Name', value: 'name', sortable: false, align: 'start' },
        { text: 'CRM Customer ID', value: 'crmCustomerId', sortable: false },
        { text: 'Customer Name', value: 'customerName', sortable: false },
        { text: 'Customer Email', value: 'customerEmail', sortable: false, align: 'start' },
        { text: 'Order Type', value: 'orderType', sortable: false },
        { text: 'Total Count', value: 'totalCount', sortable: false },
        { text: 'Site DB Id', value: 'siteDbId', sortable: false, align: 'start' },
        { text: 'CSV', value: 'csv', sortable: false, align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center', width: '110' }
      ],
      totalMaps: null,
      maps: [],
      isLoading: false,
      isMinLengthWarnShown: false,
      isDateSelected: false,
      date: new Date().toISOString().substr(0, 10),
      selectedStartDate: '',
      selectedEndDate: '',
      endDateSmaller: false,
      isNewMoversCsvDialogOpened: false,
      csvLink: '',
      mapItem: null,
      deletedMap: null,
      isDeleteDialogVisible: false,
      mapReferences: [],
      reportList: [],
      isDeleteRaportDialogVisible: false,
      isDeleteProcessing: false,
      isDeleteNotAllowed: false,
      isSaturationCSVDialogVisible: false,
      selectedOrderType: '',
      quickFilterItems: ['Availability', 'RouteSelect', 'Inserts', 'Movers', 'Saturation'],
      searchTypeTimer: null,
      isDataLoadedFromUrl: false,
      dateFilter: '',
      relatedOrderId: '',
      filter: [],
      siteFullName: null,
      currentPage: 1,
      testSitesList: null,
      isFiltersCleared: false,
      envFromQueryParam: ''
    };
  },
  computed: {
    ...mapGetters('users', {
      userStore: 'user'
    }),
    ...mapGetters('customer_messaging', {
      deletingRaport: 'getMapDeleteReport'
    }),
    reportListDisplay() {
      return this.reportList;
    },
    commonFilter() {
      let common = `${this.dateFilter ? `${this.dateFilter}${this.filter.length ? ` AND ` : ``}` : ``}`;
      if (this.filter.length) {
        this.filter.forEach((value, index) => {
          common += value.envType ? value.envType : `${Object.keys(value)}:${Object.values(value)}`;
          if (index !== this.filter.length - 1) {
            common += ' AND ';
          }
        });
      }
      return common;
    },
    ospreyCustomerId() {
      return this.mapItem?.customer?.crmCustomerId ?? '';
    },
    currentPrimaryColor() {
      return 'test-item-default';
    }
  },
  watch: {
    search(value) {
      if ((value.length === 0 && !this.isDateSelected) || value.length > 2) {
        this.isMinLengthWarnShown = false;
        this.searchTypeTimer = setTimeout(() => {
          this.resetSelectedPage();
          this.getMapsFromAlgolia(this.commonFilter, value, 0);
        }, 500);
      } else {
        this.isMinLengthWarnShown = true;
      }
    },
    deletingRaport: {
      handler() {
        const deleteRaportObj = this.deletingRaport;
        const key = Object.keys(deleteRaportObj)[0];
        const value = deleteRaportObj[key];
        this.reportList.push({
          key,
          value
        });
      },
      deep: true
    },
    selectedOrderType(value) {
      this.resetSelectedPage();
      const orderType = this.quickFilterItems[this.selectedOrderType] ?? '';
      this.routeHandler('emapMode', orderType);
      this.setCommonFilter('emapMode', orderType);
      this.getMapsFromAlgolia(this.commonFilter, this.search, 0);
    }
  },
  async created() {
    await this.initTestSitesList();
    await this.setDataFromUrl();
    this.isDataLoadedFromUrl = true;
    await this.initResult();
  },
  methods: {
    closeDeleteRaportDialog() {
      this.isDeleteRaportDialogVisible = false;
      this.reportList = [];
    },
    getLineColor(item) {
      return this.isListItemTest(item) ? this.currentPrimaryColor : '';
    },
    async initTestSitesList() {
      this.testSitesList = await this.$store.dispatch('site/getTestSites');
      Promise.resolve();
    },
    async getCsvLinkModal() {
      try {
        const firebaseID = this.mapItem.firebase_map_id;
        const mapName = this.concatAndFormat(this.mapItem.name);
        if (!this.mapItem.csvLink) {
          const response = await mapsService.getMapCsvLink(firebaseID);
          console.log('Response:', response);
          this.csvLink = response.csvLink;
        } else {
          this.csvLink = this.mapItem.csvLink;
        }
      } catch (err) {
        console.log('getCsvLinkModal has failed: ', err);
      }
    },
    getCsvLink(item) {
      this.isNewMoversCsvDialogOpened = true;
      this.mapItem = item;
      this.getCsvLinkModal();
    },
    openSaturationCsvModal(item) {
      this.isSaturationCSVDialogVisible = true;
      this.mapItem = item;
    },
    clearDate() {
      this.isFiltersCleared = true;
      this.envFromQueryParam = '';
      this.routeHandler('envType', this.envFromQueryParam);
      this.dateFilter = '';
      this.selectedEndDate = '';
      this.selectedStartDate = '';
      this.isDateSelected = false;
      this.search = '';
      this.routeHandler('search', '');
      this.selectedOrderType = '';
      this.initResult();
    },
    resetSelectedPage() {
      this.options.page = 1;
      this.currentPage = 1;
    },
    async getMapsFromAlgolia(filter, searchValue, page) {
      try {
        this.setLoadingState(true);
        const content = await this.$store.dispatch('algolia/getDataFromAlgolia', {
          indexName: `maps${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`,
          filter,
          searchValue: searchValue ?? '',
          page: page && page > 1 ? page - 1 : 0
        });
        this.totalMaps = content.nbHits;
        this.maps = content.hits;
        return Promise.resolve(content);
      } catch (error) {
        console.log(`[getMapsFromAlgolia] has failed: `, error);
        console.log(`[getMapsFromAlgolia] has failed: `, error.debugData);
        return Promise.reject(error);
      } finally {
        this.setLoadingState(false);
      }
    },
    async initResult() {
      const d = new Date();
      const date = Math.floor(d.setDate(d.getDate()) / 1000);
      await this.getMapsFromAlgolia(`creationDateTimestamp < ${date}${this.commonFilter ? ` AND ${this.commonFilter}` : ''}`, '', 0);
    },
    onDateFilterChanged(formattedFilter) {
      this.dateFilter = formattedFilter;
      return this.getMapsFromAlgolia(this.commonFilter, this.search, 0);
    },
    onEnvTypeChanged(payload) {
      this.resetSelectedPage();
      this.routeHandler('envType', payload.selectedEnv);
      this.setCommonFilter('envType', payload.envFilters);
      this.getMapsFromAlgolia(this.commonFilter, this.search, 0);
    },
    async deleteMap(map) {
      console.log('Map in delelteMap function: ', map);
      try {
        this.$store.dispatch('showLoader', { message: 'Please wait...' });
        const response = await orderService.checkMapInOrdersRef(map.siteDbId, map.firebase_map_id);

        if (response.isRelatedToOrder && response.orderFirebaseID) {
          this.relatedOrderId = response.orderFirebaseID;
          this.isDeleteNotAllowed = true;
        } else {
          this.deletedMap = map;
          this.isDeleteDialogVisible = true;
          this.$store.dispatch('customer_messaging/checkMapReferences', { firebaseID: map.firebase_map_id }).then(response => {
            // TODO: check for success
            this.mapReferences = response;
          });
        }
      } catch (error) {
        console.log('deleteMap has failed: ', error);
      } finally {
        this.$store.dispatch('hideLoader', {});
      }
    },
    closeDeleteDialog() {
      this.isDeleteDialogVisible = false;
    },
    async confirmDelete() {
      try {
        this.isDeleteDialogVisible = false;
        this.isDeleteRaportDialogVisible = true;
        this.isDeleteProcessing = true;
        await this.$store.dispatch('customer_messaging/deleteMap', {
          mapObject: this.deletedMap,
          mapRef: this.mapReferences
        });

        console.log('All done in Front');
        this.isDeleteProcessing = false;
      } catch (error) {
        console.log('Deleting all catch some error MapsAlgolia: ' + error);
      }
    },
    formatDate(dateTotransform) {
      var date = new Date(dateTotransform * 1000);
      return this.$moment(date).format('MMMM DD, YYYY [at] HH:mm:ss');
    },
    concatAndFormat(text) {
      if (!text) return '';
      let modifiedText = text.toString();
      modifiedText = modifiedText.toLowerCase();
      modifiedText = modifiedText.replace(/["']/g, '');
      modifiedText = modifiedText.replace(/:/g, '_');
      modifiedText = modifiedText.replace(/[/]/g, '_');
      modifiedText = modifiedText.replace(/\s+/g, '_');
      return modifiedText;
    },
    routeHandler(paramName, paramValue) {
      let query = { ...this.$route.query };

      if (!paramValue) {
        if (Object.keys(query).includes(paramName)) {
          delete query[paramName];
          this.$router.replace({ query });
        }
      } else if (JSON.stringify({ [paramName]: query[paramName] }) !== JSON.stringify({ [paramName]: paramValue })) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            [paramName]: paramValue
          }
        });
      }
    },
    setDataFromUrl() {
      return new Promise(resolve => {
        const routeQuery = this.$route.query;

        if (routeQuery) {
          this.selectedStartDate = routeQuery.startDate ?? '';
          this.routeHandler('startDate', this.selectedStartDate);
          this.selectedEndDate = routeQuery.endDate ?? '';
          this.routeHandler('endDate', this.selectedEndDate);
          this.isDateSelected = this.selectedStartDate !== '' && this.selectedEndDate !== '';
          this.envFromQueryParam = routeQuery.envType ?? '';
          this.selectedOrderType = routeQuery.emapMode ? this.quickFilterItems.findIndex(i => i === routeQuery.emapMode) : '';
          this.search = routeQuery.search ?? '';
          this.routeHandler('search', this.search);
        }

        resolve('done');
      });
    },
    setCommonFilter(key, value) {
      if (this.filter.length) {
        let isValueFound = false;
        this.filter.forEach((item, index) => {
          if (Object.keys(item).includes(key) && item[key] !== value) {
            isValueFound = true;
            if (value !== '') {
              this.filter[index][key] = value;
            } else {
              this.filter.splice(index, 1);
            }
          }
        });
        if (!isValueFound) {
          this.filter.push({ [key]: value });
        }
      } else if (value) {
        this.filter.push({ [key]: value });
      }
    },
    searchKeydown() {
      clearTimeout(this.searchTypeTimer);
      this.searchTypeTimer = setTimeout(() => {
        if (this.search === '' || this.search?.length > 2) {
          this.routeHandler('search', this.search);
        }
      }, 1000);
    },
    async getSiteFullName(siteDbId) {
      try {
        const res = await this.$store.dispatch('om_orders/getSiteFullNameById', { siteId: siteDbId });
        this.siteFullName = {
          name: res.name ? res.name : '',
          url: res.url ? res.url : ''
        };
      } catch (error) {
        console.log('getSiteFullName has failed: ', error);
      }
    },
    clearSiteFullName() {
      this.siteFullName = null;
    },
    closeSaturationCsvModal() {
      this.isSaturationCSVDialogVisible = false;
      this.mapItem = null;
    },
    setLoadingState(isVisible) {
      this.isLoading = isVisible;
    },
    onRouteHandlerChanged(payload) {
      this.routeHandler(payload.key, payload.value);
    },
    onDateSelectedStateChanged(isDateSelected) {
      this.isDateSelected = isDateSelected;
    },
    onWrongFilter(isEndDateSmaller) {
      this.isEndDateSmaller = isEndDateSmaller;
      this.totalMaps = 0;
      this.maps = [];
    },
    isListItemTest(item) {
      return (item?.origin?.siteDbId && this.testSitesList?.includes(item?.origin?.siteDbId)) || this.testSitesList?.includes(item.siteDbId);
    },
    async optionsHandler(options) {
      this.options = options;
      if (this.currentPage !== this.options.page) {
        await this.getMapsFromAlgolia(this.commonFilter, this.search, this.options.page);
        this.currentPage = this.options.page;
      }
    }
  }
};
</script>

<style scoped>
.delete-alert {
  height: 158px;
}
.test-item-global {
  background-color: #e6f2f4 !important;
}
.test-item-default {
  background-color: #f9e1e1 !important;
}
</style>
