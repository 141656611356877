import { firestoreTimestamp } from '../../services/firebaseInit.js';
import userGroupsService from '../../services/userGroupsService.js';
import accessLevelService from '../../services/accessLevelService.js';
import userService from '../../services/userService.js';
import routeService from '../../services/routeService.js';

const state = {
  levels: [],
  levelsPermissions: [],
  levelParameters: [],
  levelValueExist: false,
  routes: [],
  groupExist: false
};

const mutations = {
  // Levels
  setLevels(state, payload) {
    state.levels = payload;
  },
  setLevelsPermissions(state, payload) {
    state.levelsPermissions = payload;
  },
  setLevelParameters(state, payload) {
    state.levelParameters = payload;
  },
  setLevelValueExist(state, payload) {
    state.levelValueExist = payload;
  },
  // Routes
  setRoutes(state, payload) {
    state.routes = payload;
  },
  // Groups
  testGroup(state, payload) {
    state.groupExist = payload;
  }
};

const actions = {
  // Routes components
  /*
   * List routes
   * @return (as commit) {array} routesArray
   */
  async fetchRoutes({ commit }) {
    const routes = await routeService.getAll({ isArray: true });
    commit('setRoutes', routes);
  },
  /*
   * Save new route
   * @param {string} payload : routeNameToInsert,pathToInsert,componentToInsert,descriptionToInsert,iconToInsert
   * @param {array}  payload : accessLevelArray
   */
  saveRoute({ commit }, payload) {
    return routeService
      .add({
        data: {
          name: payload.routeNameToInsert,
          path: payload.pathToInsert,
          component: payload.componentToInsert,
          description: payload.descriptionToInsert,
          access_levels: payload.accessLevelArray,
          icon: payload.iconToInsert
        }
      })
      .then(data => {
        console.log('Save new route has succeeded!: ', data);
      })
      .catch(error => {
        console.log('Save new route has failed: ', error);
      });
  },
  /*
   * Edit route
   *  @param {string} payload : routeNameToInsert,pathToInsert,componentToInsert,descriptionToInsert,iconToInsert,key
   *  @param {array}  payload : accessLevelArray
   */
  editRoute({ commit }, payload) {
    const routeNameToInsert = payload.routeNameToInsert;
    const pathToInsert = payload.pathToInsert;
    const componentToInsert = payload.componentToInsert;
    const descriptionToInsert = payload.descriptionToInsert;
    const accessLevelArray = payload.accessLevelArray;
    const iconToInsert = payload.iconToInsert;
    const key = payload.key;

    return routeService
      .set(key, {
        name: routeNameToInsert,
        path: pathToInsert,
        component: componentToInsert,
        description: descriptionToInsert,
        access_levels: accessLevelArray,
        icon: iconToInsert
      })
      .then(() => {
        console.log('Edit route has succeeded!');
      })
      .catch(error => {
        console.log('Edit route has failed: ', error);
      });
  },
  /*
   * Delete route
   *  @param {string} payload : key
   */
  deleteRoute({ commit }, payload) {
    const key = payload.key;
    routeService
      .delete(key)
      .then(() => {
        console.log('Delete route has succeeded!');
      })
      .catch(error => {
        console.log('Delete route has failed: ', error);
      });
  },

  /*
   * List levels permissions
   * @return (as commit){array} levelsArray
   */
  async fetchLevelsPermissionsInApp({ commit }) {
    const levelsArray = await accessLevelService.getAll({ isArray: true, isIdReturned: true });
    commit('setLevelsPermissions', levelsArray);
  },

  /*
   * When access level is deleted, delete from groups
   * @param {string} payload : key of the deleted level
   */
  async deleteAccessLevelFromGroups({ commit }, payload) {
    const levelKey = payload.levelKey;
    const userGroups = await userGroupsService.getAll({ isArray: true, isIdReturned: true });
    const promises = [];

    userGroups.forEach(userGroup => {
      const updatedUserGroupAccessLevels = userGroup.access_levels.filter(({ id }) => id !== levelKey);
      const promise = userGroupsService.update(userGroup._id, { access_levels: updatedUserGroupAccessLevels });
      promises.push(promise);
    });

    Promise.all(promises).then(() => {
      console.log('deleteAccessLevelFromGroups succeeded');
    });
  },

  /*
   * When access level is deleted, delete from users
   * @param {string} payload : key of the deleted level
   */
  async deleteAccessLevelFromUsers({ commit }, payload) {
    const levelKey = payload.levelKey;
    const users = await userService.getAll({ isArray: true, isIdReturned: true });
    const promises = [];

    users.forEach(user => {
      const updatedUserAccessLevel = user.access_levels.filter(id => id !== levelKey);
      const promise = userService.update(user._id, { access_levels: updatedUserAccessLevel });
      promises.push(promise);
    });

    Promise.all(promises).then(() => {
      console.log('deleteAccessLevelFromUsers succeeded');
    });
  },

  /*
   * Get level parameters(description, name, value) from a given permission value
   * @param {string} payload : id of the level
   * @return {array} key:0 , value (level parameters: levelkey, name , value)
   */
  async getLevelParametersFromId({ commit }, payload) {
    let levelParameters = [];
    const levelValue = parseInt(payload.level);
    let isAl = false;
    try {
      const accessLevels = await accessLevelService.getAll({ isArray: true, isIdReturned: true });
      accessLevels.forEach(accessLevel => {
        if (accessLevel.value === levelValue) {
          isAl = true;
          const levelKey = accessLevel._id;
          const levelLevel = accessLevel.value;
          const levelName = accessLevel.name;

          levelParameters.push({
            levelkey: levelKey,
            name: levelName,
            value: levelLevel
          });
        }
      });

      commit('setLevelValueExist', isAl);
      commit('setLevelParameters', levelParameters);
    } catch (err) {
      console.log(err);
    }
  },

  /*
   * Update value of acces level in groups() when value is edited
   * @param {object} payload - get props: levelKey and levelValueToInsert
   */
  async updateAccessLevelsInGroups({ commit }, payload) {
    const levelKey = payload.levelKey;
    const levelValueToInsert = payload.levelValueToInsert;
    const levelNameToInsert = payload.levelNameToInsert;

    const userGroups = await userGroupsService.getAll({ isArray: true, isIdReturned: true });

    const promises = [];

    userGroups.forEach(userGroup => {
      const index = userGroup.access_levels.findIndex(({ id }) => id === levelKey);
      if (index > -1) {
        userGroup.access_levels[index].value = levelValueToInsert;
        userGroup.access_levels[index].name = levelNameToInsert;
        console.log(userGroup.access_levels);
        const promise = userGroupsService.update(userGroup._id, { access_levels: userGroup.access_levels });
        promises.push(promise);
      }
    });

    Promise.all(promises).then(() => {
      console.log('updateAccessLevelsInGroups succeded');
    });
  },
  async updateLevelsInRoutes({ commit }, { id: levelId, value }) {
    const routes = await routeService.getAll({ isArray: true, isIdReturned: true });

    const promises = [];

    routes.forEach(route => {
      const index = route?.access_levels?.findIndex(({ id }) => id === levelId);
      if (index > -1) {
        route.access_levels[index].value = Number(value);
        console.log(route.access_levels);
        const promise = routeService.update(route._id, { access_levels: route.access_levels });
        promises.push(promise);
      }
    });

    Promise.all(promises).then(() => {
      console.log('updateAccessLevelsInGroups succeded');
    });
  },

  /*
   * Save new level
   * @param {string} payload - get props: levelNameToInsert,levelDescriptionsToInsert ,levelDescriptionsToInsert
   * return {data} after save was successful
   */
  saveLevel({ commit }, payload) {
    const levelNameToInsert = payload.levelNameToInsert;
    const levelDescriptionsToInsert = payload.levelDescriptionsToInsert;
    const levelValueToInsert = payload.levelValueToInsert;

    return accessLevelService
      .add({
        data: {
          name: levelNameToInsert,
          description: levelDescriptionsToInsert,
          value: levelValueToInsert
        }
      })
      .then(data => {
        console.log('Save new level has succeeded: ', data);
      })
      .catch(error => {
        console.log('Save new level has failed: ', error);
      });
  },
  /*
   * Update level
   * @param {object} payload - get props: levelNameToInsert,levelDescriptionsToInsert ,levelDescriptionsToInsert
   */
  updateLevel({ commit }, payload) {
    const levelNameToInsert = payload.levelNameToInsert;
    const levelDescriptionsToInsert = payload.levelDescriptionsToInsert;
    const levelValueToInsert = payload.levelValueToInsert;
    const levelKey = payload.levelKey;

    return accessLevelService
      .update(levelKey, {
        name: levelNameToInsert,
        description: levelDescriptionsToInsert,
        value: levelValueToInsert
      })
      .then(() => {
        console.log('Update level has succeeded! ');
      })
      .catch(error => {
        console.log('Update level has failed: ', error);
      });
  },
  /*
   * Delete level
   * @param {object} payload - get props: key (level key)
   */
  deleteLevel({ commit }, payload) {
    const key = payload.key;
    return accessLevelService
      .delete(key)
      .then(() => {
        console.log('Delete level has succeeded!');
      })
      .catch(error => {
        console.log('Delete level has failed: ', error);
      });
  },
  // Groups component
  /*
   * Update access level in user if AL was chagend in group
   * if no access level prop was defined for the  user, create new one
   * @param {object} payload - get props: key(of the group), groupNameToInsertInLevels
   */
  async updateAccessLevelsInUsers({ dispatch }, { access_levels, group_id }) {
    const usersInGroup = await dispatch('getUsersInGroup', { id: group_id });
    const updatePromises = [];
    usersInGroup.forEach(user => {
      const userAccessLevels = user.access_levels;
      const accessLevelsIds = access_levels.map(level => level.id);
      const updatedAccessLevels = [...new Set([...userAccessLevels, ...accessLevelsIds])];
      updatePromises.push(userService.update(user._id, { access_levels: updatedAccessLevels }));
    });
    return Promise.all(updatePromises);
  },

  async fetchAllGroups(_context) {
    try {
      const groups = await userGroupsService.getAll({ isArray: true, isIdReturned: true });
      return groups;
    } catch (error) {
      console.log('[ fetchAllGroups ] error:', error);
      return error;
    }
  },

  async getGroupById(_context, { group_id }) {
    try {
      const group = await userGroupsService.getById(group_id);
      return group;
    } catch (error) {
      console.log('[ fetchAllGroups ] error:', error);
      return error;
    }
  },

  async saveGroup(_context, { name, access_levels }) {
    try {
      const groupKey = await userGroupsService.add({
        data: { name, access_levels, creation_date: firestoreTimestamp.fromDate(new Date()) }
      });
      return groupKey;
    } catch (error) {
      console.log('[ saveGroup ] error:', error);
      return error;
    }
  },

  async updateGroup(_context, { name, access_levels, group_id }) {
    try {
      const groupKey = await userGroupsService.update(group_id, { name, access_levels });
      return groupKey;
    } catch (error) {
      console.log('[ updateGroup ] error:', error);
      return error;
    }
  },

  deleteGroup(_context, { group_id }) {
    return userGroupsService.delete(group_id);
  },

  async deleteGroupIdFromUsers({ dispatch }, { group_data }) {
    const usersInGroup = await dispatch('getUsersInGroup', { id: group_data._id });
    const userUpdatedPromises = [];

    usersInGroup.forEach(user => {
      const userGroups = user.groups.map(group => group.id);

      if (userGroups.indexOf(group_data._id) > -1) {
        const updatedUserGroups = user.groups.filter(group => group.id !== group_data._id);
        console.log('updated User Groups:', updatedUserGroups);

        const updatedUserAccessLevels = user.access_levels.filter(levelId => {
          const groupLevels = group_data.access_levels.map(level => level.id);
          return !groupLevels.includes(levelId);
        });
        userUpdatedPromises.push(userService.update(user._id, { groups: updatedUserGroups, access_levels: updatedUserAccessLevels }));
      }
    });
    return Promise.all(userUpdatedPromises);
  },

  async getUsersInGroup(_context, { id }) {
    try {
      const users = await userService.getAll({ isArray: true, isIdReturned: true });
      const usersInSelectedGroup = [];
      users.forEach(user => {
        const userInGroup = user.groups?.find(group => group.id === id);
        if (userInGroup) {
          usersInSelectedGroup.push(user);
        }
      });
      return usersInSelectedGroup;
    } catch (error) {
      console.log('[ getUsersInGroup ] error:', error);
      return error;
    }
  },

  /*
   * Delete group from user data groups and access_levels property
   * @param { group_id: string, user_id: string }
   */
  async deleteGroupFromUser(_context, { group_data, user_data }) {
    console.log('[ deleteGroupFromUser ] group_data:', group_data, user_data);
    const updatedUserGroups = user_data.groups.filter(group => group.id !== group_data._id);
    const updatedUserAccessLevels = user_data.access_levels.filter(levelId => {
      const groupLevels = group_data.access_levels.map(level => level.id);
      return !groupLevels.includes(levelId);
    });

    try {
      const updatedUser = await userService.update(user_data._id, { groups: updatedUserGroups, access_levels: updatedUserAccessLevels });
      return updatedUser;
    } catch (error) {
      console.log('[ deleteGroupFromUser ] error:', error);
      return error;
    }
  }
};

const getters = {
  levels(state) {
    return state.levels;
  },
  levelsPermissions(state) {
    return state.levelsPermissions;
  },
  levelsParam(state) {
    return state.levelParameters;
  },
  levelsValExist(state) {
    return state.levelValueExist;
  },
  groupExistInLevels(state) {
    return state.groupExist;
  },
  routes(state) {
    return state.routes;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
