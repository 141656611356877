import { dbFS, storageRef, db, storage, auth } from '../../services/firebaseInit.js';
import algoliasearch from 'algoliasearch';
import siteSettingsService from '../../services/siteSettingsService';
import { cloudFunctionsPost } from '../../modules/cloud-function-wrapper.js';
import mapsService from '../../services/mapsService.js';

const state = {
  orders: null,
  selectedOrder: null,
  siteName: null,
  filename: '',
  customers: [],
  customer: {},
  firebaseUrl: '',
  orderDataList: ['artwork', 'assets', 'customer', 'delivery', 'mailing', 'pricing', 'product', 'additional_services'],
  deleteReport: {
    orderData: '',
    deleteOrderFromAlgoliaOrderIndex: '',
    deleteOrderFromAlgoliaCustomIndex: '',
    orderLogs: '',
    firebaseOrder: '',
    deleteAll: ''
  },
  deleteMapReport: {},
  triggeredOrder: null,
  orderData: null,
  searchClient: algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_API_KEY)
};

const mutations = {
  setSelectedOrder(state, payload) {
    state.selectedOrder = payload;
  },
  setSiteName(state, payload) {
    state.siteName = payload;
  },
  setFilename(state, payload) {
    state.filename = payload;
  },
  setCustomers(state, payload) {
    state.customers = payload;
  },
  updateCustomerMutation(state, payload) {
    const customersList = state.customers;
    customersList.forEach((value, index) => {
      if (value.firestoreId === payload.key) {
        state.customers.splice(index, 1, {
          apikey: payload.apikey,
          email: payload.email,
          idcust: payload.idcust,
          name: payload.name,
          firestoreId: payload.firestoreId,
          status: payload.status
        });
      }
    });
  },
  deleteCustomer(state, payload) {
    const customerList = state.customers;
    customerList.forEach((value, index) => {
      if (value.firestoreId === payload.key) {
        state.customers.splice(index, 1);
      }
    });
  },
  selectedCustomer(state, payload) {
    state.customer = payload;
  },
  setFirebaseUrl(state, payload) {
    state.firebaseUrl = payload;
  },
  setDeleteReport(state, payload) {
    state.deleteReport = payload.deleteReport;
  },
  setMapDeleteReport(state, payload) {
    state.deleteMapReport = payload.deleteReport;
  },

  setTriggeredOrder(state, triggeredOrder) {
    state.triggeredOrder = triggeredOrder;
  },
  setOrderData(state, orderData) {
    state.orderData = orderData;
  }
};

const getters = {
  getSelectedOrder(state) {
    return state.selectedOrder;
  },
  getSiteName(state) {
    return state.siteName;
  },
  filterFilename(state) {
    const text = state.filename;
    if (!text) return '';
    let modifiedText = text.toString();
    modifiedText = modifiedText.toLowerCase();
    modifiedText = modifiedText.replace(/["']/g, '');
    modifiedText = modifiedText.replace(/:/g, '_');
    modifiedText = modifiedText.replace(/[/]/g, '_');
    modifiedText = modifiedText.replace(/\s+/g, '_');
    return modifiedText;
  },
  getCustomers(state) {
    return state.customers;
  },
  getCustomer(state) {
    return state.customer;
  },
  filterFirebaseUrl(state) {
    const url = state.firebaseUrl;
    if (!url) return '';

    const rootUrl = process.env.VUE_APP_FIREBASE_STORAGE_URL;
    const split = url.split('?');
    const fireabseUrl = split[0];
    let split1 = fireabseUrl.split(rootUrl);
    const filePath = split1[1];
    return filePath;
  },
  getOrderDataList(state) {
    return state.orderDataList;
  },
  getDeleteReport(state) {
    return state.deleteReport;
  },
  getMapDeleteReport(state) {
    return state.deleteMapReport;
  },

  getTriggeredOrder(state) {
    return state.triggeredOrder;
  },
  getOrderData(state) {
    return state.orderData;
  },
  getSearchClient(state) {
    return state.searchClient;
  }
};

const actions = {
  async listOrderData({ commit }, payload) {
    const documentID = payload.documentId;
    let tree = [];
    let values = [];
    let companyName = '';
    let wp_customer_id = null;
    let isSuppression = false;
    return dbFS
      .collection('orders')
      .doc(documentID)
      .collection('order_data')
      .get()
      .then(querySnapshot => {
        let orderData = {};
        querySnapshot.forEach(doc => {
          const parentKey = doc.id;
          const parentObj = doc.data();
          if (doc.id === 'customer') {
            const customerParent = doc.data();
            orderData.customer = doc.data();
            Object.entries(customerParent).forEach(([key, value]) => {
              if (key === 'additional') {
                companyName = value.company_name || null;
              }
              if (key === 'wp_customer_id') {
                wp_customer_id = value;
              }
            });
          } else {
            orderData[doc.id] = doc.data();
          }
          let childrenLvl2 = [];
          Object.entries(parentObj).forEach(([key, value]) => {
            if (key === 'suppression_list') {
              isSuppression = true;
            } else {
              isSuppression = false;
            }
            if (typeof value === 'object' && value !== null) {
              let childrenLvl3 = [];
              Object.entries(value).forEach(([key1, value1]) => {
                let childrenLvl4 = [];
                if (typeof value1 === 'object' && value1 !== null) {
                  Object.entries(value1).forEach(([key2, value2]) => {
                    let childrenLvl5 = [];
                    if (typeof value2 === 'object' && value2 !== null) {
                      Object.entries(value2).forEach(([key3, value3]) => {
                        childrenLvl5.push({
                          name: key3 + ' : ' + value3,
                          file: 'txt'
                        });
                        values.push({
                          value: key3 + ' : ' + value3,
                          path: parentKey + '/' + key + '/' + key1 + '/' + key2
                        });
                      });
                      childrenLvl4.push({
                        name: key2,
                        children: childrenLvl5
                      });
                    } else {
                      let dynamicKey = '';
                      if (isSuppression && key2 === 'url') {
                        dynamicKey = 'suppression_list_url';
                      } else {
                        dynamicKey = key2;
                      }
                      childrenLvl4.push({
                        name: dynamicKey + ' : ' + value2,
                        file: 'txt'
                      });
                      values.push({
                        value: key2 + ' : ' + value2,
                        path: parentKey + '/' + key + '/' + key1
                      });
                    }
                  });
                  childrenLvl3.push({
                    name: key1,
                    children: childrenLvl4
                  });
                } else {
                  childrenLvl3.push({
                    name: key1 + ' : ' + value1,
                    file: 'txt'
                  });
                  values.push({
                    value: key1 + ' : ' + value1,
                    path: parentKey + '/' + key
                  });
                }
              });
              childrenLvl2.push({
                name: key,
                children: childrenLvl3
              });
              childrenLvl3 = [];
            } else {
              childrenLvl2.push({
                name: key + ' : ' + value,
                file: 'txt'
              });
              values.push({
                value: key + ' : ' + value,
                path: parentKey + '/'
              });
            }
          });
          tree.push({
            name: doc.id,
            children: childrenLvl2
          });
          childrenLvl2 = [];
        });

        commit('setOrderData', orderData);
        return {
          tree,
          companyName,
          wp_customer_id
        };
      });
  },
  async listSelectedList({ commit }, payload) {
    return dbFS
      .collection('mailingLists')
      .doc(payload.listID)
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.data();
        const siteDbId = data.siteDbId;

        siteSettingsService
          .getById(siteDbId)
          .then(siteSettings => {
            commit('setSiteName', siteSettings.site_name);
          })
          .catch(error => {
            console.log('listSelectedList has failed:', error);
          });

        let summary = [];
        let list = [];
        let storagePath = '';
        Object.entries(data).forEach(([key, value]) => {
          if (key === 'creationDate') {
            summary.push({
              key: 'Date',
              value: value.seconds
            });
          }
          if (key === 'listCount') {
            summary.push({
              key: 'Count',
              value: value
            });
          }
          if (key === 'siteDbId') {
            summary.push({
              key: 'Site Name',
              value: value
            });
          }
          if (key === 'storagePath') {
            summary.push({
              key: 'Storage Path',
              value: value
            });
            storagePath = value;
          }
          if (key === 'downloadUrl') {
            summary.push({
              key: 'CSV file',
              value: value
            });
            storagePath = value;
          }
          if (key === 'customer') {
            Object.entries(value).forEach(([key1, value1]) => {
              if (key1 === 'clientId') {
                summary.push({
                  key: 'Client Id',
                  value: value1
                });
              }
              if (key1 === 'first_name' && value1) {
                summary.push({
                  key: 'First Name',
                  value: value1
                });
              }
              if (key1 === 'last_name' && value1) {
                summary.push({
                  key: 'Last Name',
                  value: value1
                });
              }
              if (key1 === 'crmCustomerId') {
                summary.push({
                  key: 'CRM Customer ID',
                  value: value1
                });
              }
              if (key1 === 'userEmail' && value1) {
                summary.push({
                  key: 'User Email',
                  value: value1
                });
              }
            });
          }
          if (data.type === 'upload') {
            if (key === 'processedUploadedList') {
              summary.push({
                key: key,
                value: value.s3file
              });
              list = value.list_preview_data.list;
            }
          } else if (data.type === 'order') {
            if (key === 'totalPrice') {
              summary.push({
                key: 'Total Price',
                value: value.toFixed(2)
              });
            }
          }
        });
        return {
          data,
          list,
          summary,
          storagePath
        };
      });
  },
  async listSelectedListMap({ commit }, payload) {
    return dbFS
      .collection('mailingLists')
      .doc(payload.referenceId)
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.data();
        const siteDbId = data.siteDbId;
        siteSettingsService
          .getById(siteDbId)
          .then(siteSettings => {
            commit('setSiteName', siteSettings.site_name);
          })
          .catch(error => {
            console.log('listSelectedListMap has failed: ', error);
          });
        const type = data.type;
        const name = data.listName;
        let summary = [];
        Object.entries(data).forEach(([key, value]) => {
          if (key === 'creationDate') {
            summary.push({
              key: 'Date',
              value: value.seconds
            });
          }
          if (key === 'listCount') {
            summary.push({
              key: 'Count',
              value: value
            });
          }
          if (key === 'siteDbId') {
            summary.push({
              key: 'Site Name',
              value: value
            });
          }
          if (key === 'userEmail') {
            summary.push({
              key: 'User Email',
              value: value
            });
          }
          if (key === 'crmCustomerId') {
            summary.push({
              key: 'CRM ID',
              value: value
            });
          }
          if (key === 'storagePath') {
            summary.push({
              key: 'Storage Path',
              value: value
            });
          }
          if (key === 'downloadUrl') {
            summary.push({
              key: 'CSV file',
              value: value
            });
          }
        });
        return {
          summary,
          type,
          name
        };
      });
  },
  async listSelectedMap({ commit }, payload) {
    return dbFS
      .collection('maps')
      .doc(payload.mapID)
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.data();
        if (data === undefined) {
          return false;
        } else {
          const siteDbId = data.siteDbId;
          siteSettingsService
            .getById(siteDbId)
            .then(siteSettings => {
              commit('setSiteName', siteSettings.site_name);
            })
            .catch(error => console.log('[siteSettingsService]:', error));

          let summary = [];
          let mapState = [];
          let newMovers = [];
          let availability = [];
          let downloadStorageUrl = '';
          let mapImage = data.mapImgUrl;
          Object.entries(data).forEach(([key, value]) => {
            if (key === 'creationDate') {
              summary.push({
                key: 'Date',
                value: value.seconds
              });
            }
            if (key === 'emapMode') {
              summary.push({
                key: 'Emap Mode',
                value: value
              });
            }
            if (key === 'siteDbId') {
              summary.push({
                key: 'Site Name',
                value: value
              });
            }
            if (key === 'totalCount') {
              summary.push({
                key: 'Count',
                value: value
              });
            }
            if (key === 'orderType') {
              summary.push({
                key: 'Order Type',
                value: value
              });
            }
            if (key === 'uploadedFileUrl') {
              summary.push({
                key: 'Uploaded File Url',
                value: value
              });
            }
            if (key === 'downloadUrlExcel') {
              summary.push({
                key: 'Excel Url',
                value: value
              });
            }
            if (key === 'mapImgUrl') {
              summary.push({
                key: 'Firebase Storage Map Image Url',
                value: value
              });
            }
            if (key === 'mapImgName') {
              summary.push({
                key: 'Firebase Map Image Name',
                value: value
              });
            }
            if (key === 'customer') {
              Object.entries(value).forEach(([key1, value1]) => {
                if (key1 === 'clientId') {
                  summary.push({
                    key: 'Client Id',
                    value: value1
                  });
                }
                if (key1 === 'first_name' && value1) {
                  summary.push({
                    key: 'First Name',
                    value: value1
                  });
                }
                if (key1 === 'last_name' && value1) {
                  summary.push({
                    key: 'Last Name',
                    value: value1
                  });
                }
                if (key1 === 'crmCustomerId') {
                  summary.push({
                    key: 'CRM Customer ID',
                    value: value1
                  });
                }
                if (key1 === 'email') {
                  summary.push({
                    key: 'Email',
                    value: value1
                  });
                }
              });
            }
            if (data.orderType === 'eddm') {
              if (key === 'mapState') {
                Object.assign(mapState, value);
                Object.entries(value).forEach(([key1, value1]) => {
                  mapState.push({
                    key: key1,
                    value: value1
                  });
                });
              }
            }
            if (data.orderType === 'newmovers') {
              if (key === 'moverCounts') {
                Object.entries(value).forEach(([key1, value1]) => {
                  if (key1 === 'avg') {
                    newMovers.push({
                      key: 'Mover Counts - ' + key1,
                      value: value1
                    });
                  }
                  if (key1 === 'max') {
                    newMovers.push({
                      key: 'Mover Counts - ' + key1,
                      value: value1
                    });
                  }
                  if (key1 === 'min') {
                    newMovers.push({
                      key: 'Mover Counts - ' + key1,
                      value: value1
                    });
                  }
                });
              }
              if (key === 'csvLink') {
                newMovers.push({
                  key: key,
                  value: value
                });
              }
              if (key === 'downloadUrl') {
                newMovers.push({
                  key: 'Storage URL',
                  value: value
                });
                downloadStorageUrl = value;
              }
              if (key === 'downloadUrlDMM') {
                newMovers.push({
                  key: 'DMM - CSV Download Url',
                  value: value
                });
              }
              if (key === 'storagePath') {
                newMovers.push({
                  key: 'Storage Path',
                  value: value
                });
              }
            }
            if (data.orderType === 'availability') {
              if (key === 'estimate') {
                availability.push({
                  key: 'Estimate',
                  value: value
                });
              }
              if (key === 'week') {
                availability.push({
                  key: 'Week',
                  value: value
                });
              }
              if (key === 'mapState') {
                Object.entries(value).forEach(([key1, value1]) => {
                  availability.push({
                    key: 'Map state - ' + key1,
                    value: value1
                  });
                });
              }
            }
          });
          return {
            data,
            summary,
            mapState,
            newMovers,
            availability,
            downloadStorageUrl,
            mapImage
          };
        }
      });
  },
  async listSelectedMapSummary({ commit }, payload) {
    return dbFS
      .collection('maps')
      .doc(payload.referenceId)
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.data();
        const siteDbId = data.siteDbId;
        siteSettingsService
          .getById(siteDbId)
          .then(siteSettings => {
            commit('setSiteName', siteSettings.site_name);
          })
          .catch(error => {
            console.log('listSelectedMapSummary has failed: ', error);
          });
        const name = data.name;
        const type = data.orderType;

        let summary = [];
        Object.entries(data).forEach(([key, value]) => {
          if (key === 'creationDate') {
            summary.push({
              key: 'Date',
              value: value.seconds
            });
          }
          if (key === 'emapMode') {
            summary.push({
              key: 'Emap Mode',
              value: value
            });
          }
          if (key === 'siteDbId') {
            summary.push({
              key: 'Site Name',
              value: value
            });
          }
          if (key === 'totalCount') {
            summary.push({
              key: 'Count',
              value: value
            });
          }
          if (key === 'orderType') {
            summary.push({
              key: 'Order Type',
              value: value
            });
          }
          if (key === 'uploadedFileUrl') {
            summary.push({
              key: 'Uploaded File Url',
              value: value
            });
          }
          if (key === 'mapImgUrl') {
            summary.push({
              key: 'Firebase Storage Map Image Url',
              value: value
            });
          }
          if (key === 'mapImgName') {
            summary.push({
              key: 'Firebase Map Image Name',
              value: value
            });
          }
        });
        return {
          summary,
          name,
          type
        };
      });
  },
  checkStoragePathMailingLists({ commit }, payload) {
    const firebaseId = payload.firebaseID;
    if (!payload.storagePath.length) {
      return false;
    } else {
      const path = payload.storagePath.split('/');
      const pathFolder = path[0];
      const filename = path[1];
      const storageInfogroupChild = storageRef.child(pathFolder);
      const csvFileRef = storageInfogroupChild.child(filename);
      return csvFileRef
        .getDownloadURL()
        .then(url => {
          return dbFS
            .collection('mailingLists')
            .doc(firebaseId)
            .update({ downloadUrl: url })
            .then(() => {
              return { url };
            });
        })
        .catch(error => {
          console.error('Error : ', error.message);
          return { error: error.message };
        });
    }
  },
  async clearMapsStoragePath({ commit }, payload) {
    try {
      const firebaseId = payload.firebaseID;
      const storageFilePath = payload.storageFilePath;

      await dbFS.collection('maps').doc(firebaseId).update({
        status: '',
        storagePath: '',
        downloadUrl: ''
      });

      const storageReference = storage.ref(storageFilePath);
      await storageReference.delete();
      console.log('CSV file deleted!');

      return {
        status: true
      };
    } catch (err) {
      console.error('Error: ', err);
      return {
        error: err
      };
    }
  },
  async listSelectedOrder({ dispatch }, payload) {
    return dbFS
      .collection('orders')
      .doc(payload.orderID)
      .get()
      .then(async querySnapshot => {
        const order = await dispatch('formatOrderDoc', querySnapshot.data());
        return order;
      });
  },
  getSummary({ dispatch }, payload) {
    const refName = payload.refName;
    const referenceId = payload.referenceId;
    if (refName === 'maps') {
      return dispatch('listSelectedMapSummary', { referenceId }).then(response => {
        console.log('response', response);
        return {
          refName,
          data: response
        };
      });
    } else if (refName === 'customDesigns') {
      return dispatch('listSelectedDesignMap', { referenceId }).then(response => {
        console.log('response', response);
        return {
          refName,
          data: response
        };
      });
    } else if (refName === 'mailingLists') {
      return dispatch('listSelectedListMap', { referenceId }).then(response => {
        console.log('response', response);
        return {
          refName,
          data: response
        };
      });
    }
  },
  async addImage({ commit, getters }, payload) {
    const file = payload.file;
    const fieldName = payload.fieldName;
    const campaignName = payload.campaignName;
    const subFolderName = payload.subFolderName ? payload.subFolderName : '';

    const filename = file.name;
    const removeExt = filename.split('.');
    const filenameWithoutExt = removeExt[0];
    const fileExtension = removeExt[1];
    commit('setFilename', filenameWithoutExt);
    const timestamp = Number(new Date());
    const storageFileName = `${campaignName ? `${campaignName}_` : ''}${getters.filterFilename}_${timestamp}.${fileExtension}`;
    const storageImgfRef = storage.ref(`new-mover-campaign/${subFolderName ? `${subFolderName}/` : ''}${storageFileName}`);

    return storageImgfRef
      .put(file)
      .then(snapshot => {
        return storageRef.child(`new-mover-campaign/${subFolderName ? `${subFolderName}/` : ''}${storageFileName}`).getDownloadURL();
      })
      .then(downloadURL => {
        console.log(
          JSON.stringify({
            downloadURL: downloadURL,
            fieldName: fieldName,
            filename: storageFileName
          })
        );
        return {
          downloadURL: downloadURL,
          fieldName: fieldName,
          filename: storageFileName
        };
      })
      .catch(err => {
        console.log('err:', err);
      });
  },
  async removeCustomer({ commit }, payload) {
    return dbFS
      .collection('dmmCustomers')
      .doc(payload.customer.firestoreId)
      .delete()
      .then(() => {
        commit('deleteCustomer', {
          key: payload.customer.firestoreId
        });
        return {
          status: 'success',
          message: 'Customer: ' + payload.customer.firestoreId + ' deleted successfully!'
        };
      })
      .catch(error => {
        console.error('Error removing document: ', error);
        return {
          status: 'error',
          message: error
        };
      });
  },
  async listDmmCustomers({ commit }, payload) {
    let customersList = [];
    return dbFS
      .collection('dmmCustomers')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          let data = doc.data();
          data.firestoreId = doc.id;
          customersList.push(data);
        });
        commit('setCustomers', customersList);
      });
  },
  async createNewCustomer({ commit }, payload) {
    return siteSettingsService
      .getById(payload.firebase_site_id)
      .then(({ brand_web_id: brandId }) => {
        const newCustomer = {
          ...payload,
          stripe_customer: '',
          brand_id: brandId
        };
        const toUrlEncoded = obj =>
          Object.keys(obj)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
            .join('&');
        const formData = toUrlEncoded(newCustomer);

        const httpConfig = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        };

        return cloudFunctionsPost('/api/customers', formData, httpConfig)
          .then(result => {
            console.log('Stripe customer added:', result.data.Data.Customer);
            return Promise.resolve(Object.assign(result.data.Data.Customer.data, { id: result.data.Data.Customer.id }));
          })
          .catch(error => {
            console.log('Adding Stripe customer has failed:', error.response);
            return Promise.reject(error.response);
          });
      })
      .catch(error => {
        console.log('Getting brandId failed:', error);
        return Promise.reject(error);
      });
  },
  customerChangeStatus({ commit }, payload) {
    const promises = [];
    let listOfFirebaseIds = [];
    const selectedCustomerFirebaseId = payload.customer.firestoreId;
    if (payload.customer.status !== 'OFF') {
      return dbFS
        .collection('dmmCustomers')
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            listOfFirebaseIds.push(doc.id);
          });
          listOfFirebaseIds.forEach((element, index) => {
            const promise = dbFS
              .collection('dmmCustomers')
              .doc(element)
              .update({
                status: 'OFF'
              })
              .catch(error => {
                console.log('Update stats failed on customer' + element + '| error:', error);
              });
            promises.push(promise);
          });
          return Promise.all(promises).then(() => {
            console.log('All Customer updated with status OFF!');
            return dbFS
              .collection('dmmCustomers')
              .doc(selectedCustomerFirebaseId)
              .update({ status: 'ON' })
              .then(data => {
                commit('selectedCustomer', {
                  firebaseID: selectedCustomerFirebaseId,
                  email: payload.customer.email,
                  'name:': payload.customer.name
                });
                return {
                  status: 'success'
                };
              })
              .catch(error => {
                console.log('Update has failed: ', error);
                return {
                  status: 'error',
                  message: 'Update has failed!'
                };
              });
          });
        });
    } else {
      console.log('changed to OFF');
      return {
        status: 'OFFerror',
        message: 'Failed to update Status! At least one customer needs to be ON!',
        customer: payload
      };
    }
  },
  initRealtimeListenersForDmmCustomersList({ commit }, payload) {
    dbFS.collection('dmmCustomers').onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'modified') {
          commit('updateCustomerMutation', {
            apikey: change.doc.data().apikey,
            email: change.doc.data().email,
            idcust: change.doc.data().idcust,
            name: change.doc.data().name,
            status: change.doc.data().status,
            firestoreId: change.doc.id,
            key: change.doc.id
          });
        }
        if (change.type === 'removed') {
          commit('deleteCustomer', {
            key: change.doc.id
          });
        }
      });
    });
  },
  async fetchActiveCustomer({ commit }, payload) {
    return dbFS
      .collection('dmmCustomers')
      .where('status', '==', 'ON')
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          if (querySnapshot.size > 1) {
            return {
              status: 'error',
              message: 'More then one result found!'
            };
          } else {
            querySnapshot.forEach(doc => {
              commit('selectedCustomer', {
                firebaseID: doc.id,
                email: doc.data().email,
                'name:': doc.data().name
              });
            });
            return {
              status: 'success',
              message: 'Fetch Success'
            };
          }
        } else {
          return {
            status: 'error',
            message: 'No ON status found!'
          };
        }
      });
  },
  async checkDuplicatedMaps({ commit }, payload) {
    let isMapDuplicated = {};
    let promises = [];
    if (payload.uploadedFileUrl) {
      promises.push(
        mapsService.checkUploadedFileUrlInMaps(payload.uploadedFileUrl, payload.mapId).then(response => {
          isMapDuplicated.uploadedFileUrl = response;
          return Promise.resolve();
        })
      );
    }
    if (payload.mapImgUrl) {
      promises.push(
        mapsService.checkMapImgUrlInMaps(payload.mapImgUrl, payload.mapId).then(response => {
          isMapDuplicated.mapImgUrl = response;
          return Promise.resolve();
        })
      );
    }
    return Promise.all(promises).then(() => {
      console.log('isMapDuplicated after promises:', isMapDuplicated);
      return Promise.resolve({ isMapDuplicated: Object.values(isMapDuplicated).some(value => value === true) });
    });
  },
  async checkMapReferences({ commit, getters }, payload) {
    let mapRefList = [];
    const mapFireabseId = payload.firebaseID;
    return dbFS
      .collection('maps')
      .doc(payload.firebaseID)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.exists) {
          const data = querySnapshot.data();
          const mapImageName = data.mapImgName;
          const uploadedFileUrl = data.uploadedFileUrl;

          if (mapImageName !== undefined) {
            const mapImgUrlDecoded = decodeURIComponent(data.mapImgUrl);
            commit('setFirebaseUrl', mapImgUrlDecoded);
            const mapFilePath = getters.filterFirebaseUrl;
            mapRefList.push({
              mapImgUrlDecoded,
              mapImageName,
              mapFilePath,
              mapFireabseId
            });
          }

          if (uploadedFileUrl !== undefined) {
            const JSONUrlDecoded = decodeURIComponent(uploadedFileUrl);
            commit('setFirebaseUrl', JSONUrlDecoded);
            const JSONFilePath = getters.filterFirebaseUrl;
            mapRefList.push({
              JSONUrlDecoded,
              JSONFilePath
            });
          }
          return {
            status: 'success',
            mapRefList
          };
        } else {
          return {
            status: 'error',
            message: 'firebase ID:' + payload.firebaseID + ' does not exist!'
          };
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  async deleteOrder({ commit, dispatch, getters }, payload) {
    const orderFirebaseID = payload.orderObject.firebase_order_id;
    const orderLogFirebaseID = payload.orderLogId;
    const siteDbID = payload.orderObject.site_db_id;
    const algoliaCustomIndex = 'site_' + siteDbID + '_orders';
    const algoliaOrdersIndex = `orders${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`;
    const artwork = payload.artwork;
    const deleteReferences = payload.deleteReferences;
    const mapReferences = payload.mapReferences;
    const clientAlgolia = getters.getSearchClient;
    const user = auth.currentUser;

    if (artwork.length > 0) {
      const promisesArtwork = [];
      artwork.forEach(value => {
        const artworkFileName = value.artworkName;
        const artworkFilePath = value.artworkPath;
        promisesArtwork.push(
          dispatch('deleteFileFromStorage', { path: artworkFilePath, fileName: artworkFileName }).catch(err => {
            console.log(err);
            return Promise.reject();
          })
        );
      });

      // TODO: delete all after the artwork files are deleted ?!
      Promise.all(promisesArtwork).then(() => {
        console.log(' --- Artwork files deleted!');
        commit('setDeleteReport', {
          deleteReport: {
            artworkFiles: 'success'
          }
        });
      });
    }

    const promises = [];
    // TODO: get the order data (array) from order_data subcollection
    // TODO: check retunr "error" from delete ItemFromAlgolia() and pass by the error to front end
    getters.getOrderDataList.forEach(value => {
      promises.push(
        dbFS
          .collection('orders')
          .doc(orderFirebaseID)
          .collection('order_data')
          .doc(value)
          .delete()
          .then(() => {
            console.log(value + ' Order data ... Deleted');
            return Promise.resolve();
          })
          .catch(err => {
            console.log(err);
            return Promise.reject();
          })
      );
    });

    return Promise.all(promises)
      .then(async () => {
        commit('setDeleteReport', {
          deleteReport: {
            orderData: 'success'
          }
        });
        // delete Rferences : only maps
        if (deleteReferences) {
          if (mapReferences.status === 'success') {
            const mapRef = mapReferences.mapRefList;
            const algoliaCustomIndex = 'site_' + siteDbID + '_maps';
            const algoliaMapsIndex = 'maps';

            if (mapRef.length > 1) {
              const imageDoc = mapRef[0];
              const jsonDoc = mapRef[1];
              const mapFirebaseId = imageDoc.mapFireabseId;
              // TODO: make orderFirebaseID general not related to order
              const orderFirebaseID = mapFirebaseId;
              const mapImageFilePath = imageDoc.mapFilePath;
              const mapImageName = imageDoc.mapImageName;
              const jsonFilePath = jsonDoc.JSONFilePath;
              const jsonFileName = jsonFilePath.split('\\').pop().split('/').pop();
              const promises = [];

              promises.push(
                dispatch('deleteFileFromStorage', { path: mapImageFilePath, fileName: mapImageName })
                  .then(result => {
                    console.log('result deleteMapReferences map image: ', result);
                    commit('setDeleteReport', {
                      deleteReport: {
                        mapImage: 'success'
                      }
                    });
                  })
                  .catch(err => {
                    console.log('Delete map references error:', err);
                  })
              );

              promises.push(
                dispatch('deleteFileFromStorage', { path: jsonFilePath, fileName: jsonFileName })
                  .then(result => {
                    console.log('result deleteMapReferences map Json: ', result);
                    commit('setDeleteReport', {
                      deleteReport: {
                        mapJSONFile: 'success'
                      }
                    });
                  })
                  .catch(err => {
                    console.log('Delete file from storage error: ', err);
                  })
              );

              return Promise.all(promises).then(() => {
                console.log('All Map  References are deleted!');
                const promises = [];
                // Algolia - delete map from orders index
                promises.push(
                  dispatch(
                    'algolia/deleteOrderFromAlgoliaIndex',
                    {
                      clientAlgolia: clientAlgolia,
                      orderID: orderFirebaseID,
                      indexName: algoliaMapsIndex,
                      reportObjName: 'mapFromAlgoliaOrderIndex',
                      deleteType: 'order'
                    },
                    { root: true }
                  )
                );
                // delete map from custom index
                promises.push(
                  dispatch(
                    'algolia/deleteOrderFromAlgoliaIndex',
                    {
                      clientAlgolia: clientAlgolia,
                      orderID: orderFirebaseID,
                      indexName: algoliaCustomIndex,
                      reportObjName: 'mapFromAlgoliaCustomIndex',
                      deleteType: 'order'
                    },
                    { root: true }
                  )
                );
                // Delete map from Firebase
                promises.push(
                  dbFS
                    .collection('maps')
                    .doc(mapFirebaseId)
                    .delete()
                    .then(() => {
                      console.log('Map: ' + mapFirebaseId + ' deleted From Fireabse');
                      commit('setDeleteReport', {
                        deleteReport: {
                          firebaseMap: 'success'
                        }
                      });
                      Promise.resolve();
                    })
                    .catch(error => {
                      console.log('Error on delete ' + mapFirebaseId + ':' + error);
                      commit('setDeleteReport', {
                        deleteReport: {
                          deleteFirebaseMap: 'error'
                        }
                      });
                      Promise.reject();
                    })
                );

                return Promise.all(promises)
                  .then(() => {
                    console.log(' Delete map from Algolia(map and custom index) and from Firebase!');
                    commit('setDeleteReport', {
                      deleteReport: {
                        allMapReferences: 'success'
                      }
                    });
                    return Promise.resolve();
                  })
                  .catch(err => {
                    console.log(`Deleting all map References catch some error: ${err}`);
                    return Promise.reject();
                  });
              });
            }
          }
        }

        const promises = [];
        await Promise.all([
          // algolia - delete order from orders index
          dispatch(
            'algolia/deleteOrderFromAlgoliaIndex',
            {
              clientAlgolia: clientAlgolia,
              orderID: orderFirebaseID,
              indexName: algoliaOrdersIndex,
              reportObjName: 'deleteOrderFromAlgoliaOrderIndex',
              deleteType: 'order'
            },
            { root: true }
          )
            .then(() => {
              console.log(`Delete order from Algolia ${algoliaOrdersIndex} index success!`);
            })
            .catch(err => {
              console.log(`Delete order from Algolia ${algoliaOrdersIndex} index error!`, err);
            }),
          // delete order from custom index
          dispatch(
            'algolia/deleteOrderFromAlgoliaIndex',
            {
              clientAlgolia: clientAlgolia,
              orderID: orderFirebaseID,
              indexName: algoliaCustomIndex,
              reportObjName: 'deleteOrderFromAlgoliaCustomIndex',
              deleteType: 'order'
            },
            { root: true }
          )
            .then(() => {
              console.log(`Delete order from Algolia ${algoliaCustomIndex} index success!`);
            })
            .catch(err => {
              console.log(`Delete order from Algolia ${algoliaCustomIndex} index error!`, err);
            })
        ]);

        // Delete order from Firebase
        promises.push(
          dbFS
            .collection('orders')
            .doc(orderFirebaseID)
            .delete()
            .then(() => {
              console.log(`Order: ${orderFirebaseID} deleted From Firebase.`);
              commit('setDeleteReport', {
                deleteReport: {
                  firebaseOrder: 'success'
                }
              });
              return Promise.resolve();
            })
            .catch(error => {
              console.log(`Error on delete ${orderFirebaseID} :`, error);
              commit('setDeleteReport', {
                deleteReport: {
                  firebaseOrder: 'error'
                }
              });
              Promise.reject();
            })
        );

        return Promise.all(promises)
          .then(() => {
            console.log('All Order references deleted!');
            commit('setDeleteReport', {
              deleteReport: {
                deleteAllOrderReferences: 'success'
              }
            });
            dispatch('logs/addOrderLog', { firebase_order_id: orderFirebaseID, action: `order deleted by [${user.email}]` }, { root: true });
            return Promise.resolve();
          })
          .catch(err => {
            console.log(`Deleting all catch some error: ${err}`);
            return Promise.reject();
          });
      })
      .catch(err => {
        console.log(`Promise all catch error: ${err}`);
        commit('setDeleteReport', {
          deleteReport: {
            orderData: 'error'
          }
        });
        return Promise.reject();
      });
  },
  deleteMap({ commit, dispatch, getters }, payload) {
    const mapData = payload.mapObject;
    const mapFirebaseId = mapData.firebase_map_id;
    const siteDbID = mapData.siteDbId;
    const algoliaCustomIndex = 'site_' + siteDbID + '_maps';
    const algoliaMapsIndex = `maps${process.env.VUE_APP_ALGOLIA_INDEX_SUFFIX}`;
    const clientAlgolia = getters.getSearchClient;
    const references = payload.mapRef.mapRefList;
    if (references && references.length) {
      const promises = [];
      promises.push(
        dispatch('checkDuplicatedMaps', { uploadedFileUrl: mapData.uploadedFileUrl, mapImgUrl: mapData.mapImgUrl, mapId: mapFirebaseId }).then(response => {
          console.log('RESPONSE:', response);
          if (!response.isMapDuplicated) {
            const imageDoc = references[0];
            const jsonDoc = references[1];
            const mapImageFileName = imageDoc.mapImageName;
            console.log('mapImageFileName:', mapImageFileName);
            const jsonFilePath = jsonDoc.JSONFilePath;
            console.log('jsonFilePath:', jsonFilePath);
            const jsonFileName = jsonFilePath.split('\\').pop().split('/').pop();
            console.log('jsonFileName:', jsonFileName);
            const mapFileStoragePath = jsonFilePath.substring(0, jsonFilePath.lastIndexOf('/'));
            console.log('mapFileStoragePath:', mapFileStoragePath);
            const refPromises = [];
            refPromises.push(
              dispatch('deleteMapReferences', { mapFileStoragePath, fileName: mapImageFileName }).then(result => {
                console.log('result deleteMapReferences map image: ', result);
                commit('setMapDeleteReport', {
                  deleteReport: {
                    deleteMapImage: 'success'
                  }
                });
                return Promise.resolve();
              })
            );
            refPromises.push(
              dispatch('deleteMapReferences', { mapFileStoragePath, fileName: jsonFileName }).then(result => {
                console.log('result deleteMapReferences map image: ', result);
                commit('setMapDeleteReport', {
                  deleteReport: {
                    deleteJsonFile: 'success'
                  }
                });
                return Promise.resolve();
              })
            );
            return Promise.all(refPromises)
              .then(() => {
                console.log('References are deleted!');
                return Promise.resolve();
              })
              .catch(error => {
                console.log('Deleting references failed:', error);
                return Promise.reject();
              });
          }
          return Promise.resolve();
        })
      );
      // Algolia - delete map from orders index
      promises.push(
        dispatch(
          'algolia/deleteOrderFromAlgoliaIndex',
          {
            clientAlgolia: clientAlgolia,
            orderID: mapFirebaseId,
            indexName: algoliaMapsIndex,
            reportObjName: 'deleteMapFromAlgoliaOrderIndex',
            deleteType: 'map'
          },
          { root: true }
        )
      );
      // delete map from custom index
      promises.push(
        dispatch(
          'algolia/deleteOrderFromAlgoliaIndex',
          {
            clientAlgolia: clientAlgolia,
            orderID: mapFirebaseId,
            indexName: algoliaCustomIndex,
            reportObjName: 'deleteMapFromAlgoliaCustomIndex',
            deleteType: 'map'
          },
          { root: true }
        )
      );
      // Delete map from Firebase
      promises.push(
        dbFS
          .collection('maps')
          .doc(mapFirebaseId)
          .delete()
          .then(() => {
            console.log(`Map: ${mapFirebaseId} deleted from Firebase`);
            commit('setMapDeleteReport', {
              deleteReport: {
                firebaseMap: 'success'
              }
            });
            return Promise.resolve();
          })
          .catch(error => {
            console.log('Error on delete ' + mapFirebaseId + ':' + error);
            commit('setMapDeleteReport', {
              deleteReport: {
                deleteFirebaseMap: 'error'
              }
            });
            return Promise.reject();
          })
      );
      return Promise.all(promises)
        .then(() => {
          console.log(' Delete map from Algolia(map and custom index) and from Firebase!');
          commit('setMapDeleteReport', {
            deleteReport: {
              deleteAll: 'success'
            }
          });
          return Promise.resolve();
        })
        .catch(err => {
          console.log(`Deleting all catch some error: ${err}`);
          return Promise.reject();
        });
    }
  },
  async deleteMapReferences({ commit }, payload) {
    const mapFileStoragePath = payload.mapFileStoragePath;
    const fileName = payload.fileName;

    let storageMapRef = storage.ref(mapFileStoragePath + '/' + fileName);
    console.log('storageMapRef', mapFileStoragePath + '/' + fileName);

    return storageMapRef
      .delete()
      .then(() => {
        console.log(fileName + '  -- deleted!');
        return {
          status: 'success'
        };
      })
      .catch(error => {
        console.error('Error on delete: ', error);
        return {
          status: 'error',
          error
        };
      });
  },
  async deleteFileFromStorage({ commit }, payload) {
    const path = payload.path;
    const fileName = payload.fileName;
    let localStorageRef = storageRef.child(path);
    return localStorageRef
      .delete()
      .then(() => {
        console.log(fileName + '  -- deleted!');
        return {
          status: 'success'
        };
      })
      .catch(error => {
        console.error('Error on delete: ', error);
        return {
          status: 'error',
          error
        };
      });
  },
  formatPaymentObject: function (_context, payments) {
    return !Array.isArray(payments) || payments !== '' ? Object.values(payments) : payments;
  },
  async formatOrderDoc({ dispatch }, order) {
    if (order?.hasOwnProperty('payments')) {
      order.payments = await dispatch('formatPaymentObject', order.payments);
    }
    return order;
  },
  triggerOrderChange({ commit, dispatch }, payload) {
    if (payload.orderID) {
      dbFS
        .collection('orders')
        .doc(payload.orderID)
        .onSnapshot(async doc => {
          const order = await dispatch('formatOrderDoc', doc.data());
          console.log('TRIGGER Current data: ', order);
          commit('setTriggeredOrder', order);
        });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
