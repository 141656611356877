import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class MailingListsService extends FirestoreModel {
  constructor() {
    super(collections.mailingLists);
  }

  async clearStoragePath(firebaseID) {
    try {
      const firebaseId = firebaseID;

      await this.collectionRef.doc(firebaseId).update({
        status: '',
        storagePath: '',
        downloadUrl: ''
      });

      return { status: true };
    } catch (err) {
      console.error('clearStoragePath has failed: ', err);
      return { error: err.message };
    }
  }

  async checkMailingListsReferences(firebaseID) {
    try {
      let listRefList = [];
      const querySnapshot = await this.collectionRef.doc(firebaseID).get();

      if (querySnapshot.exists) {
        const data = querySnapshot.data();
        const savedUploadedList = data.savedUploadedList;

        if (savedUploadedList !== undefined) {
          listRefList.push({
            listFileName: savedUploadedList.name,
            listPath: savedUploadedList.path
          });
        }

        return {
          status: 'success',
          listRefList
        };
      }
      return {
        status: 'error',
        message: 'firebase ID:' + firebaseID + ' does not exist!'
      };
    } catch (err) {
      console.error('checkMailingListsReferences has failed: ', err);
    }
  }
}

export default new MailingListsService();
