import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class ProductTypeService extends FirestoreModel {
  constructor() {
    super(collections.product_types);
  }
}

export default new ProductTypeService();
