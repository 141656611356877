<template>
  <v-container fluid>
    <v-row>
      <v-btn icon large @click="navigateBack()"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <span class="ml-3 pt-1 title font-weight-regular">Import templates from default</span>
    </v-row>
    <v-divider class="my-4"></v-divider>

    <SiteSpecificTemplates :isImportMode="true"></SiteSpecificTemplates>
  </v-container>
</template>

<script>
import SiteSpecificTemplates from './SiteSpecificTemplates.vue';

export default {
  components: {
    SiteSpecificTemplates
  },
  computed: {
    siteId() {
      return this.$route.params.siteId;
    }
  },
  methods: {
    navigateBack() {
      this.$router.push({ name: 'siteSpecificTemplates', params: { siteId: this.siteId } });
    }
  }
};
</script>
