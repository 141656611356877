<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="3">
        <p class="subtitle-1">Existing custom fields:</p>
      </v-col>
      <v-col cols="5">
        <v-progress-linear v-if="isCustomFieldsLoading" indeterminate color="primary" class="mt-3"></v-progress-linear>
        <v-row v-else-if="existingCustomFieldsLength">
          <v-chip v-for="(field, index) in existingCustomFields" :key="index" close close-icon="mdi-delete" class="ml-1 mb-1 mt-3" @click:close="openDeleteDialog(field)">
            {{ field.name }}</v-chip
          >
        </v-row>
        <v-row v-else>
          <p class="mt-4 subtitle-2">You do not have any registered custom fields.</p>
        </v-row>
      </v-col>
    </v-row>
    <v-form ref="customFieldForm">
      <v-row v-if="isCustomFieldsLimitReached" class="justify-center">
        <p class="mt-3">You have reached the maximum number of custom fields!</p>
      </v-row>
      <v-container v-else class="mt-3">
        <v-row v-for="(field, index) in customFields" :key="index" class="justify-center">
          <v-col cols="3">
            <v-text-field v-model="field.name" outlined dense placeholder="Name" :rules="fieldNameRules" :error-messages="field.error" :success-messages="field.success" />
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="field.default_value" outlined dense placeholder="Default Value" />
          </v-col>
          <v-col cols="1">
            <v-btn :disabled="isSingleField" @click="removeCustomField(index)">-</v-btn>
          </v-col>
          <v-col cols="1">
            <v-btn v-if="index + 1 === customFields.length && isNewFieldAllowed" @click="addCustomField">+</v-btn>
          </v-col>
        </v-row>
        <v-row class="justify-center mb-2">
          <v-btn color="primary" @click="registerCustomFields">Create Fields</v-btn>
        </v-row>
      </v-container>
    </v-form>
    <ConfrimDialog
      v-if="isDeleteDialogOpen"
      :isOpen="true"
      :data="selectedField"
      :title="`Delete custom field '${selectedField.name}'`"
      message="Are you sure you actually want to delete this custom field? Once you delete it, it can't be undone. If it's referenced in a design, it will no longer merge."
      @confirm="handleDeleteAction"
    ></ConfrimDialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfrimDialog from '../../ui/dialogs/ConfirmDialog.vue';

export default {
  components: { ConfrimDialog },
  props: ['customerId'],
  data() {
    return {
      customFields: [
        {
          name: '',
          type: 'text',
          default_value: '',
          error: [],
          success: ''
        }
      ],
      requiredFields: ['business name', 'first name', 'last name', 'address line1', 'address line2', 'address city', 'address state', 'address zip'],
      maxLength: 100,
      isDeleteDialogOpen: false,
      selectedField: {}
    };
  },
  computed: {
    ...mapGetters('custom_fields', {
      existingCustomFields: 'getDmmCustomFields',
      isCustomFieldsLoading: 'getCustomFieldsLoadingState'
    }),
    isSingleField() {
      return this.customFields.length <= 1;
    },
    existingFieldNames() {
      return this.existingCustomFields.map(({ name }) => name.toLowerCase());
    },
    fieldNameRules() {
      return [
        v => !!v || 'Field name is required',
        v => !this.existingFieldNames.includes(v.toLowerCase()) || 'Field name is already taken',
        v => !this.requiredFields.includes(v.toLowerCase()) || 'Required fields can not be registered as custom fields'
      ];
    },
    existingCustomFieldsLength() {
      return this.existingCustomFields.length;
    },
    isCustomFieldsLimitReached() {
      return this.existingCustomFieldsLength >= this.maxLength;
    },
    isNewFieldAllowed() {
      return this.maxLength - this.existingCustomFieldsLength - this.customFields.length;
    }
  },
  mounted() {
    this.fetchCustomFields();
  },
  methods: {
    fetchCustomFields() {
      return this.$store.dispatch('custom_fields/fetchDmmCustomFields', { customerId: this.customerId });
    },
    addCustomField() {
      this.customFields.push({
        name: '',
        type: 'text',
        default_value: '',
        error: '',
        success: ''
      });
    },
    removeCustomField(index) {
      this.customFields.splice(index, 1);
    },
    async registerCustomFields() {
      if (this.$refs.customFieldForm.validate()) {
        for (const field of this.customFields) {
          field.merge_tag = `${this.customerId}_${field.name.toLowerCase().split(' ').join('_')}`;
          field.error = [];
          field.success = '';
          try {
            const response = await this.$store.dispatch('custom_fields/createCustomField', field);
            if (response.status === 200) {
              field.success = 'Field created!';
            }
          } catch (error) {
            field.error.push(error?.response?.data || 'Could not register field.');
          }
        }
        this.fetchCustomFields();
      }
    },
    async handleDeleteAction(action) {
      if (action.confirmed) {
        await this.deleteExistingCustomField(action.data);
        this.fetchCustomFields();
      }
      this.closeDeleteDialog();
    },
    async deleteExistingCustomField(field) {
      try {
        await this.$store.dispatch('custom_fields/deleteCustomField', { cfld_id: field.id });
      } catch (error) {
        this.$store.dispatch('showAlert', { type: 'error', message: 'Could not delete custom field. Check the console for more details.' });
        console.error('deleteExistingCustomField error:', error);
      }
    },
    openDeleteDialog(field) {
      this.selectedField = field;
      this.isDeleteDialogOpen = true;
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpen = false;
    }
  }
};
</script>
