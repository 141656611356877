<template>
  <v-dialog v-if="isDialogOpen" v-model="isDialogOpen" transition="fade-transition" persistent max-width="600">
    <v-row class="justify-center ml-0">
      <v-avatar color="warning" size="55" class="dialogIcon mt-n4">
        <v-icon large color="white">mdi-alert</v-icon>
      </v-avatar>
    </v-row>
    <v-card>
      <v-system-bar color="warning" height="5"></v-system-bar>
      <v-card-title class="mt-10 justify-center">
        <span class="title font-weight-regular">Warning</span>
      </v-card-title>
      <v-card-text>
        <v-row class="justify-center mt-2">
          <span class="subtitle-1 grey--text text--darken-2 text-center"
            >{{ duplicatedItemsCount }} of {{ selectedItemsCount }} selected items to import were already imported.
            {{ isSelectedGreaterThanDuplicated ? 'Do you want to overwrite them of exclude them from your item import?' : 'Do you want to overwrite existing items?' }}</span
          >
        </v-row>
      </v-card-text>
      <v-divider class="mt-3"></v-divider>
      <v-card-actions class="justify-space-between">
        <v-btn color="grey darken-1" class="subtitle-1 font-weight-medium text-capitalize px-5" outlined small @click="closeDialog()">Cancel</v-btn>
        <div>
          <v-btn v-if="isSelectedGreaterThanDuplicated" color="grey darken-1" class="subtitle-1 font-weight-medium text-capitalize px-5" outlined small @click="excludeItems()"
            >Exclude</v-btn
          >
          <v-btn color="warning" class="subtitle-1 ml-3 font-weight-medium text-capitalize px-5" small @click="overwriteItems()">Overwrite</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['isDialogOpened', 'selectedItemsCount', 'duplicatedItemsCount'],
  data() {
    return {
      isDeleteBtnDisabled: false
    };
  },
  computed: {
    isDialogOpen() {
      return this.isDialogOpened;
    },
    isSelectedGreaterThanDuplicated() {
      return this.selectedItemsCount > this.duplicatedItemsCount;
    }
  },
  watch: {
    isDialogOpen() {
      this.isDeleteBtnDisabled = false;
    }
  },
  methods: {
    emitEvent(eventName, parameter) {
      this.$emit(eventName, parameter);
    },
    closeDialog() {
      this.emitEvent('closeDialog');
    },
    excludeItems() {
      this.emitEvent('excludeItems');
    },
    overwriteItems() {
      this.emitEvent('overwriteItems');
    }
  }
};
</script>

<style scoped>
.dialogIcon {
  position: absolute;
  z-index: 1000;
}
</style>
