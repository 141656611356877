import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class userGroupsService extends FirestoreModel {
  constructor() {
    super(collections.user_groups);
  }

  async getGroupsByLevelId(levelId) {
    const groups = await this.getAll({ isIdReturned: true, isArray: true });
    const groupsWithLevel = groups.filter(({ access_levels }) => access_levels.find(({ id }) => id === levelId));
    if (!groupsWithLevel?.length) {
      return [];
    }
    return groupsWithLevel;
  }
}

export default new userGroupsService();
