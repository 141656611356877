<template>
    <v-dialog
        v-model="loadingDialog"
        persistent
        width="350"
      >
        <!-- dark -->
        <v-card
          color="primary"
        >
          <v-card-text>
            <v-layout align-center justify-center row fill-height>
                <div class="mt-4">
                    <p class="subtitle-1 white--text">Please stand by, loading...</p>
                </div>
            </v-layout>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    props: ['Dialog'],
    data () {
        return {
            loadingDialog: this.Dialog
        }
    },
    computed: {
    },
    watch: {
        Dialog (value) {
            this.loadingDialog = this.Dialog
        }
    },
    created () {
    }
}
</script>

<style scoped>
</style>