import axios from 'axios';

export default {
  resolveRequest(methodName, response) {
    console.log(`%c[ ${methodName} ] - [ RESP ]:`, 'color: #90EE90;', response);
    return Promise.resolve(response);
  },

  rejectRequest(methodName, error) {
    console.error(`%c[ ${methodName} ] - [ ERROR ]:`, 'color: #FF2828;', error);
    return Promise.reject(error);
  },

  get({ url = '', baseUrl = '', endpoint = '', id = '', headers = { 'Content-Type': 'application/json' }, params = {} }) {
    console.log('%c[ GET ] - [ REQ ]', 'color: #87CEFA;', url, baseUrl, endpoint, id);
    if (!url && !baseUrl && !endpoint) return this.rejectRequest('GET', 'Missing request URL!');
    return axios
      .get(`${url ? url : `${baseUrl}/${endpoint}${id ? `/${id}` : ''}`}`, { headers, params })
      .then(response => this.resolveRequest('GET', response))
      .catch(error => this.rejectRequest('GET', error));
  },

  post({ url = '', data = '', baseUrl = '', endpoint = '', headers = { 'Content-Type': 'application/json' }, responseType = 'json' }) {
    console.log('%c[ POST ] - [ REQ ]', 'color: #87CEFA;', url, baseUrl, endpoint, headers, data);
    if (!url && !baseUrl && !endpoint) return this.rejectRequest('POST', ' Missing request URL!');
    return axios
      .post(url || `${baseUrl}/${endpoint}`, data, { headers, responseType })
      .then(response => this.resolveRequest('POST', response))
      .catch(error => this.rejectRequest('POST', error));
  },

  update({ url = '', data = '', baseUrl = '', endpoint = '', headers = { 'Content-Type': 'application/json' } }) {
    console.log('%c[ PUT ] - [ REQ ]', 'color: #87CEFA;', url, baseUrl, endpoint, headers, data);
    if (!url && !baseUrl && !endpoint) return this.rejectRequest('PUT', ' Missing request URL!');
    return axios
      .put(url || `${baseUrl}/${endpoint}`, data, { headers })
      .then(response => this.resolveRequest('PUT', response))
      .catch(error => this.rejectRequest('PUT', error));
  },

  delete({ url = '', data = '', baseUrl = '', endpoint = '', id = '', headers = { 'Content-Type': 'application/json' } }) {
    console.log('%c[ DELETE ] - [ REQ ]', 'color: #87CEFA;', url, baseUrl, endpoint, id, headers);
    if (!url && !baseUrl && !endpoint) return this.rejectRequest('DELETE', 'Missing request URL!');
    return axios
      .delete(`${url ? url : `${baseUrl}/${endpoint}${id ? `/${id}` : ''}`}`, { data, headers })
      .then(response => this.resolveRequest('DELETE', response))
      .catch(error => this.rejectRequest('DELETE', error));
  }
};
