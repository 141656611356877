<template>
  <v-form ref="form" v-model="isValid" lazy-validation>
    <div class="site-table">
      <v-dialog v-model="isPreviewDialogOpened" max-width="700">
        <v-card>
          <v-card-title class="headline">Email Template Preview</v-card-title>
          <br />
          <v-card-subtitle>Subject:</v-card-subtitle>
          <v-card-text>
            <div v-html="previewHtmlSubject"></div>
          </v-card-text>
          <v-card-subtitle>Message:</v-card-subtitle>
          <v-card-text>
            <div v-html="previewHtmlContent"></div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closePreviewDialog()"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <template>
        <v-toolbar flat class="site-table--header site-table--lists">
          <v-col class="pl-0">
            <v-toolbar-title class="headline"> Send Email </v-toolbar-title>
          </v-col>
        </v-toolbar>
      </template>
      <v-card>
        <v-card-text>
          <v-col md="4">
            <v-autocomplete
              v-model="selectedTemplate"
              :items="templates"
              label="Select email template"
              return-object
              item-value="firebase_site_id"
              item-text="label"
              single-line
            ></v-autocomplete>
          </v-col>
          <v-col md="4">
            <v-text-field
              id="templateSlug"
              v-model="orderID"
              label="Order ID"
              :rules="orderRules"
              hint="Enter order ID"
              required
              type="number"
              @blur="getEmailAddressByOrderId()"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field id="to" v-model="to" label="To" :rules="recipientsRules" hint="" required type="text"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field id="subject" v-model="subject" label="Subject" hint="" required type="text"></v-text-field>
          </v-col>
          <v-col>
            <label>Message</label>
            <prism-editor v-model="content" language="html" :line-numbers="true" :auto-style-line-numbers="true" style="background-color: white" />
          </v-col>
          <v-col>
            <label>Available tags</label>
            <v-chip-group column active-class="primary--text text--accent-4">
              <v-chip v-for="tag in availableTags" :key="tag">
                {{ tag }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" :disabled="isPreviewDisabled" text @click="preview">Preview</v-btn>
          <v-btn color="green" :disabled="!isValid" text @click="sendEmail">Send</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-form>
</template>
<script>
import PrismEditor from 'vue-prism-editor';
import { mapGetters } from 'vuex';

export default {
  components: {
    PrismEditor
  },
  data() {
    return {
      isValid: true,
      selectedTemplate: '',
      content: '',
      subject: '',
      to: '',
      availableTags: [],
      isPreviewDialogOpened: false,
      previewHtmlContent: null,
      previewHtmlSubject: '',
      orderID: '',
      orderRules: [value => !!value || 'Required.'],
      recipientsRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid']
    };
  },
  computed: {
    ...mapGetters('email_templates', {
      templates: 'getActiveEmailTemplates'
    }),
    ...mapGetters('users', {
      currentUser: 'user'
    }),
    isPreviewDisabled() {
      return !this.orderID || !this.content;
    }
  },
  watch: {
    templates(items) {
      this.selectTemplate();
    },
    selectedTemplate(value) {
      this.content = value.message;
      this.subject = value.subject;
    }
  },
  async created() {
    await this.$store.dispatch('email_templates/fetchActiveEmailTemplates');
    this.getAvailableTags();
  },
  mounted() {
    this.getEmailAddressByOrderId();
  },
  methods: {
    preview() {
      this.previewHtmlContent = '';
      this.previewHtmlSubject = '';
      if (this.orderID !== '') {
        this.$store.dispatch('showLoader', { message: 'Loading preview...' });
        this.$store
          .dispatch('email_templates/previewEmail', {
            order_id: this.orderID,
            message: this.content,
            subject: this.subject
          })
          .then(response => {
            this.previewHtmlContent = response?.data?.message ?? '';
            this.previewHtmlSubject = response?.data?.subject ?? '';
            this.isPreviewDialogOpened = true;
          })
          .catch(error => {
            console.log('[ emailtemplate/preview ] ERROR:', error?.response?.data ?? error);
            this.showNotificationMessage('error', error?.response?.data ?? error);
          })
          .finally(() => this.$store.dispatch('hideLoader'));
      }
    },
    async sendEmail() {
      if (this.to && this.orderID && this.selectedTemplate?._id) {
        this.errorMessage = '';
        this.alertError = false;
        this.$store.dispatch('showLoader', { message: 'Sending e-mail...' });
        try {
          const response = await this.$store.dispatch('email_templates/sendEmail', {
            to: this.to,
            message: this.content,
            subject: this.subject,
            order_id: this.orderID
          });
          if (response?.data?.message) {
            this.showNotificationMessage('success', response.data.message);
            const order = await this.$store.dispatch('getOrderByOspreyID', { ospreyOrderID: this.orderID });
            await this.$store.dispatch(
              'logs/addOrderLog',
              { firebase_order_id: order.firebase_order_id, action: `[${this.subject}] ${response.data.message} by ${this.currentUser?.email}` },
              { root: true }
            );
          }
        } catch (error) {
          if (error?.response?.data) {
            console.log('[ sendEmail ] ERROR:', error.response.data);
            this.showNotificationMessage('error', error.response.data);
          }
        } finally {
          this.$store.dispatch('hideLoader');
        }
      }
    },
    getAvailableTags() {
      this.$store
        .dispatch('email_templates/getAvailableTags')
        .then(response => (this.availableTags = response?.data?.length ? response.data.split(',') : []))
        .catch(error => this.showNotificationMessage('error', error?.response?.data ?? error));
    },
    showNotificationMessage(type, message) {
      this.$store.dispatch('showAlert', { isVisible: true, type, message });
      window.scrollTo(0, 0);
    },
    getEmailAddressByOrderId() {
      this.orderID = this.orderID || this.$route.query.order_id || '';
      if (this.orderID) {
        this.$store.dispatch('showLoader', { message: 'Loading...' });
        this.$store
          .dispatch('email_templates/getEmailAddressByOrderId', { order_id: this.orderID })
          .then(response => (this.to = response?.data?.email ?? ''))
          .catch(error => {
            if (error?.response?.data) {
              console.log('[ getEmailAddressByOrderId ] ERROR:', error.response.data);
              this.showNotificationMessage('error', error.response.data);
            }
          })
          .finally(() => this.$store.dispatch('hideLoader'));
      }
    },
    selectTemplate() {
      const templateCode = this.$route.query.template || '';
      if (templateCode) {
        this.selectedTemplate = this.templates.find(template => template.code === templateCode) ?? '';
      }
    },
    closePreviewDialog() {
      this.isPreviewDialogOpened = false;
    }
  }
};
</script>
