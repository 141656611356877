import { cloudFunctionsPost } from '../../modules/cloud-function-wrapper.js';
import orderService from '../../services/orderService.js';
import moment from 'moment';

const state = {
  isLetterDropsLoading: false,
  letterDrops: []
};

const getters = {
  getLetterDropsLoadingState(state) {
    return state.isLetterDropsLoading;
  },
  getLetterDrops(state) {
    return state.letterDrops;
  }
};

const mutations = {
  setLetterDropsLoadingState(state, payload) {
    state.isLetterDropsLoading = payload;
  },
  setLetterDrops(state, payload) {
    state.letterDrops = payload;
  }
};

const actions = {
  async loadLetterDrops({ commit, dispatch }) {
    try {
      commit('setLetterDrops', []);
      commit('setLetterDropsLoadingState', true);

      const letterOrders = await orderService.getByProperty('is_letter', true, { isArray: true });
      await Promise.all(
        letterOrders.map(async order => {
          order.orderData = await orderService.getOrderData(order.firebase_order_id);
        })
      );
      const drops = letterOrders.reduce((accumulator, order) => {
        if (!order?.crm_order_id) return accumulator;
        const deliveryDates = order.orderData.delivery.delivery_dates
          .map((drop, index) => ({
            ...drop,
            drop_date: moment(new Date(drop.date)).format('YYYY-MM-DD'),
            firebase_order_id: order.firebase_order_id,
            osprey_order_id: order.crm_order_id,
            crm_customer_id: order.crm_customer_id,
            drop_num: drop.drop_num || index + 1
          }))
          .filter(drop => Number(drop.drop_status) === 695);
        if (!accumulator?.length) {
          accumulator = [];
        }
        return [...accumulator, ...deliveryDates];
      }, []);
      commit('setLetterDrops', drops);
    } catch (error) {
      dispatch('showAlert', { message: error.message || 'Could not load letter drops.', type: 'error' }, { root: true });
      console.error('[ fetchDmmCustomFields ERROR ]:', error);
    } finally {
      commit('setLetterDropsLoadingState', false);
    }
  },
  createLetterArtwork({ dispatch }, payload) {
    return cloudFunctionsPost('/letters/artworks/create', payload);
  },
  createLetters({ dispatch }, payload) {
    return cloudFunctionsPost('/letters/start', payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
