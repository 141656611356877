import scheduledDropsService from '../../services/scheduledDropsService';

const actions = {
  async fetchScheduledDrops({ commit }, payload) {
    const templates = await scheduledDropsService.getAll({ isIdReturned: true, isArray: true });
    return templates;
  },
  async getScheduleDropsByDate({ commit }, payload) {
    const templates = await scheduledDropsService.getAll({ isIdReturned: true, isArray: true });
    return templates.find(template => template._id === payload.date);
  },

  async updateOrAddScheduledDrop({ commit, dispatch }, payload) {
    const { date, drops } = payload;
    const deleteDropId = drops._id;
    delete drops._id;
    try {
      const targetScheduledDrops = await dispatch('getScheduleDropsByDate', { date: date });
      if (targetScheduledDrops?._id) {
        await scheduledDropsService.update(date, drops);
        await scheduledDropsService.delete(deleteDropId);
        return;
      }
      await scheduledDropsService.setDropsByDate(date, drops);
      await scheduledDropsService.delete(deleteDropId);
    } catch (error) {
      throw new Error(error);
    }
  }
};

export default {
  namespaced: true,
  actions,
};
