const validFilterOperators = ['==', '>', '<', '>=', '<=', '!=', 'array-contains', 'in', 'not-in', 'array-contains-any'];
import { dbFS, firestoreFieldValue, firebase } from '../services/firebaseInit.js';

export const collections = {
  access_levels: 'access_levels',
  agents: 'agents',
  alllogs: 'all_logs',
  backup_logs: 'backup_logs',
  brands: 'brands',
  categories: 'categories',
  companies: 'companies',
  config: 'config',
  customDesigns: 'customDesigns',
  custom_designs_test: 'custom_designs_test',
  customers: 'customers',
  designList: 'designList',
  dmmCustomers: 'dmmCustomers',
  dropStatusTracker: 'drop_status_tracker',
  emailtemplates: 'emailtemplates',
  executed_drops: 'executed_drops',
  fourover_statuses: 'fourover_statuses',
  infoconnect: 'infoconnect',
  mailingLists: 'mailingLists',
  mailingLists_logs: 'mailingLists_logs',
  maintenanceStatus: 'maintenanceStatus',
  maps: 'maps',
  maps_test: 'maps_test',
  notifications: 'notifications',
  orderSettings: 'orderSettings',
  order_logs: 'order_logs',
  order_settings: 'order_settings',
  orders: 'orders',
  orders_meta: 'orders_meta',
  orders_stripe: 'orders_stripe',
  payments: 'payments',
  payments_stripe: 'payments_stripe',
  product_categories: 'product_categories',
  product_services: 'product_services',
  product_types: 'product_types',
  product_pricing: 'product_pricing',
  products: 'products',
  routes: 'routes',
  scheduled_drops: 'scheduled_drops',
  services: 'services',
  sites: 'sites',
  sms_templates: 'sms_templates',
  sonicprintDmmCustomers: 'sonicprintDmmCustomers',
  stamp_settings: 'stamp_settings',
  stripe_webhooks: 'stripe_webhooks',
  subscriptions: 'subscriptions',
  template_types: 'template_types',
  template_categories: 'template_categories',
  templates: 'templates',
  test: 'test',
  total: 'total',
  user_groups: 'user_groups',
  users: 'stamp_users',
  stamp_logs: 'stamp_logs'
};

class FirestoreModel {
  /**
   * Create a Firestore Collection Instance.
   * @param {number} collection - The name of the collection you are trying to do operations on.
   */
  constructor(collection) {
    if (!Object.values(collections).includes(collection)) {
      throw new Error(`Collection ${collection} does not exist`);
    }
    this.collectionRef = dbFS.collection(collection);
    this._collectionName = collection;
    this._listeners = {};
  }

  /**
   *
   * @param { Object } options
   * @param { string } options.orderingProperty the field to order by
   * @param { 'asc' | 'desc' } [ options.orderingDirection = 'asc' ] the direction to order by
   * @param { boolean } [ options.isIdReturned = false ] the direction to order by
   * @param { boolean } [ options.isArray = false ] the return value array or not
   * @returns { Promise<any[]> }
   */
  async getAll({ orderingProperty, orderingDirection = 'asc', isIdReturned = false, isArray = false } = {}) {
    let query = this.collectionRef;
    if (orderingProperty) {
      query = query.orderBy(orderingProperty, orderingDirection);
    }
    const result = await query.get();
    if (result.empty) {
      return [];
    }

    return this._flattenQuerySnapshotResult(result, isIdReturned, isArray);
  }

  /**
   * Get a documents of collection by it`s documentPath array
   * @param { array<string> } documentPaths — A slash-separated path to a document in the current collection.
   * @returns { Promise<FirebaseFirestore.DocumentData> }  A Promise that will be resolved with the results of the Query.
   */
  async getByIdArray(documentPaths, { isIdReturned = false, isArray = false, orderByOptions = null } = {}) {
    this._validateMissingParameter({ documentPaths });
    let query = this.collectionRef.where(firebase.firestore.FieldPath.documentId(), 'in', documentPaths);
    if (orderByOptions) {
      query = query.orderBy(orderByOptions.property, orderByOptions.direction);
    }
    const result = await query.get();
    if (result.empty) {
      return [];
    }

    return this._flattenQuerySnapshotResult(result, isIdReturned, isArray);
  }

  /**
   * Get a document of collection by it`s documentPath
   * @param { string } documentPath — A slash-separated path to a document in the current collection.
   * @returns { Promise<any[]> }  A Promise that will be resolved with the results of the Query.
   */
  async getById(documentPath) {
    this._validateMissingParameter({ documentPath });
    const document = await this.collectionRef.doc(documentPath).get();
    this._validateDocument(document, documentPath);
    return document.data();
  }
  /**
   * Attach a listener to document of collection by it`s documentPath
   * @param { string } documentPath — A slash-separated path to a document in the current collection.
   * @returns { () => void }  An unsubscribe function that can be called to cancel the attached listener.
   */
  attachListenerToDocument(
    documentPath,
    onUpdate,
    onError = error => {
      console.log(error);
    }
  ) {
    this._validateMissingParameter({ documentPath });
    const listener = this.collectionRef.doc(documentPath).onSnapshot(snapshot => onUpdate(snapshot.data()), onError);
    this._listeners[documentPath] = this._createListenerRemoveFn(documentPath, listener);
    return this._listeners[documentPath];
  }

  /**
   * Attach a listener to document of collection by it`s documentPath
   * @param { string } documentPath — A slash-separated path to a document in the current collection.
   * @returns { Promise<FirebaseFirestore.DocumentData> }  An unsubscribe function that can be called to cancel the attached listener.
   */
  attachRootListener(
    onUpdate,
    {
      orderByOptions,
      isIdReturned = false,
      isArray = false,
      onError = error => {
        console.log(error);
      }
    }
  ) {
    let query = this.collectionRef;
    if (orderByOptions) {
      query = query.orderBy(orderByOptions.property, orderByOptions.direction);
    }
    const listener = this.collectionRef.onSnapshot(snapshot => onUpdate(this._flattenQuerySnapshotResult(snapshot, isIdReturned, isArray)), onError);
    this._listeners['root'] = this._createListenerRemoveFn('root', listener);
    return this._listeners['root'];
  }

  _createListenerRemoveFn(path, listener) {
    return () => {
      listener();
      delete this._listeners[path];
    };
  }

  /**
   * Remove all listeners that were attached so far
   */
  clearAllLsiteners() {
    Object.keys(this._listeners)?.forEach(listener => {
      this._listeners?.[listener]?.();
      delete this._listeners?.[listener];
    });
  }

  /**
   * Remove an attached listener by it's documentPath
   * @param { string } documentPath — A slash-separated path to a document in the current collection.
   */
  removeListener(documentPath) {
    this._listeners?.[documentPath]?.();
    delete this._listeners?.[documentPath];
  }

  /**
   * getByProperty - get a document by a property value
   *
   * @param { Object } query property and value you want to filter by
   * @param { string } query.property
   * @param { * } query.value
   * @param { Object } options options for the query (limit, orderBy, etc)
   * @param { number | null } options.limit - the maximum number of documents to return
   * @param { string } options.orderByOptions.orderingProperty the field to order by
   * @param { 'asc' | 'desc' } [ options.orderByOptions.orderingDirection = 'asc' ] the direction to order by
   * @param { boolean } [ options.isIdReturned = false ] if true, the id of the documents will be returned
   * [ operator ]: see https://firebase.google.com/docs/firestore/query-data/queries#filtering_queries
   *
   * [ limit ]: the number of documents returned
   *
   * [ orderByOptions ]: the options you want for ordering the documents
   *
   * [ orderByOptions.property ]: the field to order by
   *
   * [ orderByOptions.direction ]: the direction to order by
   *
   * [ isIdReturned ]: if true, the id of the documents will be returned
   * @returns { Promise<FirebaseFirestore.DocumentData[]> } an array of the documents that match the query
   */
  async getByProperty(property, value, { operator = '==', limit = null, isIdReturned = false, isArray = false, orderByOptions = null } = {}) {
    this._validateMissingParameter({ property, value });
    if (!validFilterOperators.includes(operator)) {
      throw new Error(`The operator [${operator}] you provided is not valid!`);
    }

    let query = this.collectionRef.where(property, operator, value);

    if (orderByOptions?.property && orderByOptions?.direction) {
      query = query.orderBy(orderByOptions.property, orderByOptions.direction);
    }
    if (limit) {
      query = query.limit(limit);
    }

    const result = await query.get();

    if (result.empty) {
      return [];
    }

    return this._flattenQuerySnapshotResult(result, isIdReturned, isArray);
  }

  /**
   * Writes to the document referred to by this DocumentReference. If the document does not yet exist, it will be created.
   * By default { merge = true }, so the provided data will be merged into an existing document.
   * To prohibit this behaviour explicitly set { merge = false }.
   *
   * @param {string} documentPath A slash-separated path to a document.
   * @param {*} data  A map of the fields and values for the document.
   * @param {Object} options An object to configure the set behavior.
   * @param {boolean} [options.merge=true] If true, the provided data will be merged with an existing document.
   * @returns { Promise<string> } A Promise resolved with the id of the new document.
   */
  async set(documentPath, data, { merge = true } = {}) {
    this._validateMissingParameter({ documentPath, data });
    const docRef = this.collectionRef.doc(documentPath);
    await docRef.set(data, { merge: merge });
    return docRef.id;
  }

  /**
   * Writes to the document referred to by this DocumentReference. If the document does not yet exist, it will be created.
   *
   * @param {string} documentPath A slash-separated path to a document.
   * @param {*} data  A map of the fields and values for the document.
   * @returns { Promise<string> } A Promise resolved with the id of the new document.
   */
  async add({ documentPath = null, data }) {
    this._validateMissingParameter({ data });
    const docRef = this.collectionRef;
    if (documentPath) {
      docRef.doc(documentPath);
    }
    return (await docRef.add(data)).id;
  }

  /**
   * Updates fields in the document referred to by this DocumentReference.
   * The update will fail if applied to a document that does not exist.
   * Nested fields can be updated by providing dot-separated field path strings.
   *
   * @param { string } documentPath — A slash-separated path to a document in the current collection.
   * @param { * } data - An object containing the fields and values with which to update the document.
   * @returns { Promise<string> } A Promise resolved with the id of the updated document.
   */
  async update(documentPath, data) {
    this._validateMissingParameter({ documentPath, data });
    const docRef = this.collectionRef.doc(documentPath);
    await docRef.update(data);
    return docRef.id;
  }

  /**
   * delete - Deletes the document referred to by this DocumentReference.
   * @param { string } documentPath - A slash-separated path to a document.
   * @returns { Promise<FirebaseFirestore.WriteResult> } A Promise that will be resolved with the results of the operation.
   */
  async delete(documentPath) {
    this._validateMissingParameter({ documentPath });
    return this.collectionRef.doc(documentPath).delete();
  }

  /**
   * deleteField - Deletes a document field
   * @param { string } documentPath - A slash-separated path to a document.
   * @param { string } fieldName - A string which contains the name of the deleted field.
   * @returns { Promise<FirebaseFirestore.WriteResult> } A Promise that will be resolved with the results of the operation.
   */
  async deleteField(documentPath, fieldName) {
    this._validateMissingParameter({ documentPath, fieldName });
    return this.collectionRef.doc(documentPath).update({
      [fieldName]: firestoreFieldValue.delete()
    });
  }

  /**
   * _validateMissing - validate that the required parameters are not missing, throw an error if they are
   * @param { * } payload
   */
  _validateMissingParameter(payload) {
    for (const key in payload) {
      if (payload.hasOwnProperty('key') && !payload[key]) {
        throw new Error(`The parameter [${key}] is missing!`);
      }
    }
  }

  /**
   *	_validateEmptyDocument - validate that the query result is not empty, throw an error if it is
   * @param { FirebaseFirestore.QuerySnapshot<FirebaseFirestore.DocumentData> } query - the query result to validate
   */
  _validateQuerySnapshot(query) {
    if (query.empty) {
      throw new Error('The query result is empty!');
    }
  }

  /**
   *  _validateEmptyDocument - validate that the snapshot exists, throw an error if it does not
   *  @param { FirebaseFirestore.DocumentSnapshot<FirebaseFirestore.DocumentData> } documnet - the documnet to validate
   *  @param { string } documentPath - the documentPath of the document to validate
   */
  _validateDocument(documnet, documentPath) {
    if (!documnet.exists) {
      throw new Error(`The document [${documentPath}] does not exist!`);
    }
  }

  /**
   * _flattenQuerySnapshotResult - Flatten the result of a query snapshot
   * @param { FirebaseFirestore.QuerySnapshot<FirebaseFirestore.DocumentData> } snapshot the result of a query
   * @param { boolean } isIdReturned - if true, the id of the documents will be returned
   * @param { boolean } isArray - if true, it will return the resutl as an array
   * @returns { FirebaseFirestore.DocumentData[] }
   */
  _flattenQuerySnapshotResult(snapshot, isIdReturned = false, isArray = false) {
    if (!isArray) {
      let returnData = {};
      snapshot.docs.forEach(doc => {
        returnData[doc.id] = doc.data();
        if (isIdReturned) {
          returnData[doc.id]._id = doc.id;
        }
      });
      return returnData;
    }
    if (isIdReturned) {
      return snapshot.docs.map(doc => {
        return { _id: doc.id, ...doc.data() };
      });
    }
    return snapshot.docs.map(doc => doc.data());
  }
}

export default FirestoreModel;
