import { dbFS, storageRef, storage } from '../../services/firebaseInit.js';
import httpClient from '@/modules/httpModule';
// initial state
const state = {
  companies: [],
  templates: [],
  template: null,
  categories: [],
  logThumb: null,
  errors: {
    frontPath: '',
    backPath: '',
    frontImage: '',
    backImage: '',
    frontThumbnailImage: '',
    backThumbnailImage: '',
    templateExist: true
  },
  path: {},
  paths: [],
  designList: [],
  designsListFromDB: []
};

// mutations
const mutations = {
  // Companies/Organizations
  setCompanies(state, payload) {
    state.companies = payload;
  },
  addCompany(state, payload) {
    state.companies.push({
      name: payload.name,
      organization_id: payload.organization_id,
      email: payload.email,
      phone: payload.phone,
      authToken: payload.authToken,
      document_id: payload.document_id
    });
  },
  updateCompany(state, payload) {
    const companiesList = state.companies;
    companiesList.forEach((value, index) => {
      if (value.document_id === payload.key) {
        state.companies.splice(index, 1, {
          name: payload.name,
          organization_id: payload.organization_id,
          email: payload.email,
          phone: payload.phone,
          authToken: payload.authToken,
          document_id: payload.key
        });
      }
    });
  },
  deleteCompany(state, payload) {
    const companiesList = state.companies;
    companiesList.forEach((value, index) => {
      if (value.document_id === payload.key) {
        state.companies.splice(index, 1);
      }
    });
  },
  // Templates
  setTemplates(state, payload) {
    state.templates = payload;
  },
  addTemplate(state, payload) {
    state.templates = [];
    state.templates.push({
      name: payload.name,
      onebrand_id: payload.onebrand_id,
      front_path: payload.front_path,
      back_path: payload.back_path,
      category: payload.category,
      document_id: payload.document_id
    });
  },
  updateTemplate(state, payload) {
    const templateList = state.templates;
    templateList.forEach((value, index) => {
      if (value.document_id === payload.key) {
        state.templates.splice(index, 1, {
          name: payload.name,
          onebrand_id: payload.onebrand_id,
          category: payload.category,
          companyId: payload.companyId,
          front_path: payload.front_path,
          back_path: payload.back_path,
          frontThumbnail: payload.frontThumbnail,
          backThumbnail: payload.backThumbnail,
          document_id: payload.key
        });
      }
    });
  },
  deleteTemplate(state, payload) {
    const templateList = state.templates;
    templateList.forEach((value, index) => {
      if (value.document_id === payload.key) {
        state.templates.splice(index, 1);
      }
    });
  },
  listTemplate(state, payload) {
    state.template = payload;
  },
  // Categories
  setCategories(state, payload) {
    state.categories = payload;
  },
  addCategory(state, payload) {
    state.categories.push({
      name: payload.name,
      description: payload.description,
      document_id: payload.document_id
    });
  },
  updateCategory(state, payload) {
    const categoryList = state.categories;
    categoryList.forEach((value, index) => {
      if (value.document_id === payload.key) {
        state.categories.splice(index, 1, {
          name: payload.name,
          description: payload.description,
          document_id: payload.key
        });
      }
    });
  },
  deleteCategory(state, payload) {
    const categoryList = state.categories;
    categoryList.forEach((value, index) => {
      if (value.document_id === payload.key) {
        state.categories.splice(index, 1);
      }
    });
  },
  // Log tuhumbnails
  setLogThumbnails(state, payload) {
    state.logThumb = payload;
  },
  // Errors
  setErrors(state, payload) {
    // console.log('payload:', payload)
    if (payload.errors.frontPath) {
      state.errors.frontPath = payload.errors.frontPath;
    }
    if (payload.errors.backPath) {
      state.errors.backPath = payload.errors.backPath;
    }
    if (payload.errors.frontImage) {
      state.errors.frontImage = payload.errors.frontImage;
    }
    if (payload.errors.frontThumbnailImage) {
      state.errors.frontThumbnailImage = payload.errors.frontThumbnailImage;
    }
    if (payload.errors.backImage) {
      state.errors.backImage = payload.errors.backImage;
    }
    if (payload.errors.backThumbnailImage) {
      state.errors.backThumbnailImage = payload.errors.backThumbnailImage;
    }
  },
  setPath(state, payload) {
    state.path = payload;
  },
  setPaths(state, payload) {
    state.paths = payload;
  },
  clearPath(state, payload) {
    state.path = {};
  },
  setDesignLiveLIst(state, payload) {
    state.designList = payload;
  },
  updateDesignLive(state, payload) {
    state.designsListFromDB = payload.designList;
  },
  setDesignsList(state, payload) {
    state.designsListFromDB = payload;
  }
};
// getters
const getters = {
  companies(state) {
    return state.companies;
  },
  templates(state) {
    return state.templates;
  },
  template(state) {
    return state.template;
  },
  categories(state) {
    return state.categories;
  },
  logThumbnails(state) {
    // console.log('gatters:', state.logThumb)
    return state.logThumb;
  },
  errors(state) {
    return state.errors;
  },
  path(state) {
    return state.path;
  },
  paths(state) {
    return state.paths;
  },
  designList(state) {
    return state.designList;
  },
  designsList(state) {
    return state.designsListFromDB;
  },
  pathsInTreeBuild(state) {
    return state.pathsInTreeBuild;
  },
  users(state) {
    return state.users;
  }
};
// actions
const actions = {
  // Companies/Organizations

  /*
   * List Companies from firebase firestore
   */
  listCompanies({ commit, dispatch, getters }, payload) {
    let companiesList = [];
    return dbFS
      .collection('companies')
      .orderBy('name')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = {
            name: doc.data().name,
            organization_id: doc.data().organization_id,
            email: doc.data().email,
            phone: doc.data().phone,
            authToken: doc.data().authToken,
            document_id: doc.id
          };
          companiesList.push(data);
        });
        // dispatch('listTemplates')
        // .then(() => {
        //     // console.log('list templates')
        //     // console.log(getters.templates)
        //     // console.log('list compaanies')
        //     // console.log(companiesList)
        //     companiesList.forEach((value, index) => {
        //         // TODO : check if company has template , then add new prop with flag:true or false
        //     })
        // })
        commit('setCompanies', companiesList);
        return { loaded: true };
      });
  },
  /*
   * Init realtime updates with Cloud Firestore - view changes between snapshots
   * CRUD operation on companies list
   */
  initRealtimeListeners({ commit }, payload) {
    dbFS.collection('companies').onSnapshot(snapshot => {
      var source1 = snapshot.metadata.hasPendingWrites ? 'Local' : 'Server';
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          // console.log('Added: ', change.doc.data())
          var source = change.doc.metadata.hasPendingWrites ? 'Local' : 'Server';
          if (source === 'Server') {
            commit('addCompany', {
              name: change.doc.data().name,
              organization_id: change.doc.data().organization_id,
              email: change.doc.data().email,
              phone: change.doc.data().phone,
              document_id: change.doc.id
            });
          }
        }
        if (change.type === 'modified') {
          // console.log('Updated !', change.doc.data())
          if (source1 === 'Server') {
            commit('updateCompany', {
              name: change.doc.data().name,
              organization_id: change.doc.data().organization_id,
              email: change.doc.data().email,
              phone: change.doc.data().phone,
              authToken: change.doc.data().authToken,
              key: change.doc.id
            });
          }
        }
        if (change.type === 'removed') {
          // console.log('Removed !', change.doc.data())
          commit('deleteCompany', {
            key: change.doc.id
          });
        }
      });
    });
  },
  /*
   * Save new company
   * @param {string} payload - get props: companylNameToInsert,organizationIdToInsert ,emailToInsert,phoneToInsert
   * return {data} after save was successful
   */
  saveCompany({ commit }, payload) {
    const companylNameToInsert = payload.companylNameToInsert;
    const organizationIdToInsert = payload.organizationIdToInsert;
    const emailToInsert = payload.emailToInsert;
    const phoneToInsert = payload.phoneToInsert;
    const authHash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    return dbFS
      .collection('companies')
      .add({
        name: companylNameToInsert,
        organization_id: organizationIdToInsert,
        email: emailToInsert,
        phone: phoneToInsert,
        authToken: authHash
      })
      .then(data => {
        // console.log('Save new level has succeeded: ', data)
        commit('addCompany', {
          name: companylNameToInsert,
          organization_id: organizationIdToInsert,
          email: emailToInsert,
          phone: phoneToInsert,
          authToken: authHash,
          document_id: data.id
        });
        return { token: authHash };
      })
      .catch(error => {
        console.log('Save new company has failed: ', error);
      });
  },
  /*
   * Update Company
   * @param {object} payload - get props: companylNameToInsert, organizationIdToInsert, emailToInsert, phoneToInsert, companyKey
   */
  updateCompany({ commit }, payload) {
    const companylNameToInsert = payload.companylNameToInsert;
    const organizationIdToInsert = payload.organizationIdToInsert;
    const emailToInsert = payload.emailToInsert;
    const phoneToInsert = payload.phoneToInsert;
    const companyKey = payload.companyKey;
    const token = payload.token;

    return dbFS
      .collection('companies')
      .doc(companyKey)
      .update({
        name: companylNameToInsert,
        organization_id: organizationIdToInsert,
        email: emailToInsert,
        phone: phoneToInsert,
        authToken: token
      })
      .then(() => {
        // console.log('Update level has succeeded! ')
        commit('updateCompany', {
          name: companylNameToInsert,
          organization_id: organizationIdToInsert,
          email: emailToInsert,
          phone: phoneToInsert,
          authToken: token,
          key: companyKey
        });
      })
      .catch(error => {
        console.log('Update level has failed: ', error);
      });
  },
  /*
   * Delete Company
   * @param {object} payload - get props: key
   */
  deleteCompany({ commit }, payload) {
    const companyKey = payload.key;
    dbFS
      .collection('companies')
      .doc(payload.key)
      .delete()
      .then(() => {
        // console.log('Company has been deleted succefully! ')
        commit('deleteCompany', {
          key: companyKey
        });
      })
      .catch(error => {
        console.error('Error removing document: ', error);
      });
  },
  // Templates
  /*
   * Init realtime updates with Cloud Firestore - view changes between snapshots
   * CRUD operation on companies list
   */
  initRealtimeListenersForTemplates({ commit }, payload) {
    dbFS.collection('templates').onSnapshot(snapshot => {
      var source1 = snapshot.metadata.hasPendingWrites ? 'Local' : 'Server';
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          // console.log('Added: ', change.doc.data())
          var source = change.doc.metadata.hasPendingWrites ? 'Local' : 'Server';
          if (source === 'Server') {
            // console.log('server')
            commit('addTemplate', {
              name: change.doc.data().name,
              onebrand_id: change.doc.data().onebrand_id,
              front_path: change.doc.data().front_path,
              back_path: change.doc.data().back_path,
              category: change.doc.data().category,
              document_id: change.doc.id
            });
          }
        }
        if (change.type === 'modified') {
          // console.log('Updated !', change.doc.data())
          if (source1 === 'Server') {
            commit('updateTemplate', {
              name: change.doc.data().name,
              onebrand_id: change.doc.data().onebrand_id,
              front_path: change.doc.data().front_path,
              back_path: change.doc.data().back_path,
              category: change.doc.data().category,
              key: change.doc.id
            });
          }
        }
        if (change.type === 'removed') {
          // console.log('Removed !', change.doc.data())
          commit('deleteTemplate', {
            key: change.doc.id
          });
        }
      });
    });
  },
  /*
   * Init realtime updates on Cloud Firestore(logthumbnails) - view changes between snapshots on thumbnail creation
   *
   */
  initRealtimeListenersForTemplatesThumbnail({ commit }, payload) {
    dbFS.collection('logthumbnails').onSnapshot(snapshot => {
      var source1 = snapshot.metadata.hasPendingWrites ? 'Local' : 'Server';
      // console.log('source1:' ,source1)
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          // TODO :
          // console.log('Added: ', change.doc.data())
          // var source = change.doc.metadata.hasPendingWrites ? 'Local' : 'Server'
          // console.log('source:' ,source)
          // if (source === 'Server') {
          //     console.log('server')
          // }
          if (source1 === 'Server') {
            // console.log('server1')
            // console.log('Added: ', change.doc.data())
            const docData = change.doc.data();
            const id = change.doc.id;
            // console.log('docdata:', docData)
            commit('setLogThumbnails', {
              data: docData,
              id: id
            });
          }
        }
        if (change.type === 'modified') {
          console.log('Updated !', change.doc.data());
          if (source1 === 'Server') {
          }
        }
        if (change.type === 'removed') {
          console.log('Removed !', change.doc.data());
        }
      });
    });
  },
  /*
   * List Templates from firebase firestore
   */
  listTemplates({ commit }, payload) {
    let templatesList = [];
    return dbFS
      .collection('templates')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = {
            name: doc.data().name,
            onebrand_id: doc.data().onebrand_id,
            front_path: doc.data().front_path,
            back_path: doc.data().back_path,
            category: doc.data().category,
            organizationId: doc.data().organizationId,
            organizationName: doc.data().organizationName,
            templateStatus: doc.data().templateStatus,
            front_full_image: doc.data().frontThumbnail,
            back_full_image: doc.data().backThumbnail,
            front_thmbnl: doc.data().frontThumbnailImgPath,
            back_thmbnl: doc.data().backThumbnailImgPath,
            document_id: doc.id
          };
          templatesList.push(data);
        });
        commit('setTemplates', templatesList);
        return { loaded: true };
      });
  },
  /*
   * Save new templates
   * @param {string} payload - get props: templateName,onebrandId ,frontPath, backPath, category
   * return {data} after save was successful
   */
  saveTemplate({ commit }, payload) {
    // change companyId to token, because is unique (in select category, Template process New)
    const templateNameToInsert = payload.templateName;
    const onebrandIdToInsert = payload.onebrandId;
    const categoryToInsert = payload.category;
    const companyIdToInsert = payload.companyId;
    const frontPathToInsert = payload.frontPath;
    const backPathToInsert = payload.backPath;
    const frontThumbnailToInsert = payload.frontThumbnail;
    const backThumbnailToInsert = payload.backThumbnail;
    const frontThumbnailImgNameToInsert = payload.frontThumbnailImgName;
    const backThumbnailImgNameToInsert = payload.backThumbnailImgName;
    const templateStatusToInsert = payload.templateStatus;
    const frontThumbnailImgPathToInsert = payload.frontThumbnailImgPath;
    const backThumbnailImgPathToInsert = payload.backThumbnailImgPath;
    // console.log('token:', companyIdToInsert)

    return dbFS
      .collection('companies')
      .where('authToken', '==', companyIdToInsert)
      .get()
      .then(querySnapshot => {
        // check if results exists
        if (querySnapshot.size > 0) {
          let organizationNameFound = '';
          let organizationId = '';
          querySnapshot.forEach(doc => {
            // console.log('data', doc.data())
            const organizationName = doc.data().name;
            organizationNameFound = organizationName;
            organizationId = doc.data().organization_id;
          });
          return {
            organizationName: organizationNameFound,
            organizationId: organizationId
          };
        } else {
          return { organizationName: 'undefined' };
        }
      })
      .then(response => {
        return dbFS
          .collection('templates')
          .add({
            name: templateNameToInsert,
            onebrand_id: onebrandIdToInsert,
            category: categoryToInsert,
            organizationId: response.organizationId,
            organizationName: response.organizationName,
            front_path: frontPathToInsert,
            back_path: backPathToInsert,
            frontThumbnail: frontThumbnailToInsert,
            backThumbnail: backThumbnailToInsert,
            frontThumbnailImgName: frontThumbnailImgNameToInsert,
            backThumbnailImgName: backThumbnailImgNameToInsert,
            templateStatus: templateStatusToInsert,
            frontThumbnailImg: '',
            backThumbnailImg: '',
            frontThumbnailImgPath: frontThumbnailImgPathToInsert,
            backThumbnailImgPath: backThumbnailImgPathToInsert
          })
          .then(data => {
            console.log('Save new level has succeeded: ', data);
            /*

                    dbFS.collection('logthumbnails').where('filename', '==', backThumbFileName).get()
                    .then((querySnapshot) => {
                        // check if results exists
                        if (querySnapshot.size > 0) {
                            console.log('querySnapshot', querySnapshot)
                            querySnapshot.forEach((doc) => {
                               console.log('doc data:', doc.data())
                               const thumbnailDownloadUrl = doc.data().metadataName
                               console.log(thumbnailDownloadUrl)
                               // return storageRef.child(thumbnailDownloadUrl).getDownloadURL()
                               return storageRef.child(thumbnailDownloadUrl).getDownloadURL()
                               .then((downloadURL) => {
                                    console.log('Back image path:', downloadURL)
                                    dbFS.collection('templates').doc(newDocId).update({
                                        'backThumbnailImgPath': downloadURL
                                    })
                                    .then(() => {
                                    console.log('Update template back image has succeeded! ')
                                    }).catch((error) => {
                                        console.log('Update template back image has failed: ', error)
                                    })
                                })
                            })
                        }
                    })
                }
                , 2000)
                */
          })
          .catch(error => {
            console.log('Save new company has failed: ', error);
          });
      });
  },
  /*
   * Update Template
   * @param {object} payload - get props: templateName,onebrandId ,frontPath, backPath, category
   */
  updateTemplate({ commit }, payload) {
    // TODO: use authToken to udentify the company and get the id and name, update the organization name (see saveTemplate)
    const templateNameToInsert = payload.templateName;
    const onebrandIdToInsert = payload.onebrandId;
    const categoryToInsert = payload.category;
    const companyIdToInsert = payload.companyId;
    const frontPathToInsert = payload.frontPath;
    const backPathToInsert = payload.backPath;
    const frontThumbnailToInsert = payload.frontThumbnail;
    const backThumbnailToInsert = payload.backThumbnail;
    const frontThumbnailImgNameToInsert = payload.frontThumbnailImgName;
    const backThumbnailImgNameToInsert = payload.backThumbnailImgName;
    const templateStatusToInsert = payload.templateStatus;
    const frontThumbnailImgPathToInsert = payload.frontThumbnailImgPath;
    const backThumbnailImgPathToInsert = payload.backThumbnailImgPath;
    const templateKey = payload.key;

    return dbFS
      .collection('templates')
      .doc(templateKey)
      .update({
        name: templateNameToInsert,
        onebrand_id: onebrandIdToInsert,
        category: categoryToInsert,
        organizationId: companyIdToInsert,
        front_path: frontPathToInsert,
        back_path: backPathToInsert,
        frontThumbnail: frontThumbnailToInsert,
        backThumbnail: backThumbnailToInsert,
        frontThumbnailImgName: frontThumbnailImgNameToInsert,
        backThumbnailImgName: backThumbnailImgNameToInsert,
        templateStatus: templateStatusToInsert,
        frontThumbnailImgPath: frontThumbnailImgPathToInsert,
        backThumbnailImgPath: backThumbnailImgPathToInsert
      })
      .then(() => {
        // console.log('Update level has succeeded! ')
        // obsolete
        commit('updateTemplate', {
          name: templateNameToInsert,
          onebrand_id: onebrandIdToInsert,
          category: categoryToInsert,
          organizationId: companyIdToInsert,
          front_path: frontPathToInsert,
          back_path: backPathToInsert,
          frontThumbnail: frontThumbnailToInsert,
          backThumbnail: backThumbnailToInsert,
          frontThumbnailImgPath: frontThumbnailImgPathToInsert,
          backThumbnailImgPath: backThumbnailImgPathToInsert,
          key: templateKey
        });
      })
      .catch(error => {
        console.log('Update level has failed: ', error);
      });
  },
  /*
   * Delete Template
   * @param {object} payload - get props: key
   */
  deleteTemplate({ commit, dispatch }, payload) {
    const templateKey = payload.key;
    return dbFS
      .collection('templates')
      .doc(templateKey)
      .get()
      .then(doc => {
        if (doc.exists) {
          const frontPath = doc.data().front_path;
          const backPath = doc.data().back_path;
          const frontThumbnailImgName = doc.data().frontThumbnailImgName;
          const backThumbnailImgName = doc.data().backThumbnailImgName;

          // delete front path
          dispatch('deletePath', { path: frontPath }).then(response => {
            // console.log('front response:', response)
            if (response.statusText) {
              console.log('front path deleted');
              commit('setErrors', {
                errors: {
                  frontPath: 'ok'
                }
              });
            } else {
              commit('setErrors', {
                errors: {
                  frontPath: response.response.data
                }
              });
            }
          });
          // .catch(e => {
          //      commit('setErrors', {
          //                  errors: {
          //                      frontPath: e
          //                  }
          //              })
          //      console.log('----e', e)
          //  })

          // delete back path
          dispatch('deletePath', { path: backPath }).then(response => {
            // console.log('back response:', response)
            if (response.statusText) {
              console.log('BACK path deleted');
              commit('setErrors', {
                errors: {
                  backPath: 'ok'
                }
              });
            } else {
              commit('setErrors', {
                errors: {
                  backPath: response.response.data
                }
              });
            }
          });
          // .catch(e => {
          //     commit('setErrors', {
          //                 errors: {
          //                     backPath: e
          //                 }
          //             })
          //     console.log('e:', e)
          // })

          // delete front image
          let storageFrontImgfRef = storage.ref('templates/designlive/dmm-salesforce/' + frontThumbnailImgName);
          storageFrontImgfRef
            .delete()
            .then(() => {
              console.log('front image deletedd');
              commit('setErrors', {
                errors: {
                  frontImage: 'ok'
                }
              });
            })
            .catch(e => {
              console.log(e);
              commit('setErrors', {
                errors: {
                  frontImage: e.message
                }
              });
            });
          // delete front image thumbnail
          let thumbFrontImgRef = storage.ref('templates/designlive/dmm-salesforce/' + 'thumb_' + frontThumbnailImgName);
          thumbFrontImgRef
            .delete()
            .then(() => {
              console.log('Front THUMB image deletedd');
              commit('setErrors', {
                errors: {
                  frontThumbnailImage: 'ok'
                }
              });
            })
            .catch(e => {
              console.log(e);
              commit('setErrors', {
                errors: {
                  frontThumbnailImage: e.message
                }
              });
            });

          // delete back image
          let storageBackImgfRef = storage.ref('templates/designlive/dmm-salesforce/' + backThumbnailImgName);
          storageBackImgfRef
            .delete()
            .then(() => {
              console.log('Back image deleted');
              commit('setErrors', {
                errors: {
                  backImage: 'ok'
                }
              });
            })
            .catch(e => {
              console.log(e);
              commit('setErrors', {
                errors: {
                  backImage: e.message
                }
              });
            });

          // delete front image thumbnail
          let thumbBackImgRef = storage.ref('templates/designlive/dmm-salesforce/' + 'thumb_' + backThumbnailImgName);
          thumbBackImgRef
            .delete()
            .then(() => {
              console.log('BACK THUMB image deleted');
              commit('setErrors', {
                errors: {
                  backThumbnailImage: 'ok'
                }
              });
            })
            .catch(e => {
              console.log(e);
              commit('setErrors', {
                errors: {
                  backThumbnailImage: e.message
                }
              });
            });

          // DELETE TEMPLATE
          dbFS
            .collection('templates')
            .doc(templateKey)
            .delete()
            .then(() => {
              // return {deleted: true}
            });
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!');
          commit('setErrors', false);
          return { deleted: 'notfound' };
        }
      })
      .catch(error => {
        console.log('Error getting document:', error);
      });
  },
  getOrganisationNameByToken({ commit }, payload) {
    return dbFS
      .collection('companies')
      .where('authToken', '==', payload.token)
      .get()
      .then(querySnapshot => {
        // check if results exists
        if (querySnapshot.size > 0) {
          let organizationNameFound = '';
          let organizationId = '';
          querySnapshot.forEach(doc => {
            // console.log('data', doc.data())
            const organizationName = doc.data().name;
            organizationNameFound = organizationName;
            organizationId = doc.data().organization_id;
          });
          return {
            organizationName: organizationNameFound,
            organizationId: organizationId
          };
        } else {
          return { organizationName: 'undefined' };
        }
      })
      .catch(error => {
        console.log('Get company by token error: ', error);
      });
  },
  listSelectedTemplate({ commit }, payload) {
    return dbFS
      .collection('templates')
      .doc(payload.templateId)
      .get()
      .then(doc => {
        const template = doc.data();
        commit('listTemplate', template);
      });
  },
  clearBackImageFields({ commit }, payload) {
    const templateKey = payload.templateKey;
    return dbFS
      .collection('templates')
      .doc(templateKey)
      .update({
        backThumbnail: '',
        backThumbnailImgName: '',
        backThumbnailImgPath: ''
      })
      .then(() => {
        console.log('Clear back fields has succeeded! ');
        return { cleared: true };
      })
      .catch(error => {
        console.log('Clear back fields has failed: ', error);
      });
  },
  clearFrontImageFields({ commit }, payload) {
    const templateKey = payload.templateKey;
    return dbFS
      .collection('templates')
      .doc(templateKey)
      .update({
        frontThumbnail: '',
        frontThumbnailImgName: '',
        frontThumbnailImgPath: ''
      })
      .then(() => {
        console.log('Clear front fields has succeeded! ');
        return { cleared: true };
      })
      .catch(error => {
        console.log('Clear front fields has failed: ', error);
      });
  },
  addImage({ commit }, payload) {
    const file = payload.file;
    const fieldName = payload.fieldName;
    const companyId = payload.companyID;
    const filename = file.name;
    const filenameToSend = companyId + '-' + filename.toLowerCase();

    const frontThumbObj = payload.frontThumbObj;
    const backThumbObj = payload.backThumbObj;

    // id+lowercase(name)
    let storageImgfRef = storage.ref('templates/designlive/dmm-salesforce/' + filenameToSend);
    // console.log('Vuex Field name:', fieldName)
    if (fieldName === 'front') {
      // check if front object is empyt (first image added)
      if (frontThumbObj.imgName === '' && frontThumbObj.url === '') {
        return storageImgfRef
          .put(file)
          .then(snapshot => {
            // extra step just for example
            return snapshot.metadata.name;
          })
          .then(res => {
            return storageRef.child('templates/designlive/dmm-salesforce/' + res).getDownloadURL();
          })
          .then(downloadURL => {
            // console.log('File available at', downloadURL)
            return {
              downloadURL: downloadURL,
              fieldName: fieldName,
              filename: filenameToSend
            };
          })
          .catch(error => {
            console.error('Error : ', error);
          });
      } else {
        // front thumbnail already added (delete current image and add new image)

        // Create a reference to the file to delete
        let storageImgfRef = storage.ref('templates/designlive/dmm-salesforce/' + frontThumbObj.imgName);
        return storageImgfRef
          .delete()
          .then(() => {
            console.log('front Image deleted');
            // delete thumbnail
            let storageThumbImgfRef = storage.ref('templates/designlive/dmm-salesforce/' + 'thumb_' + frontThumbObj.imgName);
            return storageThumbImgfRef.delete().then(() => {
              console.log('front thumb Image deleted');
              let storageImgfRefToIns = storage.ref('templates/designlive/dmm-salesforce/' + filenameToSend);
              return storageImgfRefToIns
                .put(file)
                .then(snapshot => {
                  return snapshot.metadata.name;
                })
                .then(res => {
                  return storageRef.child('templates/designlive/dmm-salesforce/' + res).getDownloadURL();
                })
                .then(downloadURL => {
                  // console.log('NEW File available at', downloadURL)
                  return {
                    downloadURL: downloadURL,
                    filename: filenameToSend,
                    fieldName: fieldName
                  };
                })
                .catch(error => {
                  console.error('Error : ', error);
                });
            });
          })
          .catch(error => {
            console.error('Delete Error : ', error);
          });
      }
    } else if (fieldName === 'back') {
      if (backThumbObj.imgName === '' && backThumbObj.url === '') {
        return storageImgfRef
          .put(file)
          .then(snapshot => {
            return snapshot.metadata.name;
          })
          .then(res => {
            return storageRef.child('templates/designlive/dmm-salesforce/' + res).getDownloadURL();
          })
          .then(downloadURL => {
            // console.log('File available at', downloadURL)
            return {
              downloadURL: downloadURL,
              fieldName: 'back',
              filename: filenameToSend
            };
          })
          .catch(error => {
            console.error('Error front thumbnail : ', error);
          });
      } else {
        // back thumbnail already added (delete current image and add new image)
        // Create a reference to the file to delete

        let storageImgfRef = storage.ref('templates/designlive/dmm-salesforce/' + backThumbObj.imgName);
        return storageImgfRef
          .delete()
          .then(() => {
            // console.log('Back Image deleted')
            // delete thumbnail
            let storageThumbImgfRef = storage.ref('templates/designlive/dmm-salesforce/' + 'thumb_' + backThumbObj.imgName);
            return storageThumbImgfRef.delete().then(() => {
              let storageImgfRefToIns = storage.ref('templates/designlive/dmm-salesforce/' + filenameToSend);
              return storageImgfRefToIns
                .put(file)
                .then(snapshot => {
                  return snapshot.metadata.name;
                })
                .then(res => {
                  return storageRef.child('templates/designlive/dmm-salesforce/' + res).getDownloadURL();
                })
                .then(downloadURL => {
                  // console.log('NEW BACK File available at', downloadURL)
                  return {
                    downloadURL: downloadURL,
                    fieldName: 'back',
                    filename: filenameToSend
                  };
                })
                .catch(error => {
                  console.error('Error back thumbnail: ', error);
                });
            });
          })
          .catch(error => {
            console.error('Delete Error : ', error);
          });
      }
    }
  },
  getDownloadUrl({ commit }, payload) {
    const metadataName = payload.metadataName;
    const logKey = payload.logKey;
    return storageRef
      .child(metadataName)
      .getDownloadURL()
      .then(downloadURL => {
        // delete log key
        return dbFS
          .collection('logthumbnails')
          .doc(logKey)
          .delete()
          .then(() => {
            return {
              downloadURL: downloadURL
            };
          })
          .catch(error => {
            console.log('Delete log has failed: ', error);
          });
      })
      .catch(error => {
        console.log('Get path of thumbnail has failed: ', error);
      });
  },
  updateFrontImage({ commit }, payload) {
    const file = payload.file;
    const companyId = payload.companyID;
    const frontImageName = payload.frontImageName;
    const filename = file.name;
    const filenameToSend = companyId + '-' + filename.toLowerCase();
    const frontFieldsCleared = payload.frontFieldsCleared;
    console.log('frontFieldsCleared:', frontFieldsCleared);

    if (frontFieldsCleared) {
      // insert new image
      let storageImgfRefToIns = storage.ref('templates/designlive/dmm-salesforce/' + filenameToSend);

      return storageImgfRefToIns
        .put(file)
        .then(snapshot => {
          // extra step just for example
          console.log('Metadata new image');
          return snapshot.metadata.name;
        })
        .then(res => {
          return storageRef.child('templates/designlive/dmm-salesforce/' + res).getDownloadURL();
        })
        .then(downloadURL => {
          console.log('Download new image url, file available at: ', downloadURL);
          return {
            downloadURL: downloadURL,
            filename: filenameToSend
          };
        })
        .catch(error => {
          console.error('Error : ', error);
        });
    } else {
      // Create a reference to the file to delete
      let storageImgfRef = storage.ref('templates/designlive/dmm-salesforce/' + frontImageName);
      // Delete the file
      return storageImgfRef
        .delete()
        .then(() => {
          // File deleted successfully
          console.log('Deleted Main image');
          // Delete Thumbnail
          let storageThumbImgfRef = storage.ref('templates/designlive/dmm-salesforce/' + 'thumb_' + frontImageName);
          return storageThumbImgfRef
            .delete()
            .then(() => {
              console.log('Deleted THUMB image');
              // insert new image
              let storageImgfRefToIns = storage.ref('templates/designlive/dmm-salesforce/' + filenameToSend);

              return storageImgfRefToIns
                .put(file)
                .then(snapshot => {
                  // extra step just for example
                  console.log('Metadata new image');
                  return snapshot.metadata.name;
                })
                .then(res => {
                  return storageRef.child('templates/designlive/dmm-salesforce/' + res).getDownloadURL();
                })
                .then(downloadURL => {
                  console.log('Download new image url, file available at: ', downloadURL);
                  return {
                    downloadURL: downloadURL,
                    filename: filenameToSend
                  };
                })
                .catch(error => {
                  console.error('Error : ', error);
                });
            })
            .catch(error => {
              console.error('Delete thumbnail Error : ', error);
            });
        })
        .catch(error => {
          console.error('Delete Error : ', error);
        });
    }
  },
  updateBackImage({ commit }, payload) {
    const file = payload.file;
    const companyId = payload.companyID;
    const backImageName = payload.backImageName;
    const filename = file.name;
    const filenameToSend = companyId + '-' + filename.toLowerCase();
    const backFieldsCleared = payload.backFieldsCleared;
    console.log('backImageName', backImageName);
    console.log('backFieldsCleared', backFieldsCleared);

    // if data was cleared add new files
    if (backFieldsCleared) {
      console.log('**new image:', filenameToSend);
      let storageImgfRefToIns = storage.ref('templates/designlive/dmm-salesforce/' + filenameToSend);
      return storageImgfRefToIns
        .put(file)
        .then(snapshot => {
          // extra step just for example
          console.log('-- Main snapshot metadata: ', snapshot.metadata.name);
          return snapshot.metadata.name;
        })
        .then(res => {
          return storageRef.child('templates/designlive/dmm-salesforce/' + res).getDownloadURL();
        })
        .then(downloadURL => {
          console.log('-- Back Donwload url: ', downloadURL);
          return {
            downloadURL: downloadURL,
            filename: filenameToSend
          };
        })
        .catch(error => {
          console.error('Error : ', error);
        });
    } else {
      // Create a reference to the file to delete
      let storageImgfRef = storage.ref('templates/designlive/dmm-salesforce/' + backImageName);
      console.log('storageImgfRef:', storageImgfRef);
      // Delete the file
      return storageImgfRef
        .delete()
        .then(() => {
          console.log('-- Main back image Deleted');
          // Delete Thumbnail
          let storageThumbImgfRef = storage.ref('templates/designlive/dmm-salesforce/' + 'thumb_' + backImageName);
          return storageThumbImgfRef
            .delete()
            .then(() => {
              console.log('-- Thumb back image Deleted');
              // insert new image
              console.log('**new image:', filenameToSend);
              let storageImgfRefToIns = storage.ref('templates/designlive/dmm-salesforce/' + filenameToSend);

              return storageImgfRefToIns
                .put(file)
                .then(snapshot => {
                  // extra step just for example
                  console.log('-- Main snapshot metadata: ', snapshot.metadata.name);
                  return snapshot.metadata.name;
                })
                .then(res => {
                  return storageRef.child('templates/designlive/dmm-salesforce/' + res).getDownloadURL();
                })
                .then(downloadURL => {
                  console.log('-- Back Donwload url: ', downloadURL);
                  return {
                    downloadURL: downloadURL,
                    filename: filenameToSend
                  };
                })
                .catch(error => {
                  console.error('Error : ', error);
                });
            })
            .catch(error => {
              console.error('Delete Thumbnail Error : ', error);
            });
        })
        .catch(error => {
          console.error('Delete Error : ', error);
        });
    }
  },
  deletePath({ commit }, payload) {
    return httpClient
      .delete({
        url: `${process.env.VUE_APP_DESIGN_LIVE_BASE_URL}${payload.path}`,
        headers: { 'X-CustomersCanvasAPIKey': payload.apiKey }
      })
      .then(result => ({ statusText: result.statusText }));
  },
  updateFilePath({ commit }, payload) {
    const file = payload.file;
    const companyId = payload.companyID;
    const apiKey = '940f95617a1d38547f3b96485eed76ce';

    // insert
    const folder = 'dmm-salesforce/org-' + companyId + '/';
    const fieldName = payload.fieldName;
    const timestamp = payload.timestamp;
    let filename = '';
    let formData = new FormData();
    if (fieldName === 'front_path') {
      filename = timestamp + '-front-' + file.name;
    } else if (fieldName === 'back_path') {
      filename = timestamp + '-back-' + file.name;
    } else {
      return { data: false };
    }

    formData.append(fieldName, file, filename);

    return httpClient
      .delete({
        url: `${process.env.VUE_APP_DESIGN_LIVE_BASE_URL}${payload.textField}`,
        headers: { 'X-CustomersCanvasAPIKey': apiKey }
      })
      .then(result => {
        if (result.statusText === 'OK') {
          return httpClient
            .post({
              url: `${process.env.VUE_APP_DESIGN_LIVE_BASE_URL}${encodeURIComponent(folder)}`,
              data: formData,
              headers: { 'X-CustomersCanvasAPIKey': apiKey }
            })
            .then(response => ({ data: response.data }))
            .catch(error => {
              console.log('[updateFilePath] - Add image to designLive has failed: ', error);
              return Promise.reject(error);
            });
        } else {
          return { status: false };
        }
      })
      .catch(error => {
        console.log('[updateFilePath] - delete Error: ', error);
        return Promise.reject(error);
      });
  },
  updateFileOnDMM({ commit }, payload) {
    const formData = new FormData();
    formData.append(payload.fieldName, payload.file, payload.file.name);

    return httpClient
      .post({
        url: `${process.env.VUE_APP_DESIGN_LIVE_BASE_URL}${payload.folder}`,
        headers: { 'X-CustomersCanvasAPIKey': payload.apiKey },
        data: formData
      })
      .then(response => ({ data: response.data }))
      .catch(error => {
        console.log('[updateFileOnDMM] Add image to designLive has failed:', error);
        return Promise.reject(error);
      });
  },
  clearLogThumbnails({ commit }, payload) {
    dbFS
      .collection('logthumbnails')
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          querySnapshot.forEach(doc => {
            const date = doc.data().date;
            const path = doc.data().metadataName;
            const docId = doc.id;
            dbFS
              .collection('logthumbnails')
              .doc(docId)
              .delete()
              .then(() => {
                console.log('Log with firestore id:', docId, 'from ', date, 'with path: ', path, ', deleted successfully!');
              })
              .catch(error => {
                console.error('Error removing document: ', error);
              });
          });
        }
      });
  },
  listCategories({ commit, dispatch, getters }, payload) {
    let categoryList = [];
    return dbFS
      .collection('categories')
      .orderBy('name')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = {
            name: doc.data().name,
            description: doc.data().description,
            count: null,
            document_id: doc.id
          };
          categoryList.push(data);
        });

        return dispatch('listTemplates')
          .then(() => {
            let i = 0;
            categoryList.forEach((valueCat, index) => {
              getters.templates.forEach((valueTemp, index) => {
                const templateCategory = valueTemp.category;
                if (typeof templateCategory === 'object') {
                  templateCategory.forEach((valueCatArr, index) => {
                    if (valueCat.name === valueCatArr) {
                      i++;
                    }
                  });
                }
              });
              valueCat.count = i;
              i = 0;
            });
            commit('setCategories', categoryList);
            return true;
          })
          .catch(err => {
            console.log('Error: ', err);
            return err;
          });
      })
      .catch(err => {
        console.log('Error: ', err);
        return err;
      });
  },
  addCountToSeletedTemplate({ commit, getters }, payload) {
    const docID = payload.docId;
    let count = null;
    getters.categories.forEach((value, index) => {
      if (value.document_id === docID) {
        count = value.count;
      }
    });
    if (count) {
      return { count: count };
    } else {
      return { count: 0 };
    }
  },
  saveCategory({ commit }, payload) {
    const categoryNameToInsert = payload.categoryName;
    const descriptionToInsert = payload.description;
    return dbFS
      .collection('categories')
      .add({
        name: categoryNameToInsert,
        description: descriptionToInsert
      })
      .then(data => {
        console.log('Save new category has succeeded: ', data);
        commit('addCategory', {
          name: categoryNameToInsert,
          description: descriptionToInsert,
          document_id: data.id
        });
      })
      .catch(error => {
        console.log('Save category has failed: ', error);
      });
  },
  editCategory({ commit }, payload) {
    const categoryNameToInsert = payload.categoryName;
    const descriptionToInsert = payload.description;
    const docKey = payload.key;

    return dbFS
      .collection('categories')
      .doc(docKey)
      .update({
        name: categoryNameToInsert,
        description: descriptionToInsert
      })
      .then(() => {
        // console.log('Update level has succeeded! ')
        commit('updateCategory', {
          name: categoryNameToInsert,
          description: descriptionToInsert,
          key: docKey
        });
      })
      .catch(error => {
        console.log('Update category has failed: ', error);
      });
  },
  deleteCategory({ commit }, payload) {
    const categoryKey = payload.key;
    return dbFS
      .collection('categories')
      .doc(categoryKey)
      .delete()
      .then(() => {
        commit('deleteCategory', {
          key: categoryKey
        });
      })
      .catch(error => {
        console.error('Error removing document: ', error);
      });
  },
  // File explorer
  checkFileInTemplate({ commit }, payload) {
    const filePath = payload.filePath;
    return dbFS
      .collection('templates')
      .where('front_path', '==', filePath)
      .get()
      .then(querySnapshot => {
        // console.log('querySnapshot:', querySnapshot)
        // check if results exists
        if (querySnapshot.size > 0) {
          let content = {};
          let id = null;
          querySnapshot.forEach(doc => {
            content = doc.data();
            id = doc.id;
          });
          return {
            path: 'front',
            content: content,
            id: id
          };
        } else {
          return dbFS
            .collection('templates')
            .where('back_path', '==', filePath)
            .get()
            .then(querySnapshot => {
              // console.log('querySnapshot back:', querySnapshot)
              if (querySnapshot.size > 0) {
                let content = {};
                let id = null;
                querySnapshot.forEach(doc => {
                  content = doc.data();
                  id = doc.id;
                });
                return {
                  path: 'back',
                  content: content,
                  id: id
                };
              } else {
                return false;
              }
            });
        }
      });
  },
  setPath({ commit }, payload) {
    commit('setPath', payload);
  },
  loadDesignLiveListToFirebase({ commit }, payload) {
    const time = new Date();
    const timeToString = time.toISOString() + ' GMT+00';

    return httpClient
      .get({ url: process.env.VUE_APP_DESIGN_LIVE_BASE_URL })
      .then(response => {
        return dbFS
          .collection('designList')
          .doc('4UMWEthENb8V59cVFYZU')
          .set({
            designList: response.data,
            lastUpdate: timeToString
          })
          .then(() => {
            console.log('Design live list successfully updated in firebase firestore!');
            return Promise.resolve({ data: true });
          })
          .catch(error => {
            console.error('Error writing document: ', error);
            return Promise.reject(error);
          });
      })
      .catch(error => {
        console.log('[loadDesignLiveListToFirebase] Connect to design live server has failed: ', error);
        return Promise.reject(error);
      });
  },
  insertDesignLiveList({ commit }, payload) {
    return httpClient
      .get({ url: process.env.VUE_APP_DESIGN_LIVE_BASE_URL })
      .then(response => ({ data: response.data }))
      .catch(error => {
        console.error('[insertDesignLiveList] Error writing document: ', error);
        return Promise.reject(error);
      });
  },
  listPaths({ commit }, payload) {
    let pathsList = [];
    dbFS
      .collection('designList')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // console.log('doc:', doc.data())
          pathsList = doc.data().designList;
        });
        // console.log('pathsList:', pathsList)
        let pathListArray = [];
        pathsList.forEach((value, index) => {
          // const element = 'designs/' + value
          pathListArray.push({ path: value });
        });
        // console.log('pathListArray:', pathListArray)
        commit('setPaths', pathListArray);
        // commit('setDesignsList', pathsList)
      });
  },
  listDesigns({ commit, dispatch, getters }, payload) {
    let pathsList = [];
    return dbFS
      .collection('designList')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // console.log('doc:', doc.data())
          pathsList = doc.data().designList;
        });
        // console.log('pathsList:', pathsList)

        commit('setDesignsList', pathsList);
        return { data: pathsList };
      });
  },
  listDesignsFolders() {
    let pathsList = [];
    return dbFS
      .collection('designList')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // console.log('doc:', doc.data())
          pathsList = doc.data().designList;
        });

        let firstDirArray = [];
        let rootFiles = [];
        let rootDirAndFiles = [];
        let firstDirList = [];
        let secondDirlist = [];
        let thirdDirlist = [];
        pathsList.forEach((value, index) => {
          // console.log(value)
          var test = value.split('/');
          if (test.length > 1) {
            firstDirList.push(test[0]);
          }
          if (test.length > 3) {
            const pathElement = test[0] + '/' + test[1];
            const path3Elements = test[0] + '/' + test[1] + '/' + test[2];
            secondDirlist.push(pathElement);
            thirdDirlist.push(path3Elements);
          }
          if (test.length === 2) {
            firstDirArray.push(test[0]);
            const element = test[0] + '/' + test[1];
            rootDirAndFiles.push({ element });
          } else if (test.length > 2) {
            let path = '';
            let dirLength = test.length - 1;
            test.forEach((value, index) => {
              if (index < dirLength) {
                path = path + '/' + value;
              }
            });
            path = path.substring(1);
            firstDirArray.push(path);
          } else {
            rootFiles.push({
              name: test[0],
              file: 'psd'
            });
          }
        });

        // first Directory list
        let uniqueFirstDir = [...new Set(firstDirList)];

        // second Directory list
        let uniqueSecondDir = [...new Set(secondDirlist)];

        // Third Directory List
        let uniqueThirdDir = [...new Set(thirdDirlist)];

        let unique = [...new Set(firstDirArray)];
        // console.log('** UNiQUE:', unique)

        // concatenate Dir's with
        const concatUnique1 = unique.concat(uniqueFirstDir);
        const concatUnique2 = concatUnique1.concat(uniqueSecondDir);
        const concatUnique3 = concatUnique2.concat(uniqueThirdDir);

        // unique list
        let uniqueConcatList = [...new Set(concatUnique3)];

        let uniqueComplete = [];
        uniqueConcatList.forEach((value, index) => {
          const path = value + '/';
          uniqueComplete.push({
            path: path
          });
        });
        // console.log('** UNIQUE COMPLETE design live:', uniqueComplete)
        // commit('setDesignsList', pathsList)
        return { uniqueComplete };
      });
  },
  /*
   * Init realtime updates with Cloud Firestore - view changes between snapshots
   *
   */
  initRealtimeListenersForPaths({ commit }, payload) {
    dbFS.collection('designList').onSnapshot(snapshot => {
      // var source1 = snapshot.metadata.hasPendingWrites ? 'Local' : 'Server'
      // console.log('source1:', source1)
      snapshot.docChanges().forEach(change => {
        if (change.type === 'modified') {
          // console.log('Updated !', change.doc.data())
          // if (source1 === 'Server') {
          commit('updateDesignLive', {
            designList: change.doc.data().designList
          });
          // }
        }
      });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
