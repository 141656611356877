import FirestoreModel from '../models/firestore-model';
import { collections } from '../models/firestore-model';

class ProductServiceService extends FirestoreModel {
  constructor() {
    super(collections.product_services);
  }
}

export default new ProductServiceService();
