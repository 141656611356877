<template>
  <div>
    <!-- <div class="text-xs-center mb-3">{{ panel }}</div>
      <v-expansion-panel popout
        v-model="panel"
      >
        <v-expansion-panel-content>
          <template v-slot:header>
            <div>
              <p :class="{ active: isActive0 }" class="subheading font-weight-medium">Summary</p>
              <p class="body-2 font-weight-medium grey--text text--darken-1" v-if="!isActive0">View order details, date, Id's, customers details</p>
            </div>
          </template>
          <v-divider></v-divider>
          <v-card>
            <v-card-text>
                <v-layout row wrap>
                    <v-flex xs3 sm3 class="test-custom-right-border">
                        <v-layout align-center justify-start row fill-heigh>
                          <div class="test-set-inline-block mx-0 my-0 mr-2">
                              <v-icon
                                medium
                                color="cyan darken-1"
                                class="mt-2"
                               >mdi-calendar-text</v-icon>
                          </div>
                          <div class="test-set-inline-block mx-0 mt-3">
                              <p class="font-weight-light grey--text text--darken-1 mx-0 mb-0">Date</p>
                              <p class="subheading font-weight-bold">October 17, 2019 at 16:19:41</p>
                          </div>
                        </v-layout>
                    </v-flex>
                    <v-flex xs3 sm3 class="test-custom-right-border">
                        <div class="test-set-inline-block mx-0 my-0 mr-2 ml-2">
                            <v-icon
                              medium
                              color="cyan darken-1"
                              class="mb-2"
                             >mdi-account</v-icon>
                        </div>
                        <div class="test-set-inline-block mx-0 mt-3">
                            <p class="font-weight-light grey--text text--darken-1 mb-0">Customer</p>
                            <p class="font-weight-bold">Jonathan Doe</p>
                        </div>
                    </v-flex>
                    <v-flex xs3 sm3 class="test-custom-right-border">
                        <div class="test-set-inline-block mx-0 my-0 mr-2 ml-2">
                            <v-icon
                              medium
                              color="cyan darken-1"
                              class="mb-2"
                             >mdi-clipboard-text</v-icon>
                        </div>
                        <div class="test-set-inline-block mx-0 mt-3">
                            <p class="font-weight-light grey--text text--darken-1 mb-0">Order Types</p>
                            <p class="font-weight-bold">New Movers</p>
                        </div>
                    </v-flex>
                    <v-flex xs3 sm3>
                        <div class="test-set-inline-block mx-0 my-0 mr-2 ml-2">
                            <v-icon
                              medium
                              color="cyan darken-1"
                              class="mb-2"
                             >mdi-currency-usd</v-icon>
                        </div>
                        <div class="test-set-inline-block mx-0 mt-3">
                            <p class="font-weight-light grey--text text--darken-1 mb-0">Total Price</p>
                            <p class="font-weight-bold">$250.00</p>
                        </div>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs6 sm6>

                  </v-flex>
                  <v-flex xs6 sm6>

                  </v-flex>
                </v-layout>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
        <v-expansion-panel-content>
          <template v-slot:header>
            <div>
                <p :class="{ active: isActive1 }">Logs</p>
                <p class="body-2 font-weight-medium grey--text text--darken-1" v-if="!isActive1">View all activity from this order</p>
            </div>
          </template>
          <v-card>
            <v-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel> -->

    <v-layout row wrap class="my-3">
      <v-flex xs6>
        <h2>Testing list order settings</h2>
        <v-btn class="static" @click="listOrderSettings()">
          <span>List</span>
        </v-btn>
      </v-flex>
      <v-flex xs6>
        <h2>Testing Firestore rules</h2>
        <v-btn class="static" @click="addToTest()">
          <span>Add to test colection</span>
        </v-btn>
        <v-btn class="static" @click="listFromTest()">
          <span>List from test</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout row wrap class="my-3">
      <v-flex xs12>
        <h3>REALTIME DB</h3>
        <v-layout row wrap>
          <v-flex xs4 sm4>
            <h3>test array insert in realtime db</h3>
            <v-btn class="static" @click="testArray()">
              <span>Change</span>
            </v-btn>
          </v-flex>
          <v-flex xs4 sm4>
            <h3>test insert new attr in firestore-colection-map field</h3>
            <v-btn class="static" @click="testMap()">
              <span>Change</span>
            </v-btn>
          </v-flex>
          <v-flex xs4 sm4>
            <h3>Test get site name based on the Realtime DB -ID -</h3>
            <v-btn class="static" @click="testGetSiteName()">
              <span>TEST</span>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="mt-4">
          <v-flex xs4 sm4>
            <h3>Check prop in orders Settings</h3>
            <v-btn class="static" @click="checkProp()">
              <span>check Prop </span>
            </v-btn>
          </v-flex>
          <v-flex xs4 sm4> </v-flex>
          <v-flex xs4 sm4> </v-flex>
        </v-layout>
        <v-divider class="my-3"></v-divider>
        <v-layout row wrap class="mt-4">
          <v-flex xs4 sm4>
            <h3>Add attributes orders Settings</h3>
            <v-text-field v-model="RLsiteID" label="site ID" type="text"></v-text-field>
            <v-btn color="blue" @click="changeSwitchObject()">
              <span>change switch object </span>
            </v-btn>
            <v-select v-model="selectedOrderType" :items="orderType" label="Order Type"></v-select>
            <div v-if="orderProcessesArray.length > 0">
              <v-select v-model="selectedOrderSettings" :items="orderProcessesArray" label="Order Settings"></v-select>
            </div>
            <v-btn class="static" @click="checkProp()">
              <span>check Prop </span>
            </v-btn>
          </v-flex>
          <v-flex xs4 sm4> </v-flex>
          <v-flex xs4 sm4> </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout row wrap class="my-4">
      <v-flex xs4>
        <h3>Test firestore WHERE -</h3>
        <v-btn class="static" @click="testwhere()">
          <span>TEST Where</span>
        </v-btn>
      </v-flex>
      <v-flex xs4>
        <h3>Test firestore WHERE Customer-</h3>
        <v-btn class="static" @click="testwhereCustomer()">
          <span>TEST WhereCustomer</span>
        </v-btn>
      </v-flex>
      <v-flex xs4>
        <h3>Test firestore DELETE subcollection-</h3>
        <v-btn outlined color="primary" @click="testsubcolection()">
          <span>TEST subcollection</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout row wrap class="my-3">
      <v-flex xs4>
        <h3>Test Change fields in FIRESTORE for specific order -</h3>
        <v-btn class="static" @click="testChangefields()">
          <span>TEST Change fields in order</span>
        </v-btn>
      </v-flex>
      <v-flex xs4>
        <h3>Test Get fields in FIRESTORE -</h3>
        <v-btn class="static" @click="testgetfields()">
          <span>- Orders- </span>
        </v-btn>
        <v-btn class="static" @click="testgetfieldsDesigns()">
          <span> - designs- </span>
        </v-btn>
        <v-btn class="static" @click="testgetfieldsLists()">
          <span> - lists- </span>
        </v-btn>
        <v-btn class="static" @click="testgetfieldsMaps()">
          <span> - Backup- </span>
        </v-btn>
      </v-flex>
      <v-flex xs4 sm4>
        <h3>Test Backup list FIRESTORE -</h3>
        <v-btn class="static" @click="testgetbackupfields()">
          <span> - Backupps- </span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout row wrap class="my-3">
      <v-flex xs12>
        <h3>Test Add Notification -</h3>
        <v-btn class="static" @click="testAddNotification()">
          <span>add notification</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="my-3">
      <v-flex xs12>
        <h3>Test Backup -</h3>
        <v-btn class="static" @click="testBackup()">
          <span>Backup</span>
        </v-btn>
        <v-btn>
          <v-icon> mdi-database-export </v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-divider class="my-2"></v-divider>
    <v-layout row wrap class="my-3">
      <v-flex xs4>
        <h3>Test Json from download URL -</h3>
        <v-btn class="static" @click="testJson()">
          <span>Json</span>
        </v-btn>
      </v-flex>
      <v-flex xs4>
        <h3>Download json from storage -</h3>
        <v-btn class="static" @click="downloadJsonFromStorage()">
          <span>Download Json</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-divider class="my-2"></v-divider>
    <v-layout row wrap class="my-3">
      <v-flex xs4>
        <h3>Test LDP API -</h3>
        <v-btn class="static" @click="testLdpAPi()">
          <span>test api</span>
        </v-btn>
      </v-flex>
      <v-flex xs4>
        <h3>Test GET order by key -</h3>
        <v-btn class="static" @click="testGetOrderByKey()">
          <span>test order</span>
        </v-btn>
      </v-flex>
      <v-flex xs4>
        <h3>Test Atz geocodes-</h3>
        <v-btn class="static" @click="testATZ()">
          <span>test ATZ</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-divider class="my-2"></v-divider>
    <v-layout row wrap class="my-3">
      <v-flex xs6>
        <h3>Save to FTP -</h3>
        <v-btn class="static my-4" @click="saveToFtp()">
          <span>Save to ftp</span>
        </v-btn>
        <el-upload
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :http-request="uploadFiles"
        >
          <el-button size="small" type="primary">Click to upload</el-button>
          <!-- <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 500kb</div> -->
        </el-upload>
      </v-flex>
      <v-flex xs6>
        <h3>Test token</h3>
        <v-btn class="static" @click="testToken()">
          <span>test token</span>
        </v-btn>
        <v-btn class="ma-2" outlined color="indigo">Outlined Button</v-btn>
      </v-flex>
    </v-layout>
    <v-divider class="my-2"></v-divider>
    <v-layout row wrap>
      <div class="my-5">
        <p class="subheading">Test Download PDF</p>
        <v-btn class="static" @click="testDownloadPDF()">
          <span>test download</span>
        </v-btn>
      </div>
    </v-layout>
    <v-divider class="my-2"></v-divider>
    <v-layout row wrap>
      <v-flex xs3>
        <h3 class="my-3">Algolia</h3>
        <p class="subheading">Test getSettings function</p>
        <v-btn class="static" @click="testAlgoliaGetSettings()">
          <span>Get settings</span>
        </v-btn>
      </v-flex>
      <v-flex xs3>
        <p class="subheading mt-5">Test listIndex function</p>

        <v-btn class="static" @click="testAlgoliaListIndex()">
          <span>List Indexes</span>
        </v-btn>
      </v-flex>
      <v-flex xs3>
        <p class="subheading mt-5">Test Delete index</p>
        <!-- <v-text-field
                  name="Id"
                  label="label"
                  id="id"
                ></v-text-field> -->
        <v-btn class="static" @click="testDeleteIndex()">
          <span>Delete Index</span>
        </v-btn>
        <v-btn class="static ml-3" @click="testDeleteObject()">
          <span>Delete Object</span>
        </v-btn>
      </v-flex>
      <v-flex xs3>
        <p class="subheading mt-5">Add new order to Algolia</p>
        <v-autocomplete v-model="selectedSite" :items="sites" label="Sites" chips item-value="firebase_id" item-text="site_id"></v-autocomplete>
        <v-autocomplete v-model="selectedEnv" :items="envs" label="envs" chips item-value="name" item-text="name"></v-autocomplete>
        <v-btn class="static" @click="addOrder()">
          <span>Add order</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-divider class="my-2"></v-divider>
    <v-layout row wrap>
      <v-flex xs4>
        <h3 class="my-3">Data axle</h3>
        <p class="subheading">Test Scan API</p>
        <v-btn class="static" @click="testScanAPI()">
          <span>Scan API</span>
        </v-btn>
      </v-flex>
      <v-flex xs4>
        <p class="subheading">Test Scroll data axle</p>
        <v-btn class="static" @click="testScrollAPI()">
          <span>scroll</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-divider class="my-2"></v-divider>
    <v-layout row wrap>
      <div class="my-3">
        <h2 class="my-0 mx-0">Dev TEST</h2>
      </div>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs4>
        <p class="subheading">SalesForce</p>
        <v-btn class="static" @click="testSalesForce()">
          <span>SalesForce</span>
        </v-btn>
      </v-flex>
      <v-flex xs4> </v-flex>
    </v-layout>
    <v-divider class="my-2"></v-divider>
    <v-layout row wrap>
      <v-flex xs4>
        <p class="subheading">Direct mail</p>
        <v-btn class="static" @click="testDmmCreateCampaign()">
          <span>Create campaign</span>
        </v-btn>
      </v-flex>
      <v-flex xs4> </v-flex>
    </v-layout>
    <v-divider class="my-2"></v-divider>
    <v-layout row wrap>
      <v-flex xs4>
        <p class="subheading">Delete Firebase Storage(file/folder)</p>
        <v-btn class="static" @click="deleteFirebaseStorege()">
          <span>Delete</span>
        </v-btn>
      </v-flex>
      <v-flex xs4> </v-flex>
    </v-layout>
    <v-divider class="my-2"></v-divider>
    <v-layout row wrap>
      <v-flex xs4>
        <p class="subheading">Political</p>
        <v-btn class="static" @click="checkPolitical()">
          <span>check</span>
        </v-btn>
      </v-flex>
      <v-flex xs4> </v-flex>
    </v-layout>
    <!-- <youtube :video-id="videoId" ref="youtube" @playing="playing"></youtube> -->
    <!-- <button @click="playVideo">play</button> -->
    <v-layout row wrap>
      <div class="my-5">
        <p>End of page</p>
      </div>
    </v-layout>
  </div>
</template>

<script>
// importing
import { dbFS, storageRef, firestoreTimestamp } from '../../services/firebaseInit';
import algoliasearch from 'algoliasearch';
import { mapGetters } from 'vuex';
// import qs from 'qs'
const db = {};
export default {
  data() {
    return {
      videoId: '9jK-NcRmVcw',
      RLsiteID: '',
      orderType: ['orderProcesses', 'orderSequence'],
      selectedOrderType: '',
      orderProcessesArray: [],
      selectedOrderSettings: 'ALL',
      env: process.env.NODE_ENV,
      // fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}],
      fileList: [],
      testJsonObj: {
        crm_order_id: 'Q-1562846471',
        order_label: 'TEST MIHAI - 5.25x11.5 Order 07/11/2019 08: 01 AM',
        client_logo: 'https: //devtest.onebrand.net/wp-content/uploads/sites/43/2018/11/OneBrandMT-01-1-300x126-web.png',
        creation_date: 'July 11,  2019',
        order_type: 'saturation',
        extra_qty: 142,
        mail_qty: 13110,
        total_qty: 13234,
        map_name: '4567',
        map_image: `${process.env.VUE_APP_FIREBASE_STORAGE_URL}wlos-1%2F61063-3%2Fmap%2F07-11-2019%2F085aYuz8ClG5vqFZo5t4%2Fmap_1562846382295.png?alt=media&token=f468f252-a1b2-4f9f-8a31-2fffb6df49f5`,
        product_name: '5.25×11.5 Large Anytime Postcard',
        artwork_type: 'Upload',
        gatekeeper_email: '',
        stock_type: '',
        coating_type: '',
        special_instructions: 'TEST INSTRUCTIONS',
        shipping_address: { label: '', apartment: '', street: '425 East Spruce St.', city: 'Jefferson City', state: 'MO', zip_code: '65109' },
        shipping_method_name: '2 Day',
        delivery: {
          delivery_dates: [
            { date: '2019-07-17', qty: 6555 },
            { date: '2019-07-25', qty: 6555 }
          ]
        },
        pricing: {
          order_total: 4095.3566,
          base_price: 1189.7366,
          postage: 2451.57,
          mailing_service_fee: 393.3,
          total_per_piece: 0.30945720114855674,
          list_purchase: 0,
          list_processing: 0,
          digital_price: 0,
          landing_page: 0,
          eddm_shipping_price: 0,
          extra_qty_price: 11.147599999999999,
          shipping_extra_price: 60.75,
          shipping: 0,
          tax_price: 0,
          stock_price: 0,
          coating_price: 0,
          design_price: 0,
          turnaround_price: 0
        },
        digital_campaign: { name: '', qty: 0, price: 0 },
        purchased_list_filters: ''
      },
      sites: [],
      selectedSite: null,
      searchClient: algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_API_KEY),
      client: algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_API_KEY),
      searchClientAdmin: algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_API_KEY),
      scanApiToken: process.env.VUE_APP_DATA_AXLE_TOKEN,
      selectedEnv: null,
      envs: [{ name: 'orders' }, { name: 'maps' }, { name: 'lists' }, { name: 'designs' }],
      panel: 0,
      isActive0: true,
      isActive1: false,
      processes: ['availability', 'custom', 'eddm', 'eddmPrintOnly', 'newMovers', 'printprepship', 'saturation', 'targeted', 'template'],
      newOrderSwitches: {
        switch_1: {
          field_description: '',
          field_label: '',
          field_name: '',
          field_type: '',
          value: false
        },
        switch_2: {
          field_description: '',
          field_label: '',
          field_name: '',
          field_type: '',
          value: false
        },
        switch_3: {
          field_description: '',
          field_label: '',
          field_name: '',
          field_type: '',
          value: false
        },
        switch_4: {
          field_description: '',
          field_label: '',
          field_name: '',
          field_type: '',
          value: false
        },
        switch_5: {
          field_description: '',
          field_label: '',
          field_name: '',
          field_type: '',
          value: false
        }
      },
      orderDataList: ['artwork', 'assets', 'customer', 'delivery', 'mailing', 'pricing', 'product']
    };
  },
  computed: {
    ...mapGetters('users', {
      userStore: 'user'
    }),
    siteSelected() {
      // console.log(this.selectedCategory)
      return this.selectedSite;
    },
    player() {
      return this.$refs.youtube.player;
    }
  },
  watch: {
    siteSelected(value) {
      // console.log('value', value)
    },
    panel(value) {
      console.log('value', value);
      console.log('value', this.panel);
      if (value === 0) {
        this.isActive0 = true;
        this.isActive1 = false;
      } else if (value === 1) {
        this.isActive1 = true;
        this.isActive0 = false;
      } else if (value === null) {
        this.isActive0 = false;
        this.isActive1 = false;
      }
    },
    selectedOrderType(value) {
      // console.log(value)
      // -Lu7t90MWKHODwcW_pxW
      if (value) {
        console.log(value);
        console.log(this.RLsiteID);
        // this.orderProcessesArray.push('ALL')
        db.ref('orderSettings/' + this.RLsiteID + '/' + value)
          .once('value')
          .then(snapshot => {
            snapshot.forEach(childSnapshot => {
              let totals = childSnapshot.val();
              let key = childSnapshot.key;
              if (key !== 'customQuote' && key !== 'map') {
                this.orderProcessesArray.push(key);
                console.log('key1:', key);
              }
              // console.log('totals:', totals)
            });
            console.log(this.orderProcessesArray);
          });
      }
    },
    selectedOrderSettings(value) {
      console.log('selectedOrderSettings value', value);
    }
  },
  created() {
    // console.log('test')
    console.log(process.env.NODE_ENV); // apiKey: this.apiKey
    var today = new Date();
    console.log(this.$moment(today).format('YYYY-MM-DD HH:mm:ss'));
  },
  mounted() {
    // const parameters = this.$route.query
    // console.log(parameters)
  },
  methods: {
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      console.log('watching!!!');
    },
    listOrderSettings() {
      console.log('TEST');
      db.ref('orderSettings')
        .once('value')
        .then(snapshot => {
          snapshot.forEach(childSnapshot => {
            // let totals = childSnapshot.val()
            let key = childSnapshot.key;
            console.log('key:', key);
          });
        });
    },
    addToTest() {
      dbFS
        .collection('test')
        .add({
          test: 'TESTING',
          is_readed: false,
          user: {
            name: 'Florin',
            last_name: 'CH'
          }
        })
        .then(data => {
          console.log('Item added successfully!');
        });
    },
    listFromTest() {
      dbFS
        .collection('test')
        .doc('Rqobzgd66B8Tiyvg2jQ7')
        .get({})
        .then(doc => {
          console.log('DOC DATA:', doc.data());
          console.log('DOC id:', doc.id);
        });
    },
    updateOrderProcessObject() {
      console.log('++++ updateOrderProcessObject');
      db.ref('orderSettings')
        .child('default')
        .child('orderProcesses')
        .once('value')
        .then(snapshot => {
          // console.log(snapshot.val())
          snapshot.forEach(childSnapshot => {
            // let childVal = childSnapshot.val()
            let key = childSnapshot.key;
            // console.log('val:', childVal)
            // console.log('key:', key)

            db.ref('orderSettings')
              .child('default')
              .child('orderProcesses')
              .child(key)
              .update({
                is_quote_enabled: false
              })
              .then(() => {
                console.log('done');
              });
          });
        });
    },
    testMap() {
      dbFS
        .collection('order_logs')
        .doc('MKgvDTk9l1ksRGBKnBxP')
        .get({})
        .then(doc => {
          console.log('DOC DATA:', doc.data());
          console.log('DOC id:', doc.id);
          // let actionsArr = []

          const oldActions = doc.data().actions;
          console.log('oldActions:', oldActions);
          const newAction = {
            action: 'update123',
            timestamp: 'timestamp1234'
          };
          oldActions.push(newAction);
          // actionsArr.push(newAction)
          console.log(oldActions);

          dbFS
            .collection('order_logs')
            .doc('MKgvDTk9l1ksRGBKnBxP')
            .update({
              actions: oldActions
            })
            .then(() => {
              console.error('Update Done!');
            })
            .catch(error => {
              console.error('Error : ', error);
            });
        })
        .catch(error => {
          console.error('Error : ', error);
        });

      // dbFS.collection('order_logs').doc('MKgvDTk9l1ksRGBKnBxP').update({
      //   actions: {
      //     action: 'test',
      //     timestamp: 'timestamp'
      //   }
      // }).then(() => {
      //    console.error('Update Done!')
      // })
      // .catch((error) => {
      //     console.error('Error : ', error)
      // })
    },
    testGetSiteName() {
      console.log('test get site name');
      db.ref('sites')
        .child('-LGjZtGpLePH-61Rp662')
        .once('value')
        .then(snapshot => {
          const siteName = snapshot.val().site_name;
          console.log('site name:', siteName);

          // snapshot.forEach((childSnapshot) => {
          //     let childVal = childSnapshot.val()
          //     let key = childSnapshot.key
          //     // console.log('val:', childVal)
          //     // console.log('key:', key)
          // })
        });
    },
    checkProp() {
      db.ref('orderSettings')
        .once('value')
        .then(snapshot => {
          snapshot.forEach(childSnapshot => {
            let totals = childSnapshot.val();
            let key = childSnapshot.key;
            // console.log('totals:', totals.orderProcesses)
            console.log('Site Key:', key);
            const orderProcesses = totals.orderProcesses;
            Object.entries(orderProcesses).forEach(([key2, value]) => {
              // console.log('value:', value)
              // console.log('Process Settings -:', key2)
              const orderProcessDetails = value.orderProcessDetails;
              // console.log('oorderSwitches:', orderProcessDetails.orderSwitches)
              if (key2 !== 'map' || key2 !== 'customQuote') {
                if (orderProcessDetails.orderSwitches === undefined) {
                  console.log('Process Settings -:', key2);
                }
              }

              // Object.entries(orderProcessDetails).forEach(([key1, value1]) => {
              //     // console.log(' ***orderSwitches key1:', value1)

              //     if (key1 === 'orderSwitches') {
              //         // console.log('orderProcessDetails:', orderProcessDetails)
              //         // console.log(' --orderSwitches--:', key2)
              //     }

              // })
              console.log('++++++++++++++++++');
            });
            console.log('----');
          });
        });
    },
    changeSwitchObject() {
      // -Lu7t90MWKHODwcW_pxW
      console.log('enter changeSwitchObject');
      let testArray = [];
      let testObject = {};
      // const prms = db.ref('orderSettings/' + this.RLsiteID + '/orderProcesses/' + value + '/orderProcessDetails' )

      const promises = [];
      // let switchObject = {}
      let orderSwitches = {};
      this.processes.forEach((value, index) => {
        const prms = db
          .ref('orderSettings/' + this.RLsiteID + '/orderProcesses/' + value + '/orderProcessDetails/orderSwitches')
          .once('value')
          .then(snapshot => {
            console.log(snapshot.val());
            const shoot = snapshot.val();
            let switchObject = {};
            Object.entries(shoot).forEach(([key, value]) => {
              switchObject = {
                field_description: '',
                field_label: value.label,
                field_name: '',
                field_type: '',
                value: value.value
              };
              orderSwitches[key] = switchObject;
              // console.log('switchObject:', switchObject)
              // console.log('---', orderSwitches)
              switchObject = {};
              // i++
            });
            // console.log('switchObject:', switchObject)
            console.log('---');
            console.log('---', orderSwitches);
            testArray.push({
              [value]: orderSwitches
            });
            testObject[value] = orderSwitches;
            orderSwitches = {};
          });
        promises.push(prms);
      });

      Promise.all(promises).then(() => {
        console.log('All sites parsed!');

        console.log('testObject', testObject);
        // -Lu7t90MWKHODwcW_pxW
        const promises = [];
        Object.entries(testObject).forEach(([key, value]) => {
          // console.log(key)
          // console.log(value)
          const prms = db.ref('orderSettings/' + this.RLsiteID + '/orderProcesses/' + key + '/orderProcessDetails').update({
            orderSwitches: value
          });
          promises.push(prms);
        });
        Promise.all(promises).then(() => {
          console.log('All processes updated!');
        });
      });

      // const prms = db.ref('orderSettings/' + this.RLsiteID + '/orderProcesses/custom/orderProcessDetails/orderSwitches' )
      // .once('value')
      // .then((snapshot) => {
      //     console.log(snapshot.val())
      //     const shoot = snapshot.val()
      //     testArray.push({
      //             availability: shoot
      //     })
      // })

      // const promises = []
      // this.processes.forEach((value, index) => {
      //   console.log('val:', value)

      //   const prms = db.ref('orderSettings/' + this.RLsiteID + '/orderProcesses/' + value + '/orderProcessDetails' )
      //   .once('value')
      //   .then((snapshot) => {
      //       snapshot.forEach((childSnapshot) => {
      //           let totals = childSnapshot.val()
      //           let key = childSnapshot.key
      //           console.log('key:', key)
      //       })
      //        console.log('---')
      //   })

      //   promises.push(prms)
      // })
      // Promise.all(promises).then(() => {
      //     console.log('All sites updated!')
      // })
    },
    testwhere() {
      // console.log('test where')
      dbFS
        .collection('order_logs')
        .where('firebase_order_id', '==', 'umX9SWVrJwfO8ns3RJHv') // MKgvDTk9l1ksRGBKnBxP
        .get()
        .then(querySnapshot => {
          let oldActions = null;
          let orderLogId = null;
          console.log('-----------------:');
          querySnapshot.forEach(doc => {
            // console.log(doc.id, '=>', doc.data())
            console.log('DOC DATA:', doc.data());
            console.log('DOC id:', doc.id);
            oldActions = doc.data().actions;
            orderLogId = doc.id;
          });
          console.log('oldActions:', oldActions);
          console.log('orderLogId:', orderLogId);
          console.log('test');
        });
    },
    testwhereCustomer() {
      dbFS
        .collection('customers')
        .where('email', '==', 'florinc@onebrandmarketing.com')
        .where('brand_id', '==', '1')
        .get()
        .then(querySnapshot => {
          console.log('-----------------:');
          console.log('querySnapshot', querySnapshot);
          querySnapshot.forEach(doc => {
            // console.log(doc.id, '=>', doc.data())
            console.log('DOC DATA:', doc.data());
            console.log('DOC id:', doc.id);
          });
        });
    },
    testChangefields() {
      const orderId = 'QPtwr4sJBkrPixj8eSfY';
      const orderStatus = {
        // account_label: 'Quote1',
        // account_tag: 'Order Incomplete - Please Call1',
        // proof_upload_url: '',
        // status: 'quote1',
        // status_approve_url: 'https://httpimg.com/public/proofs/?key=4569F66F131F14254AACDD6735DDEF41_test1',
        // status_code: 100,
        // status_proof_back: 'https://httpimg.com/public/proofs/?key=4569F66F131F14254AACDD6735DDEF41&tb=2',
        // status_proof_front: 'https://httpimg.com/public/proofs/?key=4569F66F131F14254AACDD6735DDEF41&tb=1',
        // tracking_number: '',
        // tracking_url: 'https://www.fedex.com/apps/fedextrack/?tracknumbers=_test123441'

        account_label: 'test5',
        account_tag: 'test6',
        proof_upload_url: '',
        status: 'incomplete',
        status_approve_url: '',
        status_code: 104,
        status_proof_back: '',
        status_proof_front: '',
        tracking_number: '',
        tracking_url: ''
      };
      dbFS
        .collection('orders')
        .doc(orderId)
        .update({
          epicore_id: 'test',
          extra_qty: 11,
          print_qty: 12,
          order_status: orderStatus,
          test: {
            test1: '12',
            test2: [
              {
                test1: '1234'
              },
              {
                test2: 345,
                test3: 'test1234'
              }
            ]
          }
        })
        .then(() => {
          console.log('Update Done!');
        })
        .catch(error => {
          console.error('Error : ', error);
        });
    },
    testsubcolection() {
      const documentID = 'NMNOpXbdmdr20Ply4mOU'; // NMNOpXbdmdr20Ply4mOU T40sNxPJjiqlorkiayEz
      console.log('orderDataList', this.orderDataList);
      const promises = [];
      this.orderDataList.forEach((value, index) => {
        const prms = dbFS
          .collection('orders')
          .doc(documentID)
          .collection('order_data')
          .doc(value)
          .delete()
          .then(() => {
            console.log(value + ' ..Deleted');
          })
          .catch(err => {
            console.log(err);
            return Promise.reject();
          });
        promises.push(prms);
        console.log('test');
      });

      Promise.all(promises)
        .then(() => {
          console.log('Order data clear');
        })
        .catch(err => {
          console.log(`Promise all catch error: ${err}`);
          // return Promise.reject()
        });

      // artwork assets customer delivery mailing pricing product
      const artworkDel = 'product';
      // return dbFS.collection('orders').doc(documentID).collection('order_data').doc(artworkDel).get()
      // .then((querySnapshot) => {
      //     console.log('querySnapshot:', querySnapshot)
      //     // querySnapshot.forEach((doc) => {
      //     //     console.log('doc:', doc.id)
      //     //     // console.log('doc:', doc.data())
      //     // })
      // })

      // dbFS.collection('orders').doc(documentID).collection('order_data').doc(artworkDel).delete()
      // .then(() => {
      //   console.log('Deleyed')
      // })
    },
    testgetfields() {
      dbFS
        .collection('orders')
        .doc('RRFUk3IJ01PfWrgYmoBE')
        .get({})
        .then(doc => {
          console.log('DOC DATA:', doc.data());
          // console.log('DOC id:', doc.id)
          const salesforce = doc.data().references.salesforce_line_item_results;
          console.log('salesforce:', salesforce);
          const obj = salesforce;
          let lineItemsResult = {};
          Object.entries(obj).forEach(([key1, value1]) => {
            // console.log('value:', value1)
            // console.log('index:', key1)
            lineItemsResult[key1] = value1;
          });
          console.log('lineItemsResult:', lineItemsResult);
        });
    },
    testgetfieldsDesigns() {
      dbFS
        .collection('customDesigns')
        .doc('J4PomH1TfU64qdOC2c3V')
        .get({})
        .then(doc => {
          console.log('DOC DATA:', doc.data());
        });
    },
    testgetfieldsLists() {
      dbFS
        .collection('mailingLists')
        .doc('Y3VFKRpCVVzbsvO8dfST')
        .get({})
        .then(doc => {
          console.log('DOC DATA:', doc.data());
        });
    },
    testgetfieldsMaps() {
      dbFS
        .collection('maps')
        .doc('AakmPMLFPqFZFrIh4K12')
        .get({})
        .then(doc => {
          console.log('DOC DATA:', doc.data());
        });
    },
    testgetbackupfields() {
      console.log('testgetbackupfields');
      dbFS
        .collection('backup_logs')
        .orderBy('name', 'asc')
        .limit(1)
        .get()
        .then(querySnapshot => {
          // console.log(querySnapshot)
          querySnapshot.forEach(doc => {
            // console.log(doc.id, '=>', doc.data())
            console.log('DOC DATA:', doc.data());
            // console.log('DOC id:', doc.id)
          });
        });
    },
    testAddNotification() {
      const message = 'test message';
      const date = Number(new Date());
      const user = 'Florin CH';
      dbFS
        .collection('notifications')
        .add({
          content: {
            date,
            message
          },
          is_readed: false,
          user
        })
        .then(data => {
          // increment notification total
          console.log('Update notif, id :', data.id);
        });
    },
    testBackup() {
      const passingObj = { test: 'test' };
      this.axios({
        method: 'POST',
        url: `${process.env.VUE_APP_API_URL}testBackupPost`,
        data: passingObj
      })
        // https://github.com/axios/axios
        .then(response => {
          console.log('response:', response);
        });
    },
    testJson() {
      // const body = {downloadUrl: `${process.env.VUE_APP_FIREBASE_STORAGE_URL}wlos-1%2F61063-1%2Fmap%2F07-01-2019%2FQXSmm9wOKG3j3CaTABon%2FQXSmm9wOKG3j3CaTABon.json?alt=media&token=b7d9a79f-3569-4254-bc67-787614f8bf8d`}
      const body = {
        downloadUrl: `${process.env.VUE_APP_FIREBASE_STORAGE_URL}wlos-1%2F61063-3%2Fmap%2F07-12-2019%2FFJZ8mr4ZgRdMMd9GekB4%2FFJZ8mr4ZgRdMMd9GekB4.json?alt=media&token=6ada2e90-d968-45bc-8379-a2d834da4322`
      };
      // const token = 'PDgVwnJAEmkr3eCkDownloadJSON'
      this.axios({
        method: 'POST',
        url: `${process.env.VUE_APP_API_URL}dowloadJson`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: body
      }).then(response => {
        console.log('response:', response);
        const features = response.data.jsonContent.map_data.map_state._state.routes.features;
        console.log('features', features);
        let zipArray = [];
        let i = 0;
        features.forEach((value, index) => {
          if (value.selected === true) {
            console.log(value);
            Object.entries(value).forEach(([keyO, valueO]) => {
              if (keyO === 'properties') {
                i++;
                console.log('valueO:', valueO['demographics.ZIPCRID']);
                zipArray.push(valueO['demographics.ZIPCRID']);
              }
            });
          }
        });
        console.log('i', i);
        console.log('zipArray', zipArray);
      });
    },
    downloadJsonFromStorage() {
      const jsonRef = storageRef('a-test/amazon.json');
      // jsonRef.getMetadata().then(function(metadata) {
      //   console.log('metadata', metadata)
      // })
      // ${process.env.VUE_APP_FIREBASE_STORAGE_URL}a-test%2Famazon.json?alt=media&token=63e59ef8-5756-453c-ab76-d098abd1a140
      jsonRef.getDownloadURL().then(url => {
        console.log('url', url);
        this.axios({
          method: 'GET',
          url,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'bearer ' + '63e59ef8-5756-453c-ab76-d098abd1a140'
          }
        }).then(response => {
          console.log('response:', response);
        });
      });
    },
    testLdpAPi() {
      // "firebase": "^5.2.0",
      const body = {
        message: 'testing message'
      };
      const token = 'HxCparlNZku7w6Awf4Mn';
      this.axios({
        method: 'POST',
        url: `${process.env.VUE_APP_API_URL}stampLdpOrderStatus`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'bearer ' + token
        },
        data: body
      }).then(response => {
        console.log('response:', response);
      });
    },
    testGetOrderByKey() {
      const key = 'HxCparlNZku7w6Awf4Mn';

      // firebase.firestore().collection('orders')
      // .where(firebase.firestore.FieldPath.documentId(), '==', key)
      // .get()
      // .then(snapshots => { /* results are here */
      //     console.log('snapshots:', snapshots)
      // })
      // const trace = perf.trace('testTrace')
      // trace.start()
      dbFS
        .collection('orders')
        .where('firebase_order_id', '==', key) // MKgvDTk9l1ksRGBKnBxP HxCparlNZku7w6Awf4Mn
        .get()
        .then(querySnapshot => {
          console.log('querySnapshot:', querySnapshot);
          if (querySnapshot.size > 0) {
            console.log('YES');
            querySnapshot.forEach(doc => {
              // console.log(doc.id, '=>', doc.data())
              console.log('DOC DATA:', doc.data());
              console.log('DOC id:', doc.id);
              let ldpOrder = doc.data().ldpOrder;
              console.log('DOC ldpOrder: ', ldpOrder);
              ldpOrder.submitStatus = 'test submit';
              console.log('DOC ldpOrder: ', ldpOrder);

              dbFS
                .collection('orders')
                .doc(key)
                .update({
                  ldpOrder
                })
                .then(() => {
                  console.log('Update Done!');
                })
                .catch(error => {
                  console.error('Error : ', error);
                });
            });
          } else {
            console.log('NO');
          }
        });
      // trace.stop()
      // dbFS.collection('orders').doc(key).get()
      // .then((doc) => {
      //        console.log('DOC DATA:', doc.data())
      //        console.log('DOC id:', doc.id)
      // })
    },
    testATZ() {
      console.log('test ATZ');
      const date = '2019-09-22';

      const startDate = new Date(date);
      const endDate = new Date(date);
      endDate.setDate(new Date(date).getDate() + 6);

      const startDateYMD = this.$moment(startDate).format('YYYY-MM-DD');
      const endDateYMD = this.$moment(endDate).format('YYYY-MM-DD');

      console.log(startDateYMD);
      console.log(endDateYMD);
      const geoCodesArray = ['34683C1', '34691B1'];
      const geoCodesString = geoCodesArray.toString();
      console.log('geoCodesString:', geoCodesString);
      const url = 'https://emapdata.adc4gis.com/availability?ids=' + geoCodesString + '&startDate=' + startDateYMD + '&endDate=' + endDateYMD;
      console.log('URL:', url);

      this.axios({
        url,
        method: 'GET'
      })
        .then(response => {
          console.log('Response:', response);
          const atzArrayFromResponse = response.data;
          // const testArr = [
          //  {code: "34683C1", weekOf: "2019-06-09", available: true},
          //  {code: "34691B1", weekOf: "2019-06-09", available: false}
          // ]
          let atzArray = [];
          atzArrayFromResponse.forEach((value, index) => {
            if (value.available === true) {
              atzArray.push(value.code);
            }
          });
          console.log('atzArray:', atzArray);
        })
        .catch(e => {
          console.log(e);
        });
    },
    saveToFtp() {
      console.log('save to');
      let formData = { test: 'test' };

      this.axios({
        url: `${process.env.VUE_APP_API_URL}saveToFtp`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: formData
      })
        .then(response => {
          console.log('Response:', response);
        })
        .catch(e => {
          console.log(e);
        });
    },
    // element UI uploader
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.initialLength + fileList.length} totally`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`);
    },
    uploadFiles(file) {
      console.log(file);
      const fileName = file.file.name;
      console.log(fileName);
      let fileToFile = file.file;
      let formData = new FormData();
      let fieldName = 'test_file';
      formData.append(fieldName, fileToFile, fileName);

      // let formData = {test: 'test'}

      this.axios({
        url: `${process.env.VUE_APP_API_URL}saveToFtp`,
        method: 'POST',
        headers: {
          'Content-Type': 'text/csv' // 'text/csv' // multipart/form-data
        },
        data: formData
      })
        .then(response => {
          console.log('Response:', response);
        })
        .catch(e => {
          console.log(e);
        });
    },
    testToken() {
      // firebase.auth().createCustomToken('123')
      // .then((idToken) => {
      //     console.log(idToken)
      // })
    },
    testDownloadPDF() {
      const passingObj = this.testJsonObj;
      const token = 'PDgVwnJAEmkr3eCkExcel';
      this.axios({
        method: 'POST',
        url: `${process.env.VUE_APP_API_URL}quotePdfGenerator`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: 'bearer ' + token
        },
        data: passingObj,
        responseType: 'blob'
      }).then(response => {
        // console.log('Response:', response)
        let blob = new Blob([response.data], { type: 'application/pdf' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Quote-1.pdf';
        link.click();
      });
    },
    // Algolia
    testAlgoliaGetSettings() {
      let index = this.searchClient.initIndex('site_-LVtfG5GezKZmmi0rbQX_orders');
      index.getSettings((err, content) => {
        console.log(content);
      });
      index
        .search({
          hitsPerPage: 10
        })
        .then(content => {
          // console.log('init content:', content.hits)
          console.log('hits:', content.nbHits);
        })
        .catch(err => {
          console.log(err);
          console.log(err.debugData);
        });
    },
    testAlgoliaListIndex() {
      this.client.listIndexes((err, content) => {
        console.log(content);
        // site_-LlezDCYcMYgDjnmE0no_orders
        // -LjVEVAFM5ApkOindxev a6 -LlGVMI9s6QEhg8bu1sv -LkTGXuzP1k5x1FeJrch
        /*
                -LmFZD0v52WguC9fqgLI
                site_-LlGVMI9s6QEhg8bu1sv_mailing_list
                site_-LlGVMI9s6QEhg8bu1sv_maps
                site_-LlGVMI9s6QEhg8bu1sv_orders
                site_-LlGVMI9s6QEhg8bu1sv_designs
                 */
      });
    },
    testDeleteIndex() {
      console.log('testDeleteIndex');
      const envs = ['orders', 'maps', 'mailing_list', 'designs'];
      const id = '-LmKSt2wxLXrtfLJ6ByW'; // -LkTGXuzP1k5x1FeJrch
      const promises = [];
      // const siteIndex = 'site_' + id + '_' + 'maps'
      // this.client.deleteIndex(siteIndex)
      // .then(content => {
      //     console.log(content)
      // })
      // .catch((error) => {
      //     // Handle error
      //     console.log(error)
      // })

      // this.client.deleteIndex(siteIndex, (err, content) => {
      //   if (err) throw err
      //   console.log('success')
      //   console.log(content)
      // })

      envs.forEach((element, index) => {
        const siteIndex = 'site_' + id + '_' + element;
        console.log('siteIndex:', siteIndex);
        // const prms = this.client.deleteIndex(siteIndex, (err, content) => {
        //   if (err) throw err
        //   console.log('success')
        //   console.log(content)
        // })
        const prms = this.client
          .deleteIndex(siteIndex)
          .then(content => {
            console.log(content);
          })
          .catch(error => {
            // Handle error
            console.log(error);
          });
        promises.push(prms);
      });

      Promise.all(promises).then(() => {
        console.log('All indexes deleted!');
      });

      // this.client.deleteIndex('site_-LlGVMI9s6QEhg8bu1sv_designs', (err, content) => {
      //   if (err) throw err
      //   console.log('success')
      //   console.log(content)
      // })
    },
    testDeleteObject() {
      console.log('test delete object');
      const orderId = 'T40sNxPJjiqlorkiayEz';
      let index = this.searchClientAdmin.initIndex('orders');
      // const algoliaClient = this.client.initIndex('orders')
      index
        .search({
          query: orderId,
          attributesToRetrieve: ['objectID']
        })
        .then(content => {
          console.log('content', content);
          const hitsNr = content.nbHits;
          console.log('hitsNr', hitsNr);
          if (hitsNr > 0) {
            const algoliaObjecId = content.hits[0].objectID;
            console.log('algoliaObjecId ID:', algoliaObjecId);
            index.deleteObject(algoliaObjecId, (err, content) => {
              if (err) throw err;
              console.log('After Delete from orders', content);
            });
          }
        })
        .catch(err => {
          console.log(err);
        });

      let index1 = this.searchClientAdmin.initIndex('site_-LGjZtGpLePH-61Rp662_orders');
      // const algoliaClient = this.client
      index1
        .search({
          query: orderId,
          attributesToRetrieve: ['objectID']
        })
        .then(content => {
          console.log('content', content);
          const hitsNr = content.nbHits;
          if (hitsNr > 0) {
            const algoliaObjecId = content.hits[0].objectID;
            console.log('algoliaObjecId ID site_-:', algoliaObjecId);
            // algoliaClient.deleteObject(algoliaObjecId, (err, content) => {
            //   if (err) throw err;

            //   console.log('After Delete:', content);
            // })
          }
        })
        .catch(err => {
          console.log(err);
        });

      // const options1 = { query: orderId }
      // index.findObject(hit => hit.objectID , options1, (err, content) => {
      //   console.log(content);
      // })
    },
    addOrder() {
      console.log('--- add Order');
      console.log('Selected site:', this.selectedSite);
      console.log('Selected env:', this.selectedEnv);
      const timestamp = firestoreTimestamp.fromDate(new Date());
      console.log('Firebase timesampt: ', firestoreTimestamp.fromDate(new Date()));
      //
      /*
            a4 site : -LkTGXuzP1k5x1FeJrch

             VLttqCZmhBmq0lkr5OlY del
             APCNVnxotXDOx1NWf1jN del
             QXJF0MU14vNUwKQRFMKmb del
             Pt4RhJ7jl2DF4uI3jb5s del
             68e0KV5g5d34NgEStKX2 del

            */

      // telescope check-decagram campfire anvil pickaxe

      if (this.selectedSite !== null && this.selectedEnv !== null) {
        var expr = this.selectedEnv;
        // let objectToInsert = {}
        switch (expr) {
          case 'orders':
            console.log('Orders');
            // dbFS.collection(this.selectedEnv).add(
            //     {
            //         'is_quote': false,
            //         'api_version': '1',
            //         'order_type': 'eddm',
            //         'customer_unique_id': 121345,
            //         'crm_customer_id': 0,
            //         'creation_date': timestamp,
            //         'first_name': 'Florin',
            //         'last_name': 'CH',
            //         'gatekeeper_email': '',
            //         'order_total': 123,
            //         'discount_total': 0,
            //         'product_name': '12×15 Oversized EDDM',
            //         'is_test': false,
            //         'label': 'Test EDDM  Order - Do not process (Florin)',
            //         'crm_order_id': 0,
            //         'database_path': 'orders',
            //         'order_email': 'florinc@onebrandmarketing.com',
            //         'mailing_service': 'eddm',
            //         'mail_qty': 0,
            //         'extra_qty': 0,
            //         'print_qty': 0,
            //         'special_instructions': '',
            //         'site_id': 'a-test',
            //         'site_db_id': this.selectedSite,
            //         'site_name': 'a-test',
            //         'payment_id': '',
            //         'order_status': {
            //             'account_tag': '',
            //             'account_label': '',
            //             'status_code': 0,
            //             'status_proof_front': '',
            //             'status_proof_back': '',
            //             'status_approve_url': '',
            //             'proof_upload_url': '',
            //             'tracking_number': '',
            //             'tracking_url': '',
            //             'status': 'testing'
            //         },
            //         'references': {
            //             'map_id': '',
            //             'order_list_id': '',
            //             'upload_list_id': '',
            //             'later_list_id': '',
            //             'design_id': ''
            //         }
            //     }
            // ).then((data) => {
            //     // increment notification total
            //     console.log('Update notif, id :', data.id)
            // })
            break;
          case 'maps':
            console.log('Maps');
            break;
          case 'lists':
            console.log('Lists');
            break;
          case 'designs':
            console.log('Designs:', this.selectedEnv);
            /*
                         a4 site : -LkTGXuzP1k5x1FeJrch

                         vTkeSi15hzYMPMwd6tIe
                         ml5oxxIevqJQ1gYGwhLz
                         qEiz4PbGSmxG2I2xX4Xm
                         CxDnKH3oMNe0MIGn5RKO
                         wfJRFJsBimlYAwqtIuL0

                         PZK8v0ZDTfptPUW233qU
                         sD49ejCJrEWgugj9Gyyq

                         gmDzuXmm6E994fKKpvkX
                         GWgQTfXTQ0gWd8ZYi2nb

                         7LuyPNtp67D3teCFnWQe

                         htjrlKB35YLHGqoVNkIW
                         h5oes66foyO61qjU0tSf

                         1fvDeqyFLhtNwTB3fKgi
                         Fm88tl46AVm0SCHGNnuy

                         FQW8iQ2jiSchBSxNBidf
                         xz1En9eE7YHWfmouXry5
                         */
            dbFS
              .collection('customDesigns')
              .add({
                clientId: '-1234_TEST_3_61063',
                config: {
                  initialMode: 'Advanced',
                  preloader: {},
                  restoreProductOnReloadEnabled: true,
                  spellCheckEnabled: true,
                  userId: 3
                },
                crmCustomerId: 1001,
                crm_order_id: 100100,
                customer: {
                  clientId: '-1234_TEST_3_61063',
                  crmCustomerId: 1001,
                  first_name: 'Florin',
                  last_name: 'CH',
                  userEmail: 'florinc@onebrandmarketing.com'
                },
                customizedDesignTime: timestamp,
                fb_order_path: '5BR1235',
                proofImages: {},
                renderedData: {
                  boundsData: {},
                  hiResOutputUrls: [],
                  proofImageUrls: {},
                  returnToEditUrl: 'https://dl.onebrand.net/page/iframe/?state=1234',
                  stateId: '1111-6745-4d35-83c2-1111'
                },
                savedProduct: {
                  returnToEditUrl: 'https://dl.onebrand.net/page/iframe/?state=1234',
                  stateId: '1111-6745-4d35-83c2-1111',
                  userId: '3'
                },
                siteDbId: this.selectedSite,
                surfaces: ['test_Front_9x625_EDDM', 'test_Back_9x625_EDDM'],
                templateId: 2222,
                userEmail: 'florinc@onebrandmarketing.com'
              })
              .then(data => {
                console.log('Add to designs :', data.id);
              });
            break;
          default:
            console.log('NONE');
        }
      } else {
        console.log('Selection Empty');
      }
    },
    // Data Axle
    testScanAPI() {
      console.log('SCAN API');
      // const passingObj = {
      //     'filter': {
      //         'relation': 'equals',
      //         'attribute': 'cuisines',
      //         'value': 'sushi'
      //       }
      // }
      this.axios({
        method: 'GET',
        url: `${process.env.VUE_APP_API_URL}generateCsvFromDataAxle`,
        headers: {
          // 'Access-Control-Allow-Origin': '*',
          // 'Content-Type': 'application/json'
          // 'Authorization': 'bearer ' + token
        }
        // data: passingObj
      })
        // https://github.com/axios/axios
        .then(response => {
          console.log('response:', response);
        });
    },
    testScrollAPI() {
      this.axios({
        method: 'POST',
        url: `${process.env.VUE_APP_API_URL}getScroll`,
        headers: {
          // 'Access-Control-Allow-Origin': '*',
          // 'Content-Type': 'application/json'
          // 'Authorization': 'bearer ' + token
        }
        // data: passingObj
      })
        // https://github.com/axios/axios
        .then(response => {
          console.log('response:', response);
        });
    },
    testSalesForce() {
      console.log('test');
      // Jira task 1366
      this.axios({
        method: 'POST',
        url: `${process.env.VUE_APP_API_URL}testEndpoint`,
        headers: {
          // 'Access-Control-Allow-Origin': '*',
          // 'Content-Type': 'application/json'
          // 'Authorization': 'bearer ' + token
        }
        // data: passingObj
      })
        // https://github.com/axios/axios
        .then(response => {
          console.log('response:', response);
        });
    },
    testDmmCreateCampaign() {
      // const data = 'apikey=a1ojye5tx34px9hbqfbn&idcust=73053&action=createcampaign&name=Test CampaignOne&cardsize=4x6&front_url=https://placeimg.com/640/480/animals&back_url=https://placeimg.com/640/480/any'

      // this.axios({
      //     method: 'POST',
      //     url: 'https://test.directmailmanager.com/api/mgapi.asp',
      //     headers: {
      //         'Accept': 'application/json',
      //         'Content-Type': 'application/x-www-form-urlencoded'
      //     },
      //     data
      // })
      // .then((response) => {
      //     console.log('response:', response)
      // })
      // const bodyPayload = {

      // }
      // const token = 'test'

      this.axios({
        method: 'POST',
        url: `${process.env.VUE_APP_API_URL}testEndpointDMM`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
          // 'Authorization': 'bearer ' + token
        }
        // data: passingObj
      })
        // https://github.com/axios/axios
        .then(response => {
          console.log('response:', response);
        });
    },
    deleteFirebaseStorege() {
      let storageTestRef = storageRef('a1-test/');
      storageTestRef
        .delete()
        .then(() => {
          console.log('Test file deletedd');
        })
        .catch(e => {
          console.log(e);
        });
    },
    checkPolitical() {
      console.log('yuhu');
      this.axios({
        method: 'POST',
        url: `${process.env.VUE_APP_API_URL}testEndpoint`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
          // 'Authorization': 'bearer ' + token
        }
        // data: passingObj
      })
        // https://github.com/axios/axios
        .then(response => {
          console.log('response:', response);
        });
    }
  }
};
</script>

<style scoped>
.tf-custom-div {
  height: 200px;
}
/* .active {
  font-size: 20px !important;
} */
.test-set-inline-block {
  display: inline-block;
}
.test-custom-right-border {
  border-right: 1px #d3d3d3 solid;
}
</style>
