<template>
	<v-alert v-if="snackbar && snackbar.type && snackbar.message" :type="snackbar.type" dismissible class="mt-2 mb-n2">{{ message }}</v-alert>
</template>

<script>
export default {
	data() {
		return {
			message: ''
		}
	},
	computed: {
		snackbar(){
			return this.$store.getters.getSnackbarGlobal;
		}
	},
	watch: {
		'snackbar.message': function(message){
			this.message = message;
			if(message){
				window.scrollTo(0, 0);
				setTimeout(() => {
					this.$store.dispatch('setSnackbar', { type:'', message: '' });
				}, 5000);
			}
		}
	}
}
</script>
