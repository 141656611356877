<template>
  <v-dialog v-model="isOpenValue" persistent max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">Special Instruction</span>
      </v-card-title>
      <v-card-text>
        <span v-html="specialInstructions"></span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ['isOpen', 'specialInstructions'],
  computed: {
    isOpenValue() {
      return this.isOpen;
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeSpecialInstructionDialog');
    }
  }
};
</script>
