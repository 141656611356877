import orderService from '../../services/orderService.js';
import moment from 'moment';

const state = {
  isPostcardDropsLoading: false,
  postcardDrops: []
};

const getters = {
  getPostcardDropsLoadingState(state) {
    return state.isPostcardDropsLoading;
  },
  getPostcardDrops(state) {
    return state.postcardDrops;
  }
};

const mutations = {
  setPostcardDropsLoadingState(state, payload) {
    state.isPostcardDropsLoading = payload;
  },
  setPostcardDrops(state, payload) {
    state.postcardDrops = payload;
  }
};

const actions = {
  async loadPostcardDrops({ commit, dispatch }) {
    try {
      commit('setPostcardDrops', []);
      commit('setPostcardDropsLoadingState', true);
      const postcardOrders = await orderService.getByProperty('product_name', ['4x6 DM Postcard', '6x9 DM Postcard', '4×6 Small DM Postcard', '6×9 Standard DM Postcard'], {
        operator: 'in',
        isArray: true
      });

      const orderDataPromises = postcardOrders.map(order => orderService.getOrderData(order.firebase_order_id));

      const orderData = await Promise.all(orderDataPromises);

      const drops = postcardOrders.flatMap((order, orderIndex) => {
        const orderDeliveryDates = orderData[orderIndex]?.delivery?.delivery_dates || [];
        return orderDeliveryDates
          .filter(drop => Number(drop.drop_status) === 695 && order.is_letter === false)
          .map((drop, index) => ({
            ...drop,
            drop_date: moment(drop.date, ['YYYY-MM-DD', 'DD-MMM-YYYY', 'MMMM, DD YYYY HH:mm:ss']).format('YYYY-MM-DD'),
            firebase_order_id: order.firebase_order_id,
            osprey_order_id: order.crm_order_id,
            crm_customer_id: order.crm_customer_id,
            drop_num: drop.drop_num || index + 1
          }));
      });
      commit('setPostcardDrops', drops);
    } catch (error) {
      dispatch('showAlert', { message: error.message || 'Could not load postcard drops.', type: 'error' }, { root: true });
      console.error('[ loadPostcardDrops ERROR ]:', error);
    } finally {
      commit('setPostcardDropsLoadingState', false);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
