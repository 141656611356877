<template>
  <v-row cols="12">
    <v-col cols="12" sm="5">
      <v-menu v-model="isStartDateMenuVisible" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on }">
          <v-text-field :value="selectedStartDate" label="Start Date" readonly v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="selectedStartDate" :max="maxAvailableDate" @change="setStartDateMenuVisibility(false)"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="5">
      <v-menu v-model="isEndDateMenuVisible" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on }">
          <v-text-field :value="selectedEndDate" label="End date" readonly v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="selectedEndDate" :max="maxAvailableDate" @change="setEndDateMenuVisibility(false)"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="1">
      <v-btn text color="primary" dark @click="clearDate()">Clear all</v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['isFiltersCleared', 'startDate', 'endDate', 'timeStampAttrName'],
  data() {
    return {
      selectedStartDate: '',
      selectedEndDate: '',
      isStartDateMenuVisible: false,
      isEndDateMenuVisible: false
    };
  },
  computed: {
    maxAvailableDate() {
      return this.$moment().format('YYYY-MM-DD');
    }
  },
  watch: {
    selectedStartDate(value) {
      this.onRouteHandlerChanged('startDate', value);
      this.setDateFilter('startDate', value);
    },
    selectedEndDate(value) {
      this.onRouteHandlerChanged('endDate', value);
      this.setDateFilter('endDate', value);
    },
    isFiltersCleared() {
      if (this.isFiltersCleared) this.clearDate();
    }
  },
  mounted() {
    this.selectedStartDate = this.startDate;
    this.selectedEndDate = this.endDate;
  },
  methods: {
    setDateFilter(datePicker, value) {
      const isStartDateSelected = datePicker === 'startDate';
      const startDateInUnix = isStartDateSelected
        ? this.$moment(value).startOf('day').unix()
        : this.$moment(this.selectedStartDate || '2018-12-01')
            .startOf('day')
            .unix();

      const endDateInUnix = isStartDateSelected
        ? this.$moment(this.selectedEndDate || {})
            .endOf('day')
            .unix()
        : this.$moment(value).endOf('day').unix();

      if (startDateInUnix && endDateInUnix && !isNaN(startDateInUnix) && !isNaN(endDateInUnix)) {
        if (startDateInUnix > endDateInUnix) {
          this.onWrongFilter(true);
        } else {
          this.onDateSelected(true);
          this.onWrongFilter(false);
          this.onDateFilterChanged(`${this.timeStampAttrName}:${startDateInUnix} TO ${endDateInUnix}`);
        }
      } else {
        this.onDateSelected(false);
      }
    },
    clearDate() {
      this.selectedStartDate = '';
      this.selectedEndDate = '';
      this.$emit('onDateCleared');
    },
    setStartDateMenuVisibility(state) {
      this.isStartDateMenuVisible = state;
    },
    setEndDateMenuVisibility(state) {
      this.isEndDateMenuVisible = state;
    },
    onDateFilterChanged(filter) {
      this.$emit('onDateFilterChanged', filter);
    },
    onWrongFilter(isEndDateSmaller) {
      this.$emit('onWrongFilter', isEndDateSmaller);
    },
    onRouteHandlerChanged(key, value) {
      this.$emit('onRouteHandlerChanged', { key, value });
    },
    onDateSelected(isDateSelected) {
      this.$emit('onDateSelectedStateChanged', isDateSelected);
    }
  }
};
</script>
