import { render, staticRenderFns } from "./ManageTemplateType.vue?vue&type=template&id=60f09d00"
import script from "./ManageTemplateType.vue?vue&type=script&lang=js"
export * from "./ManageTemplateType.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports