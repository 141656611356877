<template>
  <v-container fluid>
    <v-dialog v-model="editFromAddress" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline font-weight-regular grey--text text--darken-2">Edit From Address</v-card-title>
        <v-card-text class="mt-5">
          <v-row v-for="([addressProperty, addressValue], index) in Object.entries(fromAddress)" :key="index" class="justify-center mt-n6">
            <v-col cols="12">
              <v-text-field
                :key="index"
                v-model="fromAddress[addressProperty]"
                dense
                :label="formatAddress(addressProperty)"
                :value="addressValue"
                :rules="checkZipCode(addressProperty)"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-n7 justify-end">
          <v-btn text class="mr-2" color="grey darken-3" @click="closeFromAddressEditDialog()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isDmmResultVisible" max-width="500" persistent>
      <v-card>
        <v-card-title class="justify-end">
          <v-btn icon color="grey darken-2" class="mr-n1" @click="closeDmmResultDialog()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center mt-n5 mb-1">
            <v-icon :color="isDmmStatusSuccess ? 'green' : 'red'" size="60">{{ isDmmStatusSuccess ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline' }}</v-icon>
          </v-row>
          <v-row class="justify-center mt-4 mb-1">
            <span v-if="isDmmStatusSuccess" class="headline font-weight-medium green--text text--darken-2">Success</span>
            <span v-else class="headline font-weight-medium red--text text--darken-2">Failed</span>
          </v-row>
          <v-row class="justify-center dmm-result-message">
            <p class="title font-weight-medium grey--text text--darken-2 my-2">
              {{ dmmResult.message }}
            </p>
          </v-row>
          <v-row v-if="isDmmStatusSuccess && addresses > 0" class="justify-center">
            <p class="title text-center font-weight-medium grey--text text--darken-2 mb-5">
              Postcards will be mailed as soon as the processing of {{ addresses }} addresses completed
            </p>
          </v-row>
          <v-row v-if="!isDmmStatusSuccess" class="justify-center mt-3 mb-n2">
            <v-btn color="orange darken-2 white--text" :loading="isSendPostcardsLoading" block @click="sendPostcard()"> <v-icon class="mr-2">mdi-restore</v-icon>Retry </v-btn>
          </v-row>
          <v-row v-if="isDmmStatusSuccess" class="justify-center my-3">
            <v-btn block color="blue-grey darken-2" class="white--text" @click="returnToOsprey()">
              <v-img class="mr-4" src="@/assets/logos/ospreyLogo.png" max-width="22" height="30"></v-img>
              Return to Osprey
            </v-btn>
          </v-row>
          <v-row v-if="isDmmStatusSuccess" class="mb-n2">
            <v-btn block color="primary" @click="viewOrderInStamp()">View Order Details</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card class="mb-0" elevation="4">
      <p class="title font-weight-regular pt-4 pl-3">Create Postcards with DMM V3</p>
      <v-stepper v-model="steps">
        <v-stepper-header>
          <v-stepper-step v-if="isTargeted" :complete="steps > 1" step="1">Register Custom Fields (optional)</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="steps > artworkStep" :step="artworkStep">Create Front and Back Artworks</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="steps > mailingListStep" :step="mailingListStep">Get Counts And Create Mailing List</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :step="finalStep">Send Postcards</v-stepper-step>
        </v-stepper-header>
        <div id="dmm-cncw-alerts">
          <v-alert v-if="feedbackAlert" :type="feedbackType" dismissible outlined prominent dense>{{ feedbackMessage }}</v-alert>
        </div>
        <v-stepper-items>
          <v-stepper-content v-if="isTargeted" step="1">
            <v-card class="elevation-0">
              <v-card-text>
                <custom-fields :customer-id="customerId" />
              </v-card-text>
              <v-row class="justify-center mb-2">
                <v-btn color="primary" @click="goToStep(artworkStep)">Continue</v-btn>
              </v-row>
            </v-card>
          </v-stepper-content>
          <v-stepper-content :step="artworkStep">
            <v-card class="elevation-0">
              <v-card-text>
                <artwork-tool v-if="isTargeted" :rootOrder="orderData" componentMode="dmm-tool" @artworksData="loadArtworkImages" />
                <edit-creative v-else class="mt-8 mb-n5" :rootOrder="orderData" componentMode="dmm-tool" @artworksData="loadArtworkImages" />
              </v-card-text>
              <v-row v-if="isSubmitDmmV3CampaignEnabled" class="justify-center mb-2">
                <v-btn v-if="isTargeted" color="primary" outlined class="mr-8" @click="goToStep(1)">Back</v-btn>
                <v-btn color="primary" @click="goToStep(mailingListStep)">Continue</v-btn>
              </v-row>
            </v-card>
          </v-stepper-content>
          <v-stepper-content :step="mailingListStep">
            <v-card class="pt-5 mb-4 elevation-0">
              <v-card-text>
                <import-addresses
                  v-if="showSelectAddressesOptions"
                  :isImportOptionsVisible="showSelectAddressesOptions"
                  :initialList="mailingList"
                  :orderId="orderId"
                  :mailDropId="mailDropId"
                  :orderType="orderType"
                  :isCustomFieldsEnabled="true"
                  @downloadURL="csvFileDownloadUrl"
                  @selectedCsvLink="setCsvLink"
                  @notification="displayNotification"
                >
                </import-addresses>

                <v-row v-if="csvLink" class="justify-center mt-n5 ml-n5">
                  <v-col cols="12" md="7" xl="5">
                    <v-card class="elevation-0" outlined>
                      <v-row class="justify-center align-center">
                        <v-col cols="12" sm="4" class="mt-3">
                          <v-menu v-model="menuStartDMM" :close-on-content-click="false" max-width="290">
                            <template v-slot:activator="{ on }">
                              <v-text-field :value="computedStart" label="Start Date" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="firstDateDMM" @change="menuStartDMM = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="4" class="mt-3">
                          <v-menu v-model="menuEndDMM" :close-on-content-click="false" max-width="290">
                            <template v-slot:activator="{ on }">
                              <v-text-field :value="computedEnd" label="End date" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="endDateDMM" @change="menuEndDMM = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="3" class="text-end">
                          <v-btn color="blue-grey white--text mt-1" :loading="isCountsLoading" @click="getCounts()">Get Counts</v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row v-if="addresses > 0" class="justify-center ml-n5">
                  <v-col cols="12" md="7" xl="5">
                    <v-alert color="#DBE3D3" class="text-center"
                      ><b class="mr-1">{{ addresses }}</b> Addresses Available</v-alert
                    >
                  </v-col>
                </v-row>

                <v-row v-if="isDateRangeSelectorVisible" :class="`${!addresses ? 'mt-0' : 'mt-n5'} justify-center ml-n5 mb-3`">
                  <v-col cols="12" md="7" xl="5">
                    <v-card class="elevation-0" outlined>
                      <v-radio-group v-model="selectedCsvDateRange" class="pl-3 mt-3">
                        <v-radio :label="`Use the default period of 30 days (${defaultDateRange})`" value="default" color="blue-grey" class="mb-4"></v-radio>
                        <v-radio :label="`Use range ${computedStart} - ${computedEnd}`" value="custom" color="blue-grey" class="mb-n3"></v-radio>
                      </v-radio-group>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row v-if="csvLink || uploadedFileUrl" class="ml-n5 mt-n3 justify-center">
                  <v-col cols="12" md="7" xl="5">
                    <v-text-field v-model="mailingListName" label="Mailing List Name" required type="text" outlined></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="(csvLink || uploadedFileUrl) && mailingListName" class="justify-center">
                  <v-btn color="primary" class="mr-8" outlined @click="showArtworkStep()">Back</v-btn>
                  <v-btn color="primary" @click="goToStep(finalStep)">Continue</v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-stepper-content>
          <v-stepper-content :step="finalStep">
            <v-card>
              <v-card-text class="ml-1">
                <v-row class="justify-space-around">
                  <v-col cols="12" lg="6">
                    <v-row class="mt-n1">
                      <v-icon color="primary" class="ml-n2 mr-2 mt-n4">mdi-chevron-right</v-icon>
                      <p class="subtitle-1 font-weight-medium grey--text text--darken-2">
                        Size:
                        <span class="subtitle-1 font-weight-regular grey--text text--darken-2 ml-1">{{ size }}</span>
                      </p>
                    </v-row>
                    <v-row class="mt-1 mb-0">
                      <v-icon color="primary" class="ml-n2 mr-2 mt-n3">mdi-chevron-right</v-icon>
                      <p class="subtitle-1 font-weight-medium grey--text text--darken-2">
                        Mailing Date Range:
                        <span class="subtitle-1 font-weight-regular grey--text text--darken-2 ml-1">{{ mailingDateRange }}</span>
                      </p>
                    </v-row>
                    <v-row v-if="addresses" class="mt-n1">
                      <v-icon color="primary" class="ml-n2 mr-2 mt-n4">mdi-chevron-right</v-icon>
                      <v-tooltip bottom open-delay="150">
                        <template v-slot:activator="{ on }">
                          <p class="subtitle-1 font-weight-medium grey--text text--darken-2">
                            Addresses:
                            <span class="subtitle-1 font-weight-regular grey--text text--darken-2 ml-1" v-on="on">{{ addresses }}</span>
                          </p>
                        </template>
                        <span>Addresses will be recalculated based on a date period</span>
                      </v-tooltip>
                    </v-row>
                    <v-row class="mt-n4 mb-n6">
                      <v-icon color="primary" class="ml-n2 mr-2 mt-n6">mdi-chevron-right</v-icon>
                      <p class="subtitle-1 font-weight-medium grey--text text--darken-2 pt-4">Send Date:</p>
                      <v-col cols="6" sm="4" xl="3" class="mt-n2">
                        <v-menu v-model="sendDateMenu" :close-on-content-click="false" max-width="290">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="sendDateComputed"
                              v-bind="attrs"
                              outlined
                              dense
                              class="mt-1"
                              style="cursor: pointer"
                              append-icon="mdi-calendar"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="sendDate" :allowed-dates="allowedDates" @change="sendDateMenu = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class="mt-n1">
                      <v-icon color="primary" class="ml-n2 mr-2 mt-n4">mdi-chevron-right</v-icon>
                      <p class="subtitle-1 font-weight-medium grey--text text--darken-2">
                        Front and Back Artwork IDs:
                        <span class="subtitle-1 font-weight-regular grey--text text--darken-2 ml-1">{{ artworkData.front_artwork_id }} / {{ artworkData.back_artwork_id }}</span>
                      </p>
                    </v-row>
                    <v-row class="mt-n4">
                      <v-icon color="primary" class="mt-n2 ml-n2 mr-2">mdi-chevron-right</v-icon>
                      <p class="subtitle-1 font-weight-medium grey--text text--darken-2 pt-4 mr-6">Choose Mail Type:</p>
                      <v-radio-group v-model="postage" :mandatory="false" row class="mt-3">
                        <v-radio label="First Class Postage" value="first_class" color="red darken-2"></v-radio>
                        <v-radio label="Standard Postage" value="standard" color="red darken-2" :disabled="!isStandardPostageAvailable"></v-radio>
                      </v-radio-group>
                    </v-row>
                    <v-row class="mt-1">
                      <v-icon color="primary" class="ml-n2 mt-n9 mr-2">mdi-chevron-right</v-icon>
                      <v-col cols="11" sm="8" xl="4" class="mt-n4 ml-n3">
                        <v-text-field v-model="postcardDescription" label="Description - optional" outlined dense :rules="[v => v.length <= 80 || 'Max 80 characters!']">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-row class="mt-n3 mb-3">
                      <p class="subtitle-1 font-weight-bold grey--text text--darken-3">Mail From Address:</p>
                      <v-tooltip bottom open-delay="300">
                        <template v-slot:activator="{ on }">
                          <v-btn class="ml-5" color="blue-grey darken-1" outlined small v-on="on" @click="editFromAddress = true">
                            <v-icon class="mr-2">mdi-pencil</v-icon>Edit Address
                          </v-btn>
                        </template>
                        <span>Edit Mail From Address</span>
                      </v-tooltip>
                    </v-row>
                    <template v-if="Object.values(fromAddress)[0]">
                      <v-row v-for="(addrInfo, index) in Object.entries(fromAddress)" :key="index" class="justify-start">
                        <v-col cols="12" lg="10" xl="8" class="mt-n2">
                          <v-row>
                            <v-icon color="primary" class="ml-n2 mr-2 mt-n4">mdi-chevron-right</v-icon>
                            <p class="subtitle-1 font-weight-medium grey--text text--darken-2">
                              {{ formatAddress(addrInfo[0]) }}:
                              <span class="subtitle-1 font-weight-regular grey--text text--darken-2 ml-1">{{ addrInfo[1] }}</span>
                            </p>
                          </v-row>
                        </v-col>
                      </v-row>
                    </template>
                    <v-row v-else class="mt-2 offset-sm-1">
                      <v-progress-circular color="primary" indeterminate size="50"></v-progress-circular>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="Object.values(fromAddress)[0] && !isDmmResultVisible" class="justify-center mb-7">
                  <v-btn color="primary" class="mr-8" outlined @click="goToStep(mailingListStep)">Back</v-btn>
                  <v-btn color="red darken-2 white--text mr-2" :loading="isSendPostcardsLoading" :disabled="isSendPostcardsDisabled" @click="sendPostcard()">
                    <v-icon color="white" class="mr-2">mdi-send</v-icon>Send Postcards
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-container>
</template>

<script>
import ImportAddressesOptions from './DmmCustomImport.vue';
import EditCreative from '../detail-components/EditCreative.vue';
import DmmCustomFields from '../dmm/DmmCustomFields.vue';
import PostcardArtworkTool from '../dmm/PostcardArtworkTool.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    'import-addresses': ImportAddressesOptions,
    'edit-creative': EditCreative,
    'custom-fields': DmmCustomFields,
    'artwork-tool': PostcardArtworkTool
  },
  props: [
    'firestoreMapId',
    'orderId',
    'startDate',
    'endDate',
    'csvLinkProp',
    'mailDropId',
    'orderData',
    'dropNr',
    'campaignId',
    'isRetry',
    'testSiteIds',
    'isLimitApplied',
    'customerId'
  ],
  data() {
    return {
      steps: 1,
      size: '6x9',
      front_url: '',
      back_url: '',
      isArtworkLoading: false,
      artworkData: {
        is_front_preview_loading: false,
        is_back_preview_loading: false,
        front_artwork_id: '',
        front_preview_url: '',
        back_artwork_id: '',
        back_preview_url: '',
        thumbnail_front_uid: '',
        thumbnail_back_uid: ''
      },
      feedbackType: 'info',
      feedbackMessage: '',
      feedbackAlert: false,
      mailingListName: '',
      startDateParam: this.startDate,
      endDateParam: this.endDate,
      menuStartDMM: false,
      menuEndDMM: false,
      firstDateDMM: '',
      endDateDMM: '',
      addresses: 0,
      csvLink: this.csvLinkProp,
      postage: 'first_class',
      selectedCsvDateRange: 'default',
      dmmResult: {
        status: '',
        message: ''
      },
      postcardDescription: '',
      sendDate: this.getNextDay(),
      sendDateMenu: false,
      fromAddress: {
        address_line1: '',
        address_line2: '',
        address_city: '',
        address_state: '',
        address_zip: ''
      },
      editFromAddress: false,
      isCountsLoading: false,
      isSendPostcardsLoading: false,
      headerMapping: {},
      uploadedFileUrl: '',
      isDmmResultVisible: false,
      weekendDayIndexes: [0, 6], //0-Sunday; 6-Saturday
      zipCodeRule: [
        v => !!v || `ZIP code is Required`,
        v => (v && !isNaN(v)) || `ZIP code must contain only numbers!`,
        v => (v && v.length === 5) || `ZIP code must be 5 numbers!`
      ],
      testSiteFirebaseIds: []
    };
  },
  computed: {
    orderType() {
      return this.orderData?.order_type;
    },
    mailingList() {
      return this.orderData?.assets?.mailing_list?.[0] || '';
    },
    isTargeted() {
      return this.orderType === 'targeted';
    },
    artworkStep() {
      return this.isTargeted ? 2 : 1;
    },
    mailingListStep() {
      return this.isTargeted ? 3 : 2;
    },
    finalStep() {
      return this.isTargeted ? 4 : 3;
    },
    computedStart() {
      return this.startDateParam;
    },
    computedEnd() {
      return this.endDateParam;
    },
    firstDateComp() {
      return this.firstDateDMM;
    },
    endDateComp() {
      return this.endDateDMM;
    },
    sendDateComputed() {
      return this.sendDate ? this.$moment(this.sendDate).format('YYYY-MM-DD') : '';
    },
    showSelectAddressesOptions() {
      return !this.csvLink && !this.uploadedFileUrl;
    },
    campaignName() {
      return `${this.orderId ?? 'artwork'}_${this.mailDropId ? `_${this.mailDropId}` : ''}`;
    },
    defaultListName() {
      return `${this.orderId ?? 'list'}_${this.mailDropId}_${this.dropNr}`;
    },
    isDmmStatusSuccess() {
      return this.dmmResult?.status === 'success';
    },
    isFromAddressZipCodeValid() {
      return this.fromAddress?.address_zip?.length === 5 && !isNaN(this.fromAddress?.address_zip);
    },
    isSubmitDmmV3CampaignEnabled() {
      return this.artworkData?.front_artwork_id && this.artworkData?.back_artwork_id;
    },
    isDateRangeSelectorVisible() {
      return this.computedStart !== this.startDate || this.computedEnd !== this.endDate;
    },
    defaultDateRange() {
      return `${this.$moment().subtract(30, 'days').format('YYYY-MM-DD')} - ${this.$moment().format('YYYY-MM-DD')}`;
    },
    mailingDateRange() {
      return this.selectedCsvDateRange === 'default' ? this.defaultDateRange : `${this.computedStart} - ${this.computedEnd}`;
    },
    ...mapGetters('users', {
      userAccessLevel: 'userAccessLevel'
    }),
    isUserSuperAdmin() {
      return this.userAccessLevel && this.userAccessLevel.value && parseInt(this.userAccessLevel.value) === 100;
    },
    isStandardPostageAvailable() {
      return this.addresses >= 200 && this.size !== '4x6';
    },
    isSendPostcardsDisabled() {
      return (this.postage === 'standard' && !this.isStandardPostageAvailable) || !this.isFromAddressZipCodeValid;
    }
  },
  watch: {
    startDate(value) {
      this.startDateParam = value;
    },
    endDate(value) {
      this.endDateParam = value;
    },
    firstDateComp(value) {
      if (value) {
        this.startDateParam = value;
      }
    },
    endDateComp(value) {
      if (value) {
        if (this.$moment(this.startDateParam).unix() > this.$moment(value).unix()) {
          this.setFeedback('warning', 'End date cannot be smaller than start date!');
        }
        this.endDateParam = value;
      }
    },
    csvLinkProp(value) {
      this.csvLink = value;
    },
    sendDate(value) {
      const nextDay = this.getNextDay();
      if (value < nextDay) {
        this.sendDate = nextDay;
      }
    },
    steps(value) {
      if (value === this.mailingListStep) {
        this.getFromAddressData();
      }
    },
    isFromAddressZipCodeValid(valid) {
      if (!valid) {
        this.setFeedback('error', 'The given ZIP Code for Mail From address is invalid!');
      } else {
        this.feedbackAlert = false;
      }
    },
    selectedCsvDateRange(value) {
      if (value === 'default') {
        this.firstDateDMM = this.startDate;
        this.endDateDMM = this.endDate;
      }
    }
  },
  async mounted() {
    this.mailingListName = this.defaultListName;
    this.testSiteFirebaseIds = this.testSiteIds ?? (await this.$store.dispatch('site/getTestSites'));
    if (this.weekendDayIndexes.includes(this.$moment(this.sendDate).weekday())) {
      this.sendDate = this.$moment().add(1, 'weeks').day(1).format('YYYY-MM-DD');
    }
    this.postage = this.orderData?.mailing?.postage || 'first_class';
  },
  methods: {
    goToStep(step) {
      this.steps = step;
    },
    getNextDay() {
      return this.$moment().add(1, 'days').format('YYYY-MM-DD');
    },
    async getCounts() {
      try {
        this.isCountsLoading = true;
        console.log('[ Start Date ]:', this.computedStart, ' ::: [ End Date ]:', this.computedEnd);
        if (this.csvLink.length > 0) {
          const csvLink = `${this.csvLink}&startDate=${this.computedStart}&endDate=${this.computedEnd}`;
          const response = await this.$store.dispatch('tools/getCsvCounts', { csvLink, returnCsvFile: false });
          console.log('CsvLines response:', response);
          if (response.data && response.data.rowCount > 0) {
            this.addresses = response.data.rowCount;
          } else {
            this.setFeedback('info', 'No address found for selected dates!');
          }
        }
      } catch (error) {
        this.onError(error, '[ Error occured during getting counts ]:');
      } finally {
        this.isCountsLoading = false;
      }
    },
    sendPostcard() {
      this.isSendPostcardsLoading = true;
      this.$store.dispatch('showLoader', { message: 'Loading, please stand by...' });

      (this.sendDate === this.getNextDay() ? this.createPostcard() : this.scheduleDrop()).finally(() => {
        this.isSendPostcardsLoading = false;
        this.$store.dispatch('hideLoader');
      });
    },
    async createPostcard() {
      const passingObj = this.getDropExecutionConfig();
      if (this.postcardDescription.length && this.postcardDescription.length < 80) {
        passingObj.description = this.postcardDescription;
      }
      if (parseInt(this.campaignId) > 0) {
        passingObj.legacy_dmm_campaign_id = parseInt(this.campaignId);
      }
      if (this.isRetry) {
        passingObj.is_retry = true;
      }
      if (this.selectedCsvDateRange === 'custom') {
        passingObj.start_date = this.computedStart;
        passingObj.end_date = this.computedEnd;
      }

      console.log('[ CREATE POSTCARD Payload ] ::', passingObj);
      try {
        const response = await this.$store.dispatch('tools/createPostcards', passingObj);
        const dropInformations = response.data ?? '';
        if (dropInformations) {
          console.log('[ Create Postcards Result]:', dropInformations);
          this.addresses = dropInformations.drop_quantity;
          this.setDmmResult('success', 'Mailing list created successfully');
        }
      } catch (error) {
        return this.onError(error, '[ Error in sending postcards ]:');
      }
    },
    async scheduleDrop() {
      const scheduleRequestConfig = this.getDropExecutionConfig();
      if (this.selectedCsvDateRange === 'custom') {
        scheduleRequestConfig.start_date = this.computedStart;
        scheduleRequestConfig.end_date = this.computedEnd;
      }

      try {
        const {
          data: [{ scheduled_drop_id = '' }]
        } = await this.$store.dispatch('tools/scheduleDrop', [scheduleRequestConfig]);
        return this.setDmmResult('success', `Drop has been scheduled successfully on date ${this.sendDate} with firebase id: ${scheduled_drop_id}`);
      } catch (error) {
        return this.onError(error, '[ Error in schedule postcard ]:');
      }
    },
    getDropExecutionConfig() {
      return {
        osprey_order_id: parseInt(this.orderId),
        order_id: this.orderData.firebase_order_id,
        mail_drop_id: parseInt(this.mailDropId),
        drop_date: this.sendDate,
        drop_num: parseInt(this.dropNr),
        is_limit_applied: this.isLimitApplied,
        csv_link: this.uploadedFileUrl ?? this.csvLink ?? '',
        list_name: this.mailingListName,
        header_mapping: this.headerMapping,
        size: this.size,
        mail_type: this.postage,
        front_artwork: this.artworkData.front_artwork_id,
        back_artwork: this.artworkData.back_artwork_id,
        from_address: this.fromAddress,
        thumbnail_front_uid: this.artworkData.thumbnail_front_uid,
        thumbnail_back_uid: this.artworkData.thumbnail_back_uid
      };
    },
    async getFromAddressData() {
      try {
        const result = await this.$store.dispatch('om_orders/getOrderDataDoc', { orderId: this.orderData.firebase_order_id, docName: 'customer' });
        this.fromAddress = {
          address_line1: result.customer_location.street || '',
          address_line2: '',
          address_city: result.customer_location.city || '',
          address_state: result.customer_location.state || '',
          address_zip: result.customer_location.zip_code || ''
        };
        if (result.additional.company_name) {
          this.fromAddress.company_name = result.additional.company_name;
        }
      } catch (error) {
        this.onError(error, '[ Error in getting From Address ]:');
      }
    },
    returnToOsprey() {
      window.open(`${process.env.VUE_APP_OSPREY_ENDPOINT}/orders/view.cfm?id=${this.orderId}`);
    },
    loadArtworkImages(data) {
      if (data?.size) {
        this.size = data.size && data.size !== '9x6' ? data.size : '6x9';
      }

      if (data?.artwork_front_id) {
        this.artworkData.front_artwork_id = data.artwork_front_id ? data.artwork_front_id : '';

        this.artworkData.thumbnail_front_uid =
          data.artwork_thumbnails_front && data.artwork_thumbnails_front.small ? this.getThumbnailUid(data.artwork_thumbnails_front.small) : '';
      }

      if (data?.artwork_back_id) {
        this.artworkData.back_artwork_id = data.artwork_back_id ? data.artwork_back_id : '';

        this.artworkData.thumbnail_back_uid = data.artwork_thumbnails_back && data.artwork_thumbnails_back.small ? this.getThumbnailUid(data.artwork_thumbnails_back.small) : '';
      }
    },
    setDmmResult(status, message) {
      this.isDmmResultVisible = true;
      this.dmmResult.status = status;
      this.dmmResult.message = message;
    },
    setFeedback(type, message) {
      this.feedbackType = type;
      this.feedbackMessage = message;
      this.feedbackAlert = true;
      const element = document.getElementById('dmm-cncw-alerts');
      const top = element.offsetTop;
      window.scrollTo({
        behavior: 'smooth',
        top: top + 300
      });
      setTimeout(() => {
        this.feedbackAlert = false;
      }, 20000);
    },
    formatAddress(addressTitle) {
      let splitted = addressTitle.split('_');
      let formatted = '';
      splitted.forEach((chunk, index) => {
        formatted += `${index > 0 ? ' ' : ''}${chunk.charAt(0).toUpperCase()}${chunk.slice(1)}`;
      });
      return formatted;
    },
    csvFileDownloadUrl(data) {
      console.log('[ Uploaded file data ] :: ', data);
      this.addresses = data.totalCount;
      this.uploadedFileUrl = data.url;
      this.headerMapping = data.headers;
    },
    setCsvLink(link) {
      console.log('[ Selected CSV Link ]:: ', link);
      this.csvLink = link ?? '';
    },
    getThumbnailUid(url) {
      return url.substring(url.indexOf('thumbnails/'), url.indexOf('_')).split('/')[1];
    },
    closeDmmResultDialog() {
      this.isDmmResultVisible = false;
    },
    viewOrderInStamp() {
      this.$router.push({
        path: `/orders/view/${this.orderData.firebase_order_id}`
      });
    },
    getDateProperty(date, propertyName) {
      return parseInt(this.$moment(date || {}).get(propertyName));
    },
    allowedDates(date) {
      return (
        !this.weekendDayIndexes.includes(this.$moment(date).weekday()) &&
        (this.getDateProperty(date, 'year') > this.getDateProperty('', 'year') ||
          this.getDateProperty(date, 'month') > this.getDateProperty('', 'month') ||
          (this.getDateProperty(date, 'month') === this.getDateProperty('', 'month') && this.getDateProperty(date, 'date') > this.getDateProperty('', 'date')))
      );
    },
    checkZipCode(field) {
      return field === 'address_zip' ? this.zipCodeRule : [];
    },
    closeFromAddressEditDialog() {
      this.editFromAddress = false;
    },
    errorHandler(error, customMessage) {
      return {
        statusCode: error?.response?.status ?? error?.status ?? 500,
        statusText: error?.response?.statusText ?? error?.statusText ?? '',
        message: `${customMessage ?? ''} ${JSON.stringify(error?.response?.data?.message ?? error?.response?.data ?? error?.response ?? error)}`
      };
    },
    onError(error, errorLogMessage) {
      const errorData = this.errorHandler(error);
      console.log(errorLogMessage, errorData);
      this.setDmmResult('error', `${errorData.statusCode}: ${errorData.statusText} - ${errorData.message}`);
    },
    displayNotification(data) {
      if (data?.type && data?.message) {
        this.setFeedback(data.type, data.message);
      }
      if (data?.hasOwnProperty('isVisible')) {
        this.feedbackAlert = data.isVisible;
      }
    },
    showArtworkStep() {
      this.steps = this.artworkStep;
      this.addresses = 0;
      this.uploadedFileUrl = '';
      this.headerMapping = {};
      this.csvLink = this.csvLinkProp;
    }
  }
};
</script>

<style scoped>
.dmm-result-message {
  overflow-wrap: anywhere;
}
</style>
